import { Skeleton, Row, Col, Space } from "antd";
import {
	Inner,
	ProductData,
	PublishContainer,
	PublishSettings,
} from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
	return (
		<Inner>
			<ProductData>
				<Space direction="vertical" size="middle" style={{ display: "flex" }}>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={21}>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={21}>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>

					<Row></Row>
				</Space>
			</ProductData>

			<PublishSettings>
				<Space direction="vertical" size="middle" style={{ display: "flex" }}>
					<PublishContainer>
						<Skeleton.Input
							active={true}
							style={{ marginTop: "-20px", width: "350px" }}
						/>
					</PublishContainer>
				</Space>
			</PublishSettings>
		</Inner>
	);
}
