import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Space, Select, Checkbox, Modal } from "antd";
import { InnerForm } from "./styles";
import Image from "../../../common/image/Image";
import MarketLogo from "../../../../assets/images/Onetransfer-logo.png";
import { sign_up } from "../../../../api/endpoints/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/authContext";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import DropShadow from "../../../common/drop-shadow/DropShadow";
import PhoneInput from "../../../common/phone-input/PhoneInput";
import { get_provinces } from "../../../../api/endpoints/region";
import { COLORS, Typo } from "../../../layout/theme";
import CuitInput from "../../../common/cuit-input/CuitInput";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const GUTTER = { xs: 0, sm: 10, md: 10, lg: 10, xl: 10, xxl: 10 };
const { confirm } = Modal;

export default function SignUp() {
	const { login, user } = useAuth();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);
	const [idProvincia, setIdProvincia] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [listadoLocalidades, setListadoLocalidades] = useState(null);
	const [provincia, setProvincia] = useState(null);
	const [localidad, setLocalidad] = useState();
	const [activeIsChain, setActiveIsChain] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res
					.map((p) => ({
						id: p.id,
						name: p.title,
						localities: p.localities.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						})),
					}))
					.sort((a, b) => {
						const titleA = a.name.toLowerCase();
						const titleB = b.name.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);

			setListadoLocalidades(res.filter((p) => p.id === idProvincia));
		};

		fetch_region_1_data();
	}, [idProvincia]);

	useEffect(() => {
		if (user) {
			const firstName = user.displayName.split(" ")[0];
			const lastName = user.displayName.split(" ")[1];
			const phone = user.phoneNumber;
			const photoUrl = user.photoURL;
			const email = user.email;

			form.setFieldsValue({
				firstName,
				lastName,
				phone,
				photoUrl,
				email,
			});
		}
	}, [form, user]);

	const handleChange = (e) => {
		setActiveIsChain(e.target.checked);
	};

	const onFinish = async (values) => {
		const continueWithSubmission = async () => {
			try {
				setSubmitting(true);

				const postData = {
					...values,
					phone: `${values.phoneAreaCode}${values.phone}`,
					region: `ARG-${idProvincia}-${localidad.value}`,
					isChain: activeIsChain,
				};

				await sign_up(postData);

				openNotificationWithIcon(
					TYPE.SUCCESS,
					"Cuenta creada con exito",
					"Su cuenta se creo con exito, le enviamos un correo para confirmar su email."
				);

				if (!user) {
					await login(values.email, values.password);
				}
			} catch (error) {
				const errors = Object.entries(error?.response?.data?.errors);
				openNotificationWithIcon(
					TYPE.ERROR,
					errors.map((e) => `${e[1]} `).concat()
				);
				if (error.name === "FirebaseError") {
					navigate("/");
				} else {
				}
			} finally {
				setSubmitting(false);
			}
		};

		try {
			if (!provincia || !localidad) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe seleccionar una Provincia y una Localidad"
				);
				return;
			}

			if (!canSubmit) {
				confirm({
					title: "Advertencia",
					icon: <ExclamationCircleOutlined style={{ color: COLORS.Warning }} />,
					content:
						"No se pudo validar el cuit. Esto puede afectar al registro de su farmacia",
					okText: "Enviar",
					okType: "danger",
					cancelText: "Cancelar",
					onOk: continueWithSubmission,
					onCancel: () => {
						setSubmitting(false);
						return;
					},
				});
			} else {
				await continueWithSubmission();
			}
		} catch (error) {
			setSubmitting(false);

			const errors = Object.entries(error?.response?.data?.errors);
			openNotificationWithIcon(
				TYPE.ERROR,
				errors.map((e) => `${e[1]} `).concat()
			);
			if (error.name === "FirebaseError") {
				navigate("/");
			} else {
			}
		} finally {
			setSubmitting(false);
		}
	};

	const handleProvinciasSelect = (value, object) => {
		setProvincia(object);
		setIdProvincia(value);
	};

	const handleLocalidadSelect = (value, objectList) => {
		setLocalidad(objectList);
	};

	return (
		<SignInTemplate columns="1">
			<SignInTemplate.Main>
				<SignInTemplate.Logo>
					<Image
						src={MarketLogo}
						alt="Logo FarmaTransfer"
						height="45px"
						width="220px"
					/>
				</SignInTemplate.Logo>
				<SignInTemplate.Top>
					<SignInTemplate.Title>Crear nueva cuenta</SignInTemplate.Title>
					<SignInTemplate.Desc>
						Complete sus datos para unirse a One Transfer
					</SignInTemplate.Desc>
				</SignInTemplate.Top>
				<SignInTemplate.Body>
					<DropShadow>
						<SignInTemplate.Formulario>
							<Form
								form={form}
								name="sign-up-form"
								onFinish={onFinish}
								requiredMark={false}
								layout="vertical"
								wrapperCol={{ span: 24 }}
							>
								<InnerForm>
									<Space
										direction="vertical"
										size="small"
										style={{ display: "flex" }}
									>
										<Row gutter={GUTTER}>
											<Col span={24} align="start">
												<SignInTemplate.SubTitle color="Primary">
													Datos del usuario
												</SignInTemplate.SubTitle>
											</Col>
										</Row>
										<Row gutter={GUTTER}>
											<Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
												<SignInTemplate.AntdFormItem
													name="firstName"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														{
															max: 50,
															message: "Maximo 50 caracteres",
														},
														{
															pattern: new RegExp(
																/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
															),
															message:
																"No se aceptan caracteres especiales. Solo letras y numeros",
														},
													]}
												>
													<Input placeholder="Nombre" size="large" />
												</SignInTemplate.AntdFormItem>
											</Col>
											<Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
												<SignInTemplate.AntdFormItem
													name="lastName"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														{
															max: 50,
															message: "Maximo 50 caracteres",
														},
														{
															pattern: new RegExp(
																/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
															),
															message:
																"No se aceptan caracteres especiales. Solo letras y numeros",
														},
													]}
												>
													<Input size="large" placeholder="Apellido" />
												</SignInTemplate.AntdFormItem>
											</Col>
											<Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
												<PhoneInput form={form} size="large" />
											</Col>
										</Row>
										<Row gutter={GUTTER}>
											<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
												<SignInTemplate.AntdFormItem
													name="email"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													<Input
														size="large"
														placeholder="Email"
														type="email"
														disabled={!!user}
													/>
												</SignInTemplate.AntdFormItem>
											</Col>
											<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
												<SignInTemplate.AntdFormItem
													name="confirmEmail"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														({ getFieldValue }) => ({
															validator(rule, value) {
																if (
																	!value ||
																	getFieldValue("email") === value
																) {
																	return Promise.resolve();
																}
																return Promise.reject(
																	"No coinciden los correos ingresados"
																);
															},
														}),
													]}
												>
													<Input
														size="large"
														type="email"
														placeholder="Repetir Email"
														disabled={!!user}
													/>
												</SignInTemplate.AntdFormItem>
											</Col>
										</Row>

										{!user && (
											<Row gutter={GUTTER}>
												<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
													<SignInTemplate.AntdFormItem
														name="password"
														rules={[
															{
																required: true,
																message: "Este campo es obligatorio",
															},
															{
																min: 6,
																message: "Minimo 6 caracteres",
															},
															{
																max: 20,
																message: "Maximo 20 caracteres",
															},
														]}
													>
														<Input
															type="password"
															size="large"
															placeholder="Contraseña"
														/>
													</SignInTemplate.AntdFormItem>
												</Col>

												<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
													<SignInTemplate.AntdFormItem
														name="confirmPassword"
														rules={[
															{
																required: true,
																message: "Este campo es obligatorio",
															},
															{
																min: 6,
																message: "Minimo 6 caracteres",
															},
															{
																max: 20,
																message: "Maximo 20 caracteres",
															},
															({ getFieldValue }) => ({
																validator(rule, value) {
																	if (
																		!value ||
																		getFieldValue("password") === value
																	) {
																		return Promise.resolve();
																	}
																	return Promise.reject(
																		"No coinciden las contraseñas"
																	);
																},
															}),
														]}
													>
														<Input
															type="password"
															size="large"
															placeholder="Repetir contraseña"
														/>
													</SignInTemplate.AntdFormItem>
												</Col>
											</Row>
										)}
										<SignInTemplate.Break height="20px" />
										<Row>
											<Col span={24} align="start">
												<SignInTemplate.SubTitle color="Primary">
													Datos de la farmacia
												</SignInTemplate.SubTitle>
											</Col>
										</Row>

										<Row gutter={GUTTER}>
											<Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
												<SignInTemplate.AntdFormItem
													name="name"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														{
															max: 50,
															message: "Maximo 50 caracteres",
														},
														{
															pattern: new RegExp(
																/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
															),
															message:
																"No se aceptan caracteres especiales. Solo letras y numeros",
														},
													]}
												>
													<Input size="large" placeholder="Nombre farmacia" />
												</SignInTemplate.AntdFormItem>
											</Col>
											<Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
												<CuitInput
													form={form}
													canSubmit={canSubmit}
													setCanSubmit={setCanSubmit}
												/>
											</Col>
										</Row>
										<Row gutter={GUTTER}>
											<Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
												<SignInTemplate.AntdFormItem
													name="businessAddress"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														{
															max: 200,
															message: "Maximo 200 caracteres",
														},
														{
															pattern: new RegExp(
																/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
															),
															message:
																"No se aceptan caracteres especiales. Solo letras y numeros",
														},
													]}
												>
													<Input
														size="large"
														placeholder="Dirección de farmacia"
													/>
												</SignInTemplate.AntdFormItem>
											</Col>

											<Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
												<SignInTemplate.AntdFormItem
													name="businessPostalCode"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														{
															max: 20,
															message: "Maximo 20 caracteres",
														},
														{
															pattern: new RegExp(
																/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
															),
															message:
																"No se aceptan caracteres especiales. Solo letras y numeros",
														},
													]}
												>
													<Input size="large" placeholder="Código Postal" />
												</SignInTemplate.AntdFormItem>
											</Col>
											<Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
												<SignInTemplate.AntdFormItem
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													{localidad !== undefined ? (
														<Select disabled>
															<Select.Option
																key={provincia?.value}
																value={provincia?.value}
															>
																{provincia?.children}
															</Select.Option>
														</Select>
													) : (
														<Select
															onChange={handleProvinciasSelect}
															placeholder="Provincia"
														>
															{listadoProvincias
																?.sort((a, b) => {
																	const titleA = a.name.toLowerCase();
																	const titleB = b.name.toLowerCase();
																	if (titleA < titleB) {
																		return -1;
																	}
																	if (titleA > titleB) {
																		return 1;
																	}

																	return 0;
																})
																.map((p) => (
																	<Select.Option key={p.id} value={p.id}>
																		{p.name}
																	</Select.Option>
																))}
														</Select>
													)}
												</SignInTemplate.AntdFormItem>
											</Col>
											<Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
												<SignInTemplate.AntdFormItem
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													{provincia ? (
														<Select
															allowClear={true}
															placeholder="Elegir Localidad"
															onChange={handleLocalidadSelect}
														>
															{listadoLocalidades[0]?.localities
																.sort((a, b) => {
																	const titleA = a.locality.title.toLowerCase();
																	const titleB = b.locality.title.toLowerCase();
																	if (titleA < titleB) {
																		return -1;
																	}
																	if (titleA > titleB) {
																		return 1;
																	}

																	return 0;
																})
																.map((p) => (
																	<Select.Option
																		key={p.locality.id}
																		value={p.locality.id}
																	>
																		{p.locality.title}
																	</Select.Option>
																))}
														</Select>
													) : (
														""
													)}
												</SignInTemplate.AntdFormItem>
											</Col>
										</Row>
										<Row gutter={GUTTER}>
											<Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
												<SignInTemplate.AntdFormItem>
													<Checkbox
														onChange={handleChange}
														checked={activeIsChain}
													>
														<Typo type="secondary" level={6}>
															Soy una cadena con sucursales
														</Typo>
													</Checkbox>
												</SignInTemplate.AntdFormItem>
											</Col>
											<Col xs={2} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
											<Col xs={4} sm={8} md={8} lg={8} xl={8} xxl={8}></Col>
											<Col xs={2} sm={4} md={4} lg={4} xl={4} xxl={4}></Col>
										</Row>
										<SignInTemplate.Break height="15px" />
										<SignInTemplate.ButtonsContainer>
											<Row gutter={GUTTER}>
												<Col span={24}>
													<SignInTemplate.AntdFormItem>
														<SignInTemplate.AntdButton
															size="large"
															type="Primary"
															htmlType="submit"
															loading={submitting}
															bg="Primary"
															color="White"
														>
															Registrarme
														</SignInTemplate.AntdButton>
													</SignInTemplate.AntdFormItem>
												</Col>
											</Row>
										</SignInTemplate.ButtonsContainer>
									</Space>
								</InnerForm>
							</Form>
						</SignInTemplate.Formulario>
					</DropShadow>
				</SignInTemplate.Body>
			</SignInTemplate.Main>
		</SignInTemplate>
	);
}
