import React from "react";
import { DatePicker, Divider } from "antd";
import { DateFilters, Inner, Section, Title } from "./styles";

const { RangePicker } = DatePicker;

export default function DateFilterModal({ setOrdersFrom, setOrdersTo }) {
	const onChange = (date) => {
		setOrdersFrom(date.format("YYYY/MM/DD"));
		setOrdersTo(date.format("YYYY/MM/DD"));
	};

	const onChangePicker = (date) => {
		setOrdersFrom(date[0].format("YYYY/MM/DD"));
		setOrdersTo(date[1].format("YYYY/MM/DD"));
	};

	return (
		<Inner>
			<Section>
				<Title>Filtrar por fecha:</Title>
				<DateFilters>
					<DatePicker allowClear={false} onChange={onChange} />
				</DateFilters>
			</Section>
			<Divider />
			<Section>
				<Title>Filtrar por rango de fechas:</Title>
				<DateFilters>
					<RangePicker
						allowClear={false}
						onChange={onChangePicker}
						disabled={[false, false]}
					/>
				</DateFilters>
			</Section>
		</Inner>
	);
}
