import styled from "styled-components/macro";

export const Inner = styled.div`
	display: flex;
	justify-content: center;
	margin-top: -20px;
	margin-left: 50px;
	width: 100%;
	height: 600px;

	.ant-card-bordered {
		border: none;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-wrap: nowrap;
`;

export const LeftContainer = styled.div`
	justify-content: center;
	align-items: center;
	float: left;
	margin-left: -150px;
`;

export const LeftCarousel = styled.div`
	width: 400px;
	height: 400px;
	align-content: center;
`;

export const RightContainer = styled.div`
	padding: 30px 60px;
`;
