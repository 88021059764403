import styled from "styled-components/macro";
import { Button as ButtonAntd, Select } from "antd";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 40px;

	@media only screen and (min-width: 700px) and (max-width: 1190px) {
		flex-direction: column;
	}

	@media only screen and (min-width: 1191px) and (max-width: 1231px) {
		flex-direction: row;
		gap: 10px;
	}
`;

export const OptionsSection = styled.div`
	display: flex;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	margin-bottom: 30px;
	margin-top: 30px;
	gap: 20px;
`;

export const ProductsSection = styled.div`
	flex: 1 1 80%;
	height: 100%;
	width: 100%;
	overflow: hidden;
`;

export const InfoSection = styled.div`
	flex: 1 1 25%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	max-height: 540px;
`;

export const CartAntdButton = styled(ButtonAntd)`
	cursor: pointer;

	&:hover {
		color: ${COLORS.Primary};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}

	background-color: ${COLORS.Primary};
	color: ${COLORS.White};
	border-radius: 5px;
	box-shadow: 0 3px 3px 0 #c7c7c7;
`;

export const Container = styled.div`
	padding-top: 1rem;
`;

export const InfoSubSection = styled.div`
	display: flex;
	justify-content: space-evenly;
	margin: 15px 0px 0px 0px;
	align-content: center;
	align-items: center;

	@media only screen and (min-width: 760px) and (max-width: 979px) {
		justify-content: center;
		gap: 20px;

		div {
			font-size: ${SIZES.level12};
		}
	}
`;

export const InfoTitle = styled.div`
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitleTotal = styled.div`
	color: ${COLORS.Primary};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitleProductQuantity = styled.div`
	color: ${COLORS.Default};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitleBuy = styled.div`
	color: ${COLORS.Primary};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.Black};
`;

export const InfoSubTitle = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;
export const InfoNumber = styled.div`
	outline: auto;
	outline-width: thin;
	outline-color: ${COLORS.Default};
	border-radius: 5px;
	width: 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.Default};
	font-size: ${SIZES.level9};
`;

export const InfoTitleProductQuantityItems = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoNumberItems = styled.div`
	outline: auto;
	outline-width: thin;
	outline-color: ${COLORS.Grey};
	border-radius: 5px;
	width: 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
`;

export const InfoIva = styled.div`
	font-size: 10px;
	align-items: center;
	align-content: center;
	justify-content: center;
	width: 100%;
	text-align: center;
	color: ${COLORS.Default};
`;

export const SelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const PresentationTitle = styled.div`
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.SemiBold};
`;

export const InfoTitleAlert = styled.div`
	color: ${COLORS.Danger};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.Bold};
`;
