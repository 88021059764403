import { Button, Radio } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";

export const CalendarButton = styled(Button)`
	margin-top: 20px;
	border-radius: 5px;
`;

export const RadioOptions = styled.div`
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const AntdRadioButton = styled(Radio.Button)`
	border: none;
	font-weight: ${WEIGHT.Bold};
`;

export const ButtonOffer = styled.button`
	cursor: pointer;

	&:hover {
		color: ${COLORS.Primary};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}
	color: ${COLORS.White};
	font-size: ${SIZES.level7};
	font-weight: ${WEIGHT.Black};
	background-color: ${COLORS.Primary};
	margin: 1em;
	padding: 0.2em 1em;
	border: 2px solid ${COLORS.Primary};
	border-radius: 20px;
	box-shadow: 0 5px 5px #bfbfbf;
	margin-right: 60px;
`;
