import styled, { keyframes } from "styled-components/macro";
import { Radio, Input, Dropdown, Button } from "antd";
import COLORS from "../../../../../layout/theme/colors/Colors";
import { WEIGHT, SIZES } from "../../../../../layout/theme";

const { Search } = Input;

const linearMove = keyframes`
  from {
    transform: translateX(20%);
  }

  to {
    transform: translateX(0%);
  }
`;

const linearMoveRight = keyframes`
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(30%);
  }
`;

export const Filters = styled.div`
	margin: 20px 0;
	display: flex;
	justify-content: space-between;

	& button {
		padding: 0 !important;
	}

	@media only screen and (min-width: 760px) and (max-width: 1200px) {
		flex-direction: column;
		height: 150px;
	}
`;

export const FiltersLeft = styled.div``;

export const FiltersRight = styled.div``;

export const AntdSearch = styled(Search)`
	& .ant-input-group-addon:last-child {
		display: none;
	}
	& input.ant-input {
		font-size: ${SIZES.level11};
	}
	& input.ant-input {
		height: 35px;
		width: 400px;
	}
	& span.ant-input-affix-wrapper {
		border-radius: 5px !important;
	}
`;

export const SpanCointainer = styled.div`
	margin: 60px 0;
`;
export const Span = styled.span`
	cursor: pointer;
	color: ${COLORS.Secondary};
	margin-right: 60px;
	font-weight: ${WEIGHT.ExtraBold};
`;

export const Line = styled.span`
	position: relative;
	&::before {
		content: "";
		width: 120%;
		background-color: ${COLORS.Grey};
		height: 3px;
		position: absolute;
		z-index: 99;
		top: 50%;
		left: -2px;
	}
`;
export const OfferPrice = styled.span`
	margin-left: 10px;
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level11};
`;
export const Price = styled.span``;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const UnP = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const InnerExportModal = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
	align-content: center;
	justify-content: center;
`;

export const ExportButton = styled(Button)`
	width: 220px;
	height: 48px;
	border-radius: 5px;
	font-size: 14px;
`;

export const Arrow = styled.div`
	display: inline-block;
	animation: ${linearMove} 1s linear infinite;
	padding: 2rem 1rem;
	font-size: 1.2rem;
	color: ${COLORS.Success};
`;

export const ArrowRight = styled.div`
	display: inline-block;
	animation: ${linearMoveRight} 1s linear infinite;
	padding: 2rem 1rem;
	font-size: 1.2rem;
	color: ${COLORS.Success};
	position: absolute;
	left: 0;
	top: 490px;
`;
