import styled from "styled-components";
import { COLORS } from "../../layout/theme";

export const Inner = styled.div`
  display: flex;
  border-radius: 15px;
  background-color: ${COLORS.White};
  height: 140px;
  width: 100%;
  padding: 10px 20px;
  gap: 20px;
`;
export const ImageContainer = styled.div`
  flex: 1 1 15%;
  -webkit-flex: 0 0 15%;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  object-fit: contain;
`;
export const CenterData = styled.div`
  flex: 1 1 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid ${COLORS.Grey};
  border-right: 1px solid ${COLORS.Grey};
  padding: 0px 15px;
`;
export const ProductsContainer = styled.div`
  display: flex;
  gap: 10px;
`;
export const TopCenter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightData = styled.div`
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LinksContainer = styled.div`
  display: flex;
  gap: 50px;
  align-items: center;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: ${COLORS.Default};
  display: flex;
  align-items: center;
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
`;
