import React, { useEffect, useState } from "react";
import {
	AntdDatePicker,
	Container,
	ContainerDiv,
	FilterSection,
	Inner,
	ScrollSection,
	Section,
} from "./styles";
import { AutoSizer, List } from "react-virtualized";
import ProductSearchCardListContainer from "../../../../containers/product-search-cards-list/ProductSearchCardListContainer";
import { get_user_order_items } from "../../../../../api/endpoints/orders";
import { Typo } from "../../../../layout/theme";
import AntdSelect from "../../../../common/antd-select/AntdSelect";
import { Select } from "antd";

export default function BuyedList() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [width, setWidth] = useState(window.innerWidth);
	const [client, setClient] = useState("ALL");
	const [month, setMonth] = useState(0);
	const [year, setYear] = useState(0);

	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const fetch_user_items = async () => {
		setLoading(true);

		const res = await get_user_order_items(client, month, year);

		setData(res);
		setLoading(false);
	};

	useEffect(() => {
		fetch_user_items();
	}, [client, month]);

	// const onChangePicker = (date) => {
	// 	setMonth(date.month() + 1);
	// 	setYear(date.year());
	// };

	if (!data) return <div>Loading...</div>;

	return (
		<Inner>
			<FilterSection>
				<Container>
					<Typo type="primary" level={6}>
						Búsqueda:
					</Typo>
					<AntdSelect
						style={{ width: "180px" }}
						onChange={(e) => setClient(e)}
						placeholder="Elegir laboratorio"
						value={client === "ALL" ? null : client}
						allowClear
					>
						{data?.laboratories?.map((i) => (
							<Select.Option key={i.clientId} value={i.clientId}>
								{i.clientName}
							</Select.Option>
						))}
					</AntdSelect>
					{/* <AntdDatePicker
						allowClear={false}
						onChange={onChangePicker}
						picker="month"
					/> */}
				</Container>
			</FilterSection>
			<ContainerDiv>
				<AutoSizer>
					{({ width, height }) => {
						return (
							<Section>
								<List
									width={width}
									height={height}
									rowHeight={160}
									rowCount={data?.quantityResults}
									rowRenderer={({ key, index, style }) => {
										const product = data?.userOrderItems[index];
										return (
											<ScrollSection key={key} style={style}>
												<ProductSearchCardListContainer
													key={`card_${product.productId}`}
													item={product}
													dateInfo={false}
												/>
											</ScrollSection>
										);
									}}
								/>
							</Section>
						);
					}}
				</AutoSizer>
			</ContainerDiv>
		</Inner>
	);
}
