import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../utils/constants";

const useIsMobile = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	return width <= CONSTANTS.MOBILE_BREAKPOINT_WIDTH_CHECK;
};

export default useIsMobile;
