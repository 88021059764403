import styled from "styled-components";
import { COLORS } from "../../../../../layout/theme";

export const ContainerDiv = styled.div`
	width: 100%;
	margin-top: 40px;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;
