import React from "react";
import { ModalContainer } from "./styles";
import ProductTemplateDetail from "../../pages/buyer/templates/components/product-template-detail/ProductTemplateDetail";

export default function TemplateModal({
	id,
	quantityInCart,
	setQuantityInCart,
}) {
	return (
		<ModalContainer>
			<ProductTemplateDetail
				id={id}
				quantityInCart={quantityInCart}
				setQuantityInCart={setQuantityInCart}
			></ProductTemplateDetail>
		</ModalContainer>
	);
}
