import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	Form,
	Input,
	Row,
	Col,
	Space,
	Select,
	message,
	Checkbox,
	Modal,
} from "antd";
import Main from "../../../../layout/main/Main";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import {
	ClientData,
	Container,
	DistributorData,
	InnerForm,
	LeftSection,
	PublishSettings,
	RightSection,
	SalesData,
	SectionData,
	StoreData,
} from "./styles";
import DistributorsSelector from "../../../../common/distributos-selector/DistributorsSelector";
import { COLORS, Typo } from "../../../../layout/theme";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { create_client } from "../../../../../api/endpoints/clients";
import { get_sale_departments } from "../../../../../api/endpoints/saledepartments";
import DebounceSelect from "../../../../common/debounce-select/DebounceSelect";
import PhoneInput from "../../../../common/phone-input/PhoneInput";
import ImageGallery from "../../../../common/image-gallery/ImageGallery";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import { get_megaDistributors } from "../../../../../api/endpoints/megaDistributors";
import { CloseCircleOutlined } from "@ant-design/icons";
import { get_provinces } from "../../../../../api/endpoints/region";

const { Option } = Select;
const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

const states = [
	{
		id: "ACTIVE",
		name: "Activo",
	},
	// {
	// 	id: "DELETED",
	// 	name: "Inactivo",
	// },
];

const fetch_sale_departments = async (term) => {
	const res = await get_sale_departments(term);

	return res.map((r) => ({
		label: r.name,
		value: r.id,
	}));
};

export default function CreateClient() {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);
	const [distributors, setDistributors] = useState(null);
	const [isSeller, setIsSeller] = useState(false);
	const [saleDepartments, setSaleDepartments] = useState(null);
	const [imageUrl, setImageUrl] = useState();
	const navigate = useNavigate();
	const [megaDistributors, setMegaDistributors] = useState(null);
	const [selectedMegaDistributor, setSelectedMegaDistributor] = useState(null);
	const [photoGallery, setPhotoGallery] = useState([]);
	const [activeBannerStore, setActiveBannerStore] = useState(false);
	const [typeOfSaleOption, setTypeOfSaleOption] = useState(null);
	const [activeIsChain, setActiveIsChain] = useState(false);
	const [idProvincia, setIdProvincia] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [listadoLocalidades, setListadoLocalidades] = useState(null);
	const [provincia, setProvincia] = useState(null);
	const [localidad, setLocalidad] = useState();

	useEffect(() => {
		const fetch_megaDistributors = async () => {
			const res = await get_megaDistributors();
			setMegaDistributors(res);
		};
		fetch_megaDistributors();
	}, []);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res
					.map((p) => ({
						id: p.id,
						name: p.title,
						localities: p.localities.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						})),
					}))
					.sort((a, b) => {
						const titleA = a.name.toLowerCase();
						const titleB = b.name.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);

			setListadoLocalidades(res.filter((p) => p.id === idProvincia));
		};

		fetch_region_1_data();
	}, [idProvincia]);

	const handleIsChain = (e) => {
		setActiveIsChain(e.target.checked);
	};

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			if (!isSeller) {
				if (!provincia || !localidad) {
					setSubmitting(false);
					openNotificationWithIcon(
						TYPE.ERROR,
						"Para avanzar debe seleccionar una Provincia y una Localidad"
					);
					return;
				}
			}

			const newDistributor = values.distributors.map((d) =>
				d.checked === true ? { ...d, code: "si" } : { ...d, code: null }
			);

			const postData = {
				name: values.name,
				cuit: values.cuit,
				address: values.address,
				postalCode: values.postalCode,
				isSeller: isSeller,
				activeStore: isSeller,
				deleted: values.state === "ACTIVE" ? false : true,
				saleDepartments: saleDepartments?.map((i) => ({ id: i.value })),
				distributors: newDistributor,
				contactEmail: values.contactEmail,
				contactFirstName: values.contactFirstName,
				contactLastName: values.contactLastName,
				contactPhone: `${values.phoneAreaCode}${values.phone}`,
				megaDistributorId: selectedMegaDistributor
					? selectedMegaDistributor.value
					: null,
				activeBannerStore: activeBannerStore,
				typeOfSale: values.typeOfSale,
				generalDiscount: values.generalDiscount,
				isChain: activeIsChain && !isSeller,
				megaDistributorCode: isSeller ? values.megaDistributorCode : null,
				region: !isSeller ? `ARG-${idProvincia}-${localidad.value}` : null,
			};

			const formData = new FormData();
			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);
			photoGallery.forEach((f) => {
				formData.append("files", f.originFileObj);
			});
			formData.append("data", JSON.stringify(postData));

			await create_client(formData);

			openNotificationWithIcon(TYPE.SUCCESS, "El cliente se creó con éxito");
			navigate(`/admin/clients`);
		} catch (error) {
			console.log(error);

			const errors = Object.entries(error?.response?.data?.errors);
			openNotificationWithIcon(
				TYPE.ERROR,
				errors.map((e) => `${e[1]} `).concat()
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleClientTypeChange = (value) => {
		if (value === "Laboratorio") {
			setIsSeller(true);
		} else if (value === "Farmacia") {
			setIsSeller(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleMegaDistributorSelect = (value, obj) => {
		setSelectedMegaDistributor(obj);
	};

	const handlePhotoGalleryChange = (values) => {
		setPhotoGallery(values);
	};

	const handleChange = (e) => {
		setActiveBannerStore(e.target.checked);
	};

	const handleTypeOfSaleChange = (value) => {
		setTypeOfSaleOption(value);
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido completados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/clients/");
			},
		});
	};

	const handleProvinciasSelect = (value, object) => {
		setProvincia(object);
		setIdProvincia(value);
	};

	const handleLocalidadSelect = (value, objectList) => {
		setLocalidad(objectList);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/clients">Clientes</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Crear Cliente</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Form
					form={form}
					name="create-client"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
				>
					<InnerForm>
						<LeftSection>
							<SectionData>
								<Row gutter={GUTTER}>
									<Col span={6}>
										<Form.Item
											name="file"
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Logo del Cliente
												</label>
											}
										>
											<UploadFileImage
												onChange={handleImageChange}
												urlImage={imageUrl}
												imageHeight="100%"
												imageWidth="100%"
											/>
										</Form.Item>
									</Col>
									<Col span={16}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Nombre
												</label>
											}
											name="name"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(
														/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
													),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input placeholder="Nombre" />
										</Form.Item>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Tipo de cliente
												</label>
											}
											name="client"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Select
												allowClear={true}
												placeholder="Tipo de cliente"
												onChange={handleClientTypeChange}
											>
												<Option key="tipo_1" value="Laboratorio">
													Laboratorio
												</Option>
												<Option key="tipo_2" value="Farmacia">
													Farmacia
												</Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={GUTTER}>
									<Col span={6}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													CUIT
												</label>
											}
											name="cuit"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													len: 11,
													message: "El cuit debe de ser de 11 digitos",
												},
												{
													pattern: new RegExp(/^[0-9\s]*$/),
													message: "Solo numeros positivos",
												},
											]}
										>
											<Input placeholder="Cuit" />
										</Form.Item>
									</Col>
									<Col span={11}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Dirección
												</label>
											}
											name="address"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input placeholder="Dirección" />
										</Form.Item>
									</Col>
									<Col span={5}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Código Postal
												</label>
											}
											name="postalCode"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Input placeholder="Código Postal" />
										</Form.Item>
									</Col>
								</Row>

								{/* <Row gutter={GUTTER}>
								<Col span={11}>
									<Form.Item
										label={
											<label
												style={{ color: COLORS.Primary, fontWeight: "600" }}
											>
												GLN Anmat
											</label>
										}
										name="glnAnmat"
										rules={[
											{
												required: true,
												message: "Este campo es obligatorio",
											},
										]}
									>
										<Input placeholder="GLN Anmat" />
									</Form.Item>
								</Col>
							</Row> */}

								<Row gutter={GUTTER}>
									<Col span={11}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Nombre del representante
												</label>
											}
											name="contactFirstName"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input placeholder="Nombre del representante" />
										</Form.Item>
									</Col>
									<Col span={11}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Apellido del representante
												</label>
											}
											name="contactLastName"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input placeholder="Apellido del representante" />
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={GUTTER}>
									<Col span={11}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Correo de Contacto
												</label>
											}
											name="contactEmail"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Input placeholder="Correo de Contacto" />
										</Form.Item>
									</Col>
									<Col span={11}>
										<PhoneInput
											form={form}
											size="default"
											label="Teléfono de Contacto"
											labelStyle={{
												color: COLORS.Primary,
												fontWeight: "600",
											}}
										/>
									</Col>
								</Row>

								{!isSeller && (
									<Row gutter={GUTTER}>
										<Col span={11}>
											<Form.Item
												label="Provincia"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												{localidad !== undefined ? (
													<Select value={provincia} disabled>
														<Select.Option
															key={provincia?.value}
															value={provincia?.value}
														>
															{provincia?.children}
														</Select.Option>
													</Select>
												) : (
													<Select
														onChange={handleProvinciasSelect}
														value={provincia}
													>
														{listadoProvincias
															?.sort((a, b) => {
																const titleA = a.name.toLowerCase();
																const titleB = b.name.toLowerCase();
																if (titleA < titleB) {
																	return -1;
																}
																if (titleA > titleB) {
																	return 1;
																}

																return 0;
															})
															.map((p) => (
																<Select.Option key={p.id} value={p.id}>
																	{p.name}
																</Select.Option>
															))}
													</Select>
												)}
											</Form.Item>
										</Col>
										<Col span={11}>
											<Form.Item
												label={provincia ? "Localidad" : ""}
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												{provincia && listadoLocalidades ? (
													<Select
														allowClear={true}
														placeholder="Elegir Localidad"
														onClear={() => setLocalidad(undefined)}
														onChange={handleLocalidadSelect}
														value={localidad}
													>
														{idProvincia &&
															listadoLocalidades[0]?.localities
																.sort((a, b) => {
																	const titleA = a.locality.title.toLowerCase();
																	const titleB = b.locality.title.toLowerCase();
																	if (titleA < titleB) {
																		return -1;
																	}
																	if (titleA > titleB) {
																		return 1;
																	}

																	return 0;
																})
																.map((p) => (
																	<Select.Option
																		key={p.locality.id}
																		value={p.locality.id}
																	>
																		{p.locality.title}
																	</Select.Option>
																))}
													</Select>
												) : (
													""
												)}
											</Form.Item>
										</Col>
									</Row>
								)}

								<Row gutter={GUTTER}>
									<Col span={22}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Rubros asociados
												</label>
											}
										>
											<DebounceSelect
												fetchCallback={fetch_sale_departments}
												placeholder="Buscar rubro"
												onValuesChange={setSaleDepartments}
												initialValues={saleDepartments}
											/>
										</Form.Item>
									</Col>
								</Row>
							</SectionData>
							<SectionData>
								<Typo type="primary" level={5}>
									Selección de Droguerías
								</Typo>
								<Row>
									<Col span={22}>
										<Form.Item name="distributors">
											<DistributorsSelector
												onChange={setDistributors}
												initialValues={distributors}
												noBuyer={isSeller}
											/>
										</Form.Item>
									</Col>
								</Row>
							</SectionData>
						</LeftSection>
						<RightSection>
							<PublishSettings>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Typo level={5} type="primary">
										Publicación
									</Typo>
									<Form.Item
										label={
											<label
												style={{ color: COLORS.Primary, fontWeight: "600" }}
											>
												Estado del cliente
											</label>
										}
										name="state"
										rules={[
											{
												required: true,
												message: "Este campo es obligatorio",
											},
										]}
									>
										<Select allowClear={true} placeholder="Estado del cliente">
											{states.map((item) => (
												<Option key={item.id} value={item.id}>
													{item.name}
												</Option>
											))}
										</Select>
									</Form.Item>

									{isSeller && (
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Distribuidor asociado
												</label>
											}
											rules={[]}
										>
											<Select
												allowClear={true}
												placeholder="Seleccione un Distribuidor"
												onChange={handleMegaDistributorSelect}
												defaultValue={selectedMegaDistributor}
											>
												{megaDistributors?.map((item) => (
													<Option key={item.id} value={item.id}>
														{item.name}
													</Option>
												))}
											</Select>
										</Form.Item>
									)}

									{isSeller && (
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Codigo de Distribuidor Asociado
												</label>
											}
											name="megaDistributorCode"
											rules={[]}
										>
											<Input placeholder="Código distribuidor" />
										</Form.Item>
									)}

									{!isSeller && (
										<Form.Item>
											<Checkbox
												onChange={handleIsChain}
												checked={activeIsChain}
											>
												<Typo type="secondary" level={6}>
													Es una cadena con sucursales
												</Typo>
											</Checkbox>
										</Form.Item>
									)}

									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
											block
										>
											Crear cliente
										</SignInTemplate.AntdButton>
									</Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										loading={submitting}
										onClick={handleBack}
										block
									>
										<Typo type="danger" level={6}>
											Volver sin guardar
										</Typo>
									</SignInTemplate.AntdButton>
								</Space>
							</PublishSettings>
							{isSeller && (
								<SalesData>
									<Typo type="primary" level={5}>
										Selección de valores
									</Typo>
									<Container>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Tipo de venta
												</label>
											}
											name="typeOfSale"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Select
												allowClear={false}
												placeholder="Tipo de venta"
												onChange={handleTypeOfSaleChange}
											>
												<Option key="tipo_1" value="PVP">
													PVP{" "}
													<Typo type="muted">(Precio de Venta Público)</Typo>
												</Option>
												<Option key="tipo_2" value="PSD">
													PSD{" "}
													<Typo type="muted">(Precio Sugerido Droguería)</Typo>
												</Option>
											</Select>
										</Form.Item>
										<Form.Item
											label={
												<label
													style={{
														color: COLORS.Primary,
														fontWeight: "600",
														whiteSpace: "nowrap",
													}}
												>
													Descuento general
												</label>
											}
											name="generalDiscount"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Input
												type="number"
												min="0.00"
												step="0.01"
												suffix={
													<Typo type="muted" level={6}>
														%
													</Typo>
												}
												style={{ width: 100 }}
											/>
										</Form.Item>
										{typeOfSaleOption && typeOfSaleOption === "PVP" && (
											<Typo type="muted" level={8}>
												Para PVP el descuento general suele ser 31,03%
											</Typo>
										)}
									</Container>
								</SalesData>
							)}
							{isSeller && (
								<StoreData>
									<Typo type="primary" level={5}>
										Imágenes para la Tienda
									</Typo>
									<ImageGallery
										data={photoGallery}
										onChange={handlePhotoGalleryChange}
									/>
									<Form.Item>
										<Checkbox
											onChange={handleChange}
											checked={activeBannerStore}
										>
											<Typo type="secondary" level={6}>
												Banner Activos
											</Typo>
										</Checkbox>
									</Form.Item>
								</StoreData>
							)}
						</RightSection>
					</InnerForm>
				</Form>
			</Main.Body>
		</Main>
	);
}
