import styled from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";
import { Modal, Steps } from "antd";

export const InnerForm = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const DataContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: ${COLORS.White};
	width: 100%;
	padding: 20px;
	border-radius: 20px;
`;

export const InfoContainer = styled.div`
	background-color: white;
	border-radius: 10px;
	padding: 20px;
`;

export const AntdModal = styled(Modal)`
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
	}
`;
export const Section = styled.div`
	display: flex;
	width: 100%;
	gap: 10px;
	padding: 10px;
	flex-wrap: wrap;
`;
