import axiosInstance from "../axiosInstance";

const CONTROLLER = "Emails";

const create_email = async (data) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, data);
	return response.data;
};

const update_distributor_email = async (id, emailData) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, emailData);
	return response.data;
};

const delete_distributor_email = async (id) => {
	const response = await axiosInstance.delete(
		`${CONTROLLER}/DeleteEmail/${id}`
	);
	return response.data;
};

const get_emails_by_distributorId = async (distributorId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetByDistributorId?distributorId=${distributorId}`
	);
	return response.data;
};

export {
	create_email,
	update_distributor_email,
	delete_distributor_email,
	get_emails_by_distributorId,
};
