import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Space, Radio, Dropdown, Menu, Modal } from "antd";
import {
	CloseCircleOutlined,
	InfoCircleOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons";
import {
	ActionButton,
	AntdRadioButton,
	Filters,
	Inner,
	RadioOptions,
} from "./styles";
import LoadingHeader from "./components/LoadingHeader";
import { COLORS, Typo } from "../../../../layout/theme";
import DataTable from "../../../../common/data-table/DataTable";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
	get_presentations_types_header_info,
	get_presentations_types_page,
	update_presentations_types_state,
} from "../../../../../api/endpoints/productpresentations";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import Main from "../../../../layout/main/Main";

const { confirm } = Modal;

export default function ProductPresentationsList() {
	const navigate = useNavigate();
	const [headerInfo, setHeaderInfo] = useState(null);
	const [data, setData] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [key, setKey] = useState("null");

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Presentacion</div>,
			dataIndex: "name",
			render: (value, record) => (
				<Link
					to={`/admin/presentations/${key.id}`}
					onMouseOver={() => setKey(record)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Link>
			),
		},
		// {
		// 	title: () => <div style={{ color: COLORS.White }}>Unidad</div>,
		// 	dataIndex: "unit",
		// 	render: (value, record) => (
		// 		<Typo type="muted" fontWeight={600} fontSize={14}>
		// 			{value.name}
		// 		</Typo>
		// 	),
		// },
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={!r.deleted ? menu : menuInactive}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/presentations/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleDelete(key)}>Desactivar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/presentations/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleActivate(key)}>Reactivar</Typo>,
				},
			]}
		/>
	);

	const fetch_products_type = async (page, state, search) => {
		const res = await get_presentations_types_page(search, state, page);
		setData({
			...res,
			items: res.items?.map((r) => ({
				...r,
				key: r.id,
			})),
		});
	};

	const fetch_products_type_header = async () => {
		const res = await get_presentations_types_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_products_type_header();
	}, [state]);

	useEffect(() => {
		fetch_products_type(1, state, "");
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_products_type(1, state, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleDelete = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar esta Presentación?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "El cambio se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				deleteMarketProductPresentation(key);
			},
		});
	};

	const deleteMarketProductPresentation = async (id) => {
		const _state = { id: id, state: "DELETED" };
		await update_presentations_types_state(id, _state);
		fetch_products_type(1, state, "");
		fetch_products_type_header();
	};

	const handleActivate = (key) => {
		confirm({
			title: "¿Seguro que quiere reactivar esta Presentación?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El cambio se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				activateTemplate(key);
			},
		});
	};

	const activateTemplate = async (id) => {
		const _state = { id: id, state: "ACTIVE" };
		await update_presentations_types_state(id, _state);
		fetch_products_type(1, state, "");
		fetch_products_type_header();
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Presentaciones</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Inner>
					<Filters>
						{!headerInfo ? (
							<LoadingHeader />
						) : (
							<Space size="large">
								<AntdSearch
									width={400}
									allowClear
									placeholder="Buscar presentación"
									onChange={debouncedEventHandler}
								/>
								<DropShadow type="drop">
									<SignInTemplate.AntdButton
										type="primary"
										bg="Primary"
										color="White"
										icon={<PlusCircleOutlined />}
										onClick={() => navigate("/admin/presentations/create")}
										style={{ width: "200px", fontWeight: "400" }}
									>
										Nueva Presentacion
									</SignInTemplate.AntdButton>
								</DropShadow>
							</Space>
						)}
					</Filters>
					<RadioOptions>
						<Radio.Group
							value={state}
							onChange={(e) => setState(e.target.value)}
						>
							<AntdRadioButton value="ACTIVE">
								Activos ({headerInfo?.totalActive})
							</AntdRadioButton>
							<AntdRadioButton value="DELETED">
								Inactivos ({headerInfo?.totalDisabled})
							</AntdRadioButton>
						</Radio.Group>
					</RadioOptions>
					<DataTable
						data={data}
						fetch={fetch_products_type}
						columns={columns}
					/>
				</Inner>
			</Main.Body>
		</Main>
	);
}
