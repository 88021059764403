import React, { useEffect } from "react";
import { COLORS, Typo } from "../../../../../layout/theme";
import { AntdButton, Container, Section } from "./styles";
import AddProducts from "../add-products/AddProducts";
import { InfoCircleOutlined } from "@ant-design/icons";

export default function ProductsToCartStep({
	clientInfo,
	templateProducts,
	setTemplateProducts,
	user,
	selectedClient,
	clientId,
	step,
	handleOrderToCart,
	handleBack,
	handleBackToDistributorStep,
}) {
	return (
		<Section>
			<Container>
				<Typo type="primary" level={6}>
					Productos del pedido para farmacia{" "}
					<Typo type="secondary" level={6}>
						{clientInfo?.clientName}
					</Typo>
				</Typo>
				<div style={{ display: "flex", gap: 10 }}>
					{step === 3 && templateProducts && templateProducts.length > 0 && (
						<AntdButton
							color={COLORS.Danger}
							backgroundColor={COLORS.White}
							borderColor={COLORS.DarkGrey}
							hoverColor={COLORS.Danger}
							onClick={handleBack}
						>
							Volver sin enviar
						</AntdButton>
					)}
					{step === 3 && templateProducts && templateProducts.length > 0 && (
						<AntdButton
							color={COLORS.Secondary}
							backgroundColor={COLORS.White}
							borderColor={COLORS.Secondary}
							hoverColor={COLORS.Secondary}
							onClick={handleBackToDistributorStep}
						>
							<InfoCircleOutlined style={{ color: COLORS.Secondary }} /> Ver
							droguerías asociadas
						</AntdButton>
					)}
					{step === 3 &&
						templateProducts &&
						templateProducts.length > 0 &&
						templateProducts.findIndex((p) => p.quantity !== 0) > -1 && (
							<AntdButton onClick={handleOrderToCart}>
								Enviar pedido al carro
							</AntdButton>
						)}
				</div>
			</Container>
			<AddProducts
				templateProducts={templateProducts}
				setTemplateProducts={setTemplateProducts}
				userId={user.uid}
				clientId={selectedClient}
				laboratoryId={clientId}
			/>
		</Section>
	);
}
