import { Table } from "antd";
import styled from "styled-components/macro";
import { COLORS } from "../../../../../layout/theme";

export const SmartMain = styled.div`
  padding: 30px 10px;
  background-color: ${COLORS.LightGrey};
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 40px;
  margin-top: 30px;

  @media only screen and (min-width: 760px) and (max-width: 1060px) {
    flex-direction: column;
  }
`;
export const AntdTable = styled(Table)`
  & .noBackground,
  & .noBackground:hover {
    background-color: ${COLORS.LightGrey} !important;
    border: none;
  }

  .ant-table-thead > tr > th {
    background: ${COLORS.LightGrey};
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: ${COLORS.White};

    div {
      color: ${COLORS.White};
    }
  }

  thead {
    border-color: ${COLORS.White};
    border: 1px solid ${COLORS.White};
  }
  .ant-table-cell {
    border: 3px solid rgba(243, 244, 246, 1);
`;

export const SmartBody = styled.div`
  padding: 0px 10px;
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const CellContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 10px;
`;

export const RadioContainer = styled.div`
  width: 20px;
  height: 100%;
  & input[type="radio"] {
    accent-color: ${COLORS.Success};
    border: 0;
    transform: scale(2);
    color: red;
  }
`;

export const FlowtingButton = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${COLORS.Primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 250ms;

  &:hover {
    background-color: ${COLORS.Secondary};
  }

  &:active {
    transform: scale(1.1);
  }
`;

export const FlowtingContainer = styled.div`
  display: flex;
  width: 310px;
  align-items: center;
  position: sticky;
  gap: 20px;
  bottom: 90px;
  right: 0%;
  justify-content: end;
  cursor: pointer;

  &:hover > span {
    color: ${COLORS.Secondary};
  }
  &:hover > div {
    background-color: ${COLORS.Secondary};
  }
`;

export const HeadersContainer = styled.div`
  display: flex;
  width: 99.65%;
  height: 90px;
  padding: 20px 0px;
  background-color: ${COLORS.White};
  align-self: center;
  position: sticky;
  top: 0px;
  z-index: 150;
  border-bottom: 2px solid ${COLORS.LightGrey};
  & > .border {
    border-right: 3px solid ${COLORS.LightGrey};
    width: 100%;
    height: 100%;
  }
`;

export const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const QuantityItem = styled(GridItem)`
  flex: 1 1 170px;
`;
export const SelectedItem = styled(GridItem)`
  width: 100%;
  padding: 0px auto;
  flex: 1 1 20%;
`;
export const RelatedItem = styled(GridItem)`
  flex: 1 1 80%;
`;

export const GridSeparator = styled.div`
  width: 3px;
  height: 50px;
  background-color: ${COLORS.LightGrey};
  position: absolute;
  right: -2px;
`;

export const FloatingItem = styled.div`
  position: absolute;
  right: 6px;
  bottom: 17px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 250px;
  gap: 2px;
`;
export const FloatingTextContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: end;
  align-items: end;
  flex-direction: row;
  width: 185px;
`;

export const NonPercentage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BorderPercentage = styled.div`
  border-radius: 8px;
  border: 2px solid ${COLORS.Success};
  padding: 0px 10px;
  width: 83px;
  display: flex;
  justify-content: center;
`;

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const QuantityContainerTop = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`;

export const QuantityContainerBottom = styled.div`
  cursor: pointer;
`;

export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 240px;
  justify-content: center;
`;
