import { Skeleton, Row, Col, Space } from "antd";
import {
	Inner,
	ProductData,
	PublishContainer,
	PublishSettings,
	TableHeader,
} from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
	return (
		<Inner>
			<ProductData>
				<Space direction="vertical" size="middle" style={{ display: "flex" }}>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={22}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 250, marginBottom: -10, marginTop: -30 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={8}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 400, marginBottom: -10, marginTop: -30 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
						<Col span={6}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 350, marginBottom: -10, marginTop: -30 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={22}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 400, marginBottom: -10, marginTop: -30 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={22}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 400, marginBottom: -10, marginTop: -30 }}
							/>
							<Skeleton.Input active={true} block style={{ height: 100 }} />
						</Col>
					</Row>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 400, marginBottom: -10, marginTop: -30 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={8}>
							<Skeleton.Image active={true} block />
						</Col>
					</Row>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 200, marginBottom: -10, marginTop: -30 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={8}>
							<Skeleton.Image active={true} block />
						</Col>
					</Row>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={6}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 200, marginBottom: -10, marginTop: -30 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
						<Col span={6}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 300, marginBottom: -10, marginTop: -30 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={6}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 200, marginBottom: -10, marginTop: -30 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={24}>
							<TableHeader></TableHeader>
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 0, marginBottom: -10, marginTop: -9 }}
							/>
						</Col>
						<Col span={6}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{
									width: 550,
									marginBottom: -10,
									marginTop: -10,
									marginLeft: -50,
								}}
							/>
						</Col>
						<Col span={4}></Col>
						<Col span={8}>
							<Skeleton.Input
								active
								style={{
									width: 250,
									height: 35,
									marginTop: -5,
									marginLeft: -150,
								}}
							/>
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 0, marginBottom: -10, marginTop: -9 }}
							/>
						</Col>
						<Col span={6}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{
									width: 550,
									marginBottom: -10,
									marginTop: -10,
									marginLeft: -50,
								}}
							/>
						</Col>
						<Col span={4}></Col>
						<Col span={8}>
							<Skeleton.Input
								active
								style={{
									width: 250,
									height: 35,
									marginTop: -5,
									marginLeft: -150,
								}}
							/>
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 0, marginBottom: -10, marginTop: -9 }}
							/>
						</Col>
						<Col span={6}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{
									width: 550,
									marginBottom: -10,
									marginTop: -10,
									marginLeft: -50,
								}}
							/>
						</Col>
						<Col span={4}></Col>
						<Col span={8}>
							<Skeleton.Input
								active
								style={{
									width: 250,
									height: 35,
									marginTop: -5,
									marginLeft: -150,
								}}
							/>
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 0, marginBottom: -10, marginTop: -9 }}
							/>
						</Col>
						<Col span={6}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{
									width: 550,
									marginBottom: -10,
									marginTop: -10,
									marginLeft: -50,
								}}
							/>
						</Col>
						<Col span={4}></Col>
						<Col span={8}>
							<Skeleton.Input
								active
								style={{
									width: 250,
									height: 35,
									marginTop: -5,
									marginLeft: -150,
								}}
							/>
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 0, marginBottom: -10, marginTop: -9 }}
							/>
						</Col>
						<Col span={6}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{
									width: 550,
									marginBottom: -10,
									marginTop: -10,
									marginLeft: -50,
								}}
							/>
						</Col>
						<Col span={4}></Col>
						<Col span={8}>
							<Skeleton.Input
								active
								style={{
									width: 250,
									height: 35,
									marginTop: -5,
									marginLeft: -150,
								}}
							/>
						</Col>
					</Row>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={22}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 500, marginBottom: -10, marginTop: -30 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
				</Space>
			</ProductData>

			<PublishSettings>
				<Space direction="vertical" size="middle" style={{ display: "flex" }}>
					<PublishContainer>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Skeleton
									active={true}
									paragraph={{ rows: 0 }}
									style={{ width: 250, marginBottom: 20, marginTop: -20 }}
								/>
							</Col>
						</Row>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Skeleton
									active={true}
									paragraph={{ rows: 0 }}
									style={{ width: 300, marginBottom: -10, marginTop: -30 }}
								/>
								<Skeleton.Input
									active={true}
									size="default"
									style={{ width: 380 }}
								/>
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Skeleton
									active={true}
									paragraph={{ rows: 0 }}
									style={{ width: 300, marginBottom: -10, marginTop: 20 }}
								/>
								<Skeleton.Input
									active={true}
									size="default"
									style={{ width: 380 }}
								/>
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Skeleton
									active={true}
									paragraph={{ rows: 0 }}
									style={{ width: 250, marginBottom: 0, marginTop: 30 }}
								/>
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={5}>
								<Skeleton.Button active={true} style={{ width: 30 }} />
							</Col>
							<Col span={12}>
								<Skeleton
									active={true}
									paragraph={{ rows: 0 }}
									style={{ width: 300, marginBottom: 0, marginTop: -9 }}
								/>
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Skeleton
									active={true}
									paragraph={{ rows: 0 }}
									style={{ width: 400, marginBottom: -10, marginTop: -10 }}
								/>
								<Skeleton.Input
									active={true}
									size="default"
									style={{ width: 380 }}
								/>
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Skeleton
									active={true}
									paragraph={{ rows: 0 }}
									style={{ width: 400, marginBottom: -10, marginTop: 20 }}
								/>
								<Skeleton.Input
									active={true}
									size="default"
									style={{ width: 380 }}
								/>
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Skeleton
									active={true}
									paragraph={{ rows: 0 }}
									style={{ width: 400, marginBottom: -10, marginTop: 20 }}
								/>
								<Skeleton.Input
									active={true}
									size="default"
									style={{ width: 200, height: 80 }}
								/>
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Skeleton
									active={true}
									paragraph={{ rows: 0 }}
									style={{ width: 400, marginBottom: -10, marginTop: 20 }}
								/>
								<Skeleton.Input
									active={true}
									size="default"
									style={{ width: 380 }}
								/>
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Skeleton
									active={true}
									paragraph={{ rows: 0 }}
									style={{ width: 400, marginBottom: -10, marginTop: 20 }}
								/>
								<Skeleton.Input
									active={true}
									size="default"
									style={{ width: 380 }}
								/>
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Skeleton.Input
									active={true}
									size="large"
									style={{ width: 380, marginTop: 50, borderRadius: 5 }}
								/>
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
					</PublishContainer>
				</Space>
			</PublishSettings>
		</Inner>
	);
}
