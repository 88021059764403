import styled from "styled-components";
import { Input } from "antd";
import { COLORS } from "../../layout/theme";

export const OptionInner = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const OptionLabel = styled.div`
	color: ${COLORS.Secondary};
`;

export const SearchBar = styled(Input)`
	border-radius: 20px;

	@media only screen and (min-width: 760px) and (max-width: 960px) {
		width: auto !important;
	}

	@media only screen and (min-width: 970px) and (max-width: 1012px) {
		width: 230px !important;
	}

	@media only screen and (min-width: 1013px) and (max-width: 1299px) {
		width: 320px !important;
	}

	@media only screen and (min-width: 1250px) and (max-width: 1370px) {
		width: 400px !important;
	}

	@media only screen and (min-width: 1371px) and (max-width: 2600px) {
		width: 450px !important;
	}
`;
