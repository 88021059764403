import { Col, Form, Input, Row } from "antd";
import { COLORS } from "../../../../../../layout/theme";
import OrderTable from "./order-table/OrderTable";
import { InfoTableContainer, Inner } from "./styles";

const GUTTER = 20;

export default function OrderCard({ form, initialValues }) {
	const formValues = Form.useWatch([], form);

	return (
		<Inner>
			<Row gutter={GUTTER}>
				<Col span={12}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Enviado a droguería
							</label>
						}
						name={["orders", "orderDistributorName"]}
					>
						<Input disabled />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								# de pedido
							</label>
						}
						name={["orders", "orderNumId"]}
					>
						<Input disabled />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Usuario de pedido
							</label>
						}
						name={["orders", "orderUserName"]}
					>
						<Input disabled />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={GUTTER}>
				<Col span={12}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Correo del usuario
							</label>
						}
						name={["orders", "orderUserEmail"]}
					>
						<Input disabled />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Subtotal
							</label>
						}
						name={["orders", "subTotal"]}
					>
						<Input disabled prefix="$" />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Total
							</label>
						}
						name={["orders", "total"]}
					>
						<Input disabled prefix="$" />
					</Form.Item>
				</Col>
			</Row>
			<InfoTableContainer>
				<OrderTable data={initialValues} />
			</InfoTableContainer>
		</Inner>
	);
}
