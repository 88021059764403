import React, { useState } from "react";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import Image from "../../../common/image/Image";
import MarketLogo from "../../../../assets/images/Onetransfer-logo.png";
import DropShadow from "../../../common/drop-shadow/DropShadow";
import { Col, Form, Input, Row } from "antd";
import { Typo } from "../../../layout/theme";
import { InputSector } from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import { useAuth } from "../../../../contexts/authContext";
import { useNavigate } from "react-router-dom";

const GUTTER = 10;

export default function ResetPassword() {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const oobCode = params.get("oobCode");
	const navigate = useNavigate();

	const { newPassword, login } = useAuth();

	const onFinish = async (values) => {
		try {
			setSubmitting(true);
			await newPassword(oobCode, values.password);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Listo, ya modificó su contraseña"
			);
			navigate("/");
			window.location.reload();
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"No se pudo modificar su contraseña"
			);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<SignInTemplate>
			<SignInTemplate.Main>
				<SignInTemplate.Top>
					<SignInTemplate.Title>Resetear Contraseña</SignInTemplate.Title>
					<SignInTemplate.Desc>Ingrese su nueva contraseña</SignInTemplate.Desc>
				</SignInTemplate.Top>

				<SignInTemplate.Body>
					<DropShadow>
						<SignInTemplate.Logo>
							<Image
								src={MarketLogo}
								alt="Logo FarmaTransfer"
								height="45px"
								width="220px"
							/>
						</SignInTemplate.Logo>
						<SignInTemplate.Formulario>
							<Form
								form={form}
								name="reset-password"
								onFinish={onFinish}
								requiredMark={false}
								layout="vertical"
								wrapperCol={{ span: 24 }}
							>
								<Row gutter={GUTTER}>
									<Col span={6} offset={9} align="center">
										<Typo level={5}>Ingrese su Nueva Contraseña</Typo>
									</Col>
								</Row>
								<SignInTemplate.Break height="15px" />
								<Row gutter={GUTTER} align="middle">
									<InputSector>
										<Col span={5} align="center">
											<SignInTemplate.AntdFormItem
												name="password"
												password
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														max: 20,
														message: "Maximo 20 caracteres",
													},
													{
														min: 6,
														message: "Minimo 6 caracteres",
													},
												]}
											>
												<Input
													type="password"
													size="large"
													placeholder="Contraseña"
												/>
											</SignInTemplate.AntdFormItem>
										</Col>

										<Col span={5}>
											<SignInTemplate.AntdFormItem
												name="confirmPassword"
												password
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														min: 6,
														message: "Minimo 6 caracteres",
													},
													{
														max: 20,
														message: "Maximo 20 caracteres",
													},
													({ getFieldValue }) => ({
														validator(rule, value) {
															if (
																!value ||
																getFieldValue("password") === value
															) {
																return Promise.resolve();
															}
															return Promise.reject(
																"No coinciden las contraseñas"
															);
														},
													}),
												]}
											>
												<Input
													type="password"
													size="large"
													placeholder="Repetir contraseña"
												/>
											</SignInTemplate.AntdFormItem>
										</Col>
										<Col align="center" span={1}>
											<SignInTemplate.AntdFormItem>
												<SignInTemplate.AntdButton
													color="White"
													bg="Primary"
													type="primary"
													htmlType="submit"
													loading={submitting}
												>
													Restablecer Contraseña
												</SignInTemplate.AntdButton>
											</SignInTemplate.AntdFormItem>
										</Col>
									</InputSector>
								</Row>
								<SignInTemplate.Break height="15px" />
							</Form>
						</SignInTemplate.Formulario>
					</DropShadow>
				</SignInTemplate.Body>
			</SignInTemplate.Main>
		</SignInTemplate>
	);
}
