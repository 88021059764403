import { LoadingOutlined } from "@ant-design/icons";
import { Table, Spin } from "antd";
import { ContainerDiv, SpinnerContainer } from "./styles";

const antIcon = (
	<LoadingOutlined
		style={{
			fontSize: 24,
		}}
		spin
	/>
);

export default function ProductsTable({ data, columns, loading }) {
	return (
		<ContainerDiv>
			<hr style={{ opacity: 0.2 }} />
			{loading ? (
				<SpinnerContainer>
					<Spin indicator={antIcon} size="large" style={{ scale: 2 }} />
				</SpinnerContainer>
			) : (
				<Table
					showHeader={true}
					columns={columns}
					dataSource={data}
					pagination={false}
					scroll={{
						y: 450,
					}}
				/>
			)}
		</ContainerDiv>
	);
}
