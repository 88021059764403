import React, { useState } from "react";
import Image from "../../common/image/Image";
import { COLORS, Typo } from "../../layout/theme";
import {
	ButtonContainer,
	CenterData,
	ContainerModal,
	ImageContainer,
	Inner,
	ProductContainer,
	ProductSection,
	RightData,
	SelectData,
	TopCenter,
} from "./styles";
import moment from "moment";
import { Modal } from "antd";
import { RightCircleOutlined } from "@ant-design/icons";
import FallBackImage from "../../../assets/images/Fallback.png";

export default function EventCardContainer({ item, onSelect }) {
	const [isModalVisible, setIsModalVisible] = useState();

	return (
		<Inner conditional={!!onSelect}>
			<ImageContainer>
				<Image
					src={item.photoUrl ?? FallBackImage}
					width={120}
					borderRadius="10px"
					preview={true}
				/>
			</ImageContainer>
			<CenterData>
				<TopCenter>
					<Typo level={4} type="primary">
						{item?.title}
					</Typo>
					<Typo level={5} type="secondary">
						{item?.client?.name}
					</Typo>
					<Typo
						level={6}
						type="terciary"
						onClick={() => setIsModalVisible(true)}
						style={{ cursor: "pointer" }}
					>
						Productos en evento: {item?.totalProducts}
					</Typo>
					<Typo
						level={6}
						type="muted"
						onClick={() => setIsModalVisible(true)}
						style={{ cursor: "pointer" }}
					>
						{item?.province?.title}
						{" - "}
						{item?.locality?.title}
					</Typo>
				</TopCenter>
			</CenterData>
			<RightData>
				<Typo type="muted">
					Fecha de inicio: {moment(item.publishFrom).format("DD MMMM YYYY")}
				</Typo>
				<Typo type="muted">
					Fecha de cierre: {moment(item.publishTo).format("DD MMMM YYYY")}
				</Typo>
			</RightData>
			{onSelect && (
				<SelectData>
					<ButtonContainer>
						<RightCircleOutlined
							style={{ color: COLORS.Primary, fontSize: 30 }}
							onClick={() => onSelect(item)}
						/>
					</ButtonContainer>
				</SelectData>
			)}
			<Modal
				title={
					<Typo level={4} type="primary">
						Productos
					</Typo>
				}
				open={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}
				width={900}
				bodyStyle={{ height: "400px", overflowY: "scroll" }}
				centered={true}
				footer={null}
			>
				<ContainerModal>
					{item?.products?.map((p, idx) => (
						<ProductContainer key={idx}>
							<ProductSection style={{ flex: "1 1 10%" }}>
								<Image src={p.productPhotoUrl} height={40} />
							</ProductSection>
							<ProductSection>
								<Typo level={7} type="primary">
									{p.productTitle}
								</Typo>
								<Typo level={7} type="muted">
									{p.productPresentation}
								</Typo>
							</ProductSection>
							<ProductSection>
								<Typo level={7} type="primary">
									Descuento PSL
								</Typo>
								<Typo level={7} type="muted">
									- {p.productDiscount} %
								</Typo>
							</ProductSection>
							<ProductSection>
								<Typo level={7} type="primary">
									Descuento Por cantidad
								</Typo>
								<Typo level={7} type="muted">
									A partir de {p.productTriggerQty} unidades -{" "}
									{p.productTriggerDiscount} %
								</Typo>
							</ProductSection>
						</ProductContainer>
					))}
				</ContainerModal>
			</Modal>
		</Inner>
	);
}
