import styled from "styled-components/macro";

export const Inner = styled.div`
	display: flex;
	justify-content: center;
	margin-top: -20px;
	margin-left: 50px;
	width: 100%;
	height: 600px;

	.ant-card-bordered {
		border: none;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-wrap: nowrap;
`;
