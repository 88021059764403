import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { get_provinces } from "../../../../../../api/endpoints/region";
import { Container, InfoRegion, Section } from "./styles";

export default function UserRegion({ form }) {
	const formValues = Form.useWatch([], form);

	const [listadoProvincias, setListadoProvincias] = useState([]);
	const [selectedProvince, setSelectedProvince] = useState(null);
	const [listadoLocalities, setListadoLocalities] = useState(null);
	const [selectedLocalities, setSelectedLocalities] = useState([]);

	const [provinceDisabled, setProvinceDisabled] = useState(false);

	const fetch_region_1_data = async () => {
		const res = await get_provinces();
		setListadoProvincias(
			res
				.map((p) => ({
					id: p.id,
					name: p.title,
					localities: p.localities
						.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						}))
						.sort((a, b) => {
							const titleA = a.localityName.toLowerCase();
							const titleB = b.localityName.toLowerCase();
							if (titleA < titleB) {
								return -1;
							}
							if (titleA > titleB) {
								return 1;
							}

							return 0;
						}),
				}))
				.sort((a, b) => {
					const titleA = a.name.toLowerCase();
					const titleB = b.name.toLowerCase();
					if (titleA < titleB) {
						return -1;
					}
					if (titleA > titleB) {
						return 1;
					}

					return 0;
				})
		);
	};

	const localitiesSelect = (e) => {
		const country = "ARG";
		const province = selectedProvince;
		const localitie = e[e.length - 1];
		const fullLocalitie = `${country}-${province}-${localitie}`;
		if (selectedLocalities === null || selectedLocalities.length === 0) {
			setSelectedLocalities([fullLocalitie]);
		}
		if (
			selectedLocalities.length > 0 &&
			!selectedLocalities?.some((i) => i === fullLocalitie)
		) {
			const arr = [...selectedLocalities];
			arr.push(fullLocalitie);
			setSelectedLocalities(arr);
		}
	};

	const localitiesDeselect = (e) => {
		let arr = [...selectedLocalities];
		arr.forEach((element, i) => {
			if (element.includes(e)) {
				arr.splice(i, 1);
			}
		});
		setSelectedLocalities(arr);
	};

	const provinceSelect = (e, name) => {
		setSelectedProvince(e);
		setProvinceDisabled(false);
		setListadoLocalities(listadoProvincias.find((p) => p.id === e).localities);
		if (name !== null) {
			const fields = form.getFieldsValue();
			const { region } = fields;

			Object.assign(region[name], { locality: [] });
			Object.assign(region[name], { checkbox: false });
			form.setFieldsValue({ region });
		}
	};

	useEffect(() => {
		fetch_region_1_data();
	}, []);

	const handleCheckboxLocalities = (e, name) => {
		const fields = form.getFieldsValue();
		const { region } = fields;
		if (e.target.checked) {
			const provinceId = region[name].province;
			const provinceLocalities = listadoProvincias
				.find((p) => p.id === provinceId)
				.localities.map((e) => e.localityId);

			Object.assign(region[name], {
				locality: provinceLocalities,
			});
		} else {
			Object.assign(region[name], {
				locality: [],
			});
		}
		form.setFieldsValue({ region });
	};

	return (
		<Form.List name="region">
			{(fields, { add, remove }) => (
				<Section>
					{fields?.map(({ key, name, ...restField }) => (
						<Container key={key}>
							<Form.Item
								{...restField}
								name={[name, "province"]}
								label={<InfoRegion>Provincia</InfoRegion>}
							>
								<Select
									style={{ maxWidth: "150px" }}
									onChange={(e) => provinceSelect(e, name)}
									disabled={provinceDisabled}
								>
									{listadoProvincias?.map((e) => (
										<Select.Option key={e.id} value={e.id}>
											{e.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							{form.getFieldsValue(true).region[name] && (
								<>
									<Form.Item
										{...restField}
										name={[name, "locality"]}
										label={<InfoRegion>Localidades</InfoRegion>}
										style={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Select
											style={{
												maxWidth: "150px",
												maxHeight: "200px",
											}}
											onChange={(e) => localitiesSelect(e)}
											mode="multiple"
											onDeselect={(e) => localitiesDeselect(e)}
											allowClear
											maxTagCount={1}
										>
											{listadoProvincias
												?.find(
													(p) =>
														p.id ===
														form.getFieldsValue(true).region[name].province
												)
												?.localities.map((e) => (
													<Select.Option
														key={e.localityId}
														value={e.localityId}
													>
														{e.localityName}
													</Select.Option>
												))}
										</Select>
									</Form.Item>
									<Form.Item name={[name, "checkbox"]} valuePropName="checked">
										<Checkbox
											onChange={(e) => handleCheckboxLocalities(e, name)}
										>
											Seleccionar Todas
										</Checkbox>
									</Form.Item>
								</>
							)}

							<MinusCircleOutlined onClick={() => remove(name)} />
						</Container>
					))}
					<Form.Item>
						<Button
							type="dashed"
							onClick={() => add()}
							block
							icon={<PlusOutlined />}
						>
							Agregar Provincia{" "}
						</Button>
					</Form.Item>
				</Section>
			)}
		</Form.List>
	);
}
