import styled from "styled-components";
import { Checkbox, Table } from "antd";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	gap: 30px;
	flex-direction: column;
`;

export const TopContainer = styled.div`
	display: flex;
	gap: 30px;

	@media only screen and (min-width: 560px) and (max-width: 1424px) {
		flex-direction: column;
	}
`;
export const ProductData = styled.div`
	flex: 1 1 60%;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
export const RightContainer = styled.div`
	flex: 1 1 40%;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const PublishSettings = styled.div`
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	height: 100%;
`;
export const InfoContainer = styled.div`
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
`;

export const DistributorImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`;

export const AntdCheckbox = styled(Checkbox)``;

export const ContainerMails = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
export const AddMailContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;

export const FileStructureBackground = styled.div`
	background-color: white;
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const MailBackground = styled.div`
	background-color: white;
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const Header = styled.div`
	background-color: ${COLORS.Primary};
	color: ${COLORS.White};
	font-weight: ${WEIGHT.Bold};
	width: 100%;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ContainerColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
	gap: 10px;

  @media only screen and (min-width: 776px) and (max-width: 1114px) {
		grid-template-columns: repeat(3, 1fr);
	}

  @media only screen and (min-width: 560px) and (max-width: 775px) {
		grid-template-columns: repeat(2, 1fr);
`;

export const ColumnSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 1rem;
	width: 100%;
`;

export const SectionTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: ${WEIGHT.Black};
	gap: 10px;

	@media only screen and (min-width: 560px) and (max-width: 775px) {
		align-items: flex-start;
		flex-direction: column;
		margin-left: 15px;
		gap: 20px;
	}
`;

export const ResetButton = styled.button`
	cursor: pointer;

	&:hover {
		color: ${COLORS.Grey};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}
	color: ${COLORS.White};
	font-size: ${SIZES.level7};
	font-weight: ${WEIGHT.Black};
	background-color: ${COLORS.Grey};
	padding: 0.2em 1em;
	border: 2px solid ${COLORS.Grey};
	border-radius: 20px;
	box-shadow: 0 5px 5px #bfbfbf;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
`;
