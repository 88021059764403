import { Select, Tooltip } from "antd";
import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Image from "../../../../../common/image/Image";
import { Typo } from "../../../../../layout/theme";
import {
	AntdModal,
	AntdSelect,
	CenterData,
	CenterLeftData,
	CenterRightData,
	Container,
	ImageContainer,
	Inner,
	MoreInfo,
	RightData,
	Section,
} from "./styles";
import HotSaleSliders from "../../../../../../assets/images/PantallaHotSaleSliders.jpg";
import { BsFillCircleFill } from "react-icons/bs";
import moment from "moment";

export default function CampaignCardContainer({
	item,
	handleTypeChange,
	iconColor,
	iconText,
}) {
	const [campaignType, setCampaignType] = useState(item?.type);
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [showRegionInfoModal, setShowRegionInfoModal] = useState(false);

	const handleTypeSelect = (value) => {
		setCampaignType(value);
		handleTypeChange({ id: item.id, type: value });
	};

	const selectOptions = [
		{
			value: "SLIDERSMALL1",
			name: "Slider chico 1",
		},
		{
			value: "SLIDERMEDIUM1",
			name: "Slider Intermedio",
		},
		{
			value: "SLIDERSMALL2",
			name: "Slider chico 2",
		},
	];

	const handleClick = () => {
		setShowInfoModal(true);
	};

	const handleOk = () => {
		setShowInfoModal(false);
	};

	const handleCancel = () => {
		setShowInfoModal(false);
	};

	const handleMoreClick = () => {
		setShowRegionInfoModal(true);
	};

	const handleMoreOk = () => {
		setShowRegionInfoModal(false);
	};

	const handleMoreCancel = () => {
		setShowRegionInfoModal(false);
	};

	const region = item.campaignRegion.map((x) =>
		x.localitieName
			? x.localitieName + " ( " + x.provinceName + " ) - "
			: x.provinceName
			? "Todas las localidades ( " + x.provinceName + " ) - "
			: "Todas las localidades ( Argentina ) - "
	);

	if (!item) return <div>Loading...</div>;

	return (
		<Inner>
			<ImageContainer>
				<Tooltip title={iconText}>
					<BsFillCircleFill style={{ color: iconColor }} />
				</Tooltip>
				<Image
					src={item.photoGallery[0]?.url}
					height={120}
					borderRadius="10px"
					preview={true}
				/>
			</ImageContainer>
			<CenterData>
				<CenterLeftData>
					<Typo type="primary" level={6}>
						Nombre de Campaña:{" "}
					</Typo>
					<Typo type="primary" level={6}>
						Región:{" "}
					</Typo>
					<Typo type="primary" level={6}>
						Fecha comienzo:{" "}
					</Typo>
					<Typo type="primary" level={6}>
						Fecha finalización:{" "}
					</Typo>
				</CenterLeftData>
				<CenterRightData>
					<Typo type="muted" level={7}>
						{item.title}
					</Typo>
					<Section>
						<Typo type="muted" level={7}>
							{region.length < 2 ? region : region.slice(0, 1)}
						</Typo>
						{region.length > 1 && (
							<MoreInfo onClick={handleMoreClick}>Ver más</MoreInfo>
						)}
					</Section>
					<Typo type="muted" level={7}>
						{moment(item.publishFrom).format("DD-MM-YYYY")}{" "}
						{item.publishFrom.split("T")[1]}
					</Typo>
					<Typo type="muted" level={7}>
						{moment(item.publishTo).format("DD-MM-YYYY")}{" "}
						{item.publishTo.split("T")[1]}
					</Typo>
				</CenterRightData>
			</CenterData>
			<RightData>
				<Typo type="primary" level={7}>
					<AiOutlineInfoCircle
						style={{ height: 12, width: 12, cursor: "pointer" }}
						onClick={handleClick}
					/>{" "}
					Ubicación:
				</Typo>
				<AntdSelect onChange={handleTypeSelect} defaultValue={campaignType}>
					{selectOptions.map((x, idx) => (
						<Select.Option key={`Sliders_Select_${idx}`} value={x.value}>
							{x.name}
						</Select.Option>
					))}
				</AntdSelect>
			</RightData>
			<AntdModal
				title={`Distribución de sliders`}
				visible={showInfoModal}
				onOk={handleOk}
				onCancel={handleCancel}
				width={800}
				centered={true}
				footer={null}
				destroyOnClose={true}
			>
				<Container>
					<Typo type="primary" level={5}>
						Pantalla Hot Sale:{" "}
					</Typo>
					<Image src={HotSaleSliders} />
				</Container>
			</AntdModal>
			<AntdModal
				title={`Región de campaña ${item.campaignTitle}`}
				visible={showRegionInfoModal}
				onOk={handleMoreOk}
				onCancel={handleMoreCancel}
				width={800}
				centered={true}
				footer={null}
				destroyOnClose={true}
			>
				<Typo type="primary" level={5}>
					Regiones alcanzadas:{" "}
				</Typo>
				<div>{region}</div>
			</AntdModal>
		</Inner>
	);
}
