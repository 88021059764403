import { Button } from "antd";
import styled from "styled-components";
import { COLORS } from "../../../../../layout/theme";

export const Section = styled.div`
	background-color: white;
	border-radius: 10px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	min-height: 120px;
	padding: 1rem;
	flex-direction: column;
	gap: 10px;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;
export const AntdButton = styled(Button)`
	cursor: pointer;

	&:hover {
		color: ${(props) => (props.hoverColor ? props.hoverColor : COLORS.Primary)};
		border: 2px solid
			${(props) =>
				props.hoverBorderColor ? props.hoverBorderColor : COLORS.White};
		background-color: ${(props) =>
			props.hoverBackgroundColor ? props.hoverBackgroundColor : COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}

	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : COLORS.Primary};
	color: ${(props) => (props.color ? props.color : COLORS.White)};
	border-radius: 5px;
	box-shadow: 0 3px 3px 0 #c7c7c7;
`;
