import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Space } from "antd";
import {
	InnerForm,
	OrderData,
	LineBreak,
	HeaderContainer,
	TitleBanner,
	TitleContainer,
	SubTitleContainer,
	InfoContainer,
	InfoText,
	Inner,
	InfoTableContainer,
	InfoPriceContainer,
	InfoSubSection,
	InfoTitleTotal,
	OptionsButtonsContainer,
	Container,
	PriceContainer,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import COLORS from "../../../../layout/theme/colors/Colors";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import OrderTable from "./components/order-table/OrderTable";
import {
	get_sale_by_id,
	get_sale_by_id_with_status,
} from "../../../../../api/endpoints/sales";
import OneTransferLogo from "../../../../../assets/images/Onetransfer-logo-box.png";
import Image from "../../../../common/image/Image";
import { Typo } from "../../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../../utils/numberWithDotAndComas";
import moment from "moment";

export default function SaleDetail({ clientId }) {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [initialValues, setInitialValues] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		const fetch_sale_by_id = async () => {
			const data = await get_sale_by_id_with_status(id, clientId);
			const order = {
				id: data.order.id,
				created: moment(data.order.created).format("dddd, DD MMMM YYYY"),
				client: data.order.clientName,
				photoUrl: data.order.clientPhotoUrl,
				name: data.order.userFirstName + " " + data.order.userLastName,
				email: data.order.userEmail,
				distributorId: data.order.distributorId,
				distributorName: data.order.distributorName,
				distributorPhoto: data.order.distributorPhotoUrl,
				pointOfSaleId: data.order.pointOfSaleId,
				pointOfSaleName: data.order.pointOfSaleName,
				pointOfSaleAddress: data.order.pointOfSaleAddress,
				quantityItems: data.order.quantityItems,
				subTotal: data.order.subtotal,
				total: data.order.total,
				orderNumber: data.order.orderNumber,
				orderNumId: data.order.orderNumId,
				orderState: data.order.orderState,
				items: data.items.map((p) => ({
					key: p.id,
					id: p.id,
					orderId: p.orderId,
					orderNumber: p.orderNumber,
					productBrand: p.productBrand,
					productClient: p.productClient,
					productDeleted: p.productDeleted,
					productDiscount: p.productDiscount,
					productDraft: p.productDraft,
					productEan13: p.productEan13,
					productGeneralDiscount: p.productGeneralDiscount,
					productId: p.productId,
					productOfferPrice: p.productOfferPrice,
					productPaused: p.productPaused,
					productPhotoUrl: p.productPhotoUrl,
					productPrice: p.productPrice,
					productQuantity: p.productQuantity,
					productStateInOrder: p.productStateInOrder,
					productStateInOrderDate: p.productStateInOrderDate,
					productTitle: p.productTitle,
					productTriggerDiscount: p.productTriggerDiscount,
					productTriggerQty: p.productTriggerQty,
					productUnitsBilled: p.productUnitsBilled,
					productUnitsPending: p.productUnitsPending,
					productUnitsTRF: p.productUnitsTRF,
				})),
			};

			setImageUrl(data.order.clientPhotoUrl);

			setInitialValues(order);
			setLoading(false);
		};

		if (id) {
			fetch_sale_by_id();
		}
	}, []);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);
			navigate("/admin/ventas");
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(TYPE.ERROR, "Error");
		} finally {
			setSubmitting(false);
		}
	};

	if (!id) navigate("/admin/ventas");

	return (
		<div style={{ backgroundColor: COLORS.LightGrey }}>
			<HeaderContainer>
				<TitleBanner>
					<Typo type={"white"} fontWeight={600} fontSize={17}>
						GRACIAS POR ELEGIRNOS
					</Typo>
				</TitleBanner>
				<TitleContainer>
					<Image
						src={OneTransferLogo}
						style={{ width: "50px", height: "50px" }}
					/>
				</TitleContainer>
			</HeaderContainer>
			<Main>
				<Main.Body>
					{!initialValues ? (
						<LoadingSkeleton />
					) : (
						<Form
							form={form}
							name="basic"
							onFinish={onFinish}
							autoComplete="off"
							requiredMark={false}
							layout="vertical"
						>
							<InnerForm>
								<OrderData>
									<Space
										direction="vertical"
										size="middle"
										style={{ display: "flex" }}
									>
										<SubTitleContainer>
											<Typo
												fontWeight={600}
												fontSize={16}
												style={{ color: "#6e6d6d" }}
											>
												Nombre de Comercio
											</Typo>
										</SubTitleContainer>
										<InfoContainer
											style={{
												display: "flex",
												alignItems: "center",
												alignContent: "center",
												justifyContent: "flex-start",
												gap: "10px",
											}}
										>
											<Form.Item name="pointOfSaleName">
												<InfoText>{initialValues.pointOfSaleName}:</InfoText>
											</Form.Item>
											<Form.Item name="pointOfSaleAddress">
												<InfoText>{initialValues.pointOfSaleAddress}</InfoText>
											</Form.Item>
										</InfoContainer>

										<SubTitleContainer
											style={{
												display: "flex",
												alignContent: "center",
												alignItems: "center",
												justifyContent: "space-between",
												marginBottom: "-30px",
											}}
										>
											<Form.Item>
												<Typo
													fontWeight={600}
													fontSize={16}
													style={{ color: "#6e6d6d" }}
												>
													DETALLE DE ORDEN #{initialValues.orderNumId}
												</Typo>
											</Form.Item>
											<Form.Item name="name">
												<Typo
													fontWeight={600}
													fontSize={16}
													style={{ color: "#6e6d6d" }}
												>
													CLIENTE: {initialValues.client}
												</Typo>
											</Form.Item>
										</SubTitleContainer>
										<LineBreak />

										<InfoContainer>
											<Form.Item name="created">
												<InfoText>Creada el {initialValues.created}</InfoText>
											</Form.Item>
											<Form.Item name="distributor">
												<InfoText>
													Enviada a la droguería:{" "}
													<InfoText style={{ color: COLORS.Primary }}>
														{initialValues.distributorName}
													</InfoText>
												</InfoText>
											</Form.Item>
										</InfoContainer>

										<InfoContainer>
											<Form.Item name="distributor">
												<InfoText>
													Cantidad de Productos: {initialValues.items.length}
												</InfoText>
											</Form.Item>
											<Form.Item name="distributor">
												<InfoText>
													Unidades totales:{" "}
													{initialValues.items?.reduce(function (
														prev,
														current
													) {
														return prev + +current.productQuantity;
													},
													0)}
												</InfoText>
											</Form.Item>
										</InfoContainer>

										<SubTitleContainer
											style={{
												display: "flex",
												alignContent: "center",
												alignItems: "center",
												justifyContent: "center",
												marginBottom: "-30px",
											}}
										>
											<Form.Item>
												<Typo
													fontWeight={600}
													fontSize={16}
													style={{ color: "#6e6d6d" }}
												>
													LISTADO DE PRODUCTOS
												</Typo>
											</Form.Item>
										</SubTitleContainer>
										<LineBreak />
										<Inner>
											<OrderTable data={initialValues} />

											<PriceContainer>
												<Container style={{ alignItems: "flex-end" }}>
													<Typo type="primary" level={6}>
														Total:{" "}
													</Typo>
													<Typo type="primary" level={4}>
														${" "}
														{numberWithDotAndCommas(
															initialValues.items
																?.reduce(function (prev, current) {
																	return (
																		prev +
																		+(
																			current.productQuantity *
																			current.productOfferPrice
																		)
																	);
																}, 0)
																.toFixed(2)
														)}
													</Typo>
												</Container>
											</PriceContainer>
										</Inner>
									</Space>
								</OrderData>

								<OptionsButtonsContainer>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
										>
											Volver
										</SignInTemplate.AntdButton>
									</Form.Item>
								</OptionsButtonsContainer>
							</InnerForm>
						</Form>
					)}
				</Main.Body>{" "}
			</Main>
		</div>
	);
}
