import axiosInstance from "../axiosInstance";

const CONTROLLER = "BookOfMissings";

const has_book = async () => {
  const response = await axiosInstance.get(`${CONTROLLER}/HasBook`);
  return response.data;
};

const get_book = async () => {
  const response = await axiosInstance.get(`${CONTROLLER}/GetBook`);
  return response.data;
};

const get_book_quantity = async () => {
  const response = await axiosInstance.get(`${CONTROLLER}/GetBookItemsQty`);
  return response.data;
};
const get_book_items = async () => {
  const response = await axiosInstance.get(`${CONTROLLER}/GetBookItems`);
  return response.data;
};

const update_book_of_missings = async (prods) => {
  const response = await axiosInstance.put(
    `${CONTROLLER}/UpdateBookOfMissings`,
    prods
  );
  return response.data;
};

const increment_decrement_quantity = async (type) => {
  const response = await axiosInstance.put(
    `${CONTROLLER}/IncrementDecrementQuantity`,
    type
  );
  return response.data;
};

const update_item_quantity = async (obj) => {
  const response = await axiosInstance.put(
    `${CONTROLLER}/UpdateItemQuantity`,
    obj
  );
  return response.data;
};

const create_book = async () => {
  const response = await axiosInstance.post(`${CONTROLLER}`);
  return response.data;
};

const get_smart_buy = async () => {
  const response = await axiosInstance.get(`${CONTROLLER}/GetSmartBuy`);
  return response.data;
};

const send_to_cart = async () => {
  const response = await axiosInstance.post(`${CONTROLLER}/SendToCart`);
  return response.data;
};

const delete_item_book_by_id = async (id) => {
  const response = await axiosInstance.delete(`${CONTROLLER}?id=${id}`);
  return response.data;
};

export {
  has_book,
  update_book_of_missings,
  increment_decrement_quantity,
  update_item_quantity,
  get_book,
  get_book_items,
  create_book,
  get_smart_buy,
  get_book_quantity,
  send_to_cart,
  delete_item_book_by_id,
};
