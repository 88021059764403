import axiosInstance from "../axiosInstance";

const CONTROLLER = "ProductCategories";

const get_categories = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}`
	);
	return response.data;
};

const get_categories_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_category_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const create_category = async (category) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, category);
	return response.data;
};

const update_category = async (id, category) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, category);
	return response.data;
};

const get_categories_header_info = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetProductCategoriesHeader`
	);
	return response.data;
};

const update_category_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateProductCategoryState/${id}`,
		state
	);
	return response.data;
};

const get_categories_name_list = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetNameList`);
	return response.data;
};

export {
	get_categories,
	get_categories_page,
	get_category_by_id,
	create_category,
	update_category,
	get_categories_header_info,
	update_category_state,
	get_categories_name_list,
};
