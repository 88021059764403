import React from "react";
import { Inner, Container, Title, ViewTable } from "./styles";
import LoadingSkeleton from "./component/LoadingSkeleton";
import { COLORS, Typo } from "../../../../../../layout/theme";
import { FaImages } from "react-icons/fa";
import Image from "../../../../../../common/image/Image";

export default function TemplateViewDetail({ dataView }) {
	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.Primary }} />;
			},
			key: "photourl",
			dataIndex: "product",
			render: (value, record, index) => (
				<Image
					width={40}
					height={40}
					src={record.product.photoUrl}
					alt={record.product.name}
				/>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Primary,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Nombre
				</div>
			),
			key: "title",
			dataIndex: "title",
			render: (value, record) => (
				<>
					<Title>{record.product.title}</Title>
				</>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Primary,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Marca
				</div>
			),
			key: "brand",
			dataIndex: "brand",
			render: (value, record) => (
				<>
					<Title>{record.product?.brand?.name || ""}</Title>
				</>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Primary,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Laboratorio
				</div>
			),
			key: "client",
			dataIndex: "client",
			render: (value, record) => (
				<>
					<Title>{record.product?.client?.name || ""}</Title>
				</>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Primary,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Cantidad
				</div>
			),
			key: "quantity",
			dataIndex: "quantity",
			render: (value, record) => <Title>{value}</Title>,
		},
	];

	if (!dataView) return <LoadingSkeleton />;

	return (
		<Inner>
			<Container>
				<Typo type="primary" fontWeight={600} fontSize={16}>
					DETALLE DE PLANTILLA {dataView.name.toUpperCase()}
				</Typo>

				<ViewTable
					dataSource={dataView.items}
					pagination={false}
					columns={columns}
				/>
			</Container>
		</Inner>
	);
}
