import styled from "styled-components";
import { COLORS } from "../../layout/theme";
import { Table } from "antd";

export const Inner = styled.div`
	display: flex;
	border-radius: 15px;
	background-color: ${COLORS.White};
	height: 160px;
	width: 100%;
	padding: 10px 20px;
	gap: 20px;
	${(props) =>
		props.conditional &&
		`
      border: 1px solid ${COLORS.MediumGrey};
    `}
`;
export const ImageContainer = styled.div`
	flex: 1 1 15%;
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: contain;
`;
export const CenterData = styled.div`
	flex: 1 1 55%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-left: 1px solid ${COLORS.Grey};
	border-right: 1px solid ${COLORS.Grey};
	padding: 0px 15px;
`;
export const ProductsContainer = styled.div`
	display: flex;
	gap: 10px;
`;
export const TopCenter = styled.div`
	display: flex;
	flex-direction: column;
`;

export const RightData = styled.div`
	flex: 1 1 30%;
	display: flex;
	padding-left: 50px;
	flex-direction: column;
	justify-content: center;
	align-items: start;
`;

export const ContainerModal = styled.div`
	display: flex;
	flex-direction: column;
	padding: 5px 20px;
	gap: 10px;

	.ant-table-wrapper .ant-table {
		scrollbar-color: auto;
	}
	.ant-table-body {
		scrollbar-width: auto;
		scrollbar-color: auto;
	}

	.ant-table-body::-webkit-scrollbar {
		width: 4px;
	}

	.ant-table-body::-webkit-scrollbar-track {
		background-color: ${COLORS.White};
	}

	.ant-table-body::-webkit-scrollbar-thumb {
		border-radius: 6px;
		background-color: ${COLORS.MediumGrey};
	}
`;

export const ProductContainer = styled.div`
	display: flex;
	border: 1px solid ${COLORS.LightGrey};
	border-radius: 20px;
	padding: 20px;
	justify-content: flex-start;
	gap: 30px;
`;

export const ProductSection = styled.div`
	display: flex;
	flex: 1 1 20%;
	flex-direction: column;
	gap: 5px;
`;
export const SelectData = styled.div`
	flex: 1 1 10%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-left: 1px solid ${COLORS.Grey};
	padding: 0px 15px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.White};
	}

	.ant-table-tbody > tr > td {
		border-bottom: 0px solid ${COLORS.White};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.DarkGrey};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const Section = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;
