import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../../../layout/main/Main";
import { Space, Radio, Dropdown, Menu, Modal } from "antd";
import {
	get_points_of_sale_header_info,
	get_points_of_sale_page,
	update_point_of_sale_state,
} from "../../../../../api/endpoints/pointsofsale";
import DataTable from "../../../../common/data-table/DataTable";
import { ActionButton, Arrow, ArrowRight, Filters } from "./styles";
import LoadingHeader from "./components/LoadingHeader";
import {
	CloseCircleOutlined,
	InfoCircleOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import { COLORS, Typo } from "../../../../layout/theme";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { useAuth } from "../../../../../contexts/authContext";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import AntdSearch from "../../../../common/antd-search/AntdSearch";

const { confirm, info, error } = Modal;

export default function PointOfSaleList({ clientUser }) {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [key, setKey] = useState("null");
	const [state, setState] = useState("ACTIVE");
	const [headerInfo, setHeaderInfo] = useState(null);
	const { user, userdata } = useAuth();

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			render: (f, r) => (
				<Link
					to={`/admin/pointsofsale/${key.id}`}
					onMouseOver={() => setKey(r)}
				>
					<Typo type="terciary" fontWeight={600} fontSize={14}>
						{f}
					</Typo>
				</Link>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Dirección</div>,
			dataIndex: "address",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Código Postal</div>,
			dataIndex: "postalCode",
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Nombre de contacto</div>
			),
			dataIndex: "contactName",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Email de contacto</div>,
			dataIndex: "contactEmail",
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Teléfono de contacto</div>
			),
			dataIndex: "contactPhone",
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={r.deleted ? menuInactive : menu}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/pointsofsale/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleDelete(key)}>Desactivar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/pointsofsale/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleActivate(key)}>Reactivar</Typo>,
				},
			]}
		/>
	);

	const fetch_pointsofsale = async (page, state, search) => {
		const res = await get_points_of_sale_page(page, state, search);
		setData(res);
	};

	const fetch_points_of_sale_header_info = async () => {
		const res = await get_points_of_sale_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_points_of_sale_header_info();
	}, []);

	useEffect(() => {
		fetch_pointsofsale(1, state, "");
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_pointsofsale(1, state, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleDelete = (key) => {
		!clientUser?.client?.isChain && data?.items?.length > 0
			? info({
					title: "No puede desactivar su unico punto de venta",
					icon: <InfoCircleOutlined style={{ color: COLORS.Secondary }} />,
					content:
						'Si desea ajustar un valor por favor entre la opción "Editar"',
					okText: "Aceptar",
					okType: "danger",
					onOk: () => {},
			  })
			: confirm({
					title: "¿Seguro que quiere eliminar este Punto de Venta?",
					icon: <CloseCircleOutlined style={{ color: "red" }} />,
					content: "Este cambio se aplicará de inmediato",
					okText: "Sí",
					okType: "danger",
					cancelText: "No",
					onOk: () => {
						deletePointOfSale(key);
					},
			  });
	};

	const deletePointOfSale = async (id) => {
		const _state = { id: id, state: "DELETED" };
		await update_point_of_sale_state(id, _state);
		fetch_pointsofsale(1, state, "");
		fetch_points_of_sale_header_info();
	};

	const handleActivate = (key) => {
		!clientUser?.client?.isChain && data?.items?.length > 0
			? error({
					title: "No puede activar otro punto de venta",
					icon: <CloseCircleOutlined style={{ color: "red" }} />,
					content: "Ya posee un punto de venta activo",
					okText: "Aceptar",
					okType: "danger",
					onOk: () => {},
			  })
			: confirm({
					title: "¿Seguro que quiere reactivar este Punto de Venta?",
					icon: <InfoCircleOutlined style={{ color: "blue" }} />,
					content: "Este cambio se aplicará de inmediato",
					okText: "Sí",
					okType: "default",
					cancelText: "No",
					onOk: () => {
						activatePointOfSale(key);
					},
			  });
	};

	const activatePointOfSale = async (id) => {
		const _state = { id: id, state: "ACTIVE" };
		await update_point_of_sale_state(id, _state);
		fetch_pointsofsale(1, state, "");
		fetch_points_of_sale_header_info();
	};

	const handleCreatePointOfSale = () => {
		navigate("/admin/pointsofsale/create");
	};

	if (!data) return <div>Loading...</div>;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Puntos de Venta</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				{(clientUser?.client?.isChain || data?.items?.length === 0) && (
					<SignInTemplate.AntdButton
						type="primary"
						bg="Primary"
						color="White"
						icon={<PlusOutlined />}
						onClick={handleCreatePointOfSale}
						style={{ width: "300px", fontWeight: "400" }}
					>
						Crear punto de venta
					</SignInTemplate.AntdButton>
				)}
				{userdata?.guideProgress === 1 && data?.items?.length === 0 && (
					<Arrow>
						<FaArrowLeft />
					</Arrow>
				)}
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<AntdSearch
								allowClear
								placeholder="Buscar punto de venta"
								onChange={debouncedEventHandler}
							/>

							<Radio.Group
								value={state}
								onChange={(e) => setState(e.target.value)}
							>
								<Radio.Button value="ACTIVE">{`Activos (${headerInfo.totalActive})`}</Radio.Button>
								<Radio.Button value="DELETED">{`Inactivos (${headerInfo.totalDisabled})`}</Radio.Button>
							</Radio.Group>
						</Space>
					)}
				</Filters>
				{userdata?.guideProgress === 1 && data?.items?.length > 0 && (
					<ArrowRight>
						<FaArrowRight />
					</ArrowRight>
				)}
				<DataTable data={data} fetch={fetch_pointsofsale} columns={columns} />
			</Main.Body>
		</Main>
	);
}
