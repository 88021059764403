import styled from "styled-components";
import { Button as ButtonAntd } from "antd";
import { COLORS } from "../../../layout/theme";

export const Inner = styled.div`
	${(props) =>
		!props.clientId &&
		`
    padding: 30px 100px;
    background-color: ${COLORS.LightGrey};
    min-height: 65vh;

    @media only screen and (min-width: 600px) and (max-width: 860px) {
    }
  `}
`;

export const ButtonContainer = styled.div``;
export const AntdButton = styled(ButtonAntd)`
	background-color: ${(props) =>
		props.active ? COLORS.Primary : COLORS.White};
	color: ${(props) => (props.active ? COLORS.White : COLORS.Primary)};
	border-color: ${COLORS.Primary};
	border-radius: 5px;
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
`;
export const ChildrenContainer = styled.div`
	margin-top: 50px;
	position: relative;
`;
