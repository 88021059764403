import React, { useEffect, useState } from "react";
import Main from "../../../../layout/main/Main";
import { Link } from "react-router-dom";
import { Select } from "antd";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import {
	AntdButton,
	ButtonContainer,
	ImportHistoryContainer,
	ImportResultContainer,
	UploadFile,
} from "./styles";
import moment from "moment";
import { get_clients_name_list } from "../../../../../api/endpoints/clients";
import { BsUpload, BsDownload } from "react-icons/bs";
import { Typo } from "../../../../layout/theme";
import {
	add_general_pos,
	confirm_import_general_pos,
	export_general_pos,
} from "../../../../../api/endpoints/pointsofsale";
import ResultsTable from "../results-table/ResultsTable";
import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { CONSTANTS } from "../../../../../utils/constants";
import ImportHistory from "../history/list/ImportHistory";

const { Option } = Select;

export default function ImportGeneralPos() {
	const [errors, setErrors] = useState(null);
	const [clients, setClients] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [buttonsState, setButtonsState] = useState(false);
	const [isLoading, setIsLoading] = useState([false, false, false, false]);
	const [importResponse, setImportResponse] = useState(null);

	const fetch_clients = async () => {
		const res = await get_clients_name_list();
		setClients(
			res
				.filter((i) => i.isSeller && i.isApproved)
				.map((r) => ({
					value: r.id,
					label: r.name,
				}))
				.concat({
					value: CONSTANTS.ONE_TRANSFER_CLIENT_ID,
					label: "One Transfer",
				})
		);
	};
	useEffect(() => {
		fetch_clients();
	}, []);

	const handleChange = async (e, type) => {
		if (type === "import") {
			setIsLoading([false, true, false, false]);
			try {
				const formData = new FormData();
				formData.append(
					"file",
					e.fileList[e.fileList.length - 1].originFileObj
				);
				formData.append(
					"data",
					JSON.stringify({
						clientId: selectedClient,
					})
				);
				const res = await add_general_pos(formData);
				setImportResponse(res);
				setErrors(res?.errors);
				if (res?.errors?.length > 0) {
					openNotificationWithIcon(
						TYPE.ERROR,
						"Los productos que intenta subir no se cargaron",
						"Los productos que intenta subir tienen problemas, reviselos y subalos de nuevo"
					);
				}
			} catch (err) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Error de archivo",
					"Los productos deben subirse en formato excel o verifique que este bien su excel"
				);
			} finally {
				setIsLoading([false, false, false, false]);
			}
		}

		if (type === "export") {
			setIsLoading([true, false, false, false]);

			try {
				const res = await export_general_pos();
				const href = URL.createObjectURL(res);

				const link = document.createElement("a");
				link.href = href;

				const fileName =
					"PuntosDeVentaGenerales_" + moment().format("DD-MM-YYYY") + ".xlsx";
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);
				URL.revokeObjectURL(href);
			} catch (err) {
				console.log(err);
			} finally {
				setIsLoading([false, false, false, false]);
			}
		}

		if (type === "confirmImport") {
			setIsLoading([false, false, true, false]);

			try {
				const postData = {
					pointsOfSaleToUpdate: importResponse.hasUpdatesPos,
					pointsOfSaleToAdd: importResponse.newPos,
					pointsOfSaleToDelete: importResponse.deletedPos,
					clientId: selectedClient,
				};
				const res = await confirm_import_general_pos(postData);
				if (res) {
					setImportResponse(null);
					openNotificationWithIcon(
						TYPE.SUCCESS,
						"Importación exitosa",
						"Los Puntos de venta generales han sido actualizados"
					);
				}
			} catch (err) {
				console.log(err);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Error de archivo",
					"Los Puntos de venta deben subirse en formato excel. Verifique su archivo"
				);
			} finally {
				setIsLoading([false, false, false, false]);
			}
		}

		if (type === "cancelImport") {
			setImportResponse(null);
		}
	};

	const selectLaboratory = async (e) => {
		setSelectedClient(e);
		setButtonsState(true);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Importar</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<ButtonContainer>
					<AntdButton
						onClick={(e) => handleChange(e, "export")}
						loading={isLoading[0]}
					>
						<BsDownload
							style={{ position: "relative", top: "0.8px", left: "-5px" }}
						/>{" "}
						Descargar PDVs
					</AntdButton>
					{buttonsState && (
						<AntdButton
							loading={isLoading[1]}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "170px",
							}}
						>
							<UploadFile
								multiple={false}
								showUploadList={false}
								beforeUpload={() => false}
								action={null}
								onChange={(e) => handleChange(e, "import")}
							>
								<BsUpload
									style={{ position: "relative", top: "0.8px", left: "-5px" }}
								/>
								Cargar nuevos PDV
							</UploadFile>
						</AntdButton>
					)}
					<Select
						showSearch
						placeholder="Elegi un laboratorio"
						optionFilterProp="children"
						allowClear={() => setSelectedClient(null)}
						onChange={(e) => selectLaboratory(e)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={clients}
						style={{ width: 180 }}
					/>
				</ButtonContainer>

				<ImportResultContainer>
					{importResponse &&
						(importResponse?.hasUpdatesPos?.length > 0 ||
							importResponse?.newPos?.length > 0 ||
							importResponse?.deletedPos?.length > 0) && (
							<ButtonContainer>
								<Typo type="primary" level={6}>
									Resultado importación
								</Typo>
								<AntdButton
									onClick={(e) => handleChange(e, "confirmImport")}
									loading={isLoading[2]}
									success={true}
								>
									<CheckOutlined
										style={{ position: "relative", top: "0.8px", left: "2px" }}
									/>{" "}
									Confirmar importación
								</AntdButton>
								<AntdButton onClick={(e) => handleChange(e, "cancelImport")}>
									<CloseCircleOutlined
										style={{ position: "relative", top: "0.8px", left: "2px" }}
									/>{" "}
									Cancelar importación
								</AntdButton>
							</ButtonContainer>
						)}
					{importResponse && importResponse?.hasUpdatesPos?.length > 0 && (
						<ResultsTable
							data={[
								{
									title: "Puntos de venta modificados",
									quantity: importResponse?.hasUpdatesPos?.length,
								},
							]}
							expandableData={importResponse?.hasUpdatesPos}
							fileName={"PuntosDeVentaModificados"}
						/>
					)}
					{importResponse && importResponse?.newPos?.length > 0 && (
						<ResultsTable
							data={[
								{
									title: "Puntos de venta agregados",
									quantity: importResponse?.newPos?.length,
								},
							]}
							expandableData={importResponse?.newPos}
							fileName={"PuntosDeVentaAgregados"}
						/>
					)}
					{importResponse && importResponse?.ignoredPos?.length > 0 && (
						<ResultsTable
							data={[
								{
									title: "Puntos de venta ignorados",
									quantity: importResponse?.ignoredPos?.length,
								},
							]}
							expandableData={importResponse?.ignoredPos}
							fileName={"PuntosDeVentaIgnorados"}
						/>
					)}
					{importResponse && importResponse?.deletedPos?.length > 0 && (
						<ResultsTable
							data={[
								{
									title: "Puntos de venta eliminados",
									quantity: importResponse?.deletedPos?.length,
								},
							]}
							expandableData={importResponse?.deletedPos}
							fileName={"PuntosDeVentaEliminados"}
						/>
					)}
				</ImportResultContainer>

				<ImportHistoryContainer>
					<ImportHistory isLoading={isLoading} />
				</ImportHistoryContainer>
			</Main.Body>
		</Main>
	);
}
