import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Row, Col, Space, Select } from "antd";
import Main from "../../../../../layout/main/Main";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import {
	BlockedData,
	ClientData,
	InnerForm,
	PreviewImage,
	PublishSettings,
} from "./styles";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { COLORS, Typo } from "../../../../../layout/theme";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import {
	get_client_by_id,
	update_client_megaDistributor,
} from "../../../../../../api/endpoints/clients";
import { get_megaDistributors } from "../../../../../../api/endpoints/megaDistributors";

const { Option } = Select;
const GUTTER = 10;

export default function UpdateLaboratory() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const [imageUrl, setImageUrl] = useState();
	const [megaDistributors, setMegaDistributors] = useState(null);
	const [selectedMegaDistributor, setSelectedMegaDistributor] = useState(null);

	useEffect(() => {
		const fetch_client_by_id = async () => {
			const data = await get_client_by_id(id);
			const client = {
				name: data.client.name,
				megaDistributor: data.client.megaDistributor
					? data.client.megaDistributor.name
					: null,
			};

			setImageUrl(data.client.photoUrl);

			setInitialValues(client);
		};

		const fetch_megaDistributors = async () => {
			const res = await get_megaDistributors();
			setMegaDistributors(res);
		};

		if (id) {
			fetch_client_by_id();
			fetch_megaDistributors();
		}
	}, []);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				id: id,
				megaDistributorId: selectedMegaDistributor
					? selectedMegaDistributor
					: null,
			};

			await update_client_megaDistributor(postData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"El laboratorio se editó con éxito"
			);
			navigate(`/admin/distributorsIndex`);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al editar el laboratorio",
				"Un error ocurrio al intentar editar el laboratorio, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleMegaDistributorSelect = (value) => {
		setSelectedMegaDistributor(value);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/distributorsIndex">
							Distribuidores y Laboratorios
						</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Editar Laboratorio
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<ClientData>
								<BlockedData>
									<Space
										direction="vertical"
										size="middle"
										style={{ display: "flex" }}
									>
										<Row gutter={GUTTER} style={{ justifyContent: "center" }}>
											<Col span={6}>
												<Form.Item
													name="file"
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Laboratorio
														</label>
													}
													style={{
														height: 20,
														display: "flex",
														alignItems: "center",
														paddingLeft: 8,
													}}
												></Form.Item>
												{imageUrl && (
													<PreviewImage
														style={{}}
														src={imageUrl}
														alt="avatar"
													/>
												)}
											</Col>
										</Row>
									</Space>
								</BlockedData>
							</ClientData>
							<PublishSettings>
								<Typo level={5} type="primary">
									Actualización
								</Typo>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Distribuidor asociado
										</label>
									}
									name="megaDistributor"
									rules={[]}
									style={{ width: "50%" }}
								>
									<Select
										allowClear={true}
										placeholder="Seleccione un Distribuidor"
										onChange={handleMegaDistributorSelect}
									>
										{megaDistributors?.map((item) => (
											<Option key={item.id} value={item.id}>
												{item.name}
											</Option>
										))}
									</Select>
								</Form.Item>

								<Form.Item style={{ width: "50%" }}>
									<SignInTemplate.AntdButton
										type="Primary"
										color="white"
										bg="Primary"
										htmlType="submit"
										loading={submitting}
										block
									>
										Guardar Cambios
									</SignInTemplate.AntdButton>
								</Form.Item>
							</PublishSettings>
						</InnerForm>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
