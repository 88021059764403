import { Input } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	flex-direction: row;
	align-content: center;
	gap: 50px;
`;

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 50px;
	padding: 40px 0 40px 0px;
`;

export const Inner = styled.div`
	display: flex;
	gap: 40px;
	margin-top: 30px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
	}
`;

export const FilterSection = styled.div`
	display: flex;
`;
export const ResultsSection = styled.div`
	flex: 1 1 80%;
	padding: 20px 20px;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 10px;
	width: 100hv;
`;
export const OptionsSection = styled.div``;

export const ProductsSection = styled.div`
	flex: 1 1 60%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 100%;
	width: 100%;
	overflow: hidden;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 50%;
	}
`;

export const InfoSection = styled.div`
	flex: 1 1 40%;
	background-color: white;
	position: sticky;
	margin-top: 10px;
	border-radius: 10px;
	padding-top: 1rem;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 50%;
	}
`;

export const PublishSettings = styled.div`
	flex: 1 1 35%;
	background-color: white;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	align-content: center;
	justify-content: center;
	height: 80px;
`;

export const ContainerTitle = styled.div`
	display: flex;
	align-items: start;
	gap: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const Section = styled.div`
	margin-top: 20px;
	padding: 10px 15px 10px 15px;
	background-color: ${COLORS.LightGrey};
`;

export const InfoSubSection = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 10px 80px 20px 80px;
	align-content: center;
	align-items: center;
`;

export const InfoTitleProductQuantity = styled.div`
	color: ${COLORS.Default};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitleItemQuantity = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoProductNumber = styled.div`
	outline: auto;
	outline-width: thin;
	outline-color: ${COLORS.Grey};
	border-radius: 5px;
	width: 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.Default};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoItemNumber = styled.div`
	outline: auto;
	outline-width: thin;
	outline-color: ${COLORS.Grey};
	border-radius: 5px;
	width: 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.Grey};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitle = styled.div`
	font-weight: ${WEIGHT.Bold};
	color: ${COLORS.Primary};
	white-space: nowrap;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
	}
`;

export const InfoTitleContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
	}
`;

export const SearchContainer = styled.div`
	flex: 1 1 40%;
	align-items: center;
	align-content: center;
	justify-content: center;
	display: flex;
	align-content: space-evenly;
`;

export const TitleSectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-content: center;
	gap: 10px;
	margin-top: 0.6rem;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
`;

export const CompaginationDiv = styled.div`
	display: flex;
	gap: 4px;
`;

export const LeftSection = styled.div`
	flex: 1 1 20%;
`;

export const RightSection = styled.div`
	flex: 1 1 20%;
`;
