import styled from "styled-components";
import { COLORS, WEIGHT } from "../../../../layout/theme";

export const InnerForm = styled.div`
  display: flex;
  gap: 50px;
`;

export const LeftData = styled.div`
  flex: 1 1 65%;
`;

export const RightData = styled.div`
  flex: 1 1 35%;
  background-color: white;
  border-radius: 10px;
  padding: 20px 0;
`;

export const Container = styled.div`
  padding: 0 2rem;
`;

export const NewMarketing = styled.div``;

export const AllProductsContainer = styled.div`
  text-align: end;
`;

export const PreviewImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
`;

export const Span = styled.span`
  cursor: pointer;
  color: ${COLORS.Secondary};
  margin-right: 60px;
  font-weight: ${WEIGHT.ExtraBold};
`;

export const CampaignsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const Footer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;
