import styled from "styled-components/macro";
import { COLORS } from "../../../../../../../layout/theme";

export const Inner = styled.div`
	width: 100%;
	height: 450px;
	overflow: auto;

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.SkeletonGrey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}
`;
