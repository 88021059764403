import React, { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { get_home_data } from "../../../../api/endpoints/home";
import OfferSlideContainer from "../../../containers/product-slide/OfferSlideContainer";
import { Typo } from "../../../layout/theme";
import {
	AntdModal,
	ButtonOffer,
	Inner,
	Section,
	SectionBanners,
	SectionTitle,
} from "./styles";
import LaboratoriesSlideContainer from "../../../containers/product-slide/LaboratoriesSlideContainer";
import Slider from "../../../common/slider/Slider";
import { useNavigate } from "react-router-dom";
import SliderProduct from "../../../common/slider-products/SliderProducts";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import PublicitySlideContainer from "../../../containers/product-slide/PublicitySlideContainer";
import { useAuth } from "../../../../contexts/authContext";
import WelcomeModal from "./components/welcome-modal/WelcomeModal";
import { CONSTANTS } from "../../../../utils/constants";
import { get_featured_products_home } from "../../../../api/endpoints/advertisings";
import FeaturedGroupSlideContainer from "./components/featured-group-slide-container/FeaturedGroupSlideContainer";
import { useCart } from "../../../../contexts/cartContext";

export default function Main() {
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [bigBannerImages, setBigBannerImages] = useState(null);
	const [cardBannerImages, setCardBannerImages] = useState(null);
	const [doubleBannerImages, setDoubleBannerImages] = useState(null);
	const [tripleBannerImages, setTripleBannerImages] = useState(null);
	const [showWelcomeModal, setShowWelcomeModal] = useState(false);

	useEffect(() => {
		if (
			userdata?.guideProgress === 0 &&
			!userdata?.isOneMarket &&
			userdata?.p_client
		) {
			setShowWelcomeModal(true);
		}
	}, []);

	useEffect(() => {
		const fetch_home_data = async () => {
			const res = await get_home_data();
			setData(res);

			setBigBannerImages(
				res.banners
					.filter((b) => b.title === CONSTANTS.BIG_BANNER_HOME)
					.map((b) => ({
						id: b.id,
						title: b.title,
						src: b.photoUrl,
						bannerUrl: b.bannerUrl,
					}))
			);
			setDoubleBannerImages(
				res.banners
					.filter((b) => b.title === CONSTANTS.DOUBLE_BANNER_HOME)
					.map((b) => ({
						id: b.id,
						title: b.title,
						src: b.photoUrl,
						bannerUrl: b.bannerUrl,
					}))
			);
			setTripleBannerImages(
				res.banners
					.filter((b) => b.title === CONSTANTS.TRIPLE_BANNER_HOME)
					.map((b) => ({
						id: b.id,
						title: b.title,
						src: b.photoUrl,
						bannerUrl: b.bannerUrl,
					}))
			);
		};

		fetch_home_data();
	}, []);

	useEffect(() => {
		const fetch_featured_home = async () => {
			const res = await get_featured_products_home();
			setCardBannerImages(res?.activeFeatureProducts);
		};

		fetch_featured_home();
	}, []);

	const handleOfferButton = () => {
		window.scrollTo(0, 0);
		navigate(`/offers`);
	};

	const handlePromotedButton = () => {
		window.scrollTo(0, 0);
		navigate(`/offers`);
	};

	const handleRxButton = () => {
		//appInsights.trackEvent({ name: "Otro evento custom para Rx Home" });
		window.scrollTo(0, 0);
		navigate(`/offers/?t=Rx`);
	};

	const handleOTCButton = () => {
		window.scrollTo(0, 0);
		navigate(`/offers/?t=OTC%20-%20Venta%20Libre`);
	};

	const handleConsumoMasivoButton = () => {
		window.scrollTo(0, 0);
		navigate(`/offers/?t=Consumo%20Masivo`);
	};

	const handleSearchButton = () => {
		window.scrollTo(0, 0);
		navigate(`/search`);
	};

	const handleCategoryTwoButton = () => {
		window.scrollTo(0, 0);
		navigate(`/search/?c=${data.selectedCategory}`);
	};

	const handleSaleDepartmentSearchButton = () => {
		window.scrollTo(0, 0);
		navigate(`/search/?r=${data.selectedSaleDepartment}`);
	};

	const handleBuyedListButton = () => {
		window.scrollTo(0, 0);
		navigate(`/admin/buyedList`);
	};

	const handleStoresButton = () => {
		window.scrollTo(0, 0);
		navigate(`/stores`);
	};

	const handleOk = () => {
		setShowWelcomeModal(false);
	};

	const handleCancel = () => {
		setShowWelcomeModal(false);
	};

	const redirectToGuide = () => {
		navigate("/startGuide");
	};

	const skipGuide = () => {
		updateStartGuideProgress(user.uid, 7);
		setShowWelcomeModal(false);
	};

	if (!data) return <LoadingSkeleton />;

	return (
		<Inner>
			<div style={{ padding: "10px 0px 0px 0px" }}>
				<Slider slidesPerView={1} navigation={true} opacityValue={0.5}>
					{bigBannerImages &&
						bigBannerImages.map((d, idx) => (
							<SwiperSlide key={`bigBanner_slide_${idx}`}>
								<PublicitySlideContainer
									id={d.id}
									title={d.title}
									image={d.src}
									bannerUrl={d.bannerUrl}
									bannerHeight="100%"
									bannerFit="fit"
									bannerType={CONSTANTS.BIG_BANNER_HOME}
								/>
							</SwiperSlide>
						))}
				</Slider>
			</div>

			<Section style={{}}>
				<SectionTitle style={{ padding: "20px 0 30px 0" }}>
					<Typo level={4} type="primary">
						Empresas y laboratorios
					</Typo>
					{/*<ButtonOffer onClick={handleStoresButton}>Ver Todas</ButtonOffer> */}
				</SectionTitle>
				<Slider
					loading={loading}
					slidesPerView={10}
					slidesPerGroup={10}
					spaceBetween={5}
					delay={false}
					swipperHeight="30vh"
					navigation={true}
				>
					{data &&
						data.stores.map((d, idx) => (
							<SwiperSlide key={`stores_slide_${idx}`}>
								<LaboratoriesSlideContainer
									id={d.id}
									slug={d.slug}
									title={d.name}
									image={d.photoUrl}
								/>
							</SwiperSlide>
						))}
				</Slider>
			</Section>

			{cardBannerImages && cardBannerImages?.length > 2 ? (
				<Section style={{ padding: "0px 20px 10px 20px" }}>
					<SectionTitle style={{ padding: "10px 60px 20px 60px" }}>
						<Typo level={4} type="primary">
							No te lo pierdas
						</Typo>
					</SectionTitle>
					<SectionBanners style={{ padding: "0 60px 0 60px" }}>
						<Slider
							loading={loading}
							slidesPerView={3}
							slidesPerGroup={3}
							spaceBetween={50}
							navigation={true}
							//opacityValue={0.5}
							delay={900000}
						>
							{cardBannerImages &&
								cardBannerImages.map((d, idx) => (
									<SwiperSlide key={`publicity_card_slide_${idx}`}>
										<FeaturedGroupSlideContainer
											data={d}
											bannerHeight={"100%"}
											bannerBorder={"10px"}
											bannerFit="fit"
										/>
									</SwiperSlide>
								))}
						</Slider>
					</SectionBanners>
				</Section>
			) : null}

			{data?.productosPromocionadosSlider1?.length > 5 ? (
				<Section>
					<SectionTitle style={{ padding: "30px 0 20px 0" }}>
						<Typo level={4} type="primary">
							Productos Promocionados
						</Typo>
						<ButtonOffer onClick={handlePromotedButton}>Ver Todas</ButtonOffer>
					</SectionTitle>
					<SliderProduct
						slidesPerView={6}
						slidesPerGroup={6}
						delay={false}
						navigation={true}
					>
						{data &&
							data.productosPromocionadosSlider1.map((d, idx) => (
								<SwiperSlide key={`offers_slide_${idx}`}>
									<OfferSlideContainer
										id={d.productId}
										title={d.title}
										image={d.photoUrl}
										price={d.price}
										offerPrice={d.offerPrice}
										offerTo={d.offerTo}
										discount={d.discount}
										labImage={d.clientPhotoUrl}
										qtyInCart={d.productQtyInCart}
										clientName={d.clientName}
										productPresentation={d.presentationName}
										productQty={d.presentationQty}
										productUnit={d.presentationUnit}
										marketing={d.marketingId}
										clientTypeOfSale={d.clientTypeOfSale}
										triggerQty={d.triggerQty}
										triggerDiscount={d.triggerDiscount}
										minQuantity={d.minQuantity}
									/>
								</SwiperSlide>
							))}
					</SliderProduct>
				</Section>
			) : null}

			{data?.productosPromocionadosSlider2?.length > 5 ? (
				<Section>
					<SectionTitle style={{ padding: "0 0 30px 0" }}></SectionTitle>
					<SliderProduct
						slidesPerView={6}
						slidesPerGroup={6}
						delay={false}
						navigation={true}
					>
						{data &&
							data.productosPromocionadosSlider2.map((d, idx) => (
								<SwiperSlide key={`offers_slide_${idx}`}>
									<OfferSlideContainer
										id={d.productId}
										title={d.title}
										image={d.photoUrl}
										price={d.price}
										offerPrice={d.offerPrice}
										offerTo={d.offerTo}
										discount={d.discount}
										labImage={d.clientPhotoUrl}
										qtyInCart={d.productQtyInCart}
										clientName={d.clientName}
										productPresentation={d.presentationName}
										productQty={d.presentationQty}
										productUnit={d.presentationUnit}
										marketing={d.marketingId}
										clientTypeOfSale={d.clientTypeOfSale}
										triggerQty={d.triggerQty}
										triggerDiscount={d.triggerDiscount}
										minQuantity={d.minQuantity}
									/>
								</SwiperSlide>
							))}
					</SliderProduct>
				</Section>
			) : null}

			<Section>
				<SectionTitle>
					<Typo level={4} type="primary">
						No te lo pierdas
					</Typo>
				</SectionTitle>
				<SectionBanners>
					<Slider
						loading={loading}
						slidesPerView={2}
						slidesPerGroup={2}
						spaceBetween={5}
						navigation={true}
						opacityValue={0.5}
					>
						{doubleBannerImages &&
							doubleBannerImages.map((d, idx) => (
								<SwiperSlide key={`small_banner_slide_${idx}`}>
									<PublicitySlideContainer
										id={d.id}
										title={d.title}
										image={d.src}
										bannerUrl={d.bannerUrl}
										bannerHeight={"300px"}
										bannerBorder={"10px"}
										bannerType={CONSTANTS.DOUBLE_BANNER_HOME}
									/>
								</SwiperSlide>
							))}
					</Slider>
				</SectionBanners>
			</Section>

			{/* Se deja comentado para agregar a futuro */}
			{/* {data?.productosPorInteres?.length > 5 ? (
				<Section>
					<SectionTitle>
						<Typo level={4} type="primary">
							En base a tus búsquedas
						</Typo>
						<ButtonOffer onClick={handleSearchButton}>Ver Todas</ButtonOffer>
					</SectionTitle>
					<SliderProduct slidesPerView={6} delay={false} navigation={true}>
						{data?.productosPorInteres &&
							data?.productosPorInteres?.map((d, idx) => (
								<SwiperSlide key={`userInterest_slide_${idx}`}>
									<OfferSlideContainer
										id={d.productId}
										title={d.title}
										image={d.photoUrl}
										price={d.price}
										offerPrice={d.offerPrice}
										offerTo={d.offerTo}
										discount={d.discount}
										labImage={d.clientPhotoUrl}
										qtyInCart={d.productQtyInCart}
										clientName={d.clientName}
										productPresentation={d.presentationName}
										productQty={d.presentationQty}
										productUnit={d.presentationUnit}
										marketing={d.marketingId}
										clientTypeOfSale={d.clientTypeOfSale}
										triggerQty={d.triggerQty}
										triggerDiscount={d.triggerDiscount}
									/>
								</SwiperSlide>
							))}
					</SliderProduct>
				</Section>
			) : null} */}

			{/* {data?.rubroElegido?.length > 5 ? (
				<Section>
					<SectionTitle>
						<Typo level={4} type="primary">
							Productos en {data?.selectedSaleDepartment}
						</Typo>
						<ButtonOffer onClick={handleSaleDepartmentSearchButton}>
							Ver Todas
						</ButtonOffer>
					</SectionTitle>
					<SliderProduct
						slidesPerView={6}
						slidesPerGroup={6}
						delay={false}
						navigation={true}
					>
						{data?.rubroElegido &&
							data?.rubroElegido?.map((d, idx) => (
								<SwiperSlide key={`saleDepartment_slide_${idx}`}>
									<OfferSlideContainer
										id={d.productId}
										title={d.title}
										image={d.photoUrl}
										price={d.price}
										offerPrice={d.offerPrice}
										offerTo={d.offerTo}
										discount={d.discount}
										labImage={d.clientPhotoUrl}
										qtyInCart={d.productQtyInCart}
										clientName={d.clientName}
										productPresentation={d.presentationName}
										productQty={d.presentationQty}
										productUnit={d.presentationUnit}
										marketing={d.marketingId}
										clientTypeOfSale={d.clientTypeOfSale}
										triggerQty={d.triggerQty}
										triggerDiscount={d.triggerDiscount}
										minQuantity={d.minQuantity}
									/>
								</SwiperSlide>
							))}
					</SliderProduct>
				</Section>
			) : null} */}

			{data?.productosMasBuscados?.length > 5 ? (
				<Section>
					<SectionTitle>
						<Typo level={4} type="primary">
							Productos más buscados
						</Typo>
						<ButtonOffer onClick={handleSearchButton}>Ver Todas</ButtonOffer>
					</SectionTitle>
					<SliderProduct
						slidesPerView={6}
						slidesPerGroup={6}
						delay={false}
						navigation={true}
					>
						{data?.productosMasBuscados &&
							data?.productosMasBuscados?.map((d, idx) => (
								<SwiperSlide key={`marketInterest_slide_${idx}`}>
									<OfferSlideContainer
										id={d.productId}
										title={d.title}
										image={d.photoUrl}
										price={d.price}
										offerPrice={d.offerPrice}
										offerTo={d.offerTo}
										discount={d.discount}
										labImage={d.clientPhotoUrl}
										qtyInCart={d.productQtyInCart}
										clientName={d.clientName}
										productPresentation={d.presentationName}
										productQty={d.presentationQty}
										productUnit={d.presentationUnit}
										marketing={d.marketingId}
										clientTypeOfSale={d.clientTypeOfSale}
										triggerQty={d.triggerQty}
										triggerDiscount={d.triggerDiscount}
										minQuantity={d.minQuantity}
									/>
								</SwiperSlide>
							))}
					</SliderProduct>
				</Section>
			) : null}

			<Section>
				<SectionTitle>
					<Typo level={4} type="primary">
						No te lo pierdas
					</Typo>
				</SectionTitle>
				<SectionBanners>
					<Slider
						loading={loading}
						slidesPerView={3}
						slidesPerGroup={3}
						spaceBetween={5}
						navigation={true}
						opacityValue={0.5}
					>
						{tripleBannerImages &&
							tripleBannerImages.map((d, idx) => (
								<SwiperSlide key={`publicity_slide_${idx}`}>
									<PublicitySlideContainer
										id={d.id}
										title={d.title}
										image={d.src}
										bannerUrl={d.bannerUrl}
										bannerHeight={"160px"}
										bannerBorder={"10px"}
										bannerType={CONSTANTS.TRIPLE_BANNER_HOME}
									/>
								</SwiperSlide>
							))}
					</Slider>
				</SectionBanners>
			</Section>

			{/* {data?.categoriaElegida?.length > 5 ? (
				<Section>
					<SectionTitle>
						<Typo level={4} type="primary">
							Productos en {data.selectedCategory}
						</Typo>
						<ButtonOffer onClick={handleCategoryTwoButton}>
							Ver Todas
						</ButtonOffer>
					</SectionTitle>
					<SliderProduct
						slidesPerView={6}
						slidesPerGroup={6}
						delay={false}
						navigation={true}
					>
						{data?.categoriaElegida &&
							data?.categoriaElegida?.map((d, idx) => (
								<SwiperSlide key={`category2_slide_${idx}`}>
									<OfferSlideContainer
										id={d.productId}
										title={d.title}
										image={d.photoUrl}
										price={d.price}
										offerPrice={d.offerPrice}
										offerTo={d.offerTo}
										discount={d.discount}
										labImage={d.clientPhotoUrl}
										qtyInCart={d.productQtyInCart}
										clientName={d.clientName}
										productPresentation={d.presentationName}
										productQty={d.presentationQty}
										productUnit={d.presentationUnit}
										marketing={d.marketingId}
										clientTypeOfSale={d.clientTypeOfSale}
										triggerQty={d.triggerQty}
										triggerDiscount={d.triggerDiscount}
										minQuantity={d.minQuantity}
									/>
								</SwiperSlide>
							))}
					</SliderProduct>
				</Section>
			) : null} */}

			{/* {data?.ultimasCompras?.length > 5 ? (
				<Section>
					<SectionTitle>
						<Typo level={4} type="primary">
							Volvé a comprar
						</Typo>
						<ButtonOffer onClick={handleBuyedListButton}>Ver Todas</ButtonOffer>
					</SectionTitle>
					<SliderProduct
						slidesPerView={6}
						slidesPerGroup={6}
						delay={false}
						navigation={true}
					>
						{data?.ultimasCompras &&
							data?.ultimasCompras?.map((d, idx) => (
								<SwiperSlide key={`lastBuys_slide_${idx}`}>
									<OfferSlideContainer
										id={d.productId}
										title={d.title}
										image={d.photoUrl}
										price={d.price}
										offerPrice={d.offerPrice}
										offerTo={d.offerTo}
										discount={d.discount}
										labImage={d.clientPhotoUrl}
										qtyInCart={d.productQtyInCart}
										clientName={d.clientName}
										productPresentation={d.presentationName}
										productQty={d.presentationQty}
										productUnit={d.presentationUnit}
										marketing={d.marketingId}
										clientTypeOfSale={d.clientTypeOfSale}
										triggerQty={d.triggerQty}
										triggerDiscount={d.triggerDiscount}
										minQuantity={d.minQuantity}
									/>
								</SwiperSlide>
							))}
					</SliderProduct>
				</Section>
			) : null} */}

			{data?.rx?.length > 0 ? (
				<Section>
					<SectionTitle>
						<Typo level={4} type="primary">
							Ofertas en Productos Rx
						</Typo>
						<ButtonOffer onClick={handleRxButton}>Ver Todas</ButtonOffer>
					</SectionTitle>
					<SliderProduct
						slidesPerView={6}
						slidesPerGroup={6}
						delay={false}
						navigation={true}
					>
						{data &&
							data.rx.map((d, idx) => (
								<SwiperSlide key={`offers_slide_${idx}`}>
									<OfferSlideContainer
										id={d.productId}
										title={d.title}
										image={d.photoUrl}
										price={d.price}
										offerPrice={d.offerPrice}
										offerTo={d.offerTo}
										discount={d.discount}
										labImage={d.clientPhotoUrl}
										qtyInCart={d.productQtyInCart}
										clientName={d.clientName}
										productPresentation={d.presentationName}
										productQty={d.presentationQty}
										productUnit={d.presentationUnit}
										marketing={d.marketingId}
										clientTypeOfSale={d.clientTypeOfSale}
										triggerQty={d.triggerQty}
										triggerDiscount={d.triggerDiscount}
										minQuantity={d.minQuantity}
									/>
								</SwiperSlide>
							))}
					</SliderProduct>
				</Section>
			) : null}

			{data?.otc?.length > 0 ? (
				<Section>
					<SectionTitle>
						<Typo level={4} type="primary">
							Ofertas en Productos OTC
						</Typo>
						<ButtonOffer onClick={handleOTCButton}>Ver Todas</ButtonOffer>
					</SectionTitle>
					<SliderProduct
						slidesPerView={6}
						slidesPerGroup={6}
						delay={false}
						navigation={true}
					>
						{data &&
							data.otc.map((d, idx) => (
								<SwiperSlide key={`offers_slide_${idx}`}>
									<OfferSlideContainer
										id={d.productId}
										title={d.title}
										image={d.photoUrl}
										price={d.price}
										offerPrice={d.offerPrice}
										offerTo={d.offerTo}
										discount={d.discount}
										labImage={d.clientPhotoUrl}
										qtyInCart={d.productQtyInCart}
										clientName={d.clientName}
										productPresentation={d.presentationName}
										productQty={d.presentationQty}
										productUnit={d.presentationUnit}
										marketing={d.marketingId}
										clientTypeOfSale={d.clientTypeOfSale}
										triggerQty={d.triggerQty}
										triggerDiscount={d.triggerDiscount}
										minQuantity={d.minQuantity}
									/>
								</SwiperSlide>
							))}
					</SliderProduct>
				</Section>
			) : null}

			{/* {data?.consumoMasivo?.length > 0 ? (
				<Section>
					<SectionTitle>
						<Typo level={4} type="primary">
							Ofertas en Consumo Masivo
						</Typo>
						<ButtonOffer onClick={handleConsumoMasivoButton}>
							Ver Todas
						</ButtonOffer>
					</SectionTitle>
					<SliderProduct
						slidesPerView={6}
						slidesPerGroup={6}
						delay={false}
						navigation={true}
					>
						{data &&
							data.consumoMasivo.map((d, idx) => (
								<SwiperSlide key={`offers_slide_${idx}`}>
									<OfferSlideContainer
										id={d.productId}
										title={d.title}
										image={d.photoUrl}
										price={d.price}
										offerPrice={d.offerPrice}
										offerTo={d.offerTo}
										discount={d.discount}
										labImage={d.clientPhotoUrl}
										qtyInCart={d.productQtyInCart}
										clientName={d.clientName}
										productPresentation={d.presentationName}
										productQty={d.presentationQty}
										productUnit={d.presentationUnit}
										marketing={d.marketingId}
										clientTypeOfSale={d.clientTypeOfSale}
										triggerQty={d.triggerQty}
										triggerDiscount={d.triggerDiscount}
										minQuantity={d.minQuantity}
									/>
								</SwiperSlide>
							))}
					</SliderProduct>
				</Section>
			) : null} */}

			<AntdModal
				visible={showWelcomeModal}
				width={800}
				onOk={handleOk}
				onCancel={handleCancel}
				centered={true}
				footer={null}
				destroyOnClose={true}
			>
				<WelcomeModal
					redirectToGuide={redirectToGuide}
					userdata={userdata}
					skipGuide={skipGuide}
				/>
			</AntdModal>
		</Inner>
	);
}
