import { Button } from "antd";
import React, { useState } from "react";
import { send_verification_email } from "../../../../api/endpoints/auth";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import Image from "../../../common/image/Image";
import MarketLogo from "../../../../assets/images/Onetransfer-logo.png";
import { Typo } from "../../../layout/theme";

export default function EmailVerification() {
	const [sent, setSent] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const resendMail = async () => {
		try {
			setSubmitting(true);
			await send_verification_email();
			setSent(true);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Correo enviado",
				"Verifique su casilla de correo"
			);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"No se pudo enviar el correo",
				"Un error ocurrio al intentar enviar el correo de verificacion."
			);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<SignInTemplate>
			<SignInTemplate.Main>
				<SignInTemplate.Logo>
					<Image
						src={MarketLogo}
						alt="Logo FarmaTransfer"
						height="45px"
						width="220px"
					/>
				</SignInTemplate.Logo>
				<SignInTemplate.Top>
					<SignInTemplate.Title>
						Verificación de correo electronico necesaria
					</SignInTemplate.Title>
					<Typo level={4}>
						Por favor revise su casilla de correo, le enviamos un mail con un
						link.
					</Typo>
				</SignInTemplate.Top>
				{!sent ? (
					<>
						<SignInTemplate.Desc color="Black">
							Si no recibio el mail de verificacion puede solicitar uno nuevo
							haciendo click en el siguiente boton
						</SignInTemplate.Desc>
						<SignInTemplate.Break height={"20px"} />
						<Button type="primary" onClick={resendMail} loading={submitting}>
							Reenviar correo de verificacion
						</Button>
					</>
				) : (
					<>
						<SignInTemplate.Break height="15px" />
						<SignInTemplate.Desc color="Black">
							Correo enviado, verifique su casilla.
						</SignInTemplate.Desc>
					</>
				)}
			</SignInTemplate.Main>
		</SignInTemplate>
	);

	// return (
	//   <Inner>
	//     <Title>Debe verificar su correo electronico antes de continuar</Title>
	//     <Subtitle>
	//       Por favor revise su casilla de correo, le enviamos un mail con un link.
	//     </Subtitle>

	//     {!sent ? (
	//       <>
	//         <Subtitle>
	//           Si no recibio el mail de verificacion puede solicitar uno nuevo
	//           haciendo click en el siguiente boton
	//         </Subtitle>
	//         <Button type="primary" onClick={resendMail} loading={submitting}>
	//           Reenviar correo de verificacion
	//         </Button>
	//       </>
	//     ) : (
	//       <>
	//         <Subtitle>Correo enviado, verifique su casilla.</Subtitle>
	//       </>
	//     )}
	//   </Inner>
	// );
}
