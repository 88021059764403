import axiosInstance from "../axiosInstance";

const CONTROLLER = "coupons";

const get_coupons = async (search, clientId, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search}&clientId=${clientId}&state=${state}`
	);
	return response.data;
};

const get_coupons_header_info = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetCouponsHeader?clientId=${clientId}`
	);
	return response.data;
};

const get_coupon_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetCouponById/${id}`);
	return response.data;
};

const update_coupon = async (id, data) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_coupon_state = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateCouponState`,
		state
	);
	return response.data;
};

const create_coupon = async (data) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const validate_coupon_code = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ValidateCouponCode`,
		data
	);
	return response.data;
};

export {
	get_coupons,
	get_coupons_header_info,
	update_coupon_state,
	create_coupon,
	get_coupon_by_id,
	update_coupon,
	validate_coupon_code,
};
