import axiosInstance from "../axiosInstance";

const CONTROLLER = "billers";

const get_billers = async () => {
	const response = await axiosInstance.get(CONTROLLER);
	return response.data;
};

const get_active_billers = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}`
	);
	return response.data;
};

const get_billers_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_billers_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const get_billers_header_info = async (search) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetBillersHeader`);
	return response.data;
};

const update_biller = async (id, biller) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, biller, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_biller_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateBillerState/${id}`,
		state
	);
	return response.data;
};

const create_biller = async (biller) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, biller, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const download_file_in_container = async (filePath, container) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/DownloadFileContent?filePath=${filePath}&fileContainer=${container}`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

export {
	get_active_billers,
	get_billers_page,
	get_billers_by_id,
	get_billers_header_info,
	update_biller,
	update_biller_state,
	create_biller,
	get_billers,
	download_file_in_container,
};
