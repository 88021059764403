import styled from "styled-components/macro";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 20px;
`;

export const PublishContainer = styled.div`
	flex: 1 1 50%;
	background-color: ${COLORS.SkeletonGrey};
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 1rem;
	border-radius: 10px;
	flex-direction: column;
	gap: 10px;
`;

export const CheckBoxDiv = styled.div`
	height: 20px;
	width: 20px;
	background-color: ${COLORS.SkeletonGrey};
	border: 2px solid ${COLORS.Grey};
`;
