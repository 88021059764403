import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../../../layout/main/Main";
import { Space, Radio, Dropdown, Menu, Modal } from "antd";
import {
	CloseCircleOutlined,
	InfoCircleOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import {
	ActionButton,
	AntdRadioButton,
	Filters,
	RadioOptions,
	TableContainer,
} from "./styles";
import LoadingHeader from "./components/LoadingHeader";
import Image from "../../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import { COLORS, Typo } from "../../../../layout/theme";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import DataTable from "../../../../common/data-table/DataTable";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import {
	get_billers_header_info,
	get_billers_page,
	update_biller_state,
} from "../../../../../api/endpoints/billers";
import { CONSTANTS } from "../../../../../utils/constants";

const { confirm } = Modal;

export default function BillersList() {
	const navigate = useNavigate();
	const [headerInfo, setHeaderInfo] = useState(null);
	const [data, setData] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [key, setKey] = useState("null");
	const [loading, setLoading] = useState(false);

	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "title",
			render: (value, record) => (
				<Link
					to={`/admin/billers/${key.id}`}
					onMouseOver={() => setKey(record)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Link>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Conexión</div>,
			dataIndex: "connectionType",
			render: (value, record) => (
				<Link
					to={`/admin/billers/${key.id}`}
					onMouseOver={() => setKey(record)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{
							CONSTANTS.BILLER_CONNECTIONS_TYPE.find(
								(elemento) => elemento.value === value
							)?.label
						}
					</Typo>
				</Link>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={r.isActive ? menu : menuInactive}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
							{console.log(r)}
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/billers/${key.id}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleDelete(key)}>Desactivar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/billers/${key.id}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleActivate(key)}>Reactivar</Typo>,
				},
			]}
		/>
	);

	const fetch_billers = async (page, state, search) => {
		const res = await get_billers_page(search, state, page);
		setData(res);
	};

	const fetch_billers_header = async () => {
		const res = await get_billers_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_billers_header();
	}, [state]);

	useEffect(() => {
		fetch_billers(1, state, "");
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_billers(1, state, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleDelete = (dist) => {
		confirm({
			title: "¿Seguro que quiere desactivar este facturador?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La desactivación del facturador se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleDeleteSecondWarning(dist);
			},
		});
	};

	const handleDeleteSecondWarning = (dist) => {
		confirm({
			title:
				"Tenga en cuenta que al continuar con esta acción el facturador quedará inaccesible en el sistema",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "Desea proceder de todas maneras?",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				deleteBiller(dist);
			},
		});
	};

	const deleteBiller = async (biller) => {
		setLoading(true);
		const _state = { id: biller.id, state: "DISABLED" };
		await update_biller_state(biller.id, _state);
		fetch_billers(1, state, "");
		fetch_billers_header();
		setLoading(false);
	};

	const handleActivate = (biller) => {
		confirm({
			title: "¿Seguro que quiere reactivar este facturador?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "La reactivación del facturador se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				activateTemplate(biller);
			},
		});
	};

	const activateTemplate = async (biller) => {
		setLoading(true);
		try {
			const _state = { id: biller.id, state: "ACTIVE" };
			await update_biller_state(biller.id, _state);
			fetch_billers(1, state, "");
			fetch_billers_header();
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Facturadores</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<AntdSearch
								width={400}
								allowClear
								placeholder="Buscar facturador"
								onChange={debouncedEventHandler}
							/>
							<DropShadow type="drop">
								<SignInTemplate.AntdButton
									type="primary"
									bg="Primary"
									color="White"
									icon={<PlusOutlined />}
									onClick={() => navigate("/admin/billers/create")}
									style={{ width: "200px", fontWeight: "400" }}
								>
									Crear Facturador
								</SignInTemplate.AntdButton>
							</DropShadow>
						</Space>
					)}
				</Filters>
				<RadioOptions>
					<Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
						<AntdRadioButton value="ACTIVE">
							Activo ({headerInfo?.totalActive})
						</AntdRadioButton>
						<AntdRadioButton value="DELETED">
							Inactivo ({headerInfo?.totalDisabled})
						</AntdRadioButton>
					</Radio.Group>
				</RadioOptions>
				<TableContainer>
					<DataTable
						data={data}
						fetch={fetch_billers}
						columns={columns}
						loading={loading}
					/>
				</TableContainer>
			</Main.Body>
		</Main>
	);
}
