import { Spin, Typography } from "antd";
import React, { useEffect } from "react";
import Main from "../../../layout/main/Main";
import { COLORS } from "../../../layout/theme";
import { BodyContainer, LoadingSection } from "./styles";
import { useSearchParams } from "react-router-dom";

const { Title, Paragraph } = Typography;

export default function RedirectPage() {
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const redirectPage = searchParams.get("uri") ?? "/";
		window.location.replace(redirectPage);
	}, []);

	return (
		<Main>
			<LoadingSection>
				<BodyContainer>
					<Title style={{ color: COLORS.Primary, marginTop: 30 }} level={3}>
						Centro de Soporte
					</Title>
					<Paragraph>
						<blockquote>
							Estamos redireccionandote a nuestro centro de soporte
						</blockquote>
					</Paragraph>
					<Spin size="large" />
				</BodyContainer>
			</LoadingSection>
		</Main>
	);
}
