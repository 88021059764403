import {
	ApartmentOutlined,
	BookOutlined,
	ClusterOutlined,
	DatabaseOutlined,
	DollarOutlined,
	ExperimentOutlined,
	FileSearchOutlined,
	GoldOutlined,
	IdcardOutlined,
	MobileOutlined,
	SearchOutlined,
	CopyOutlined,
	StarOutlined,
	RiseOutlined,
	GlobalOutlined,
	TableOutlined,
	UserSwitchOutlined,
	BarChartOutlined,
	SolutionOutlined,
	DollarCircleOutlined,
	TeamOutlined,
	SnippetsOutlined,
	BankOutlined,
	UserOutlined,
	CalendarOutlined,
	AuditOutlined,
	SettingOutlined,
	ProjectOutlined,
	KeyOutlined,
	LockOutlined,
} from "@ant-design/icons";
import React from "react";
import { AiOutlineLineChart } from "react-icons/ai";
import { BiImport, BiPhotoAlbum } from "react-icons/bi";
import { MdOutlineLocalOffer, MdOutlinePlace } from "react-icons/md";
import {
	RiArrowDownSFill,
	RiArrowUpSFill,
	RiDashboardLine,
} from "react-icons/ri";
import { COLORS } from "../../../layout/theme";
import { FaFireAlt, FaHeadset } from "react-icons/fa";

const AsideBarAdminOneMarketData = [
	{
		title: "Reportes",
		path: "#",
		icon: <AiOutlineLineChart color={COLORS.Primary} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Dashboard",
				path: "/admin/dashboard",
				icon: <RiDashboardLine color={COLORS.Primary} />,
			},
			{
				title: "Ventas",
				path: "/admin/salesReport",
				icon: <DollarCircleOutlined style={{ color: COLORS.Primary }} />,
			},
			// {
			// 	title: "Productos",
			// 	path: "/admin/productReport",
			// 	icon: <RiseOutlined style={{ color: COLORS.Primary }} />,
			// },
			{
				title: "Banners",
				path: "/admin/bannerReport",
				icon: <BarChartOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Administración",
		path: "#",
		icon: <RiDashboardLine color={COLORS.Primary} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Auditoría",
				path: "/admin/auditory",
				icon: <BiImport style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Ordenes",
				path: "/admin/marketOrders",
				icon: <AiOutlineLineChart style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Archivos",
				path: "/admin/sendedFiles",
				icon: <FileSearchOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Cuentas",
		path: "#",
		icon: <DatabaseOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Clientes",
				path: "/admin/clients",
				icon: <DatabaseOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Usuarios",
				path: "/admin/marketUsers",
				icon: <IdcardOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Puntos de Venta",
				path: "/admin/marketPos",
				icon: <MdOutlinePlace style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Negocio",
		path: "#",
		icon: <BankOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Droguerías",
				path: "/admin/distributors",
				icon: <ApartmentOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Distribuidores",
				path: "/admin/distributorsIndex",
				icon: <ClusterOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Rubros",
				path: "/admin/saledepartments",
				icon: <SearchOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Categorías",
				path: "/admin/categories",
				icon: <GoldOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Principios Activos",
				path: "/admin/activeIngredients",
				icon: <ExperimentOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Presentaciones",
				path: "/admin/presentations",
				icon: <CopyOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Facturadores",
				path: "/admin/billers",
				icon: <ClusterOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Accesos API",
				path: "/admin/administrationAccess",
				icon: <CopyOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Claves API",
				path: "/admin/administrationKeys",
				icon: <LockOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Tablas",
		path: "#",
		icon: <TableOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Productos",
				path: "/admin/marketProductsIndex",
				icon: <BookOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Importar/Exportar Productos",
				path: "/admin/import",
				icon: <BiImport style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Firebase Auth",
				path: "/admin/marketAuths",
				icon: <TeamOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Archivos Droguerias",
				path: "/admin/distributorResponse",
				icon: <SnippetsOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Mobile",
		path: "#",
		icon: <MobileOutlined color={COLORS.Primary} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Solicitudes Mobile",
				path: "/admin/mobileRequests",
				icon: <MobileOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "PDV Generales",
				path: "/admin/generalPointsOfSale",
				icon: <GlobalOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Importar PdV Generales",
				path: "/admin/importGeneralPos",
				icon: <BiImport style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Rutas Generales",
				path: "/admin/marketRoutes",
				icon: <ClusterOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Rutas de Usuario",
				path: "/admin/marketUserRoutes",
				icon: <UserSwitchOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Descuentos por Producto",
				path: "/admin/marketProductDiscounts",
				icon: <ProjectOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Descuentos por Usuario",
				path: "/admin/marketUserDiscounts",
				icon: <SolutionOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Configuraciones Mobile",
				path: "/admin/marketMobileConfigs",
				icon: <SettingOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Vendedores Web",
		path: "#",
		icon: <FaHeadset color={COLORS.Primary} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Descuentos por usuario",
				path: "/admin/marketWebSellers",
				icon: <UserOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Rutas Generales",
				path: "/admin/marketRoutes",
				icon: <ClusterOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Publicidad",
		path: "#",
		icon: <DollarOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Campañas",
				path: "/admin/advertising/campaigns",
				icon: <DollarOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Banners",
				path: "/admin/marketBannersIndex",
				icon: <BiPhotoAlbum style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Hot Sale",
				path: "/admin/marketHotSalesIndex",
				icon: <FaFireAlt style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Destacados",
				path: "/admin/featuredSettings",
				icon: <StarOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Cupones",
				path: "/admin/marketCoupons",
				icon: <MdOutlineLocalOffer style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Eventos",
		path: "#",
		icon: <CalendarOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Expo",
				path: "/admin/expoEventOT",
				icon: <AuditOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Solicitudes",
		path: "#",
		icon: <FileSearchOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Solicitudes de Farmacias",
				path: "/admin/marketRequests",
				icon: <FileSearchOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
];

const AsideBarAccountManagerOneMarketData = [
	{
		title: "Administración",
		path: "#",
		icon: <RiDashboardLine color={COLORS.Primary} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Dashboard",
				path: "/admin/dashboard",
				icon: <RiDashboardLine color={COLORS.Primary} />,
			},
			{
				title: "Auditoría",
				path: "/admin/auditory",
				icon: <BiImport style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Ordenes",
				path: "/admin/marketOrders",
				icon: <AiOutlineLineChart style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Negocio",
		path: "#",
		icon: <ClusterOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Droguerías",
				path: "/admin/distributors",
				icon: <ApartmentOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Distribuidores",
				path: "/admin/distributorsIndex",
				icon: <ClusterOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Rubros",
				path: "/admin/saledepartments",
				icon: <SearchOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Categorías",
				path: "/admin/categories",
				icon: <GoldOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Principios Activos",
				path: "/admin/activeIngredients",
				icon: <ExperimentOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Productos",
		path: "#",
		icon: <BookOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Productos",
				path: "/admin/marketProductsIndex",
				icon: <BookOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Importar/Exportar Productos",
				path: "/admin/import",
				icon: <BiImport style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Publicidad",
		path: "#",
		icon: <DollarOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Campañas",
				path: "/admin/advertising/campaigns",
				icon: <DollarOutlined style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Banners",
				path: "/admin/marketBannersIndex",
				icon: <BiPhotoAlbum style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Hot Sale",
				path: "/admin/marketHotSalesIndex",
				icon: <MdOutlineLocalOffer style={{ color: COLORS.Primary }} />,
			},
		],
	},
	{
		title: "Solicitudes",
		path: "#",
		icon: <FileSearchOutlined style={{ color: COLORS.Primary }} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Solicitudes de Farmacias",
				path: "/admin/marketRequests",
				icon: <FileSearchOutlined style={{ color: COLORS.Primary }} />,
			},
		],
	},
];

const AsideBarSellerOneMarketData = [
	{
		title: "Administración",
		path: "#",
		icon: <RiDashboardLine color={COLORS.Primary} />,
		iconClosed: <RiArrowDownSFill color={COLORS.Primary} />,
		iconOpened: <RiArrowUpSFill color={COLORS.Primary} />,

		subNav: [
			{
				title: "Dashboard",
				path: "/admin/dashboard",
				icon: <RiDashboardLine color={COLORS.Primary} />,
			},
			{
				title: "Auditoría",
				path: "/admin/auditory",
				icon: <BiImport style={{ color: COLORS.Primary }} />,
			},
			{
				title: "Ordenes",
				path: "/admin/marketOrders",
				icon: <AiOutlineLineChart style={{ color: COLORS.Primary }} />,
			},
		],
	},
];

export function checkOneMarketMenu(userdata) {
	if (
		userdata.p_users &&
		userdata.p_client &&
		userdata.p_dashboard &&
		userdata.p_sales
	) {
		return AsideBarAdminOneMarketData;
	} else if (userdata.p_products && userdata.p_dashboard && userdata.p_sales) {
		return AsideBarAccountManagerOneMarketData;
	} else if (userdata.p_shop && userdata.p_sales && userdata.p_dashboard) {
		return AsideBarSellerOneMarketData;
	} else if (userdata.p_dashboard && userdata.p_sales && !userdata.p_shop) {
		return AsideBarSellerOneMarketData;
	} else {
		return [];
	}
}
