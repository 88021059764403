import styled from "styled-components/macro";
import { COLORS } from "../../../../../../layout/theme/colors/Colors";

export const Inner = styled.div`
  display: flex;
  width: 100%;
`;

export const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 50px;
`;
export const ProductData = styled.div`
  flex: 1 1 70%;
  height: 500px;
  background-color: ${COLORS.SkeletonGrey};
`;
export const PublishSettings = styled.div`
  flex: 1 1 30%;
  height: 500px;
  background-color: ${COLORS.SkeletonGrey};
`;

export const Container = styled.div`
  padding: 2rem;
`;

export const PublishContainer = styled.div`
  height: 450px;
  width: 350px;
  background-color: ${COLORS.SkeletonGrey};
  padding: 2rem;
  margin-top: 10px;
  margin-right: 0px;
`;

export const Line = styled.div`
  height: 5px;
  width: 100%;
  background-color: ${COLORS.SkeletonGrey};
`;

export const TextLine = styled.div`
  height: ${(props) => (props.ySize ? props.ySize : "10px")};
  width: ${(props) => (props.xSize ? props.xSize : "200px")};
  background-color: ${COLORS.SkeletonGrey};
`;
