import styled from "styled-components";
import { Card } from "antd";
import { COLORS } from "../../../../../layout/theme";

export const AntdCard = styled(Card)`
	min-height: 50%;
	border-radius: 15px;
	display: flex;
	flex: 1 1 25%;
	flex-direction: column;
	justify-items: start;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const TableContainer = styled.div`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const Container = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	gap: 10px;
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const IconGroupContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
`;

export const IconContainer = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	background-color: ${(props) =>
		props.$active ? COLORS.Primary : COLORS.White};
	color: ${(props) => (props.$active ? COLORS.White : COLORS.MediumGrey)};
	border-color: ${COLORS.Primary};
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  
	.&hover {
		background-color: ${COLORS.Default};
	}
`;
