import { Input, Modal } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 20px;
	margin-bottom: 30px;
`;

export const PreviewImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: contain;
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 20px;
`;

export const SectionTitle = styled.div`
	color: ${COLORS.Primary};
	font-height: ${SIZES.level6};
	font-weight: ${WEIGHT.Bold};
	margin: 0 10px 20px 0;
`;

export const LeftCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	flex: 1 1 70%;
	background-color: ${COLORS.White};
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
`;

export const RightCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex: 1 1 30%;
	background-color: ${COLORS.White};
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
`;

export const BottomCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: ${COLORS.White};
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
	margin-bottom: 30px;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 20px;
`;
export const AddSpecialCommisionContainer = styled.div`
	display: flex;
	gap: 10px;
	height: 30px;
	align-items: center;
	cursor: pointer;
	width: 35%;
`;

export const AntdModal = styled(Modal)`
	.ant-modal-header {
		border-radius: 10px 10px 0 0;
	}

	.ant-modal-footer {
		border-radius: 0 0 10px 10px;
	}

	.ant-modal-content {
		border-radius: 10px;
	}
`;
