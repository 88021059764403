import { numberWithDotAndCommas } from "../../../../../../../../utils/numberWithDotAndComas";
import { COLORS, Typo } from "../../../../../../../layout/theme";
import { OrderProductsTable } from "./styles";

export default function OrderTable({ data }) {
	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "productTitle",
			render: (value) => (
				<Typo type="terciary" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio</div>,
			dataIndex: "price",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					${numberWithDotAndCommas(value)}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "offerPrice",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{`${numberWithDotAndCommas(
						(100 - (record.offerPrice * 100) / record.price).toFixed(2)
					)}%`}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cantidad</div>,
			dataIndex: "quantity",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Final</div>,
			dataIndex: "offerPrice",
			render: (value, r) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					${numberWithDotAndCommas((value * r.quantity).toFixed(2))}
				</Typo>
			),
		},
	];

	return (
		<OrderProductsTable
			showHeader={true}
			columns={columns}
			dataSource={data.items}
			pagination={false}
		/>
	);
}
