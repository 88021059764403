import axiosInstance from "../axiosInstance";

const CONTROLLER = "accessTypes";

const get_access_types = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}`
	);
	return response.data;
};

const get_access_types_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_access_type_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const create_access_type = async (activeIngredient) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, activeIngredient);
	return response.data;
};

const update_access_type = async (id, activeIngredient) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/${id}`,
		activeIngredient
	);
	return response.data;
};

const get_access_types_header_info = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetAccessTypesHeader`
	);
	return response.data;
};

const update_access_type_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateAccessTypeState/${id}`,
		state
	);
	return response.data;
};

export {
	get_access_types,
	get_access_types_page,
	create_access_type,
	get_access_type_by_id,
	update_access_type,
	get_access_types_header_info,
	update_access_type_state,
};
