import { Table } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../../../../layout/theme";

export const ContainerDiv = styled.div`
	width: 100%;
	overflow: auto;
	direction: rtl;

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.SkeletonGrey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}
`;

export const InfoPrice = styled.div`
	background-color: ${COLORS.Primary};
	border-radius: 5px;
	width: 60px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.White};
	font-size: ${SIZES.level9};
	align-content: center;
	margin-bottom: 15px;
`;

export const InfoTitle = styled.div`
	color: ${COLORS.Primary};
	font-size: ${SIZES.level12};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoClient = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level7};
	font-weight: ${WEIGHT.Bold};
`;

export const InfoQuantity = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.Bold};
`;

export const CartDelete = styled.div`
	color: ${COLORS.Grey};
	font-family: sans-serif;
	font-stretch: ultra-expanded;
	font-size: ${SIZES.level12};
	font-weight: ${WEIGHT.Bold};
`;

export const AntdTable = styled(Table)`
	direction: ltr;

	.ant-empty-description {
		visibility: hidden;
	}

	.ant-empty-description::after {
		content: "No hay productos";
		visibility: visible;
		display: block;
		margin-top: -10px;
	}

	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}

	.ant-table-row td::-webkit-scrollbar {
		display: none;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const TriggerQtyContainer = styled.div`
	color: ${COLORS.StrongGreen};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level8};
	text-align: center;
	position: absolute;
	bottom: 5px;
	width: 90px;
`;
