import { Skeleton, Row, Col, Space } from "antd";
import {
  Inner,
  ProductData,
  PublishContainer,
  PublishSettings,
} from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
  return (
    <Inner>
      <ProductData>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row></Row>
          <Row></Row>
          <Row></Row>
          <Row gutter={GUTTER} style={{ width: 1000 }}>
            <Col span={16}>
              <Skeleton
                avatar
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 1200, marginLeft: 30 }}
              />
            </Col>
          </Row>
          <Row></Row>
          <Row></Row>
          <Row gutter={GUTTER} style={{ width: 1000 }}>
            <Col span={16}>
              <Skeleton
                avatar
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 1200, marginLeft: 30 }}
              />
            </Col>
          </Row>
          <Row></Row>
          <Row></Row>
          <Row gutter={GUTTER} style={{ width: 1000 }}>
            <Col span={16}>
              <Skeleton
                avatar
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 1200, marginLeft: 30 }}
              />
            </Col>
          </Row>
          <Row></Row>
          <Row></Row>
          <Row gutter={GUTTER} style={{ width: 1000 }}>
            <Col span={16}>
              <Skeleton
                avatar
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 1200, marginLeft: 30 }}
              />
            </Col>
          </Row>
          <Row></Row>
          <Row></Row>
          <Row gutter={GUTTER} style={{ width: 1000 }}>
            <Col span={16}>
              <Skeleton
                avatar
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 1200, marginLeft: 30 }}
              />
            </Col>
          </Row>
          <Row></Row>
          <Row></Row>
          <Row gutter={GUTTER} style={{ width: 1000 }}>
            <Col span={16}>
              <Skeleton
                avatar
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 1200, marginLeft: 30 }}
              />
            </Col>
          </Row>
          <Row></Row>
          <Row></Row>
          <Row gutter={GUTTER} style={{ width: 1000 }}>
            <Col span={16}>
              <Skeleton
                avatar
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 1200, marginLeft: 30 }}
              />
            </Col>
          </Row>

          <Row></Row>
        </Space>
      </ProductData>

      <PublishSettings>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <PublishContainer>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Skeleton.Input
                  active={true}
                  size="small"
                  style={{
                    width: "250px",
                    marginLeft: "20px",
                  }}
                />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Skeleton.Input
                  active={true}
                  size="small"
                  style={{
                    marginTop: "40px",
                    width: "250px",
                    marginLeft: "20px",
                  }}
                />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Skeleton.Input
                  active={true}
                  size="small"
                  style={{
                    marginTop: "30px",
                    width: "250px",
                    marginLeft: "20px",
                  }}
                />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Skeleton.Input
                  active={true}
                  size="small"
                  style={{
                    marginTop: "30px",
                    width: "250px",
                    marginLeft: "20px",
                  }}
                />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Skeleton.Input
                  active={true}
                  size="small"
                  style={{
                    marginTop: "50px",
                    width: "250px",
                    marginLeft: "20px",
                  }}
                />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={10}>
                <Skeleton.Input
                  active={true}
                  size="large"
                  style={{
                    marginTop: "70px",
                    width: "100px",
                    marginLeft: "45px",
                  }}
                />
              </Col>
            </Row>
          </PublishContainer>
        </Space>
      </PublishSettings>
    </Inner>
  );
}
