import React from "react";
import {
	Inner,
	Header,
	HeaderLeft,
	HeaderRight,
	Main,
	Footer,
	FooterPrices,
	FooterPricesLeft,
	FooterPricesLeftTextContainer,
	FooterPricesLeftTextBox,
	FooterPricesRight,
	FooterPricesCenter,
	SectionContainer,
	FooterPriceTextOff,
	FooterCart,
	FooterInputs,
	SpinnerSection,
	Spinner,
} from "./styles";

const ProductSearchCard = ({ children, ...restProps }) => (
	<Inner {...restProps}>{children}</Inner>
);

export default ProductSearchCard;

ProductSearchCard.Header = ({ children, ...restProps }) => {
	return <Header {...restProps}>{children}</Header>;
};
ProductSearchCard.HeaderLeft = ({ children, ...restProps }) => {
	return <HeaderLeft {...restProps}>{children}</HeaderLeft>;
};
ProductSearchCard.HeaderRight = ({ children, ...restProps }) => {
	return <HeaderRight {...restProps}>{children}</HeaderRight>;
};

ProductSearchCard.Main = ({ children, ...restProps }) => {
	return <Main {...restProps}>{children}</Main>;
};

ProductSearchCard.SectionContainer = ({ children, ...restProps }) => {
	return <SectionContainer {...restProps}>{children}</SectionContainer>;
};

ProductSearchCard.Footer = ({ children, ...restProps }) => {
	return <Footer {...restProps}>{children}</Footer>;
};

ProductSearchCard.FooterPrices = ({ children, ...restProps }) => {
	return <FooterPrices {...restProps}>{children}</FooterPrices>;
};
ProductSearchCard.FooterPriceTextOff = ({ children, ...restProps }) => {
	return <FooterPriceTextOff {...restProps}>{children}</FooterPriceTextOff>;
};

ProductSearchCard.FooterPricesLeft = ({ children, ...restProps }) => {
	return <FooterPricesLeft {...restProps}>{children}</FooterPricesLeft>;
};

ProductSearchCard.FooterPricesLeftTextContainer = ({
	children,
	...restProps
}) => {
	return (
		<FooterPricesLeftTextContainer {...restProps}>
			{children}
		</FooterPricesLeftTextContainer>
	);
};

ProductSearchCard.FooterPricesLeftTextBox = ({ children, ...restProps }) => {
	return (
		<FooterPricesLeftTextBox {...restProps}>{children}</FooterPricesLeftTextBox>
	);
};

ProductSearchCard.FooterPricesRight = ({ children, ...restProps }) => {
	return <FooterPricesRight {...restProps}>{children}</FooterPricesRight>;
};

ProductSearchCard.FooterPricesCenter = ({ children, ...restProps }) => {
	return <FooterPricesCenter {...restProps}>{children}</FooterPricesCenter>;
};

ProductSearchCard.FooterCart = ({ children, ...restProps }) => {
	return <FooterCart {...restProps}>{children}</FooterCart>;
};

ProductSearchCard.FooterInputs = ({ children, ...restProps }) => {
	return <FooterInputs {...restProps}>{children}</FooterInputs>;
};
ProductSearchCard.Spinner = ({ children, ...restProps }) => {
	return <Spinner {...restProps}>{children}</Spinner>;
};

ProductSearchCard.SpinnerSection = ({ children, ...restProps }) => {
	return <SpinnerSection {...restProps}>{children}</SpinnerSection>;
};
