import { Skeleton, Row, Col, Space } from "antd";
import {
  BottomContainer,
  Inner,
  Line,
  ProductData,
  PublishSettings,
} from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
  return (
    <Inner>
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", width: "100%" }}
      >
        <Row gutter={GUTTER}>
          <Col span={24}>
            <Skeleton.Input active={true} />{" "}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Skeleton.Input active={true} />{" "}
          </Col>
        </Row>
        <Row />
        <Row>
          <Col span={24}>
            <Skeleton.Input active={true} />{" "}
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Skeleton.Input active={true} />{" "}
          </Col>
          <Col span={6} offset={12}>
            <Skeleton.Input active={true} />{" "}
          </Col>
        </Row>
        <Line />
        <Row>
          <Col span={6}>
            <Skeleton.Input active={true} />{" "}
          </Col>
          <Col span={6} offset={12}>
            <Skeleton.Input active={true} />{" "}
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Skeleton.Input active={true} />{" "}
          </Col>
          <Col span={6} offset={12}>
            <Skeleton.Input active={true} />{" "}
          </Col>
        </Row>
        <Row />
        <Row>
          <Col span={6} offset={9}>
            <Skeleton.Input active={true} />{" "}
          </Col>
        </Row>
        <Line />
        <BottomContainer>
          <ProductData />
          <PublishSettings />
        </BottomContainer>
      </Space>
    </Inner>
  );
}
