import styled from "styled-components";
import { COLORS } from "../../../../layout/theme";

export const Inner = styled.div``;

export const FilterContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	margin-top: 20px;
	background-color: ${COLORS.LightGrey};
	padding: 1rem 2rem 0rem 2rem;
`;

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 50px;

	@media only screen and (min-width: 760px) and (max-width: 859px) {
		gap: 40px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media only screen and (min-width: 860px) and (max-width: 1180px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	@media only screen and (min-width: 1181px) and (max-width: 1270px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.Grey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}
`;

export const Container = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	${(props) =>
		props.width &&
		`
    width: ${props.width}px;
  `}
	height: 60px;

	&:hover {
		border-bottom: solid 2px ${COLORS.Secondary};
	}
`;

export const StoresContainerDiv = styled.div`
	height: 100%;
	width: 100%;
	overflow: hidden;
`;

export const ResultContainer = styled.div`
	padding: 2rem 4rem 2rem 4rem;
	width: 100%;
	overflow-x: hidden;
	overflow-y: none;
	background-color: rgb(243 244 246 / 1);
	&::-webkit-scrollbar {
		display: none;
	}
`;

export const CompaginationDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-top: 20px;
`;
