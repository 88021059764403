import React from "react";
import { Inner } from "./styles";
import DistributorsSelectorCoupon from "../../../../../../common/distributor-selector-coupon/DistributorsSelectorCoupon";

export default function DistributorModal({
	setDistributors,
	distributors,
	selectedClient,
}) {
	return (
		<Inner>
			<DistributorsSelectorCoupon
				onChange={setDistributors}
				initialValues={distributors}
				selectedClient={selectedClient}
			/>
		</Inner>
	);
}
