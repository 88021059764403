import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link } from "react-router-dom";
import {
	ButtonContainer,
	ChildrenContainer,
	AntdButton,
	ButtonContainerTop,
	Container,
} from "./styles";
import { get_market_brands } from "../../../../api/endpoints/brands";
import { get_client_products } from "../../../../api/endpoints/products";
import { get_sale_departments } from "../../../../api/endpoints/saledepartments";
import BannerList from "./banner/list/BannerList";
import { CONSTANTS } from "../../../../utils/constants";

export default function BannersPage() {
	const [filter, setFilter] = useState(CONSTANTS.BIG_BANNER_HOME);
	const [laboratories, setLaboratories] = useState(null);
	const [brands, setBrands] = useState(null);
	const [products, setProducts] = useState(null);
	const [saleDepartment, setSaleDepartment] = useState(null);

	const get_filter = () => {
		setFilter(
			localStorage.getItem("indexFilter")
				? localStorage.getItem("indexFilter")
				: "BIGBANNERHOME"
		);
	};

	useEffect(() => {
		get_filter();
	}, []);

	useEffect(() => {
		const fetch_clients = async () => {
			const res = await get_client_products();
			const hash = {};
			setLaboratories(
				res
					?.map((r) => ({
						clientId: r.client.id,
						clientName: r.client.name,
						clientSlug: r.client.slug,
					}))
					.filter((o) => (hash[o.clientId] ? false : (hash[o.clientId] = true)))
			);

			setProducts(
				res.map((c) => ({
					key: c.id,
					id: c.id,
					photoUrl: c.photoUrl,
					quantity: 1,
					price: c.price,
					offerPrice: c.offerPrice,
					title: c.title,
					clientId: c.client.id,
					clientName: c.client.name,
					brandId: c.brandId,
				}))
			);
		};

		const fetch_brands = async () => {
			const data = await get_market_brands();
			setBrands(data || []);
		};

		const fetch_sale_departments = async () => {
			const data = await get_sale_departments();
			setSaleDepartment(data || []);
		};

		fetch_clients();
		fetch_brands();
		fetch_sale_departments();
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Banners</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Container>
					<ButtonContainerTop>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setFilter(CONSTANTS.BIG_BANNER_HOME)}
							$active={filter === CONSTANTS.BIG_BANNER_HOME}
						>
							Principal Home
						</AntdButton>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setFilter(CONSTANTS.TRIPLE_CARD_BANNER_HOME)}
							$active={filter === CONSTANTS.TRIPLE_CARD_BANNER_HOME}
						>
							Card Banner Home
						</AntdButton>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setFilter(CONSTANTS.DOUBLE_BANNER_HOME)}
							$active={filter === CONSTANTS.DOUBLE_BANNER_HOME}
						>
							Doble Banner Home
						</AntdButton>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setFilter(CONSTANTS.TRIPLE_BANNER_HOME)}
							$active={filter === CONSTANTS.TRIPLE_BANNER_HOME}
						>
							Triple Banner Home
						</AntdButton>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setFilter(CONSTANTS.OFFER_BANNER)}
							$active={filter === CONSTANTS.OFFER_BANNER}
						>
							Banner Ofertas
						</AntdButton>
					</ButtonContainerTop>
					<ButtonContainerTop>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setFilter(CONSTANTS.BOOK_UP_LEFT_BANNER)}
							$active={filter === CONSTANTS.BOOK_UP_LEFT_BANNER}
						>
							Libro - Banner Izquierdo superior
						</AntdButton>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setFilter(CONSTANTS.BOOK_DOWN_LEFT_BANNER)}
							$active={filter === CONSTANTS.BOOK_DOWN_LEFT_BANNER}
						>
							Libro - Banner Izquierdo inferior
						</AntdButton>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setFilter(CONSTANTS.BOOK_UP_RIGHT_BANNER)}
							$active={filter === CONSTANTS.BOOK_UP_RIGHT_BANNER}
						>
							Libro - Banner Derecho superior
						</AntdButton>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setFilter(CONSTANTS.BOOK_DOWN_RIGHT_BANNER)}
							$active={filter === CONSTANTS.BOOK_DOWN_RIGHT_BANNER}
						>
							Libro - Banner Derecho inferior
						</AntdButton>
					</ButtonContainerTop>
				</Container>
				<ChildrenContainer>
					{filter === CONSTANTS.BIG_BANNER_HOME && (
						<BannerList
							section={CONSTANTS.BIG_BANNER_HOME}
							laboratories={laboratories}
							brands={brands}
							products={products}
							saleDepartment={saleDepartment}
							bannerHeight={420}
							bannerWidth={1920}
							sectionName="Principal Home"
						/>
					)}
					{filter === CONSTANTS.TRIPLE_CARD_BANNER_HOME && (
						<BannerList
							section={CONSTANTS.TRIPLE_CARD_BANNER_HOME}
							laboratories={laboratories}
							brands={brands}
							products={products}
							saleDepartment={saleDepartment}
							bannerHeight={360}
							bannerWidth={420}
							sectionName="Card Banner Home"
						/>
					)}
					{filter === CONSTANTS.DOUBLE_BANNER_HOME && (
						<BannerList
							section={CONSTANTS.DOUBLE_BANNER_HOME}
							laboratories={laboratories}
							brands={brands}
							products={products}
							saleDepartment={saleDepartment}
							bannerHeight={320}
							bannerWidth={680}
							sectionName="Doble Banner Home"
						/>
					)}
					{filter === CONSTANTS.TRIPLE_BANNER_HOME && (
						<BannerList
							section={CONSTANTS.TRIPLE_BANNER_HOME}
							laboratories={laboratories}
							brands={brands}
							products={products}
							saleDepartment={saleDepartment}
							bannerHeight={160}
							bannerWidth={450}
							sectionName="Triple Banner Home"
						/>
					)}
					{filter === CONSTANTS.OFFER_BANNER && (
						<BannerList
							section={CONSTANTS.OFFER_BANNER}
							laboratories={laboratories}
							brands={brands}
							products={products}
							bannerHeight={120}
							bannerWidth={1180}
							sectionName="Banner Ofertas"
						/>
					)}
					{filter === CONSTANTS.BOOK_UP_LEFT_BANNER && (
						<BannerList
							section={CONSTANTS.BOOK_UP_LEFT_BANNER}
							laboratories={laboratories}
							brands={brands}
							products={products}
							bannerHeight={260}
							bannerWidth={260}
							sectionName="Banner libro superior izquierdo"
						/>
					)}
					{filter === CONSTANTS.BOOK_DOWN_LEFT_BANNER && (
						<BannerList
							section={CONSTANTS.BOOK_DOWN_LEFT_BANNER}
							laboratories={laboratories}
							brands={brands}
							products={products}
							bannerHeight={260}
							bannerWidth={260}
							sectionName="Banner libro inferior izquierdo"
						/>
					)}
					{filter === CONSTANTS.BOOK_UP_RIGHT_BANNER && (
						<BannerList
							section={CONSTANTS.BOOK_UP_RIGHT_BANNER}
							laboratories={laboratories}
							brands={brands}
							products={products}
							bannerHeight={260}
							bannerWidth={260}
							sectionName="Banner libro superior derecho"
						/>
					)}
					{filter === CONSTANTS.BOOK_DOWN_RIGHT_BANNER && (
						<BannerList
							section={CONSTANTS.BOOK_DOWN_RIGHT_BANNER}
							laboratories={laboratories}
							brands={brands}
							products={products}
							bannerHeight={260}
							bannerWidth={260}
							sectionName="Banner libro inferior derecho"
						/>
					)}
				</ChildrenContainer>
			</Main.Body>
		</Main>
	);
}
