import React from "react";
import { Typo } from "../../../../../layout/theme";
import { Inner, Section } from "./styles";

export default function TermsPolicy({ user }) {
	return (
		<Inner>
			<Section>
				<Typo type="primary" level={1}>
					Política de privacidad
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					1.INTRODUCCIÓN.
				</Typo>
				<Typo level={5}>
					One Market Ventures S.A., con domicilio en la calle Helguera 2840,
					Ciudad Autónoma de Buenos Aires, es el titular de la aplicación
					OneTransfer (la “Aplicación”).
					<br />
					<br />
					One Market Ventures S.A., se compromete a mantener la confidencialidad
					y la seguridad de la información personal proporcionada por los
					usuarios de la Aplicación, a cuyo efecto aplica las siguientes
					Políticas de Privacidad (las “Políticas”).
					<br />
					<br />
					One Market Ventures S.A., respetan el derecho de los usuarios de la
					aplicación a la protección integral de sus Datos Personales, los
					cuales están amparados por la Ley de Protección de Datos Personales N°
					25.326, sus normas complementarias y reglamentarias.
					<br />
					<br />
					Las Políticas constituyen una parte integrante de los Términos y
					Condiciones de la Aplicación los que las Farmacias han revisado y
					aceptado. Asimismo, el Usuario (la “Farmacia”) reconoce que con su
					registración como Farmacia en la Aplicación presta su consentimiento
					para que se utilicen sus Datos Personales de conformidad con estas
					Políticas.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					2. INFORMACIÓN QUE RECOLECTAMOS.
				</Typo>
				<Typo level={5}>
					Para el funcionamiento de la Aplicación, One Market Ventures S.A.,
					recolecta la información ingresados por la Farmacia y por los usuarios
					administradores registrados. Los Datos Personales son almacenados en
					bases de datos debidamente registradas.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					3. USO DE LA INFORMACIÓN RECOLECTADA.
				</Typo>
				<Typo level={5}>
					Sus Datos Personales serán utilizados a los efectos de prestar los
					servicios correspondientes a la Aplicación, incluyendo sin limitación:
					<br />
					<br />
					<ul>
						<li>Crear los usuarios y registrar las farmacias;</li>
						<li>
							Enviar emails con notificaciones del pedido, actualizaciones y
							promociones;
						</li>
						<li>Ofrecer productos, promociones y descuentos de los mismos;</li>
						<li>
							Enviar información sobre nuevos productos y ofertas, ya sea
							mediante correo electrónico, mensaje de texto, llamadas
							telefónicas, wastup o notificaciones en la Aplicación;
						</li>
						<li>Hacer posible la compra y venta de productos;</li>
						<li>Generar información sobre ventas.</li>
					</ul>
					<br />
					Se acepta expresamente la cesión de los Datos Personales a One Market
					Ventures S.A.,a proveedores de servicios, los Proveedores
					(Distribuidores y Droguerías seleccionadas), y los Fabricantes de los
					productos disponibles a través de la Aplicación, a los efectos de su
					procesamiento para los propósitos indicados anteriormente. Las
					empresas y personas indicadas no podrán ceder los Datos Personales a
					terceros y en caso de hacerlo sera su exclusiva responsabilidad.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					4. CONSENTIMIENTO.
				</Typo>
				<Typo level={5}>
					El titular acepta en forma expresa y autoriza la recolección y uso de
					sus Datos Personales de conformidad con lo descrito en estas
					Políticas.
					<br />
					<br />
					One Market Ventures S.A., no recolecta Datos Personales sin contar con
					el consentimiento de su titular.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					5. DERECHOS DEL TITULAR DE DATOS PERSONALES.
				</Typo>
				<Typo level={5}>
					El titular que desee suprimir o conocer los datos personales que
					constan en la misma, actualizarlos o rectificar errores, o bien
					solicitar el cese de envío de los correos electrónicos, deberá enviar
					un e-mail a la siguiente dirección de correo electrónico
					“contacto@onetransfer.com.ar” o bien haciendo clic en el botón de
					“Unsuscribe” al momento de recibir un correo.
					<br />
					<br />
					La solicitud será evacuada dentro de los diez días corridos de
					efectuada en forma fehaciente.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					6. COOKIES.
				</Typo>
				<Typo level={5}>
					Onetransfer utiliza cookies, archivos informáticos que se instalan en
					su dispositivo (computadora personal, Tablet, teléfono móvil, etc.),
					por un tiempo limitado a fin de personalizar la experiencia del
					usuario. Las cookies se pueden utilizar para recordar sus credenciales
					de acceso, registrar sus preferencias y otorgarle un acceso más rápido
					a la aplicación, Usted puede desactivar el uso de cookies en su
					navegador en el momento que lo considere necesario.
					<br />
					<br />
					A continuación, encontrará los vínculos a las páginas de ayuda de los
					principales navegadores explicando cómo desactivar las cookies:
					<br />
					<br />
					Chrome: https://support.google.com/chrome/answer/95647?hl=es
					<br />
					<br />
					Mozilla Firefox:
					https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-?redirectlocale=en-US&redirectslug=Cookies
					<br />
					<br />
					Edge:
					https://support.microsoft.com/es-ar/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy
					<br />
					<br />
					Explorer:
					https://support.microsoft.com/es-ar/help/17442/windows-internet-explorer-delete-manage-cookies
					<br />
					<br />
					Safari: https://support.apple.com/es-es/HT201265
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					8. WEB BEACONS.
				</Typo>
				<Typo level={5}>
					La Aplicación también usa web beacons que transmiten información de su
					dispositivo a un servidor, de forma similar a las cookies. Los web
					beacons pueden estar insertados en contenido online, videos y emails,
					y permiten leer cierto tipo de información de su dispositivo, en qué
					momento se ha accedido a determinado contenido o email, y la dirección
					IP del dispositivo. Los web beacons se utilizan en forma conjunta con
					las cookies y con el mismo propósito y para medir patrones de tráfico
					en la Web.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					9. SEGURIDAD DE LA INFORMACIÓN.
				</Typo>
				<Typo level={5}>
					Los Datos Personales se procesarán y almacenarán en servidores o
					medios magnéticos que mantienen altos estándares de seguridad y
					protección tanto física como tecnológica tales como Microsoft Azure o
					similares.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					10. CONFIDENCIALIDAD.
				</Typo>
				<Typo level={5}>
					One Market Ventures S.A., y los cedentes autorizados tratarán en forma
					confidencial todos los Datos Personales a los que se tenga acceso en
					virtud del uso de la Aplicación y no los revelarán a terceros no
					autorizados.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					11. ÓRDENES DE AUTORIDADES COMPETENTES.
				</Typo>
				<Typo level={5}>
					One Market Ventures S.A., podrá revelar Datos Personales ante la
					recepción de un requerimiento de las autoridades judiciales o
					gubernamentales competentes al efecto, incluyendo sin limitación, en
					caso de litigios penales, civiles o comerciales.
				</Typo>
			</Section>
			<Section>
				<Typo type="primary" level={2}>
					12. CAMBIOS Y MODIFICACIONES.
				</Typo>
				<Typo level={5}>
					One Market Ventures S.A., podrá modificar las Políticas
					periódicamente.
				</Typo>
			</Section>
		</Inner>
	);
}
