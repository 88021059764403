import styled from "styled-components/macro";
import { COLORS, SIZES, Typo } from "../../../layout/theme";
import { Button } from "antd";

export const Inner = styled.div`
	height: 110px;
	width: 97%;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: ${COLORS.White};
	border-radius: 20px;

	transition-property: transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;

	&:hover {
		transform: scale(1.01);
	}
`;
export const SectionContainer = styled.div`
	padding-right: 5px;
	padding-left: 5px;
	display: flex;
	height: 100%;
`;
export const LeftContainer = styled.div`
	padding: 10px;
	flex: 0 0 65%;
	display: flex;
	flex-direction: row;
	gap: 20px;
	height: 100%;
	width: 100%;
	border-right: 1px solid ${COLORS.Grey};

	@media only screen and (min-width: 1038px) and (max-width: 1338px) {
		flex: 0 0 65%;
	}
`;
export const RigthContainer = styled.div`
	padding-right: 0px;
	padding-left: 0px;
	flex: 0 0 35%;
	display: flex;
	gap: 10px;
	justify-content: center;

	@media only screen and (min-width: 1038px) and (max-width: 1338px) {
		flex: 0 0 35%;
	}
`;
export const ImageContainer = styled.div`
	flex: 0 0 25%;
	-webkit-flex: 0 0 25%;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	object-fit: contain;
	position: relative;
`;
export const DataContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1 1 70%;
`;

export const PslContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 5px;
	padding: 10px;
	align-items: center;
`;

export const DataSeparator = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1px;

	span {
		white-space: pre-wrap;
	}
`;

export const Laboratory = styled.div`
	display: flex;
	justify-content: start;
	align-items: end;
	gap: 5px;
	margin-bottom: 4px;
`;
export const PriceContainer = styled.div`
	display: flex;
	flex: 0 0 50%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 15px;
`;
export const CartContainer = styled.div`
	flex: 0 0 40%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;

export const Price = styled.div`
	text-align: center;
	position: relative;
	display: flex;
	align-items: end;
	justify-content: flex-end;
	gap: 5px;
`;

export const PriceSeparator = styled.div`
	border-bottom: 0px solid ${COLORS.Grey};
	width: 100%;
`;

export const CartButtomNumber = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	& input {
		background: transparent !important;
		color: ${COLORS.Grey} !important;
		border: none !important;
		height: 100% !important;
		outline: none !important;
		box-shadow: none !important;
		text-align: center !important;
		font-size: ${SIZES.level4} !important;
		padding: 0 10px;
		width: 60px;
		position: absolute;
		top: -45px;
	}
`;

export const FlowtingNumber = styled(Typo)`
	position: absolute;
	top: -5px;
`;

export const PriceCenter = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
`;

export const PriceAlong = styled.div`
	display: flex;
`;

export const PriceAndOffer = styled.div`
	display: flex;
	gap: 10px;
	align-items: end;
	justify-content: center;
	width: 100%;
`;
