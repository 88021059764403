import React from "react";
import { Inner, PlaceholderStore, ResultsSection } from "./styles";

export default function LoadingSkeleton() {
  return (
    <Inner>
      <ResultsSection>
        <PlaceholderStore />
        <PlaceholderStore />
        <PlaceholderStore />
        <PlaceholderStore />
        <PlaceholderStore />
        <PlaceholderStore />
        <PlaceholderStore />
        <PlaceholderStore />
        <PlaceholderStore />
        <PlaceholderStore />
      </ResultsSection>
    </Inner>
  );
}
