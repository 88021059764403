import { Skeleton, Row, Col } from "antd";
import { Inner } from "./styles";

export default function LoadingHeader() {
	return (
		<Inner>
			<Row>
				<Col span={6}>
					<Skeleton.Input
						active
						size="default"
						style={{ width: 450, height: 50, borderRadius: 5 }}
					/>
				</Col>
				<Col span={4}>
					<Skeleton.Input
						active
						size="default"
						style={{
							width: 200,
							height: 50,
							marginLeft: 140,
							borderRadius: 5,
						}}
					/>
				</Col>
				<Col span={2}></Col>
				<Col span={6}>
					<Skeleton.Input
						active
						size="default"
						style={{ width: 200, height: 50, marginLeft: 200, borderRadius: 5 }}
					/>
				</Col>
				<Col span={4}>
					<Skeleton.Input
						active
						size="default"
						style={{ width: 200, height: 50, marginLeft: 80, borderRadius: 5 }}
					/>
				</Col>
			</Row>
		</Inner>
	);
}
