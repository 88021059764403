import React from "react";
import {
  Inner,
  Applied,
  AppliedItemList,
  AppliedItem,
  NotApplied,
  NotAppliedItemList,
  NotAppliedItem,
  NotAppliedInner,
  NotAppliedItemRight,
  AppliedItemRight,
  NotAppliedTitle,
  IconContainer,
} from "./styles";

const Filter = ({ children, ...restProps }) => (
  <Inner {...restProps}>{children}</Inner>
);

export default Filter;

Filter.Applied = ({ children, ...restProps }) => {
  return <Applied {...restProps}>{children}</Applied>;
};

Filter.Applied.ItemList = ({ children, ...restProps }) => {
  return <AppliedItemList {...restProps}>{children}</AppliedItemList>;
};

Filter.Applied.Item = ({ children, ...restProps }) => {
  return <AppliedItem {...restProps}>{children}</AppliedItem>;
};

Filter.Applied.Item.Right = ({ children, ...restProps }) => {
  return <AppliedItemRight {...restProps}>{children}</AppliedItemRight>;
};

Filter.NotApplied = ({ children, ...restProps }) => {
  return <NotApplied {...restProps}>{children}</NotApplied>;
};

Filter.NotApplied.Inner = ({ children, ...restProps }) => {
  return <NotAppliedInner {...restProps}>{children}</NotAppliedInner>;
};

Filter.NotApplied.NotAppliedTitle = ({ children, ...restProps }) => {
  return <NotAppliedTitle {...restProps}>{children}</NotAppliedTitle>;
};

Filter.NotApplied.IconContainer = ({ children, ...restProps }) => {
  return <IconContainer {...restProps}>{children}</IconContainer>;
};

Filter.NotApplied.ItemList = ({ children, ...restProps }) => {
  return <NotAppliedItemList {...restProps}>{children}</NotAppliedItemList>;
};

Filter.NotApplied.Item = ({ children, ...restProps }) => {
  return <NotAppliedItem {...restProps}>{children}</NotAppliedItem>;
};

Filter.NotApplied.Item.Right = ({ children, ...restProps }) => {
  return <NotAppliedItemRight {...restProps}>{children}</NotAppliedItemRight>;
};
