import { COLORS, SIZES, Typo, WEIGHT } from "../../layout/theme";
import styled from "styled-components/macro";
import { Button } from "antd";

export const Line = styled(Typo)`
	display: flex;
	justify-content: center;
	&::before {
		content: "";
		width: 120%;
		background-color: ${COLORS.Grey};
		height: 3px;
		position: absolute;
		z-index: 2;
		top: 13px;
	}
	margin-left: 15px;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
`;

export const ItemsQty = styled.div`
	color: ${COLORS.Warning};
	font-size: ${SIZES.level7};
`;

export const Loader = styled.div`
	position: absolute;
	transform: translate(-5px, -30px) scale(1.5);
	background-color: transparent;
	z-index: 999;
`;

export const CardContainer = styled.div``;

export const PriceTextOff = styled.p`
	font-weight: 700;
	writing-mode: vertical-lr;
	font-size: 9px;
	height: 9px;
	margin-right: -7px;
	color: #646464;
	transform-origin: bottom;
	transform: rotateZ(180deg);
`;

export const SectionOffer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
`;

export const TriggerQtyContainer = styled.div`
	color: ${COLORS.StrongGreen};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level7};
	text-align: center;
`;
export const OffContainer = styled.div`
	writing-mode: vertical-lr;
	font-size: 8px;
	transform: rotateZ(180deg);
`;

export const SpecialOfferContainer = styled.div`
	display: flex;
	align-items: center;
`;
export const MinQtyContainer = styled.div`
	color: ${COLORS.Primary};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level7};
	text-align: center;
	margin-bottom: 2px;

	@media only screen and (min-width: 760px) and (max-width: 1180px) {
		font-size: 10px;
	}
`;

export const MarketingStar = styled.div`
	color: ${COLORS.Default};
`;

export const PslContainer = styled.div`
	font-size: 10px;
	font-weigth: 500;
	color: ${COLORS.Success};
`;

export const LeftContainer = styled.div`
	flex: 1 1 20%;
	display: flex;
	justify-content: center;
`;
export const CenterContainer = styled.div`
	flex: 1 1 60%;
	display: flex;
	justify-content: center;
`;
export const RightContainer = styled.div`
	flex: 1 1 20%;
	display: flex;
	justify-content: center;
	min-height: 30px;
`;

export const TopContainer = styled.div`
	display: flex;
	justify-content: space-beetween;
	align-items: center;
	width: 100%;
`;
export const TitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: start;
	gap: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const HeaderLeft = styled.div`
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	display: flex;
	flex-direction: column;
`;
