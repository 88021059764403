import React, { useEffect, useState } from "react";
import Image from "../image/Image";
import { get_market_distributors } from "../../../api/endpoints/distributors";
import { AntdTable } from "./components/styles";
import { COLORS } from "../../layout/theme";
import { FaImages } from "react-icons/fa";
import AntdCheckbox from "../antd-checkbox/AntdCheckbox";
import AntdInput from "../antd-input/AntdInput";

export default function MegaDistributorsCode({
	initialValues,
	onChange,
	ean13 = "",
}) {
	const [distributors, setDistributors] = useState(null);

	useEffect(() => {
		const fetch_distributors = async () => {
			const data = await get_market_distributors();

			setDistributors(
				data.map((d) => ({
					key: d.id,
					...d,
					checked: initialValues?.find((i) => i.id === d.id)?.code
						? true
						: false,
					code: initialValues?.find((i) => i.id === d.id)?.code,
					officeCode: initialValues?.find((i) => i.id === d.id)?.officeCode,
				})) || []
			);
		};

		fetch_distributors();
	}, []);

	useEffect(() => {
		onChange(distributors);
	}, [distributors]);

	const handleChange = (text, record, index, value) => {
		let items = [...distributors];
		items[index] = {
			...distributors[index],
			code: value,
		};
		setDistributors(items);
	};

	const handleOfficeCodeChange = (text, record, index, value) => {
		let items = [...distributors];
		items[index] = {
			...distributors[index],
			officeCode: value,
		};
		setDistributors(items);
	};

	const handleCheck = (id, check) => {
		const _dis = distributors.map((d) =>
			d.id === id ? { ...d, checked: check } : { ...d }
		);
		setDistributors(_dis);
	};

	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			render: (data) => <Image width={40} height={40} src={data} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Droguería</div>,
			dataIndex: "name",
			key: "name",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Activa</div>,
			dataIndex: "",
			render: (value, record, index) => (
				<AntdCheckbox
					onChange={(e) => handleCheck(record.id, e.target.checked)}
					defaultChecked={value.checked}
				></AntdCheckbox>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Código</div>,
			dataIndex: "code",
			key: "code",
			render: (text, record, index) => (
				<AntdInput
					value={text}
					onChange={(e) => handleChange(text, record, index, e.target.value)}
					defaultValue={text}
					disabled={!record.checked}
				/>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Sucursal</div>,
			dataIndex: "officeCode",
			key: "officeCode",
			render: (text, record, index) => (
				<AntdInput
					value={text}
					onChange={(e) =>
						handleOfficeCodeChange(text, record, index, e.target.value)
					}
					defaultValue={text}
					disabled={!record.checked}
				/>
			),
		},
	];

	if (!distributors) return <div>Loading...</div>;

	return (
		<AntdTable
			size="small"
			columns={columns}
			dataSource={distributors}
			pagination={false}
		/>
	);
}
