import styled from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";
import { Modal } from "antd";

export const DistributorImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`;

export const GuideText = styled.div`
	display: flex;
	white-space: nowrap;
	margin-bottom: 10px;
	color: ${COLORS.Secondary};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level5};
`;

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	gap: 10px;
	background-color: ${COLORS.White};
	border-radius: 20px;
`;

export const DistributorBiller = styled.div`
	width: 98.5%;
	display: flex;
	gap: 20px;
`;

export const DistributorContainer = styled.div`
	flex: 0 0 60%;
	padding: 2rem;
	gap: 10px;
	background-color: ${COLORS.White};
	border-radius: 20px;
`;

export const BillerContainer = styled.div`
	flex: 0 0 40%;
	padding: 2rem;
	gap: 10px;
	background-color: ${COLORS.White};
	border-radius: 20px;
`;

export const BillerAndCodeContainer = styled.div`
	display: flex;
	gap: 20px;
`;

export const BillerSelection = styled.div``;

export const BillerCode = styled.div``;

export const DistributorText = styled.div`
	cursor: pointer;
	display: flex;
	gap: 5px;
	justify-content: center;
	align-items: center;
	color: ${(props) =>
		props.textColor === 1 ? COLORS.Primary : COLORS.Secondary};
	font-size: ${SIZES.level6};
	${(props) =>
		props.marginBottom &&
		`
      margin-bottom: ${props.marginBottom}px;
    `}
`;

export const AntdModal = styled(Modal)`
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
	}
`;
