import { Skeleton, Row, Col, Space } from "antd";
import {
  Container,
  Inner,
  ProductData,
  PublishContainer,
  PublishSettings,
} from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
  return (
    <Inner>
      <ProductData>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row></Row>
          <Row gutter={GUTTER}>
            <Col span={22}>
              <Skeleton.Input active={false} block />
            </Col>
          </Row>

          <Row></Row>
        </Space>
      </ProductData>

      <PublishSettings>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <PublishContainer>
            <Skeleton.Input
              active={false}
              style={{ marginTop: "-10px", width: "300px" }}
            />
          </PublishContainer>
        </Space>
      </PublishSettings>
    </Inner>
  );
}
