import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import ProductList from "./products/list/ProductList";
import BrandList from "./brands/list/BrandList";
import { Link } from "react-router-dom";
import { ButtonContainer, ChildrenContainer, AntdButton } from "./styles";

export default function Items() {
  const [filter, setFilter] = useState("PRODUCTS");

  const get_filter = () => {
    setFilter(
      localStorage.getItem("itemsFilter")
        ? localStorage.getItem("itemsFilter")
        : "PRODUCTS"
    );
  };

  useEffect(() => {
    get_filter();
  }, []);

  return (
    <Main>
      <Main.Header>
        <Main.Breadcrumb>
          <Main.BreadcrumbItem>
            <Link to="/">Home</Link>
          </Main.BreadcrumbItem>
          <Main.BreadcrumbSeparator />
          <Main.ActiveBreadcrumbItem>
            Productos y Marcas
          </Main.ActiveBreadcrumbItem>
        </Main.Breadcrumb>
      </Main.Header>
      <Main.Body>
        <ButtonContainer>
          <AntdButton
            type="primary"
            bg="Primary"
            color="White"
            style={{ fontWeight: "600" }}
            width={220}
            height={48}
            fontSize={12}
            onClick={() => setFilter("PRODUCTS")}
            $active={filter === "PRODUCTS"}
          >
            PRODUCTOS
          </AntdButton>
          <AntdButton
            type="primary"
            bg="Primary"
            color="White"
            style={{ fontWeight: "600", marginLeft: "20px" }}
            width={220}
            height={48}
            fontSize={12}
            onClick={() => setFilter("BRANDS")}
            $active={filter === "BRANDS"}
          >
            MARCAS
          </AntdButton>
        </ButtonContainer>
        <ChildrenContainer>
          {filter === "PRODUCTS" ? <ProductList /> : <BrandList />}
        </ChildrenContainer>
      </Main.Body>
    </Main>
  );
}
