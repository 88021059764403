import styled from "styled-components";

export const PreviewImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: contain;
`;

export const AddBannerContainer = styled.div`
	display: flex;
	gap: 10px;
	height: 30px;
	align-items: center;
	cursor: pointer;
	width: 25%;
`;

export const InfoImage = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const BannerListContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
