import { Button, Modal } from "antd";
import styled from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../../../layout/theme";

export const Text = styled.div`
	padding: 0px 60px;
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.SemiBold};
	text-decoration: underline;
	cursor: pointer;

	@media only screen and (min-width: 701px) and (max-width: 900px) {
		padding: 0px;
	}

	@media only screen and (min-width: 414px) and (max-width: 700px) {
		padding: 0px;
	}

	@media only screen and (min-width: 250px) and (max-width: 413px) {
		padding: 0px;
	}
`;

export const Space = styled.div`
	padding: 20px 0px 0px 60px;
`;

export const AntdModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
	}

	.ant-modal-header {
		border-radius: 10px 10px 0 0;
	}
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
		padding: 1rem 5rem 1rem 5rem;
	}
	.ant-modal-footer {
		text-align: left;
		height: 80px;
	}
`;

export const Section = styled.div`
	width: 80%;
	box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
		rgb(0 0 0 / 30%) 0px 30px 60px -30px;
`;

export const AntdButton = styled(Button)`
	@media only screen and (min-width: 701px) and (max-width: 900px) {
		white-space: pre-line;
		height: 100%;
	}

	@media only screen and (min-width: 414px) and (max-width: 700px) {
		white-space: pre-line;
		height: 100%;
	}

	@media only screen and (min-width: 250px) and (max-width: 413px) {
		white-space: pre-line;
		height: 100%;
	}
`;
