import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link } from "react-router-dom";
import {
	ButtonContainer,
	ChildrenContainer,
	AntdButton,
	Inner,
} from "./styles";
import UpdatePointOfSale from "./historyPointsOfSale/update/UpdatePointsOfSale";
import HistoryPointsOfSale from "./historyPointsOfSale/list/HistoryPointsOfSale";
import HistoryExports from "./historyPointsOfSale/list/HistoryExports";

export default function GeneralPointsOfSale() {
	const [filter, setFilter] = useState("UPDATE");

	const get_filter = () => {
		setFilter(
			localStorage.getItem("itemFilter")
				? localStorage.getItem("itemFilter")
				: "UPDATE"
		);
	};

	useEffect(() => {
		get_filter();
	}, []);

	const change_filter = (state) => {
		setFilter(state);
		localStorage.setItem("itemFilter", state);
	};

	return (
		<Inner>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Listado general de Puntos de venta
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<ButtonContainer>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600" }}
						width={220}
						height={48}
						fontSize={12}
						active={filter === "UPDATE" ? true : false}
						onClick={() => change_filter("UPDATE")}
					>
						Lista de Puntos de venta
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={220}
						height={48}
						fontSize={12}
						active={filter === "UPDATEHISTORY" ? true : false}
						onClick={() => change_filter("UPDATEHISTORY")}
					>
						Historial de cambios
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={220}
						height={48}
						fontSize={12}
						active={filter === "EXPORTHISTORY" ? true : false}
						onClick={() => change_filter("EXPORTHISTORY")}
					>
						Historial de exportación
					</AntdButton>
				</ButtonContainer>
				<ChildrenContainer>
					{filter === "UPDATE" && <UpdatePointOfSale />}
					{filter === "UPDATEHISTORY" && <HistoryPointsOfSale />}
					{filter === "EXPORTHISTORY" && <HistoryExports />}
				</ChildrenContainer>
			</Main.Body>
		</Inner>
	);
}
