import { Skeleton } from "antd";
import React from "react";
import {
  FilterSection,
  Inner,
  PlaceholderProduct,
  ResultsSection,
} from "./styles";

export default function LoadingSkeleton() {
  return (
    <Inner>
      <FilterSection>
        <Skeleton paragraph={{ rows: 15, width: "100%" }} />
      </FilterSection>
      <ResultsSection>
        <PlaceholderProduct />
        <PlaceholderProduct />
        <PlaceholderProduct />
        <PlaceholderProduct />
        <PlaceholderProduct />
        <PlaceholderProduct />
        <PlaceholderProduct />
        <PlaceholderProduct />
      </ResultsSection>
    </Inner>
  );
}
