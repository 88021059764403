import React, { useState } from "react";
import Image from "../../common/image/Image";
import { Typo } from "../../layout/theme";
import {
	CenterData,
	ContainerModal,
	ExternalLink,
	ImageContainer,
	Inner,
	LinksContainer,
	RightData,
	TopCenter,
} from "./styles";
import moment from "moment";
import { FiDownload } from "react-icons/fi";
import { Modal } from "antd";

export default function CampaingCardContainer({ item }) {
	const [isModalVisible, setIsModalVisible] = useState();

	const handleDownloadImage = () => {
		const link = document.createElement("a");
		link.href = item.photoUrl;
		link.setAttribute("download", "file.png");
		document.body.append(link);
		link.click();
	};

	return (
		<Inner>
			<ImageContainer>
				<Image
					src={item.photoUrl}
					height={120}
					borderRadius="10px"
					preview={true}
				/>
			</ImageContainer>
			<CenterData>
				<TopCenter>
					<Typo level={4} type="primary">
						{item?.title}
					</Typo>
					<Typo level={5} type="secondary">
						{item?.brand?.name || item?.brand}
					</Typo>
					<Typo
						level={6}
						type="muted"
						onClick={() => setIsModalVisible(true)}
						style={{ cursor: "pointer" }}
					>
						{item?.products?.map((p, i) => (i <= 3 ? `${p.title} ` : null))}
					</Typo>
				</TopCenter>
				<LinksContainer>
					<Typo
						onClick={() => handleDownloadImage()}
						type="muted"
						style={{ cursor: "pointer" }}
					>
						<FiDownload />
						Descargar Imagen
					</Typo>
					{item?.link && (
						<ExternalLink href={item?.link} target="_blank">
							<Typo type="muted">Ir a Publicidad</Typo>
						</ExternalLink>
					)}
				</LinksContainer>
			</CenterData>
			<RightData>
				<Typo type="muted">
					Fecha de inicio: {moment(item.publishFrom).format("DD MMMM YYYY")}
				</Typo>
				<Typo type="muted">
					Fecha de cierre: {moment(item.publishTo).format("DD MMMM YYYY")}
				</Typo>
			</RightData>
			<Modal
				title={
					<Typo level={4} type="primary">
						Productos
					</Typo>
				}
				open={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}
				width={900}
				bodyStyle={{ height: "400px", overflowY: "scroll" }}
				centered={true}
				footer={null}
			>
				<ContainerModal>
					{item?.products?.map((p) => (
						<Typo>{p.title}</Typo>
					))}
				</ContainerModal>
			</Modal>
		</Inner>
	);
}
