import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components/macro";
import { COLORS } from "../../../layout/theme";

const intermitentChange = keyframes`
  0% {
    opacity:1;
  }
  45% {
  opacity:1;
  }
  55% {
  opacity:0;
  }
  100% {
  opacity:0;
  }
`;

export const SidebarLink = styled(Link)`
	display: flex;
	color: ${COLORS.Secondary};
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	list-style: none;
	height: 60px;
	text-decoration: none;
	font-size: 18px;

	&:hover {
		border-left: 4px solid ${COLORS.Secondary};
		cursor: pointer;
	}
`;

export const SidebarLabel = styled.span`
	margin-left: 16px;
`;

export const LaboratorySidebarLink = styled(Link)`
	display: flex;
	color: ${COLORS.Secondary};
	justify-content: space-between;
	align-items: center;
	list-style: none;
	height: 60px;
	text-decoration: none;
	font-size: 18px;

	&:hover {
		border-left: 4px solid ${COLORS.Secondary};
		padding: 10px;
		cursor: pointer;
	}
`;

export const DropdownLink = styled(Link)`
	height: 60px;
	padding-left: 3rem;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: ${COLORS.Secondary};
	font-size: 18px;

	&:hover {
		background: ${COLORS.LightGrey};
		cursor: pointer;
	}
`;

export const IconContainer = styled.img`
	align-items: center;
	display: flex;
	justify-content: center;
	object-fit: contain;
	width: 25px;
	height: auto;
`;

export const AlertContainer = styled.div`
	position: absolute;
	top: -20px;
`;

export const AlertContainerAnimated = styled.div`
	position: absolute;
	top: -20px;
	animation-name: ${intermitentChange};
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 1s;
	animation-direction: alternate;
`;

export const MenuAlertContainer = styled.div``;
