import React from "react";
import HistoryModalDetail from "../HistoryModalDetail/HistoryModalDetail";
import { ModalContainer } from "./styles";

export default function HistoryModal({ client, history }) {
	return (
		<ModalContainer>
			<HistoryModalDetail
				client={client}
				history={history}
			></HistoryModalDetail>
		</ModalContainer>
	);
}
