import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link } from "react-router-dom";
import { ButtonContainer, ChildrenContainer, AntdButton } from "./styles";
import ClientsList from "./list/ClientsList";
import { get_client_list_dapper } from "../../../../api/endpoints/clients";

export default function MarketClientsIndex() {
	const [filter, setFilter] = useState("LABORATORIES");

	const get_filter = () => {
		setFilter(
			localStorage.getItem("marketClientsIndexFilter")
				? localStorage.getItem("marketClientsIndexFilter")
				: "LABORATORIES"
		);
	};

	useEffect(() => {
		get_filter();
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Clientes</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<ButtonContainer>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600" }}
						width={220}
						height={48}
						fontSize={12}
						onClick={() => setFilter("LABORATORIES")}
						$active={filter === "LABORATORIES"}
					>
						LABORATORIOS
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={220}
						height={48}
						fontSize={12}
						onClick={() => setFilter("PHARMACIES")}
						$active={filter === "PHARMACIES"}
					>
						FARMACIAS
					</AntdButton>
				</ButtonContainer>
				<ChildrenContainer>
					{filter === "LABORATORIES" && (
						<ClientsList
							get_clients_page={get_client_list_dapper}
							clientType="LABORATORY"
						/>
					)}
					{filter === "PHARMACIES" && (
						<ClientsList
							get_clients_page={get_client_list_dapper}
							clientType="PHARMACY"
						/>
					)}
				</ChildrenContainer>
			</Main.Body>
		</Main>
	);
}
