import { useAuth } from "../../../../contexts/authContext";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../../layout/main/Main";
import { Form, Input, Row, Col, Space, Button, message, Modal } from "antd";
import { PhoneTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import { FcGoogle } from "react-icons/fc";
import React, { useState, useEffect } from "react";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import UploadFile from "../../../common/upload-file/UploadFile";
import {
	PreviewImage,
	GoogleAuth,
	SmsAuth,
	Inner,
	DataSection,
	ConectivitySection,
	LeftContainer,
	RightContainer,
	ButtonContainer,
	WelcomeSection,
	WelcomeTitle,
	WelcomeSubTitle,
} from "./styles";
import {
	get_current_user,
	update_profile,
} from "../../../../api/endpoints/users";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import SendSmsCode from "./components/send-sms-code/SendSmsCode";
import { COLORS, Typo } from "../../../layout/theme";
import NoImageUser from "../../../../assets/images/SinImagenDeUsuario.jpeg";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";

const GUTTER = 10;

const { confirm } = Modal;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function UserProfile() {
	const {
		user,
		connectGoogle,
		disconnectGoogle,
		disconnectPhone,
		setChangePhoto,
		photoKey,
	} = useAuth();

	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [initialValues, setInitialValues] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [photoURL, setphotoURL] = useState(null);
	const [connectedGoogleProvider, setConnectedGoogleProvider] = useState(false);
	const [connectedSmsProvider, setConnectedSmsProvider] = useState(false);
	const [phoneModalVisible, setPhoneModalVisible] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");

	useEffect(() => {
		const fetch_current_user = async () => {
			const res = await get_current_user();
			setInitialValues(res);
			setphotoURL(res.photoUrl + `?k=${photoKey}`);
			setPhoneNumber(res.phone);
		};
		fetch_current_user();
	}, []);

	useEffect(() => {
		(async () => {
			if (user?.providerData.some((p) => p.providerId === "google.com")) {
				setConnectedGoogleProvider(true);
			}
			if (user?.providerData.some((p) => p.providerId === "phone")) {
				setConnectedSmsProvider(true);
			}
		})();
	}, [user]);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				firstName: values.firstName,
				lastName: values.lastName,
				phone: phoneNumber,
			};

			const formData = new FormData();
			formData.append("data", JSON.stringify(postData));
			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);
			const res = await update_profile(formData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Los datos se guardaron con Éxito"
			);
			setChangePhoto((prev) => prev + 1);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al guardar los datos",
				"Un error ocurrio al intentar guardar los datos, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setphotoURL(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleConnectGoogle = async () => {
		try {
			await connectGoogle(setConnectedGoogleProvider);
		} catch (error) {
			console.log(error);
		}
	};

	const handleDisconnectGoogle = async () => {
		try {
			showDeleteConfirmGoogle();
		} catch (error) {
			console.log(error);
		}
	};

	const handleDisconnectPhone = async () => {
		try {
			showDeleteConfirmPhone();
		} catch (error) {
			console.log(error);
		}
	};

	const showDeleteConfirmPhone = () => {
		confirm({
			title: "¿Quieres eliminar este proveedor de acceso?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"La eliminación del proveedor de acceso se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				disconnectPhone();
				setConnectedSmsProvider(false);
			},
		});
	};

	const showDeleteConfirmGoogle = () => {
		confirm({
			title: "¿Quieres eliminar este proveedor de acceso?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"La eliminación del proveedor de acceso se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				disconnectGoogle();
				setConnectedGoogleProvider(false);
			},
		});
	};

	useEffect(() => {}, [connectedGoogleProvider]);

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/");
			},
		});
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Perfil de usuario
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<WelcomeSection>
							<WelcomeTitle>Hola {initialValues.firstName}</WelcomeTitle>
							<WelcomeSubTitle>{initialValues.client.name}</WelcomeSubTitle>
						</WelcomeSection>
						<Inner>
							<DataSection>
								<LeftContainer>
									<Row gutter={GUTTER}>
										<Col md={12} xl={12}>
											<Form.Item
												name="file"
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Imagen de usuario
													</label>
												}
											>
												<UploadFile onChange={handleImageChange} />
											</Form.Item>
										</Col>
									</Row>
									<Row>
										<Col>
											{photoURL ? (
												<PreviewImage src={photoURL} alt={"Avatar"} />
											) : (
												<PreviewImage src={NoImageUser} alt={"NoAvatar"} />
											)}
										</Col>
									</Row>
								</LeftContainer>
								<RightContainer>
									<Row gutter={GUTTER}>
										<Col md={12} xl={16}>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Nombre
													</label>
												}
												name="firstName"
											>
												<Input />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={GUTTER}>
										<Col md={12} xl={16}>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Apellido
													</label>
												}
												name="lastName"
											>
												<Input />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={GUTTER}>
										<Col md={14} xl={16}>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Email
													</label>
												}
												name="email"
											>
												<Input
													type="email"
													style={{ cursor: "auto" }}
													disabled
												/>
											</Form.Item>
										</Col>
									</Row>
								</RightContainer>
							</DataSection>
							<ConectivitySection>
								<Row
									gutter={GUTTER}
									style={{
										display: "flex",
										alignItems: "center",
										alignContent: "center",
										height: "100%",
										width: "100%",
										padding: "0 20px 20px 20px",
									}}
								>
									<Col
										md={24}
										xl={24}
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "55px",
										}}
									>
										<Typo level={6} type="primary">
											{" "}
											Proveedores de acceso{" "}
										</Typo>
										<GoogleAuth>
											<Space>
												<FcGoogle style={{ fontSize: "21px" }} /> Google
												<Form.Item>
													{connectedGoogleProvider
														? user?.providerData
																?.filter(function (obj) {
																	return obj.providerId === "google.com";
																})
																?.map(function (obj) {
																	return obj.email;
																})
														: null}
												</Form.Item>
												{!connectedGoogleProvider ? (
													<Button onClick={() => handleConnectGoogle()}>
														Conectar
													</Button>
												) : (
													<Button
														onClick={() => handleDisconnectGoogle()}
														danger
													>
														Desconectar
													</Button>
												)}
											</Space>
										</GoogleAuth>
										<SmsAuth>
											<Space>
												<PhoneTwoTone style={{ fontSize: "20px" }} />
												Telefono
												<Form.Item>
													{connectedSmsProvider
														? user?.providerData
																.filter(function (obj) {
																	return obj.providerId === "phone";
																})
																.map(function (obj) {
																	return obj.phoneNumber;
																})
														: null}
												</Form.Item>
												{!connectedSmsProvider ? (
													<Button
														onClick={() => {
															setShowModal(true);
															setPhoneModalVisible(true);
														}}
													>
														Conectar
													</Button>
												) : (
													<Button
														onClick={() => handleDisconnectPhone()}
														danger
													>
														Desconectar
													</Button>
												)}
											</Space>
											{showModal === true ? (
												<>
													<Modal
														style={{ textAlign: "center" }}
														title="Editar acceso con número de teléfono"
														visible={showModal}
														onCancel={() => setShowModal(false)}
														footer={[
															<Button
																key="cancel"
																onClick={() => setShowModal(false)}
															>
																Cancelar
															</Button>,
														]}
													>
														{phoneModalVisible === true ? (
															<SendSmsCode
																setShowModal={setShowModal}
																setConnectedSmsProvider={
																	setConnectedSmsProvider
																}
															/>
														) : null}
													</Modal>
												</>
											) : null}
										</SmsAuth>
									</Col>
								</Row>
							</ConectivitySection>
						</Inner>
						<Form.Item>
							<Space>
								<ButtonContainer>
									<SignInTemplate.AntdButton
										type="Primary"
										color="white"
										bg="Primary"
										htmlType="submit"
										loading={submitting}
									>
										Guardar Cambios
									</SignInTemplate.AntdButton>
									<SignInTemplate.AntdButton
										type="Primary"
										loading={submitting}
										onClick={handleBack}
									>
										Volver sin guardar
									</SignInTemplate.AntdButton>
								</ButtonContainer>
							</Space>
						</Form.Item>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
