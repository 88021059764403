import axiosInstance from "../axiosInstance";

const CONTROLLER = "Advertising";

const get_advertisings = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}`
	);
	return response.data;
};

const get_advertisings_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_advertising_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const get_featured_products_list_dapper = async (term, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetFeaturedProductList`,
		{
			params: {
				searchTerm: term,
				state: state,
			},
		}
	);
	return response.data;
};

const get_featured_product_by_id = async (id) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetFeaturedProductById/${id}`
	);
	return response.data;
};

const get_featured_products_header = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetFeaturedProductHeader`
	);
	return response.data;
};

const create_featured_group_product = async (featuredData) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, featuredData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_featured_group_product = async (id, campaign) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, campaign, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_featured_product_state = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateFeaturedProductStatus`,
		state
	);
	return response.data;
};

const get_featured_products_home = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetFeaturedProductsHome`
	);
	return response.data;
};

export {
	get_advertisings,
	get_advertisings_page,
	get_advertising_by_id,
	get_featured_products_list_dapper,
	get_featured_product_by_id,
	create_featured_group_product,
	update_featured_group_product,
	get_featured_products_header,
	update_featured_product_state,
	get_featured_products_home,
};
