import { Skeleton, Row, Col, Space } from "antd";
import { Inner, UserData, PublishSettings } from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
  return (
    <Inner>
      <UserData>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row></Row>
          <Row gutter={GUTTER}>
            <Col span={12}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 200, marginBottom: -10, marginTop: -30 }}
              />
              <Skeleton.Input active={true} block />
            </Col>
            <Col span={12}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 200, marginBottom: -10, marginTop: -30 }}
              />
              <Skeleton.Input active={true} block />
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={12}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 200, marginBottom: -10 }}
              />
              <Skeleton.Input active={true} block />
            </Col>
            <Col span={12}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 200, marginBottom: -10 }}
              />
              <Skeleton.Input active={true} block />
            </Col>
          </Row>
        </Space>
        <Row>
          <Col>
            <Skeleton.Input active style={{ width: 150, marginTop: 30 }} />
          </Col>
        </Row>
      </UserData>

      <PublishSettings>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row gutter={GUTTER}>
            <Col span={6}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 150, marginBottom: -10, marginTop: -15 }}
              />
              <Skeleton.Input
                active={false}
                block
                style={{ marginBottom: 10 }}
              />

              <Skeleton.Image active={false} block />
            </Col>
          </Row>
        </Space>
      </PublishSettings>
    </Inner>
  );
}
