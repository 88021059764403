import { Button } from "antd";
import styled, { keyframes } from "styled-components";
import { COLORS } from "../../../../../layout/theme";

const colorSelector = (color) => {
	const separeteColors = Object.entries(COLORS).join(",").split(",");
	const colorNameIndex = separeteColors.findIndex(
		(element) => element === color
	);
	return colorNameIndex === -1 ? "#fff" : separeteColors[colorNameIndex + 1];
};

const handleOutline = (color) => {
	const separeteColors = Object.entries(COLORS).join(",").split(",");
	const colorNameIndex = separeteColors.findIndex(
		(element) => element === color
	);
	return `border: 1px solid ${separeteColors[colorNameIndex + 1]}`;
};

const linearMove = keyframes`
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(40%);
  }
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 600px;
	overflow: hidden;
	justify-content: flex-start;
	align-items: center;
	padding: 2rem;
	gap: 20px;
	text-align: center;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Arrow = styled.div`
	display: inline-block;
	animation: ${linearMove} 1s linear infinite;
	padding: 2rem 1rem;
	font-size: 1.2rem;
	color: ${COLORS.Success};
`;

export const AntdButton = styled(Button)`
	background-color: ${({ bg }) => (bg ? colorSelector(bg) : "white")};
	color: ${({ color }) => (color ? colorSelector(color) : COLORS.Black)};
	${(props) =>
		props.borderRadius &&
		`
    border-radius: ${props.borderRadius}px;
    `}
	${(props) => props.outline && handleOutline(props.color)}
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
  ${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}

  overflow: hidden;

	span {
		white-space: pre-line;
	}

	&.ant-btn-link {
		color: ${COLORS.Grey};
	}
`;
