import styled from "styled-components";

const handleType = (type) => {
  const tipo =
    type === "drop"
      ? "filter: drop-shadow(0px 7px 7px rgba(0, 0, 0, 0.25))"
      : "box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0,0,0,0.3) 0px 30px 60px -30px";
  return tipo;
};

export const Inner = styled.div`
  width: 100%;
  ${({ type }) => handleType(type)};
`;
