import { Button, Upload } from "antd";
import styled from "styled-components";
import { COLORS } from "../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 40px;
	margin-top: 30px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: column;
	}
`;

export const ImageSection = styled.div`
	min-height: 350px;
	width: 100%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 10px 20px 20px 20px;
	align-items: center;
`;

export const FilterContainer = styled.div`
	display: flex;
`;

export const AntdButton = styled(Button)`
	display: flex;
	height: 40px;
	align-items: center;
	background-color: ${(props) =>
		props.success ? COLORS.Success : COLORS.Secondary};
	color: ${COLORS.White};
	border: 1px solid
		${(props) => (props.success ? COLORS.Success : COLORS.Secondary)};
	border-radius: 7px;
	padding: 1rem;
	&:hover {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
		color: ${COLORS.White};
	}
	& span {
		color: ${COLORS.White};
	}
	position: relative;

	&:focus {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
	}
	&:active {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
	}
`;

export const Container = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const UploadFile = styled(Upload)`
	width: 100%;
	min-height: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 2px solid ${COLORS.LightGrey};
	border-radius: 10px;

	& span {
		color: ${COLORS.Primary};
	}

	position: relative;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: row;
	}
`;

export const ResponseHistoryContainer = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
