import React, { useEffect, useState } from "react";
import {
	get_import_history,
	get_import_history_detail,
} from "../../../../../../api/endpoints/products";
import { TableContainer, AntdTable, ActionButton, AntdModal } from "./styles";
import { Dropdown, Menu } from "antd";
import { COLORS, Typo } from "../../../../../layout/theme";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
	get_general_pos_history,
	get_general_pos_history_detail,
} from "../../../../../../api/endpoints/pointsofsale";
import moment from "moment";
import { CONSTANTS } from "../../../../../../utils/constants";

export default function ImportHistory({ isLoading }) {
	const [data, setData] = useState(false);
	const [key, setKey] = useState(null);
	const [dataDetails, setDataDetails] = useState(null);
	const [showHistoryDetailModal, setShowHistoryDetailModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const fetch_import_history = async () => {
		setLoading(true);
		const res = await get_general_pos_history(
			CONSTANTS.GENERAL_POS_HISTORY_CREATED
		);
		setData(res.items);
		setLoading(false);
	};

	useEffect(() => {
		fetch_import_history();
	}, [isLoading]);

	const handleHistoryDetail = async (history) => {
		setLoading(true);
		const res = await get_general_pos_history_detail(history);
		setDataDetails(res);
		setShowHistoryDetailModal(true);
		setLoading(false);
	};

	const handleOk = () => {
		setShowHistoryDetailModal(false);
	};

	const handleCancel = () => {
		setShowHistoryDetailModal(false);
	};

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Typo type="secondary" onClick={() => handleHistoryDetail(key)}>
							Ver Detalle
						</Typo>
					),
				},
			]}
		/>
	);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Fecha</div>,
			dataIndex: "created",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					{moment(value).format("DD-MM-YYYY HH:ss")}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Usuario</div>,
			dataIndex: "user",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{record.userFirstName + " " + record.userLastName}
				</div>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Laboratorio emisor</div>
			),
			dataIndex: "clientName",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Puntos de venta agregados</div>
			),
			dataIndex: "addedPos",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			width: 50,
			align: "center",
			className: "noBackground",
			render: (f, r, i) => (
				<>
					<Dropdown overlay={menu} placement="bottomRight">
						<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const detailColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Primary,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Address</div>,
			dataIndex: "address",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cuit</div>,
			dataIndex: "cuit",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Provincia</div>,
			dataIndex: "province",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Localidad</div>,
			dataIndex: "location",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
	];

	return (
		<TableContainer>
			<AntdTable
				dataSource={data}
				columns={columns}
				loading={loading}
				pagination={{ pageSize: 25, showSizeChanger: false }}
				rowClassName={(record) => (record.hasChanges ? "hasChanges" : null)}
			/>
			<AntdModal
				title={`Historial importación día: ${key?.created}`}
				open={showHistoryDetailModal}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1200}
				centered={true}
				footer={false}
				destroyOnClose={true}
			>
				<AntdTable
					dataSource={dataDetails?.items}
					columns={detailColumns}
					loading={loading}
					pagination={{ pageSize: 25, showSizeChanger: false }}
					scroll={{
						x: 1500,
						y: 450,
					}}
				/>
			</AntdModal>
		</TableContainer>
	);
}
