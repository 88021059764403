import { Row } from "antd";
import styled from "styled-components/macro";
import { SIZES, COLORS, WEIGHT } from "../theme/index";

export const Inner = styled.div`
	@media only screen and (min-width: 1286px) {
		position: relative;
		height: 100%;
		padding: 0px 0 0 0;
	}
`;

export const LayoutHeader = styled(Row)`
	position: fixed;
	left: 0;
	${(props) => !props.scroll && "top: 90px"}
	${(props) =>
		props.scroll && props.scroll === "up" ? "top: 0px" : "top: -90px"};
	display: flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	height: 90px;
	width: 100%;
	padding: 0 20px 0 30px;
	background-color: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
	appearance: none;
	-webkit-appearance: none;
	align-content: center;
	z-index: 200;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 500ms;
`;

export const LayoutHeaderOptions = styled(Row)`
	justify-content: space-evenly;
	align-items: center;
	align-content: middle;

	@media only screen and (min-width: 760px) and (max-width: 1280px) {
		justify-content: space-around;
		align-content: middle;
		scale: 0.9;
	}

	@media only screen and (min-width: 1281px) and (max-width: 1380px) {
		justify-content: space-around;
		align-content: middle;
	}
`;

export const Header = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 90px;
	width: 100%;
	padding: 0 20px 0 30px;
	background-color: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
`;

export const HeaderLogo = styled.div`
	${"" /* width: 200px; */}
	display: flex;
	${"" /* position: relative; */}
	margin-bottom: 9px;

	& img {
		object-fit: contain;
	}
`;

export const HeaderLinks = styled.div`
	display: flex;
	gap: ${({ lab }) => (lab ? "20px" : "50px")};
	padding: 0 40px 10px 0;
`;

export const HeaderSearch = styled.div`
	margin-bottom: 9px;

	@media (max-width: 1180px) {
		transition: transform 0.1s;
		position: absolute;
		margin-top: 100px;
	}
`;

export const HeaderIconGroup = styled.div`
	width: 350px;
	display: flex;
	flex-direction: row;
	align-content: space-between;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 9px;
`;

export const HeaderRight = styled.div`
	gap: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const HeaderAction = styled.div`
	display: flex;
	align-items: center;
	font-weight: ${WEIGHT.Bold};
	cursor: pointer;
	position: relative;

	@media only screen and (min-width: 760px) and (max-width: 1160px) {
		scale: 0.8;
	}
`;

export const HeaderUserIconContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	gap: 10px;
	align-items: center;
`;

export const HeaderUserParagraph = styled.div`
	display: flex;
	flex-direction: column;
	color: #bbc4c2;
`;

export const HeaderIcon = styled.div`
	display: flex;
`;

export const HeaderMenuIcon = styled.div`
	cursor: pointer;
	background-color: #0a4997;
	border-radius: 40px;
	font-weight: ${WEIGHT.Black};
	align-items: center;
	align-content: center;
`;

export const HeaderMenuIconContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 40px;
	height: 40px;
	align-items: center;
	margin-left: 0.01em;
	font-size: ${SIZES.level6};

	.ant-image {
		${(props) => props.firefox && `width: 100%`};
	}
`;

export const HeaderMenuParagraph = styled.div`
	display: flex;
	width: 30px;
	position: absolute;
	color: #0a4997;
	${"" /* font-size: 14px; */}
	margin-left: 80px;
	text-decoration: none;
`;

export const Main = styled.main`
	padding-top: 70px;
	height: 100%;
`;

export const HeaderActionParagraph = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	&:hover {
		color: ${COLORS.Primary};
	}

	color: ${COLORS.Grey};

	@media only screen and (min-width: 760px) and (max-width: 1190px) {
		scale: 0.9;
	}
`;

export const HeaderSpan = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const HeaderUserIcon = styled.div`
	display: flex;
	justify-content: center;
	width: 40px;
	height: 40px;
	cursor: pointer;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-color: ${COLORS.White};
	border: 2px solid ${COLORS.LightGrey};
	border-radius: 50%;
	box-shadow: 0 3px 3px 0 ${COLORS.LightGrey};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.Black};
	color: ${COLORS.Grey};
	text-align: right;
`;

export const WhatsAppButton = styled.div`
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	width: 60px;
	height: 60px;
	background-color: #128c7e;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: 250ms ease-in-out;
	transform: scale(1, 1);
	z-index: 999;

	&:hover {
		background-color: #25d366;
	}
	&:active {
		transform: scale(1.1, 1.1);
	}
`;
