import React, { useState } from "react";
import { AntdButton, BannerListContainer, Inner, Section } from "./styles";
import CampaignCardContainer from "../components/campaign-container/CampaignCardContainer";
import { update_campaign_type } from "../../../../../api/endpoints/campaigns";
import SkeletonSlidersList from "../components/skeleton-sliders-list/SkeletonSlidersList";
import { Divider } from "antd";
import { COLORS, Typo } from "../../../../layout/theme";

export default function CampaignsSliders({ campaigns }) {
	const [state, setState] = useState("ACTIVE");

	const handleTypeChange = async (campaignType) => {
		await update_campaign_type(campaignType);
	};

	if (!campaigns) return <SkeletonSlidersList />;

	return (
		<Inner>
			<Section>
				<AntdButton
					type="primary"
					bg="Primary"
					color="White"
					style={{ fontWeight: "600" }}
					width={200}
					height={28}
					fontSize={12}
					onClick={() => setState("ACTIVE")}
					$active={state === "ACTIVE"}
				>
					Activas
				</AntdButton>
				<AntdButton
					type="primary"
					bg="Primary"
					color="White"
					style={{ fontWeight: "600", marginLeft: "20px" }}
					width={200}
					height={28}
					fontSize={12}
					onClick={() => setState("INACTIVE")}
					$active={state === "INACTIVE"}
				>
					Finalizadas
				</AntdButton>
			</Section>
			<Section>
				{state === "ACTIVE" && (
					<>
						<Divider orientation="left">
							<Typo type="secondary" level={4}>
								Online
							</Typo>
						</Divider>
						<BannerListContainer>
							{campaigns &&
								campaigns.activeCampaigns.map((b) => (
									<CampaignCardContainer
										key={b.id}
										item={b}
										handleTypeChange={handleTypeChange}
										iconColor={COLORS.Success}
										iconText="Campaña Online"
									/>
								))}
						</BannerListContainer>
						<Divider orientation="left">
							<Typo type="secondary" level={4}>
								Futuras
							</Typo>
						</Divider>
						<BannerListContainer>
							{campaigns &&
								campaigns.futureCampaigns.map((b) => (
									<CampaignCardContainer
										key={b.id}
										item={b}
										handleTypeChange={handleTypeChange}
										iconColor={COLORS.Warning}
										iconText="Campaña futura"
									/>
								))}
						</BannerListContainer>
					</>
				)}

				{state === "INACTIVE" && (
					<BannerListContainer>
						{campaigns &&
							campaigns.finishedCampaigns.map((b) => (
								<CampaignCardContainer
									key={b.id}
									item={b}
									handleTypeChange={handleTypeChange}
									iconColor={COLORS.Grey}
									iconText="Campaña finalizada"
								/>
							))}
					</BannerListContainer>
				)}
			</Section>
		</Inner>
	);
}
