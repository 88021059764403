import { Table } from "antd";
import styled from "styled-components/macro";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	overflow: auto;
	align-content: center;
	align-items: flex-start;
	justify-content: center;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
`;

export const Container = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	gap: 20px;
`;

export const HistoryTable = styled(Table)`
	width: 100%;
	height: 100%;
	overflow: auto;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const Title = styled.div`
	color: ${COLORS.Grey};
	font-weight: 600;
	font-size: 14px;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
`;
