import styled from "styled-components";
import { Radio, Select, Option } from "antd";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";

export const Filters = styled.div`
	margin: 0px 0px 20px 0px;

	& button {
		padding: 0 !important;
	}
`;

export const RadioOptions = styled.div`
	margin-bottom: 20px;
`;

export const AntdRadioButton = styled(Radio.Button)`
	border: none;
	font-weight: ${WEIGHT.Bold};
`;

export const AntdSelect = styled(Select)`
	border: none;
	font-weight: ${WEIGHT.SemiBold};
`;

export const AntdOption = styled.div`
	border: none;
	font-weight: ${WEIGHT.SemiBold};
	color: ${COLORS.Grey};
`;

export const Line = styled.span`
	position: relative;
	&::before {
		content: "";
		width: 120%;
		background-color: ${COLORS.Grey};
		height: 3px;
		position: absolute;
		z-index: 99;
		top: 50%;
		left: -2px;
	}
`;
export const OfferPrice = styled.span`
	margin-left: 10px;
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level11};
`;
export const Price = styled.span``;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const UnP = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const OptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
