import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../layout/theme";

export const Inner = styled.div`
	background-color: ${COLORS.LightGrey};
`;

export const Section = styled.div`
	padding: 40px 90px 30px 90px;

	@media only screen and (min-width: 1901px) and (max-width: 2300px) {
		padding: 40px 300px 30px 300px;
	}

	@media only screen and (min-width: 1501px) and (max-width: 1900px) {
		padding: 40px 200px 30px 200px;
	}

	@media only screen and (min-width: 1400px) and (max-width: 1500px) {
		padding: 40px 100px 30px 100px;
	}

	@media only screen and (min-width: 1299px) and (max-width: 1399px) {
		padding: 40px 90px 30px 90px;
	}
`;

export const SectionBanners = styled.div`
	padding: 30px 30px 30px 30px;
`;

export const BannerContainer = styled.div`
	@media only screen and (min-width: 760px) and (max-width: 1069px) {
	}
`;
