import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Main from "../../../../layout/main/Main";
import { Space, Row, Col, Dropdown, Menu, Modal, Radio } from "antd";
import {
	ActionButton,
	AntdRadioButton,
	Filters,
	RadioOptions,
	TableContainer,
} from "./styles";
import Image from "../../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import { COLORS, Typo } from "../../../../layout/theme";
import DataTable from "../../../../common/data-table/DataTable";
import {
	get_clients_requests,
	get_clients_request_header_info,
	update_approved,
} from "../../../../../api/endpoints/clients";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import LoadingHeader from "./components/LoadingHeader";
import {
	create_history_approval,
	get_history_approval_by_client_id,
} from "../../../../../api/endpoints/historyApprovals";
import HistoryModal from "./components/HistoryModal/HistoryModal";
import { useAuth } from "../../../../../contexts/authContext";
import { send_approved_validation_email } from "../../../../../api/endpoints/auth";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import moment from "moment";
import { get_provinces } from "../../../../../api/endpoints/region";

const { confirm } = Modal;

export default function RequestList() {
	const { updateRequestValidation, setRequestsQty } = useAuth();
	const [data, setData] = useState(null);
	const [state, setState] = useState("PENDING");
	const [key, setKey] = useState("null");
	const [headerInfo, setHeaderInfo] = useState(null);
	const [show, setShow] = useState(false);
	const [history, setHistory] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [provinceIds, setProvinceIds] = useState(null);
	const [filteredProvinces, setFilteredProvinces] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res.map((p) => ({
					id: p.id,
					name: p.title,
				}))
			);
		};

		fetch_region_1_data();
	}, []);

	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			fixed: "left",
			width: 100,
			render: (value, record, index) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cliente</div>,
			dataIndex: "name",
			fixed: "left",
			width: 180,
			render: (value, record) => (
				<Link
					to={`/admin/marketRequests/${key.id}`}
					onMouseOver={() => setKey(record)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
					{record.isMobileRequest && (
						<Row gutter={5}>
							<Col>
								<Typo type="secondary" fontWeight={200} fontSize={10}>
									origen Mobile
								</Typo>
							</Col>
						</Row>
					)}
					{record.isExpoRequest && (
						<Row gutter={5}>
							<Col>
								<Typo type="secondary" fontWeight={200} fontSize={10}>
									origen Expo
								</Typo>
							</Col>
						</Row>
					)}
					{record.isWebSellerRequest && (
						<Row gutter={5}>
							<Col>
								<Typo type="secondary" fontWeight={200} fontSize={10}>
									origen Vendedor Web
								</Typo>
							</Col>
						</Row>
					)}
				</Link>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Contacto</div>,
			dataIndex: "contactName",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Telefono</div>,
			dataIndex: "contactPhone",
			width: 150,
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Mail</div>,
			dataIndex: "contactEmail",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cuit</div>,
			dataIndex: "cuit",
			width: 150,
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Fecha</div>,
			dataIndex: "created",
			width: 200,
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{moment(fila).format("DD/MM/YYYY hh:mm:SS")}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Provincia</div>,
			dataIndex: "provinceId",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{listadoProvincias?.find((p) => p.id === value)?.name}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			width: 30,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={
							r.users[0].approved
								? r.deleted
									? menuRejected
									: menuApproved
								: r.deleted
								? menuRejected
								: menu
						}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/marketRequests/${key.id}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleApprove(key)}>Aprobar</Typo>,
				},
				{
					key: "3",
					label: <Typo onClick={() => handleReject(key)}>Rechazar</Typo>,
				},
			]}
		/>
	);

	const menuApproved = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/marketRequests/${key.id}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleReject(key)}>Rechazar</Typo>,
				},
				{
					key: "3",
					label: (
						<Typo onClick={() => handleHistoryView(key)}>Ver historial</Typo>
					),
				},
			]}
		/>
	);

	const menuRejected = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/marketRequests/${key.id}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleApprove(key)}>Aprobar</Typo>,
				},
				{
					key: "3",
					label: (
						<Typo onClick={() => handleHistoryView(key)}>Ver historial</Typo>
					),
				},
			]}
		/>
	);

	const fetch_requests = async (page, estado = state, search) => {
		setLoading(true);
		const res = await get_clients_requests(search, estado, page);
		//setData(res);
		setData({
			...res,
			items: res.items.map((r) => {
				const splitedRegion = r.clientRegion
					? r.clientRegion.split("-")
					: [null, null, null];
				return {
					...r,
					provinceId: splitedRegion[1],
					localityId: splitedRegion[2],
				};
			}),
		});
	};

	const set_province_ids = () => {
		const uniqueProvinceIds = Array.from(
			new Set(data?.items?.map((item) => item.provinceId))
		);
		const provinciasFiltradas = listadoProvincias?.filter((provincia) =>
			uniqueProvinceIds?.includes(provincia.id)
		);
		setProvinceIds(uniqueProvinceIds);
		setFilteredProvinces(
			provinciasFiltradas?.map((p) => ({
				text: p.name,
				value: p.id,
			}))
		);
	};

	const fetch_requests_header = async () => {
		const res = await get_clients_request_header_info();
		setHeaderInfo(res);
		setRequestsQty(res.totalPending);
	};

	useEffect(() => {
		fetch_requests_header();
	}, [state]);

	useEffect(() => {
		fetch_requests(1, state, "");
		setLoading(false);
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_requests(1, state, e.target.value);
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 250),
		[state]
	);

	const handleApprove = (key) => {
		confirm({
			title: "¿Seguro que quiere aprobar esta Solicitud?",
			icon: <InfoCircleOutlined style={{ color: "green" }} />,
			content: "La aprobación de la solicitud se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				approveRequest(key);
			},
		});
	};

	const approveRequest = async (key) => {
		const _state = {
			id: key.id,
			userId: key.users[0].id,
			approve: true,
			deleted: false,
		};
		const _history = {
			clientId: key.id,
			userId: key.users[0].id,
			changedState: "APPROVED",
			approvalStatus: true,
		};
		setState("APPROVED");
		await update_approved(_state);
		await updateRequestValidation(_state.userId, _state.approve);
		await send_approved_validation_email(_state.userId);
		await create_history_approval(_history);
		fetch_requests(1, "APPROVED", "");
		fetch_requests_header();
	};

	const handleReject = (key) => {
		confirm({
			title: "¿Seguro que quiere rechazar esta Solicitud?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "El rechazo de la solicitud se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				rejectRequest(key);
			},
		});
	};

	const rejectRequest = async (key) => {
		const _state = {
			id: key.id,
			userId: key.users[0].id,
			approve: false,
			deleted: true,
		};
		const _history = {
			clientId: key.id,
			userId: key.users[0].id,
			changedState: "REJECTED",
			approvalStatus: false,
		};
		setState("REJECTED");
		await update_approved(_state);
		await updateRequestValidation(_state.userId, _state.approve);
		await create_history_approval(_history);
		fetch_requests(1, "REJECTED", "");
		fetch_requests_header();
	};

	const handleHistoryView = async (client) => {
		const res = await get_history_approval_by_client_id(client.id);
		setHistory(res);
		setShow(true);
	};

	const handleOk = () => {
		setShow(false);
	};

	const handleCancel = () => {
		setShow(false);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Solicitudes</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<AntdSearch
								width={400}
								allowClear
								placeholder="Buscar farmacia"
								onChange={debouncedEventHandler}
							/>
						</Space>
					)}
				</Filters>
				<RadioOptions>
					<Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
						<AntdRadioButton value="PENDING">
							Pendientes ({headerInfo?.totalPending})
						</AntdRadioButton>
						<AntdRadioButton value="APPROVED">
							Aprobadas ({headerInfo?.totalApproved})
						</AntdRadioButton>
						<AntdRadioButton value="REJECTED">
							Rechazadas ({headerInfo?.totalRejected})
						</AntdRadioButton>
					</Radio.Group>
				</RadioOptions>
				<TableContainer>
					<DataTable
						data={data}
						fetch={fetch_requests}
						columns={columns}
						scroll={{
							x: 1500,
							y: 450,
						}}
					/>
				</TableContainer>
				<Modal
					visible={show}
					onOk={handleOk}
					onCancel={handleCancel}
					width={900}
					centered={true}
					footer={null}
					destroyOnClose={true}
				>
					<HistoryModal client={key} history={history} />
				</Modal>
			</Main.Body>
		</Main>
	);
}
