import styled from "styled-components";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 20px;
`;

export const Container = styled.div`
	display: flex;
	flex: 1 1 50%;
	flex-direction: column;
	background-color: ${COLORS.White};
	width: 100%;
	padding: 20px;
	border-radius: 20px;
`;
