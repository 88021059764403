import React, { useState, useEffect } from "react";
import { COLORS, Typo } from "../../../layout/theme";
import { DrawerContainer, FilterContainerButton, Inner } from "./styles";
import {
	get_dinamic_mobile_dashboard,
	get_dinamic_sales_dashboard,
	get_pharmacy_laboratory_dashboard,
} from "../../../../api/endpoints/dashboard";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/authContext";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import {
	BarChartOutlined,
	MobileOutlined,
	QuestionCircleOutlined,
	CloseCircleOutlined,
} from "@ant-design/icons";
import { numberWithDotAndCommas } from "../../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../../utils/constants";
import moment from "moment";
import { Layout, Menu, Tooltip } from "antd";
import SaleDashboard from "./components/dashboards/SaleDashboard";
import MobileDashboard from "./components/dashboards/MobileDashboard";
import { useFilter } from "../../../../contexts/filterContext";
import { FaInfo } from "react-icons/fa";
import { numberToMillions } from "../../../../utils/numberToMillions";
import { TiPlusOutline } from "react-icons/ti";
import PharmacyDashboard from "./components/dashboards/PharmacyDashboard";
const { Sider } = Layout;

export default function DashboardSeller({ clientId, clientUser }) {
	const navigate = useNavigate();
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const {
		selectedDistributor,
		setSelectedDistributor,
		selectedLaboratory,
		setSelectedLaboratory,
		selectedPharmacy,
		setSelectedPharmacy,
		selectedPointOfSale,
		setSelectedPointOfSale,
		selectedProvince,
		setSelectedProvince,
		rangeDate,
		setRangeDate,
		dateFrom,
		setDateFrom,
		dateTo,
		setDateTo,
		mobilePeriod,
		setMobilePeriod,
		selectedFilters,
		setSelectedFilters,
		selectedTypeOfSale,
		setSelectedTypeOfSale,
	} = useFilter();
	const [collapsed, setCollapsed] = useState(true);
	const [openPanel, setOpenPanel] = useState([]);
	const [display, setDisplay] = useState(CONSTANTS.DASHBOARD_SALES);
	const [isLoading, setIsLoading] = useState(false);
	const [distributorsD2, setDistributorsD2] = useState(null);
	const [provincesD2, setProvincesD2] = useState(null);
	const [generalInfoD2, setGeneralInfoD2] = useState(null);
	const [pieDataD2, setPieDataD2] = useState(null);
	const [timeSalesGraphD2, setTimeSalesGraphD2] = useState(null);
	const [timePharmacyOrdersGraphD2, setTimePharmacyOrdersGraphD2] =
		useState(null);
	const [timeOrdersGraphD2, setTimeOrdersGraphD2] = useState(null);
	const [timeUnitsGraphD2, setTimeUnitsGraphD2] = useState(null);
	const [productSalesGraphD2, setProductSalesGraphD2] = useState(null);
	const [productUnitsGraphD2, setProductUnitsGraphD2] = useState(null);
	const [productGraphSelectorD2, setProductGraphSelectorD2] = useState("SALES");
	const [distributorSalesGraphD2, setDistributorSalesGraphD2] = useState(null);
	const [typesOfSalesD2, setTypesOfSalesD2] = useState(null);
	const [VSInfoSelector, setVSInfoSelector] = useState("SALES");
	const [timeVSSalesGraphD2, setTimeVSSalesGraphD2] = useState(null);
	const [timeVSUnitsGraphD2, setTimeVSUnitsGraphD2] = useState(null);
	const [timeVSOrdersGraphD2, setTimeVSOrdersGraphD2] = useState(null);

	const [distributorsD3, setDistributorsD3] = useState(null);
	const [provincesD3, setProvincesD3] = useState(null);
	const [laboratoriesD3, setLaboratoriesD3] = useState(null);
	const [usersD3, setUsersD3] = useState(null);
	const [selectedUserD3, setSelectedUserD3] = useState(null);
	const [dateFromD3, setDateFromD3] = useState(
		moment().startOf("month").format("MM-DD-YYYY").toString()
	);
	const [dateToD3, setDateToD3] = useState(
		moment().endOf("month").format("MM-DD-YYYY").toString()
	);
	const [rangeDateD3, setRangeDateD3] = useState([
		moment().startOf("month"),
		moment().endOf("month"),
	]);
	const [generalInfoD3, setGeneralInfoD3] = useState(null);
	const [pieDataD3, setPieDataD3] = useState(null);
	const [timePharmacyOrdersGraphD3, setTimePharmacyOrdersGraphD3] =
		useState(null);
	const [sellerSalesTableD3, setSellerSalesTableD3] = useState(null);
	const [timeOrdersGraphD3, setTimeOrdersGraphD3] = useState(null);
	const [distributorSalesGraphD3, setDistributorSalesGraphD3] = useState(null);
	const [distributorOrdersGraphD3, setDistributorOrdersGraphD3] =
		useState(null);
	const [distributorUnitsGraphD3, setDistributorUnitsGraphD3] = useState(null);
	const [distributorGraphSelector, setDistributorGraphSelector] =
		useState("SALES");
	const [ordersInfoSelector, setOrdersInfoSelector] = useState("SALES");
	const [distributorsPieInfoSelector, setDistributorsPieInfoSelector] =
		useState("UNITS");
	const [openFilterList, setOpenFilterList] = useState(false);
	const [secondPieDataD2, setSecondPieDataD2] = useState(null);
	const [regionSalesGraphD2, setRegionSalesGraphD2] = useState(null);
	const [distributorsD4, setDistributorsD4] = useState(null);
	const [laboratoriesD4, setLaboratoriesD4] = useState(null);
	const [pharmaciesD4, setPharmaciesD4] = useState(null);
	const [provincesD4, setProvincesD4] = useState(null);
	const [generalInfoD4, setGeneralInfoD4] = useState(null);
	const [pharmaciesTable, setPharmaciesTable] = useState(null);
	const [productSalesGraphD3, setProductSalesGraphD3] = useState(null);
	const [productUnitsGraphD3, setProductUnitsGraphD3] = useState(null);
	const [productGraphSelectorD3, setProductGraphSelectorD3] = useState("SALES");

	const fetch_sales_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_dinamic_sales_dashboard(
				selectedDistributor,
				clientId,
				selectedProvince,
				dateFrom,
				dateTo,
				selectedTypeOfSale
			);
			const resDistributorsD2 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD2 = JSON.parse(res?.generalInfo?.provinceList);
			const slicedProductSales = res?.productSales.slice(0, 40);
			const slicedProductUnits = res?.productSales
				.sort((a, b) => b.totalUnits - a.totalUnits)
				.slice(0, 40);

			setDistributorsD2(
				resDistributorsD2?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD2(
				resProvincesD2?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setTypesOfSalesD2([
				{
					value: null,
					label: "Total",
				},
				{
					value: "WEB",
					label: "Web",
				},
				{
					value: "MOBILE",
					label: "Mobile",
				},
			]);

			setGeneralInfoD2(res?.generalInfo);

			setPieDataD2({
				chart: {
					type: "variablepie",
					height: "100%",
				},
				title: {
					style: { display: "none" },
				},
				credits: { enabled: false },
				tooltip: {
					pointFormat: "<b>{point.y}</b>",
				},
				legend: {
					layout: "vertical",
					align: "right",
					verticalAlign: "top",
					x: 0,
					y: 10,
					floating: false,
				},
				plotOptions: {
					variablepie: {
						size: "150",
						cursor: "pointer",
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
						center: ["50%", "18%"],
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						type: "variablepie",
						zMin: 0,
						name: "Droguerias",
						data: res?.distributorOrders?.map((dist, idx) => ({
							name: dist.distributorName,
							y: dist.totalInOrders,
							z: 100,
						})),
					},
				],
			});

			setTimeSalesGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return numberToMillions(this.total);
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.ordersByYearAndMonth.map((p) => p.totalSales),
						lineWidth: 2,
						lineColor: COLORS.Success,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Success,
							},
							{
								color: COLORS.LightSuccess,
							},
						],
					},
				],
			});

			setTimePharmacyOrdersGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.pharmacyOrdersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.pharmacyOrdersByYearAndMonth.map((p) => p.totalOrders),
						lineWidth: 2,
						lineColor: COLORS.Success,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.pharmacyOrdersByYearAndMonth[
										res.pharmacyOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.pharmacyOrdersByYearAndMonth.length - 2
										: res?.pharmacyOrdersByYearAndMonth.length,
								color: COLORS.Success,
							},
							{
								color: COLORS.LightSuccess,
							},
						],
					},
				],
			});

			setTimeOrdersGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.totalOrdersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.totalOrdersByYearAndMonth.map((p) => p.totalOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.Success,
							lineWidth: 2,
							lineColor: COLORS.Success,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.totalOrdersByYearAndMonth[
										res.totalOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.totalOrdersByYearAndMonth.length - 2
										: res?.totalOrdersByYearAndMonth.length,
								color: COLORS.Secondary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setDistributorSalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.distributorSales.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						name: "",
						data: res?.distributorSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setSecondPieDataD2({
				chart: {
					type: "variablepie",
					height: "100%",
				},
				title: {
					style: { display: "none" },
				},
				credits: { enabled: false },
				tooltip: {
					pointFormat: "<b>{point.y}</b>",
				},
				legend: {
					layout: "vertical",
					align: "right",
					verticalAlign: "top",
					x: 0,
					y: 10,
					floating: false,
				},
				plotOptions: {
					variablepie: {
						size: "150",
						cursor: "pointer",
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
						center: ["50%", "18%"],
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						type: "variablepie",
						zMin: 0,
						name: "Droguerias",
						data: res?.distributorSales?.map((dist, idx) => ({
							name: dist.distributorName,
							y: dist.totalInOrders,
							z: 100,
						})),
					},
				],
			});

			setTimeUnitsGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.ordersByYearAndMonth.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Secondary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setProductSalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductSales.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductUnitsGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductUnits.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -5,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductUnits.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setRegionSalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.regionSales.map((p) => p.provinceName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: res?.regionSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			//Mapeo para completar meses sin ventas mobile
			const mobileSalesMap = res?.mobileOrdersByYearAndMonth?.reduce(
				(acc, curr) => {
					acc[curr.saleMonth] = curr.totalSales;
					return acc;
				},
				{}
			);

			const mobileSalesData = res?.ordersByYearAndMonth?.map((p) => {
				const mobileSales = mobileSalesMap[p.saleMonth] || 0;
				return mobileSales;
			});

			const mobileUnitsMap = res?.mobileOrdersByYearAndMonth?.reduce(
				(acc, curr) => {
					acc[curr.saleMonth] = curr.totalUnits;
					return acc;
				},
				{}
			);

			const mobileUnitsData = res?.ordersByYearAndMonth?.map((p) => {
				const mobileUnits = mobileUnitsMap[p.saleMonth] || 0;
				return mobileUnits;
			});

			const mobileOrdersMap = res?.mobileTotalOrdersByYearAndMonth?.reduce(
				(acc, curr) => {
					acc[curr.saleMonth] = curr.totalOrders;
					return acc;
				},
				{}
			);

			const mobileOrdersData = res?.totalOrdersByYearAndMonth?.map((p) => {
				const mobileOrders = mobileOrdersMap[p.saleMonth] || 0;
				return mobileOrders;
			});

			setTimeVSSalesGraphD2({
				chart: { type: "spline" },
				title: { text: undefined },
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: { enabled: false },
				colors: [COLORS.Primary, COLORS.Default],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						visible: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: COLORS.LightGrey,
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [{ color: COLORS.LightGrey, width: 1, value: 0 }],
						stackLabels: {
							enabled: true,
							style: { color: COLORS.MediumGrey, textOutline: 0 },
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return numberToMillions(this.total);
								} else {
									return null;
								}
							},
						},
					},
					{
						visible: false,
						opposite: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
					},
				],
				plotOptions: {
					series: { stacking: "normal" },
				},
				series: [
					{
						name: "Ventas Totales",
						data: res?.ordersByYearAndMonth.map((p) => p.totalSales),
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.Primary,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{ color: COLORS.LightSecondary },
						],
						yAxis: 0,
					},
					{
						name: "Ventas Mobile",
						data: mobileSalesData,
						type: "area",
						lineWidth: 2,
						lineColor: COLORS.Default,
						marker: {
							fillColor: COLORS.Default,
							lineWidth: 2,
							lineColor: COLORS.Default,
							symbol: "circle",
							radius: 2,
						},
						yAxis: 0,
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Default,
							},
							{ color: COLORS.LightDefault },
						],
					},
				],
			});

			setTimeVSUnitsGraphD2({
				chart: { type: "spline" },
				title: { text: undefined },
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: { enabled: false },
				colors: [COLORS.Primary, COLORS.Default],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						visible: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [{ color: "#FF0000", width: 1, value: 0 }],
						stackLabels: {
							enabled: true,
							style: { color: COLORS.MediumGrey, textOutline: 0 },
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return this.total;
								} else {
									return null;
								}
							},
						},
					},
					{
						visible: false,
						opposite: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
					},
				],
				plotOptions: {
					series: { stacking: "normal" },
				},
				series: [
					{
						name: "Unidades Totales",
						data: res?.ordersByYearAndMonth.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.Primary,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{ color: COLORS.LightSecondary },
						],
						yAxis: 0,
					},
					{
						name: "Unidades Mobile",
						data: mobileUnitsData,
						type: "area",
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 2,
						},
						yAxis: 0,
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Default,
							},
							{ color: COLORS.LightDefault },
						],
					},
				],
			});

			setTimeVSOrdersGraphD2({
				chart: { type: "spline" },
				title: { text: undefined },
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: { enabled: false },
				colors: [COLORS.Primary, COLORS.Default],
				credits: { enabled: false },
				xAxis: {
					categories: res?.totalOrdersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						visible: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [{ color: "#FF0000", width: 1, value: 0 }],
						stackLabels: {
							enabled: true,
							style: { color: COLORS.MediumGrey, textOutline: 0 },
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return this.total;
								} else {
									return null;
								}
							},
						},
					},
					{
						visible: false,
						opposite: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
					},
				],
				plotOptions: {
					series: { stacking: "normal" },
				},
				series: [
					{
						name: "Ordenes Totales",
						data: res?.totalOrdersByYearAndMonth.map((p) => p.totalOrders),
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.Primary,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.totalOrdersByYearAndMonth[
										res.totalOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.totalOrdersByYearAndMonth.length - 2
										: res?.totalOrdersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{ color: COLORS.LightSecondary },
						],
						yAxis: 0,
					},
					{
						name: "Ordenes Mobile",
						data: mobileOrdersData,
						type: "area",
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 2,
						},
						yAxis: 0,
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.totalOrdersByYearAndMonth[
										res.totalOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.totalOrdersByYearAndMonth.length - 2
										: res?.totalOrdersByYearAndMonth.length,
								color: COLORS.Default,
							},
							{ color: COLORS.LightDefault },
						],
					},
				],
			});
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_mobile_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_dinamic_mobile_dashboard(
				selectedDistributor,
				clientId,
				selectedUserD3,
				selectedProvince,
				dateFromD3,
				dateToD3
			);
			const resDistributorsD3 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD3 = JSON.parse(res?.generalInfo?.provinceList);
			const resSellersD3 = JSON.parse(res?.generalInfo?.sellerList);
			const slicedProductSales = res?.productSales.slice(0, 25);
			const slicedProductUnits = res?.productSales
				.sort((a, b) => b.totalUnits - a.totalUnits)
				.slice(0, 25);
			const distributorsUnits = res?.distributorSales.sort(
				(a, b) => b.totalUnits - a.totalUnits
			);

			setDistributorsD3(
				resDistributorsD3?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD3(
				resProvincesD3?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setUsersD3(
				resSellersD3?.map((r) => ({
					value: r.SellerId,
					label: r.SellerFirstName + " " + r.SellerLastName,
				}))
			);

			setGeneralInfoD3(res?.generalInfo);

			setTimePharmacyOrdersGraphD3({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.posOrderingByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return numberToMillions(this.total);
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.posOrderingByYearAndMonth.map((p) => p.totalPosOrdering),
						lineWidth: 2,
						lineColor: COLORS.Success,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.posOrderingByYearAndMonth[
										res.posOrderingByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.posOrderingByYearAndMonth.length - 2
										: res?.posOrderingByYearAndMonth.length,
								color: COLORS.Success,
							},
							{
								color: COLORS.LightSuccess,
							},
						],
					},
				],
			});

			setSellerSalesTableD3(res?.sellerSales);

			setTimeOrdersGraphD3({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Primary, COLORS.Secondary],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: true,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						stackLabels: {
							enabled: true,
							style: {
								color: COLORS.MediumGrey,
								textOutline: 0,
							},
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return numberToMillions(this.total);
								} else {
									return null;
								}
							},
						},
					},
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: false,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
				],
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "Ventas $",
						valuePrefix: "$",
						data: res?.ordersByYearAndMonth.map((p) => p.totalSales),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
					{
						name: "Unidades",
						valueSuffix: "un.",
						data: res?.ordersByYearAndMonth.map((p) => p.totalUnits),
						yAxis: 1,
						lineWidth: 2,
						lineColor: COLORS.Default,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Default,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Default,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setDistributorSalesGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.distributorSales.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: res?.distributorSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setDistributorUnitsGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: distributorsUnits.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: distributorsUnits.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setDistributorOrdersGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.distributorOrders.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: res?.distributorOrders.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductSalesGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductSales.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductUnitsGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y + " un.";
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductUnits.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductUnits.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_pharmacy_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_pharmacy_laboratory_dashboard(
				selectedDistributor,
				clientId,
				selectedPharmacy,
				selectedPointOfSale,
				selectedProvince,
				dateFrom,
				dateTo
			);
			const resDistributorsD4 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD4 = JSON.parse(res?.generalInfo?.provinceList);
			const resLaboratoriesD4 = JSON.parse(res?.generalInfo?.laboratoryList);
			const resPharmaciesD4 = JSON.parse(res?.generalInfo?.pharmacyList);
			const slicedPharmacyTable = res?.pharmacyOrdersList.slice(0, 50);

			setDistributorsD4(
				resDistributorsD4?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD4(
				resProvincesD4?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLaboratoriesD4(
				resLaboratoriesD4?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setPharmaciesD4(
				resPharmaciesD4?.map((r) => ({
					value: r.PharmacyId,
					label: r.PharmacyName,
				}))
			);

			setGeneralInfoD4(res?.generalInfo);

			setPharmaciesTable(slicedPharmacyTable);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (display === CONSTANTS.DASHBOARD_SALES) fetch_sales_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedProvince,
		dateFrom,
		dateTo,
		display,
		selectedTypeOfSale,
	]);

	useEffect(() => {
		if (display === CONSTANTS.DASHBOARD_MOBILE) fetch_mobile_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedUserD3,
		selectedProvince,
		dateFromD3,
		dateToD3,
		display,
	]);

	useEffect(() => {
		if (display === CONSTANTS.DASHBOARD_PHARMACY) fetch_pharmacy_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedPharmacy,
		selectedPointOfSale,
		selectedProvince,
		dateFrom,
		dateTo,
		display,
	]);

	const handleBackToTheGuide = () => {
		updateStartGuideProgress(user.uid, 6);
		navigate("/startGuide");
	};

	const handleSelectDistributor = (e, o) => {
		setSelectedDistributor(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Distributor" },
		]);
	};

	const handleSelectProvince = (e, o) => {
		setSelectedProvince(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Province" },
		]);
	};

	const handleSelectedDate = (dateRange) => {
		const firstDate = moment(dateRange[0]).format("MM-DD-YYYY").toString();
		const lastDate = moment(dateRange[1]).format("MM-DD-YYYY").toString();
		setDateFrom(firstDate);
		setDateTo(lastDate);
		setRangeDate([dateRange[0], dateRange[1]]);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: "Fecha", key: "DateRange" },
		]);
	};

	const handleDeleteFilters = () => {
		setSelectedDistributor(null);
		setSelectedProvince(null);
		setSelectedLaboratory(null);
		setSelectedPharmacy(null);
		setSelectedUserD3(null);
		setDateFrom(null);
		setDateTo(null);
		setRangeDate(null);
		setSelectedFilters([]);
		setMobilePeriod(CONSTANTS.PERIOD_CURRENT);
		setDateFromD3(moment().startOf("month").format("MM-DD-YYYY").toString());
		setDateToD3(moment().endOf("month").format("MM-DD-YYYY").toString());
		setRangeDateD3(null);
		setSelectedTypeOfSale(null);
		onCloseFilterList();
	};

	const handleSelectSellerD3 = (e, o) => {
		setSelectedUserD3(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Seller" },
		]);
	};

	const handleSelectPharmacy = (e, o) => {
		setSelectedPharmacy(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Pharmacy" },
		]);
	};

	const handleSelectedDateD3 = (dateRange) => {
		const firstDate = moment(dateRange[0]).format("MM-DD-YYYY").toString();
		const lastDate = moment(dateRange[1]).format("MM-DD-YYYY").toString();
		setDateFromD3(firstDate);
		setDateToD3(lastDate);
		setDateFrom(firstDate);
		setDateTo(lastDate);
		setRangeDate([dateRange[0], dateRange[1]]);
	};

	const handleDistributorGraphSelect = (value) => {
		setDistributorGraphSelector(value);
	};

	const handleProductGraphSelectD2 = (value) => {
		setProductGraphSelectorD2(value);
	};

	const handlePeriodChange = (value) => {
		console.log(value);
		setMobilePeriod(value);
		if (value === CONSTANTS.PERIOD_CURRENT) {
			const startOfMonth = moment()
				.startOf("month")
				.format("MM-DD-YYYY")
				.toString();
			const endOfMonth = moment()
				.endOf("month")
				.format("MM-DD-YYYY")
				.toString();
			setDateFromD3(startOfMonth);
			setDateToD3(endOfMonth);
			setRangeDateD3([moment().startOf("month"), moment().endOf("month")]);
		}

		if (value === CONSTANTS.PERIOD_HISTORICAL) {
			setDateFromD3(dateFrom);
			setDateToD3(dateTo);
			setRangeDateD3(rangeDate);
		}
	};

	const handleMenuClick = ({ key }) => {
		setDisplay(key);
	};

	const handleVSGraphD2 = (value) => {
		setVSInfoSelector(value);
	};

	const handleTypeOfSaleD2 = (value, o) => {
		setSelectedTypeOfSale(value);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "TypeOfSale" },
		]);
	};

	const handleFilterRemove = (filterToRemove) => {
		setSelectedFilters((prevFilters) =>
			prevFilters.filter((filter) => filter !== filterToRemove)
		);
		//onCloseFilterList();

		switch (filterToRemove.key) {
			case "Distributor":
				setSelectedDistributor(null);
				break;
			case "Province":
				setSelectedProvince(null);
				break;
			case "Laboratory":
				setSelectedLaboratory(null);
				break;
			case "DateRange":
				setDateFrom(null);
				setDateTo(null);
				setRangeDate(null);
				break;
			case "Seller":
				setSelectedUserD3(null);
				break;
			case "Pharmacy":
				setSelectedPharmacy(null);
				break;
			case "TypeOfSale":
				setSelectedTypeOfSale(null);
				break;
			default:
				break;
		}
	};

	const showFilterList = (value) => {
		setOpenFilterList(value);
	};

	const onCloseFilterList = () => {
		setOpenFilterList(false);
	};

	const handleOrdersInfoSelect = (value) => {
		setOrdersInfoSelector(value);
	};

	const handleDistributorsPieInfoSelect = (value) => {
		setDistributorsPieInfoSelector(value);
	};

	const handleProductGraphSelectD3 = (value) => {
		setProductGraphSelectorD3(value);
	};

	return (
		<Layout style={{ minHeight: "100vh" }}>
			<Sider
				theme="light"
				trigger={null}
				collapsible
				collapsed={collapsed}
				// onMouseEnter={() => setCollapsed(false)}
				// onMouseLeave={() => setCollapsed(true)}
			>
				<div></div>
				<Menu
					theme="light"
					mode="inline"
					defaultSelectedKeys={[CONSTANTS.DASHBOARD_SALES]}
					style={{ paddingTop: 20 }}
					onClick={handleMenuClick}
				>
					<Tooltip
						placement="right"
						title={"Filtros aplicados"}
						trigger={selectedFilters.length > 0 ? "hover" : ""}
					>
						<FilterContainerButton
							key={CONSTANTS.DASHBOARD_FILTERS}
							onClick={
								selectedFilters.length > 0
									? (prev) => showFilterList((prev) => !prev)
									: null
							}
							conditionFullfilled={selectedFilters.length > 0}
							height={45}
						>
							{selectedFilters.length > 0 ? (
								<FaInfo color={COLORS.Default} size={16} />
							) : null}
						</FilterContainerButton>{" "}
					</Tooltip>
					<Menu.Item
						key={CONSTANTS.DASHBOARD_SALES}
						icon={<BarChartOutlined />}
						onClick={() => setDisplay(CONSTANTS.DASHBOARD_SALES)}
					>
						Ventas
					</Menu.Item>
					{clientUser?.client?.contract &&
						clientUser?.client?.contract?.activeMobileApp &&
						clientUser?.client?.contract?.mobileUsersZoneType ===
							CONSTANTS.USER_ZONE_TYPE_ROUTE && (
							<Menu.Item
								key={CONSTANTS.DASHBOARD_MOBILE}
								icon={<MobileOutlined />}
								onClick={() => setDisplay(CONSTANTS.DASHBOARD_MOBILE)}
							>
								Fuerza de venta
							</Menu.Item>
						)}
					<Menu.Item
						key={CONSTANTS.DASHBOARD_PHARMACY}
						icon={<TiPlusOutline />}
						onClick={() => setDisplay(CONSTANTS.DASHBOARD_PHARMACY)}
					>
						Farmacias
					</Menu.Item>
				</Menu>
				{selectedFilters.length > 0 && (
					<DrawerContainer
						title={
							<Typo type="primary" level={6}>
								Filtros aplicados
							</Typo>
						}
						placement="left"
						closable={false}
						onClose={onCloseFilterList}
						open={openFilterList}
						getContainer={false}
					>
						<div style={{ height: 170 }}>
							{selectedFilters.map((filter, index) => (
								<div
									key={index}
									onClick={() => handleFilterRemove(filter)}
									style={{
										cursor: "pointer",
										height: 30,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typo level={7} type="muted">
										{filter.label}
									</Typo>
									<CloseCircleOutlined style={{ color: COLORS.Danger }} />
								</div>
							))}
						</div>
						<div
							onClick={() => onCloseFilterList()}
							style={{
								position: "absolute",
								bottom: 5,
								display: "flex",
								justifyContent: "center",
								alignItems: "flex-end",
								width: "75%",
								cursor: "pointer",
							}}
						>
							<Typo type="muted" level={7}>
								Cerrar
							</Typo>
						</div>
					</DrawerContainer>
				)}
			</Sider>
			<Layout className="site-layout">
				<Inner>
					{userdata?.guideProgress === 5 && (
						<SignInTemplate.AntdButton
							type="primary"
							bg="Primary"
							color="White"
							icon={<QuestionCircleOutlined />}
							onClick={handleBackToTheGuide}
							style={{
								width: "250px",
								fontWeight: "400",
								marginTop: "40px",
								marginLeft: "20px",
							}}
						>
							Volver a la Guia de Inicio
						</SignInTemplate.AntdButton>
					)}
					{display === CONSTANTS.DASHBOARD_SALES &&
						(generalInfoD2 ? (
							<SaleDashboard
								dashboard={generalInfoD2}
								distributorList={distributorsD2}
								provinceList={provincesD2}
								optionsPie={pieDataD2}
								distributors={distributorsD2}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD2}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								isLoading={isLoading}
								timeSalesGraph={timeSalesGraphD2}
								timePharmacyOrdersGraph={timePharmacyOrdersGraphD2}
								timeOrderGraph={timeOrdersGraphD2}
								distributorSalesGraph={distributorSalesGraphD2}
								timeUnitsGraph={timeUnitsGraphD2}
								selectedLaboratory={selectedLaboratory}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								productSalesGraph={productSalesGraphD2}
								productUnitsGraph={productUnitsGraphD2}
								regionSalesGraph={regionSalesGraphD2}
								rangeDate={rangeDate}
								ordersInfoSelector={ordersInfoSelector}
								selectOrdersInfo={handleOrdersInfoSelect}
								distributorsInfoSelector={distributorsPieInfoSelector}
								selectDistributorsInfo={handleDistributorsPieInfoSelect}
								optionsPie2={secondPieDataD2}
								timeVSSalesGraph={timeVSSalesGraphD2}
								timeVSUnitsGraph={timeVSUnitsGraphD2}
								timeVSOrdersGraph={timeVSOrdersGraphD2}
								selectVSInfo={handleVSGraphD2}
								VSInfoSelector={VSInfoSelector}
								typeOfSales={typesOfSalesD2}
								selectedTypeOfSale={selectedTypeOfSale}
								handleTypeOfSale={handleTypeOfSaleD2}
								productGraphSelector={productGraphSelectorD2}
								selectProductGraph={handleProductGraphSelectD2}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{display === CONSTANTS.DASHBOARD_MOBILE &&
						(generalInfoD3 ? (
							<MobileDashboard
								dashboard={generalInfoD3}
								distributorList={distributorsD3}
								provinceList={provincesD3}
								optionsPie={pieDataD3}
								distributors={distributorsD3}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD3}
								selectProvince={handleSelectProvince}
								laboratories={laboratoriesD3}
								sellers={usersD3}
								selectSeller={handleSelectSellerD3}
								handleDateChange={handleSelectedDateD3}
								deleteFilters={handleDeleteFilters}
								isLoading={isLoading}
								posOrderingGraph={timePharmacyOrdersGraphD3}
								sellersTable={sellerSalesTableD3}
								timeOrdersGraph={timeOrdersGraphD3}
								distributorSalesGraph={distributorSalesGraphD3}
								distributorUnitsGraph={distributorUnitsGraphD3}
								distributorOrdersGraph={distributorOrdersGraphD3}
								selectedSeller={selectedUserD3}
								distributorGraphSelector={distributorGraphSelector}
								selectDistributorGraph={handleDistributorGraphSelect}
								selectPeriod={handlePeriodChange}
								rangeDate={rangeDateD3}
								mobilePeriod={mobilePeriod}
								productSalesGraph={productSalesGraphD3}
								productUnitsGraph={productUnitsGraphD3}
								productGraphSelector={productGraphSelectorD3}
								selectProductGraph={handleProductGraphSelectD3}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{display === CONSTANTS.DASHBOARD_PHARMACY &&
						(generalInfoD4 ? (
							<PharmacyDashboard
								dashboard={generalInfoD4}
								provinceList={provincesD4}
								pharmacies={pharmaciesD4}
								selectPharmacy={handleSelectPharmacy}
								distributors={distributorsD4}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD4}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								laboratories={laboratoriesD4}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								selectedLaboratory={selectedLaboratory}
								selectedPharmacy={selectedPharmacy}
								isLoading={isLoading}
								pharmaciesTable={pharmaciesTable}
								rangeDate={rangeDate}
								hasMobileDashboard={
									clientUser?.client?.contract &&
									clientUser?.client?.contract?.activeMobileApp &&
									clientUser?.client?.contract?.mobileUsersZoneType ===
										CONSTANTS.USER_ZONE_TYPE_ROUTE
								}
							/>
						) : (
							<LoadingSkeleton />
						))}
				</Inner>
			</Layout>
		</Layout>
	);
}
