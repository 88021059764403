function compareWithToday(date) {
	const today = new Date();
	const dateOffer = new Date(date);
	return today.getTime() < dateOffer.getTime();
}

function compareBeforeToday(date) {
	const today = new Date();
	const dateOffer = new Date(date);
	return today.getTime() > dateOffer.getTime();
}

export { compareWithToday, compareBeforeToday };
