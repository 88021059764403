import styled from "styled-components";
import { COLORS } from "../../../../../../layout/theme";
import { Table } from "antd";

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const AntdTable = styled(Table)`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

  & .noBackground, & .noBackground:hover {
    background-color: ${COLORS.LightGrey} !important;
    border: none;
  }
	}
`;
