import styled from "styled-components";
import { COLORS } from "../../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const Inner = styled.div`
	display: flex;
	gap: 20px;
`;

export const DataContainer = styled.div`
	display: flex;
	gap: 10px;
	background-color: ${COLORS.White};
	width: 100%;
	padding: 50px;
	border-radius: 10px;
`;
