import styled from "styled-components/macro";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
  display: flex;
`;
export const ProductData = styled.div`
  flex: 1 1 60%;
  width: 950px;
`;

export const TableHeader = styled.div`
  width: 630px;
  height: 70px;
  background-color: ${COLORS.SkeletonGrey};
`;
