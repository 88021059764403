import styled from "styled-components/macro";

export const FilterViewContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
	margin-right: 7px;
	gap: 5px;
	text-align: end;

	@media only screen and (min-width: 1339px) and (max-width: 1414px) {
		margin-right: 14px;
	}
`;
