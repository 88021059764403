import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link } from "react-router-dom";
import {
	ChildrenContainer,
	SelectContainer,
	Section,
	Container,
} from "./styles";
import { Select } from "antd";
import { Typo } from "../../../layout/theme";
import {
	get_client_by_id,
	get_clients_users,
} from "../../../../api/endpoints/clients";
import AntdSelect from "../../../common/antd-select/AntdSelect";
import UserDiscountList from "./list/UserDiscountList";

export default function WebSellersDiscountsPage({ clientId }) {
	const [clientType, setClientType] = useState("LABORATORIO");
	const [data, setData] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [loading, setLoading] = useState(false);
	const [clientOptions, setClientOptions] = useState(null);
	const [webSellerUsers, setWebSellerUsers] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [userOptions, setUserOptions] = useState(null);
	useEffect(() => {
		const fetch_clients = async () => {
			const res = await get_client_by_id(clientId);
			setSelectedClient(res.client);
			setWebSellerUsers(
				res.client?.users
					.filter((u) => !u.p_mobile && u.p_shop)
					.sort((a, b) => a.firstName - b.firstName)
			);
			setUserOptions(
				res.client?.users
					.filter((u) => !u.p_mobile && u.p_shop)
					.sort((a, b) => a.firstName - b.firstName)
					.map((r) => ({
						value: r.id,
						label: r.firstName + " " + r.lastName,
					}))
			);
		};
		setLoading(true);
		fetch_clients();
		setLoading(false);
	}, []);

	const handleUserSelect = (value) => {
		setSelectedUser(webSellerUsers.filter((u) => u.id === value));
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Descuentos por Usuario
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<SelectContainer style={{ flexBasis: 0 }}>
					<Container>
						<Section>
							{selectedClient && (
								<Typo type="primary" level={6}>
									Usuario
								</Typo>
							)}
							{selectedClient && (
								<Select
									showSearch
									placeholder="Elegi un usuario"
									optionFilterProp="children"
									onChange={handleUserSelect}
									value={
										selectedUser
											? selectedUser[0].firstName +
											  " " +
											  selectedUser[0].lastName
											: "..."
									}
									filterOption={(input, option) =>
										(option?.label ?? "")
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									options={userOptions}
									style={{ width: 180 }}
								/>
							)}
						</Section>
					</Container>
				</SelectContainer>
				{selectedUser && (
					<ChildrenContainer>
						<UserDiscountList
							clientId={selectedClient.id}
							client={selectedClient}
							userId={selectedUser[0].id}
							user={selectedUser[0]}
							userType="WEBSELLER"
						/>
					</ChildrenContainer>
				)}
			</Main.Body>
		</Main>
	);
}
