import React, { useEffect, useState } from "react";
import {
	AntdButton,
	AntdSteps,
	IconContainer,
	Inner,
	Section,
	StepContainer,
	StepsContainer,
	TitleContainer,
} from "./styles";
import { useCart } from "../../../../contexts/cartContext";
import { useAuth } from "../../../../contexts/authContext";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import PharmacySelectionStep from "./components/pharmacy-selection-step/PharmacySelectionStep";
import ProductsToCartStep from "./components/products-to-cart-step/ProductsToCartStep";
import PharmacyCreateStep from "./components/pharmacy-create-step/PharmacyCreateStep";
import { Steps } from "antd";
import Number1 from "../../../../assets/images/number-one-icon.svg";
import Number1Filled from "../../../../assets/images/number-one-solid.svg";
import Number2Grey from "../../../../assets/images/number-two.svg";
import Number2Filled from "../../../../assets/images/number-two-solid.svg";
import StepPharmacyIcon from "../../../../assets/images/house-chimney-medical-solid.svg";
import StepProductTableIcon from "../../../../assets/images/table-list-solid.svg";
import StepPharmacyIconSelected from "../../../../assets/images/house-chimney-medical-solid-selected.svg";
import StepProductTableIconSelected from "../../../../assets/images/table-list-solid-selected.svg";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../../../utils/constants";
import { get_laboratory_products_dapper } from "../../../../api/endpoints/products";
import PharmacyUpdateStep from "./components/pharmacy-update-step/PharmacyUpdateStep";

const { Step } = Steps;

export default function SellerWeb({ clientId }) {
	const { user } = useAuth();
	const {
		addProductsToCart,
		emptyCart,
		marketSellerClient,
		selectedClient,
		setSelectedClient,
		qty,
		templateProducts,
		setTemplateProducts,
		webSellerSteps,
		setWebSellerSteps,
		webSellerClientInfo,
		setWebSellerClientInfo,
		webSellerProducts,
		setWebSellerProducts,
	} = useCart();
	const navigate = useNavigate();
	const [clients, setClients] = useState(null);
	const [skip, setSkip] = useState(1);
	const [page, setPage] = useState(CONSTANTS.PAGE_SIZE_ALL);
	const [loading, setLoading] = useState(false);

	const fetch_products = async (search = "") => {
		setLoading(true);
		try {
			const res = await get_laboratory_products_dapper(
				search,
				clientId,
				user.uid,
				skip,
				page
			);
			setTemplateProducts(
				res.items.map((r) => ({
					...r,
					quantity: 0,
					key: r.id,
					hasChanges: false,
				}))
			);
			setWebSellerProducts(
				res.items.map((r) => ({
					...r,
					quantity: 0,
					key: r.id,
					hasChanges: false,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleOrderToCart = async () => {
		try {
			const _cartOrder = {
				prods: templateProducts
					.filter((i) => i.quantity > 0)
					.map((i) => ({
						id: i.id,
						quantity: i.quantity,
					})),
				origin: CONSTANTS.PRODUCTS_ORIGIN_WEB_SELLER,
				originId: user.uid,
			};

			await addProductsToCart(_cartOrder);

			setTemplateProducts(webSellerProducts);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Pedido agregado al carro de compras"
			);

			window.scrollTo(0, 0);

			navigate("/cart");
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al enviar pedido al carro de compras",
				"Un error ocurrio al intentar avanzar con el pedido, contacte con soporte a soporte@onetransfer.com."
			);
		}
	};

	useEffect(() => {
		fetch_products();
	}, []);

	const handleBack = () => {
		setWebSellerSteps(0);
		setTemplateProducts(webSellerProducts);
	};

	const handleBackToDistributorStep = () => {
		setWebSellerSteps(2);
	};

	return (
		<Inner>
			<StepsContainer>
				<AntdSteps current={webSellerSteps} onChange={setWebSellerSteps}>
					<Step
						icon={
							<IconContainer
								src={webSellerSteps === 0 ? Number1Filled : Number1}
								alt="Number1"
								style={{ width: 28 }}
							/>
						}
						title={
							<StepContainer>
								<IconContainer
									src={
										webSellerSteps === 0
											? StepPharmacyIconSelected
											: StepPharmacyIcon
									}
									alt="Step 1"
									style={{ width: 19 }}
								/>
								<TitleContainer>Punto de Venta</TitleContainer>
							</StepContainer>
						}
					/>
					<Step
						icon={
							<IconContainer
								src={webSellerSteps === 3 ? Number2Filled : Number2Grey}
								alt="Number2"
								style={{ width: 28 }}
							/>
						}
						title={
							<StepContainer>
								<IconContainer
									src={
										webSellerSteps === 3
											? StepProductTableIconSelected
											: StepProductTableIcon
									}
									alt="Step 3"
									style={{ width: 19 }}
								/>
								<TitleContainer>Productos</TitleContainer>
							</StepContainer>
						}
					/>
				</AntdSteps>
			</StepsContainer>
			{webSellerSteps === 0 && (
				<PharmacySelectionStep
					clientId={clientId}
					clients={clients}
					setClients={setClients}
					selectedClient={selectedClient}
					setSelectedClient={setSelectedClient}
					setClientInfo={setWebSellerClientInfo}
					marketSellerClient={marketSellerClient}
					setStep={setWebSellerSteps}
					emptyCart={emptyCart}
					qty={qty}
				/>
			)}
			{webSellerSteps === 1 && (
				<PharmacyCreateStep
					laboratoryId={clientId}
					pharmacyInfo={webSellerClientInfo}
					setPharmacyInfo={setWebSellerClientInfo}
					setStep={setWebSellerSteps}
					setClientInfo={setWebSellerClientInfo}
					setSelectedClient={setSelectedClient}
					marketSellerClient={marketSellerClient}
				/>
			)}
			{webSellerSteps === 2 && (
				<PharmacyUpdateStep
					pharmacyInfo={webSellerClientInfo}
					setStep={setWebSellerSteps}
					selectedClient={selectedClient}
					handleBack={handleBack}
				/>
			)}
			{webSellerSteps === 3 && selectedClient && (
				<ProductsToCartStep
					clientInfo={webSellerClientInfo}
					templateProducts={templateProducts}
					setTemplateProducts={setTemplateProducts}
					user={user}
					selectedClient={selectedClient}
					clientId={clientId}
					step={webSellerSteps}
					handleOrderToCart={handleOrderToCart}
					handleBack={handleBack}
					handleBackToDistributorStep={handleBackToDistributorStep}
				/>
			)}
			{webSellerSteps === 3 &&
				templateProducts &&
				templateProducts.length > 0 && (
					<Section onClick={handleOrderToCart} style={{ alignItems: "center" }}>
						<AntdButton>Enviar pedido al carro</AntdButton>
					</Section>
				)}
		</Inner>
	);
}
