import { Skeleton, Row, Col, Space } from "antd";
import { Inner, CategoryData, TableHeader } from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
  return (
    <Inner>
      <CategoryData>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row gutter={GUTTER}>
            <Col span={24}>
              <TableHeader></TableHeader>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 600, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 300, marginTop: -10, marginLeft: -10 }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={4}>
              <Skeleton
                active={true}
                paragraph={{ rows: 0 }}
                style={{ width: 500, marginTop: -10, marginLeft: 10 }}
              />
            </Col>
            <Col span={4}></Col>
          </Row>
        </Space>
      </CategoryData>
    </Inner>
  );
}
