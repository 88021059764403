import styled from "styled-components";
import { COLORS } from "../../../../layout/theme";

export const Inner = styled.div`
	min-height: 80vh;
`;

export const Section = styled.div`
	display: flex;
	padding: 2rem;
	background-color: ${COLORS.White};
	border-radius: 10px;
	gap: 30px;
`;

export const StyledOl = styled.ol`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const StyledLi = styled.li`
	color: ${COLORS.Grey};
`;

export const Container = styled.div`
	display: flex;
	flex: 1 1 50%;
	flex-direction: column;
	justify-content: flex-start;
	gap: 30px;
`;

export const GraphContainer = styled.div`
	display: flex;
	flex: 0 0 50%;
	align-items: center;
	justify-content: flex-start;
`;
