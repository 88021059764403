import { Input, Select } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES } from "../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	overflow: hidden;
`;

export const Container = styled.div`
	display: flex;
	gap: 20px;
`;

export const OptionSection = styled.div`
	display: flex;
	flex: 0 0 100%;

	span {
		max-width: 620px;
		height: 100%;
	}
`;

export const ProductSelect = styled(Select)`
  margin-bottom: 20px;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 2px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &..ant-select-arrow {
      
    }
  

  &.ant-select-selector{
    .ant-select-selection-item {
      font-size: ${SIZES.level7};
      color: ${COLORS.Primary};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${COLORS.Primary};
      }
    }
  }
`;

export const Section = styled.div`
	margin: 50px 0 130px 0;
`;

export const LaboratoryBoxContainer = styled.div`
	position: absolute;
	display: block;
	left: 70px;
	max-height: 1250px;
	margin: 0;
	overflow-y: auto;
	overflow-x: hidden;
	transform: rotate(-90deg) translateY(-80px);
	transform-origin: right top;
	direction: rtl;

	div {
		transform: rotate(90deg);
		width: 100px;
		height: 100px;
		margin: 10px;
		color: ${COLORS.Secondary};
	}

	img {
		transform: rotate(180deg);
	}

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px #e3e3e3;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background: ${COLORS.Grey};
		border-radius: 10px;
	}
`;

export const RoundSearch = styled(Input)`
	width: 450px;
	border-radius: 20px;
`;

export const CompaginationDiv = styled.div`
	display: flex;
	gap: 4px;
`;

export const SearchContainer = styled.div`
	flex: 1 1 40%;
	align-items: center;
	align-content: center;
	justify-content: center;
	display: flex;
	align-content: space-evenly;
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
`;
