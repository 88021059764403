import axiosInstance from "../axiosInstance";

const CONTROLLER = "ProductUnits";

const get_units = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}`
	);
	return response.data;
};

export { get_units };
