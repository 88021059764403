import { Skeleton, Row, Col, Space } from "antd";
import {
  Container,
  Inner,
  ProductData,
  PublishContainer,
  PublishSettings,
} from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
  return (
    <Inner>
      <ProductData>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row></Row>
          <Row gutter={GUTTER}>
            <Col span={4}>
              <Skeleton.Input active={true} block />
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={8}>
              <Skeleton.Image active={true} block />
            </Col>
          </Row>
          <Row></Row>
          <Row gutter={GUTTER}>
            <Col span={20}>
              <Skeleton.Input active={true} block />
            </Col>
          </Row>
          <Row></Row>
          <Row gutter={GUTTER}>
            <Col span={20}>
              <Skeleton.Input active={true} block />
            </Col>
          </Row>
          <Row></Row>
          <Row gutter={GUTTER}>
            <Col span={20}>
              <Skeleton.Input active={true} block />
            </Col>
          </Row>
          <Row></Row>
          <Row gutter={GUTTER}>
            <Col span={20}>
              <Skeleton.Input active={true} block />
            </Col>
          </Row>
          <Row></Row>
          <Row gutter={GUTTER}>
            <Col span={10}>
              <Skeleton.Input active={true} block />
            </Col>
            <Col span={10}>
              <Skeleton.Input active={true} block />
            </Col>
          </Row>
          <Row></Row>
          <Row gutter={GUTTER}>
            <Col span={10}>
              <Skeleton.Input active={true} block />
            </Col>
            <Col span={10}>
              <Skeleton.Input active={true} block />
            </Col>
          </Row>
          <Row></Row>
          <Row gutter={GUTTER}>
            <Col span={10}>
              <Skeleton.Input active={true} block />
            </Col>
            <Col span={10}>
              <Skeleton.Input active={true} block />
            </Col>
          </Row>
          <Row></Row>
          <Skeleton
            active
            size="default"
            paragraph={{
              rows: 10,
            }}
            style={{ width: "83%" }}
          />
        </Space>
      </ProductData>

      <PublishSettings>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <PublishContainer>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={10}>
                <Skeleton.Input
                  active={true}
                  size="small"
                  style={{
                    marginTop: "20px",
                    width: "300px",
                    marginLeft: "20px",
                  }}
                />
              </Col>
            </Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={10}>
                <Skeleton.Input
                  active={true}
                  size="large"
                  style={{
                    marginTop: "40px",
                    width: "300px",
                    marginLeft: "20px",
                  }}
                />
              </Col>
            </Row>
          </PublishContainer>
        </Space>
      </PublishSettings>
    </Inner>
  );
}
