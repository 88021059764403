import React, { useState, useEffect } from "react";
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { Form, Input, Row, Col, Select, Modal, Checkbox } from "antd";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import {
	create_point_of_sale,
	get_client_pointOfSale_dapper,
} from "../../../../../api/endpoints/pointsofsale";
import Main from "../../../../layout/main/Main";
import PhoneInput from "../../../../common/phone-input/PhoneInput";
import { get_provinces } from "../../../../../api/endpoints/region";
import DistributorsFiltered from "../../../../common/distributors-code-filtered/DistributorsFiltered";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { useAuth } from "../../../../../contexts/authContext";
import { update_client_distributors } from "../../../../../api/endpoints/clients";
import {
	AntdModal,
	BillerAndCodeContainer,
	BillerCode,
	BillerContainer,
	BillerSelection,
	Container,
	DistributorBiller,
	DistributorContainer,
	DistributorText,
	Inner,
} from "./styles";
import { CloseCircleOutlined } from "@ant-design/icons";
import { COLORS, Typo } from "../../../../layout/theme";
import { get_billers } from "../../../../../api/endpoints/billers";
import { AiFillPlusCircle } from "react-icons/ai";
import DistributorModal from "../components/distributors-modal/DIstributorModal";

const GUTTER = 10;

export default function CreatePointOfSale({ clientUser }) {
	const { Option } = Select;
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const { user, userdata, updateStartGuideProgress } = useAuth();

	const [submitting, setSubmitting] = useState(false);
	const [distributors, setDistributors] = useState(null);
	const [idProvincia, setIdProvincia] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [listadoLocalidades, setListadoLocalidades] = useState(null);
	const [provincia, setProvincia] = useState(null);
	const [localidad, setLocalidad] = useState();
	const [initialValues, setInitialValues] = useState(null);
	const [billers, setBillers] = useState(null);
	const [billerSelected, setBillerSelected] = useState(null);
	const [showBillers, setShowBillers] = useState(false);
	const [conditionToCreate, setConditionToCreate] = useState(null);
	const [showDistributorsModal, setShowDistributorsModal] = useState(false);
	const [flag, setFlag] = useState(false);
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	useEffect(() => {
		const fetch_client = async () => {
			const res = await get_client_pointOfSale_dapper();

			const formData = {
				name: res?.client?.name,
				address: res?.client?.address,
				postalCode: res?.client?.postalCode,
				contactName: res?.client?.contactName,
				contactEmail: res?.client?.contactEmail,
				phone: res?.client?.contactPhone.slice(
					3,
					res?.client?.contactPhone.length
				),
				phoneAreaCode: res?.client?.contactPhone.slice(0, 2),
				cuit: res?.client?.cuit,
				businessName: res?.client?.businessName,
			};

			setConditionToCreate(
				res?.client?.isChain ? true : res?.totalActivePointsOfSale === 0
			);

			setInitialValues(formData);
		};

		const fetch_billers = async () => {
			const res = await get_billers();
			setBillers(res);
		};

		fetch_client();
		fetch_billers();
	}, []);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res
					.map((p) => ({
						id: p.id,
						name: p.title,
						localities: p.localities.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						})),
					}))
					.sort((a, b) => {
						const titleA = a.name.toLowerCase();
						const titleB = b.name.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);

			setListadoLocalidades(res.filter((p) => p.id === idProvincia));
		};

		fetch_region_1_data();
	}, [idProvincia]);

	const onFinish = async (values) => {
		try {
			if (!provincia || !localidad) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe seleccionar una Provincia y una Localidad"
				);
				return;
			}

			const _aux = distributors.filter((i) => i.code === "" || i.code === "si");

			if (distributors.length - _aux.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe colocar al menos un codigo de Drogueria"
				);
				return;
			}

			if (!conditionToCreate) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"No puede tener más de un punto de venta activo"
				);
				return;
			}

			setSubmitting(true);

			const postData = {
				name: values.name,
				address: values.address,
				postalCode: values.postalCode,
				contactName: values.contactName,
				contactEmail: values.contactEmail,
				contactPhone: `${values.phoneAreaCode}${values.phone}`,
				cuit: values.cuit,
				businessName: values.businessName,
				distributors: distributors?.map((d) => ({
					id: d.id,
					code: d.code,
				})),
				localities: localidad ? [{ localityId: localidad.value }] : [],
				pointOfSaleRegion: `ARG-${idProvincia}-${localidad.value}`,
				billerId: values.biller && values.billerCode ? values.biller : null,
				billerCode:
					values.biller && values.billerCode ? values.billerCode : null,
			};

			const res = await create_point_of_sale(postData);

			if (uri) {
				window.scrollTo(0, 0);
				navigate(`/${uri}`);
			} else {
				if (userdata?.guideProgress === 0 || userdata?.guideProgress === 1) {
					updateStartGuideProgress(user.uid, 2);
					navigate("/startGuide");
				} else {
					navigate(`/admin/pointsofsale/`);
				}
			}

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Punto de venta creado con éxito",
				"El punto de venta se creó con éxito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear el punto de venta",
				"Un error ocurrio al intentar crear el punto de venta, contacte con soporte a soporte@onetransfer.com."
			);
			console.log(error);
		} finally {
			setSubmitting(false);
		}
	};

	const handleProvinciasSelect = (value, object) => {
		setProvincia(object);
		setIdProvincia(value);
	};

	const handleLocalidadSelect = (value, objectList) => {
		setLocalidad(objectList);
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido completados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/pointsofsale/");
			},
		});
	};

	const handleBillerChange = (b) => {
		setBillerSelected(b);
	};

	const handleUseBiller = (e) => {
		setShowBillers(e.target.checked);
	};

	const handleClick = () => {
		setShowDistributorsModal(true);
	};

	const handleOk = async () => {
		const newDistributor = distributors.map((d) =>
			d.checked === true ? { ...d, code: "si" } : { ...d, code: null }
		);
		const data = {
			distributors: newDistributor.map((d) => ({
				id: d.id,
				code: d.code,
				selectedMail: d.selectedMail,
			})),
		};
		await update_client_distributors(data);
		setFlag(!flag);
		setShowDistributorsModal(false);
	};

	const handleCancel = () => {
		setShowDistributorsModal(false);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/pointsofsale">Puntos de venta</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Crear Punto de venta
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				{!initialValues ? (
					<div>loading...</div>
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<Inner>
							<Container>
								<Row gutter={GUTTER}>
									<Col span={12}>
										<Form.Item
											label="Nombre del punto de venta"
											name="name"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(
														/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
													),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item
											label="Dirección"
											name="address"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item
											label="Código Postal"
											name="postalCode"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Input type="number" />
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={GUTTER}>
									<Col span={6}>
										<Form.Item
											label="Provincia"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											{localidad !== undefined ? (
												<Select disabled>
													<Select.Option
														key={provincia?.value}
														value={provincia?.value}
													>
														{provincia?.children}
													</Select.Option>
												</Select>
											) : (
												<Select onChange={handleProvinciasSelect}>
													{listadoProvincias
														?.sort((a, b) => {
															const titleA = a.name.toLowerCase();
															const titleB = b.name.toLowerCase();
															if (titleA < titleB) {
																return -1;
															}
															if (titleA > titleB) {
																return 1;
															}

															return 0;
														})
														.map((p) => (
															<Select.Option key={p.id} value={p.id}>
																{p.name}
															</Select.Option>
														))}
												</Select>
											)}
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item
											label={provincia ? "Localidad" : ""}
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											{provincia ? (
												<Select
													allowClear={true}
													placeholder="Elegir Localidad"
													onChange={handleLocalidadSelect}
												>
													{listadoLocalidades[0]?.localities
														.sort((a, b) => {
															const titleA = a.locality.title.toLowerCase();
															const titleB = b.locality.title.toLowerCase();
															if (titleA < titleB) {
																return -1;
															}
															if (titleA > titleB) {
																return 1;
															}

															return 0;
														})
														.map((p) => (
															<Select.Option
																key={p.locality.id}
																value={p.locality.id}
															>
																{p.locality.title}
															</Select.Option>
														))}
												</Select>
											) : (
												""
											)}
										</Form.Item>
									</Col>
									<Col span={12}>
										<PhoneInput
											form={form}
											size="default"
											label="Teléfono de contacto"
										/>
									</Col>
								</Row>

								<Row gutter={GUTTER}>
									<Col span={12}>
										<Form.Item
											label="Nombre de contacto"
											name="contactName"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label="Email de contacto"
											name="contactEmail"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={GUTTER}>
									<Col span={12}>
										<Form.Item
											label="Razón Social"
											name="businessName"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input disabled={!clientUser?.client?.isChain} />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label="CUIT"
											name="cuit"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													len: 11,
													message: "El cuit debe de ser de 11 digitos",
												},
												{
													pattern: new RegExp(/^[0-9\s]*$/),
													message: "Solo numeros positivos",
												},
											]}
										>
											<Input disabled={!clientUser?.client?.isChain} />
										</Form.Item>
									</Col>
								</Row>
							</Container>
							<DistributorBiller>
								<DistributorContainer>
									<DistributorText
										onClick={handleClick}
										textColor={1}
										marginBottom={5}
									>
										<AiFillPlusCircle /> Agregar/Quitar Droguería
									</DistributorText>
									<Form.Item label="Listado de droguerías con codigo">
										<DistributorsFiltered
											onChange={setDistributors}
											flag={flag}
										/>
									</Form.Item>
								</DistributorContainer>
								<AntdModal
									title={`Droguerías`}
									visible={showDistributorsModal}
									onOk={handleOk}
									onCancel={handleCancel}
									width={900}
									centered={true}
									destroyOnClose={false}
								>
									<DistributorModal
										setDistributors={setDistributors}
										distributors={distributors}
										isSeller={userdata.isSeller}
										isOneMarket={userdata.isOneMarket}
									/>
								</AntdModal>
								{/* {billers?.length > 0 && (
									<BillerContainer>
										<Form.Item label="Asociar Facturador">
											<Checkbox onClick={(e) => handleUseBiller(e)}>
												Asociar Facturador{" "}
											</Checkbox>
										</Form.Item>
										{showBillers && (
											<BillerAndCodeContainer>
												<BillerSelection>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Facturador
															</label>
														}
														name="biller"
													>
														<Select
															allowClear={true}
															placeholder="Contenido del Envase"
															onChange={handleBillerChange}
														>
															{billers.map((item) => (
																<Option key={item.id} value={item.id}>
																	{item.title}
																</Option>
															))}
														</Select>
													</Form.Item>
												</BillerSelection>
												<BillerCode>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Código cliente
															</label>
														}
														name="billerCode"
													>
														<Input placeholder="Codigo de cliente" />
													</Form.Item>
												</BillerCode>
											</BillerAndCodeContainer>
										)}
									</BillerContainer>
								)} */}
							</DistributorBiller>
							<Row>
								<Col span={4}>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
										>
											Crear punto de venta
										</SignInTemplate.AntdButton>
									</Form.Item>
								</Col>
								<Col span={4}>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											loading={submitting}
											onClick={handleBack}
										>
											<Typo type="danger" level={6}>
												Volver sin guardar
											</Typo>
										</SignInTemplate.AntdButton>
									</Form.Item>
								</Col>
							</Row>
						</Inner>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
