import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Select } from "antd";
import PhoneInput from "../../../../../../common/phone-input/PhoneInput";
import { get_provinces } from "../../../../../../../api/endpoints/region";
import { COLORS } from "../../../../../../layout/theme";
import { Inner } from "./styles";
import DistributorsFilteredByClient from "../../../../../../common/distributor-code-by-client/DistributorsFilteredByClient";

const GUTTER = 20;

export default function PointOfSaleCard({
	form,
	id,
	distributors,
	setDistributors,
	idProvincia,
	setIdProvincia,
	provincia,
	setProvincia,
	localidad,
	setLocalidad,
}) {
	const formValues = Form.useWatch([], form);

	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [listadoLocalidades, setListadoLocalidades] = useState(null);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res
					.map((p) => ({
						id: p.id,
						name: p.title,
						localities: p.localities.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						})),
					}))
					.sort((a, b) => {
						const titleA = a.name.toLowerCase();
						const titleB = b.name.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);

			setListadoLocalidades(res.filter((p) => p.id === idProvincia));
		};

		fetch_region_1_data();
	}, [idProvincia]);

	const handleProvinciasSelect = (value, object) => {
		setProvincia(object);
		setIdProvincia(value);
	};

	const handleLocalidadSelect = (value, objectList) => {
		setLocalidad([
			{
				key: objectList.key,
				value: objectList.value,
				children: objectList.children,
			},
		]);
	};

	return (
		<Inner>
			<Row gutter={GUTTER}>
				<Col span={12}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Nombre del punto de venta
							</label>
						}
						name={["pointOfSale", "name"]}
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
							{
								pattern: new RegExp(
									/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
								),
								message:
									"No se aceptan caracteres especiales. Solo letras y numeros",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Dirección
							</label>
						}
						name={["pointOfSale", "address"]}
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
							{
								pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s.-]*$/),
								message:
									"No se aceptan caracteres especiales. Solo letras y numeros",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Código Postal
							</label>
						}
						name={["pointOfSale", "postalCode"]}
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={GUTTER}>
				<Col span={8}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Provincia
							</label>
						}
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
						]}
					>
						{localidad !== undefined ? (
							<Select style={{ width: 250 }} value={provincia} disabled>
								<Select.Option key={provincia?.value} value={provincia?.value}>
									{provincia?.children}
								</Select.Option>
							</Select>
						) : (
							<Select
								style={{ width: 250 }}
								onChange={handleProvinciasSelect}
								value={provincia}
							>
								{listadoProvincias
									?.sort((a, b) => {
										const titleA = a.name.toLowerCase();
										const titleB = b.name.toLowerCase();
										if (titleA < titleB) {
											return -1;
										}
										if (titleA > titleB) {
											return 1;
										}

										return 0;
									})
									.map((p) => (
										<Select.Option key={p.id} value={p.id}>
											{p.name}
										</Select.Option>
									))}
							</Select>
						)}
					</Form.Item>
				</Col>
				<Col span={4}></Col>
				<Col span={8}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								{provincia ? "Localidad" : ""}
							</label>
						}
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
						]}
					>
						{provincia && listadoLocalidades ? (
							<Select
								allowClear={true}
								style={{ width: 250 }}
								placeholder="Elegir Localidad"
								onClear={() => setLocalidad(undefined)}
								onChange={handleLocalidadSelect}
								value={localidad}
							>
								{idProvincia &&
									listadoLocalidades[0]?.localities
										.sort((a, b) => {
											const titleA = a.locality.title.toLowerCase();
											const titleB = b.locality.title.toLowerCase();
											if (titleA < titleB) {
												return -1;
											}
											if (titleA > titleB) {
												return 1;
											}

											return 0;
										})
										.map((p) => (
											<Select.Option key={p.locality.id} value={p.locality.id}>
												{p.locality.title}
											</Select.Option>
										))}
							</Select>
						) : (
							""
						)}
					</Form.Item>
				</Col>
				<Col span={4}></Col>
			</Row>

			<Row gutter={GUTTER}>
				<Col span={12}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Nombre de contacto
							</label>
						}
						name={["pointOfSale", "contactName"]}
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
							{
								pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
								message:
									"No se aceptan caracteres especiales. Solo letras y numeros",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Email de contacto
							</label>
						}
						name={["pointOfSale", "contactEmail"]}
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={GUTTER}>
				<Col span={12}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Razón social
							</label>
						}
						name={["pointOfSale", "businessName"]}
						rules={[
							{ required: true, message: "Este campo es obligatorio" },
							{
								pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
								message:
									"No se aceptan caracteres especiales. Solo letras y numeros",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Número de CUIT
							</label>
						}
						name={["pointOfSale", "cuit"]}
						rules={[
							{ required: true, message: "Este campo es obligatorio" },
							{
								len: 11,
								message: "El cuit debe de ser de 11 digitos",
							},
							{
								pattern: new RegExp(/^[0-9\s]*$/),
								message: "Solo numeros positivos",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col span={12}>
					<PhoneInput
						form={form}
						size="default"
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Teléfono de contacto
							</label>
						}
					/>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Listado de droguerías con codigo
							</label>
						}
					>
						<DistributorsFilteredByClient
							initialValues={distributors}
							onChange={setDistributors}
							clientId={id}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Inner>
	);
}
