import React from "react";
import {
	Inner,
	TopContainer,
	Divider,
	InfoTitle,
	InfoTitleTemp,
	TopContainerTemp,
} from "./styles";

const PublicitySlide = ({ children, ...restProps }) => (
	<Inner {...restProps}>{children}</Inner>
);

export default PublicitySlide;

PublicitySlide.TopContainer = ({ children, ...restProps }) => {
	return <TopContainer {...restProps}>{children}</TopContainer>;
};

PublicitySlide.Divider = ({ children, ...restProps }) => {
	return <Divider {...restProps}>{children}</Divider>;
};

PublicitySlide.InfoTitle = ({ children, ...restProps }) => {
	return <InfoTitle {...restProps}>{children}</InfoTitle>;
};

PublicitySlide.InfoTitleTemp = ({ children, ...restProps }) => {
	return <InfoTitleTemp {...restProps}>{children}</InfoTitleTemp>;
};
PublicitySlide.TopContainerTemp = ({ children, ...restProps }) => {
	return <TopContainerTemp {...restProps}>{children}</TopContainerTemp>;
};
