import { Table } from "antd";
import styled from "styled-components/macro";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	overflow: auto;
	align-content: center;
	align-items: flex-start;
	justify-content: center;

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.Grey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}
`;

export const Container = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	gap: 20px;
`;

export const ViewTable = styled(Table)`
	width: 100%;
	height: 100%;
	overflow: auto;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
`;

export const Title = styled.div`
	color: ${COLORS.Grey};
	font-weight: 600;
	font-size: 14px;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
`;
