import { Input } from "antd";
import styled from "styled-components";
import { COLORS } from "../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;

	& input {
		text-align: center;
	}
`;

export const AntdInput = styled(Input)`
	color: ${(props) => (props.hasChanges ? COLORS.Primary : COLORS.Grey)};
	border: ${(props) =>
		props.hasChanges
			? `2px solid ${COLORS.Grey}`
			: `1px solid ${COLORS.LightGrey}`};
`;
