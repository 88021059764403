import { Skeleton, Row, Col } from "antd";
import { CheckBoxDiv, Inner, TableHeader } from "./styles";

const GUTTER = 20;

export default function SkeletonBanners() {
	return (
		<Inner>
			<Row gutter={GUTTER}>
				<TableHeader>
					<Col span={5} style={{ display: "flex", justifyContent: "center" }}>
						<Skeleton.Image />
					</Col>
					<Col
						span={15}
						style={{ display: "flex", justifyContent: "space-evenly" }}
					>
						<Skeleton.Input active />
						<Skeleton.Input active />
						<Skeleton.Input active />
					</Col>
					<Col
						span={4}
						style={{ display: "flex", justifyContent: "space-evenly" }}
					>
						<CheckBoxDiv />
						<CheckBoxDiv />
					</Col>
					<Col span={4}></Col>
				</TableHeader>
			</Row>

			<Row gutter={GUTTER}>
				<TableHeader>
					<Col span={5} style={{ display: "flex", justifyContent: "center" }}>
						<Skeleton.Image />
					</Col>
					<Col
						span={15}
						style={{ display: "flex", justifyContent: "space-evenly" }}
					>
						<Skeleton.Input active />
						<Skeleton.Input active />
						<Skeleton.Input active />
					</Col>
					<Col
						span={4}
						style={{ display: "flex", justifyContent: "space-evenly" }}
					>
						<CheckBoxDiv />
						<CheckBoxDiv />
					</Col>
					<Col span={4}></Col>
				</TableHeader>
			</Row>

			<Row gutter={GUTTER}>
				<TableHeader>
					<Col span={5} style={{ display: "flex", justifyContent: "center" }}>
						<Skeleton.Image />
					</Col>
					<Col
						span={15}
						style={{ display: "flex", justifyContent: "space-evenly" }}
					>
						<Skeleton.Input active />
						<Skeleton.Input active />
						<Skeleton.Input active />
					</Col>
					<Col
						span={4}
						style={{ display: "flex", justifyContent: "space-evenly" }}
					>
						<CheckBoxDiv />
						<CheckBoxDiv />
					</Col>
					<Col span={4}></Col>
				</TableHeader>
			</Row>
		</Inner>
	);
}
