import { Progress, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { checkBuyerProgressPercentage } from "../../../../../utils/checkBuyerProgressPercentage";
import Main from "../../../../layout/main/Main";
import { COLORS, Typo } from "../../../../layout/theme";
import {
	Container,
	GraphContainer,
	Inner,
	Section,
	StyledLi,
	StyledOl,
} from "./styles";

const { Title, Paragraph } = Typography;

export default function BuyerStartGuide({ userdata }) {
	const [percent, setPercent] = useState(0);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		setPercent(() => checkBuyerProgressPercentage(userdata));
	}, [userdata]);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Guia de Inicio</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
				<Main.Title>
					<Title style={{ color: COLORS.Primary, marginTop: 30 }} level={3}>
						Te damos la bienvenida a One Transfer
					</Title>
				</Main.Title>
				<Main.SubTitle>
					<Paragraph>
						<blockquote>
							En esta sección te dejamos una guía de pasos para que puedas
							configurar tu cuenta y disfrutar de los mejores descuentos
						</blockquote>
					</Paragraph>
				</Main.SubTitle>
			</Main.Header>

			<Inner>
				<Section>
					<Container>
						<Typo type="primary" level={4}>
							Pasos recomendados
						</Typo>
						<StyledOl>
							<StyledLi>
								<Link to="/admin/account">
									Confirmá los datos de tu cuenta y las Droguerías seleccionadas{" "}
									{userdata?.guideProgress > 0 &&
										userdata?.guideProgress < 8 && (
											<BsFillCheckCircleFill
												color={COLORS.Success}
												style={{ marginLeft: 10 }}
											/>
										)}
								</Link>
							</StyledLi>
							<StyledLi>
								{userdata?.guideProgress > 0 && userdata?.guideProgress < 8 ? (
									<Link to="/admin/pointsofsale">
										Confirmá los datos de tu Punto de Venta{" "}
										{userdata?.guideProgress > 1 &&
											userdata?.guideProgress < 8 && (
												<BsFillCheckCircleFill
													color={COLORS.Success}
													style={{ marginLeft: 10 }}
												/>
											)}
									</Link>
								) : (
									"Configurá tu Punto de Venta"
								)}
							</StyledLi>
						</StyledOl>
						<Typo type="primary" level={4}>
							Pasos opcionales
						</Typo>
						<StyledOl>
							<StyledLi>
								{userdata?.guideProgress > 1 && userdata?.guideProgress < 8 ? (
									<Link to="/admin/templates">
										Si tenés compras recurrentes, podés armar una plantilla{" "}
										{userdata?.guideProgress > 2 &&
											userdata?.guideProgress < 8 && (
												<BsFillCheckCircleFill
													color={COLORS.Success}
													style={{ marginLeft: 10 }}
												/>
											)}
									</Link>
								) : (
									"Si tenés compras recurrentes, podés armar una plantilla"
								)}
							</StyledLi>
							<StyledLi>
								{userdata?.guideProgress > 2 && userdata?.guideProgress < 8 ? (
									<Link to="/admin/users">
										Podés crear distintos usuarios para gestionar el sitio{" "}
										{userdata?.guideProgress > 3 &&
											userdata?.guideProgress < 8 && (
												<BsFillCheckCircleFill
													color={COLORS.Success}
													style={{ marginLeft: 10 }}
												/>
											)}
									</Link>
								) : (
									"Podés crear distintos usuarios para gestionar el sitio"
								)}
							</StyledLi>
							<StyledLi>
								{userdata?.guideProgress > 3 && userdata?.guideProgress < 8 ? (
									<Link to="/admin/compras">
										Podés ver los pedidos que han sido realizados{" "}
										{userdata?.guideProgress > 4 &&
											userdata?.guideProgress < 8 && (
												<BsFillCheckCircleFill
													color={COLORS.Success}
													style={{ marginLeft: 10 }}
												/>
											)}
									</Link>
								) : (
									"Podés ver los pedidos que han sido realizados"
								)}
							</StyledLi>
							<StyledLi>
								{userdata?.guideProgress > 4 && userdata?.guideProgress < 8 ? (
									<Link to="/admin/dashboard">
										Podés tener un seguimiento del flujo general de tu farmacia{" "}
										{userdata?.guideProgress > 5 &&
											userdata?.guideProgress < 8 && (
												<BsFillCheckCircleFill
													color={COLORS.Success}
													style={{ marginLeft: 10 }}
												/>
											)}
									</Link>
								) : (
									"Podés tener un seguimiento del flujo general de tu farmacia"
								)}
							</StyledLi>
							<StyledLi>
								{userdata?.guideProgress > 5 && userdata?.guideProgress < 8 ? (
									<Link to="/admin/marketing">
										Mantenete al tanto de la publicidad activa de los distintos
										laboratorios{" "}
										{userdata?.guideProgress > 6 &&
											userdata?.guideProgress < 8 && (
												<BsFillCheckCircleFill
													color={COLORS.Success}
													style={{ marginLeft: 10 }}
												/>
											)}
									</Link>
								) : (
									"Mantenete al tanto de la publicidad activa de los distintos laboratorios"
								)}
							</StyledLi>
						</StyledOl>
					</Container>
					<GraphContainer>
						<Progress
							type="circle"
							percent={percent}
							style={{
								marginRight: 8,
							}}
						/>
					</GraphContainer>
				</Section>
			</Inner>
		</Main>
	);
}
