import styled from "styled-components";
import { Skeleton, Card } from "antd";

const { Image } = Skeleton;

export const Inner = styled.div`
	display: flex;
	min-height: 100vh;
	background-color: rgb(243 244 246 / 1);
`;

export const ResultsSection = styled.div`
	flex: 1 1 80%;
	padding: 30px 20px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
	& .wide {
		grid-column: span 3;
		grid-row: span 2;
	}
	& .large {
		grid-row: span 2;
	}
`;

export const ImageSkeleton = styled(Image)`
	& .ant-skeleton-image {
		width: 100%;
		height: 100%;
	}
`;

export const AntdCard = styled(Card)`
	height: 100%;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	justify-items: space-between;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
