import React, { useContext, useState, useEffect } from "react";
import {
  delete_item_book_by_id,
  get_book_quantity,
  send_to_cart,
  update_book_of_missings,
} from "../api/endpoints/bookofmissings";
import { useAuth } from "./authContext";
import { useCart } from "./cartContext";

const BookContext = React.createContext();

export function useBook() {
  return useContext(BookContext);
}

export function BookProvider({ children }) {
  const [bookQty, setBookQty] = useState(0);
  const { addProdsToCart } = useCart();
  const { userdata } = useAuth();

  const quantity_book = async () => {
    const res = await get_book_quantity();
    setBookQty(res);
  };

  const delete_from_smart_buy = async (item) => {
    const res = await delete_item_book_by_id(item);
    setBookQty(res);
  };

  useEffect(() => {
    if (!userdata.isSeller && !userdata.isOneMarket) quantity_book();
  }, []);

  const update_book = async (data) => {
    const res = await update_book_of_missings(data);
    setBookQty(res);
    return res;
  };

  const sendProdsToCart = async (data) => {
    const res = await addProdsToCart(data);
    setBookQty(res.bookItemsQty);
    return res;
  };

  const sendProdsToCartFromBook = async () => {
    const res = await send_to_cart();
    if (res) {
      setBookQty(0);
    }
    return res;
  };

  const value = {
    bookQty,
    update_book,
    sendProdsToCart,
    quantity_book,
    delete_from_smart_buy,
    sendProdsToCartFromBook,
  };

  return <BookContext.Provider value={value}>{children}</BookContext.Provider>;
}
