import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { COLORS, Typo } from "../../../../layout/theme";
import {
	ButtonContainer,
	CartDelete,
	CompaginationDiv,
	ContainerTitle,
	GridContainer,
	Inner,
	ModalContainer,
	Section,
} from "./styles";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import { Button, Checkbox, Modal, Select, Space } from "antd";
import {
	CloseCircleOutlined,
	LeftOutlined,
	RightCircleOutlined,
	RightOutlined,
} from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Image from "../../../../common/image/Image";
import { get_market_products_simplified } from "../../../../../api/endpoints/products";
import debounce from "lodash/debounce";
import ProductsTable from "../components/products-table/ProductsTable";
import { get_clients_name_list_filtered } from "../../../../../api/endpoints/clients";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { get_categories_name_list } from "../../../../../api/endpoints/categories";
import { get_brands_name_list_filtered } from "../../../../../api/endpoints/brands";
import { get_active_ingredients_name_list } from "../../../../../api/endpoints/activeIngredients";
import {
	get_featureds,
	update_featureds,
} from "../../../../../api/endpoints/search";
import LoadingSkeleton from "../components/loading-skeleton/LoadingSkeleton";
let productIndex = null;

export default function FeaturedSettings() {
	const navigate = useNavigate();
	const [clients, setClients] = useState(null);
	const [loadingPage, setLoadingPage] = useState(false);
	const [originalClients, setOriginalClients] = useState(null);
	const [categories, setCategories] = useState(null);
	const [brands, setBrands] = useState(null);
	const [activeIngredients, setActiveIngredients] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [searchByStore, setSearchByStore] = useState(null);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(50);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchModalShow, setSearchModalShow] = useState(false);
	const [isApprovedFilter, setIsApprovedFilter] = useState(false);
	const [catalogProducts, setCatalogProducts] = useState([]);
	const [selectedClient, setSelectedClient] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [selectedBrands, setSelectedBrands] = useState([]);
	const [selectedActiveIngredients, setSelectedActiveIngredients] = useState(
		[]
	);

	useEffect(() => {
		if (productIndex) {
			setData(data?.filter((p, idx) => p.id !== productIndex[0]?.id));
		}
	}, [productIndex]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	useEffect(() => {
		setLoadingPage(true);
		const fetch_featureds = async () => {
			const res = await get_featureds();
			setCatalogProducts(
				res.products?.map((p) => ({
					key: p.id,
					id: p.id,
					productId: p.id,
					quantity: 1,
					title: p.name,
					photoUrl: p.photoUrl,
					presentationName: p.presentationName,
				}))
			);

			setSelectedClient(res.clients?.map((r) => r.id));

			setSelectedBrands(res.brands?.map((r) => r.id));

			setSelectedCategories(res.categories?.map((r) => r.id));

			setSelectedActiveIngredients(res.activeIngredients?.map((r) => r.id));

			setLoadingPage(false);
		};
		fetch_featureds();
	}, []);

	useEffect(() => {
		const fetch_market_client_list = async () => {
			const res = await get_clients_name_list_filtered();
			setOriginalClients(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
			);
			setClients(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
			);
		};

		const fetch_market_categories_list = async () => {
			const res = await get_categories_name_list();
			setCategories(
				res?.map((r) => ({
					value: r.id,
					label: r.name,
				}))
			);
		};

		const fetch_market_brands_list = async () => {
			const res = await get_brands_name_list_filtered();
			setBrands(
				res?.map((r) => ({
					value: r.id,
					label: r.name,
				}))
			);
		};

		const fetch_market_active_ingredients_list = async () => {
			const res = await get_active_ingredients_name_list();
			setActiveIngredients(
				res?.map((r) => ({
					value: r.id,
					label: r.name,
				}))
			);
		};

		fetch_market_client_list();
		fetch_market_categories_list();
		fetch_market_brands_list();
		fetch_market_active_ingredients_list();
	}, []);

	const handleChange = (value, type) => {
		setLoading(true);
		switch (type) {
			case "CLIENT":
				setSelectedClient(value);
				setLoading(false);
				break;
			case "CATEGORY":
				setSelectedCategories(value);
				setLoading(false);
				break;
			case "BRAND":
				setSelectedBrands(value);
				setLoading(false);
				break;
			case "ACTIVEINGREDIENT":
				setSelectedActiveIngredients(value);
				setLoading(false);
				break;

			default:
				setLoading(false);
				break;
		}
	};

	const handleIsApprovedFilter = (value) => {
		setIsApprovedFilter(value);
		if (value) {
			const filteredClients = clients.filter((client) => client.isApproved);
			setClients(filteredClients);
		} else {
			setClients(originalClients);
		}
	};

	const productColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			width: 100,
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image width={40} height={40} src={value} alt={record.title} />
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 18 : 16}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.presentationName}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			width: 100,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(index);
						}}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	const catalogColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 90,
			render: (photoUrl) => <Image src={photoUrl} height={40} width={40} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			width: 600,
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 16 : 14}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.presentationName}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Quitar</div>,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button type="link" onClick={() => handleDelete(index)}>
						<CartDelete>X</CartDelete>
					</Button>
				</Space>
			),
		},
	];

	const fetch_products = async (search, skipValue) => {
		setLoading(true);
		try {
			const res = await get_market_products_simplified(
				search,
				skipValue !== null ? skipValue : skip,
				take
			);
			setData(
				res.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setSearchModalShow(true);
		}
	};

	//////******************************************************** */

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => idx === e);
		productIndex = _template;
		const auxTemplate = catalogProducts.concat(_template);

		const TemplateSinDuplicados = auxTemplate.reduce(
			(acumulador, valorActual) => {
				const elementoYaExiste = acumulador.find(
					(elemento) => elemento.id === valorActual.id
				);
				if (elementoYaExiste) {
					return acumulador.map((elemento) => {
						if (elemento.id === valorActual.id) {
							return {
								...elemento,
								quantity: elemento.quantity + valorActual.quantity,
							};
						}
						return elemento;
					});
				}

				return [...acumulador, valorActual];
			},
			[]
		);
		setCatalogProducts(
			TemplateSinDuplicados.sort((a, b) => {
				const titleA = a.title.toLowerCase();
				const titleB = b.title.toLowerCase();
				if (titleA < titleB) {
					return -1;
				}
				if (titleA > titleB) {
					return 1;
				}

				return 0;
			})
		);
	};

	//////******************************************************** */

	const handleDelete = (index) => {
		const _template = catalogProducts.filter((p, idx) => idx !== index);
		setCatalogProducts(_template);
	};

	//////******************************************************** */

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido completados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/");
			},
		});
	};

	const eventHandler = (e) => {
		setCurrentPage(1);
		setSearchByStore(e.target.value);
		fetch_products(e.target.value, 0);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 600), []);

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - 50;

			fetch_products(searchByStore, skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - 50);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + 50;

		fetch_products(searchByStore, skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + 50);
	};

	const handleModalOk = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	const handleModalCancel = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	const onFinish = async (values) => {
		try {
			if (catalogProducts.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe seleccionar al menos un Producto para destacar en el buscador"
				);
				return;
			} else if (catalogProducts.length > 20) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"La cantidad de Productos para destacar en catálogo no puede ser mayor a 20"
				);
				return;
			}

			if (selectedClient.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe seleccionar al menos un Cliente para destacar en el buscador"
				);
				return;
			} else if (selectedClient.length > 5) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"La cantidad de Clientes para destacar en el buscador no puede ser mayor a 5"
				);
				return;
			}

			if (selectedBrands.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe seleccionar al menos una Marca para destacar en el buscador"
				);
				return;
			} else if (selectedBrands.length > 5) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"La cantidad de Marcas para destacar en el buscador no puede ser mayor a 5"
				);
				return;
			}

			if (selectedCategories.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe seleccionar al menos una Categoria para destacar en el buscador"
				);
				return;
			} else if (selectedCategories.length > 5) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"La cantidad de Categorias para destacar en el buscador no puede ser mayor a 5"
				);
				return;
			}

			if (selectedActiveIngredients.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe seleccionar al menos un Ingrediente Activo para destacar en el buscador"
				);
				return;
			} else if (selectedActiveIngredients.length > 5) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"La cantidad de Ingredientes Activos para destacar en el buscador no puede ser mayor a 5"
				);
				return;
			}

			setSubmitting(true);

			const postData = {
				featureds: {
					products:
						catalogProducts?.map((element) => {
							return { id: element?.id };
						}) || [],
					clients:
						selectedClient?.map((element) => {
							return { id: element };
						}) || [],
					brands:
						selectedBrands?.map((element) => {
							return { id: element };
						}) || [],
					categories:
						selectedCategories?.map((element) => {
							return { id: element };
						}) || [],
					activeIngredients:
						selectedActiveIngredients?.map((element) => {
							return { id: element };
						}) || [],
				},
			};

			await update_featureds(postData);
			window.scrollTo(0, 0);
			window.location.reload();

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Destacados creados con exito",
				"Los destacados se crearon con exito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear destacados",
				"Un error ocurrio al intentar crear los destacados, contacte con soporte a soporte@onetransfer.com."
			);
			console.log(error);
		} finally {
			setSubmitting(false);
		}
	};

	if (loadingPage) return <LoadingSkeleton />;

	return (
		<Inner>
			<Section>
				<Typo type="primary" level={6}>
					Productos en catálogo
				</Typo>
				<AntdSearch
					allowClear
					placeholder="Buscar producto"
					width={459}
					onChange={(e) => setSearchByStore(e.target.value)}
					value={searchByStore}
					onPressEnter={(e) =>
						e.target.value.toUpperCase() === "RX" ||
						e.target.value.toUpperCase() === "OTC"
							? debouncedEventHandler(e)
							: e.target.value.length > 3
							? debouncedEventHandler(e)
							: console.log(e.target.value)
					}
					disabled={loading}
				/>
				<Modal
					open={searchModalShow}
					onOk={handleModalOk}
					onCancel={handleModalCancel}
					width={900}
					centered={true}
					footer={null}
					destroyOnClose={true}
				>
					<ModalContainer>
						{data && data.length > 0 && (
							<CompaginationDiv>
								{currentPage !== 1 && (
									<Button size="small" shape="circle" onClick={handleDecrement}>
										<LeftOutlined />
									</Button>
								)}
								{currentPage}
								{data.length === 50 && (
									<Button
										size="small"
										shape="circle"
										onClick={handleIncrement}
										disabled={data.length < 50 ? true : false}
									>
										<RightOutlined />
									</Button>
								)}
							</CompaginationDiv>
						)}
						<ProductsTable
							data={data}
							columns={productColumns}
							loading={loading}
							scrollData={{ y: 350 }}
						/>
					</ModalContainer>
				</Modal>
				<ProductsTable
					data={catalogProducts}
					columns={catalogColumns}
					scrollData={{ y: 360 }}
				></ProductsTable>
			</Section>
			<GridContainer>
				<Section>
					<Typo type="primary" level={6}>
						Clientes en buscador
					</Typo>
					<Checkbox
						checked={isApprovedFilter}
						onChange={(e) => handleIsApprovedFilter(e.target.checked)}
					>
						Clientes con Contrato
					</Checkbox>
					<Select
						showSearch
						mode="multiple"
						placeholder="Buscar cliente"
						optionFilterProp="children"
						onChange={(e) => handleChange(e, "CLIENT")}
						value={selectedClient?.length > 0 ? selectedClient : []}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={clients}
						style={{ width: 180 }}
					/>
				</Section>
				<Section>
					<Typo type="primary" level={6}>
						Marcas en buscador
					</Typo>
					<Select
						showSearch
						mode="multiple"
						placeholder="Buscar marca"
						optionFilterProp="children"
						onChange={(e) => handleChange(e, "BRAND")}
						value={selectedBrands?.length > 0 ? selectedBrands : []}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={brands}
						style={{ width: 180 }}
					/>
				</Section>
				<Section>
					<Typo type="primary" level={6}>
						Categorias en buscador
					</Typo>
					<Select
						showSearch
						mode="multiple"
						placeholder="Buscar categoria"
						optionFilterProp="children"
						onChange={(e) => handleChange(e, "CATEGORY")}
						value={selectedCategories?.length > 0 ? selectedCategories : []}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={categories}
						style={{ width: 180 }}
					/>
				</Section>
				<Section>
					<Typo type="primary" level={6}>
						Ingredientes Activos en buscador
					</Typo>
					<Select
						showSearch
						mode="multiple"
						placeholder="Buscar ingrediente activo"
						optionFilterProp="children"
						onChange={(e) => handleChange(e, "ACTIVEINGREDIENT")}
						value={
							selectedActiveIngredients?.length > 0
								? selectedActiveIngredients
								: []
						}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={activeIngredients}
						style={{ width: 180 }}
					/>
				</Section>
			</GridContainer>
			<ButtonContainer>
				<SignInTemplate.AntdButton
					type="Primary"
					color="white"
					bg="Primary"
					onClick={onFinish}
					loading={submitting}
				>
					Guardar destacados
				</SignInTemplate.AntdButton>
				<SignInTemplate.AntdButton
					type="Primary"
					loading={submitting}
					onClick={handleBack}
				>
					<Typo type="danger" level={6}>
						Salir sin guardar
					</Typo>
				</SignInTemplate.AntdButton>
			</ButtonContainer>
		</Inner>
	);
}
