import axiosInstance from "../axiosInstance";

const CONTROLLER = "Campaigns";

const get_campaigns = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}`
	);
	return response.data;
};

const get_campaigns_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_campaigns_header_info = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetCampaignsHeader`);
	return response.data;
};

const get_campaign_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const create_campaign = async (campaign) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, campaign, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_campaign = async (id, campaign) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, campaign, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_campaign_region = async (id, campaignRegion) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateRegion/${id}`,
		campaignRegion
	);
	return response.data;
};

const delete_campaign = async (id) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/DeleteCampaign/${id}`
	);
	return response.data;
};

const update_campaign_state = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateCampaignStatus`,
		state
	);
	return response.data;
};

const get_campaigns_by_region = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetByRegion`);
	return response.data;
};

const update_campaign_type = async (campaignType) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateCampaignType`,
		campaignType
	);
	return response.data;
};

const get_total_campaigns_by_region = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetTotalByRegion`);
	return response.data;
};

export {
	get_campaigns,
	get_campaigns_page,
	get_campaigns_header_info,
	create_campaign,
	get_campaign_by_id,
	update_campaign,
	delete_campaign,
	update_campaign_state,
	update_campaign_region,
	get_campaigns_by_region,
	update_campaign_type,
	get_total_campaigns_by_region,
};
