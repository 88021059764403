import React from "react";
import { AntdSelect } from "./styles";

export default function SelectQuantity({ product, onChange }) {
	const quantityToShow = [];
	for (
		let i = product.record.minQuantity;
		i <= product.record.maxQuantity;
		i++
	) {
		quantityToShow.push(<AntdSelect.Option key={0 + i}>{i}</AntdSelect.Option>);
	}

	const handleChange = (e) => {
		onChange({ index: product.index, value: e });
	};

	return (
		<AntdSelect defaultValue={product.quantity} onChange={handleChange}>
			{quantityToShow}
		</AntdSelect>
	);
}
