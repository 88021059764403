import styled from "styled-components";
import { COLORS } from "../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const TemplatesSection = styled.div`
	flex: 1 1 80%;

	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}
`;

export const Filters = styled.div`
	margin: 20px 0;

	& button {
		padding: 0 !important;
	}
`;
export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const TopContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: ${COLORS.White};
	border-radius: 20px;
	padding: 2rem;
	align-item: center;
	align-content: center;
	margin-top: 20px;

	@media only screen and (min-width: 980px) and (max-width: 2100px) {
		max-width: 50%;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: ${COLORS.White};
	border-radius: 20px;
	padding: 2rem;
	align-item: center;
	align-content: center;
	margin-top: 20px;
`;

export const FilterContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;

	@media only screen and (min-width: 400px) and (max-width: 1345px) {
		flex-direction: column;
	}
`;
