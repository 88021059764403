import { Button, Table } from "antd";
import styled from "styled-components";
import { COLORS } from "../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 40px;
	margin-top: 30px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: column;
	}
`;

export const ImageSection = styled.div`
	min-height: 350px;
	width: 100%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 10px 20px 20px 20px;
	align-items: center;
`;

export const FilterContainer = styled.div`
	display: flex;
`;

export const AntdButton = styled(Button)`
	display: flex;
	height: 40px;
	align-items: center;
	background-color: ${(props) =>
		props.success ? COLORS.Success : COLORS.Secondary};
	color: ${COLORS.White};
	border: 1px solid
		${(props) => (props.success ? COLORS.Success : COLORS.Secondary)};
	border-radius: 7px;
	padding: 1rem;
	&:hover {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
		color: ${COLORS.White};
	}
	& span {
		color: ${COLORS.White};
	}
	position: relative;

	&:focus {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
	}
	&:active {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
	}
`;

export const Container = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
`;

export const AntdTable = styled(Table)`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const TableContainer = styled.div`
	width: 75%;
`;
