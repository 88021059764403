import { Upload } from "antd";
import UploadIcon from "../../../assets/images/UploadIcon.png";
import Image from "../image/Image";
import { AntdButton } from "./styles";

export default function UploadFileImage({
	onChange,
	urlImage = null,
	imageHeight = "50%",
	imageWidth = "90%",
	borderRadius,
}) {
	return (
		<Upload
			multiple={false}
			className="avatar-uploader"
			showUploadList={false}
			beforeUpload={() => false}
			action={null}
			onChange={onChange}
		>
			<AntdButton>
				<Image
					src={urlImage ? urlImage : UploadIcon}
					alt="Nube"
					style={{
						height: urlImage ? imageHeight : "60%",
						width: urlImage ? imageWidth : "60%",
						borderRadius,
					}}
				/>
			</AntdButton>
		</Upload>
	);
}
