import React, { useEffect, useState } from "react";
import { get_pharmacies_with_totalPos_list_dapper } from "../../../../api/endpoints/clients";
import { Typo } from "../../../layout/theme";
import {
	AntdButton,
	ClientSelect,
	Container,
	CreateOrderContainer,
	Inner,
	Section,
} from "./styles";
import { useCart } from "../../../../contexts/cartContext";
import { useAuth } from "../../../../contexts/authContext";
import AddProducts from "./components/add-products/AddProducts";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import LoadingSkeletonInput from "./components/loading-skeleton-input/LoadingSkeletonInput";
import AddIcon from "../../../../../src/assets/images/circle-plus-solid.svg";
import Image from "../../../common/image/Image";
import {} from "../../../../api/endpoints/templates";
import { Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export default function OneMarketSellerPage() {
	const { user } = useAuth();
	const {
		addProductsToCart,
		emptyCart,
		marketSellerClient,
		selectedClient,
		setSelectedClient,
		qty,
		templateProducts,
		setTemplateProducts,
	} = useCart();
	const [clients, setClients] = useState(null);
	const [orderTemplate, setOrderTemplate] = useState(false);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(500);
	const [clientInfo, setClientInfo] = useState(null);

	useEffect(() => {
		if (templateProducts.length > 0) setOrderTemplate(true);
	}, []);

	useEffect(() => {
		const fetch_clients = async (search, state) => {
			const res = await get_pharmacies_with_totalPos_list_dapper(
				search,
				skip,
				take,
				state
			);
			setClients(res?.items?.map((r) => ({ ...r, key: r.id })));
			selectedClient &&
				setClientInfo(...res?.items?.filter((c) => c.id === selectedClient));
		};

		fetch_clients("", "ACTIVE");
	}, []);

	const handleClientSelect = async (value) => {
		marketSellerClient({ clientId: value });
		setSelectedClient(value);
		setClientInfo(...clients?.filter((c) => c.id === value));
	};

	const handleOrderToCart = async () => {
		try {
			const _cartOrder = {
				prods: templateProducts.map((i) => ({
					id: i.id,
					quantity: i.quantity,
				})),
			};

			await addProductsToCart(_cartOrder);

			setTemplateProducts([]);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Pedido agregado al carro de compras"
			);

			window.scrollTo(0, 0);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al enviar pedido al carro de compras",
				"Un error ocurrio al intentar avanzar con el pedido, contacte con soporte a soporte@onetransfer.com."
			);
		}
	};

	const handleCreateOrder = async () => {
		if (qty > 0) {
			confirm({
				title: "Posee productos en el carro",
				icon: <CloseCircleOutlined style={{ color: "red" }} />,
				content:
					"Si acepta estos productos serán eliminados antes de comenzar con el pedido",
				okText: "Sí",
				okType: "danger",
				cancelText: "No",
				onOk: () => {
					emptyCart({ userId: user.uid });
					setOrderTemplate(true);
				},
				onCancel: () => {
					setOrderTemplate(true);
				},
			});
		} else {
			setOrderTemplate(true);
		}
	};

	return (
		<Inner>
			{!orderTemplate && (
				<Section style={{ alignItems: "center" }}>
					<CreateOrderContainer onClick={handleCreateOrder}>
						<Image
							src={AddIcon}
							alt="Menu"
							style={{
								width: "30px",
							}}
						/>
						<Typo type="primary">CREAR PEDIDO</Typo>
					</CreateOrderContainer>
				</Section>
			)}
			{orderTemplate && (
				<Section>
					<Typo type="primary" level={6}>
						Seleccione un cliente
					</Typo>
					{clients ? (
						<Container>
							<ClientSelect
								onChange={handleClientSelect}
								style={{ width: 260 }}
								placeholder="Seleccione una Farmacia"
								showSearch
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={clients?.map((c) => ({ value: c.id, label: c.name }))}
								value={selectedClient}
							>
								{clients?.map((item) => (
									<ClientSelect.Option key={item.id} value={item.id}>
										{item.name}
									</ClientSelect.Option>
								))}
							</ClientSelect>
							{clientInfo && clientInfo?.pointOfSaleCount === 0 && (
								<Typo type="danger" level={6}>
									Este cliente no posee punto de venta creado
								</Typo>
							)}
						</Container>
					) : (
						<LoadingSkeletonInput />
					)}
				</Section>
			)}
			{orderTemplate && selectedClient && (
				<Section>
					<Typo type="primary" level={6}>
						Productos del pedido
					</Typo>
					<AddProducts
						templateProducts={templateProducts}
						setTemplateProducts={setTemplateProducts}
						orderTemplate={orderTemplate}
						userId={user.uid}
						clientId={selectedClient}
					/>
				</Section>
			)}
			{templateProducts && templateProducts.length > 0 && (
				<Section onClick={handleOrderToCart} style={{ alignItems: "center" }}>
					<AntdButton>Enviar pedido al carro</AntdButton>
				</Section>
			)}
		</Inner>
	);
}
