import React, { useContext, useState, useEffect } from "react";
import {
	add_items_group_to_cart,
	add_products_to_cart,
	add_product_to_cart,
	empty_cart,
	get_cart,
	update_cart_client,
} from "../api/endpoints/cart";

const CartContext = React.createContext();

export function useCart() {
	return useContext(CartContext);
}

export function CartProvider({ children }) {
	const [qty, setQty] = useState(0);
	const [selectedClient, setSelectedClient] = useState(null);
	const [userId, setUserId] = useState("");
	const [templateProducts, setTemplateProducts] = useState([]);
	const [cartItems, setCartItems] = useState({});
	const [webSellerSteps, setWebSellerSteps] = useState(0);
	const [webSellerClientInfo, setWebSellerClientInfo] = useState(0);
	const [expoSelected, setExpoSelected] = useState(null);
	const [expoSellerSteps, setExpoSellerSteps] = useState(0);
	const [expoSellerClientInfo, setExpoSellerClientInfo] = useState(null);
	const [eventProducts, setEventProducts] = useState([]);
	const [webSellerProducts, setWebSellerProducts] = useState([]);

	useEffect(() => {
		const fetch_cart = async () => {
			const res = await get_cart();
			setQty(res.quantityTotal);
			setSelectedClient(res.clientId);
			setUserId(res.userClientId);
		};
		fetch_cart();
	}, []);

	const addToCart = async (item, quantity, isAdd = true) => {
		const res = await add_product_to_cart(item, quantity, isAdd);
		setQty(res.totalCartQuantity);
		setCartItems((prevCartItems) => {
			const updatedCartItems = { ...prevCartItems };
			updatedCartItems[item] = {
				quantity: res.quantity,
			};
			return updatedCartItems;
		});
		return res.quantity;
	};

	const addProdsToCart = async (prods) => {
		const res = await add_products_to_cart(prods);
		setQty(res.totalCartQuantity);
		return res.quantity;
	};

	const addProductsToCart = async (data) => {
		const res = await add_items_group_to_cart(data);
		setQty(res.totalCartQuantity);
		return res.quantity;
	};

	const emptyCart = async (data) => {
		const res = await empty_cart(data);
		setQty(0);
		return res.quantity;
	};

	const marketSellerClient = async (data) => {
		const res = await update_cart_client(data);
		setUserId(res);
	};

	const resetExpoSellerData = () => {
		setExpoSellerClientInfo(null);
		setExpoSellerSteps(1);
		setTemplateProducts(eventProducts);
	};

	const resetWebSellerData = () => {
		setWebSellerClientInfo(null);
		setWebSellerSteps(0);
		setTemplateProducts(webSellerProducts);
	};

	const value = {
		qty,
		addToCart,
		addProdsToCart,
		addProductsToCart,
		emptyCart,
		setQty,
		selectedClient,
		setSelectedClient,
		marketSellerClient,
		userId,
		setUserId,
		templateProducts,
		setTemplateProducts,
		cartItems,
		webSellerSteps,
		setWebSellerSteps,
		webSellerClientInfo,
		setWebSellerClientInfo,
		expoSellerSteps,
		setExpoSellerSteps,
		expoSellerClientInfo,
		setExpoSellerClientInfo,
		expoSelected,
		setExpoSelected,
		eventProducts,
		setEventProducts,
		resetExpoSellerData,
		webSellerProducts,
		setWebSellerProducts,
		resetWebSellerData,
	};

	return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}
