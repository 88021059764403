import styled from "styled-components";
import { Upload } from "antd";
import { COLORS } from "../../../../../layout/theme/colors/Colors";
import { Button, Table } from "antd";
import { WEIGHT } from "../../../../../layout/theme";

export const Buttons = styled.div`
	${(props) =>
		!props.clientId
			? `
      display: flex;
      width: 100%;
      justify-content: end;
      position: absolute;
      transform: translateY(-86px);
      & button {
        padding: 0 !important;
      }

      @media only screen and (min-width: 760px) and (max-width: 1200px) {
        flex-direction: column;
        height: 150px;
      }
  `
			: `
    display: flex;
    width: 100%;
    justify-content: start;
    & button {
      padding: 0 !important;
    }
  `}
`;

export const ButtonsLeft = styled.div`
	display: flex;
	gap: 20px;
`;

export const UploadFile = styled(Upload)`
	width: 150px;
	display: flex;
	justify-content: center;
	background-color: ${COLORS.Secondary};
	color: ${COLORS.White};
	align-items: center;
	border: 1px solid ${COLORS.Secondary};
	border-radius: 7px;
	padding: 0.5rem 0;
	margin-bottom: 1rem;
	cursor: pointer;
	&:hover {
		background-color: ${COLORS.Secondary};
	}
	& span {
		color: ${COLORS.White};
	}
`;

export const AntdButton = styled(Button)`
	width: 150px;
	height: 40px;
	background-color: ${(props) =>
		props.success ? COLORS.Success : COLORS.Default};
	color: ${COLORS.White};
	border: 1px solid
		${(props) => (props.success ? COLORS.Success : COLORS.Default)};
	border-radius: 7px;
	padding: 0.5rem 0;
	&:hover {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Default};
	}
	& span {
		color: ${COLORS.White};
	}
`;

export const TableContainer = styled.div``;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: transparent !important;
		border: none;
	}

	& .hasChanges,
	& .hasChanges:hover {
		color: white;
		background-color: ${COLORS.Default};
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const ConfirmButton = styled.div`
	${(props) =>
		!props.clientId
			? `
	display: flex;
	justify-content: end;
	margin: 20px 0;`
			: `display: flex;
	justify-content: start;
	margin: 0px 0;`}
`;

export const SpanCointainer = styled.div`
	${(props) => (!props.clientId ? `margin: 30px 0;` : `margin: 30px`)}
	display: flex;
	justify-content: space-between;
`;
export const Span = styled.span`
	cursor: pointer;
	color: ${COLORS.Secondary};
	margin-right: 60px;
	font-weight: ${WEIGHT.ExtraBold};
`;

export const Container = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 10px;
`;

export const OptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const SectionContainer = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 10px;
`;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;
