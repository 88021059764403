import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 40px 20px;
`;

export const Block = styled.div`
  padding: 10px 40px;
  width: 100%;
`;
