import React, { useRef } from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination, Autoplay } from "swiper";
import {
	PlaceholderImage,
	SwiperNavNext,
	SwiperNavPrev,
	SwiperSliders,
} from "./styles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export default function Slider({
	delay = 9000,
	children,
	navigation = false,
	pagination = false,
	slidesPerView = 1,
	slidesPerGroup = 1,
	loop = true,
	loading,
	opacityValue = 0.8,
	spaceBetween = 10,
}) {
	const swiperSingleNavPrevRef = useRef(null);
	const swiperSingleNavNextRef = useRef(null);

	return (
		<SwiperSliders
			allowTouchMove={true}
			loop={loop}
			modules={[Navigation, Pagination, Autoplay]}
			pagination={pagination ? { clickable: true } : false}
			className="mySwiper"
			autoHeight={true}
			autoplay={
				delay
					? {
							delay: delay,
							disableOnInteraction: true,
					  }
					: false
			}
			navigation={
				navigation
					? {
							prevEl: swiperSingleNavPrevRef.current,
							nextEl: swiperSingleNavNextRef.current,
					  }
					: false
			}
			onInit={(swiper) => {
				swiper.params.navigation.prevEl = swiperSingleNavPrevRef.current;
				swiper.params.navigation.nextEl = swiperSingleNavNextRef.current;
				swiper.navigation.init();
				swiper.navigation.update();
			}}
			slidesPerView={slidesPerView}
			spaceBetween={spaceBetween}
			slidesPerGroup={slidesPerGroup}
		>
			{!children ? (
				<SwiperSlide>
					<PlaceholderImage />
				</SwiperSlide>
			) : (
				children
			)}
			{navigation && (
				<SwiperNavPrev ref={swiperSingleNavPrevRef} opacityValue={opacityValue}>
					<LeftOutlined />
				</SwiperNavPrev>
			)}
			{navigation && (
				<SwiperNavNext ref={swiperSingleNavNextRef} opacityValue={opacityValue}>
					<RightOutlined />
				</SwiperNavNext>
			)}
		</SwiperSliders>
	);
}
