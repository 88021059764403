import styled, { keyframes } from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../../../layout/theme";
import { Button } from "antd";

const spin = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

export const Inner = styled.div`
	height: 400px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: ${COLORS.White};
	width: 260px;
	border-radius: 6px;

	transition-property: transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;

	&:hover {
		transform: scale(1.01);
	}
`;
export const Header = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: flex-start;
	justify-content: center;
	padding: 8px 5px 5px 5px;
	border-bottom: 1px solid ${COLORS.Grey};
`;

export const HeaderLeft = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: start;
	gap: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const HeaderRight = styled.div`
	height: 55px;
	display: flex;
	align-items: end;
	max-width: 30%;
	padding-right: 10px;
`;

export const Main = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	height: 250px;
`;
export const SectionContainer = styled.div`
	padding-right: 5px;
	padding-left: 5px;
`;
export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: end;
`;

export const FooterPrices = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	border-top: 1px solid ${COLORS.Grey};
	height: 60px;
`;

export const FooterPricesLeft = styled.div`
	width: 50%;
	display: flex;
	align-items: center;
`;
export const FooterPricesLeftTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: content;
	margin-left: 15px;
`;
export const FooterPricesLeftTextBox = styled.div`
	line-height: 0;
`;

export const FooterPricesCenter = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	align-content: center;
	margin-top: 4px;
	gap: 10px;
	scale: 1;

	@media only screen and (min-width: 760px) and (max-width: 1160px) {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		align-content: center;
		margin-top: 4px;
		scale: 0.7;
	}
`;

export const FooterPricesRight = styled.div`
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
`;
export const FooterPriceTextOff = styled.p`
	font-weight: 700;
	writing-mode: vertical-lr;
	font-size: 9px;
	height: 9px;
	margin-right: -7px;
	color: #646464;
	transform-origin: bottom;
	transform: rotateZ(180deg);
`;

export const FooterCart = styled.div`
	height: 60px;
	background: ${COLORS.Primary};
	color: ${COLORS.White};
	display: flex;
	align-items: center;
	position: absolute;
	width: 260px;
	z-index: 3;

	& button {
		background: none;
		color: ${COLORS.White} !important;
		border: none !important;
		height: 100% !important;
		font-size: ${SIZES.level8} !important;
	}

	& button:first-child {
		width: 61.8px;
	}

	& button:nth-of-type(2) {
		width: 61.8px;
	}

	& button:last-child {
		font-size: ${SIZES.level5} !important;
		padding-left: 30px;
		padding-right: 35px;
	}

	& input {
		background: ${COLORS.Primary} !important;
		color: ${COLORS.White} !important;
		border: none !important;
		height: 100% !important;
		outline: none !important;
		box-shadow: none !important;
		text-align: center !important;
		font-size: ${SIZES.level4} !important;
		padding: 0 10px;
		width: 60px;
	}
`;

export const FooterInputs = styled.div`
	height: 60px;
	background: ${COLORS.Primary} !important;
	color: ${COLORS.White};
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	position: absolute;
	width: 260px;
	z-index: 3;
	border-radius: 0 0 6px 6px;
	transition: all 1s ease-out;

	& button {
		background: ${COLORS.Primary} !important;
		color: ${COLORS.White} !important;
		border: none !important;
		height: 100% !important;
		font-size: ${SIZES.level6} !important;
		font-weight: ${WEIGHT.Bold} !important;
		width: 100%;
	}

	& input {
		background: ${COLORS.White} !important;
		color: ${COLORS.Primary} !important;
		transition: color 0.2s ease-out;
		border: none !important;
		height: 100% !important;
		outline: none !important;
		box-shadow: none !important;
		text-align: center !important;
		font-size: ${SIZES.level4} !important;
		font-weight: ${WEIGHT.Bold} !important;
		width: 100%;
	}
`;

export const Spinner = styled.div`
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border-left-color: ${COLORS.White};
	animation: ${spin} 1s ease infinite;
`;

export const SpinnerSection = styled.div`
	display: flex;
	height: 60px;
	align-items: center;
	position: absolute;
	width: 260px;
	z-index: 3;
	background: ${COLORS.Primary};
	justify-content: center;
	align-items: center;
	color: ${COLORS.White};
	border-radius: 0 0 6px 6px;
`;
