import styled from "styled-components/macro";
import { Divider as AntDivider } from "antd";

export const Inner = styled.div`
	min-height: 90vh;
`;
export const Top = styled.div``;
export const Actions = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	gap: 20px;
`;
export const Action = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 14pt;
	padding: 10px 0;
`;

export const Separator = styled.div`
	width: 100%;
`;

export const Divider = styled(AntDivider)`
	margin: 10px 0;
`;
