const WEIGHT = {
  ExtraLight: 100,
  Light: 300,
  SemiBold: 400,
  Bold: 500,
  ExtraBold: 600,
  Black: 700,

};

export default WEIGHT;
