import React from "react";
import { COLORS, Typo } from "../../../../../layout/theme";
import moment from "moment";
import ReportProductTable from "../report-product-table/ReportProductTable";

export default function UsersInfoModal({ modalData }) {
	const userColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Usuario</div>,
			dataIndex: "userName",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Telefono</div>,
			dataIndex: "userPhone",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Email</div>,
			dataIndex: "userEmail",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Farmacia</div>,
			dataIndex: "clientName",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Fecha Interaccion</div>,
			dataIndex: "interactionDate",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{moment(value).format("DD/MM/YYYY hh:mm:ss")}
				</Typo>
			),
		},
	];

	return (
		<div>
			<ReportProductTable
				data={modalData}
				columns={userColumns}
				isHeaderShow={true}
				scroll={{ y: 300 }}
			/>
		</div>
	);
}
