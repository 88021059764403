import { Modal } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../layout/theme";

export const Inner = styled.div`
	background-color: ${COLORS.LightGrey};
`;

export const OffersContainer = styled.div``;

export const Section = styled.div`
	padding: 20px 0;
	padding: 0 30px 10px 30px;
	user-select: none;
`;
export const SectionSeparator = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-weight: ${WEIGHT.Black};
	padding: 10px 0 10px 0;
`;
export const SectionTitle = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-weight: ${WEIGHT.Black};
	padding: 40px 0 30px 0;
`;

export const SectionImage = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
`;

export const SectionLaboImage = styled.div``;

export const SectionSuggestionImage = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 0 auto;
	margin-left: auto;
	margin-right: auto;
`;

export const SectionSuggestionSecondImage = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 0 auto;
	margin-left: auto;
	margin-right: auto;
`;

export const ButtonOffer = styled.button`
	cursor: pointer;

	&:hover {
		color: ${COLORS.Primary};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}
	color: ${COLORS.White};
	font-size: ${SIZES.level7};
	font-weight: ${WEIGHT.Black};
	background-color: ${COLORS.Primary};
	margin: 1em;
	padding: 0.2em 1em;
	border: 2px solid ${COLORS.Primary};
	border-radius: 20px;
	box-shadow: 0 5px 5px #bfbfbf;
	margin-left: 40px;
`;

export const SectionBanners = styled.div`
	@media only screen and (min-width: 760px) and (max-width: 1069px) {
	}
`;

export const AntdModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 20px;
	}
`;
