import { Button, Input, Radio } from "antd";
import styled from "styled-components";
import { COLORS, WEIGHT } from "../../../layout/theme";

export const CalendarButton = styled(Button)`
	border-radius: 5px;
`;

export const RadioOptions = styled.div`
	margin-bottom: 20px;
	display: flex;
	gap: 20px;
`;

export const AntdRadioButton = styled(Radio.Button)`
	border: none;
	font-weight: ${WEIGHT.Bold};
`;

export const CompaginationDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
`;

export const AntdInput = styled(Input)`
	.ant-input-group .ant-input {
		border: 1px solid ${COLORS.Grey};
		border-radius: 10px;
		background-color: transparent;
		text-align: end;
		padding: 5px;
	}

	.ant-input-group .ant-input:hover {
		border: 1px solid ${COLORS.Secondary};
		border-radius: 10px;
	}

	.ant-input-group .ant-input:focus {
		border-radius: 10px;
	}

	.ant-input-group-addon {
		border: 0;
		background-color: transparent;
		padding: 5px;
	}
`;

export const Inner = styled.div`
	display: flex;
	align-items: flex-end;
	padding: 20px 20px 20px 0;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	z-index: 3;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	min-height: 100px;
	justify-content: space-between;
	margin-bottom: 10px;
`;

export const FloatingContainer = styled.div`
	position: fixed;
	top: 110px;
	z-index: 2;
	display: flex;
	justify-content: end;
	margin-bottom: -30px;
	width: 85%;
`;

export const TotalsCard = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	gap: 30px;
	width: 40%;
	height: 50px;
	background-color: ${COLORS.White};
	border-radius: 10px;
`;
