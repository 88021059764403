import React from "react";
import { Inner } from "./styles";
import FeaturedGroupCardContainer from "../featured-group-card-container/FeaturedGroupCardContainer";

export default function FeaturedGroupModal({ data }) {
	return (
		<Inner>
			{data.products?.map((d, idx) => (
				<FeaturedGroupCardContainer
					key={`BannerCard_${idx}`}
					item={d.product}
				/>
			))}
		</Inner>
	);
}
