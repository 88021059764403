import { Input } from "antd";
import styled from "styled-components";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const ContainerDiv = styled.div`
	width: 100%;
	max-height: 600px;
	overflow: auto;

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.Grey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const SearchContainer = styled.div`
	flex: 1 1 40%;
	align-items: center;
	align-content: center;
	justify-content: center;
	display: flex;
	align-content: space-evenly;
	border-raidus: 50px;
	.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}
`;

export const RoundSearch = styled(Input)`
	width: 50%;
	border-radius: 20px;
`;
