import styled from "styled-components";
import { Modal } from "antd";
import { Button, Table } from "antd";
import { COLORS, SIZES, WEIGHT } from "../../../../../../../layout/theme";

export const Buttons = styled.div`
	margin: 20px 0;
	display: flex;
	gap: 20px;

	& button {
		padding: 0 !important;
	}

	@media only screen and (min-width: 760px) and (max-width: 1200px) {
		flex-direction: column;
		height: 150px;
	}
`;

export const AntdButton = styled(Button)`
	width: 150px;
	height: 40px;
	background-color: ${COLORS.Success};
	color: ${COLORS.White};
	border: 1px solid ${COLORS.Success};
	padding: 0.5rem 0;
	&:hover {
		background-color: ${COLORS.Success};
	}
	& span {
		color: ${COLORS.White};
	}
`;

export const TableContainer = styled.div`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const ExpandableTableContainer = styled.div`
	.ant-table-thead > tr > th {
		background: ${COLORS.LightDefault};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.LightGrey};

		div {
			color: ${COLORS.Grey};
		}
	}

	thead {
		border-color: ${COLORS.Grey};
		border: 1px solid ${COLORS.Grey};
	}
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: transparent !important;
		border: none;
	}

	& .hasChanges,
	& .hasChanges:hover {
		color: white;
		background-color: ${COLORS.Default};
	}
`;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const AntdModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
	}

	.ant-modal-header {
		border-radius: 10px 10px 0 0;
	}
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
		padding: 1rem 5rem 1rem 5rem;
		border-radius: 0 0 10px 10px;
	}
	.ant-modal-footer {
		text-align: left;
		height: 80px;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const InfoTitle = styled.div`
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const PresentationTitle = styled.div`
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.SemiBold};
`;

export const InfoClient = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level7};
	font-weight: ${WEIGHT.Bold};
`;

export const InfoQuantity = styled.div`
	display: flex;
	color: ${COLORS.Primary};
	font-size: ${SIZES.level11};
	font-weight: ${WEIGHT.Bold};
	justify-content: center;
	align-items: flex-end;
`;

export const InfoUnity = styled.div`
	color: ${COLORS.Grey};
	font-size: 17px;
	font-weight: ${WEIGHT.Bold};
	margin-left: 5px;
`;

export const Container = styled.div`
	display: flex;
	justify-content: center;
`;

export const CouponInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const DiscountsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export const PriceContainer = styled.div`
	display: flex;
	gap: 5px;
	align-items: baseline;
	justify-content: center;
`;
