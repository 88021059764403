import axiosInstance from "../axiosInstance";

const CONTROLLER = "ProductTags";

const get_tags = async (search) => {
  const response = await axiosInstance.get(
    `${CONTROLLER}?search=${search || ""}`
  );
  return response.data;
};

export { get_tags };
