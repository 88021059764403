import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link } from "react-router-dom";
import { ButtonContainer, ChildrenContainer, AntdButton } from "./styles";
import { get_client_by_id } from "../../../../api/endpoints/clients";
import UsersList from "./list/UsersList";
import { useAuth } from "../../../../contexts/authContext";

export default function UsersPage({ clientId }) {
	const [filter, setFilter] = useState("WEBUSERS");
	const [selectedClient, setSelectedClient] = useState(null);
	const { user, userdata, updateStartGuideProgress } = useAuth();

	const get_filter = () => {
		setFilter(
			localStorage.getItem("marketUsersIndexFilter")
				? localStorage.getItem("marketUsersIndexFilter")
				: "WEBUSERS"
		);
	};

	useEffect(() => {
		get_filter();
	}, []);

	useEffect(() => {
		const fetch_clients = async () => {
			const res = await get_client_by_id(clientId);
			setSelectedClient(res.client);
		};
		fetch_clients();
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Usuarios</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				{selectedClient && selectedClient.contract?.activeMobileApp && (
					<ButtonContainer>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setFilter("WEBUSERS")}
							$active={filter === "WEBUSERS"}
						>
							Usuarios Web
						</AntdButton>
						{selectedClient.contract?.activeMobileApp && (
							<AntdButton
								type="primary"
								bg="Primary"
								color="White"
								style={{ fontWeight: "600", marginLeft: "20px" }}
								width={220}
								height={48}
								fontSize={12}
								onClick={() => setFilter("MOBILEUSERS")}
								$active={filter === "MOBILEUSERS"}
							>
								Usuarios Mobile
							</AntdButton>
						)}
					</ButtonContainer>
				)}

				{selectedClient && (
					<ChildrenContainer>
						{filter === "WEBUSERS" && (
							<UsersList
								usersData={selectedClient.users}
								clientId={clientId}
								client={selectedClient}
								userType="WEB"
								user={user}
								userdata={userdata}
								updateStartGuideProgress={updateStartGuideProgress}
								guideProgress={4}
							/>
						)}
						{filter === "MOBILEUSERS" && (
							<UsersList
								usersData={selectedClient.users}
								clientId={clientId}
								client={selectedClient}
								userType="MOBILE"
								user={user}
								userdata={userdata}
								updateStartGuideProgress={updateStartGuideProgress}
								guideProgress={4}
							/>
						)}
					</ChildrenContainer>
				)}
			</Main.Body>
		</Main>
	);
}
