import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
	ActionButton,
	Container,
	FilterContainer,
	Filters,
	Inner,
	TemplatesSection,
	TopContainer,
} from "./styles";
import { Input, Menu, Dropdown, Radio, Modal } from "antd";
import {
	CloseCircleOutlined,
	InfoCircleOutlined,
	PlusOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import Main from "../../../../layout/main/Main";
import {
	get_templates_page,
	get_template_by_id,
	get_template_header_info,
	update_template_state,
} from "../../../../../api/endpoints/templates";
import DataTable from "../../../../common/data-table/DataTable";
import { COLORS, Typo } from "../../../../layout/theme";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import LoadingHeader from "./components/LoadingHeader";
import TemplateViewModal from "./components/TemplateViewModal/TemplateViewModal";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { useAuth } from "../../../../../contexts/authContext";
import AntdSearch from "../../../../common/antd-search/AntdSearch";

const { Search } = Input;
const { confirm } = Modal;

export default function TemplatesList() {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [key, setKey] = useState("null");
	const [headerInfo, setHeaderInfo] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [show, setShow] = useState(false);
	const [dataView, setDataView] = useState(null);
	const { user, userdata, updateStartGuideProgress } = useAuth();

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Nombre de la plantilla</div>
			),
			dataIndex: "name",
			key: "name",
			render: (f, r) => (
				<Link to={`/admin/templates/${key.id}`} onMouseOver={() => setKey(r)}>
					<Typo type="terciary" fontWeight={600} fontSize={14}>
						{f}
					</Typo>
				</Link>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Productos guardados</div>
			),
			dataIndex: "quantityItems",
			key: "quantityItems",
			render: (fila) => (
				<div style={{ color: COLORS.Grey, fontWeight: "600" }}>{fila}</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Unidades totales</div>,
			dataIndex: "totalProductQuantity",
			key: "totalProductQuantity",
			render: (fila) => (
				<div style={{ color: COLORS.Grey, fontWeight: "600" }}>{fila}</div>
			),
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={r.deleted ? menuInactive : menu}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/templates/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleDelete(key)}>Desactivar</Typo>,
				},
				{
					key: "3",
					label: <Typo onClick={() => handleView(key)}>Ver plantilla</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/templates/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleActivate(key)}>Reactivar</Typo>,
				},
				{
					key: "3",
					label: <Typo onClick={() => handleView(key)}>Ver plantilla</Typo>,
				},
			]}
		/>
	);

	const fetch_data_template_page = async (page, state, search) => {
		const res = await get_templates_page(page, state, search);

		setData({
			...res,
			items: res.items?.map((r) => ({
				...r,
				totalProductQuantity: r.items?.reduce(function (prev, current) {
					return prev + +current.quantity;
				}, 0),
			})),
		});
	};

	useEffect(() => {
		fetch_data_template_page(1, state, "");
	}, [state]);

	const fetch_order_templates_header_info = async () => {
		const res = await get_template_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_order_templates_header_info();
	}, []);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_data_template_page(1, state, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleDelete = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar esta Plantilla?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La eliminación de la Plantilla se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				deleteTemplate(key);
			},
		});
	};

	const deleteTemplate = async (id) => {
		const _state = { id: id, state: "DELETED" };
		await update_template_state(id, _state);
		fetch_data_template_page(1, state, "");
		fetch_order_templates_header_info();
	};

	const handleActivate = (key) => {
		confirm({
			title: "¿Seguro que quiere reactivar esta Plantilla?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "La reactivación de la Plantilla se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				activateTemplate(key);
			},
		});
	};

	const activateTemplate = async (id) => {
		const _state = { id: id, state: "ACTIVE" };
		await update_template_state(id, _state);
		fetch_data_template_page(1, state, "");
		fetch_order_templates_header_info();
	};

	const handleView = async (key) => {
		const res = await get_template_by_id(key);
		setDataView(res);
		setShow(true);
	};

	const handleOk = () => {
		setShow(false);
	};

	const handleCancel = () => {
		setShow(false);
	};

	const handleCreateTemplate = () => {
		navigate("/admin/templates/create");
	};

	const handleBackToTheGuide = () => {
		updateStartGuideProgress(user.uid, 3);
		navigate("/startGuide");
	};

	return (
		<Main>
			<Main.Breadcrumb>
				<Main.BreadcrumbItem>
					<Link to="/">Home</Link>
				</Main.BreadcrumbItem>
				<Main.BreadcrumbSeparator />
				<Main.ActiveBreadcrumbItem>
					Plantillas de compra
				</Main.ActiveBreadcrumbItem>
			</Main.Breadcrumb>

			<Inner>
				<TopContainer>
					<SignInTemplate.AntdButton
						type="primary"
						bg="Primary"
						color="White"
						icon={<PlusOutlined />}
						onClick={handleCreateTemplate}
						style={{ width: "300px", fontWeight: "400" }}
					>
						Crear plantilla de compra
					</SignInTemplate.AntdButton>
					<Filters>
						{!headerInfo ? (
							<LoadingHeader />
						) : (
							<FilterContainer>
								<AntdSearch
									allowClear
									placeholder="Buscar plantilla"
									onChange={debouncedEventHandler}
									width={300}
								/>
								<Radio.Group
									value={state}
									onChange={(e) => setState(e.target.value)}
								>
									<Radio.Button value="ACTIVE">{`Activos (${headerInfo.totalActive})`}</Radio.Button>
									<Radio.Button value="DELETED">{`Inactivos (${headerInfo.totalDisabled})`}</Radio.Button>
								</Radio.Group>
							</FilterContainer>
						)}
					</Filters>
				</TopContainer>
				<Container>
					<TemplatesSection>
						<DataTable
							data={data}
							fetch={fetch_data_template_page}
							columns={columns}
						/>
					</TemplatesSection>
					{userdata?.guideProgress === 2 && (
						<SignInTemplate.AntdButton
							type="primary"
							bg="Primary"
							color="White"
							icon={<QuestionCircleOutlined />}
							onClick={handleBackToTheGuide}
							style={{ width: "250px", fontWeight: "400" }}
						>
							Volver a la Guia de Inicio
						</SignInTemplate.AntdButton>
					)}
				</Container>
			</Inner>
			<Modal
				visible={show}
				onOk={handleOk}
				onCancel={handleCancel}
				width={900}
				centered={true}
				footer={null}
				destroyOnClose={true}
			>
				<TemplateViewModal dataView={dataView} />
			</Modal>
		</Main>
	);
}
