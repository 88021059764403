import { Checkbox, Select } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";
import AntArrow from "../../../../../assets/images/cart-arrow-select.svg";

export const Inner = styled.div`
	display: flex;
	border-radius: 15px;
	background-color: ${COLORS.White};
	height: 140px;
	width: 100%;
	padding: 10px 20px;
	gap: 20px;
`;
export const ImageContainer = styled.div`
	flex: 1 1 20%;
	-webkit-flex: 0 0 20%;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	object-fit: contain;
`;
export const CenterData = styled.div`
	flex: 1 1 60%;
	display: flex;
	border-left: 1px solid ${COLORS.Grey};
	border-right: 1px solid ${COLORS.Grey};
`;

export const RightData = styled.div`
	flex: 1 1 10%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	gap: 40px;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	height: 50px;
`;

export const AntdCheckbox = styled(Checkbox)`
	position: absolute;
	margin-top: -5px;
	margin-left: 5px;
`;
