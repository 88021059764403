import styled, { keyframes } from "styled-components";
import { COLORS, WEIGHT } from "../../../../layout/theme";

const linearMove = keyframes`
  from {
    transform: translateX(20%);
  }

  to {
    transform: translateX(0%);
  }
`;

export const Filters = styled.div`
	margin: 20px 0;

	& button {
		padding: 0 !important;
	}
`;

export const SpanCointainer = styled.div`
	margin: 60px 0;
`;

export const Span = styled.span`
	cursor: pointer;
	color: ${COLORS.Secondary};
	margin-right: 60px;
	font-weight: ${WEIGHT.ExtraBold};
`;

export const CampaignsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const CardContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
