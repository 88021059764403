function regionGroup(userRegion) {
	let regionArr = userRegion.map((r) => ({
		province: r.region.split("-")[1],
		locality: r.region.split("-")[2],
	}));

	const groupByProvince = (array) => {
		return array.reduce((result, currentValue) => {
			if (!result[currentValue.province]) {
				result[currentValue.province] = {
					province: currentValue.province,
					locality: [],
				};
			}

			result[currentValue.province].locality.push(currentValue.locality);

			return result;
		}, {});
	};

	const regionInitialValue = groupByProvince(regionArr);

	return Object.values(regionInitialValue);
}

export { regionGroup };
