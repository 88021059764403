import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Space, Radio, Dropdown, Menu, Modal } from "antd";
import {
	CloseCircleOutlined,
	InfoCircleOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import {
	ActionButton,
	AntdRadioButton,
	Filters,
	Inner,
	RadioOptions,
} from "./styles";
import LoadingHeader from "./components/LoadingHeader";
import Image from "../../../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import { COLORS, Typo } from "../../../../../layout/theme";
import DropShadow from "../../../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import DataTable from "../../../../../common/data-table/DataTable";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
	get_megaDistributors_header_info,
	get_megaDistributors_page,
	update_megaDistributor_state,
} from "../../../../../../api/endpoints/megaDistributors";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";

const { confirm } = Modal;

export default function MegaDistributorList() {
	const navigate = useNavigate();
	const [headerInfo, setHeaderInfo] = useState(null);
	const [data, setData] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [key, setKey] = useState("null");

	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			render: (value, record) => (
				<Link
					to={`/admin/distributorsIndex/megaDistributor/${key.id}`}
					onMouseOver={() => setKey(record)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Link>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Correo electrónico</div>
			),
			dataIndex: "email",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={r.isActive ? menu : menuInactive}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/distributorsIndex/megaDistributor/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleDelete(key)}>Eliminar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/distributorsIndex/megaDistributor/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleActivate(key)}>Restablecer</Typo>,
				},
			]}
		/>
	);

	const fetch_megaDistributors = async (page, state, search) => {
		const res = await get_megaDistributors_page(search, state, page);
		setData(res);
	};

	const fetch_megaDistributors_header = async () => {
		const res = await get_megaDistributors_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_megaDistributors_header();
	}, [state]);

	useEffect(() => {
		fetch_megaDistributors(1, state, "");
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_megaDistributors(1, state, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleDelete = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar este distribuidor?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "Este cambio se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				deleteDistributor(key);
			},
		});
	};

	const deleteDistributor = async (id) => {
		const _state = { id: id, state: "DISABLED" };
		await update_megaDistributor_state(id, _state);
		fetch_megaDistributors(1, state, "");
		fetch_megaDistributors_header();
	};

	const handleActivate = (key) => {
		confirm({
			title: "¿Seguro que quiere restablecer este distribuidor?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "Este cambio se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				activateTemplate(key);
			},
		});
	};

	const activateTemplate = async (id) => {
		const _state = { id: id, state: "ACTIVE" };
		await update_megaDistributor_state(id, _state);
		fetch_megaDistributors(1, state, "");
		fetch_megaDistributors_header();
	};

	return (
		<Inner>
			<Filters>
				{!headerInfo ? (
					<LoadingHeader />
				) : (
					<Space size="large">
						<AntdSearch
							width={400}
							allowClear
							placeholder="Buscar distribuidor"
							onChange={debouncedEventHandler}
						/>
						<DropShadow type="drop">
							<SignInTemplate.AntdButton
								type="primary"
								bg="Primary"
								color="White"
								icon={<PlusOutlined />}
								onClick={() =>
									navigate("/admin/distributorsIndex/createMegaDistributor")
								}
								style={{ width: "200px", fontWeight: "400" }}
							>
								Crear Distribuidor
							</SignInTemplate.AntdButton>
						</DropShadow>
					</Space>
				)}
			</Filters>
			<RadioOptions>
				<Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
					<AntdRadioButton value="ACTIVE">
						Activo ({headerInfo?.totalActive})
					</AntdRadioButton>
					<AntdRadioButton value="DISABLED">
						Inactivo ({headerInfo?.totalDisabled})
					</AntdRadioButton>
				</Radio.Group>
			</RadioOptions>
			<DataTable data={data} fetch={fetch_megaDistributors} columns={columns} />
		</Inner>
	);
}
