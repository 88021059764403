import styled from "styled-components";
import { Button as ButtonAntd } from "antd";
import { COLORS } from "../../../layout/theme";

export const ButtonContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 1fr;
	grid-row-gap: 20px;
	align-items: center;
`;

export const ButtonContainerTop = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: 1fr;
	grid-row-gap: 20px;
	align-items: center;
`;

export const AntdButton = styled(ButtonAntd)`
	background-color: ${(props) =>
		props.$active ? COLORS.Primary : COLORS.White};
	color: ${(props) => (props.$active ? COLORS.White : COLORS.Primary)};
	border-color: ${COLORS.Primary};
	border-radius: 5px;
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
`;
export const ChildrenContainer = styled.div`
	margin-top: 50px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
