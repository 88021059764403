import { useEffect, useState } from "react";
import { auth } from "./configuration/firebase";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { get_current_user } from "./api/endpoints/users";
import "./App.css";
import LoadingScreen from "./components/common/loading-screen/LoadingScreen";
import {
	CompleteUserData,
	ConfirmEmail,
	SignIn,
	SignUp,
	EmailVerification,
	ForgotPassword,
	ResetPassword,
	TermsOfService,
	MobileSignIn,
} from "./components/pages/auth";
import Main from "./components/root/Main";
import { useAuth } from "./contexts/authContext";
import { BookProvider } from "./contexts/bookContext";
import { CartProvider } from "./contexts/cartContext";
import "moment/locale/es";
import useIsMobile from "./hooks/useIsMobile";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./configuration/applicationInsightsConfig";
import axiosInstance from "./api/axiosInstance";
import { FilterProvider } from "./contexts/filterContext";

function App() {
	const { user, userdata, ready } = useAuth();
	const [dbUser, dbSetUser] = useState(null);
	const isMobile = useIsMobile();

	axiosInstance.interceptors.request.use(
		async (config) => {
			const token = await auth?.currentUser?.getIdToken();

			if (!token) {
				return config;
			}

			config.headers = {
				...config.headers,
				authorization: `Bearer ${token}`,
			};

			return config;
		},
		(error) => {
			console.log("axios interceptor error");
			return Promise.reject(error);
		}
	);

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const mode = params.get("mode");
	const oobCode = params.get("oobCode");

	useEffect(() => {
		const fetch_user = async () => {
			const res = await get_current_user();
			dbSetUser(res);
		};

		if (ready) {
			fetch_user();
		}
	}, [ready]);

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/signup"
					element={user ? <Navigate to="/" /> : <SignUp />}
				/>

				<Route
					path="/confirm-email"
					element={
						user && user.emailVerified ? <Navigate to="/" /> : <ConfirmEmail />
					}
				/>

				<Route path="/forgot-password" element={<ForgotPassword />} />

				<Route
					path="/*"
					element={
						isMobile ? (
							<MobileSignIn />
						) : mode === "resetPassword" && oobCode ? (
							<ResetPassword />
						) : mode === "verifyEmail" && oobCode ? (
							<ConfirmEmail />
						) : !user ? (
							<SignIn />
						) : !ready ? (
							<LoadingScreen message="Cargando informacion de usuario..." />
						) : !userdata ? (
							<SignUp />
						) : !user?.emailVerified ? (
							<EmailVerification />
						) : !userdata.completeUserData ? (
							<CompleteUserData />
						) : !userdata.termsOfService ? (
							<TermsOfService user={userdata} />
						) : !userdata.validated ? (
							<SignIn newUserData={userdata} />
						) : dbUser?.deleted ? (
							<SignIn deletedUser={dbUser.deleted} />
						) : (
							<AppInsightsContext.Provider value={reactPlugin}>
								<FilterProvider>
									<CartProvider>
										<BookProvider>
											<Main userdata={userdata} clientUser={dbUser} />
										</BookProvider>
									</CartProvider>
								</FilterProvider>
							</AppInsightsContext.Provider>
						)
					}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
