import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../../../layout/main/Main";
import { Space, Radio, Dropdown, Menu, Modal, Button } from "antd";
import {
	CloseCircleOutlined,
	DeploymentUnitOutlined,
	InfoCircleOutlined,
	LeftOutlined,
	PlusOutlined,
	RightOutlined,
} from "@ant-design/icons";
import {
	get_distributors_header_info,
	update_distributor_state,
	get_distributors_list_dapper,
} from "../../../../../api/endpoints/distributors";
import {
	ActionButton,
	AntdRadioButton,
	AntdRadioButton2,
	CompaginationDiv,
	Filters,
	RadioOptions,
	TableContainer,
} from "./styles";
import LoadingHeader from "./components/LoadingHeader";
import Image from "../../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import { COLORS, Typo } from "../../../../layout/theme";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import DistributorTable from "./components/distributor-table/DistributorTable";
import { CONSTANTS } from "../../../../../utils/constants";

const { confirm } = Modal;

export default function DistributorList() {
	const navigate = useNavigate();
	const [headerInfo, setHeaderInfo] = useState(null);
	const [data, setData] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [key, setKey] = useState("null");
	const [loading, setLoading] = useState(false);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(100);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [distributorType, setDistributorType] = useState(
		CONSTANTS.DISTRIBUTOR_TYPE_DISTRIBUTOR
	);

	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			render: (value, record) => (
				<Link
					to={`/admin/distributors/${key.id}`}
					onMouseOver={() => setKey(record)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Link>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={r.isActive ? menu : menuInactive}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/distributors/${key.id}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleDelete(key)}>Desactivar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/distributors/${key.id}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleActivate(key)}>Reactivar</Typo>,
				},
			]}
		/>
	);

	const fetch_distributors = async (search = "", skipValue = null) => {
		setLoading(true);
		try {
			const res = await get_distributors_list_dapper(
				search,
				skipValue !== null ? skipValue : skip,
				take,
				state,
				distributorType
			);

			setData(res);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - 100;

			fetch_distributors(searchTerm, skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - 100);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + 100;

		fetch_distributors(searchTerm, skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + 100);
	};

	const fetch_distributors_header = async () => {
		const res = await get_distributors_header_info(distributorType);
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_distributors_header();
	}, [state, distributorType]);

	useEffect(() => {
		fetch_distributors();
	}, [state, distributorType]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setSearchTerm(e.target.value);
		fetch_distributors(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleDelete = (dist) => {
		confirm({
			title: "¿Seguro que quiere desactivar esta droguería?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La desactivación de la droguería se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleDeleteSecondWarning(dist);
			},
		});
	};

	const handleDeleteSecondWarning = (dist) => {
		confirm({
			title:
				"Tenga en cuenta que al continuar con esta acción la droguería quedará inaccesible en el sistema",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "Desea proceder de todas maneras?",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				deleteDistributor(dist);
			},
		});
	};

	const deleteDistributor = async (dist) => {
		setLoading(true);
		const _state = { id: dist.id, state: "DISABLED" };
		await update_distributor_state(dist.id, _state);
		fetch_distributors();
		fetch_distributors_header();
		setLoading(false);
	};

	const handleActivate = (dist) => {
		confirm({
			title: "¿Seguro que quiere reactivar esta droguería?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "La reactivación de la droguería se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				activateTemplate(dist);
			},
		});
	};

	const activateTemplate = async (dist) => {
		if (dist.mails.length === 0) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Al menos un correo es necesario para poder activar la Droguería"
			);
			return;
		} else {
			setLoading(true);
			const _state = { id: dist.id, state: "ACTIVE" };
			await update_distributor_state(dist.id, _state);
			fetch_distributors();
			fetch_distributors_header();
			setLoading(false);
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Droguerías</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<AntdSearch
								width={400}
								allowClear
								placeholder="Buscar droguería"
								onChange={debouncedEventHandler}
							/>
							<DropShadow type="drop">
								<SignInTemplate.AntdButton
									type="primary"
									bg="Primary"
									color="White"
									icon={<PlusOutlined />}
									onClick={() => navigate("/admin/distributors/create")}
									style={{ width: "200px", fontWeight: "400" }}
								>
									Crear droguería
								</SignInTemplate.AntdButton>
							</DropShadow>
							<DropShadow type="drop">
								<SignInTemplate.AntdButton
									type="primary"
									bg="Primary"
									color="White"
									icon={<DeploymentUnitOutlined />}
									onClick={() => navigate("/admin/distributors/asign")}
									style={{ width: "200px", fontWeight: "400" }}
								>
									Crear relaciones
								</SignInTemplate.AntdButton>
							</DropShadow>
						</Space>
					)}
				</Filters>
				<RadioOptions>
					<Radio.Group
						value={distributorType}
						onChange={(e) => setDistributorType(e.target.value)}
					>
						<AntdRadioButton2 value={CONSTANTS.DISTRIBUTOR_TYPE_DISTRIBUTOR}>
							Droguerías
						</AntdRadioButton2>
						<AntdRadioButton2 value={CONSTANTS.DISTRIBUTOR_TYPE_LABORATORY}>
							Laboratorios
						</AntdRadioButton2>
					</Radio.Group>
					<Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
						<AntdRadioButton value="ACTIVE">
							Activo ({headerInfo?.totalActive})
						</AntdRadioButton>
						<AntdRadioButton value="DISABLED">
							Inactivo ({headerInfo?.totalDisabled})
						</AntdRadioButton>
					</Radio.Group>
				</RadioOptions>
				<TableContainer>
					<DistributorTable
						data={data?.items}
						columns={columns}
						loading={loading}
						isHeaderShow={true}
					/>
				</TableContainer>
				{data && data.length > 0 && (
					<CompaginationDiv>
						{currentPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						{data.length === 100 && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={data.length < 100 ? true : false}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
			</Main.Body>
		</Main>
	);
}
