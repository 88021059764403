import axiosInstance from "../axiosInstance";

const CONTROLLER = "region";

const get_provinces = async () => {
	const response = await axiosInstance.get(CONTROLLER);
	return response.data;
};

const get_localities_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

export { get_provinces, get_localities_by_id };
