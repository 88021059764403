import { Modal } from "antd";
import styled from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	height: 100%;
`;

export const Section = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;

	.span {
		text-overflow: ellipsis;
		width: 85%;
		overflow: hidden;
		white-space: nowrap;
	}
`;

export const MoreInfo = styled.div`
  color: ${COLORS.Secondary};
  font-size: ${SIZES.level6}:
  font-weight: ${WEIGHT.Bold};
  cursor: Pointer;
`;

export const AntdModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
	}

	.ant-modal-header {
		border-radius: 10px 10px 0 0;
	}
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
		border-radius: 10px;
	}
	.ant-modal-footer {
		text-align: left;
		height: 80px;
	}
`;

export const RightContainer = styled.div`
	flex: 1 1 90%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 100%;
`;

export const LeftContainer = styled.div`
	flex: 0 0 5%;
	align-items: center;
	justify-content: center;
	display: flex;
	color: ${COLORS.Secondary};
	cursor: pointer;
`;
