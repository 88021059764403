import { Checkbox } from "antd";
import styled from "styled-components/macro";
import { COLORS } from "../../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;
export const ClientData = styled.div`
	flex: 1 1 50%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
`;
export const PublishSettings = styled.div`
	flex: 1 1 50%;
	background-color: ${COLORS.White};
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	padding: 2rem;
	border-radius: 10px;
`;
export const DistributorImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`;
export const PreviewImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: contain;
`;
export const Container = styled.div`
	padding: 2rem;
`;

export const AntdCheckbox = styled(Checkbox)``;

export const BlockedData = styled.div`
	pointer-events: none;
`;
