import styled from "styled-components";
import { Checkbox } from "antd";
import { COLORS } from "../../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;
export const ProductData = styled.div`
	flex: 1 1 65%;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.ant-form-item-explain-error {
		white-space: nowrap;
	}
`;
export const PublishSettings = styled.div`
	flex: 1 1 35%;
	background-color: ${COLORS.White};
	border-radius: 10px;
`;
export const DistributorImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`;
export const Container = styled.div`
	position: sticky;
	top: 0;
	padding: 2rem;
`;

export const AntdCheckbox = styled(Checkbox)``;

export const CardContainer = styled.div`
	background-color: ${COLORS.White};
	border-radius: 10px;
	padding: 1rem;
`;
