import { Select } from "antd";
import styled from "styled-components";
import { COLORS, SIZES } from "../../layout/theme";

export const SelectAntd = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &..ant-select-arrow {
      
    }
  

  &.ant-select-selector{
    .ant-select-selection-item {
      font-size: ${SIZES.level6};
      color: ${COLORS.DarkGrey};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${COLORS.Primary};
      }
    }
  }
`;
