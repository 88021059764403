import axiosInstance from "../axiosInstance";

const CONTROLLER = "ProductPresentations";

const get_presentations_types = async () => {
	const response = await axiosInstance.get(CONTROLLER);
	return response.data;
};

const get_presentations_types_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_presentations_types_header_info = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetProductPresentationsHeader`
	);
	return response.data;
};

const get_presentation_type_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const update_presentations_types_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateProductPresentationState/${id}`,
		state
	);
	return response.data;
};

const create_presentation_type = async (presentationType) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, presentationType);
	return response.data;
};

const update_presentation_type = async (id, presentationType) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/${id}`,
		presentationType
	);
	return response.data;
};

export {
	get_presentations_types,
	get_presentations_types_page,
	get_presentations_types_header_info,
	update_presentations_types_state,
	create_presentation_type,
	get_presentation_type_by_id,
	update_presentation_type,
};
