import { Input } from "antd";
import styled from "styled-components";

export const RoundSearch = styled(Input)`
	${(props) =>
		props.width &&
		`
    width: ${props.width}px;
  `}
	${(props) =>
		props.height &&
		`
    height: ${props.height}px;
  `}
  border-radius: 5px;

	.ant-input-suffix {
		${(props) =>
			props.callbackSearch !== null &&
			props.callbackSearch !== undefined &&
			`
      cursor: pointer;
    `}
	}
`;
