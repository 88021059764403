import {
	Checkbox,
	Dropdown,
	Form,
	Input,
	Menu,
	Modal,
	Select,
	Typography,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Main from "../../../../layout/main/Main";
import { COLORS, Typo } from "../../../../layout/theme";
import {
	ActionButton,
	AntdModal,
	AntdTable,
	ClientData,
	Container,
	ImageSection,
	Inner,
	InnerForm,
	PublishSettings,
	TableContainer,
} from "./styles";
import {
	TYPE,
	openNotificationWithIcon,
} from "../../../../../utils/notificationToast";
import moment from "moment";
import {
	get_firestore_users,
	update_firebase_user,
} from "../../../../../api/endpoints/users";
import { CONSTANTS } from "../../../../../utils/constants";
import debounce from "lodash/debounce";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import { BsThreeDotsVertical } from "react-icons/bs";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";

const { Option } = Select;
const { Paragraph } = Typography;

export default function MarketUsersFirestore({
	isCheckedFilter,
	setIsCheckedFilter,
}) {
	const [isLoading, setIsLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [clientType, setClientType] = useState("");
	const [userType, setUserType] = useState("");
	const [key, setKey] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [firestoreUsersList, setFirestoreUsersList] = useState(null);
	const [modalInputValue, setModalInputValue] = useState("");
	const [emailModified, setEmailModified] = useState(false);
	const [originalFirestoreUsersList, setOriginalFirestoreUsersList] =
		useState(null);

	const typesOfUsers = [
		{
			id: 1,
			key: 1,
			name: "Web",
			value: "WEB",
		},
		{
			id: 2,
			key: 2,
			name: "Mobile",
			value: "MOBILE",
		},
	];

	const typesOfClients = [
		{
			id: 1,
			key: 1,
			name: "Farmacia",
			value: "PHARMACY",
		},
		{
			id: 2,
			key: 2,
			name: "Laboratorio",
			value: "LABORATORY",
		},
	];

	const fetch_users = async (search) => {
		setIsLoading(true);
		try {
			const res = await get_firestore_users(search, clientType, userType);
			setFirestoreUsersList(
				res.firestoreUsers.filter((u) => u.userDbData?.clientName === null)
			);
			setOriginalFirestoreUsersList(res.firestoreUsers);
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetch_users("");
	}, [clientType, userType, emailModified]);

	const selectTypeOfUser = (value) => {
		if (value === CONSTANTS.ALL_VALUES) {
			setUserType("");
		} else {
			setUserType(value);
		}
	};

	const selectTypeOfClient = (value) => {
		if (value === CONSTANTS.ALL_VALUES) {
			setClientType("");
			setUserType("");
		} else {
			setClientType(value);
		}
	};

	const handleEditUserClick = () => {
		if (key) {
			setModalInputValue(key.userMetadata.email);
			setShowEditModal(true);
		}
	};

	const handleOk = () => {
		setModalInputValue("");
		setShowEditModal(false);
		setKey(null);
	};

	const handleCancel = () => {
		setModalInputValue("");
		setShowEditModal(false);
		setKey(null);
	};

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Correo</div>,
			dataIndex: "firebaseEmail",
			key: "firebaseEmail",
			width: 260,
			render: (value, record, index) => {
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 5,
							cursor: "pointer",
						}}
						onClick={handleEditUserClick}
						onMouseOver={() => setKey(record)}
					>
						<Typo level={6} type="primary">
							{record?.userMetadata?.email}
						</Typo>
						<Typo level={8} type="muted">
							{record?.email}
						</Typo>
					</div>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "firstName",
			key: "firstName",
			render: (value, record, index) => {
				return (
					<Typo level={6} type="primary">
						{value}
					</Typo>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Apellido</div>,
			dataIndex: "lastName",
			key: "lastName",
			render: (value, record, index) => {
				return (
					<Typo level={6} type="primary">
						{value}
					</Typo>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cliente</div>,
			dataIndex: "client",
			key: "client",
			render: (value, record, index) => {
				return (
					<Typo level={6} type="primary">
						{record?.userDbData?.clientName}
					</Typo>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}>PDVs</div>,
			dataIndex: "pdv",
			key: "pdv",
			render: (value, record, index) => {
				return (
					<Typo level={6} type="primary">
						{record?.userDbData?.totalPointsOfSale.toString()}
					</Typo>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Creacion</div>,
			dataIndex: "created",
			key: "created",
			render: (value, record, index) => {
				return (
					<Typo level={6} type="primary">
						{moment(record?.userMetadata?.createdAt).format("DD/MM/YYYY")}
					</Typo>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Ultimo Login</div>,
			dataIndex: "logIn",
			key: "logIn",
			render: (value, record, index) => {
				return (
					<Typo level={6} type="primary">
						{moment(record?.userMetadata?.accessDate).format("DD/MM/YYYY")}
					</Typo>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}></div>,
			dataIndex: "action",
			key: "action",
			width: 50,
			render: (value, record, index) => {
				return (
					<>
						<Dropdown menu={{ items }} placement="bottomRight">
							<ActionButton key={`${value}`} onMouseOver={() => setKey(record)}>
								<BsThreeDotsVertical />
							</ActionButton>
						</Dropdown>
					</>
				);
			},
		},
	];

	const items = [
		{
			key: "1",
			label: <Typo onClick={handleEditUserClick}>Editar</Typo>,
		},
	];

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_users(e.target.value);
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 350),
		[clientType, userType]
	);

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				setShowEditModal(false);
			},
		});
	};

	const onFinish = async () => {
		if (!!key.userDbData.clientName) {
			Modal.confirm({
				title: "¿Seguro que quiere editar el usuario?",
				icon: <CloseCircleOutlined style={{ color: "red" }} />,
				content: (
					<Paragraph>
						<p>Se va a implementar el siguiente cambio:</p> <br />
						<strong>Correo anterior:</strong> {key.userMetadata.email} <br />
						<strong>Correo nuevo:</strong> {modalInputValue}
						<br />
						<br />
						<strong>
							<font color={COLORS.Danger}>
								Tenga en cuenta que este usuario posee un cliente asociado
							</font>
						</strong>
					</Paragraph>
				),
				okText: "Sí",
				okType: "danger",
				cancelText: "No",
				onOk: () => {
					handleUserEdit();
				},
			});
		} else {
			Modal.confirm({
				title: "Aviso de confirmación",
				icon: <CloseCircleOutlined style={{ color: "orange" }} />,
				content: (
					<Paragraph>
						<p>Se va a implementar el siguiente cambio:</p> <br />
						<strong>Correo anterior:</strong> {key.userMetadata.email} <br />
						<strong>Correo nuevo:</strong> {modalInputValue}
					</Paragraph>
				),
				okText: "Sí",
				okType: "danger",
				cancelText: "No",
				onOk: () => {
					handleUserEdit();
				},
			});
		}
	};

	const handleUserEdit = async () => {
		setSubmitting(true);
		try {
			const res = await update_firebase_user({
				id: key.documentId,
				email: modalInputValue,
			});
			setShowEditModal(false);
		} catch (error) {
		} finally {
			setSubmitting(false);
			setShowEditModal(false);
			setEmailModified((prev) => !prev);
		}
	};

	const handleIsCompleteUserListFilter = (value) => {
		setIsCheckedFilter(value);
		if (!value) {
			const filteredUsers = originalFirestoreUsersList.filter(
				(u) => u.userDbData?.clientName === null
			);
			setFirestoreUsersList(filteredUsers);
		} else {
			setFirestoreUsersList(originalFirestoreUsersList);
		}
	};

	return (
		// <Main>
		// 	<Main.Header>
		// 		<Main.Breadcrumb>
		// 			<Main.BreadcrumbItem>
		// 				<Link to="/">Home</Link>
		// 			</Main.BreadcrumbItem>
		// 			<Main.BreadcrumbSeparator />
		// 			<Main.ActiveBreadcrumbItem>
		// 				Usuarios Firebase
		// 			</Main.ActiveBreadcrumbItem>
		// 		</Main.Breadcrumb>
		// 	</Main.Header>
		// 	<Main.Body>
		<Inner>
			<ImageSection>
				<Container>
					<AntdSearch
						width={400}
						allowClear
						placeholder="Buscar usuario"
						onChange={debouncedEventHandler}
					/>
					<Select
						style={{ width: "200px" }}
						onChange={(e) => selectTypeOfClient(e)}
						defaultValue={clientType === "" ? CONSTANTS.ALL_VALUES : clientType}
					>
						<Select.Option value={CONSTANTS.ALL_VALUES}>Todos</Select.Option>
						{typesOfClients?.map((i) => (
							<Select.Option value={i.value} key={i.key}>
								{i.name}
							</Select.Option>
						))}
					</Select>
					{clientType !== "" && (
						<Select
							style={{ width: "200px" }}
							onChange={(e) => selectTypeOfUser(e)}
							defaultValue={userType === "" ? CONSTANTS.ALL_VALUES : userType}
						>
							<Select.Option value={CONSTANTS.ALL_VALUES}>Todos</Select.Option>
							{typesOfUsers?.map((i) => (
								<Select.Option value={i.value} key={i.key}>
									{i.name}
								</Select.Option>
							))}
						</Select>
					)}
					<Checkbox
						checked={isCheckedFilter}
						onChange={(e) => handleIsCompleteUserListFilter(e.target.checked)}
					>
						Listado de usuarios completo
					</Checkbox>
				</Container>
				<TableContainer>
					<AntdTable
						columns={columns}
						loading={isLoading}
						dataSource={firestoreUsersList}
						pagination={false}
						scroll={{
							y: 500,
						}}
					/>
				</TableContainer>
			</ImageSection>

			<AntdModal
				title={`Usuario: ${key?.firstName} ${key?.lastName}`}
				visible={showEditModal}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1200}
				centered={true}
				footer={false}
				destroyOnClose={true}
			>
				<InnerForm>
					<ClientData>
						<Input
							type="email"
							value={modalInputValue}
							onChange={(e) => setModalInputValue(e.target.value)}
						/>
					</ClientData>
					<PublishSettings>
						<SignInTemplate.AntdButton
							type="Primary"
							color="white"
							bg="Primary"
							loading={submitting}
							onClick={onFinish}
							block
						>
							Guardar Cambios
						</SignInTemplate.AntdButton>
						<SignInTemplate.AntdButton
							type="Danger"
							loading={submitting}
							onClick={handleBack}
							block
						>
							<Typo type="danger" level={6}>
								Volver sin guardar
							</Typo>
						</SignInTemplate.AntdButton>
					</PublishSettings>
				</InnerForm>
			</AntdModal>
		</Inner>

		// 	</Main.Body>
		// </Main>
	);
}
