import React, { useEffect, useState } from "react";
import { Checkbox, Table } from "antd";
import { COLORS } from "../../layout/theme";
import { TableContainer } from "./styles";
import { get_laboratories } from "../../../api/endpoints/clients";
import { FaImages } from "react-icons/fa";
import Image from "../image/Image";

export default function ClientSelector({ initialValues, onChange }) {
	const [client, setClient] = useState(null);

	const columns = [
		{
			title: "",
			dataIndex: "selected",
			render: (value, record, index) => (
				<Checkbox
					checked={value}
					onChange={(e) => handleChange(record.id, e.target.checked)}
				/>
			),
			width: 35,
		},
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			render: (data) => <Image width={40} height={40} src={data} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cliente</div>,
			dataIndex: "name",
			key: "name",
		},
	];

	useEffect(() => {
		const fetch_points_of_sale = async () => {
			const res = await get_laboratories();
			setClient(
				res.map((d) => ({
					...d,
					key: d.id,
					selected: initialValues?.some((a) => a.id === d.id),
				})) || []
			);
		};

		fetch_points_of_sale();
	}, []);

	useEffect(() => {
		onChange(client);
	}, [client]);

	const handleChange = (id, check) => {
		const _pos = client.map((p) =>
			p.id === id ? { ...p, selected: check } : { ...p }
		);
		setClient(_pos);
	};

	if (!client || !initialValues) return <div>Loading...</div>;

	return (
		<>
			{!client ? (
				<div>No hay clientes</div>
			) : (
				<TableContainer>
					<Table
						scroll={{ y: "300px" }}
						size="small"
						columns={columns}
						dataSource={client}
						pagination={false}
					/>
				</TableContainer>
			)}
		</>
	);
}
