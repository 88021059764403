import React, { useState } from "react";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import Dragger from "./Dragger";
import { AntdTable } from "./styles";
import DraggableWrapper from "./DraggableWraper";
import DraggableRow from "./DraggableRow";
import { COLORS, Typo } from "../../layout/theme";

const AntTableDragDropGeneral = ({ columns, data, setData, scroll }) => {
	const [activeId, setActiveId] = useState(null);

	function handleDragStart(event) {
		const { active } = event;
		setActiveId(active.id);
	}

	const handleDragEnd = (event) => {
		const { active, over } = event;
		if (active.id !== over.id) {
			const oldIndex = data.findIndex((item) => item.id === active.id);
			const newIndex = data.findIndex((item) => item.id === over.id);
			const updatedData = [...data];
			const [movedItem] = updatedData.splice(oldIndex, 1);
			updatedData.splice(newIndex, 0, movedItem);
			setData(updatedData);
			setActiveId(null);
		}
	};

	return (
		<DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
			<AntdTable
				dataSource={data}
				columns={[
					{
						dataIndex: "id",
						width: 120,
						render: (id) => <Dragger id={id} />,
					},
					...columns,
				]}
				components={{
					body: {
						wrapper: DraggableWrapper,
						row: DraggableRow,
					},
				}}
				pagination={false}
				scroll={scroll}
			/>
			<DragOverlay>
				<AntdTable
					columns={columns}
					showHeader={false}
					dataSource={
						activeId
							? new Array(1).fill(
									data[data.findIndex((item) => item.id === activeId)]
							  )
							: []
					}
					pagination={false}
				/>
			</DragOverlay>
		</DndContext>
	);
};

export default AntTableDragDropGeneral;
