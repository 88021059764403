import axiosInstance from "../axiosInstance";

const CONTROLLER = "routes";

const get_routes = async (search, clientId, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}&clientId=${clientId || ""}&state=${
			state || ""
		}`
	);
	return response.data;
};

const get_routes_header_info = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetRoutesHeader?clientId=${clientId}`
	);
	return response.data;
};

const update_routes = async (data) => {
	const response = await axiosInstance.post(`${CONTROLLER}/UpdateRoutes`, data);
	return response.data;
};

const add_general_route_by_cuit = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/AddGeneralRouteByCuits`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const get_routes_by_user_id = async (search, userId, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetRoutesByUserId?search=${search || ""}&userId=${
			userId || ""
		}&state=${state || ""}`
	);
	return response.data;
};

const get_routes_by_user_id_header_info = async (userId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetRoutesByUserIdHeader?userId=${userId}`
	);
	return response.data;
};

const update_routes_by_user_id = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/UpdateUserRoutes`,
		data
	);
	return response.data;
};

const add_user_route_by_cuit = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/AddUserRouteByCuits`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const delete_user_route = async (data) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/DeleteUserRoute`,
		data
	);
	return response.data;
};

const export_routes_to_assign = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/ExportRoutesToAssign?clientId=${clientId || ""}`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const update_general_route_state = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateGeneralRouteState`,
		state
	);
	return response.data;
};

const export_laboratory_routes = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/ExportLaboratoryRoutes?clientId=${clientId || ""}`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

export {
	get_routes,
	get_routes_header_info,
	update_routes,
	add_general_route_by_cuit,
	get_routes_by_user_id,
	get_routes_by_user_id_header_info,
	update_routes_by_user_id,
	add_user_route_by_cuit,
	delete_user_route,
	export_routes_to_assign,
	update_general_route_state,
	export_laboratory_routes,
};
