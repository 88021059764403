import styled from "styled-components";
import { Button as ButtonAntd } from "antd";
import { COLORS } from "../../../../../layout/theme";

export const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50vh;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  gap: 20px;
  padding: 40px 30%;
  border-radius: 20px;
`;

export const AntdButton = styled(ButtonAntd)`
  cursor: pointer;

  &:hover {
    color: ${COLORS.Primary};
    border: 2px solid ${COLORS.White};
    background-color: ${COLORS.White};
    box-shadow: 0 1px 1px #bfbfbf;
  }

  background-color: ${COLORS.Secondary};
  color: ${COLORS.White};
  border-radius: 5px;
  box-shadow: 0 3px 3px 0 #c7c7c7;
`;
