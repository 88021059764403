import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import { useMemo, useRef, useState, useEffect } from "react";

function DebounceSelect({
  fetchOptions,
  debounceTimeout = 400,
  initialOptions,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(initialOptions || []);
  const fetchRef = useRef(0);

  useEffect(() => {
    if (initialOptions) return;
    setFetching(true);
    fetchOptions("").then((newOptions) => {
      setOptions(newOptions);
      setFetching(false);
    });
  }, []);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

export default function Debounce({
  fetchCallback,
  placeholder,
  onValuesChange,
  initialValues,
  disabled,
}) {
  const [value, setValue] = useState(initialValues || []);

  useEffect(() => {
    onValuesChange(value);
  }, [value]);

  return (
    <DebounceSelect
      mode="multiple"
      value={value}
      placeholder={placeholder}
      fetchOptions={fetchCallback}
      onChange={(newValue) => {
        setValue(newValue);
      }}
      style={{
        width: "100%",
      }}
      allowClear={true}
      initialOptions={initialValues}
      disabled={disabled}
    />
  );
}
