import React from "react";
import { Input, Form, Row, Col } from "antd";
import { Container, Inner } from "./styles";
import { COLORS, Typo } from "../../../../../../layout/theme";
import AntdCheckbox from "../../../../../../common/antd-checkbox/AntdCheckbox";
import CustomAntdTooltip from "../../../../../../common/custom-antd-tooltip/CustomAntdTooltip";
import { InfoCircleOutlined } from "@ant-design/icons";

const GUTTER = 10;

export default function CouponLimitations({
	form,
	couponLimitationIsActive,
	setCouponLimitationIsActive,
	stricProductValidationIsActive,
	setStrictProductValidationIsActive,
}) {
	return (
		<Inner>
			<Container>
				<Row gutter={GUTTER}>
					<Col span={8}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Descuento
								</label>
							}
							name="discount"
							rules={[
								{
									required: true,
									message: "Este campo es obligatorio",
								},
							]}
						>
							<Input
								type="number"
								min={0}
								step={0.01}
								max={99}
								placeholder="Descuento"
								suffix="%"
							/>
						</Form.Item>
					</Col>
					<Col span={2}></Col>
					<Col span={8}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Cantidad Mínima
								</label>
							}
							name="minQuantity"
							rules={[
								{
									required: true,
									message: "Este campo es obligatorio",
								},
								{
									pattern: new RegExp(
										/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
									),
									message:
										"No se aceptan caracteres especiales. Solo letras y numeros",
								},
							]}
						>
							<Input
								type="number"
								min={0}
								placeholder="Cant. min."
								suffix="un."
							/>
						</Form.Item>
					</Col>
					<Col span={2}></Col>
				</Row>
				<Row gutter={GUTTER}>
					<Col span={12}>
						<Form.Item name="activeLimitations" style={{ flex: "1 1 50%" }}>
							<AntdCheckbox
								onChange={(e) =>
									setStrictProductValidationIsActive(e.target.checked)
								}
								checked={stricProductValidationIsActive}
							>
								<Typo type="primary" level={6}>
									Cupón combo{" "}
									<CustomAntdTooltip
										width="250px"
										tooltipContent={[
											{ title: "Validación" },
											{
												body: "Este check hace obligatoria la presencia de todos los productos en el carro",
											},
										]}
									>
										<InfoCircleOutlined />
									</CustomAntdTooltip>
								</Typo>
							</AntdCheckbox>
						</Form.Item>
					</Col>
				</Row>
			</Container>
			<Container>
				<Row gutter={GUTTER}>
					<Col span={12}>
						<Form.Item name="activeLimitations" style={{ flex: "1 1 50%" }}>
							<AntdCheckbox
								onChange={(e) => setCouponLimitationIsActive(e.target.checked)}
								checked={couponLimitationIsActive}
							>
								<Typo type="primary" level={6}>
									Activar condiciones de cupón
								</Typo>
							</AntdCheckbox>
						</Form.Item>
					</Col>
				</Row>
				{couponLimitationIsActive && (
					<Row gutter={GUTTER}>
						<Col span={8}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Limite de usos
									</label>
								}
								name="limitUses"
								rules={[]}
							>
								<Input type="number" min={0} placeholder="Usos" />
							</Form.Item>
						</Col>
						<Col span={2}></Col>
						<Col span={8}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Limite de unidades
									</label>
								}
								name="limitUnits"
								rules={[]}
							>
								<Input
									type="number"
									min={0}
									placeholder="Unidades"
									suffix="un."
								/>
							</Form.Item>
						</Col>
						<Col span={2}></Col>
					</Row>
				)}
			</Container>
		</Inner>
	);
}
