import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
	Form,
	Input,
	Row,
	Col,
	Space,
	Select,
	Modal,
	Checkbox,
	Typography,
} from "antd";
import Main from "../../../../layout/main/Main";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import {
	AfipCuitInfo,
	AntdButton,
	BlockedData,
	ClientData,
	Container,
	InnerForm,
	PreviewImage,
	PublishSettings,
	RightContainer,
	Section,
} from "./styles";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import DistributorsSelector from "../../../../common/distributos-selector/DistributorsSelector";
import { COLORS, Typo } from "../../../../layout/theme";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	get_client_by_id,
	update_approved,
} from "../../../../../api/endpoints/clients";
import PhoneInput from "../../../../common/phone-input/PhoneInput";
import { create_history_approval } from "../../../../../api/endpoints/historyApprovals";
import { useAuth } from "../../../../../contexts/authContext";
import {
	get_cuit_info,
	send_approved_validation_email,
} from "../../../../../api/endpoints/auth";
import { get_provinces } from "../../../../../api/endpoints/region";
import { CloseCircleOutlined } from "@ant-design/icons";
import { validateCUIT } from "../../../../../utils/cuitValidator";
import { CONSTANTS } from "../../../../../utils/constants";

const { confirm } = Modal;
const { Paragraph } = Typography;
const { Option } = Select;
const GUTTER = 10;

const states = [
	{
		id: "APPROVED",
		name: "Aprobada",
	},
	{
		id: "REJECTED",
		name: "Rechazada",
	},
	{
		id: "PENDING",
		name: "Pendiente",
	},
];

const twoStates = [
	{
		id: "APPROVED",
		name: "Aprobada",
	},
	{
		id: "REJECTED",
		name: "Rechazada",
	},
];

export default function UpdateRequest() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const { updateRequestValidation, userdata, findUserInFirestore } = useAuth();

	const [submitting, setSubmitting] = useState(false);
	const [distributors, setDistributors] = useState(null);
	const [saleDepartments, setSaleDepartments] = useState(null);
	const [initialValues, setInitialValues] = useState(null);
	const [idProvincia, setIdProvincia] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [listadoLocalidades, setListadoLocalidades] = useState(null);
	const [provincia, setProvincia] = useState(null);
	const [localidad, setLocalidad] = useState();
	const [imageUrl, setImageUrl] = useState();
	const [stateList, setStateList] = useState([]);
	const [userDataInFirestore, setUserDataInFirestore] = useState(null);
	const [loading, setLoading] = useState(false);
	const [afipData, setAfipData] = useState(null);
	const [isCuitValid, setIsCuitValid] = useState(null);

	const businessName = Form.useWatch("businessName", form);
	const businessAddress = Form.useWatch("address", form);
	const businessPostalCode = Form.useWatch("postalCode", form);

	useEffect(() => {
		const fetch_client_by_id = async () => {
			const data = await get_client_by_id(id);

			const client = {
				name: data.client.name,
				cuit: data.client.cuit,
				address: data.client.address,
				postalCode: data.client.postalCode,
				businessName: data.client.businessName,
				glnAnmat: data.client.glnAnmat,
				isSeller: data.client.isSeller,
				contactEmail: data.client.contactEmail,
				contactFirstName: data.client.contactName.split(/(\s+)/)[0],
				contactLastName: data.client.contactName.split(/(\s+)/)[2],
				phone: data.client.contactPhone.slice(
					3,
					data.client.contactPhone.length
				),
				phoneAreaCode: data.client.contactPhone.slice(0, 2),
				contactId: data.client.users ? data.client.users[0].id : null,
				provinceName: data.region?.provinceName,
				localityName: data.region?.localityName,
				firstUserApproved: data.client.users
					? data.client.users[0].approved
					: null,
				deleted: data.client.deleted,
			};

			setDistributors(data.distributors);

			setSaleDepartments(
				data.client.saleDepartments?.map((i) => ({
					value: i.saleDepartment.id,
					label: i.saleDepartment.name,
				}))
			);

			setImageUrl(data.client.photoUrl);

			if (!data.client.users[0].approved && !data.client.deleted) {
				setStateList([
					{
						id: "APPROVED",
						name: "Aprobada",
					},
					{
						id: "REJECTED",
						name: "Rechazada",
					},
					{
						id: "PENDING",
						name: "Pendiente",
					},
				]);
			} else {
				setStateList([
					{
						id: "APPROVED",
						name: "Aprobada",
					},
					{
						id: "REJECTED",
						name: "Rechazada",
					},
				]);
			}

			const _userFS = await findUserInFirestore(data?.client?.users[0]?.id);
			setUserDataInFirestore(_userFS);
			setInitialValues(client);
		};

		if (id) {
			fetch_client_by_id();
		}
	}, []);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res
					.map((p) => ({
						id: p.id,
						name: p.title,
						localities: p.localities.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						})),
					}))
					.sort((a, b) => {
						const titleA = a.name.toLowerCase();
						const titleB = b.name.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);

			setListadoLocalidades(res.filter((p) => p.id === idProvincia));
		};

		fetch_region_1_data();
	}, [idProvincia]);

	const onFinish = async (values) => {
		const continueWithApproval = async () => {
			try {
				setSubmitting(true);

				const postData = {
					id: id,
					userId: values.contactId,
					approve: values.state === "APPROVED",
					deleted: values.state === "REJECTED",
				};

				const _history = {
					clientId: id,
					userId: values.contactId,
					changedState: values.state,
					approvalStatus: values.state === "APPROVED",
				};

				await update_approved(postData);
				await updateRequestValidation(postData.userId, postData.approve);
				await create_history_approval(_history);
				if (postData.approve) {
					await send_approved_validation_email(postData.userId);
				}

				openNotificationWithIcon(
					TYPE.SUCCESS,
					"La farmacia se actualizó con éxito"
				);
				navigate(`/admin/marketRequests`);
			} catch (error) {
				console.log(error);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Error al actualizar la farmacia",
					"Un error ocurrio al intentar actualizar la farmacia, contacte con soporte a soporte@onetransfer.com."
				);
			} finally {
				setSubmitting(false);
			}
		};

		if (!!isCuitValid) {
			try {
				if (values.state === "PENDING") {
					setSubmitting(false);
					openNotificationWithIcon(
						TYPE.SUCCESS,
						"La farmacia se actualizó con éxito"
					);
					navigate(`/admin/marketRequests`);
					return;
				}

				if (values.state === "APPROVED") {
					confirm({
						title: "Confirmación de aprobación",
						content: (
							<div>
								{isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR && (
									<Paragraph>
										<strong>El Cuit no posee una estructura válida</strong>
									</Paragraph>
								)}
								{isCuitValid === CONSTANTS.CUIT_STRUCTURE_OK &&
									afipData?.condicionIVA !== CONSTANTS.AFIP_STATUS_OK && (
										<Paragraph>
											<strong>
												Este Cuit no corresponde a un Responsable Inscripto
											</strong>
										</Paragraph>
									)}
								{businessName !== afipData?.razonSocial &&
									afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK && (
										<Paragraph>
											<strong>Razón Social:</strong> {businessName} <br />
											<strong>Dato Afip:</strong> {afipData?.razonSocial}
										</Paragraph>
									)}
								{businessAddress !== afipData?.fiscalDireccion &&
									afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK && (
										<Paragraph>
											<strong>Dirección:</strong> {businessAddress} <br />
											<strong>Dato Afip:</strong> {afipData?.fiscalDireccion}
										</Paragraph>
									)}
								{businessPostalCode !== afipData?.fiscalCodPostal &&
									afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK && (
										<Paragraph>
											<strong>CP:</strong> {businessPostalCode} <br />
											<strong>Dato Afip:</strong> {afipData?.fiscalCodPostal}
										</Paragraph>
									)}
								{isCuitValid === CONSTANTS.CUIT_STRUCTURE_OK &&
									afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK &&
									businessName === afipData?.razonSocial &&
									businessAddress === afipData?.fiscalDireccion &&
									businessPostalCode === afipData?.fiscalCodPostal && (
										<Paragraph>
											<strong>No hay advertencias</strong>
										</Paragraph>
									)}
							</div>
						),
						okText: "Confirmar",
						okType: "danger",
						cancelText: "Cancelar",
						onOk: continueWithApproval,
						onCancel: () => {
							setSubmitting(false);
							return;
						},
					});
				} else {
					await continueWithApproval();
				}
			} catch (error) {
				console.log(error);
				setSubmitting(false);
			}
		} else {
			alert("Debe validar el Cuit antes de continuar");
			setSubmitting(false);
			return;
		}
	};

	const handleBack = () => {
		confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/marketRequests");
			},
		});
	};

	const fetch_afip_check = async (cuit) => {
		const data = await get_cuit_info(cuit);
		setAfipData(data?.cuitInfo);
	};

	const handleCuitCheck = () => {
		setLoading(true);
		try {
			if (validateCUIT(initialValues.cuit)) {
				setIsCuitValid(CONSTANTS.CUIT_STRUCTURE_OK);
				fetch_afip_check(initialValues.cuit);
			} else {
				setIsCuitValid(CONSTANTS.CUIT_STRUCTURE_ERROR);
			}
		} catch (error) {
			console.log(error);
			setIsCuitValid(CONSTANTS.CUIT_STRUCTURE_ERROR);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/marketRequests">Solicitudes</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Editar Solicitud
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<ClientData>
								<BlockedData>
									<Space
										direction="vertical"
										size="middle"
										style={{ display: "flex" }}
									>
										<Row gutter={GUTTER}>
											<Col span={6}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Logo
														</label>
													}
												>
													{imageUrl && (
														<PreviewImage src={imageUrl} alt="avatar" />
													)}
												</Form.Item>
											</Col>
										</Row>

										<Row gutter={GUTTER}>
											<Col span={8}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Nombre
														</label>
													}
													name="name"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														{
															pattern: new RegExp(
																/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
															),
															message:
																"No se aceptan caracteres especiales. Solo letras y numeros",
														},
													]}
												>
													<Input placeholder="Nombre" />
												</Form.Item>
											</Col>
											<Col span={8}>
												<BlockedData>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																CUIT
															</label>
														}
														name="cuit"
														rules={[
															{
																required: true,
																message: "Este campo es obligatorio",
															},
															{
																pattern: new RegExp(/^[0-9\s]*$/),
																message: "Solo numeros positivos",
															},
														]}
														help={
															isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
																? "CUIT inválido"
																: afipData
																? afipData?.condicionIVA ===
																  CONSTANTS.AFIP_STATUS_OK
																	? ""
																	: "No es responsable inscripto"
																: null
														}
														validateStatus={
															isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
																? "error"
																: afipData
																? afipData?.condicionIVA ===
																  CONSTANTS.AFIP_STATUS_OK
																	? "success"
																	: "error"
																: ""
														}
														hasFeedback
													>
														<Input placeholder="Cuit" />
													</Form.Item>
												</BlockedData>
											</Col>
											<Col span={8}>
												<BlockedData>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Razon Social
															</label>
														}
														name="businessName"
														help={
															isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
																? "CUIT inválido"
																: afipData
																? afipData?.razonSocial === businessName
																	? ""
																	: afipData?.condicionIVA !==
																	  CONSTANTS.AFIP_STATUS_OK
																	? "No es un responsable inscripto"
																	: "No coindicide con dato de Afip"
																: null
														}
														validateStatus={
															isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
																? "error"
																: afipData
																? afipData?.razonSocial === businessName
																	? "success"
																	: "error"
																: ""
														}
														hasFeedback
													>
														<Input placeholder="Razon Social" />
													</Form.Item>
												</BlockedData>
											</Col>
										</Row>

										<Row gutter={GUTTER}>
											<Col span={12}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Dirección
														</label>
													}
													name="address"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														{
															pattern: new RegExp(
																/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
															),
															message:
																"No se aceptan caracteres especiales. Solo letras y numeros",
														},
													]}
													help={
														isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
															? "CUIT inválido"
															: afipData
															? afipData?.fiscalDireccion === businessAddress
																? ""
																: afipData?.condicionIVA !==
																  CONSTANTS.AFIP_STATUS_OK
																? "No es un responsable inscripto"
																: "No coindicide con dato de Afip"
															: null
													}
													validateStatus={
														isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
															? "error"
															: afipData
															? afipData?.fiscalDireccion === businessAddress
																? "success"
																: "error"
															: ""
													}
													hasFeedback
												>
													<Input placeholder="Dirección" />
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Código Postal
														</label>
													}
													name="postalCode"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														{
															pattern: new RegExp(
																/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
															),
															message:
																"No se aceptan caracteres especiales. Solo letras y numeros",
														},
													]}
													help={
														isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
															? "CUIT inválido"
															: afipData
															? afipData?.fiscalCodPostal === businessPostalCode
																? ""
																: afipData?.condicionIVA !==
																  CONSTANTS.AFIP_STATUS_OK
																? "No es un responsable inscripto"
																: "No coindicide con dato de Afip"
															: null
													}
													validateStatus={
														isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
															? "error"
															: afipData
															? afipData?.fiscalCodPostal === businessPostalCode
																? "success"
																: "error"
															: ""
													}
													hasFeedback
												>
													<Input placeholder="Código Postal" />
												</Form.Item>
											</Col>
										</Row>

										<Row gutter={GUTTER}>
											<Col span={12}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Provincia
														</label>
													}
													name="provinceName"
												>
													<Input placeholder="Provincia" />
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Localidad
														</label>
													}
													name="localityName"
												>
													<Input placeholder="Localidad" />
												</Form.Item>
											</Col>
										</Row>

										<Row gutter={GUTTER}>
											<Col span={12}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Nombre del representante
														</label>
													}
													name="contactFirstName"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														{
															pattern: new RegExp(
																/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
															),
															message:
																"No se aceptan caracteres especiales. Solo letras y numeros",
														},
													]}
												>
													<Input placeholder="Nombre del representante" />
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Apellido del representante
														</label>
													}
													name="contactLastName"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
														{
															pattern: new RegExp(
																/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
															),
															message:
																"No se aceptan caracteres especiales. Solo letras y numeros",
														},
													]}
												>
													<Input placeholder="Apellido del representante" />
												</Form.Item>
											</Col>
											<Col span={1}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Id
														</label>
													}
													name="contactId"
													style={{ display: "none" }}
												>
													<Input placeholder="Id" />
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={GUTTER}>
											<Col span={12}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															{userDataInFirestore?.emailVerified ||
															userDataInFirestore?.completeUserData ? (
																<Typo type="success" level={6}>
																	Correo de Contacto verificado
																</Typo>
															) : (
																<Typo type="danger" level={6}>
																	Correo de Contacto sin verificar
																</Typo>
															)}
														</label>
													}
													name="contactEmail"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													<Input placeholder="Correo de Contacto" />
												</Form.Item>
											</Col>
											<Col span={12}>
												<PhoneInput
													form={form}
													size="default"
													label="Teléfono de Contacto"
													labelStyle={{
														color: COLORS.Primary,
														fontWeight: "600",
													}}
												/>
											</Col>
										</Row>

										<Row>
											<Col span={16}>
												<Form.Item name="distributors">
													<DistributorsSelector
														onChange={setDistributors}
														initialValues={distributors}
														noBuyer={initialValues.isSeller}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Space>
								</BlockedData>
							</ClientData>
							<RightContainer>
								<PublishSettings>
									<Space
										direction="vertical"
										size="middle"
										style={{ display: "flex" }}
									>
										<Typo level={5} type="primary">
											Estado
										</Typo>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Solicitud de Farmacia
												</label>
											}
											name="state"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Select
												allowClear={true}
												placeholder="Estado de la farmacia"
											>
												{stateList.map((item) => (
													<Option key={item.id} value={item.id}>
														{item.name}
													</Option>
												))}
											</Select>
										</Form.Item>

										<Form.Item>
											<SignInTemplate.AntdButton
												type="Primary"
												color="white"
												bg="Primary"
												htmlType="submit"
												loading={submitting}
												block
											>
												Guardar Cambios
											</SignInTemplate.AntdButton>
										</Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											loading={submitting}
											onClick={handleBack}
											block
										>
											<Typo type="danger" level={6}>
												Volver sin guardar
											</Typo>
										</SignInTemplate.AntdButton>
									</Space>
								</PublishSettings>
								<AfipCuitInfo>
									<AntdButton onClick={handleCuitCheck} loading={loading}>
										Verificar Cuit
									</AntdButton>
									{isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR && (
										<Typo level={6}>No es una estructura de Cuit válida</Typo>
									)}
									{isCuitValid === CONSTANTS.CUIT_STRUCTURE_OK && afipData && (
										<Container>
											<Section>
												<Typo type="primary" level={6}>
													Condición:{" "}
												</Typo>
												<Typo
													type={
														afipData.condicionIVA === CONSTANTS.AFIP_STATUS_OK
															? "success"
															: "warning"
													}
													level={6}
												>
													{afipData.condicionIVA}
												</Typo>
											</Section>
											<Section>
												<Typo type="primary" level={6}>
													Cuit:{" "}
												</Typo>
												<Typo level={6}>{afipData.cuit}</Typo>
											</Section>
											<Section>
												<Typo type="primary" level={6}>
													Razón Social:{" "}
												</Typo>
												<Typo level={6}>{afipData.razonSocial}</Typo>
											</Section>
											<Section>
												<Typo type="primary" level={6}>
													Dirección Fiscal:{" "}
												</Typo>
												<Typo level={6}>{afipData.fiscalDireccion}</Typo>
											</Section>
											<Section>
												<Typo type="primary" level={6}>
													CP Fiscal:{" "}
												</Typo>
												<Typo level={6}>{afipData.fiscalCodPostal}</Typo>
											</Section>
										</Container>
									)}
								</AfipCuitInfo>
							</RightContainer>
						</InnerForm>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
