import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../../../../layout/main/Main";
import { Space, Radio, Menu, Dropdown, Modal } from "antd";
import {
	CloseCircleOutlined,
	PlusOutlined,
	InfoCircleOutlined,
} from "@ant-design/icons";
import { ActionButton, Filters } from "./styles";
import { COLORS, Typo } from "../../../../../layout/theme";
import DropShadow from "../../../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import DataTable from "../../../../../common/data-table/DataTable";
import debounce from "lodash/debounce";
import {
	get_campaigns_header_info,
	get_campaigns_page,
	update_campaign_state,
} from "../../../../../../api/endpoints/campaigns";
import LoadingHeader from "./components/LoadingHeader";
import { BsThreeDotsVertical } from "react-icons/bs";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";

const { confirm } = Modal;

export default function CampaignList() {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [state, setState] = useState("PUBLISHED");
	const [headerInfo, setHeaderInfo] = useState(null);
	const [key, setKey] = useState(null);

	const menuActive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/advertising/campaigns/${key}`}>
							<Typo type="secondary">Ver</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						<Typo type="secondary" onClick={() => handlePauseCampaign(key)}>
							Pausar
						</Typo>
					),
				},
				{
					key: "3",
					label: (
						<Typo type="secondary" onClick={() => handleDeleteCampaign(key)}>
							Borrar
						</Typo>
					),
				},
			]}
		/>
	);

	const menuPaused = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/advertising/campaigns/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						<Typo type="secondary" onClick={() => handleActivateCampaign(key)}>
							Activar
						</Typo>
					),
				},
				{
					key: "3",
					label: (
						<Typo type="secondary" onClick={() => handleDeleteCampaign(key)}>
							Borrar
						</Typo>
					),
				},
			]}
		/>
	);

	const menuFuture = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/advertising/campaigns/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						<Typo type="secondary" onClick={() => handlePauseCampaign(key)}>
							Pausar
						</Typo>
					),
				},
				{
					key: "3",
					label: (
						<Typo type="secondary" onClick={() => handleDeleteCampaign(key)}>
							Borrar
						</Typo>
					),
				},
			]}
		/>
	);

	const notMenu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/advertising/campaigns/${key}`}>
							<Typo type="secondary">Ver</Typo>
						</Link>
					),
				},
			]}
		/>
	);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Campaña</div>,
			dataIndex: "title",
			render: (value, record) => (
				<Link
					to={`/admin/advertising/campaigns/${key?.id}`}
					onMouseOver={() => setKey(record)}
				>
					<Typo type="terciary" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Link>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cliente</div>,
			dataIndex: "client",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value.name}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>% de Campaña</div>,
			dataIndex: "campaignOffer",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value} %
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Fecha comienzo</div>,
			dataIndex: "publishFrom",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila ? fila.split("T")[0] : ""}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Fecha finalización</div>
			),
			dataIndex: "publishTo",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila ? fila.split("T")[0] : ""}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={
							state === "PUBLISHED"
								? menuActive
								: state === "PAUSED"
								? menuPaused
								: state === "FUTURE"
								? menuFuture
								: notMenu
						}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const fetch_campaigns = async (page, search) => {
		const res = await get_campaigns_page(search, state, page);
		setData(res);
	};
	const fetch_campaigns_header_info = async () => {
		const res = await get_campaigns_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_campaigns(1);
		fetch_campaigns_header_info();
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_campaigns(1, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleAction = async (key, state) => {
		const _state = { id: key, state: state };
		await update_campaign_state(_state);
		fetch_campaigns_header_info();
		fetch_campaigns(1, "");
	};

	const handlePauseCampaign = (key) => {
		confirm({
			title: "¿Seguro que quiere pausar la campaña?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "Pausar la campaña se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "PAUSED");
			},
		});
	};

	const handleDeleteCampaign = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar la campaña?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "Eliminar la campaña se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "DELETED");
			},
		});
	};
	const handleActivateCampaign = (key) => {
		confirm({
			title: "¿Seguro que quiere activar la campaña?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "Activar la campaña se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "ACTIVE");
			},
		});
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Campañas</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<AntdSearch
								width={400}
								allowClear
								placeholder="Buscar campaña"
								onChange={debouncedEventHandler}
							/>

							<Radio.Group
								value={state}
								onChange={(e) => setState(e.target.value)}
							>
								<Radio.Button value="PUBLISHED">{`Activas (${headerInfo.totalPublished})`}</Radio.Button>
								<Radio.Button value="PAUSED">{`Pausadas (${headerInfo.totalPaused})`}</Radio.Button>
								<Radio.Button value="FUTURE">{`Futuras (${headerInfo.totalFuture})`}</Radio.Button>
								<Radio.Button value="FINISHED">{`Finalizadas (${headerInfo.totalFinished})`}</Radio.Button>
								<Radio.Button value="DELETED">{`Borradas (${headerInfo.totalDeleted})`}</Radio.Button>
							</Radio.Group>

							<DropShadow type="drop">
								<SignInTemplate.AntdButton
									type="primary"
									bg="Primary"
									color="White"
									icon={<PlusOutlined />}
									onClick={() =>
										navigate("/admin/advertising/campaigns/createCampaign")
									}
									style={{ width: "200px", fontWeight: "400" }}
								>
									Crear Campaña
								</SignInTemplate.AntdButton>
							</DropShadow>
						</Space>
					)}
				</Filters>
				<DataTable data={data} fetch={fetch_campaigns} columns={columns} />
			</Main.Body>
		</Main>
	);
}
