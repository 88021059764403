import styled from "styled-components/macro";
import { COLORS } from "../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
export const ProductData = styled.div`
	flex: 1 1 60%;
	width: 950px;
`;

export const TableHeader = styled.div`
	display: flex;
	align-items: center;
	width: 1200px;
	height: 130px;
	border-radius: 10px;
	background-color: ${COLORS.SkeletonGrey};
`;

export const CheckBoxDiv = styled.div`
	height: 20px;
	width: 20px;
	background-color: ${COLORS.SkeletonGrey};
	border: 2px solid ${COLORS.Grey};
`;
