import { Button } from "antd";
import styled from "styled-components";
import { COLORS } from "../../../layout/theme";

export const ImageSection = styled.div`
	flex: 1 1 60%;
	height: 100%;
	width: 100%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 10px 20px 20px 20px;
	align-items: center;
`;

export const PublishSection = styled.div`
	flex: 1 1 30%;
	background-color: ${COLORS.White};
	padding: 10px 20px 20px 20px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 60%;
	}
`;

export const Inner = styled.div`
	display: flex;
	gap: 40px;
	margin-top: 30px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: column;
	}
`;

export const StoreAntdButton = styled(Button)`
	cursor: pointer;

	&:hover {
		color: ${COLORS.Primary};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}

	background-color: ${COLORS.Primary};
	color: ${COLORS.White};
	border-radius: 5px;
	box-shadow: 0 3px 3px 0 #c7c7c7;
`;

export const AlertContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	padding: 1rem;
`;
