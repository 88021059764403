function checkRolPermission(user, client) {
	if (client.isOneMarket) {
		if (
			user.p_client &&
			user.p_users &&
			user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			!user.p_shop &&
			!user.p_mobile
		) {
			return "MASTERADMINOT";
		}
		if (
			!user.p_client &&
			!user.p_users &&
			!user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			user.p_shop &&
			!user.p_mobile
		) {
			return "SELLEROT";
		}
		if (
			!user.p_client &&
			!user.p_users &&
			!user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			user.p_shop &&
			user.p_mobile
		) {
			return "SELLERMOBILEOT";
		}
		if (
			!user.p_client &&
			!user.p_users &&
			!user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			!user.p_shop &&
			!user.p_mobile
		) {
			return "ANALIST";
		}
		if (
			user.p_client &&
			!user.p_users &&
			user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			!user.p_shop &&
			!user.p_mobile
		) {
			return "CLIENTADMINOT";
		}
	} else if (client.isSeller) {
		if (
			user.p_client &&
			user.p_users &&
			user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			!user.p_shop &&
			!user.p_mobile
		) {
			return "MASTERADMINLAB";
		}
		if (
			!user.p_client &&
			!user.p_users &&
			user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			!user.p_shop &&
			!user.p_mobile
		) {
			return "CLIENTADMINLAB";
		}
		if (
			!user.p_client &&
			!user.p_users &&
			!user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			!user.p_shop &&
			!user.p_mobile
		) {
			return "ANALIST";
		}
		if (
			!user.p_client &&
			!user.p_users &&
			!user.p_products &&
			!user.p_dashboard &&
			!user.p_sales &&
			!user.p_shop &&
			user.p_mobile
		) {
			return "SELLERMOBILELAB";
		}
		if (
			!user.p_client &&
			!user.p_users &&
			!user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			user.p_shop &&
			!user.p_mobile
		) {
			return "SELLERWEBLAB";
		}
		if (
			!user.p_client &&
			!user.p_users &&
			!user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			user.p_shop &&
			user.p_mobile
		) {
			return "SELLEREXPOLAB";
		}
	} else {
		if (
			user.p_client &&
			user.p_users &&
			user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			user.p_shop &&
			!user.p_mobile
		) {
			return "MASTERADMIN";
		}
		if (
			!user.p_client &&
			!user.p_users &&
			user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			user.p_shop &&
			!user.p_mobile
		) {
			return "SELLER";
		}
		if (
			!user.p_client &&
			!user.p_users &&
			!user.p_products &&
			user.p_dashboard &&
			user.p_sales &&
			!user.p_shop &&
			!user.p_mobile
		) {
			return "ANALIST";
		}
		if (
			!user.p_client &&
			!user.p_users &&
			user.p_products &&
			!user.p_dashboard &&
			user.p_sales &&
			!user.p_shop &&
			!user.p_mobile
		) {
			return "DEPENDANT";
		}
	}
}

function setRolName(rol) {
	switch (rol) {
		case "MASTERADMIN":
			return "Administrador General";

		case "ANALIST":
			return "Analista";

		case "SELLER":
			return "Comprador";

		case "DEPENDANT":
			return "Dependiente";

		case "MASTERADMINLAB":
			return "Administrador General";

		case "CLIENTADMINLAB":
			return "Administrador de Cuenta";

		case "SELLERMOBILELAB":
			return "Vendedor Mobile";

		case "SELLERWEBLAB":
			return "Vendedor Web";

		case "SELLEREXPOLAB":
			return "Vendedor Expo";

		case "MASTERADMINOT":
			return "Administrador General";

		case "CLIENTADMINOT":
			return "Administrador de Cuenta";

		case "SELLEROT":
			return "Vendedor";

		case "SELLERMOBILEOT":
			return "Vendedor Mobile";

		default:
			break;
	}
}

export { checkRolPermission, setRolName };
