import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Typo } from "../../../../../layout/theme";
import {
	AntdModal,
	Inner,
	LeftContainer,
	MoreInfo,
	RightContainer,
	Section,
} from "./styles";

export default function HotSaleModalFooter({
	campaignRegion,
	campaignDistributors,
	campaignTitle,
}) {
	const [showFooterInfoModal, setShowFooterInfoModal] = useState(false);

	const handleClick = () => {
		setShowFooterInfoModal(true);
	};

	const handleOk = () => {
		setShowFooterInfoModal(false);
	};

	const handleCancel = () => {
		setShowFooterInfoModal(false);
	};

	const region = campaignRegion.map((x) =>
		x.localitieName
			? x.localitieName + " ( " + x.provinceName + " ) - "
			: x.provinceName
			? "Todas las localidades ( " + x.provinceName + " ) - "
			: "Todas las localidades ( Argentina ) - "
	);

	const distributors = campaignDistributors.map(
		(x) => x.distributor?.name + " - "
	);

	return (
		<Inner>
			<LeftContainer onClick={handleClick}>
				<AiOutlineInfoCircle style={{ height: 20, width: 20 }} />
			</LeftContainer>
			<RightContainer>
				<Section>
					<Typo type="primary" level={6}>
						Región:{" "}
					</Typo>
					<Typo type="muted" level={7}>
						{region.length < 4 ? region : region.slice(0, 3)}
					</Typo>
					{region.length > 3 && (
						<MoreInfo onClick={handleClick}>Ver más</MoreInfo>
					)}
				</Section>
				<Section>
					<Typo type="primary" level={6}>
						Droguería:{" "}
					</Typo>
					<Typo type="muted" level={7}>
						{distributors.length < 7 ? distributors : distributors.slice(0, 3)}
					</Typo>
					{distributors.length > 6 && (
						<MoreInfo onClick={handleClick}>Ver más</MoreInfo>
					)}
				</Section>
			</RightContainer>
			<AntdModal
				title={`Condiciones de campaña ${campaignTitle}`}
				visible={showFooterInfoModal}
				onOk={handleOk}
				onCancel={handleCancel}
				width={800}
				centered={true}
				footer={null}
				destroyOnClose={true}
			>
				<Typo type="primary" level={5}>
					Regiones alcanzadas:{" "}
				</Typo>
				<div>{region}</div>
				<Typo type="primary" level={5}>
					Droguerías:{" "}
				</Typo>
				<div>{distributors}</div>
			</AntdModal>
		</Inner>
	);
}
