import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;
export const LeftSection = styled.div`
	flex: 1 1 65%;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const RightSection = styled.div`
	flex: 1 1 35%;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const PublishSettings = styled.div`
	flex: 0 0 30%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
`;

export const SectionData = styled.div`
	flex: 1;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const Container = styled.div`
	cursor: pointer;
	display: flex;
	gap: 10px;
	align-items: center;
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level6};
	color: ${COLORS.DarkGrey};
`;

export const BlockedData = styled.div`
	pointer-events: none;
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
`;
