import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Inner } from "./styles";
import { Button, Input } from "antd";

export default function ProductTriggerQuantity({ product, onChange }) {
	const handleDecrement = () => {
		if (product.quantity > 1)
			onChange({ index: product.index, value: product.quantity - 1 });
	};

	const handleChange = (e) => {
		if (e.target.value <= 0) {
			e.target.value = 1;
			e.target.setSelectionRange(0, 0);
			e.target.focus();
			e.target.select();
		}
		if (e.target.value >= 999) e.target.value = 999;
		const onlyPositiveInteger = /^[0-9\b]+$/;
		if (onlyPositiveInteger.test(e.target.value))
			onChange({ index: product.index, value: e.target.value });
	};

	const handleIncrement = (e) => {
		if (product.quantity >= 999) product.quantity = 998;
		onChange({ index: product.index, value: product.quantity + 1 });
	};

	const handleFocus = (event) => event.target.select();

	return (
		<Inner>
			{/* <Button
				size="small"
				shape="circle"
				onClick={handleDecrement}
				disabled={product.quantity <= 1}
			>
				<MinusOutlined />
			</Button> */}
			<Input
				value={product.quantity}
				onChange={handleChange}
				onFocus={handleFocus}
			/>
			{/* <Button size="small" shape="circle" onClick={handleIncrement}>
				<PlusOutlined />
			</Button> */}
		</Inner>
	);
}
