import styled from "styled-components/macro";
import img from "../../../../../../assets/images/libreta-8.png";
import { Upload } from "antd";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin-top: 30px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: column;
	}
`;

export const ProductsSection = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 30px;
	overflow: hidden;
	padding: 0 30px 0 30px;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 50%;
	}
`;

export const BookContainer = styled.div`
	min-heigth: 650px;
	height: 650px;
	max-height: 650px;
	width: 100%;
	padding: 30px 1.2% 0px 13.5px;
	position: sticky;
	border-radius: 10px;
	background-image: url(${img});
	background-color: ${COLORS.White};
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 50%;
	}
	text-align: center;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	gap: 20px;
	min-height: 50px;
	${(props) =>
		props.condition &&
		`
      background-color: ${COLORS.White};
      border-radius: 10px;
	    width: 260px;
    `}
`;

export const SearchContainer = styled.div`
	flex: 1 1 40%;
	align-items: center;
	align-content: center;
	display: flex;
	flex-direction: column;
	align-content: space-evenly;
	border-raidus: 50px;

	.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}
`;

export const CompaginationDiv = styled.div`
	display: flex;
	gap: 4px;
`;

export const CartDelete = styled.div`
	color: ${COLORS.Grey};
	font-family: sans-serif;
	font-stretch: ultra-expanded;
	font-size: ${SIZES.level12};
	font-weight: ${WEIGHT.Bold};
`;

export const PriceInfoContainer = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: space-around;
`;

export const PriceAndDiscountContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
`;

export const UploadFile = styled(Upload)`
	width: 100%;
	min-height: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 2px solid ${COLORS.LightGrey};
	border-radius: 10px;

	& span {
		color: ${COLORS.Primary};
	}

	position: relative;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: row;
	}
`;

export const Container = styled.div`
	display: flex;
	width: 70%;
	justify-content: flex-start;
	gap: 10px;
	align-items: center;
	color: ${COLORS.Primary};
	cursor: pointer;
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const ConnectionContainer = styled.div`
	position: absolute;
	left: 130px;
`;

export const BookHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px 0 10px;
`;

export const TableInfoContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
