import React from "react";
import { Checkbox, Col, Form, Row } from "antd";
import { Inner } from "./styles";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import { Typo } from "../../../../../layout/theme";

const SECTION_NAME = "saledepartments";
const GUTTER = 7;

export default function SalesDepartments({ form, initialValues }) {
	return (
		<Inner>
			<Typo level={4} type="primary">
				Selección de rubros de compra
			</Typo>
			<SignInTemplate.Break height="15px" />
			<Form.List name={SECTION_NAME}>
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, ...restField }) => (
							<Row gutter={GUTTER} align="bottom" key={key}>
								<Col align="start">
									<Form.Item
										{...restField}
										name={[name, "selected"]}
										valuePropName="checked"
										initialValue={false}
									>
										<Checkbox />
									</Form.Item>
								</Col>
								<Col align="start">
									<Form.Item {...restField} name={[name, "name"]}>
										<Typo level={5}>{initialValues[key].name}</Typo>
									</Form.Item>
								</Col>
							</Row>
						))}
					</>
				)}
			</Form.List>
		</Inner>
	);
}
