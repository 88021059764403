import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../layout/theme";

export const PreviewImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: contain;
`;

export const ButtonSubmit = styled.button`
	cursor: pointer;

	&:hover {
		color: ${COLORS.Primary};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}
	color: ${COLORS.White};
	font-size: ${SIZES.level7};
	font-weight: ${WEIGHT.Black};
	background-color: ${COLORS.Primary};
	margin: 1em;
	padding: 0.2em 1em;
	border: 2px solid ${COLORS.Primary};
	border-radius: 20px;
	box-shadow: 0 5px 5px #bfbfbf;
	margin-left: 40px;
`;

export const GoogleAuth = styled.div`
	margin-top: 8px;
	border: 1px solid rgb(217 217 217);
	padding: 8px;
	& .ant-form-item {
		margin-bottom: 0;
	}
`;
export const SmsAuth = styled.div`
	border-style: none solid solid solid;
	border-width: 1px;
	border-color: rgb(217 217 217);
	padding: 8px;
	& .ant-form-item {
		margin-bottom: 0;
	}
`;

export const Info = styled.div``;

export const Inner = styled.div`
	display: flex;
	gap: 40px;
	margin-top: 30px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: column;
	}
`;

export const DataSection = styled.div`
	flex: 1 1 60%;
	width: 100%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	padding-top: 1rem;
	display: flex;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 40%;
	}
`;

export const ConectivitySection = styled.div`
	flex: 1 1 40%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	padding-top: 1rem;
	display: flex;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 60%;
	}
`;

export const Container = styled.div`
	padding-top: 1rem;
	display: flex;
`;

export const LeftContainer = styled.div`
	width: 100%;
	padding: 0 20px 0 20px;
`;

export const RightContainer = styled.div`
	width: 100%;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 20px;
	justify-content: center;
	align-items: center;
	align-content: center;
	margin-top: 30px;
`;

export const WelcomeSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-content: center;
	justify-content: center;
	gap: 5px;
`;

export const WelcomeTitle = styled.div`
	color: ${COLORS.Primary};
	font-size: ${SIZES.level4};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const WelcomeSubTitle = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;
