import React from "react";
import { Inner } from "./styles";
import { Input } from "antd";

export default function CommisionQuantity({ product, onChange }) {
	const handleChange = (e) => {
		if (e.target.value <= 0) {
			e.target.value = 1;
			e.target.setSelectionRange(0, 0);
			e.target.focus();
			e.target.select();
		}
		if (e.target.value >= 999) e.target.value = 999;
		const onlyPositiveInteger = /^[0-9\b]+$/;
		if (onlyPositiveInteger.test(e.target.value))
			onChange({ index: product.index, value: e.target.value });
	};

	const handleFocus = (event) => event.target.select();

	return (
		<Inner>
			<Input
				value={product.commision}
				onChange={handleChange}
				onFocus={handleFocus}
				suffix="%"
				style={{ width: 80 }}
			/>
		</Inner>
	);
}
