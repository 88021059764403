import { Table } from "antd";
import LoadingSkeleton from "./LoadingSkeleton";

export default function CategoryTable({ data, fetch, columns }) {
  if (!data) return <LoadingSkeleton />;

  return (
    <Table
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={data}
      pagination={false}
    />
  );
}
