import styled from "styled-components";
import { Radio, Input } from "antd";
import { COLORS, WEIGHT } from "../../../../layout/theme";

export const Filters = styled.div`
	margin: 20px 0;

	& button {
		padding: 0 !important;
	}
`;

export const RadioOptions = styled.div`
	margin-bottom: 20px;
`;

export const AntdRadioButton = styled(Radio.Button)`
	border: none;
	font-weight: ${WEIGHT.Bold};
`;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const TierContainer = styled.div`
	cursor: pointer;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
`;

export const CompaginationDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
`;

export const AntdInput = styled(Input)`
	.ant-input-group .ant-input {
		border: 1px solid ${COLORS.Grey};
		border-radius: 10px;
		background-color: transparent;
		text-align: end;
		padding: 5px;
	}

	.ant-input-group .ant-input:hover {
		border: 1px solid ${COLORS.Secondary};
		border-radius: 10px;
	}

	.ant-input-group .ant-input:focus {
		border-radius: 10px;
	}

	.ant-input-group-addon {
		border: 0;
		background-color: transparent;
		padding: 5px;
	}
`;
