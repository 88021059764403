import styled from "styled-components";
import { COLORS } from "../../../../../../../../layout/theme";

export const Card = styled.div`
	background-color: ${COLORS.White};
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
	display: flex;
	gap: 20px;
`;

export const Section = styled.div`
	display: flex;
	flex: 1;
	justify-content: start;
	align-items: center;
	flex-direction: column;
	gap: 20px;
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 100%;
`;
