import React from "react";
import {
	LinkContainer,
	Info,
	InfoBody,
	InfoHeader,
	Inner,
	TopContainer,
	SectionContainer,
} from "./styles";

const StoreSearchCard = ({ children, ...restProps }) => (
	<Inner {...restProps}>{children}</Inner>
);

export default StoreSearchCard;

StoreSearchCard.Top = ({ children, ...restProps }) => {
	return <TopContainer {...restProps}>{children}</TopContainer>;
};

StoreSearchCard.Section = ({ children, ...restProps }) => {
	return <SectionContainer {...restProps}>{children}</SectionContainer>;
};

StoreSearchCard.Info = ({ children, ...restProps }) => {
	return <Info {...restProps}>{children}</Info>;
};

StoreSearchCard.Info.Header = ({ children, ...restProps }) => {
	return <InfoHeader {...restProps}>{children}</InfoHeader>;
};

StoreSearchCard.Info.Body = ({ children, ...restProps }) => {
	return <InfoBody {...restProps}>{children}</InfoBody>;
};

StoreSearchCard.LinkContainer = ({ children, ...restProps }) => {
	return <LinkContainer {...restProps}>{children}</LinkContainer>;
};
