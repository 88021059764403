import React from "react";
import Box from "../box/Box";
import { COLORS, Typo } from "../../../../../layout/theme";
import { BiBox, BiDollar, BiCoinStack } from "react-icons/bi";
import {
	AntdTable,
	BigChartContainer,
	Container,
	Dashboard1Container,
	DoubleChartContainer,
	FilterContainer,
	HeaderContainer,
} from "./styles";
import { Button, DatePicker, Select } from "antd";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../../../../utils/constants";
import Image from "../../../../../common/image/Image";
import NoImageUser from "../../../../../../assets/images/SinImagenDeUsuario.jpeg";

export default function Dashboard3({
	dashboard,
	distributors,
	selectDistributor,
	provinces,
	selectProvince,
	laboratories,
	selectLaboratory,
	handleDateChange,
	deleteFilters,
	isLoading,
	selectedLaboratory,
	selectedDistributor,
	selectedProvince,
	posOrderingGraph,
	sellersTable,
	timeOrdersGraph,
	distributorSalesGraph,
	distributorUnitsGraph,
	sellers,
	selectSeller,
	selectedSeller,
	distributorOrdersGraph,
	distributorGraphSelector,
	selectDistributorGraph,
	selectPeriod,
	rangeDate,
	mobilePeriod,
	productUnitsGraph,
	productSalesGraph,
	productGraphSelector,
	selectProductGraph,
}) {
	const columns = [
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}></div>,
			dataIndex: "sellerPhotoUrl",
			align: "center",
			width: 120,
			render: (value, record, index) => (
				<Image
					width={50}
					height={50}
					src={value ? value : NoImageUser}
					alt={record.sellerFirstName ? record.sellerFirstName : "No image"}
					borderRadius={40}
				/>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Nombre</div>,
			width: 260,
			dataIndex: "sellerFirstName",
			render: (value, record) => (
				<Typo level={5}>
					{value} {record.sellerLastName}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Unidades</div>,
			dataIndex: "totalUnits",
			render: (value, record) => (
				<Typo type="primary" level={4}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>%</div>,
			dataIndex: "totalUnits",
			render: (value, record) => (
				<Typo type="success" level={6}>
					{((value * 100) / dashboard?.totalUnits)?.toFixed(0)} %
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Ventas</div>,
			dataIndex: "totalSales",
			render: (value, record) => (
				<Typo type="primary" level={4}>
					$ {numberWithDotAndCommas(value?.toFixed(0))}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>%</div>,
			dataIndex: "totalSales",
			render: (value, record) => (
				<Typo type="success" level={6}>
					{((value * 100) / dashboard?.totalSales)?.toFixed(0)} %
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Ordenes</div>,
			dataIndex: "totalOrders",
			render: (value, record) => (
				<Typo type="primary" level={4}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>%</div>,
			dataIndex: "totalOrders",
			render: (value, record) => (
				<Typo type="success" level={6}>
					{((value * 100) / dashboard?.totalOrders)?.toFixed(0)} %
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>$ Promedio</div>,
			dataIndex: "totalSales",
			render: (value, record) => (
				<Typo type="secondary" level={4}>
					$ {numberWithDotAndCommas((value / record.totalOrders)?.toFixed(0))}
				</Typo>
			),
		},
	];

	return (
		<Dashboard1Container>
			<FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Fecha (desde/hasta)
					</Typo>
					<DatePicker.RangePicker
						format="DD/MM/YYYY"
						value={rangeDate}
						onChange={(e) => handleDateChange(e)}
					/>
					{/* {rangeDate ? (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							value={rangeDate}
							onChange={(e) => handleDateChange(e)}
						/>
					) : (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							onChange={(e) => handleDateChange(e)}
						/>
					)} */}
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Droguería
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Droguería"
						optionFilterProp="children"
						value={selectedDistributor}
						//defaultValue={selectedDistributor ?? null}
						onChange={(e, o) => selectDistributor(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 180 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Provincia
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Provincia"
						optionFilterProp="children"
						value={selectedProvince}
						//defaultValue={selectedProvince ?? null}
						onChange={(e, o) => selectProvince(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={provinces}
						style={{ width: 220 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Laboratorio
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Laboratorio"
						optionFilterProp="children"
						value={selectedLaboratory}
						// defaultValue={selectedLaboratory ?? null}
						onChange={(e, o) => selectLaboratory(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={laboratories}
						style={{ width: 180 }}
					/>
				</Container>
				{selectedLaboratory && (
					<Container>
						<Typo type="primary" level={6}>
							Vendedor
						</Typo>
						<Select
							showSearch
							//allowClear
							placeholder="Vendedor"
							optionFilterProp="children"
							value={selectedSeller}
							// defaultValue={selectedSeller ?? null}
							onChange={(e, o) => selectSeller(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={sellers}
							style={{ width: 180 }}
						/>
					</Container>
				)}
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Período
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Período"
						optionFilterProp="children"
						value={mobilePeriod}
						onChange={(e) => selectPeriod(e)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={CONSTANTS.MOBILE_DASHBOARD_PERIODS}
						style={{ width: 180 }}
					/>
				</Container>
			</FilterContainer>
			<HeaderContainer>
				<Box
					data={numberWithDotAndCommas(dashboard?.totalSales?.toFixed(0))}
					dataPrefix={"$"}
					secondData={dashboard?.totalUnits}
					secondDataSuffix={"un."}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					isLoading={isLoading}
					type="headerBoxMobile"
					title={
						<Typo texto="light" type="primary">
							Ventas período
						</Typo>
					}
				/>
				<Box
					data={dashboard?.totalOrders}
					dataSuffix={"Transfers"}
					secondData={dashboard?.totalOrderPos}
					secondDataSuffix={"Puntos de venta"}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					isLoading={isLoading}
					type="headerBoxMobile"
					title={
						<>
							<Typo texto="light" type="primary">
								Puntos de venta asignados:{" "}
							</Typo>
							<Typo type="primary" level={3}>
								{selectedSeller
									? dashboard?.totalUserRoutes
									: dashboard?.totalLaboratoryRoutes}
							</Typo>
						</>
					}
				/>
				<Box
					title={
						<Typo texto="light" type="primary">
							Histórico ruta
						</Typo>
					}
					graphData={posOrderingGraph}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					isLoading={isLoading}
					type="headerBoxMobile"
				/>
			</HeaderContainer>
			<BigChartContainer>
				<AntdTable
					dataSource={sellersTable}
					loading={isLoading}
					columns={columns}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
			</BigChartContainer>
			<BigChartContainer>
				<Box
					type="timeChartMultipleProduct"
					options={productSalesGraph}
					iconOption={<BiDollar size={20} />}
					option2={productUnitsGraph}
					iconOption2={<BiCoinStack size={20} />}
					selectProductGraph={selectProductGraph}
					productGraph={productGraphSelector}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							{productGraphSelector === "SALES"
								? "Ventas por producto"
								: productGraphSelector === "UNITS"
								? "Unidades por producto"
								: ""}
						</Typo>
					}
				/>
			</BigChartContainer>
			<DoubleChartContainer>
				<Box
					type="timeChart"
					options={timeOrdersGraph}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							Histórico ventas
						</Typo>
					}
				/>
				<Box
					type="timeChartSalesUnitsOrders"
					options={distributorSalesGraph}
					iconOption={<BiDollar size={20} />}
					option2={distributorUnitsGraph}
					iconOption2={<BiCoinStack size={20} />}
					option3={distributorOrdersGraph}
					iconOption3={<BiBox size={20} />}
					selectDistributorGraph={selectDistributorGraph}
					distributorGraph={distributorGraphSelector}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							{distributorGraphSelector === "SALES"
								? "Ventas por droguería"
								: distributorGraphSelector === "UNITS"
								? "Unidades por droguería"
								: distributorGraphSelector === "ORDERS"
								? "Ordenes por droguería"
								: ""}
						</Typo>
					}
				/>
			</DoubleChartContainer>
		</Dashboard1Container>
	);
}
