import { Skeleton, Row, Col } from "antd";
import { Inner } from "./styles";

const GUTTER = 10;

export default function LoadingSkeletonInput() {
	return (
		<Inner>
			<Row gutter={GUTTER}>
				<Col span={18}>
					<Skeleton.Input
						active={true}
						block
						style={{ marginBottom: -10, width: 260, borderRadius: 10 }}
					/>
				</Col>
				<Col span={4}></Col>
			</Row>
		</Inner>
	);
}
