import React, { useState } from "react";
import { Form, Row, Space, Button } from "antd";
import { useAuth } from "../../../../../../contexts/authContext";
import VerifySmsCode from "../verify-sms-code/VerifySmsCode";
import PhoneInput from "../../../../../common/phone-input/PhoneInput";

export default function SendSmsCode({ setConnectedSmsProvider, setShowModal }) {
	const { connectPhone } = useAuth();
	const [form] = Form.useForm();
	const [confirmationResult, setConfirmationResult] = useState("");
	const [error, setError] = useState("");
	const [phoneModalVisible, setPhoneModalVisible] = useState(false);
	const [verifierModalVisible, setVerifierModalVisible] = useState(false);

	const handleConnectPhone = async () => {
		setError("");
		if (
			form.getFieldValue("phone") === "" ||
			form.getFieldValue("phone") === undefined
		)
			return setError("Por favor ingresa un código valido");
		try {
			const response = await connectPhone(
				form.getFieldValue("phoneAreaCode") + form.getFieldValue("phone")
			);

			setConfirmationResult(response);
			setVerifierModalVisible(true);
			setPhoneModalVisible(false);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			{verifierModalVisible === false && (
				<Form form={form} name="basic" autoComplete="off">
					<Space>
						<Row>
							<PhoneInput form={form} size="default" label="Teléfono" />
							<Form.Item>
								<Button type="primary" onClick={handleConnectPhone} id="algo">
									Verificar teléfono
								</Button>
							</Form.Item>
						</Row>
					</Space>
				</Form>
			)}

			<div id="recaptcha"></div>
			{verifierModalVisible === true ? (
				<VerifySmsCode
					confirmationResult={confirmationResult}
					setConnectedSmsProvider={setConnectedSmsProvider}
					setShowModal={setShowModal}
				/>
			) : null}
		</>
	);
}
