import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
	Buttons,
	UploadFile,
	AntdButton,
	TableContainer,
	AntdTable,
	ButtonsLeft,
	ConfirmButton,
	SpanCointainer,
	Span,
	Container,
	OptionContainer,
	ActionButton,
	SectionContainer,
} from "./styles";
import { Dropdown, Input, Menu, Modal, Select } from "antd";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import { COLORS, Typo } from "../../../../../layout/theme";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../../contexts/authContext";
import LoadingSkeleton from "./components/LoadingSkeleton";
import {
	check_general_pos_data,
	export_general_pos,
	get_general_pos,
	get_general_pos_header_info,
	update_general_pos_data,
	update_general_pos_state,
} from "../../../../../../api/endpoints/pointsofsale";
import { debounce } from "lodash";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";
import { get_provinces } from "../../../../../../api/endpoints/region";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function UpdatePointsOfSale() {
	const navigate = useNavigate();
	const { user, userdata } = useAuth();
	const [data, setData] = useState(false);
	const [state, setState] = useState("ACTIVE");
	const [hasChanges, setHasChanges] = useState(false);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [page, setPage] = useState(1);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(200);
	const [totalPos, setTotalPos] = useState(null);
	const [loading, setLoading] = useState(false);
	const [provinceName, setProvinceName] = useState(null);
	const [localityName, setLocalityName] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState([]);
	const [listadoTotalProvincias, setListadoTotalProvincias] = useState([]);
	const [key, setKey] = useState("null");
	const [clientId, setClientId] = useState(null);
	const [search, setSearch] = useState(null);

	const { confirm } = Modal;

	const fetch_general_pos = async (
		search,
		provinceName,
		localityName,
		state
	) => {
		setLoading(true);
		const res = await get_general_pos(
			search,
			provinceName,
			localityName,
			state
		);
		const newData = res.pointsOfSale.map((i) => ({
			...i,
			hasChanges: false,
		}));
		setData(newData);
		setTotalPos(res.totalPointsOfSale);
		setListadoProvincias(
			res.tableRegion
				?.map((p) => ({
					id: p.id,
					name: p.title,
					localities: p.localities
						.map((l) => ({
							localityId: l.id,
							localityName: l.title,
						}))
						.sort((a, b) => {
							const titleA = a.localityName.toLowerCase();
							const titleB = b.localityName.toLowerCase();
							if (titleA < titleB) {
								return -1;
							}
							if (titleA > titleB) {
								return 1;
							}

							return 0;
						}),
				}))
				.sort((a, b) => {
					const titleA = a.name.toLowerCase();
					const titleB = b.name.toLowerCase();
					if (titleA < titleB) {
						return -1;
					}
					if (titleA > titleB) {
						return 1;
					}

					return 0;
				})
		);
		setListadoTotalProvincias(
			res.totalRegions
				?.map((p) => ({
					id: p.id,
					name: p.title,
					localities: p.localities
						.map((l) => ({
							localityId: l.id,
							localityName: l.title,
						}))
						.sort((a, b) => {
							const titleA = a.localityName.toLowerCase();
							const titleB = b.localityName.toLowerCase();
							if (titleA < titleB) {
								return -1;
							}
							if (titleA > titleB) {
								return 1;
							}

							return 0;
						}),
				}))
				.sort((a, b) => {
					const titleA = a.name.toLowerCase();
					const titleB = b.name.toLowerCase();
					if (titleA < titleB) {
						return -1;
					}
					if (titleA > titleB) {
						return 1;
					}

					return 0;
				})
		);
		setLoading(false);
	};

	const fetch_general_pos_header = async () => {
		const res = await get_general_pos_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_general_pos_header();
	}, [state]);

	useEffect(() => {
		fetch_general_pos(search, provinceName, localityName, state);
	}, [provinceName, localityName, state]);

	const onInputChange = (key, index) => (e) => {
		const newData = [...data];

		if (key === "province" || key === "location") {
			newData[index][key] = e;
		} else {
			newData[index][key] = e.target.value;
		}

		newData[index]["hasChanges"] = true;
		setData(newData);
		setHasChanges(true);
	};

	const fetch_export_general_pos = async () => {
		setLoading(true);
		try {
			const res = await export_general_pos();
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName =
				"PuntosDeVentaGenerales_" + moment().format("DD-MM-YYYY") + ".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleUpload = async (e) => {
		try {
			setLoading(true);
			const formData = new FormData();
			formData.append("data", clientId ?? userdata.clientId);
			formData.append("file", e.fileList[e.fileList.length - 1].originFileObj);
			const res = await check_general_pos_data(formData);
			setData(
				res.pointsOfSaleToChange.map((i) => ({
					...i,
				}))
			);
			const thereIsChanges = res.pointsOfSaleToChange.some(
				(i) => i.hasChanges === true
			);
			setHasChanges(thereIsChanges);
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Los Puntos de Venta no pudieron actualizarse",
				"Verifique su archivo de excel, si sigue con problemas contacte con soporte a soporte@onetransfer.com"
			);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = () => {
		confirm({
			title: "¿Seguro que quiere publicar estos cambios?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content:
				"Se efectuará la modificación de los Puntos de Venta de manera inmediata",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				confirmPos();
			},
		});
	};

	const confirmPos = async () => {
		try {
			setLoading(true);
			const res = await update_general_pos_data({ pointsOfSale: data });
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Los Puntos de Venta se actualizaron correctamente",
				"Los Puntos de Venta se actualizaron correctamente"
			);
			setSearch(null);
			setProvinceName(null);
			setLocalityName(null);
			setHasChanges(false);
			fetch_general_pos("", null, null, state);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Los Puntos de Venta no se pudieron actualizar correctamente, contacte con soporte a soporte@onetransfer.com",
				"Los Puntos de Venta se se pudieron actualizar correctamente, contacte con soporte a soporte@onetransfer.com"
			);
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar este Punto de Venta?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "Este cambio se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				deletePointOfSale(key);
			},
		});
	};

	const deletePointOfSale = async (id) => {
		const _state = { id: id, state: "DELETED" };
		await update_general_pos_state(id, _state);
		fetch_general_pos("", provinceName, localityName, state);
		fetch_general_pos_header();
	};

	const handleActivate = (key) => {
		confirm({
			title: "¿Seguro que quiere reactivar este Punto de Venta?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "Este cambio se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				activatePointOfSale(key);
			},
		});
	};

	const activatePointOfSale = async (id) => {
		const _state = { id: id, state: "ACTIVE" };
		await update_general_pos_state(id, _state);
		fetch_general_pos("", provinceName, localityName, state);
		fetch_general_pos_header();
	};

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Cuit</div>,
			dataIndex: "cuit",
			width: 160,
			fixed: "left",
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					<Input
						value={value}
						onChange={onInputChange(
							"cuit",
							page === 1 ? index : (page - 1) * 25 + index
						)}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			width: 350,
			fixed: "left",
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					<Input
						value={value}
						onChange={onInputChange(
							"name",
							page === 1 ? index : (page - 1) * 25 + index
						)}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Razon Social</div>,
			dataIndex: "businessName",
			width: 350,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					<Input
						value={value}
						onChange={onInputChange(
							"businessName",
							page === 1 ? index : (page - 1) * 25 + index
						)}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Direccion</div>,
			dataIndex: "address",
			width: 350,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					<Input
						value={value}
						onChange={onInputChange(
							"address",
							page === 1 ? index : (page - 1) * 25 + index
						)}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>CP</div>,
			dataIndex: "postalCode",
			width: 160,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					<Input
						value={value}
						onChange={onInputChange(
							"postalCode",
							page === 1 ? index : (page - 1) * 25 + index
						)}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre contacto</div>,
			dataIndex: "contactName",
			width: 250,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					<Input
						value={value}
						onChange={onInputChange(
							"contactName",
							page === 1 ? index : (page - 1) * 25 + index
						)}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Correo contacto</div>,
			dataIndex: "contactEmail",
			width: 300,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					<Input
						value={value}
						onChange={onInputChange(
							"contactEmail",
							page === 1 ? index : (page - 1) * 25 + index
						)}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Telefono contacto</div>,
			dataIndex: "contactPhone",
			width: 170,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					<Input
						value={value}
						onChange={onInputChange(
							"contactPhone",
							page === 1 ? index : (page - 1) * 25 + index
						)}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cliente</div>,
			dataIndex: "clientName",
			width: 200,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					<Input
						value={value}
						onChange={onInputChange(
							"clientName",
							page === 1 ? index : (page - 1) * 25 + index
						)}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Provincia</div>,
			dataIndex: "province",
			width: 380,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					{record.location !== undefined && record.location !== null ? (
						<Select value={value} disabled style={{ width: "100%" }}>
							<Select.Option key={value} value={value}>
								{value}
							</Select.Option>
						</Select>
					) : (
						<Select
							onChange={onInputChange(
								"province",
								page === 1 ? index : (page - 1) * 25 + index
							)}
							value={value}
							style={{ width: "100%" }}
						>
							{listadoTotalProvincias
								?.sort((a, b) => {
									const titleA = a.name.toLowerCase();
									const titleB = b.name.toLowerCase();
									if (titleA < titleB) {
										return -1;
									}
									if (titleA > titleB) {
										return 1;
									}

									return 0;
								})
								.map((p) => (
									<Select.Option key={p.name} value={p.name}>
										{p.name}
									</Select.Option>
								))}
						</Select>
					)}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Localidad</div>,
			dataIndex: "location",
			width: 300,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					{record.province && listadoTotalProvincias ? (
						<Select
							allowClear={true}
							placeholder="Elegir Localidad"
							onClear={() => (record.location = undefined)}
							onChange={onInputChange(
								"location",
								page === 1 ? index : (page - 1) * 25 + index
							)}
							value={value}
							style={{ width: "100%" }}
						>
							{listadoTotalProvincias
								.filter((p) => p.name === record.province)[0]
								?.localities.sort((a, b) => {
									const titleA = a.localityName.toLowerCase();
									const titleB = b.localityName.toLowerCase();
									if (titleA < titleB) {
										return -1;
									}
									if (titleA > titleB) {
										return 1;
									}

									return 0;
								})
								.map((p) => (
									<Select.Option key={p.localityName} value={p.localityName}>
										{p.localityName}
									</Select.Option>
								))}
						</Select>
					) : (
						""
					)}
				</div>
			),
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			width: 30,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={r.deleted ? menuInactive : menu}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleDelete(key)}>Desactivar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleActivate(key)}>Reactivar</Typo>,
				},
			]}
		/>
	);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setSearch(e.target.value);
		fetch_general_pos(e.target.value, provinceName, localityName, state);
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 600),
		[provinceName, localityName, state]
	);

	const handleProvinceSelect = (value) => {
		setProvinceName(value);
	};

	const handleLocalidadSelect = (value) => {
		setLocalityName(value);
	};

	const handleProvinceClear = () => {
		setProvinceName(null);
		setLocalityName(null);
	};

	const handleLocalityClear = () => {
		setLocalityName(null);
	};

	return (
		<>
			<Buttons>
				<ButtonsLeft>
					<AntdButton onClick={fetch_export_general_pos} loading={loading}>
						Exportar listado
					</AntdButton>
					<UploadFile
						multiple={false}
						showUploadList={false}
						beforeUpload={() => false}
						action={null}
						onChange={handleUpload}
						loading={loading}
					>
						Actualizar PDVs
					</UploadFile>
				</ButtonsLeft>
			</Buttons>
			{!clientId && (
				<ConfirmButton>
					{hasChanges && (
						<AntdButton onClick={handleSubmit} success>
							Confirmar cambios
						</AntdButton>
					)}
				</ConfirmButton>
			)}
			<Container>
				<SectionContainer>
					<AntdSearch
						width={400}
						allowClear
						placeholder="Buscar punto de venta"
						onChange={debouncedEventHandler}
					/>
					<OptionContainer>
						<Typo>Provincia</Typo>
						<Select
							allowClear={true}
							onChange={handleProvinceSelect}
							onClear={handleProvinceClear}
							value={provinceName}
							style={{ width: 250 }}
						>
							{listadoProvincias
								?.sort((a, b) => {
									const titleA = a.name.toLowerCase();
									const titleB = b.name.toLowerCase();
									if (titleA < titleB) {
										return -1;
									}
									if (titleA > titleB) {
										return 1;
									}

									return 0;
								})
								.map((p) => (
									<Select.Option key={p.id} value={p.name}>
										{p.name}
									</Select.Option>
								))}
						</Select>
					</OptionContainer>
					{provinceName && listadoProvincias ? (
						<OptionContainer>
							<Typo>Localidad</Typo>
							<Select
								allowClear={true}
								placeholder="Elegir Localidad"
								onClear={handleLocalityClear}
								onChange={handleLocalidadSelect}
								value={localityName}
								style={{ width: 250 }}
							>
								{listadoProvincias
									.filter((p) => p.name === provinceName)[0]
									?.localities.sort((a, b) => {
										const titleA = a.localityName.toLowerCase();
										const titleB = b.localityName.toLowerCase();
										if (titleA < titleB) {
											return -1;
										}
										if (titleA > titleB) {
											return 1;
										}

										return 0;
									})
									.map((p) => (
										<Select.Option key={p.localityId} value={p.localityName}>
											{p.localityName}
										</Select.Option>
									))}
							</Select>
						</OptionContainer>
					) : (
						<OptionContainer></OptionContainer>
					)}
				</SectionContainer>
				<SectionContainer>
					<Span onClick={() => setState("ACTIVE")}>
						Activos ({headerInfo?.totalActive})
					</Span>
					<Span onClick={() => setState("DISABLED")}>
						Eliminados ({headerInfo?.totalDisabled})
					</Span>
				</SectionContainer>
			</Container>
			<SpanCointainer>
				{data && (
					<Typo type="primary" level={6}>
						Total PDV: {totalPos}
					</Typo>
				)}
			</SpanCointainer>
			{!data ? (
				<LoadingSkeleton />
			) : (
				<TableContainer>
					<AntdTable
						dataSource={data}
						columns={columns}
						pagination={{
							pageSize: 25,
							showSizeChanger: false,
							onChange: (page) => setPage(page),
						}}
						rowClassName={(record) => (record.hasChanges ? "hasChanges" : null)}
						scroll={{
							x: 2500,
							y: 500,
						}}
					/>
				</TableContainer>
			)}
		</>
	);
}
