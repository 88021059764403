import React, { useState, useEffect } from "react";
import { Inner } from "./styles";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { useNavigate } from "react-router-dom";
import { LaptopOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import BookList from "./book-list/BookList";
import { CONSTANTS } from "../../../../../utils/constants";
import Image from "../../../../common/image/Image";
const { Sider } = Layout;

export default function BookPage({ userdata }) {
	const navigate = useNavigate();
	const [collapsed, setCollapsed] = useState(true);
	const [openPanel, setOpenPanel] = useState([]);
	const [display, setDisplay] = useState(CONSTANTS.BOOK_LIST);
	const [hasBook, setHasBook] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [logoImportFile, setLogoImportFile] = useState(null);
	const [logoImportSoftware, setLogoImportSoftware] = useState(null);
	const [connectionType, setConnectionType] = useState(null);
	const [connectionName, setConnectionName] = useState(null);
	const [reload, setReload] = useState(false);

	const handleMenuClick = ({ key }) => {
		setDisplay(key);
	};

	const handleReload = () => {
		setReload((prev) => !prev);
	};

	return (
		<Layout style={{ minHeight: "100vh" }}>
			<Sider theme="light" trigger={null} collapsible collapsed={collapsed}>
				<div></div>
				<Menu
					theme="light"
					mode="inline"
					defaultSelectedKeys={[CONSTANTS.BOOK_LIST]}
					style={{ paddingTop: 20 }}
					onClick={handleMenuClick}
				>
					<Menu.Item
						key={CONSTANTS.BOOK_LIST}
						icon={
							logoImportSoftware && logoImportFile ? (
								connectionType ===
								CONSTANTS.BILLER_CONNECTIONS_TYPE[0].value ? (
									<div
										style={{
											display: "flex",
											alignContent: "center",
											alignItems: "center",
										}}
									>
										<Image src={logoImportSoftware} width="20px" />
									</div>
								) : (
									<div
										style={{
											display: "flex",
											alignContent: "center",
											alignItems: "center",
										}}
									>
										<Image src={logoImportFile} width="20px" />
									</div>
								)
							) : (
								<LaptopOutlined />
							)
						}
						onClick={() => {
							setDisplay(CONSTANTS.BOOK_LIST);
							handleReload();
						}}
					>
						{hasBook
							? connectionName !== "DEFAULT"
								? `Conectado a ${connectionName}`
								: "Reposición"
							: "Reposición"}
					</Menu.Item>
				</Menu>
			</Sider>
			<Layout className="site-layout">
				<Inner>
					{display === CONSTANTS.BOOK_LIST &&
						(true ? (
							<BookList
								userdata={userdata}
								hasBook={hasBook}
								setHasBook={setHasBook}
								connectionName={connectionName}
								setConnectionName={setConnectionName}
								connectionType={connectionType}
								setConnectionType={setConnectionType}
								logoImportFile={logoImportFile}
								setLogoImportFile={setLogoImportFile}
								logoImportSoftware={logoImportSoftware}
								setLogoImportSoftware={setLogoImportSoftware}
								reload={reload}
								setReload={handleReload}
							/>
						) : (
							<LoadingSkeleton />
						))}
				</Inner>
			</Layout>
		</Layout>
	);
}
