import axiosInstance from "../axiosInstance";

const CONTROLLER = "megaDistributors";

const get_megaDistributors = async () => {
	const response = await axiosInstance.get(CONTROLLER);
	return response.data;
};

const get_megaDistributors_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_megaDistributors_header_info = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMegaDistributorHeader`
	);
	return response.data;
};

const get_megaDistributor_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const create_megaDistributor = async (megaDistributor) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, megaDistributor, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_megaDistributor = async (id, megaDistributor) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/${id}`,
		megaDistributor,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const update_megaDistributor_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateMegaDistributorState/${id}`,
		state
	);
	return response.data;
};

const get_megaDistributors_name_list = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMegaDistributorNameList`
	);
	return response.data;
};

export {
	get_megaDistributors,
	get_megaDistributors_page,
	get_megaDistributors_header_info,
	get_megaDistributor_by_id,
	create_megaDistributor,
	update_megaDistributor,
	update_megaDistributor_state,
	get_megaDistributors_name_list,
};
