import styled from "styled-components/macro";
import { COLORS, SIZES } from "../../layout/theme";
import { Select } from "antd";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	overflow: hidden;
`;

export const Container = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const OptionSection = styled.div`
	display: flex;
	flex: 0 0 100%;
	justify-content: space-between;
	gap: 5px;
	align-items: end;
`;

export const Section = styled.div`
	display: flex;
	flex: 1 1 30%;
`;

export const SearchContainer = styled.div`
	align-items: end;
	justify-content: flex-end;
	display: flex;
`;

export const ClientContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export const AntdSelector = styled(Select)`
 &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &..ant-select-arrow {
      
    }
  

  &.ant-select-selector{
    .ant-select-selection-item {
      font-size: ${SIZES.level7};
      color: ${COLORS.MediumGrey};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${COLORS.Primary};
      }
    }
  }
`;
