import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link } from "react-router-dom";
import { ButtonContainer, ChildrenContainer, AntdButton } from "./styles";
import PublicityBanner from "./publicity-banner/PublicityBanner";
import { get_campaigns } from "../../../../api/endpoints/campaigns";
import CampaignsSliders from "./campaigns-sliders/CampaignsSliders";

export default function HotSalesPage() {
	const [filter, setFilter] = useState("BIGBANNERHOTSALE");
	const [campaigns, setCampaigns] = useState(null);

	const get_filter = () => {
		setFilter(
			localStorage.getItem("indexHotSaleFilter")
				? localStorage.getItem("indexHotSaleFilter")
				: "BIGBANNERHOTSALE"
		);
	};

	useEffect(() => {
		get_filter();
	}, []);

	useEffect(() => {
		const fetch_campaigns = async () => {
			const res = await get_campaigns();

			setCampaigns(res);
		};

		fetch_campaigns();
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>HotSale</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<ButtonContainer>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600" }}
						width={220}
						height={48}
						fontSize={12}
						onClick={() => setFilter("BIGBANNERHOTSALE")}
						$active={filter === "BIGBANNERHOTSALE"}
					>
						Banner Publicitario
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={220}
						height={48}
						fontSize={12}
						onClick={() => setFilter("CAMPAIGNSSLIDERS")}
						$active={filter === "CAMPAIGNSSLIDERS"}
					>
						Sliders de Campañas
					</AntdButton>
				</ButtonContainer>
				<ChildrenContainer>
					{filter === "BIGBANNERHOTSALE" && (
						<PublicityBanner
							section="BIGBANNERHOTSALE"
							bannerHeight={720}
							bannerWidth={1920}
						/>
					)}
					{filter === "CAMPAIGNSSLIDERS" && (
						<CampaignsSliders campaigns={campaigns} />
					)}
				</ChildrenContainer>
			</Main.Body>
		</Main>
	);
}
