import React from "react";
import { ModalContainer } from "./styles";
import ProductDetail from "../../pages/seller/product-detail/ProductDetail";

export default function ProductModal({
	id,
	quantityInCart,
	setQuantityInCart,
	setIsModalVisible,
	minQuantity,
	maxQuantity,
	marketing,
	setFlag = null,
	setSelectedPointsOfSale = null,
	setProducts = null,
	setSliderData = null,
}) {
	return (
		<ModalContainer>
			<ProductDetail
				id={id}
				quantityInCart={quantityInCart}
				setQuantityInCart={setQuantityInCart}
				setIsModalVisible={setIsModalVisible}
				minQuantity={minQuantity}
				maxQuantity={maxQuantity}
				marketing={marketing}
				setFlag={setFlag}
				setSelectedPointsOfSale={setSelectedPointsOfSale}
				setProducts={setProducts}
				setSliderData={setSliderData}
			></ProductDetail>
		</ModalContainer>
	);
}
