import React, { useEffect, useState } from "react";
import { Inner, Container, HistoryTable, Title } from "./styles";
import { get_market_users } from "../../../../../../../api/endpoints/users";
import LoadingSkeleton from "./component/LoadingSkeleton";
import { COLORS, Typo } from "../../../../../../layout/theme";

export default function HistoryModalDetail({ client, history }) {
	const [usersData, setUsersData] = useState(null);
	const [loading, setLoading] = useState(true);

	const columns = [
		{
			title: () => (
				<div
					style={{
						color: COLORS.White,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Usuario
				</div>
			),
			dataIndex: "changedBy",
			render: (value, record) => (
				<>
					<Title>
						{usersData.find((u) => u.id === value).firstName +
							" " +
							usersData.find((u) => u.id === value).lastName}
					</Title>
				</>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.White,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Fecha Modificación
				</div>
			),
			dataIndex: "changedDate",
			render: (value, record) => (
				<Title>{value ? value.split("T")[0] : ""}</Title>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.White,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Estado aplicado
				</div>
			),
			dataIndex: "changedStatus",
			render: (value, record) => <Title>{value}</Title>,
		},
	];

	useEffect(() => {
		const fetch_users = async () => {
			setLoading(true);
			const res = await get_market_users();
			setUsersData(res);
			setLoading(false);
		};
		fetch_users();
	}, []);

	if (!usersData) return <LoadingSkeleton />;

	return (
		<Inner>
			<Container>
				<Typo type="primary" fontWeight={600} fontSize={16}>
					HISTORIAL DE SOLICITUDES DE {client.name.toUpperCase()}
				</Typo>

				<HistoryTable
					dataSource={history}
					pagination={false}
					columns={columns}
				/>
			</Container>
		</Inner>
	);
}
