import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import { FaImages } from "react-icons/fa";
import Image from "../../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../../layout/theme";
import CommisionQuantity from "../commision-quantity/CommisionQuantity";
import { Container, CrossDelete } from "./styles";

export default function ContractTable({ data, onDelete, onChange }) {
	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			render: (photoUrl) => <Image src={photoUrl} width={30} height={30} />,
			width: 100,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "title",
			key: "title",
			render: (value, record) => (
				<Typo type="secondary" level={7}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Comisión</div>,
			dataIndex: "saleCommision",
			key: "saleCommision",
			render: (commision, record, index) => (
				<CommisionQuantity
					product={{ commision, record, index }}
					onChange={onChange}
				/>
			),
			width: 150,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Quitar</div>,
			key: "action",
			width: 150,
			render: (_, record, index) => (
				<Button type="link" onClick={() => handleDelete(_.id)}>
					<CrossDelete>X</CrossDelete>
				</Button>
			),
		},
	];

	const handleDelete = (id) => {
		Modal.confirm({
			title: "¿Seguro que quiere eliminar este producto de la lista?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si acepta, el producto se quitará de la lista y pasará a tener la comisión general dada por el contrato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				onDelete(id);
			},
		});
	};

	return (
		<Container>
			<Table
				showHeader={true}
				columns={columns}
				dataSource={data}
				pagination={false}
				scroll={{
					y: 260,
				}}
				rowKey={(record) => record.id}
			/>
		</Container>
	);
}
