import React from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../common/image/Image";
import PublicitySlide from "../../common/slides/publicity-slide/PublicitySlide";
import { add_user_interaction } from "../../../api/endpoints/users";
import { CONSTANTS } from "../../../utils/constants";
import { useAuth } from "../../../contexts/authContext";

export default function PublicitySlideContainer({
	id,
	title,
	image,
	bannerHeight,
	bannerBorder = 0,
	bannerFit = "cover",
	bannerUrl,
	laboratorySearch = null,
	brandSearch = null,
	productSearch = null,
	handleBannerClick = null,
	bannerType = null,
}) {
	const { userdata } = useAuth();
	const navigate = useNavigate();

	const handleClick = async () => {
		window.scrollTo(0, 0);
		if (!userdata?.isSeller && !userdata?.isOneMarket && bannerType) {
			await add_user_interaction({
				productId: id,
				inCart: false,
				InteractionType: bannerType,
			});
		}
		!handleBannerClick && bannerUrl && navigate(bannerUrl);
		handleBannerClick &&
			bannerUrl &&
			handleBannerClick({ laboratorySearch, brandSearch, productSearch });
	};

	return (
		<PublicitySlide onClick={handleClick} bannerHeight={bannerHeight}>
			<PublicitySlide.TopContainer bannerHeight={bannerHeight}>
				<Image
					src={image}
					height="100%"
					width="100%"
					style={{
						objectFit: bannerFit,
						borderRadius: bannerBorder,
					}}
				/>
			</PublicitySlide.TopContainer>
		</PublicitySlide>
	);
}
