import React from "react";
import TemplateViewDetail from "../TemplateViewDetail/TemplateViewDetail";
import { ModalContainer } from "./styles";

export default function TemplateViewModal({ dataView }) {
	return (
		<ModalContainer>
			<TemplateViewDetail dataView={dataView}></TemplateViewDetail>
		</ModalContainer>
	);
}
