import { Button } from "antd";
import styled from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	margin-top: 30px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: column;
	}
`;

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 30px;
	min-height: 200px;

	@media only screen and (min-width: 760px) and (max-width: 859px) {
		gap: 40px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media only screen and (min-width: 860px) and (max-width: 1180px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media only screen and (min-width: 1181px) and (max-width: 1270px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
`;

export const ImageSection = styled.div`
	height: 200px;
	width: 100%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 10px 20px 20px 20px;
	align-items: center;
`;

export const FilterContainer = styled.div`
	display: flex;
`;

export const AntdButton = styled(Button)`
	width: 170px;
	height: 40px;
	background-color: ${(props) =>
		props.success ? COLORS.Success : COLORS.Secondary};
	color: ${COLORS.White};
	border: 1px solid
		${(props) => (props.success ? COLORS.Success : COLORS.Secondary)};
	border-radius: 7px;
	padding: 0.5rem 0;
	&:hover {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
		color: ${COLORS.White};
	}
	& span {
		color: ${COLORS.White};
	}
	position: relative;

	&:focus {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
	}
	&:active {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
	}
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const SearchContainer = styled.div`
	flex: 1 1 40%;
	align-items: center;
	align-content: center;
	justify-content: center;
	display: flex;
	align-content: space-evenly;
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
`;

export const CompaginationDiv = styled.div`
	display: flex;
	gap: 4px;
`;

export const ContainerTitle = styled.div`
	display: flex;
	align-items: start;
	gap: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const CartDelete = styled.div`
	color: ${COLORS.Grey};
	font-family: sans-serif;
	font-stretch: ultra-expanded;
	font-size: ${SIZES.level12};
	font-weight: ${WEIGHT.Bold};
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	align-content: center;
	justify-content: center;
	height: 80px;
`;
