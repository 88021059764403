import React, { useEffect, useState } from "react";
import { Select, Input, Form, Row, Col, DatePicker } from "antd";
import { Inner, PreviewImage } from "./styles";
import UploadFileImage from "../../../../../../common/upload-file-with-image/UploadFileImage";
import { COLORS } from "../../../../../../layout/theme";

const { Option } = Select;
const GUTTER = 10;
const { RangePicker } = DatePicker;

const EventBasicInfo = ({
	form,
	idProvincia,
	provincia,
	localidad,
	setLocalidad,
	setProvincia,
	setIdProvincia,
	listadoLocalidades,
	listadoProvincias,
	handleChange,
	selectedClient,
	handleImageChange,
	imageUrl,
	clients,
	disabledDate,
}) => {
	const formValues = Form.useWatch([], form);

	const handleProvinciasSelect = (value, object) => {
		setProvincia(object);
		setIdProvincia(value);
	};

	const handleLocalidadSelect = (value, objectList) => {
		setLocalidad(objectList);
	};

	return (
		<Inner>
			<Row gutter={GUTTER} style={{ width: "100%" }}>
				<Col md={6} xl={6}>
					<Form.Item
						label={
							<label
								style={{
									color: COLORS.Primary,
									fontWeight: "600",
								}}
							>
								Imagen
							</label>
						}
						name="file"
						// rules={[
						// 	{
						// 		required: imageToSend ? false : true,
						// 		message: "Este campo es obligatorio",
						// 	},
						// ]}
					>
						{/* <UploadFileImage onChange={handleImageChange} /> */}
						{imageUrl && (
							<PreviewImage
								style={{ marginLeft: "20px" }}
								src={imageUrl}
								alt="avatar"
							/>
						)}
					</Form.Item>
				</Col>
				<Col md={12} xl={12}>
					<Row gutter={GUTTER}>
						<Col md={12} xl={12}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Nombre del evento
									</label>
								}
								name="title"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
									{
										pattern: new RegExp(
											/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
										),
										message:
											"No se aceptan caracteres especiales. Solo letras y numeros",
									},
								]}
							>
								<Input disabled placeholder="Nombre" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Duracion
									</label>
								}
								name="publish"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
							>
								<RangePicker
									disabled
									disabledDate={disabledDate}
									format="DD/MM/YYYY"
									style={{
										width: "100%",
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<Form.Item
								label="Provincia"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
							>
								{localidad !== undefined ? (
									<Select value={provincia} disabled>
										<Select.Option
											key={provincia?.value}
											value={provincia?.value}
										>
											{provincia?.children}
										</Select.Option>
									</Select>
								) : (
									<Select onChange={handleProvinciasSelect} value={provincia}>
										{listadoProvincias
											?.sort((a, b) => {
												const titleA = a.name.toLowerCase();
												const titleB = b.name.toLowerCase();
												if (titleA < titleB) {
													return -1;
												}
												if (titleA > titleB) {
													return 1;
												}

												return 0;
											})
											.map((p) => (
												<Select.Option key={p.id} value={p.id}>
													{p.name}
												</Select.Option>
											))}
									</Select>
								)}
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label={provincia ? "Localidad" : ""}
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
							>
								{provincia && listadoLocalidades ? (
									<Select
										disabled
										allowClear={true}
										placeholder="Elegir Localidad"
										onClear={() => setLocalidad(undefined)}
										onChange={handleLocalidadSelect}
										value={localidad}
									>
										{idProvincia &&
											listadoLocalidades[0]?.localities
												.sort((a, b) => {
													const titleA = a.locality.title.toLowerCase();
													const titleB = b.locality.title.toLowerCase();
													if (titleA < titleB) {
														return -1;
													}
													if (titleA > titleB) {
														return 1;
													}

													return 0;
												})
												.map((p) => (
													<Select.Option
														key={p.locality.id}
														value={p.locality.id}
													>
														{p.locality.title}
													</Select.Option>
												))}
									</Select>
								) : (
									""
								)}
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Row>
		</Inner>
	);
};

export default EventBasicInfo;
