import { Skeleton, Row, Col, Space } from "antd";
import { Inner, ProductData } from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
	return (
		<Inner>
			<ProductData>
				<Space direction="vertical" size="middle" style={{ display: "flex" }}>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 600, marginBottom: -10, marginTop: -20 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
						<Col span={6}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 300, marginBottom: -10, marginTop: -20 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
						<Col span={6}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 300, marginBottom: -10, marginTop: -20 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 600, marginBottom: -10 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
						<Col span={12}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 300, marginBottom: -10 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 600, marginBottom: -10 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
						<Col span={12}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 300, marginBottom: -10 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={24}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 600, marginBottom: -10 }}
							/>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row></Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 1200, marginBottom: -10 }}
							/>
						</Col>
						<Col span={12}>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 1200, marginBottom: -10 }}
							/>
						</Col>
						<Col span={12}>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 1200, marginBottom: -10 }}
							/>
						</Col>
						<Col span={12}>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 1200, marginBottom: -10 }}
							/>
						</Col>
						<Col span={12}>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 1200, marginBottom: -10 }}
							/>
						</Col>
						<Col span={12}>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 1200, marginBottom: -10 }}
							/>
						</Col>
						<Col span={12}>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 1200, marginBottom: -10 }}
							/>
						</Col>
						<Col span={12}>
							<Skeleton.Input active={true} block />
						</Col>
					</Row>

					<Row></Row>
					<Row></Row>
				</Space>
				<Row gutter={GUTTER}>
					<Col span={6}>
						<Skeleton.Input active={true} style={{ width: 100 }} />
					</Col>
				</Row>
			</ProductData>
		</Inner>
	);
}
