import styled from "styled-components";
import { COLORS } from "../../../../../layout/theme";

export const Inner = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${COLORS.LightGrey};
`;

export const BigHeader = styled.div`
  width: 100%;
  height: 440px;
  background-color: rgba(190, 190, 190, 0.2);
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 10px;
`;

export const Container = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  gap: 10px;
`;

export const BigPublicity = styled.div`
  width: 50%;
  height: 380px;
  background-color: rgba(190, 190, 190, 0.2);
`;
