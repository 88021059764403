import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS, Typo } from "../../../../layout/theme";
import {
	ButtonContainer,
	CartDelete,
	Container,
	ContainerTitle,
	Inner,
	Section,
} from "./styles";
import { Button, Modal, Space } from "antd";
import {
	CloseCircleOutlined,
	LeftOutlined,
	MenuOutlined,
	RightCircleOutlined,
	RightOutlined,
} from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Image from "../../../../common/image/Image";
import ProductsTable from "../components/products-table/ProductsTable";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	get_featured_laboratories,
	update_featureds_labs,
} from "../../../../../api/endpoints/search";
import LoadingSkeleton from "../components/loading-skeleton/LoadingSkeleton";
import { get_clients_list_for_featureds } from "../../../../../api/endpoints/clients";
import AntTableDragDropGeneral from "../../../../common/and-table-dragdrop-general/AntTableDragDropGeneral";
import LaboratoriesTable from "../components/laboratories-table/LaboratoriesTable";
let productIndex = null;

export default function FeaturedLaboratories() {
	const navigate = useNavigate();
	const [loadingPage, setLoadingPage] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [homeSliderLaboratories, setHomeSliderLaboratories] = useState([]);
	const [flag, setFlag] = useState(false);

	useEffect(() => {
		if (productIndex) {
			setData(data?.filter((p, idx) => p.id !== productIndex[0]?.id));
		}
	}, [productIndex]);

	const fetch_products = async () => {
		setLoading(true);
		try {
			const res = await get_clients_list_for_featureds();
			setData(
				res.map((r) => ({
					...r,
					title: r.name,
					quantity: 1,
					key: r.id,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const fetch_featureds = async () => {
		setLoadingPage(true);
		try {
			const res = await get_featured_laboratories();
			setHomeSliderLaboratories(
				res.sliderHome?.map((p, idx) => ({
					key: p.id,
					id: p.id,
					title: p.name,
					photoUrl: p.photoUrl,
					index: idx,
				}))
			);
		} catch (error) {
		} finally {
			setLoadingPage(false);
		}
	};

	useEffect(() => {
		fetch_featureds();
		fetch_products();
	}, [flag]);

	const productColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			width: 90,
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image width={40} height={40} src={value} alt={record.title} />
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Laboratorio</div>,
			dataIndex: "title",
			key: "title",
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 18 : 16}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.presentationName}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			width: 100,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(index);
						}}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	const featuredsColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 90,
			render: (photoUrl) => <Image src={photoUrl} height={40} width={40} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Laboratorio</div>,
			dataIndex: "title",
			key: "title",
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 16 : 14}
					>
						{t}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Quitar</div>,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button type="link" onClick={() => handleDelete(index)}>
						<CartDelete>X</CartDelete>
					</Button>
				</Space>
			),
		},
	];

	//////******************************************************** */

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => idx === e);
		productIndex = _template;
		const auxTemplate = homeSliderLaboratories.concat(_template);

		const TemplateSinDuplicados = auxTemplate.reduce(
			(acumulador, valorActual) => {
				const elementoYaExiste = acumulador.find(
					(elemento) => elemento.id === valorActual.id
				);
				if (elementoYaExiste) {
					return acumulador.map((elemento) => {
						if (elemento.id === valorActual.id) {
							return {
								...elemento,
								quantity: elemento.quantity + valorActual.quantity,
							};
						}
						return elemento;
					});
				}

				return [...acumulador, valorActual];
			},
			[]
		);
		setHomeSliderLaboratories(
			TemplateSinDuplicados.sort((a, b) => {
				const titleA = a.title.toLowerCase();
				const titleB = b.title.toLowerCase();
				if (titleA < titleB) {
					return -1;
				}
				if (titleA > titleB) {
					return 1;
				}

				return 0;
			})
		);
	};

	//////******************************************************** */

	const handleDelete = (index) => {
		const _template = homeSliderLaboratories.filter((p, idx) => idx !== index);
		setHomeSliderLaboratories(_template);
	};

	//////******************************************************** */

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido completados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/");
			},
		});
	};

	const onFinish = async (values) => {
		try {
			if (homeSliderLaboratories.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe seleccionar al menos un Laboratorio para destacar en el home"
				);
				return;
			} else if (homeSliderLaboratories.length > 30) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"La cantidad de Laboratorios para destacar en el slider no puede ser mayor a 30"
				);
				return;
			}

			setSubmitting(true);

			const postData = {
				featureds: {
					sliderHome:
						homeSliderLaboratories?.map((element, idx) => {
							return { id: element?.id, index: idx };
						}) || [],
				},
			};

			await update_featureds_labs(postData);
			window.scrollTo(0, 0);
			setFlag((prev) => !prev);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Destacados creados con exito",
				"Los destacados se crearon con exito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear destacados",
				"Un error ocurrio al intentar crear los destacados, contacte con soporte a soporte@onetransfer.com."
			);
			console.log(error);
		} finally {
			setSubmitting(false);
		}
	};

	if (loadingPage) return <LoadingSkeleton />;

	return (
		<Inner>
			<Container>
				<Section>
					<Typo type="primary" level={6}>
						Listado Laboratorios
					</Typo>
					<LaboratoriesTable
						data={data}
						columns={productColumns}
						loading={loading}
						scrollData={{ y: 700 }}
					/>
				</Section>
				<Section>
					<Typo type="primary" level={6}>
						Laboratorios en Slider Home
					</Typo>
					<AntTableDragDropGeneral
						data={homeSliderLaboratories}
						setData={setHomeSliderLaboratories}
						columns={featuredsColumns}
						scroll={{ y: 700 }}
					/>
				</Section>
			</Container>
			<ButtonContainer>
				<SignInTemplate.AntdButton
					type="Primary"
					color="white"
					bg="Primary"
					onClick={onFinish}
					loading={submitting}
				>
					Guardar cambios
				</SignInTemplate.AntdButton>
				<SignInTemplate.AntdButton
					type="Primary"
					loading={submitting}
					onClick={handleBack}
				>
					<Typo type="danger" level={6}>
						Salir sin guardar
					</Typo>
				</SignInTemplate.AntdButton>
			</ButtonContainer>
		</Inner>
	);
}
