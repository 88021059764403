import styled from "styled-components";

export const Inner = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;

	& input {
		text-align: center;
	}
`;
