import { Table } from "antd";
import { COLORS, Typo } from "../../../../../layout/theme";
import ProductQuantity from "../product-quantity/ProductQuantity";
import { ContainerDiv, PriceAndDiscountContainer } from "./styles";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import Image from "../../../../../common/image/Image";
import { FaImages } from "react-icons/fa";

export default function ProductTable({
	data,
	columns,
	scrollData = null,
	onChange,
}) {
	const defColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Ean</div>,
			dataIndex: "ean13",
			key: "ean13",
			render: (value, record, index) => (
				<Typo type="primary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			width: 100,
			align: "center",
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image width={40} height={40} src={value} alt={record.title} />
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			filters: data.map((p) => ({
				key: p.id,
				text: p.title + " " + p.presentationName,
				value: p.title + " " + p.presentationName,
			})),
			onFilter: (value, record) =>
				(record.title + " " + record.presentationName).startsWith(value),
			filterSearch: true,
			render: (value, record, index) => (
				<Typo type="primary" level={6}>
					{value} {record.presentationName}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "clientGeneralDiscount",
			key: "clientGeneralDiscount",
			render: (v, r, idx) => (
				<div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
					<PriceAndDiscountContainer>
						<Typo type="success" level={6}>
							-{numberWithDotAndCommas(r.discount.toFixed(2))}% PSL
						</Typo>
						<Typo type="secondary" level={7} translate="no">
							-
							{numberWithDotAndCommas(
								(100 - (r.offerPrice * 100) / r.price).toFixed(2)
							)}
							% PVP
						</Typo>
					</PriceAndDiscountContainer>
					{parseInt(r.triggerQty) !== 0 && (
						<Typo
							level={8}
							type="primary"
							style={{ textAlign: "start" }}
							translate="no"
						>
							{r.triggerQty} un. o más -{r.triggerDiscount}% OFF
						</Typo>
					)}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cantidad pedido</div>,
			dataIndex: "quantity",
			key: "quantity",
			width: 180,
			render: (quantity, record, index) => (
				<ProductQuantity
					product={{ quantity, record, index }}
					onChange={onChange}
				/>
			),
		},
	];

	return (
		<ContainerDiv>
			<Table
				showHeader={true}
				columns={columns ? columns : defColumns}
				dataSource={data}
				pagination={false}
				rowKey={(record) => record.id}
				scroll={scrollData}
			/>
		</ContainerDiv>
	);
}
