import { Skeleton, Row, Col, Space } from "antd";
import { Inner, ClientData, TableHeader } from "./styles";
import Main from "../../../../../../layout/main/Main";

const GUTTER = 10;

export default function LoadingSkeleton() {
  return (
    <Inner>
      <ClientData>
        <Main.Body>
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 250, marginBottom: -10, marginTop: -20 }}
                />
                <Skeleton.Input active />
              </Col>
            </Row>
            <Row>
              <Col>
                <Skeleton.Image width={150} height={150} alt="avatar" />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 250, marginBottom: -10, marginTop: -20 }}
                />
                <Skeleton.Input active={true} block />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 350, marginBottom: -10, marginTop: -20 }}
                />
                <Skeleton.Input active={true} block />
              </Col>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 250, marginBottom: -10, marginTop: -20 }}
                />
                <Skeleton.Input active={true} block />
              </Col>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 250, marginBottom: -10, marginTop: -20 }}
                />
                <Skeleton.Input active={true} block />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 250, marginBottom: -10, marginTop: -20 }}
                />
                <Skeleton.Input active={true} block />
              </Col>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 350, marginBottom: -10, marginTop: -20 }}
                />
                <Skeleton.Input active={true} block />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={24}>
                <TableHeader></TableHeader>
              </Col>
            </Row>
            <Row gutter={GUTTER}>
              <Col span={4}>
                <Skeleton
                  avatar
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 0, marginBottom: -10, marginTop: -10 }}
                />
              </Col>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{
                    width: 550,
                    marginBottom: -10,
                    marginTop: -10,
                    marginLeft: -50,
                  }}
                />
              </Col>
              <Col span={6}></Col>
              <Col span={1}>
                <Skeleton.Button
                  active
                  style={{
                    marginTop: -7,
                    marginLeft: -200,
                  }}
                  block
                />
              </Col>
            </Row>
            <Row gutter={GUTTER}>
              <Col span={4}>
                <Skeleton
                  avatar
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 0, marginBottom: -10, marginTop: -10 }}
                />
              </Col>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{
                    width: 550,
                    marginBottom: -10,
                    marginTop: -10,
                    marginLeft: -50,
                  }}
                />
              </Col>
              <Col span={6}></Col>
              <Col span={1}>
                <Skeleton.Button
                  active
                  style={{
                    marginTop: -7,
                    marginLeft: -200,
                  }}
                  block
                />
              </Col>
            </Row>
            <Row gutter={GUTTER}>
              <Col span={4}>
                <Skeleton
                  avatar
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 0, marginBottom: -10, marginTop: -10 }}
                />
              </Col>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{
                    width: 550,
                    marginBottom: -10,
                    marginTop: -10,
                    marginLeft: -50,
                  }}
                />
              </Col>
              <Col span={6}></Col>
              <Col span={1}>
                <Skeleton.Button
                  active
                  style={{
                    marginTop: -7,
                    marginLeft: -200,
                  }}
                  block
                />
              </Col>
            </Row>
            <Row gutter={GUTTER}>
              <Col span={4}>
                <Skeleton
                  avatar
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 0, marginBottom: -10, marginTop: -10 }}
                />
              </Col>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{
                    width: 550,
                    marginBottom: -10,
                    marginTop: -10,
                    marginLeft: -50,
                  }}
                />
              </Col>
              <Col span={6}></Col>
              <Col span={1}>
                <Skeleton.Button
                  active
                  style={{
                    marginTop: -7,
                    marginLeft: -200,
                  }}
                  block
                />
              </Col>
            </Row>
            <Row gutter={GUTTER}>
              <Col span={4}>
                <Skeleton
                  avatar
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 0, marginBottom: -10, marginTop: -10 }}
                />
              </Col>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{
                    width: 550,
                    marginBottom: -10,
                    marginTop: -10,
                    marginLeft: -50,
                  }}
                />
              </Col>
              <Col span={6}></Col>
              <Col span={1}>
                <Skeleton.Button
                  active
                  style={{
                    marginTop: -7,
                    marginLeft: -200,
                  }}
                  block
                />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row>
              <Col span={2}>
                <Skeleton.Button
                  active={true}
                  block
                  style={{ marginTop: -20 }}
                />
              </Col>
            </Row>
          </Space>
        </Main.Body>
      </ClientData>
    </Inner>
  );
}
