import styled from "styled-components";
import { COLORS } from "../../../../../layout/theme";

export const Inner = styled.div`
  display: flex;
  gap: 50px;
`;
export const ClientData = styled.div`
  flex: 1 1 80%;
`;

export const Table = styled.div`
  height: 112px;
  width: 650px;
  background: ${COLORS.SkeletonGrey};
  margin-top: -10px;
`;
