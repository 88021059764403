import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/authContext";
import { ConfigProvider } from "antd";
import esEs from "antd/es/locale/es_ES";
import ReactGA from "react-ga4";

ReactGA.initialize(
	process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || "test",
	{ debug: true }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<ConfigProvider locale={esEs}>
			<AuthProvider>
				<App />
			</AuthProvider>
		</ConfigProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
