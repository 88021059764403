import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../configuration/firebase";
import {
	signInWithEmailAndPassword,
	GoogleAuthProvider,
	signInWithPopup,
	applyActionCode,
	signOut,
	sendPasswordResetEmail,
	updatePassword,
	signInWithRedirect,
	onAuthStateChanged,
	confirmPasswordReset,
	linkWithPopup,
	unlink,
	linkWithPhoneNumber,
	RecaptchaVerifier,
} from "@firebase/auth";
import {
	doc,
	onSnapshot,
	updateDoc,
	query,
	collection,
	where,
	getDocs,
	getDoc,
} from "@firebase/firestore";
import { get_clients_request_header_info } from "../api/endpoints/clients";
import moment from "moment";
import {
	get_orders_requests_header_info,
	get_total_count_orders,
} from "../api/endpoints/orders";
import "moment/locale/es";
import checkBrowser from "../utils/checkBrowser";

moment.locale("es");

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [ready, setReady] = useState(false);
	const [error, setError] = useState("");

	const [changePhoto, setChangePhoto] = useState(0);
	const [photoKey, setPhotoKey] = useState(moment().milliseconds());

	const [user, setUser] = useState();
	const [userdata, setUserdata] = useState();

	const [requestsQty, setRequestsQty] = useState(0);
	const [mobileRequestsQty, setMobileRequestsQty] = useState(0);
	const [ordersQty, setOrdersQty] = useState(0);
	const [browserInfo, setBrowserInfo] = useState(null);

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			setUser(user);

			if (user) {
				onSnapshot(doc(db, "users", user.uid), (doc) => {
					if (doc.data()) {
						setUserdata(doc.data());
					} else {
						setUserdata(null);
					}
				});
			}
		});
	}, []);

	useEffect(() => {
		localStorage.setItem("user", JSON.stringify(user));
		localStorage.setItem("user-data", JSON.stringify(userdata));
		setReady(user && userdata);

		if (user && userdata?.isOneMarket) {
			const fetch_clients_request = async () => {
				const res = await get_clients_request_header_info();
				setRequestsQty(res.totalPending);
			};
			const fetch_orders_request = async () => {
				const res2 = await get_orders_requests_header_info();
				setMobileRequestsQty(res2.totalPending);
			};
			const fetch_orders_count = async () => {
				const res3 = await get_total_count_orders();
				setOrdersQty(res3.orders?.totalActiveOrders);
			};
			fetch_clients_request();
			fetch_orders_request();
			fetch_orders_count();
		}

		setBrowserInfo(checkBrowser());
	}, [user, userdata]);

	function login(email, password) {
		return signInWithEmailAndPassword(auth, email, password);
	}

	function logout() {
		return signOut(auth);
	}

	function resetPassword(email) {
		return sendPasswordResetEmail(auth, email, {
			url: "http://localhost:3000/",
		});
	}

	function changePassword(password) {
		return updatePassword(auth.currentUser, password);
	}

	// Esta la cree para el reset password luego de que te llegue el mail cuando te la olvidas
	function newPassword(oobCode, newPassword) {
		return confirmPasswordReset(auth, oobCode, newPassword);
	}

	function updateTermsOfService() {
		updateDoc(doc(db, "users", user.uid), {
			termsOfService: true,
		});
	}

	function updateRequestValidation(userId, validation) {
		updateDoc(doc(db, "users", userId), {
			validated: validation,
		});
	}

	function updateStartGuideProgress(userId, progress) {
		updateDoc(doc(db, "users", userId), {
			guideProgress: progress,
		});
	}

	function loginWithGooglePopup() {
		const provider = new GoogleAuthProvider();
		provider.addScope("profile");
		provider.addScope("email");
		signInWithPopup(auth, provider);
	}

	function loginWithGoogleRedirect() {
		const provider = new GoogleAuthProvider();
		provider.addScope("profile");
		provider.addScope("email");
		signInWithRedirect(auth, provider);
	}

	function connectGoogle(callback) {
		const provider = new GoogleAuthProvider();
		linkWithPopup(user, provider)
			.then((res) => {
				const credential = GoogleAuthProvider.credentialFromResult(res);
				const user = res.user;
				callback(true);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function disconnectGoogle() {
		unlink(user, "google.com");
	}

	async function connectPhone(phoneNumber) {
		const recaptchaVerifier = new RecaptchaVerifier(
			"recaptcha",
			{
				size: "invisible",
			},
			auth
		);
		recaptchaVerifier.render();
		return linkWithPhoneNumber(
			auth.currentUser,
			phoneNumber,
			recaptchaVerifier
		);
	}

	function disconnectPhone() {
		unlink(auth.currentUser, "phone");
	}

	function applyCode(code) {
		return applyActionCode(auth, code);
	}

	function reloadUser() {
		return auth.currentUser.reload();
	}

	async function checkClient(clientId) {
		const q = query(collection(db, "users"), where("clientId", "==", clientId));

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			if (doc.data().isAdmin) {
				return doc.data().validated;
			}
		});
	}

	async function findUserInFirestore(userId) {
		try {
			const userDocRef = doc(db, "users", userId);
			const userDocSnapshot = await getDoc(userDocRef);

			if (userDocSnapshot.exists()) {
				const userData = userDocSnapshot.data();
				return userData;
			} else {
				console.log("No se encontró un documento para el userId:", userId);
				return null;
			}
		} catch (error) {
			console.error("Error al cargar datos de Firestore:", error);
			return null;
		}
	}

	function updateVerifiedEmail(userId, state) {
		updateDoc(doc(db, "users", userId), {
			emailVerified: state,
		});
	}

	const value = {
		user,
		userdata,
		error,
		ready,
		login,
		logout,
		resetPassword,
		changePassword,
		loginWithGooglePopup,
		loginWithGoogleRedirect,
		setError,
		applyCode,
		reloadUser,
		newPassword,
		connectGoogle,
		disconnectGoogle,
		connectPhone,
		disconnectPhone,
		updateTermsOfService,
		updateRequestValidation,
		setRequestsQty,
		requestsQty,
		setMobileRequestsQty,
		mobileRequestsQty,
		setOrdersQty,
		ordersQty,
		checkClient,
		changePhoto,
		setChangePhoto,
		photoKey,
		setPhotoKey,
		updateStartGuideProgress,
		findUserInFirestore,
		updateVerifiedEmail,
		browserInfo,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
