import React from "react";
import { Link } from "react-router-dom";
import Main from "../../../../../layout/main/Main";
import { Typo } from "../../../../../layout/theme";
import { AntdButton, CardContainer, Inner } from "./styles";

export default function CreateBook({ cb }) {
	return (
		<Main>
			<Main.Body>
				<Inner>
					<CardContainer>
						<Typo type="primary" level={3}>
							No posees un libro de reposición
						</Typo>
						<AntdButton onClick={() => cb()}>Crear Libro</AntdButton>
					</CardContainer>
				</Inner>
			</Main.Body>
		</Main>
	);
}
