import styled from "styled-components";
import { Modal, Upload } from "antd";
import { Button, Table } from "antd";
import { COLORS } from "../../../../layout/theme";

export const Buttons = styled.div`
	margin: 20px 0;
	display: flex;
	gap: 20px;

	& button {
		padding: 0 !important;
	}

	@media only screen and (min-width: 760px) and (max-width: 1200px) {
		flex-direction: column;
		height: 150px;
	}
`;

export const UploadFile = styled(Upload)`
	width: 150px;
	display: flex;
	justify-content: center;
	background-color: ${COLORS.Primary};
	color: ${COLORS.White};
	align-items: center;
	border: 1px solid ${COLORS.Primary};
	padding: 0.5rem 0;
	margin-bottom: 1rem;
	cursor: pointer;
	&:hover {
		background-color: ${COLORS.Primary};
	}
	& span {
		color: ${COLORS.White};
	}
`;

export const AntdButton = styled(Button)`
	width: 150px;
	height: 40px;
	background-color: ${COLORS.Success};
	color: ${COLORS.White};
	border: 1px solid ${COLORS.Success};
	padding: 0.5rem 0;
	&:hover {
		background-color: ${COLORS.Success};
	}
	& span {
		color: ${COLORS.White};
	}
`;

export const TableContainer = styled.div`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: transparent !important;
		border: none;
	}

	& .hasChanges,
	& .hasChanges:hover {
		color: white;
		background-color: ${COLORS.Default};
	}
`;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const AntdModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
	}

	.ant-modal-header {
		border-radius: 10px 10px 0 0;
	}
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
		padding: 1rem 5rem 1rem 5rem;
		border-radius: 0 0 10px 10px;
	}
	.ant-modal-footer {
		text-align: left;
		height: 80px;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;
