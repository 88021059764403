import styled from "styled-components";
import { COLORS } from "../../../../../../layout/theme";
import { Table } from "antd";

export const Container = styled.div`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};

		&.statusColumn {
			background: ${COLORS.Secondary};
		}
	}

	.ant-table-cell-scrollbar:not([rowspan]) {
		background: ${COLORS.Secondary} !important;
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: transparent !important;
		border: none;
	}

	& .hasChanges,
	& .hasChanges:hover {
		color: white;
		background-color: ${COLORS.Default};
	}

	& .statusColumn {
		color: white;
		background-color: ${COLORS.LightDefault};
	}
`;
