import React from "react";
import Box from "../box/Box";
import { COLORS, Typo } from "../../../../../layout/theme";
import {
	AntdTable,
	BigChartContainer,
	Container,
	Dashboard1Container,
	FilterContainer,
	IconContainer,
	IconGroupContainer,
} from "./styles";
import { Button, DatePicker, Select } from "antd";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { BiBox, BiCoinStack, BiDollar } from "react-icons/bi";
import Image from "../../../../../common/image/Image";
import NoImage from "../../../../../../assets/images/Fallback.png";

export default function Dashboard7({
	dashboard,
	distributors,
	selectDistributor,
	laboratories,
	selectLaboratory,
	handleDateChange,
	deleteFilters,
	isLoading,
	selectedLaboratory,
	selectedDistributor,
	rangeDate,
	laboratoryTable,
	selectLaboratoryInfo,
	laboratoryInfoSelector,
}) {
	const columns = [
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}></div>,
			dataIndex: "photoUrl",
			align: "center",
			width: 140,
			render: (value, record, index) => (
				<Image
					width={50}
					height={50}
					src={value ? value : NoImage}
					alt={record.laboratoryName ? record.laboratoryName : "No image"}
					borderRadius={40}
				/>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.DarkGrey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Laboratorio
				</div>
			),
			width: 250,
			dataIndex: "laboratory",
			render: (value, record) => (
				<div style={{ paddingLeft: 20 }}>
					<Typo level={5}>{value}</Typo>
				</div>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.DarkGrey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Ventas
				</div>
			),
			width: 400,
			dataIndex: "totals",
			render: (value, record) => (
				<div style={{ display: "flex", justifyContent: "center" }}>
					{record.totals.totalSales ? (
						<Typo type="primary" level={3}>
							${" "}
							{numberWithDotAndCommas(
								parseFloat(record.totals.totalSales).toFixed(0)
							)}
						</Typo>
					) : (
						<Typo type="primary" level={3}>
							Sin Datos
						</Typo>
					)}
				</div>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.DarkGrey,
						display: "flex",
						justifyContent: "center",
						gap: 20,
					}}
				>
					Evolución{" "}
					{laboratoryInfoSelector === "SALES"
						? "Ventas"
						: laboratoryInfoSelector === "UNITS"
						? "Unidades"
						: "Ordenes"}
					<IconGroupContainer>
						<IconContainer
							onClick={() => selectLaboratoryInfo("SALES")}
							height={25}
							width={25}
							$active={laboratoryInfoSelector === "SALES"}
						>
							<BiDollar size={18} />
						</IconContainer>
						<IconContainer
							onClick={() => selectLaboratoryInfo("UNITS")}
							height={25}
							width={25}
							$active={laboratoryInfoSelector === "UNITS"}
						>
							<BiCoinStack size={18} />
						</IconContainer>
						<IconContainer
							onClick={() => selectLaboratoryInfo("ORDERS")}
							height={25}
							width={25}
							$active={laboratoryInfoSelector === "ORDERS"}
						>
							<BiBox size={18} />
						</IconContainer>
					</IconGroupContainer>
				</div>
			),
			dataIndex: "salesGraph",
			width: 400,
			render: (value, record) => (
				<>
					{laboratoryInfoSelector === "SALES" && (
						<Box
							graphData={record.salesGraph}
							isLoading={isLoading}
							regionInfo={laboratoryInfoSelector}
							type="regionTableBox"
						/>
					)}
					{laboratoryInfoSelector === "UNITS" && (
						<Box
							graphData={record.unitsGraph}
							isLoading={isLoading}
							regionInfo={laboratoryInfoSelector}
							type="regionTableBox"
						/>
					)}
					{laboratoryInfoSelector === "ORDERS" && (
						<Box
							graphData={record.ordersGraph}
							isLoading={isLoading}
							regionInfo={laboratoryInfoSelector}
							type="regionTableBox"
						/>
					)}
				</>
			),
		},
	];

	return (
		<Dashboard1Container>
			<FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Fecha (desde/hasta)
					</Typo>
					{rangeDate ? (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							value={rangeDate}
							onChange={(e) => handleDateChange(e)}
						/>
					) : (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							onChange={(e) => handleDateChange(e)}
						/>
					)}
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Droguería
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Droguería"
						optionFilterProp="children"
						value={selectedDistributor}
						// defaultValue={selectedDistributor ?? null}
						onChange={(e, o) => selectDistributor(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 180 }}
					/>
				</Container>
				{/* <Container>
					<Typo type="primary" level={6}>
						Provincia
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Provincia"
						optionFilterProp="children"
						value={selectedProvince}
						// defaultValue={selectedProvince ?? null}
						onChange={(e, o) => selectProvince(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={provinces}
						style={{ width: 220 }}
					/>
				</Container> */}
				<Container>
					<Typo type="primary" level={6}>
						Laboratorio
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Laboratorio"
						optionFilterProp="children"
						value={selectedLaboratory}
						// defaultValue={selectedLaboratory ?? null}
						onChange={(e, o) => selectLaboratory(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={laboratories}
						style={{ width: 180 }}
					/>
				</Container>
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</FilterContainer>
			<BigChartContainer>
				<AntdTable
					dataSource={laboratoryTable}
					loading={isLoading}
					columns={columns}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
			</BigChartContainer>
		</Dashboard1Container>
	);
}
