import React from "react";
import {
  Main,
  Inner,
  Image,
  Divider,
  Column,
  Copy,
  Titles,
  Info,
  Bottom,
} from "./styles";

const Footer = ({ children, ...restProps }) => (
  <Inner {...restProps}>{children}</Inner>
);

export default Footer;

Footer.Main = ({ children, ...restProps }) => {
  return <Main {...restProps}>{children}</Main>;
};

Footer.Divider = ({ children, ...restProps }) => {
  return <Divider {...restProps}>{children}</Divider>;
};

Footer.Column = ({ children, ...restProps }) => {
  return <Column {...restProps}>{children}</Column>;
};

Footer.Image = ({ children, ...restProps }) => {
  return <Image {...restProps}>{children}</Image>;
};

Footer.Copy = ({ children, ...restProps }) => {
  return <Copy {...restProps}>{children}</Copy>;
};

Footer.Titles = ({ children, ...restProps }) => {
  return <Titles {...restProps}>{children}</Titles>;
};

Footer.Info = ({ children, ...restProps }) => {
  return <Info {...restProps}>{children}</Info>;
};

Footer.Bottom = ({ children, ...restProps }) => {
  return <Bottom {...restProps}>{children}</Bottom>;
};
