import React, { useState } from "react";
import { Button, Input, Modal } from "antd";
import ProductSearchCard from "../../common/product-search-cards/card/ProductSearchCard";
import Image from "../../common/image/Image";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { FaShoppingCart } from "react-icons/fa";
import { COLORS, SIZES, Typo, WEIGHT } from "../../layout/theme";
import { useCart } from "../../../contexts/cartContext";
import {
	Line,
	Container,
	ItemsQty,
	CardContainer,
	MarketingStar,
	SpecialOfferContainer,
	OffContainer,
	TriggerQtyContainer,
	SectionOffer,
	MinQtyContainer,
	PslContainer,
	LeftContainer,
	CenterContainer,
	RightContainer,
	TopContainer,
	TitleContainer,
	HeaderLeft,
} from "./style";
import { useAuth } from "../../../contexts/authContext";
import ProductModal from "../../common/product-modal/ProductModal";
import { add_view } from "../../../api/endpoints/products";
import { numberWithDotAndCommas } from "../../../utils/numberWithDotAndComas";
import { IoMegaphoneOutline } from "react-icons/io5";
import { add_user_interaction } from "../../../api/endpoints/users";
import { compareWithToday } from "../../../utils/compareDates";

export default function ProductSearchCardContainer({ item }) {
	const { addToCart } = useCart();
	const [quantity, setQuantity] = useState(
		item.productQtyInCart
			? item.productQtyInCart
			: item.minQuantity
			? item.minQuantity
			: 1
	);
	const [submitting, setSubmitting] = useState(false);
	const [qtyInCart, setQtyInCart] = useState(
		item.productQtyInCart ? item.productQtyInCart : 0
	);
	const [isHover, setIsHover] = useState(false);
	const { userdata } = useAuth();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [showAddToCart, setShowAddToCart] = useState(
		item.productQtyInCart ? true : false
	);

	const handleDecrement = async () => {
		setSubmitting(true);
		let res;
		let newQuantity = quantity;
		if (quantity > item.minQuantity && quantity > 1) {
			newQuantity -= 1;
			setQuantity((prev) => prev - 1);
		}
		if (newQuantity <= item.minQuantity) {
			res = await addToCart(item.productId, item.minQuantity, false);
		} else {
			res = await addToCart(item.productId, 1, false);
		}
		if (res === 0) {
			setQtyInCart(res);
			setQuantity(item.minQuantity > 0 ? item.minQuantity : 1);
			item.productQtyInCart = 0;
			setShowAddToCart(false);
		} else {
			setQtyInCart(res);
			setQuantity(res);
			item.productQtyInCart = res;
			setShowAddToCart(true);
		}
		setSubmitting(false);
	};

	const handleChange = (e) => {
		if (e.target.value <= item.minQuantity) {
			e.target.value = item.minQuantity;
			e.target.setSelectionRange(0, 0);
			e.target.focus();
			e.target.select();
		}
		if (e.target.value >= item.maxQuantity) e.target.value = item.maxQuantity;
		const onlyPositiveInteger = /^[0-9\b]+$/;
		if (onlyPositiveInteger.test(e.target.value)) {
			setQuantity(parseInt(e.target.value));
		}
	};

	const handleIncrement = async (e) => {
		setSubmitting(true);
		setQuantity((prev) => prev + 1);
		if (quantity >= item.maxQuantity) {
			setQuantity((prev) => prev - 1);
		} else {
			const res = await addToCart(item.productId, 1);
			setQtyInCart(res);
			setQuantity(res);
			item.productQtyInCart = res;
			setShowAddToCart(true);
			setSubmitting(false);
		}
	};

	const handleAddToCart = async () => {
		setSubmitting(true);
		const res = await addToCart(item.productId, quantity);
		setQtyInCart(res);
		setQuantity(item.minQuantity);
		item.productQtyInCart = res;
		setShowAddToCart(true);
		setSubmitting(false);
	};

	const handleFocus = (event) => event.target.select();

	const handleMouseOver = () => {
		setIsHover(true);
	};
	const handleMouseOut = () => {
		setIsHover(false);
	};

	const handleDetail = async () => {
		setIsModalVisible(true);
		if (!userdata?.isSeller && !userdata?.isOneMarket) {
			await add_view(item.productId);
			await add_user_interaction({ productId: item.productId, inCart: false });
		}
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<ProductSearchCard>
			<CardContainer onClick={handleDetail}>
				<ProductSearchCard.SectionContainer>
					<ProductSearchCard.Header>
						<HeaderLeft>
							<Container>
								<Image src={item.clientPhotoUrl} width="19px" />
								<Typo
									texto="light"
									type="muted"
									fontSize={9}
									style={{ letterSpacing: "0px", marginLeft: "6px" }}
								>
									{item.clientName.length > 26
										? item.clientName.slice(0, 20).toUpperCase() + "..."
										: item.clientName.toUpperCase()}
								</Typo>
							</Container>
							{/* <ProductSearchCard.HeaderLeft> */}
							<TitleContainer>
								<Typo
									type="primary"
									lineHeight={1}
									texto="medium"
									fontSize={item.title.length < 15 ? 18 : 16}
								>
									{item.title}{" "}
									<Typo type="muted" lineHeight={1} level={5}>
										{item.presentationName}
									</Typo>
								</Typo>
							</TitleContainer>
							{/* </ProductSearchCard.HeaderLeft> */}
						</HeaderLeft>
					</ProductSearchCard.Header>
				</ProductSearchCard.SectionContainer>
			</CardContainer>
			<CardContainer onClick={handleDetail}>
				<ProductSearchCard.Main>
					<TopContainer>
						<LeftContainer>
							{/* {item.marketingActive && (
								<MarketingStar>
									<IoMegaphoneOutline />
								</MarketingStar>
							)} */}
						</LeftContainer>
						<CenterContainer>
							<ItemsQty>
								{qtyInCart === 0
									? " "
									: qtyInCart === 1
									? `${qtyInCart} agregado`
									: `${qtyInCart} agregados`}
							</ItemsQty>
						</CenterContainer>
						<RightContainer>
							{item?.discount > 0 &&
								item?.offerTo &&
								compareWithToday(item?.offerTo) && (
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											zIndex: 4,
											overflow: "visible",
											position: "absolute",
										}}
									>
										<PslContainer>PSL</PslContainer>
										<PslContainer
											style={{
												fontSize: SIZES.level4,
												fontWeight: WEIGHT.ExtraBold,
												marginTop: "-7px",
											}}
										>
											-{item?.discount.toFixed(0)}%
										</PslContainer>
									</div>
								)}
						</RightContainer>
					</TopContainer>
					<Image height={200} src={item.photoUrl} alt={item.title} />
					<SectionOffer
						style={{
							backgroundColor:
								item?.triggerQty > 0
									? COLORS.Success
									: item?.minQuantity > 1
									? COLORS.White
									: COLORS.White,
							color: COLORS.White,
						}}
					>
						{item.triggerQty > 0 ? (
							<TriggerQtyContainer style={{ color: COLORS.White }}>
								Comprando {item.triggerQty} o más
							</TriggerQtyContainer>
						) : item.minQuantity > 1 ? (
							<MinQtyContainer>
								Compra mínima {item.minQuantity} un.
							</MinQtyContainer>
						) : (
							` `
						)}
						{item.triggerQty > 0 && (
							<SpecialOfferContainer>
								<OffContainer style={{ color: COLORS.White }} translate="no">
									OFF
								</OffContainer>
								<Typo level={5} type="white">
									{`${numberWithDotAndCommas(
										(
											100 -
											((item.offerPrice -
												(item.offerPrice * item.triggerDiscount) / 100) *
												100) /
												item.price
										).toFixed(2)
									)}%`}
								</Typo>
							</SpecialOfferContainer>
						)}
					</SectionOffer>
				</ProductSearchCard.Main>
			</CardContainer>
			<Modal
				title={
					<div style={{ height: 30 }}>
						<Image
							src={item.clientPhotoUrl}
							style={{ objectFit: "contain", width: 30 }}
						/>{" "}
						<Typo level={8} type="muted">
							{item.clientName.slice(0, 20).toUpperCase()}
						</Typo>
					</div>
				}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={900}
				centered={true}
				footer={null}
			>
				<ProductModal
					id={item.productId}
					quantityInCart={qtyInCart}
					setQuantityInCart={setQtyInCart}
					setIsModalVisible={setIsModalVisible}
					minQuantity={item.minQuantity}
					maxQuantity={item.maxQuantity}
					marketing={item.marketingId}
				/>
			</Modal>
			<ProductSearchCard.Footer>
				<CardContainer
					onClick={handleDetail}
					onMouseEnter={() => handleMouseOver()}
				>
					<ProductSearchCard.SectionContainer>
						<ProductSearchCard.FooterPrices>
							{item.offerPrice !== item.price &&
							item.offerPrice !== null &&
							item.offerPrice > 0 ? (
								<>
									<ProductSearchCard.FooterPricesLeft>
										<ProductSearchCard.FooterPricesLeftTextContainer>
											<ProductSearchCard.FooterPricesLeftTextBox>
												<Typo
													level={8}
													type="primary"
													lineHeight={1.2}
													fontSize={9}
												>
													{item.clientTypeOfSale}
												</Typo>
											</ProductSearchCard.FooterPricesLeftTextBox>
										</ProductSearchCard.FooterPricesLeftTextContainer>
										<Line
											level={6}
											type="primary"
											fontSize={19}
											style={{ position: "relative" }}
										>{`$${numberWithDotAndCommas(item.price)}`}</Line>
									</ProductSearchCard.FooterPricesLeft>
									<ProductSearchCard.FooterPricesRight>
										<ProductSearchCard.FooterPriceTextOff translate="no">
											OFF
										</ProductSearchCard.FooterPriceTextOff>
										<Typo
											level={3}
											type="secondary"
											style={{ paddingLeft: "10px" }}
										>{`${numberWithDotAndCommas(
											(100 - (item.offerPrice * 100) / item.price).toFixed(2)
										)}%`}</Typo>
									</ProductSearchCard.FooterPricesRight>
								</>
							) : (
								<ProductSearchCard.FooterPricesCenter>
									<Typo level={8} type="primary" lineHeight={1.2} fontSize={9}>
										{item.clientTypeOfSale}
									</Typo>
									<Typo
										level={3}
										type="primary"
										style={{ position: "relative" }}
									>{`$${numberWithDotAndCommas(item.price)}`}</Typo>
								</ProductSearchCard.FooterPricesCenter>
							)}
						</ProductSearchCard.FooterPrices>
					</ProductSearchCard.SectionContainer>
				</CardContainer>
				{isHover &&
					!userdata.isSeller &&
					userdata.p_shop &&
					(submitting === true ? (
						<ProductSearchCard.SpinnerSection>
							<ProductSearchCard.Spinner />
						</ProductSearchCard.SpinnerSection>
					) : (
						<ProductSearchCard.FooterInputs
							onMouseLeave={() => handleMouseOut()}
						>
							{!showAddToCart ? (
								<Button
									type="primary"
									onClick={handleAddToCart}
									loading={submitting}
									style={{ borderRadius: "0 0 6px 6px" }}
								>
									<FaShoppingCart style={{ fontSize: "24px" }} />
								</Button>
							) : (
								<>
									<Button
										type="primary"
										onClick={handleDecrement}
										style={{ borderRadius: "0 0 0 6px" }}
									>
										<MinusOutlined />
									</Button>
									<Input
										value={quantity}
										onChange={handleChange}
										onFocus={handleFocus}
										style={{
											pointerEvents: "none",
										}}
									/>
									<Button
										type="primary"
										onClick={handleIncrement}
										style={{ borderRadius: "0 0 6px 0" }}
									>
										<PlusOutlined />
									</Button>
								</>
							)}
						</ProductSearchCard.FooterInputs>
					))}
			</ProductSearchCard.Footer>
		</ProductSearchCard>
	);
}
