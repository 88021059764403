import React, { useEffect, useState } from "react";
import { Input, Form, Col, Row } from "antd";
import { validateCUIT } from "../../../utils/cuitValidator";
import { get_cuit_info } from "../../../api/endpoints/auth";
import { CONSTANTS } from "../../../utils/constants";

const CuitInput = ({
	form,
	size,
	label,
	labelStyle,
	canSubmit,
	setCanSubmit,
	...rest
}) => {
	const [afipData, setAfipData] = useState([]);
	const [isCuitValid, setIsCuitValid] = useState(null);
	const [isAfipDataValidated, setIsAfipDataValidated] = useState(false);
	const [isAfipStatusOk, setIsAfipStatusOk] = useState(false);

	const businessCuit = Form.useWatch("businessCuit", form);
	const businessName = Form.useWatch("businessName", form);

	const fetch_afip_check = async (cuit) => {
		const data = await get_cuit_info(cuit);
		setAfipData(data?.cuitInfo);
		if (data?.cuitInfo?.condicionIVA === CONSTANTS.AFIP_STATUS_OK) {
			form.setFieldsValue({
				businessName: data?.cuitInfo?.razonSocial,
			});
		} else {
			throw new Error("No es responsable inscripto");
		}
	};

	useEffect(() => {
		if (afipData) {
			if (afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK) {
				setIsAfipDataValidated(true);
				setIsAfipStatusOk(true);
				setCanSubmit(true);
			} else {
				setIsAfipDataValidated(true);
				setIsAfipStatusOk(false);
				setCanSubmit(false);
			}
		} else {
			setIsAfipDataValidated(false);
			setIsAfipStatusOk(false);
			setCanSubmit(false);
		}
	}, [afipData]);

	useEffect(() => {
		if (businessCuit && businessCuit.length === 11) {
			try {
				if (validateCUIT(businessCuit)) {
					setIsCuitValid(true);
					fetch_afip_check(businessCuit);
				} else {
					setIsCuitValid(false);
				}
			} catch (error) {
				console.log(error);
				setIsCuitValid(false);
				setCanSubmit(false);
			}
		} else {
			setAfipData(null);
			setIsCuitValid(null);
			setIsAfipDataValidated(null);
			setIsAfipStatusOk(null);
			setCanSubmit(false);
			form.setFieldsValue({
				businessName: null,
			});
		}
	}, [businessCuit]);

	useEffect(() => {
		if (businessCuit && !isCuitValid) {
			form.setFields([
				{
					name: "businessCuit",
					errors: ["CUIT inválido"],
				},
			]);
		}
	}, [isCuitValid, form]);

	const handleChange = () => {
		if (afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK) {
			form.setFieldsValue({
				businessName: afipData?.razonSocial,
			});
		}
	};

	return (
		<Row gutter={10}>
			<Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
				<Form.Item
					label={labelStyle ? <label style={labelStyle}>{label}</label> : label}
					name="businessCuit"
					rules={[
						{
							required: true,
							message: "Este campo es obligatorio",
						},
						{
							len: 11,
							message: "El cuit debe de ser de 11 digitos",
						},
						{
							pattern: new RegExp(/^[0-9\s]*$/),
							message: "Solo numeros positivos",
						},
					]}
					help={
						isCuitValid === false
							? "CUIT inválido"
							: isAfipDataValidated
							? isAfipStatusOk
								? ""
								: "No es responsable inscripto"
							: null
					}
					validateStatus={
						isCuitValid === false
							? "error"
							: isAfipDataValidated
							? isAfipStatusOk
								? "success"
								: "error"
							: ""
					}
					hasFeedback
				>
					<Input size="large" placeholder="CUIT" />
				</Form.Item>
			</Col>
			<Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
				<Form.Item
					label={labelStyle ? <label style={labelStyle}>{label}</label> : label}
					name="businessName"
					rules={[
						{
							required: true,
							message: "Este campo es obligatorio",
						},
					]}
				>
					<Input size="large" placeholder="Razon Social" />
				</Form.Item>
			</Col>
		</Row>
	);
};

export default CuitInput;
