import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Form,
	Input,
	Row,
	Col,
	Space,
	message,
	Select,
	Checkbox,
	Modal,
} from "antd";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import {
	ClientData,
	Container,
	Inner,
	InnerForm,
	PublishSettings,
} from "./styles";
import LoadingSkeleton from "./loading-skeleton/LoadingSkeleton";
import { COLORS, Typo } from "../../../../layout/theme";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import PointOfSaleSelector from "../../../../common/points-of-sale-selector/PointOfSaleSelector";
import { update_user } from "../../../../../api/endpoints/users";
import PhoneInput from "../../../../common/phone-input/PhoneInput";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import { checkRolPermission } from "../../../../../utils/checkRolPermission";
import { regionGroup } from "../../../../../utils/regionGroup";
import UserRegion from "../components/user-region/UserRegion";
import { CloseCircleOutlined } from "@ant-design/icons";
import { CONSTANTS } from "../../../../../utils/constants";

const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function UpdateMarketUser({
	data,
	client,
	checkUpdated,
	closeUpdateModal,
}) {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const [pointsOfSale, setPointsOfSale] = useState([]);
	const [imageUrl, setImageUrl] = useState();
	const navigate = useNavigate();
	const [selectRoles, setSelectRoles] = useState(null);
	const [rol, setRol] = useState(null);
	const [checkOptions, setCheckOptions] = useState(null);
	const [permissionsOptions, setPermissionOptions] = useState(null);

	useEffect(() => {
		let key = "";
		if (client.isOneMarket) {
			key = CONSTANTS.CLIENT_TYPE_ONETRANSFER;
		} else if (client.isSeller) {
			key = CONSTANTS.CLIENT_TYPE_LABORATORY;
			if (
				client.contract?.activeMobileApp &&
				!client.contract?.activeExpoSection
			) {
				key = CONSTANTS.CLIENT_TYPE_LABORATORY_MOBILE;
			}
			if (
				!client.contract?.activeMobileApp &&
				client.contract?.activeExpoSection
			) {
				key = CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO;
			}
			if (
				client.contract?.activeMobileApp &&
				client.contract?.activeExpoSection
			) {
				key = CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO_AND_MOBILE;
			}
		} else {
			key = CONSTANTS.CLIENT_TYPE_PHARMACY;
		}

		switch (key) {
			case CONSTANTS.CLIENT_TYPE_ONETRANSFER:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Mobile",
						value: "mobile",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Administrador General",
						value: "MASTERADMINOT",
					},
					{
						id: 2,
						name: "Administrador de Cuentas",
						value: "CLIENTADMINOT",
					},
					{
						id: 3,
						name: "Analista",
						value: "ANALIST",
					},
					{
						id: 4,
						name: "Vendedor",
						value: "SELLEROT",
					},
					{
						id: 5,
						name: "Vendedor Mobile",
						value: "SELLERMOBILEOT",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_LABORATORY_MOBILE:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Mobile",
						value: "mobile",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Administrador General",
						value: "MASTERADMINLAB",
					},
					{
						id: 2,
						name: "Administrador de Cuentas",
						value: "CLIENTADMINLAB",
					},
					{
						id: 3,
						name: "Analista",
						value: "ANALIST",
					},
					{
						id: 4,
						name: "Vendedor Web",
						value: "SELLERWEBLAB",
					},
					{
						id: 5,
						name: "Vendedor Mobile",
						value: "SELLERMOBILELAB",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Expo",
						value: "expo",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Administrador General",
						value: "MASTERADMINLAB",
					},
					{
						id: 2,
						name: "Administrador de Cuentas",
						value: "CLIENTADMINLAB",
					},
					{
						id: 3,
						name: "Analista",
						value: "ANALIST",
					},
					{
						id: 4,
						name: "Vendedor Web",
						value: "SELLERWEBLAB",
					},
					{
						id: 5,
						name: "Vendedor Expo",
						value: "SELLEREXPOLAB",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO_AND_MOBILE:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Mobile",
						value: "mobile",
					},
					{
						label: "Expo",
						value: "expo",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Administrador General",
						value: "MASTERADMINLAB",
					},
					{
						id: 2,
						name: "Administrador de Cuentas",
						value: "CLIENTADMINLAB",
					},
					{
						id: 3,
						name: "Analista",
						value: "ANALIST",
					},
					{
						id: 4,
						name: "Vendedor Web",
						value: "SELLERWEBLAB",
					},
					{
						id: 5,
						name: "Vendedor Mobile",
						value: "SELLERMOBILELAB",
					},
					{
						id: 6,
						name: "Vendedor Expo",
						value: "SELLEREXPOLAB",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_LABORATORY:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Administrador General",
						value: "MASTERADMINLAB",
					},
					{
						id: 2,
						name: "Administrador de Cuentas",
						value: "CLIENTADMINLAB",
					},
					{
						id: 3,
						name: "Analista",
						value: "ANALIST",
					},
					{
						id: 4,
						name: "Vendedor Web",
						value: "SELLERWEBLAB",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_PHARMACY:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pendientes",
						value: "products",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Administrador General",
						value: "MASTERADMIN",
					},
					{
						id: 2,
						name: "Analista",
						value: "ANALIST",
					},
					{
						id: 3,
						name: "Comprador",
						value: "SELLER",
					},
					{
						id: 4,
						name: "Dependiente",
						value: "DEPENDANT",
					},
				]);
				break;
			default:
				break;
		}
	}, []);

	const permissionsCheck = (rol) => {
		switch (rol) {
			case "MASTERADMIN":
				setCheckOptions([
					"admin",
					"users",
					"shop",
					"dashboard",
					"sales",
					"clients",
					"products",
				]);
				break;

			case "ANALIST":
				setCheckOptions(["dashboard", "sales"]);
				break;

			case "SELLER":
				setCheckOptions(["shop", "dashboard", "sales", "products"]);
				break;

			case "DEPENDANT":
				setCheckOptions(["sales", "products"]);
				break;

			case "MASTERADMINLAB":
				setCheckOptions([
					"admin",
					"clients",
					"users",
					"products",
					"dashboard",
					"sales",
				]);
				break;

			case "CLIENTADMINLAB":
				setCheckOptions(["products", "dashboard", "sales"]);
				break;

			case "SELLERMOBILELAB":
				setCheckOptions(["mobile"]);
				break;

			case "SELLERWEBLAB":
				setCheckOptions(["shop", "dashboard", "sales"]);
				break;

			case "SELLEREXPOLAB":
				setCheckOptions(["expo", "dashboard", "sales"]);
				break;

			case "MASTERADMINOT":
				setCheckOptions([
					"admin",
					"clients",
					"users",
					"products",
					"dashboard",
					"sales",
				]);
				break;

			case "CLIENTADMINOT":
				setCheckOptions(["clients", "products", "dashboard", "sales"]);
				break;

			case "SELLEROT":
				setCheckOptions(["dashboard", "sales", "shop"]);
				break;

			case "SELLERMOBILEOT":
				setCheckOptions(["dashboard", "sales", "shop", "mobile"]);
				break;

			default:
				setCheckOptions([]);
				break;
		}
	};

	useEffect(() => {
		const fetch_market_user_by_id = () => {
			const _user = {
				id: data.id,
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				phone: data.phone.slice(3, data.phone.length),
				phoneAreaCode: data.phone.slice(0, 2),
				clientId: data.clientId,
				p_client: data.p_client,
				p_dashboard: data.p_dashboard,
				p_products: data.p_products,
				p_sales: data.p_sales,
				p_shop: data.p_shop,
				p_users: data.p_users,
				p_mobile: data.p_mobile,
				region: regionGroup(data.userRegion),
			};

			setRol(checkRolPermission(_user, client));

			setImageUrl(data.photoUrl);

			setPointsOfSale(data.pointsOfSale?.map((p) => ({ id: p.pointOfSaleId })));

			setInitialValues(_user);
		};

		fetch_market_user_by_id();
	}, []);

	useEffect(() => {
		permissionsCheck(rol);
	}, [rol]);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const arr = [];

			if (!rol) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Se requiere un Rol",
					"Esta intentado crear un usuario sin asignarle un rol"
				);
				return;
			}

			if (
				(rol === "SELLERMOBILELAB" && values.region) ||
				(rol === "SELLERMOBILEOT" && values.region)
			) {
				const transfromArray = values.region.map((i) => {
					if (typeof i.province === "undefined") {
						return false;
					} else if (i.locality.length === 0) {
						return `ARG-${i.province}`;
					} else {
						return i.locality.map((a) => `ARG-${i.province}-${a}`);
					}
				});
				transfromArray.forEach((g) => {
					if (typeof g === "string") {
						arr.push(g);
					} else if (typeof g === "boolean") {
						return null;
					} else {
						arr.push(...g);
					}
				});
			}

			if (client.isSeller === false) {
				pointsOfSale?.filter((p) => p.selected).map((p) => p.id);
			}

			if (
				(rol === "MASTERADMIN" || rol === "SELLER") &&
				pointsOfSale?.filter((p) => p.selected).length === 0
			) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Se requiere Punto de Venta",
					"Esta intentado actualizar un rol que requiere tener asignado al menos un Punto de Venta"
				);
				return;
			}

			const postData = {
				id: data.id,
				firstName: values.firstName,
				lastName: values.lastName,
				email: values.email,
				phone: `${values.phoneAreaCode}${values.phone}`,
				pointsOfSale: pointsOfSale?.filter((p) => p.selected).map((p) => p.id),
				p_users: checkOptions.some((o) => o === "users"),
				p_dashboard: checkOptions.some((o) => o === "dashboard"),
				p_products: checkOptions.some((o) => o === "products"),
				p_sales: checkOptions.some((o) => o === "sales"),
				p_shop: checkOptions.some((o) => o === "shop" || o === "expo"),
				p_client: checkOptions.some((o) => o === "clients"),
				p_mobile: checkOptions.some((o) => o === "mobile" || o === "expo"),
				isAdmin: checkOptions.some((o) => o === "admin"),
				isOneMarket: client.isOneMarket,
				isSeller: client.isSeller,
				clientId: client.id,
				userRegion: values.region ? arr : null,
			};

			const formData = new FormData();
			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);
			formData.append("data", JSON.stringify(postData));

			await update_user(data.id, formData);

			checkUpdated((prev) => !prev);

			closeUpdateModal(false);

			openNotificationWithIcon(TYPE.SUCCESS, "El usuario se editó con éxito");
			navigate(`/admin/users`);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al editar el usuario",
				"Un error ocurrio al intentar editar el usuario, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const onChange = (checkedValues) => {};

	const handleRol = (value) => {
		setRol(value);
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				closeUpdateModal(false);
			},
		});
	};

	return (
		<Inner>
			{!initialValues ? (
				<LoadingSkeleton />
			) : (
				<Form
					form={form}
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
					initialValues={initialValues}
				>
					<InnerForm>
						<ClientData>
							<Space
								direction="vertical"
								size="middle"
								style={{ display: "flex" }}
							>
								<Row gutter={GUTTER}>
									<Col span={12}>
										<Form.Item
											name="file"
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Imagen del usuario
												</label>
											}
										>
											<UploadFileImage
												onChange={handleImageChange}
												urlImage={imageUrl}
												imageHeight="100%"
												imageWidth="100%"
												borderRadius={40}
											/>
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={GUTTER}>
									<Col span={12}>
										<Form.Item
											label="Nombre"
											name="firstName"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label="Apellido"
											name="lastName"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={GUTTER}>
									<Col span={12}>
										<Form.Item
											label="Mail"
											name="email"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Input disabled={data.isFirstUser} type="email" />
										</Form.Item>
									</Col>
									<Col span={12}>
										<PhoneInput form={form} size="default" label="Teléfono" />
									</Col>
								</Row>

								{!client?.isSeller && (
									<Row gutter={GUTTER}>
										<Col span={24}>
											<Form.Item label="Puntos de venta">
												<PointOfSaleSelector
													initialValues={pointsOfSale}
													onChange={setPointsOfSale}
													userId={data.id}
												/>
											</Form.Item>
										</Col>
									</Row>
								)}
							</Space>
						</ClientData>
						<PublishSettings>
							<Container>
								<Typo type="primary" level={6}>
									Rol de usuario:{" "}
								</Typo>
								<Select
									onChange={handleRol}
									defaultValue={rol}
									disabled={data.isFirstUser}
								>
									{selectRoles.map((r, idx) => (
										<Select.Option key={`Rol_${idx}_${r.id}`} value={r.value}>
											{r.name}
										</Select.Option>
									))}
								</Select>
								<Typo type="primary" level={6}>
									Permisos:{" "}
								</Typo>
								<Checkbox.Group
									options={permissionsOptions}
									value={checkOptions}
									disabled
									onChange={onChange}
								/>
								<Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										color="white"
										bg="Primary"
										htmlType="submit"
										loading={submitting}
										block
									>
										Guardar Cambios
									</SignInTemplate.AntdButton>
								</Form.Item>
								<SignInTemplate.AntdButton
									type="Primary"
									loading={submitting}
									onClick={handleBack}
									block
								>
									<Typo type="danger" level={6}>
										Volver sin guardar
									</Typo>
								</SignInTemplate.AntdButton>
							</Container>
							{rol === "SELLERMOBILELAB" && (
								<Container>
									<UserRegion form={form} />
								</Container>
							)}
						</PublishSettings>
					</InnerForm>
				</Form>
			)}
		</Inner>
	);
}
