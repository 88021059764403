import styled from "styled-components";
import { COLORS } from "../../../layout/theme";

export const InnerForm = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;
export const DataSector = styled.div`
  flex: 1 1 70%;
`;
export const LogoSector = styled.div`
  flex: 1 1 30%;
`;
export const InputSector = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100%;
  height: 50px;
`;

export const GoBack = styled.div`
  height: 50px;
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: ${COLORS.Primary};
  margin: 10px 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 150ms ease-in-out;

  svg {
    fill: white;
    width: 25px;
    height: 25px;
  }

  &:hover {
    background-color: ${COLORS.Secondary};
    transform: scale(0.95);
  }
`;
