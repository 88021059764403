import styled, { keyframes } from "styled-components";
import { COLORS } from "../../../../layout/theme";

const linearMove = keyframes`
  from {
    transform: translateX(20%);
  }

  to {
    transform: translateX(0%);
  }
`;

const linearMoveRight = keyframes`
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(30%);
  }
`;

export const Filters = styled.div`
	margin: 20px 0;

	& button {
		padding: 0 !important;
	}
`;
export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;
export const OptionsContainer = styled.div`
	align-item: center;
	align-content: center;
	margin-top: 20px;
`;

export const Arrow = styled.div`
	display: inline-block;
	animation: ${linearMove} 1s linear infinite;
	padding: 2rem 1rem;
	font-size: 1.2rem;
	color: ${COLORS.Success};
`;

export const ArrowRight = styled.div`
	display: inline-block;
	animation: ${linearMoveRight} 1s linear infinite;
	padding: 2rem 1rem;
	font-size: 1.2rem;
	color: ${COLORS.Success};
	position: absolute;
	left: 0;
	top: 250px;
`;
