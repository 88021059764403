import React, { useEffect, useState } from "react";
import Filter from "../../common/filter/Filter";
import { Link, useHref, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import Image from "../../common/image/Image";
import { Typo } from "../../layout/theme";
import { get_products } from "../../../api/endpoints/products";
import { List, ViewGrid } from "../../common/filter/styles";
import { FaMinus, FaPlus } from "react-icons/fa";
import { FilterViewContainer } from "./style";

export default function FilterContainer({
	searchTerm,
	appliedFilters,
	notAppliedFilters,
	resultsQty,
	laboratory,
	data,
	setDisplay,
	visibleDisplayButton = true,
	isStore = false,
	laboratoryName = null,
}) {
	const [products, setProducts] = useState(null);
	const [activeGrid, setActiveGrid] = useState(false);
	const [activeList, setActiveList] = useState(true);
	const [state, setState] = useState(null);
	const location = useLocation();
	const navigate = useNavigate();

	const fetch_products = async () => {
		const res = await get_products();
		setProducts(res);
	};

	useEffect(() => {
		fetch_products();
		setState(
			notAppliedFilters?.map((i) => ({
				title: i.title,
				continue: i.items.length > 14 ? true : false,
			}))
		);
	}, []);

	const handleActiveDisplay = (fnc, typeOfDisplay, displayStyle) => {
		try {
			setActiveGrid(false);
			setActiveList(false);
			displayStyle(true);
			fnc(typeOfDisplay);
		} catch (err) {
			console.log(err);
		}
	};

	const handleVerMas = (title) => {
		const estado = [...state];
		const index = state.findIndex((i) => i.title === title);

		estado[index].continue = false;

		setState(estado);
	};

	const handleVerMenos = (title) => {
		const estado = [...state];
		const index = state.findIndex((i) => i.title === title);

		estado[index].continue = true;

		setState(estado);
	};

	return (
		<Filter>
			{laboratory && (
				<Filter.Applied>
					<Filter.NotApplied.ItemList>
						<Typo level={6} type="primary">
							Laboratorio
						</Typo>
					</Filter.NotApplied.ItemList>

					<Filter.Applied.ItemList>
						<Image width={40} height={40} src={laboratory.photoUrl} />
						<Typo level={7} type="darkMuted">
							{laboratory.name}
						</Typo>
					</Filter.Applied.ItemList>
				</Filter.Applied>
			)}
			{visibleDisplayButton && (
				<FilterViewContainer>
					{location?.pathname.includes("/offers") && (
						<Typo
							level={4}
							type={activeGrid ? "primary" : "darkMuted"}
							onClick={() =>
								handleActiveDisplay(setDisplay, "CARD", setActiveGrid)
							}
						>
							<ViewGrid />
						</Typo>
					)}
					<Typo
						level={5}
						type={activeList ? "primary" : "darkMuted"}
						onClick={() =>
							handleActiveDisplay(setDisplay, "LIST", setActiveList)
						}
					>
						<List />
					</Typo>
				</FilterViewContainer>
			)}
			{/* <div
				style={{
					display: "flex",
					alignItems: "end",
					justifyContent: "space-between",
				}}
			>
				<Typo level={3} type="darkMuted">
					{searchTerm.length === 36 && data.productSearch.length > 0
						? data.productSearch[0]?.title
						: searchTerm}{" "}
				</Typo>
			</div> */}

			{appliedFilters && appliedFilters.length > 0 && (
				<Filter.Applied>
					<Typo level={6} type="primary">
						Filtros seleccionados
					</Typo>

					<Filter.Applied.ItemList>
						{appliedFilters.map((f, idx) => (
							<Link key={`sel_${idx}`} to={`/${f.actionUrl}`}>
								<Filter.Applied.Item>
									<Typo type="darkMuted">
										{searchTerm.length === 36 && data.productSearch.length > 0
											? data.productSearch[0].title
											: f.text}
									</Typo>
									<Filter.Applied.Item.Right>
										{f.photoUrl && (
											<Image
												width={30}
												height={30}
												src={f.photoUrl}
												alt={f.text}
											/>
										)}

										<AiOutlineClose />
									</Filter.Applied.Item.Right>
								</Filter.Applied.Item>
							</Link>
						))}
						<Typo
							level={8}
							type="darkMuted"
						>{`Se encontraron ${resultsQty} resultados`}</Typo>
					</Filter.Applied.ItemList>
				</Filter.Applied>
			)}
			<Filter.NotApplied>
				{notAppliedFilters &&
					notAppliedFilters.map((filter, idx) => (
						<Filter.NotApplied.Inner key={`not_sel_${idx}`}>
							<Filter.NotApplied.NotAppliedTitle>
								{!(
									filter.title === "Descuentos" || filter.title === "Limitados"
								) && (
									<Typo level={6} type="primary">
										{filter.title}
									</Typo>
								)}
								{filter.items.length >= 14 &&
									state?.find((i) => i.title === filter.title)?.continue && (
										<Filter.NotApplied.IconContainer>
											<Typo
												type="darkMuted"
												onClick={() => handleVerMas(filter.title)}
												style={{
													position: "absolute",
													top: "0.5px",
													left: "1.75px",
												}}
												level={8}
											>
												<FaPlus />
											</Typo>
										</Filter.NotApplied.IconContainer>
									)}
								{filter.items.length >= 14 &&
									!state?.find((i) => i.title === filter.title)?.continue && (
										<Filter.NotApplied.IconContainer>
											<Typo
												type="darkMuted"
												onClick={() => handleVerMenos(filter.title)}
												style={{
													position: "absolute",
													top: "0.5px",
													left: "1.75px",
												}}
												level={8}
											>
												<FaMinus />
											</Typo>
										</Filter.NotApplied.IconContainer>
									)}
							</Filter.NotApplied.NotAppliedTitle>
							<Filter.NotApplied.ItemList>
								{filter.items &&
									filter.items.map((item, idx) => (
										<div key={`not_applied_${item.id}_${idx}`}>
											{state?.find((i) => i.title === filter.title)
												?.continue === true && idx < 14 ? (
												<Link
													key={`not_apll_${item.id}_${idx}`}
													to={`/${item.actionUrl}`}
												>
													<Filter.NotApplied.Item>
														<Filter.NotApplied.Item.Right>
															{item.photoUrl && (
																<Image
																	width={40}
																	height={40}
																	src={item.photoUrl}
																	alt={item.title}
																/>
															)}
															<Typo type="darkMuted">{item.title}</Typo>
															{/* <Typo type="darkMuted">{`(${item.quantity})`}</Typo> */}
														</Filter.NotApplied.Item.Right>
													</Filter.NotApplied.Item>
												</Link>
											) : state?.find((i) => i.title === filter.title)
													?.continue === false && idx < 14 ? (
												<Link
													key={`not_apll2_${item.id}_${idx}`}
													to={`/${item.actionUrl}`}
												>
													<Filter.NotApplied.Item>
														<Filter.NotApplied.Item.Right>
															{item.photoUrl && (
																<Image
																	width={40}
																	height={40}
																	src={item.photoUrl}
																	alt={item.title}
																/>
															)}
															<Typo type="darkMuted">{item.title}</Typo>

															{/* <Typo type="darkMuted">{`(${item.quantity})`}</Typo> */}
														</Filter.NotApplied.Item.Right>
													</Filter.NotApplied.Item>
												</Link>
											) : state?.find((i) => i.title === filter.title)
													?.continue === true && idx >= 14 ? null : (
												<Link
													key={`not_apll3_${item.id}_${idx}`}
													to={`/${item.actionUrl}`}
												>
													<Filter.NotApplied.Item>
														<Filter.NotApplied.Item.Right>
															{item.photoUrl && (
																<Image
																	width={40}
																	height={40}
																	src={item.photoUrl}
																	alt={item.title}
																/>
															)}
															<Typo type="darkMuted">{item.title}</Typo>

															{/* <Typo type="darkMuted">{`(${item.quantity})`}</Typo> */}
														</Filter.NotApplied.Item.Right>
													</Filter.NotApplied.Item>
												</Link>
											)}
										</div>
									))}
							</Filter.NotApplied.ItemList>
						</Filter.NotApplied.Inner>
					))}
			</Filter.NotApplied>
		</Filter>
	);
}
