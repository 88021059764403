import { Modal } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 40px;
	margin-top: 30px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: column;
	}
`;

export const LeftContainer = styled.div`
	flex: 1 1 40%;
	width: 100%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	padding: 10px 20px 20px 20px;
	align-items: center;
`;

export const ImageSection = styled.div`
	flex: 1 1 40%;
	width: 100%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	padding: 10px 20px 20px 20px;
	align-items: center;
	gap: 10px;
`;

export const DataSection = styled.div`
	flex: 1 1 60%;
	display: flex;
	flex-direction: column;
	background-color: ${COLORS.White};
	padding: 10px 20px 20px 20px;
	border-radius: 10px;
	gap: 20px;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 60%;
	}
`;

export const RightContainer = styled.div`
	flex: 1 1 60%;
	background-color: ${COLORS.White};
	padding: 10px 20px 20px 20px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 60%;
	}
`;

export const Container = styled.div``;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 30px;
`;

export const Title = styled.span`
	padding-bottom: 0.5rem;
`;

export const Header = styled.div`
	background-color: ${COLORS.Primary};
	color: ${COLORS.White};
	font-weight: ${WEIGHT.Bold};
	width: 100%;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const MegaDistributorContainer = styled.div``;

export const AntdModal = styled(Modal)`
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
	}
`;

export const FloatingButton = styled.div`
	width: 40px;
	height: 40px;
	background-color: ${COLORS.Primary};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	transition: all ease-in-out 250ms;

	&:hover {
		background-color: ${COLORS.Secondary};
	}

	&:active {
		transform: scale(1.1);
	}
`;

export const FloatingContainer = styled.div`
	display: flex;
	position: sticky;
	top: 20px;
	z-index: 2;
	justify-content: end;
	margin-bottom: -30px;
`;

export const InfoContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	cursor: pointer;
	padding: 1rem;
`;

export const GuideText = styled.div`
	display: flex;
	white-space: nowrap;
	margin-bottom: 10px;
	color: ${COLORS.Secondary};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level5};
	justify-content: center;
	margin-top: 20px;
`;
