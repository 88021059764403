import { Col, Form, Input, message, Row } from "antd";
import PhoneInput from "../../../../../../common/phone-input/PhoneInput";
import UploadFileImage from "../../../../../../common/upload-file-with-image/UploadFileImage";
import { COLORS } from "../../../../../../layout/theme";
import { CONSTANTS } from "../../../../../../../utils/constants";

const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function ClientCard({
	form,
	imageUrl,
	setImageUrl,
	saleDepartments,
	setSaleDepartments,
	fetch_sale_departments,
	isCuitValid,
	afipData,
}) {
	const formValues = Form.useWatch([], form);
	const businessName = Form.useWatch("businessName", form);
	const businessAddress = Form.useWatch("address", form);
	const businessPostalCode = Form.useWatch("postalCode", form);

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<Row gutter={GUTTER}>
				<Col span={6}>
					<Form.Item
						name="file"
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Imagen principal
							</label>
						}
					>
						<UploadFileImage
							onChange={handleImageChange}
							urlImage={imageUrl}
							imageHeight="100%"
							imageWidth="100%"
						/>
					</Form.Item>
				</Col>
				<Col span={16}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Nombre
							</label>
						}
						name="name"
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
							{
								pattern: new RegExp(
									/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s.-]*$/
								),
								message:
									"No se aceptan caracteres especiales. Solo letras y numeros",
							},
						]}
					>
						<Input placeholder="Nombre" />
					</Form.Item>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Razón social
							</label>
						}
						name="businessName"
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
							{
								pattern: new RegExp(
									/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s.-]*$/
								),
								message:
									"No se aceptan caracteres especiales. Solo letras y numeros",
							},
						]}
						help={
							isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
								? "CUIT inválido"
								: afipData
								? afipData?.razonSocial === businessName
									? ""
									: afipData?.condicionIVA !== CONSTANTS.AFIP_STATUS_OK
									? "No es un responsable inscripto"
									: "No coindicide con dato de Afip"
								: null
						}
						validateStatus={
							isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
								? "error"
								: afipData
								? afipData?.razonSocial === businessName
									? "success"
									: "error"
								: ""
						}
						hasFeedback
					>
						<Input placeholder="Razon Social" />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={GUTTER}>
				<Col span={6}>
					<Form.Item
						label={
							<label
								style={{
									color: COLORS.Primary,
									fontWeight: "600",
								}}
							>
								CUIT
							</label>
						}
						name="cuit"
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
							{
								len: 11,
								message: "El cuit debe de ser de 11 digitos",
							},
							{
								pattern: new RegExp(/^[0-9\s]*$/),
								message: "Solo numeros positivos",
							},
						]}
						help={
							isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
								? "CUIT inválido"
								: afipData
								? afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK
									? ""
									: "No es responsable inscripto"
								: null
						}
						validateStatus={
							isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
								? "error"
								: afipData
								? afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK
									? "success"
									: "error"
								: ""
						}
						hasFeedback
					>
						<Input placeholder="Cuit" />
					</Form.Item>
				</Col>
				<Col span={11}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Dirección
							</label>
						}
						name="address"
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
							{
								pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s.-]*$/),
								message:
									"No se aceptan caracteres especiales. Solo letras y numeros",
							},
						]}
						help={
							isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
								? "CUIT inválido"
								: afipData
								? afipData?.fiscalDireccion === businessAddress
									? ""
									: afipData?.condicionIVA !== CONSTANTS.AFIP_STATUS_OK
									? "No es un responsable inscripto"
									: "No coindicide con dato de Afip"
								: null
						}
						validateStatus={
							isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
								? "error"
								: afipData
								? afipData?.fiscalDireccion === businessAddress
									? "success"
									: "error"
								: ""
						}
						hasFeedback
					>
						<Input placeholder="Dirección" />
					</Form.Item>
				</Col>
				<Col span={5}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Código Postal
							</label>
						}
						name="postalCode"
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
						]}
						help={
							isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
								? "CUIT inválido"
								: afipData
								? afipData?.fiscalCodPostal === businessPostalCode
									? ""
									: afipData?.condicionIVA !== CONSTANTS.AFIP_STATUS_OK
									? "No es un responsable inscripto"
									: "No coindicide con dato de Afip"
								: null
						}
						validateStatus={
							isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
								? "error"
								: afipData
								? afipData?.fiscalCodPostal === businessPostalCode
									? "success"
									: "error"
								: ""
						}
						hasFeedback
					>
						<Input placeholder="Código Postal" />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={GUTTER}>
				<Col span={11}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Nombre del representante
							</label>
						}
						name="contactFirstName"
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
							{
								pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
								message:
									"No se aceptan caracteres especiales. Solo letras y numeros",
							},
						]}
					>
						<Input placeholder="Nombre del representante" />
					</Form.Item>
				</Col>
				<Col span={11}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Apellido del representante
							</label>
						}
						name="contactLastName"
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
							{
								pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
								message:
									"No se aceptan caracteres especiales. Solo letras y numeros",
							},
						]}
					>
						<Input placeholder="Apellido del representante" />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={GUTTER}>
				<Col span={11}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Correo de Contacto
							</label>
						}
						name="contactEmail"
						rules={[
							{
								required: true,
								message: "Este campo es obligatorio",
							},
						]}
					>
						<Input placeholder="Correo de Contacto" />
					</Form.Item>
				</Col>
				<Col span={11}>
					<PhoneInput
						form={form}
						size="default"
						label="Teléfono de Contacto"
						labelStyle={{
							color: COLORS.Primary,
							fontWeight: "600",
						}}
					/>
				</Col>
			</Row>
			{/* <Row gutter={GUTTER}>
				<Col span={22}>
					<Form.Item
						label={
							<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
								Rubros asociados
							</label>
						}
					>
						<DebounceSelect
							fetchCallback={fetch_sale_departments}
							placeholder="Buscar rubro"
							onValuesChange={setSaleDepartments}
							initialValues={saleDepartments}
						/>
					</Form.Item>
				</Col>
			</Row> */}
		</>
	);
}
