import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Row, Col, Space, Modal, Select, Spin, Typography } from "antd";
import Main from "../../../../../layout/main/Main";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import {
	Container,
	InnerForm,
	LeftSection,
	ModalContainer,
	PublishSettings,
	RightSection,
	SectionData,
} from "./styles";
import LoadingSkeleton from "../components/loading-skeleton/LoadingSkeleton";
import DistributorsSelector from "../../../../../common/distributos-selector/DistributorsSelector";
import { COLORS, Typo } from "../../../../../layout/theme";
import {
	get_client_by_id,
	update_client_distributor_list,
} from "../../../../../../api/endpoints/clients";
import { CloseCircleOutlined } from "@ant-design/icons";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import { CONSTANTS } from "../../../../../../utils/constants";

const { Title, Paragraph } = Typography;

export default function AsignDistributors({
	clients,
	type,
	clientType,
	selectedClient,
	setSelectedClient,
}) {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [submitting, setSubmitting] = useState(false);
	const [distributors, setDistributors] = useState(null);
	const [initialValues, setInitialValues] = useState(null);

	const fetch_client_by_id = async (id) => {
		const data = await get_client_by_id(id);

		setInitialValues(data);
		setDistributors(data.distributors);
	};

	useEffect(() => {
		if (
			type === CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_ALL &&
			clientType === CONSTANTS.DISTRIBUTORS_FILTER_WITHOUTCONTRACT
		) {
			setInitialValues(clients);
		} else {
			const aux = clients.find((c) => c.value === selectedClient);
			if (aux) {
				fetch_client_by_id(selectedClient);
			} else {
				setInitialValues(null);
				localStorage.setItem("selectedClientForDistributorAsign", null);
			}
		}
	}, [selectedClient]);

	const selectLaboratory = async (e) => {
		setSelectedClient(e);
		localStorage.setItem("selectedClientForDistributorAsign", e);
	};

	const handleDeleteSelectedClient = async () => {
		setInitialValues(null);
		setSelectedClient(null);
		localStorage.setItem("selectedClientForDistributorAsign", null);
	};

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const newDistributor = values.distributors.map((d) =>
				d.checked === true ? { ...d, code: "si" } : { ...d, code: null }
			);

			let postData = {};
			if (
				clientType === CONSTANTS.DISTRIBUTORS_FILTER_WITHOUTCONTRACT &&
				type === CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_ALL
			) {
				postData = {
					type: CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_ALL,
					clientsId: clients.map((c) => c.value),
					distributors: newDistributor,
				};
			} else {
				postData = {
					type: CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_INDIVIDUAL,
					clientsId: [selectedClient],
					distributors: newDistributor,
				};
			}

			const formData = new FormData();

			formData.append("data", JSON.stringify(postData));

			//console.log(postData);
			await update_client_distributor_list(formData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Las droguerías se asignaron con éxito"
			);
			//navigate(`/admin/distributors`);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error en al edición",
				"Un error ocurrio al intentar asignar las droguerías, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/distributors/");
			},
		});
	};

	return (
		<div>
			<Main.Body>
				{!initialValues ? (
					<div>
						<Select
							showSearch
							placeholder="Elegi un cliente"
							optionFilterProp="children"
							onChange={(e) => selectLaboratory(e)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={clients}
							style={{ width: 180 }}
						/>
					</div>
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<LeftSection>
								{type === CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_INDIVIDUAL &&
									selectedClient && (
										<Container onClick={handleDeleteSelectedClient}>
											{initialValues.client.name}
											<CloseCircleOutlined style={{ color: COLORS.Danger }} />
										</Container>
									)}
								<SectionData>
									<Row>
										<Col span={22}>
											<Form.Item name="distributors">
												<DistributorsSelector
													onChange={setDistributors}
													initialValues={distributors}
													noBuyer={true}
												/>
											</Form.Item>
										</Col>
									</Row>
								</SectionData>
							</LeftSection>
							<RightSection>
								<PublishSettings>
									<Space
										direction="vertical"
										size="middle"
										style={{ display: "flex" }}
									>
										<Form.Item>
											<SignInTemplate.AntdButton
												type="Primary"
												color="white"
												bg="Primary"
												htmlType="submit"
												loading={submitting}
												block
											>
												Guardar Cambios
											</SignInTemplate.AntdButton>
										</Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											loading={submitting}
											onClick={handleBack}
											block
										>
											<Typo type="danger" level={6}>
												Volver sin guardar
											</Typo>
										</SignInTemplate.AntdButton>
									</Space>
								</PublishSettings>
							</RightSection>
						</InnerForm>
					</Form>
				)}
				<Modal
					title="Actualizando datos"
					open={submitting}
					closable={false}
					width={900}
					centered={true}
					footer={null}
					maskClosable={false}
				>
					<ModalContainer>
						<Title style={{ color: COLORS.Primary, marginTop: 30 }} level={3}>
							Actualizando datos
						</Title>
						<Paragraph>
							<blockquote>
								Actualizando las relaciones entre cliente y distribuidor{"  "}
								<Spin size="small" />
							</blockquote>
						</Paragraph>
						<Paragraph>
							<blockquote>
								Asignando el código EAN a cada producto como código de droguería
								{"  "}
								<Spin size="small" />
							</blockquote>
						</Paragraph>
						<Spin size="large" />
					</ModalContainer>
				</Modal>
			</Main.Body>
		</div>
	);
}
