import { Skeleton, Row, Col } from "antd";
import { Inner, TableHeader } from "./styles";

const GUTTER = 20;

export default function SkeletonSlidersList() {
	return (
		<Inner>
			<Row gutter={GUTTER}>
				<TableHeader>
					<Col span={5} style={{ display: "flex", justifyContent: "center" }}>
						<Skeleton.Image />
					</Col>
					<Col
						span={14}
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-evenly",
						}}
					>
						<Skeleton
							active
							paragraph={{
								rows: 0,
							}}
						/>
						<Skeleton
							active
							paragraph={{
								rows: 0,
							}}
						/>
					</Col>
					<Col
						span={5}
						style={{ display: "flex", justifyContent: "space-evenly" }}
					>
						<Skeleton.Input active />
					</Col>
					<Col span={4}></Col>
				</TableHeader>
			</Row>

			<Row gutter={GUTTER}>
				<TableHeader>
					<Col span={5} style={{ display: "flex", justifyContent: "center" }}>
						<Skeleton.Image />
					</Col>
					<Col
						span={14}
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-evenly",
						}}
					>
						<Skeleton
							active
							paragraph={{
								rows: 0,
							}}
						/>
						<Skeleton
							active
							paragraph={{
								rows: 0,
							}}
						/>
					</Col>
					<Col
						span={5}
						style={{ display: "flex", justifyContent: "space-evenly" }}
					>
						<Skeleton.Input active />
					</Col>
					<Col span={4}></Col>
				</TableHeader>
			</Row>

			<Row gutter={GUTTER}>
				<TableHeader>
					<Col span={5} style={{ display: "flex", justifyContent: "center" }}>
						<Skeleton.Image />
					</Col>
					<Col
						span={14}
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-evenly",
						}}
					>
						<Skeleton
							active
							paragraph={{
								rows: 0,
							}}
						/>
						<Skeleton
							active
							paragraph={{
								rows: 0,
							}}
						/>
					</Col>
					<Col
						span={5}
						style={{ display: "flex", justifyContent: "space-evenly" }}
					>
						<Skeleton.Input active />
					</Col>
					<Col span={4}></Col>
				</TableHeader>
			</Row>
		</Inner>
	);
}
