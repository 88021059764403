import React from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../common/image/Image";
import LaboratoriesSlide from "../../common/slides/laboratories-slide/LaboratoriesSlide";

export default function LaboratoriesSlideContainer({ id, slug, title, image }) {
	const navigate = useNavigate();

	const handleClick = () => {
		window.scrollTo(0, 0);
		navigate(`/stores/${slug}`);
	};

	return (
		<LaboratoriesSlide onClick={handleClick}>
			<LaboratoriesSlide.TopContainer>
				<Image
					src={image}
					height="100%"
					width="100%"
					style={{ objectFit: "contain" }}
				/>
			</LaboratoriesSlide.TopContainer>
		</LaboratoriesSlide>
	);
}
