import React, { useEffect, useState } from "react";
import { Table, Checkbox, Select, Modal, Input } from "antd";
import Image from "../image/Image";
import { get_distributors } from "../../../api/endpoints/distributors";
import { FaImages } from "react-icons/fa";
import { COLORS, Typo } from "../../layout/theme";
import { TableContainer } from "./styles";
import { InfoCircleOutlined } from "@ant-design/icons";
import AntdCheckbox from "../antd-checkbox/AntdCheckbox";

const { Option } = Select;
const { info } = Modal;

export default function Distributors({ initialValues, onChange, noBuyer }) {
	const [distributors, setDistributors] = useState(null);
	const [isBuyer, setIsBuyer] = useState(true);
	useEffect(() => {
		const fetch_distributors = async () => {
			const data = await get_distributors();

			const futureDistributor =
				data.map((d) => ({
					key: d.id,
					...d,
					mails: !initialValues
						? d.mails
						: initialValues?.find((i) => i.id === d.id)?.mails,
					selectedMail: initialValues
						? initialValues?.find((i) => i.id === d.id)?.selectedMail
						: d.email,
					code: initialValues?.find((i) => i.id === d.id)?.code,
					checked: initialValues?.find((i) => i.id === d.id)?.code
						? true
						: false,
					eanAutocomplete: initialValues?.find((i) => i.id === d.id)
						?.eanAutocomplete,
				})) || [];

			setDistributors(futureDistributor);
		};
		fetch_distributors();
		if (noBuyer) setIsBuyer(false);
	}, [noBuyer]);

	useEffect(() => {
		onChange(distributors);
	}, [distributors]);

	const handleChange = (id, check) => {
		const _dis = distributors.map((d) =>
			d.id === id ? { ...d, checked: check } : { ...d }
		);
		setDistributors(_dis);
	};

	const handleSelect = (id, mailId) => {
		const _dis = distributors.map((d) =>
			d.id === id ? { ...d, selectedMail: mailId } : { ...d }
		);
		setDistributors(_dis);
	};

	const handleAutocompleteChange = (id, check) => {
		const _dis = distributors.map((d) =>
			d.id === id ? { ...d, eanAutocomplete: check } : { ...d }
		);
		const _auxDis = distributors.find((i) => i.id === id);
		setDistributors(_dis);
		if (check) handleInfoEan(_auxDis);
	};

	const handleInfoEan = (dist) => {
		info({
			title: "Hay información que sera sobreescrita al guardar los cambios",
			icon: <InfoCircleOutlined style={{ color: COLORS.Secondary }} />,
			content: `Tenga en cuenta que guardar cambios con esta casilla activa implica que se le asigne a cada producto el código ean para el distribuidor ${dist.name}`,
			okText: "Volver",
		});
	};

	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			render: (data) => <Image width={40} height={40} src={data} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Droguería</div>,
			dataIndex: "name",
			key: "name",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>@Mail</div>,
			dataIndex: "mails",
			key: "mail",
			width: "300px",
			render: (value, record, index) => (
				<Typo type="muted" level={6}>
					{record.selectedMail}
				</Typo>
				// <Select
				// 	onChange={(e) => handleSelect(record.id, e)}
				// 	defaultValue={record.selectedMail}
				// 	disabled={record.checked ? false : true}
				// >
				// 	{value?.map((i) => (
				// 		<Option value={i.id} key={i.id}>
				// 			{i.mail} {i.name}
				// 		</Option>
				// 	))}
				// </Select>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Activa</div>,
			dataIndex: "",
			render: (value, record, index) => (
				<Checkbox
					onChange={(e) => handleChange(record.id, e.target.checked)}
					defaultChecked={value.checked}
				></Checkbox>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Usa EAN</div>,
			dataIndex: "",
			render: (value, record, index) => (
				<Checkbox
					disabled={!record.checked}
					onChange={(e) =>
						handleAutocompleteChange(record.id, e.target.checked)
					}
					defaultChecked={record.eanAutocomplete}
				></Checkbox>
			),
		},
	];

	const columnsBuyer = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			render: (data) => <Image width={40} height={40} src={data} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Droguería</div>,
			dataIndex: "name",
			key: "name",
		},
		{
			title: "",
			dataIndex: "",
			render: (value, record, index) => (
				<AntdCheckbox
					onChange={(e) => handleChange(record.id, e.target.checked)}
					defaultChecked={value.checked}
				></AntdCheckbox>
			),
		},
	];

	if (!distributors) return <div>Loading...</div>;

	return (
		<TableContainer>
			{!noBuyer ? (
				<Table
					size="small"
					columns={columnsBuyer}
					dataSource={distributors}
					pagination={false}
				/>
			) : (
				<Table
					size="small"
					columns={columns}
					dataSource={distributors}
					pagination={false}
				/>
			)}
		</TableContainer>
	);
}
