import { Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { DataContainer, Inner, InnerForm } from "./styles";
import { AiOutlinePlusCircle } from "react-icons/ai";
import DropShadow from "../../../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import {
	create_client_mobile_config,
	update_client_mobile_config,
} from "../../../../../../api/endpoints/clients";
import ConfigurationUpdate from "../configuration-update/ConfigurationUpdate";
import { CONSTANTS } from "../../../../../../utils/constants";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import moment from "moment";

export default function ConfigurationPage({
	mobileConfig,
	setMobileConfig,
	clientId,
}) {
	const [form] = Form.useForm();
	const [appLogo, setAppLogo] = useState(mobileConfig?.appLogo ?? null);
	const [posImageUrl, setPosImageUrl] = useState(
		mobileConfig?.posSelectionPhotoUrl ?? null
	);
	const [offerImageUrl, setOfferImageUrl] = useState(
		mobileConfig?.offerPhotoUrl ?? null
	);
	const [cartImageUrl, setCartImageUrl] = useState(
		mobileConfig?.cartPhotoUrl ?? null
	);
	const [orderImageUrl, setOrderImageUrl] = useState(
		mobileConfig?.orderPhotoUrl ?? null
	);
	const [appLogoToSend, setAppLogoToSend] = useState(null);
	const [posImageToSend, setPosImageToSend] = useState(null);
	const [offerImageToSend, setOfferImageToSend] = useState(null);
	const [cartImageToSend, setCartImageToSend] = useState(null);
	const [orderImageToSend, setOrderImageToSend] = useState(null);
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		if (mobileConfig) {
			setAppLogo(mobileConfig.appLogo);
			setPosImageUrl(mobileConfig.posSelectionPhotoUrl);
			setOfferImageUrl(mobileConfig.offerPhotoUrl);
			setCartImageUrl(mobileConfig.cartPhotoUrl);
			setOrderImageUrl(mobileConfig.orderPhotoUrl);
		}
	}, [mobileConfig]);

	const handleCreateConfig = async () => {
		const postData = {
			clientId: clientId,
		};
		const res = await create_client_mobile_config(postData);
		setMobileConfig(res);
	};

	const onFinish = async (values) => {
		try {
			const postData = {
				id: mobileConfig.id,
				clientId: mobileConfig.clientId,
				appLogo: appLogo,
				posSelectionPhotoUrl: posImageUrl,
				orderPhotoUrl: orderImageUrl,
				offerPhotoUrl: offerImageUrl,
				cartPhotoUrl: cartImageUrl,
				mainColor1: values.mainColor1,
				mainColor2: values.mainColor2,
				mainColor3: values.mainColor3,
				successColor: values.successColor,
				successColorLight: values.successColorLight,
				errorColor: values.errorColor,
				warningColor: values.warningColor,
			};

			setSubmitting(true);

			const formData = new FormData();

			formData.append("data", JSON.stringify(postData));
			formData.append("appLogoFile", appLogoToSend);
			formData.append("posFile", posImageToSend);
			formData.append("offerFile", offerImageToSend);
			formData.append("cartFile", cartImageToSend);
			formData.append("orderFile", orderImageToSend);

			const res = await update_client_mobile_config(postData.id, formData);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Configuración actualizada con éxito",
				"La configuración se actualizo con éxito"
			);
			const now = moment().milliseconds();
			setMobileConfig({
				...res,
				appLogo: res.appLogo + `?key=${now}`,
				posSelectionPhotoUrl: res.posSelectionPhotoUrl + `?key=${now}`,
				orderPhotoUrl: res.orderPhotoUrl + `?key=${now}`,
				offerPhotoUrl: res.offerPhotoUrl + `?key=${now}`,
				cartPhotoUrl: res.cartPhotoUrl + `?key=${now}`,
			});
		} catch (error) {
			const errors = Object.entries(error?.response?.data?.errors);
			openNotificationWithIcon(
				TYPE.ERROR,
				errors.map((e) => `${e[1]} `).concat()
			);
		} finally {
			setSubmitting(false);
		}
	};

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const handleImageChange = (info, type) => {
		try {
			const isJpgOrPng =
				// info.file.type === "image/jpeg" ||
				info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("Solo se admiten archivos PNG");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("La imagen debe ser menor a 2MB");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					switch (type) {
						case CONSTANTS.MOBILE_CONFIG_APP_LOGO:
							setAppLogo(url);
							break;
						case CONSTANTS.MOBILE_CONFIG_POS_IMAGE:
							setPosImageUrl(url);
							break;
						case CONSTANTS.MOBILE_CONFIG_OFFER_IMAGE:
							setOfferImageUrl(url);
							break;
						case CONSTANTS.MOBILE_CONFIG_CART_IMAGE:
							setCartImageUrl(url);
							break;
						case CONSTANTS.MOBILE_CONFIG_ORDER_IMAGE:
							setOrderImageUrl(url);
							break;

						default:
							break;
					}
				}
			);

			switch (type) {
				case CONSTANTS.MOBILE_CONFIG_APP_LOGO:
					setAppLogoToSend(
						info.fileList[info.fileList.length - 1].originFileObj
					);
					break;
				case CONSTANTS.MOBILE_CONFIG_POS_IMAGE:
					setPosImageToSend(
						info.fileList[info.fileList.length - 1].originFileObj
					);
					break;
				case CONSTANTS.MOBILE_CONFIG_OFFER_IMAGE:
					setOfferImageToSend(
						info.fileList[info.fileList.length - 1].originFileObj
					);
					break;
				case CONSTANTS.MOBILE_CONFIG_CART_IMAGE:
					setCartImageToSend(
						info.fileList[info.fileList.length - 1].originFileObj
					);
					break;
				case CONSTANTS.MOBILE_CONFIG_ORDER_IMAGE:
					setOrderImageToSend(
						info.fileList[info.fileList.length - 1].originFileObj
					);
					break;

				default:
					break;
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Inner>
			{mobileConfig ? (
				<Form
					form={form}
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
					initialValues={mobileConfig}
					style={{ width: "100%" }}
				>
					<InnerForm>
						<ConfigurationUpdate
							form={form}
							handleImageChange={handleImageChange}
							appLogo={appLogo}
							posImageUrl={posImageUrl}
							offerImageUrl={offerImageUrl}
							cartImageUrl={cartImageUrl}
							orderImageUrl={orderImageUrl}
						/>
						<Form.Item>
							<SignInTemplate.AntdButton
								type="Primary"
								color="white"
								bg="Primary"
								htmlType="submit"
								loading={submitting}
								style={{ width: "200px" }}
							>
								Guardar cambios
							</SignInTemplate.AntdButton>
						</Form.Item>
					</InnerForm>
				</Form>
			) : (
				<DataContainer>
					<DropShadow type="drop">
						<SignInTemplate.AntdButton
							type="primary"
							bg="Primary"
							color="White"
							icon={
								<AiOutlinePlusCircle
									style={{
										top: 2,
										position: "relative",
										marginRight: "5px",
									}}
								/>
							}
							onClick={handleCreateConfig}
							style={{ fontWeight: "600" }}
							width={220}
							height={38}
							fontSize={12}
						>
							CREAR CONFIGURACION
						</SignInTemplate.AntdButton>
					</DropShadow>
				</DataContainer>
			)}
		</Inner>
	);
}
