import axiosInstance from "../axiosInstance";

const CONTROLLER = "stores";

const get_stores = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}`
	);
	return response.data;
};

const get_stores_with_contracts = async (search, skip, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetStoresWithContracts?search=${
			search || ""
		}&skip=${skip}&page=${page}`
	);
	return response.data;
};

const get_book_stores = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetBookStores?search=${search || ""}`
	);
	return response.data;
};

export { get_stores, get_stores_with_contracts, get_book_stores };
