import { Select, Steps } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../layout/theme";

export const Inner = styled.div`
	background-color: ${COLORS.LightGrey};
	padding: 30px 70px;

	@media only screen and (min-width: 1191px) and (max-width: 1284px) {
		padding: 30px 5px;
	}

	@media only screen and (min-width: 1285px) and (max-width: 1393px) {
		padding: 30px 30px;
	}
`;

export const Body = styled.div`
	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}
`;

export const StepsContainer = styled.div`
	margin: 1rem;
	background-color: white;
	border-radius: 10px;
`;

export const AntdSteps = styled(Steps)`
	padding: 20px 150px 20px 150px;

	.ant-steps-item-content {
		background-color: ${COLORS.White};
	}

	.ant-steps-item-title::after {
		position: absolute;
		top: 16px;
		left: 200px;
		display: block;
		width: 1450px;
		height: 2px;
		background: #f0f0f0;
		content: "";
	}

	&.ant-steps-item-active {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					color: ${COLORS.Primary};
				}
				.ant-steps-item-icon {
					color: ${COLORS.Primary};
				}
			}
		}
	}

	.ant-steps-item-process
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title {
		color: ${COLORS.Primary};
		width: 170px;
		border: 1px solid ${COLORS.Primary};
		border-radius: 5px;
	}

	.ant-steps-item-finish {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					color: ${COLORS.Grey};
				}
			}
		}
	}

	&.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role="button"]
		.ant-steps-item-icon
		.ant-steps-icon {
		display: flex;
		margin-right: 10px;
		pointer-events: none;
	}

	.ant-steps-item-custom.ant-steps-item-process
		.ant-steps-item-icon
		> .ant-steps-icon {
		display: flex;
		margin-right: 10px;
	}

	.ant-steps-item-finish
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title::after {
		background-color: ${COLORS.Secondary};
	}

	.ant-steps-item-wait
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title {
		width: 170px;
		border: 1px solid ${COLORS.Grey};
		border-radius: 5px;
	}

	.ant-steps-item-finish
		.ant-steps-item-container
		.ant-steps-item-content
		.ant-steps-item-title {
		width: 170px;
		border: 1px solid ${COLORS.Grey};
		border-radius: 10px;
	}

	.ant-steps-item:last-child {
		pointer-events: none;
	}

	@media only screen and (min-width: 760px) and (max-width: 881px) {
		padding: 20px 30px 20px 30px;
	}

	@media only screen and (min-width: 882px) and (max-width: 979px) {
		padding: 20px 80px 20px 80px;
	}

	@media only screen and (min-width: 980px) and (max-width: 1076px) {
		padding: 20px 100px 20px 100px;
	}

	@media only screen and (min-width: 1075px) and (max-width: 1189px) {
		padding: 20px 120px 20px 120px;
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 170px;
`;

export const IconContainer = styled.img`
	align-items: center;
	display: flex;
	justify-content: center;
	object-fit: contain;
	width: 25px;
	height: auto;
`;

export const TitleContainer = styled.div`
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.Bold};
`;
export const SubTitleContainer = styled.div`
	font-size: ${SIZES.level8};
	font-weight: ${WEIGHT.Bold};
`;

export const ClientSelect = styled(Select)`
  margin-bottom: 20px;
  position: absolute;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 2px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &..ant-select-arrow {
      
    }
  

  &.ant-select-selector{
    .ant-select-selection-item {
      font-size: ${SIZES.level7};
      color: ${COLORS.Primary};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${COLORS.Primary};
      }
    }
  }
`;

export const Section = styled.div``;
