import {
	LeftOutlined,
	RightCircleOutlined,
	RightOutlined,
} from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { get_market_products_dapper } from "../../../../../../api/endpoints/products";
import Image from "../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../layout/theme";
import {
	CompaginationDiv,
	Container,
	Inner,
	ModalContainer,
	OptionSection,
	SearchContainer,
} from "./styles";
import ProductTable from "../product-table/ProductTable";
import debounce from "lodash/debounce";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { calculatePrice } from "../../../../../../utils/calculatePrice";
let productIndex = null;

export default function AddProducts({
	templateProducts,
	setTemplateProducts,
	userId,
	clientId,
}) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [searchByStore, setSearchByStore] = useState(null);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(50);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchModalShow, setSearchModalShow] = useState(false);

	const productColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			width: 80,
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image width={40} height={40} src={value} alt={record.title} />
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Laboratorio</div>,
			dataIndex: "clientName",
			key: "clientName",
			width: 150,
			render: (value, record, index) => (
				<Container key={`${value}`}>
					<Image
						width={20}
						height={20}
						src={record.clientPhotoUrl}
						alt={record.clientName}
					/>{" "}
					<Typo type="primary" level={6}>
						{value}
					</Typo>
				</Container>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			render: (value, record, index) => (
				<Typo type="primary" level={6}>
					{value} {record.presentationName}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio</div>,
			dataIndex: "price",
			key: "price",
			width: 130,
			render: (value, record, index) => (
				<Typo type="grey" level={6}>
					$ {value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "discount",
			key: "discount",
			width: 100,
			render: (v, r, index) => (
				<Typo type="success" level={6}>
					{numberWithDotAndCommas(
						(
							100 -
							(calculatePrice(r.price, r.clientGeneralDiscount, v, 0, 0) *
								100) /
								r.price
						).toFixed(2)
					)}
					%
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar al pedido</div>,
			key: "action",
			width: 160,
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(index);
						}}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	const fetch_products = async (search, skipValue) => {
		setLoading(true);
		try {
			const res = await get_market_products_dapper(
				search,
				skipValue !== null ? skipValue : skip,
				take
			);
			setData(
				res.products.map((r) => ({
					...r,
					quantity: 1,
					key: r.productId,
					id: r.productId,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setSearchModalShow(true);
		}
	};

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - 50;

			fetch_products(searchByStore, skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - 50);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + 50;

		fetch_products(searchByStore, skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + 50);
	};

	useEffect(() => {
		if (productIndex) {
			setData(data?.filter((p, idx) => p.id !== productIndex[0]?.id));
		}
	}, [productIndex]);

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => idx === e);
		productIndex = _template;
		const auxTemplate = templateProducts.concat(_template);

		const TemplateSinDuplicados = auxTemplate.reduce(
			(acumulador, valorActual) => {
				const elementoYaExiste = acumulador.find(
					(elemento) => elemento.id === valorActual.id
				);
				if (elementoYaExiste) {
					return acumulador.map((elemento) => {
						if (elemento.id === valorActual.id) {
							return {
								...elemento,
								quantity: elemento.quantity + valorActual.quantity,
							};
						}
						return elemento;
					});
				}

				return [...acumulador, valorActual];
			},
			[]
		);

		setTemplateProducts(
			TemplateSinDuplicados.sort((a, b) => {
				const titleA = a.title.toLowerCase();
				const titleB = b.title.toLowerCase();
				if (titleA < titleB) {
					return -1;
				}
				if (titleA > titleB) {
					return 1;
				}

				return 0;
			})
		);
	};

	const handleDelete = (index) => {
		const _template = templateProducts.filter((p, idx) => idx !== index);
		setTemplateProducts(_template);
	};

	const handleTableChange = async (values) => {
		const _template = templateProducts.map((p, idx) =>
			idx === values.index
				? {
						...p,
						quantity: parseInt(values.value),
				  }
				: { ...p }
		);

		setTemplateProducts(_template);
	};

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setCurrentPage(1);
		setSearchByStore(e.target.value);
		fetch_products(e.target.value, 0);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 600), []);

	const handleModalOk = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	const handleModalCancel = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	return (
		<Inner>
			<OptionSection>
				<SearchContainer>
					<AntdSearch
						allowClear
						placeholder="Buscar productos"
						width={459}
						onChange={(e) => setSearchByStore(e.target.value)}
						value={searchByStore}
						onPressEnter={(e) =>
							e.target.value.toUpperCase() === "RX" ||
							e.target.value.toUpperCase() === "OTC"
								? debouncedEventHandler(e)
								: e.target.value.length > 3
								? debouncedEventHandler(e)
								: console.log(e.target.value)
						}
						disabled={loading}
					/>
				</SearchContainer>
			</OptionSection>
			<Container>
				<Modal
					open={searchModalShow}
					onOk={handleModalOk}
					onCancel={handleModalCancel}
					width={1100}
					centered={true}
					footer={null}
					destroyOnClose={true}
				>
					<ModalContainer>
						{data && data.length > 0 && (
							<CompaginationDiv>
								{currentPage !== 1 && (
									<Button size="small" shape="circle" onClick={handleDecrement}>
										<LeftOutlined />
									</Button>
								)}
								{currentPage}
								{data.length === 50 && (
									<Button
										size="small"
										shape="circle"
										onClick={handleIncrement}
										disabled={data.length < 50 ? true : false}
									>
										<RightOutlined />
									</Button>
								)}
							</CompaginationDiv>
						)}
						<ProductTable
							data={data}
							columns={productColumns}
							loading={loading}
							scrollData={{ y: 350 }}
						/>
					</ModalContainer>
				</Modal>
				<ProductTable
					data={templateProducts}
					onDelete={handleDelete}
					onChange={handleTableChange}
				/>
			</Container>
		</Inner>
	);
}
