import React from "react";
import { ImageSkeleton, Inner, ResultsSection } from "./styles";

export default function LoadingSkeleton() {
	return (
		<Inner>
			<ResultsSection>
				<ImageSkeleton active={true} block />
				<ImageSkeleton active={true} block />
				<ImageSkeleton active={true} block />
				<ImageSkeleton active={true} block />
				<ImageSkeleton active={true} block className="wide" />
				<ImageSkeleton active={true} block className="large" />
				<ImageSkeleton active={true} block />
				<ImageSkeleton active={true} block />
				<ImageSkeleton active={true} block />
				<ImageSkeleton active={true} block />
			</ResultsSection>
		</Inner>
	);
}
