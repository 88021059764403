import React, { useEffect, useMemo, useState } from "react";
import { CardContainer, Container, Section } from "./styles";
import { Typo } from "../../../../../layout/theme";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";
import { Spin } from "antd";
import debounce from "lodash/debounce";
import { get_expo_events } from "../../../../../../api/endpoints/event";
import EventCardContainer from "../../../../../containers/event-card/EventCardContainer";
import { CONSTANTS } from "../../../../../../utils/constants";
import { get_laboratory_expo_products_dapper } from "../../../../../../api/endpoints/products";

export default function EventSelectionStep({
	clientId,
	setStep,
	expoSelected,
	setExpoSelected,
	setEventProducts,
}) {
	const [loading, setLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [events, setEvents] = useState(null);

	const fetch_events = async () => {
		setLoading(true);
		try {
			const res = await get_expo_events(searchTerm || "", clientId, "ACTIVE");
			setEvents(res);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const fetch_products = async (eventId) => {
		setLoading(true);
		try {
			const res = await get_laboratory_expo_products_dapper(
				"",
				clientId,
				eventId,
				1,
				CONSTANTS.PAGE_SIZE_ALL
			);
			setEventProducts(
				res.items.map((r) => ({
					...r,
					quantity: 0,
					key: r.id,
					hasChanges: false,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch_events();
	}, [searchTerm]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setSearchTerm(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleEventSelect = async (item) => {
		fetch_products(item.id);
		setExpoSelected(item);
		setStep(1);
	};

	return (
		<Section>
			<Typo type="primary" level={6}>
				Seleccione un evento
			</Typo>
			<Container>
				<AntdSearch
					width={260}
					allowClear
					placeholder={loading ? "" : "Buscar evento"}
					disabled={loading}
					onChange={debouncedEventHandler}
				/>
				{loading && (
					<div
						style={{
							position: "absolute",
							paddingTop: 1,
							paddingLeft: 11,
						}}
					>
						<Typo type="secondary" level={7}>
							Cargando{" "}
						</Typo>{" "}
						<Spin size="small" />
					</div>
				)}
			</Container>
			{events &&
				events.map((i, idx) => (
					<CardContainer key={idx}>
						<EventCardContainer item={i} onSelect={handleEventSelect} />
					</CardContainer>
				))}
		</Section>
	);
}
