import React from "react";
import { TextSection } from "./styles";
import { Tooltip } from "antd";
import { COLORS, Typo } from "../../layout/theme";

export default function CustomAntdTooltip({
	placement = "right",
	width = "480px",
	color = COLORS.Primary,
	tooltipContent,
	children,
}) {
	return (
		<Tooltip
			placement={placement}
			overlayInnerStyle={{ width: width }}
			color={color}
			title={
				<TextSection>
					{tooltipContent &&
						tooltipContent.map((txt, idx) => (
							<React.Fragment key={idx}>
								{txt.title && (
									<Typo type="white" level={6} weight="Bold">
										{txt.title}
									</Typo>
								)}
								<Typo type="white" level={6} weight="Light">
									{txt.body}
								</Typo>
							</React.Fragment>
						))}
				</TextSection>
			}
		>
			{children}
		</Tooltip>
	);
}
