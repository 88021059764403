import React from "react";
import Highcharts from "highcharts";
import { ChartContainer } from "./styles";
import variablepie from "highcharts/modules/variable-pie";

variablepie(Highcharts);
export default function Chart({
	options,
	big,
	headerBox,
	headerBoxMobile,
	headerBoxPharmacy,
	headerBoxRegion,
	timeChart,
	timeChartDistributor,
	headerBoxPie,
}) {
	return (
		<ChartContainer
			highcharts={Highcharts}
			options={options}
			containerProps={
				big
					? null
					: timeChart
					? {
							style: {
								width: "100%",
								maxWidth: "100%",
								height: "250px",
								backgroundCOlor: "transparent",
								margin: "0px",
								padding: "0px",
							},
					  }
					: timeChartDistributor
					? {
							style: {
								width: "100%",
								maxWidth: "100%",
								height: "350px",
								backgroundCOlor: "transparent",
								margin: "0px",
								padding: "0px",
							},
					  }
					: headerBox
					? {
							style: {
								width: "100%",
								maxWidth: "100%",
								height: "220px",
								backgroundCOlor: "transparent",
								margin: "0px",
								padding: "0px",
							},
					  }
					: headerBoxMobile
					? {
							style: {
								width: "100%",
								maxWidth: "100%",
								height: "180px",
								backgroundCOlor: "transparent",
								margin: "0px",
								padding: "0px",
							},
					  }
					: headerBoxPharmacy
					? {
							style: {
								width: "100%",
								maxWidth: "100%",
								height: "150px",
								backgroundCOlor: "transparent",
								margin: "0px",
								padding: "0px",
							},
					  }
					: headerBoxRegion
					? {
							style: {
								width: "100%",
								maxWidth: "100%",
								height: "70px",
								backgroundColor: "transparent",
								margin: "0px",
								padding: "0px",
							},
					  }
					: headerBoxPie
					? {
							style: {
								width: "100%",
								maxWidth: "100%",
								height: "220px",
								backgroundCOlor: "transparent",
								margin: "0px",
								padding: "0px 40px",
							},
					  }
					: {
							style: {
								width: "202px",
								maxWidth: "202px",
								height: "150px",
								backgroundCOlor: "transparent",
								margin: "0px",
								padding: "0px",
							},
					  }
			}
		/>
	);
}
