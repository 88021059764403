import styled from "styled-components";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	height: 100vh;
	background-color: rgb(243 244 246 / 1);
`;
export const ResultsSection = styled.div`
	flex: 1 1 80%;
	padding: 50px 50px 50px 60px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
`;
export const PlaceholderStore = styled.div`
	height: 280px;
	width: 270px;
	background: ${COLORS.SkeletonGrey};
	border-radius: 20px;
`;
