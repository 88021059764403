import styled from "styled-components";
import { Radio, Select, Option, Table } from "antd";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	background-color: ${COLORS.LightGrey};

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: column;
	}
`;

export const Filters = styled.div`
	margin: 20px 0;

	& button {
		padding: 0 !important;
	}
`;

export const RadioOptions = styled.div`
	margin-bottom: 20px;
`;

export const AntdRadioButton = styled(Radio.Button)`
	border: none;
	font-weight: ${WEIGHT.Bold};
`;

export const AntdSelect = styled(Select)`
	border: none;
	font-weight: ${WEIGHT.SemiBold};
`;

export const AntdOption = styled.div`
	border: none;
	font-weight: ${WEIGHT.SemiBold};
	color: ${COLORS.Grey};
`;

export const PreviewImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: contain;
`;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	background-color: ${COLORS.White};
	gap: 20px;
`;

export const SearchContainer = styled.div`
	flex: 1 1 40%;
	align-items: center;
	align-content: center;
	justify-content: center;
	display: flex;
	align-content: space-evenly;
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
`;

export const CompaginationDiv = styled.div`
	display: flex;
	gap: 4px;
`;

export const ContainerTitle = styled.div`
	display: flex;
	align-items: start;
	gap: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const CartDelete = styled.div`
	color: ${COLORS.Grey};
	font-family: sans-serif;
	font-stretch: ultra-expanded;
	font-size: ${SIZES.level12};
	font-weight: ${WEIGHT.Bold};
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	align-content: center;
	justify-content: center;
	height: 80px;
`;
export const AntdTable = styled(Table)`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;
export const ProductData = styled.div`
	flex: 1 1 65%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	max-height: 300px;
`;
export const PublishSettings = styled.div`
	flex: 1 1 35%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	max-height: 300px;
`;
export const InnerForm = styled.div`
	display: flex;
	gap: 20px;
	height: 300px;
	margin-top: 30px;
`;
