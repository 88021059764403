import { Button, Select, Spin, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Main from "../../../layout/main/Main";
import { COLORS, Typo } from "../../../layout/theme";
import { BodyContainer, Container, Inner, LoadingSection } from "./styles";

const { Title, Paragraph } = Typography;

export default function BuyerStartGuide() {
	return (
		<Main>
			<LoadingSection>
				<BodyContainer>
					<Title style={{ color: COLORS.Primary, marginTop: 30 }} level={3}>
						Catalogo de Productos
					</Title>
					<Paragraph>
						<blockquote>
							Estamos procesando tu búsqueda para mostrarte las mejores ofertas
						</blockquote>
					</Paragraph>
					<Spin size="large" />
				</BodyContainer>
			</LoadingSection>
		</Main>
	);
}
