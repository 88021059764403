import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Space, Radio, Menu, Dropdown, Modal, Spin } from "antd";
import {
	CloseCircleOutlined,
	PlusOutlined,
	InfoCircleOutlined,
} from "@ant-design/icons";
import { ActionButton, Filters, Inner } from "./styles";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import { COLORS, Typo } from "../../../../layout/theme";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import DataTable from "../../../../common/data-table/DataTable";
import {
	get_featured_products_header,
	get_featured_products_list_dapper,
	update_featured_product_state,
} from "../../../../../api/endpoints/advertisings";

const { confirm } = Modal;

export default function FeaturedProductsList() {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [headerInfo, setHeaderInfo] = useState(null);
	const [key, setKey] = useState(null);

	const menuActive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/featuredSettings/featuredProduct/${key}`}>
							<Typo type="secondary">Ver</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						<Typo type="secondary" onClick={() => handlePauseFeatured(key)}>
							Pausar
						</Typo>
					),
				},
				{
					key: "3",
					label: (
						<Typo type="secondary" onClick={() => handleDeleteCampaign(key)}>
							Borrar
						</Typo>
					),
				},
			]}
		/>
	);

	const menuPaused = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/featuredSettings/featuredProduct/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						<Typo type="secondary" onClick={() => handleActivateCampaign(key)}>
							Activar
						</Typo>
					),
				},
				{
					key: "3",
					label: (
						<Typo type="secondary" onClick={() => handleDeleteCampaign(key)}>
							Borrar
						</Typo>
					),
				},
			]}
		/>
	);

	const menuFuture = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/featuredSettings/featuredProduct/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						<Typo type="secondary" onClick={() => handlePauseFeatured(key)}>
							Pausar
						</Typo>
					),
				},
				{
					key: "3",
					label: (
						<Typo type="secondary" onClick={() => handleDeleteCampaign(key)}>
							Borrar
						</Typo>
					),
				},
			]}
		/>
	);

	const notMenu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/featuredSettings/featuredProduct/${key}`}>
							<Typo type="secondary">Ver</Typo>
						</Link>
					),
				},
			]}
		/>
	);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "title",
			render: (value, record) => (
				<Link
					to={`/admin/featuredSettings/featuredProduct/${key?.id}`}
					onMouseOver={() => setKey(record)}
				>
					<Typo type="terciary" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Link>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Productos destacados</div>
			),
			dataIndex: "featuredProducts",
			render: (value, record) => (
				<Typo type="terciary" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Posición en Home</div>,
			dataIndex: "cardPosition",
			render: (value, record) => (
				<Typo type="terciary" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			width: 30,
			dataIndex: "id",
			className: "noBackground",
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={
							state === "ACTIVE"
								? menuActive
								: state === "PAUSED"
								? menuPaused
								: state === "FUTURE"
								? menuFuture
								: notMenu
						}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const fetch_featured_products = async (search) => {
		const res = await get_featured_products_list_dapper(search, state);
		setData(res);
	};

	const fetch_featured_products_header_info = async () => {
		const res = await get_featured_products_header();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_featured_products();
		fetch_featured_products_header_info();
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_featured_products(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleAction = async (key, state) => {
		const _state = { id: key, state: state };
		await update_featured_product_state(_state);
		fetch_featured_products("");
	};

	const handlePauseFeatured = (key) => {
		confirm({
			title: "¿Seguro que quiere pausar este destacado?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El cambio se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "PAUSED");
			},
		});
	};

	const handleDeleteCampaign = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar el destacado?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "El cambio se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "DISABLED");
			},
		});
	};
	const handleActivateCampaign = (key) => {
		confirm({
			title: "¿Seguro que quiere activar el destacado?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El cambio se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "ACTIVE");
			},
		});
	};

	return (
		<Inner>
			<Filters>
				<Space size="large">
					<AntdSearch
						width={400}
						allowClear
						placeholder="Buscar destacado"
						onChange={debouncedEventHandler}
					/>

					<DropShadow type="drop">
						<SignInTemplate.AntdButton
							type="primary"
							bg="Primary"
							color="White"
							icon={<PlusOutlined />}
							onClick={() =>
								navigate("/admin/featuredSettings/createFeaturedProducts")
							}
							style={{ width: "200px", fontWeight: "400" }}
						>
							Crear Destacado
						</SignInTemplate.AntdButton>
					</DropShadow>
				</Space>

				<Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
					<Radio.Button value="ACTIVE">{`Activos (${headerInfo?.totalPublished})`}</Radio.Button>
					<Radio.Button value="PAUSED">{`Pausadas (${headerInfo?.totalPaused})`}</Radio.Button>
					{/* <Radio.Button value="FUTURE">{`Futuras (${headerInfo?.totalFuture})`}</Radio.Button>
					<Radio.Button value="FINISHED">{`Finalizadas (${headerInfo?.totalFinished})`}</Radio.Button> */}
					<Radio.Button value="DISABLED">{`Borrados (${headerInfo?.totalDeleted})`}</Radio.Button>
				</Radio.Group>
			</Filters>
			<DataTable data={data} columns={columns} />
		</Inner>
	);
}
