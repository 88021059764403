import styled from "styled-components";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
  display: flex;
  gap: 50px;
`;
export const ClientData = styled.div`
  flex: 1 1 80%;
`;

export const TableHeader = styled.div`
  height: 40px;
  width: 800px;
  background: ${COLORS.SkeletonGrey};
`;
