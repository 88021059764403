import axiosInstance from "../axiosInstance";

const CONTROLLER = "saledepartments";

const get_sale_departments = async () => {
	const response = await axiosInstance.get(CONTROLLER);
	return response.data;
};

const get_sale_departments_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_sale_departments_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const create_sale_department = async (saleDepartment) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, saleDepartment);
	return response.data;
};

const update_sale_department = async (id, saleDepartment) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/${id}`,
		saleDepartment
	);
	return response.data;
};

const get_sale_department_header_info = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetSaleDepartmentHeader`
	);
	return response.data;
};

const update_sale_department_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateSaleDepartmentState/${id}`,
		state
	);
	return response.data;
};

export {
	get_sale_departments,
	get_sale_departments_page,
	get_sale_departments_by_id,
	create_sale_department,
	update_sale_department,
	get_sale_department_header_info,
	update_sale_department_state,
};
