import axiosInstance from "../axiosInstance";

const CONTROLLER = "administrationKeys";

const get_administration_keys = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}`
	);
	return response.data;
};

const get_administration_keys_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_administration_key_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const create_administration_key = async (activeIngredient) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, activeIngredient);
	return response.data;
};

const update_administration_key = async (id, activeIngredient) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/${id}`,
		activeIngredient
	);
	return response.data;
};

const get_administration_keys_header_info = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetAdministrationKeysHeader`
	);
	return response.data;
};

const update_administration_key_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateAdministrationKeyState/${id}`,
		state
	);
	return response.data;
};

export {
	get_administration_keys,
	get_administration_keys_page,
	create_administration_key,
	get_administration_key_by_id,
	update_administration_key,
	get_administration_keys_header_info,
	update_administration_key_state,
};
