import styled from "styled-components";

export const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-height: 500px;
	background-color: transparent;
	border-radius: 20px;
	width: 100%;
	gap: 10px;
`;

export const LoadingSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 80px 30px 80px;
`;
