import styled from "styled-components/macro";
import { COLORS, SIZES } from "../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	border-radius: 15px;
	background-color: ${COLORS.White};
	height: 140px;
	width: 100%;
	padding: 10px 20px;
	gap: 20px;
`;
export const ImageContainer = styled.div`
	flex: 1 1 20%;
	-webkit-flex: 0 0 20%;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	object-fit: contain;
`;
export const CenterData = styled.div`
	flex: 1 1 60%;
	display: flex;
	border-left: 1px solid ${COLORS.Grey};
	border-right: 1px solid ${COLORS.Grey};
	padding: 10px;
	gap: 20px;
`;

export const RightData = styled.div`
	flex: 1 1 10%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	gap: 40px;
	pointer-events: ${(props) => (props.isOneMarket ? "none" : "auto")};
`;

export const Section = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	gap: 10px;
`;

export const Container = styled.div`
	flex: 1 1 40%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const OffContainer = styled.div`
	writing-mode: vertical-lr;
	font-size: 11px;
	transform: rotateZ(180deg);
	color: ${COLORS.DarkGrey};
	margin-right: -8px;
`;

export const CartButtomNumber = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	& input {
		background: transparent !important;
		color: ${COLORS.Grey} !important;
		border: none !important;
		height: 100% !important;
		outline: none !important;
		box-shadow: none !important;
		text-align: center !important;
		font-size: ${SIZES.level4} !important;
		padding: 0 10px;
		width: 60px;
		position: absolute;
		top: -45px;
	}
`;

export const Loader = styled.div`
	position: absolute;
	top: 0px;
	transform: translate(10px, -20px) scale(2.5);
	background-color: transparent;
	z-index: 999;
`;

export const ItemsQty = styled.div`
	position: absolute;
	color: ${COLORS.Grey};
	user-select: none;
	margin-bottom: -115px;
`;

export const Line = styled.div`
	text-decoration: line-through rgba(0, 0, 0, 0.2);
	align-items: flex-start;
	align-content: center;
	justify-content: center;
	display: flex;
`;
