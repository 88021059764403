import styled, { keyframes } from "styled-components";
import { COLORS, WEIGHT } from "../../../../layout/theme";

const linearMove = keyframes`
  from {
    transform: translateX(20%);
  }

  to {
    transform: translateX(0%);
  }
`;

export const Filters = styled.div`
	margin: 20px 0;

	& button {
		padding: 0 !important;
	}
`;

export const SpanCointainer = styled.div`
	margin: 60px 0;
`;

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;

export const LeftData = styled.div`
	flex: 1 1 65%;
`;

export const RightData = styled.div`
	flex: 1 1 35%;
`;

export const Container = styled.div`
	padding: 0 2rem;
`;

export const AllProductsContainer = styled.div`
	text-align: end;
`;

export const PreviewImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: contain;
`;

export const Span = styled.span`
	cursor: pointer;
	color: ${COLORS.Secondary};
	margin-right: 60px;
	font-weight: ${WEIGHT.ExtraBold};
`;

export const CampaignsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const CardContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const Arrow = styled.div`
	display: inline-block;
	animation: ${linearMove} 1s linear infinite;
	padding: 2rem 1rem;
	font-size: 1.2rem;
	color: ${COLORS.Success};
`;
