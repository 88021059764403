import { Skeleton, Row, Col, Space } from "antd";
import {
  Inner,
  ProductData,
  PublishContainer,
  PublishSettings,
} from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
  return (
    <Inner>
      <Skeleton.Input
        active={true}
        size="default"
        style={{
          width: "80vw",
          height: "500px",
        }}
      />
    </Inner>
  );
}
