import styled from "styled-components";

export const InnerForm = styled.div`
	.ant-row {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.ant-col:first-child {
		padding-left: 0 !important;
	}

	.ant-col:last-child {
		padding-right: 0 !important;
	}

	@media only screen and (min-width: 701px) and (max-width: 900px) {
		.ant-row {
			row-gap: 10px;
		}
	}

	@media only screen and (min-width: 414px) and (max-width: 700px) {
		.ant-row {
			row-gap: 10px;
		}
	}

	@media only screen and (min-width: 250px) and (max-width: 413px) {
		.ant-row {
			row-gap: 10px;
		}
	}
`;
