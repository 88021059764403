import React, { useEffect, useMemo, useState } from "react";
import {
	AntdInput,
	CompaginationDiv,
	Container,
	Section,
	TableContainer,
} from "./styles";
import { COLORS, Typo } from "../../../../../layout/theme";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";
import { Button, Modal, Space, Spin } from "antd";
import DataTable from "../../../../../common/data-table/DataTable";
import {
	CloseCircleOutlined,
	FormOutlined,
	LeftOutlined,
	RightCircleOutlined,
	RightOutlined,
} from "@ant-design/icons";
import {
	get_client_name,
	get_pharmacies_for_laboratory_seller,
} from "../../../../../../api/endpoints/clients";
import { CONSTANTS } from "../../../../../../utils/constants";
import debounce from "lodash/debounce";
import { useAuth } from "../../../../../../contexts/authContext";

const { confirm } = Modal;

export default function PharmacySelectionStep({
	clientId,
	clients,
	setClients,
	selectedClient,
	setSelectedClient,
	setClientInfo,
	marketSellerClient,
	setStep,
	emptyCart,
	qty,
}) {
	const { user } = useAuth();
	const [loading, setLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [skip, setSkip] = useState(1);
	const [page, setPage] = useState(CONSTANTS.PAGE_LARGE_SIZE);
	const [searchInfo, setSearchInfo] = useState(null);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [cartClientName, setCartClientName] = useState(null);

	useEffect(() => {
		const fetch_cart_client_name = async () => {
			try {
				const res = await get_client_name(selectedClient);
				setCartClientName(res?.name);
			} catch (error) {}
		};
		if (selectedClient) fetch_cart_client_name();
	}, []);

	const fetch_clients = async (skipValue) => {
		setLoading(true);
		try {
			const res = await get_pharmacies_for_laboratory_seller(
				searchTerm || "",
				clientId,
				skipValue,
				page
			);
			setClients({ items: res?.items });
			selectedClient &&
				setClientInfo(...res?.items?.filter((c) => c.id === selectedClient));
			setSearchInfo(res?.totalPos);
			setPaginationInputValue(res?.totalPos?.actualPage);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch_clients(1);
	}, [searchTerm]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setSearchTerm(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 1200), []);

	const handleClientSelect = async (value, record) => {
		if (value !== selectedClient && qty > 0) {
			confirm({
				title: `Ya existe un carro creado para el cliente ${cartClientName}`,
				icon: <CloseCircleOutlined style={{ color: "red" }} />,
				content: "Avanzar eliminará los productos del carro",
				okText: "Avanzar",
				okType: "default",
				cancelText: "Cancelar",
				onOk: () => {
					emptyCart({ userId: user.uid });
					handleSelectClient(value, record);
				},
			});
		} else {
			handleSelectClient(value, record);
		}
	};

	const handleSelectClient = (value, record) => {
		if (value) {
			marketSellerClient({ clientId: value });
			setSelectedClient(value);
			setClientInfo({
				...record,
				phone: record.contactPhone,
				contactEmail: null,
			});
			setStep(2);
		} else {
			setClientInfo({
				...record,
				phone: record.contactPhone,
				contactEmail: null,
			});
			setStep(1);
		}
	};

	const pointOfSaleColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Punto de Venta</div>,
			dataIndex: "name",
			width: 400,
			render: (value, r) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cuit</div>,
			dataIndex: "cuit",
			width: 100,
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Dirección</div>,
			dataIndex: "address",
			width: 350,
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Farmacia asociada</div>,
			dataIndex: "clientName",
			width: 350,
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value ?? "No hay datos en sistema"}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			width: 30,
			render: (f, r, i) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleClientSelect(r.clientId, r);
						}}
						style={{
							marginRight: 20,
							border: r.clientId
								? `1px solid ${COLORS.Warning}`
								: `1px solid ${COLORS.Secondary}`,
						}}
					>
						{r.clientId ? (
							<RightCircleOutlined style={{ color: COLORS.Warning }} />
						) : (
							<FormOutlined />
						)}
					</Button>
				</Space>
			),
		},
	];

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_clients(cp);
			setSkip(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_clients(cp);
			setSkip(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_clients(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	return (
		<Section>
			<Container>
				<AntdSearch
					width={260}
					allowClear
					placeholder={loading ? "" : "Buscar cliente"}
					disabled={loading}
					onChange={debouncedEventHandler}
				/>
				{loading && (
					<div
						style={{
							position: "absolute",
							paddingTop: 1,
							paddingLeft: 11,
						}}
					>
						<Typo type="secondary" level={7}>
							Cargando{" "}
						</Typo>{" "}
						<Spin size="small" />
					</div>
				)}
			</Container>
			<TableContainer>
				<DataTable
					loading={loading}
					data={clients}
					columns={pointOfSaleColumns}
					pagination={false}
				/>
			</TableContainer>
			{searchInfo && searchInfo.totalPOS > 0 && (
				<CompaginationDiv>
					{searchInfo.actualPage !== 1 && (
						<Button size="small" shape="circle" onClick={handleDecrement}>
							<LeftOutlined />
						</Button>
					)}
					<AntdInput
						step={1}
						min={1}
						max={searchInfo.totalPages}
						onPressEnter={(e) => handlePageChange(e)}
						onChange={(e) => setPaginationInputValue(e.target.value.toString())}
						defaultValue={paginationInputValue.toString()}
						value={paginationInputValue.toString()}
						addonAfter={`/ ${searchInfo.totalPages}`}
						style={{
							width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
						}}
					/>
					{searchInfo.actualPage < searchInfo.totalPages && (
						<Button
							size="small"
							shape="circle"
							onClick={handleIncrement}
							disabled={
								searchInfo.actualPage === searchInfo.totalPages ? true : false
							}
						>
							<RightOutlined />
						</Button>
					)}
				</CompaginationDiv>
			)}
		</Section>
	);
}
