import React, { useRef } from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "swiper/css/effect-coverflow";

import { Navigation, Pagination, Autoplay } from "swiper";
import {
	SwiperNavNext,
	SwiperNavPrev,
	WidthImage,
	SwiperProducts,
} from "./styles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export default function SliderProduct({
	delay = 3000,
	children,
	navigation = false,
	pagination = false,
	slidesPerView = 1,
	loop = false,
	spaceBetween = 10,
	slidesPerGroup = 1,
}) {
	const swiperSingleNavPrevRef = useRef(null);
	const swiperSingleNavNextRef = useRef(null);

	return (
		<SwiperProducts
			spaceBetween={spaceBetween}
			allowTouchMove={true}
			loop={true}
			modules={[Navigation, Pagination, Autoplay]}
			pagination={pagination ? { clickable: true } : false}
			className="swiperProducts"
			autoHeight={true}
			autoplay={
				delay
					? {
							delay: delay,
							disableOnInteraction: true,
					  }
					: false
			}
			navigation={
				navigation
					? {
							prevEl: swiperSingleNavPrevRef.current,
							nextEl: swiperSingleNavNextRef.current,
					  }
					: false
			}
			onInit={(swiper) => {
				swiper.params.navigation.prevEl = swiperSingleNavPrevRef.current;
				swiper.params.navigation.nextEl = swiperSingleNavNextRef.current;
				swiper.navigation.init();
				swiper.navigation.update();
			}}
			slidesPerView={slidesPerView}
			slidesPerGroup={slidesPerGroup}
		>
			{!children ? (
				<SwiperSlide style={{ marginLeft: "40px" }}>
					<WidthImage />
				</SwiperSlide>
			) : (
				children
			)}
			<SwiperNavPrev ref={swiperSingleNavPrevRef}>
				{<LeftOutlined />}
			</SwiperNavPrev>
			<SwiperNavNext ref={swiperSingleNavNextRef}>
				{<RightOutlined />}
			</SwiperNavNext>
		</SwiperProducts>
	);
}
