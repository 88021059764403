import { Button, Checkbox, Modal } from "antd";
import styled from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;
export const ClientData = styled.div`
	flex: 1 1 65%;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;
export const PublishSettings = styled.div`
	flex: 1 1 35%;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;
export const DistributorImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`;
export const PreviewImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: contain;
`;
export const Container = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const AntdCheckbox = styled(Checkbox)``;

export const SectionData = styled.div`
	flex: 1;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const ModalText = styled.div`
	cursor: pointer;
	display: flex;
	gap: 5px;
	justify-content: center;
	align-items: center;
	color: ${(props) =>
		props.textColor === 1 ? COLORS.Primary : COLORS.Secondary};
	font-size: ${SIZES.level6};
`;
export const AntdModal = styled(Modal)`
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
	}
`;

export const UncheckedOrderAlert = styled.span`
	color: ${COLORS.Danger};
	font-weight: ${WEIGHT.Bold};
	margin-left: 10px;
`;

export const AntdButton = styled(Button)`
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
  
  border-radius: 20px;

	background-color: ${COLORS.Secondary};
	color: ${COLORS.White};
	font-weigth: ${WEIGHT.Bold};

	span {
		${(props) =>
			props.height &&
			`
        height: ${props.height}px;
      `}
		${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
	}
`;

export const CuitInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 0 20px 0 20px;
`;

export const Section = styled.div`
	display: flex;
	gap: 10px;
	justify-content: space-between;
`;
