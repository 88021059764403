import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey:
			process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY || "test",
		extensions: [reactPlugin],
		enableAutoRouteTracking: true,
		disableAjaxTracking: false,
		autoTrackPageVisitTime: false,
		enableCorsCorrelation: true,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true,
		disableFetchTracking: true,
	},
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
