import React, { useEffect, useRef, useState } from "react";
import { get_products_by_id } from "../../../../../../api/endpoints/products";
import {
	Inner,
	Container,
	LeftContainer,
	LeftCarousel,
	ImageContainer,
	Info,
	InfoRow,
	RightContainer,
	Line,
	PriceTextOff,
	PriceTextOffContainer,
	InfoSection,
	InfoDescription,
	ContainerShadow,
	Section,
	SubSection,
	InfoIva,
	SectionItems,
	ImageFooter,
	MainImageContainer,
	BottomContainer,
} from "./styles";
import Image from "../../../../../common/image/Image";
import { Typo } from "../../../../../layout/theme";
import { Card, Carousel } from "antd";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";

export default function ProductTemplateDetail({ id }) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const mediaRef = useRef(null);
	const navRef = useRef(null);

	const onChange = (currentSlide) => {
		mediaRef.current.goTo(currentSlide, false);
	};

	useEffect(() => {
		const fetch_product_by_id = async () => {
			setLoading(true);
			const res = await get_products_by_id(id);

			setData(res);
			setLoading(false);
		};
		fetch_product_by_id();
	}, []);

	if (!data) return <LoadingSkeleton />;

	return (
		<Inner>
			<Card style={{ position: "static" }}>
				<Container>
					<LeftContainer>
						<InfoSection>
							<Typo type="primary">EAN: </Typo>
							<Info>
								<Typo level={7} type="muted">
									{`${data.ean13}`}
								</Typo>
							</Info>
						</InfoSection>
						<LeftCarousel>
							{data && data.photoGallery.length > 0 && (
								<div>
									<Carousel
										asNavFor={navRef.current}
										touchMove={false}
										dots={false}
										ref={mediaRef}
										effect="fade"
									>
										<MainImageContainer>
											<Image
												style={{
													paddingTop: "90px",
													height: "400px",
													width: "100%",
												}}
												src={data.photoUrl}
											/>
										</MainImageContainer>
										{data &&
											data.photoGallery.map((i) => (
												<ImageFooter key={i.id}>
													<Image
														style={{
															paddingTop: "90px",
															height: "400px",
															width: "100%",
														}}
														src={i.url}
													/>
												</ImageFooter>
											))}
									</Carousel>
									<ContainerShadow
										style={{
											boxShadow:
												"rgb(50 50 93 / 25%) 40px 30px 20px -40px, rgb(0 0 0 / 25%) 0px 20px 30px -20px",
										}}
									>
										<Carousel
											slidesToShow={2}
											centerMode={true}
											asNavFor={mediaRef.current}
											draggable={true}
											ref={(carousel) => (navRef.current = carousel)}
											afterChange={onChange}
											swipeToSlide={true}
											touchThreshold={50}
											focusOnSelect={true}
											dots={false}
											style={{ marginTop: 10 }}
										>
											<ImageContainer>
												<Image
													style={{
														height: "90px",
														width: "80%",
														objectFit: "scale-down",
													}}
													src={data.photoUrl}
												/>
											</ImageContainer>
											{data &&
												data.photoGallery.map((i) => (
													<ImageContainer key={i.id}>
														<Image
															style={{
																height: "90px",
																width: "80%",
																objectFit: "scale-down",
															}}
															src={i.url}
														/>
													</ImageContainer>
												))}
										</Carousel>
									</ContainerShadow>
								</div>
							)}
							{data && data.photoGallery.length === 0 && (
								<ImageContainer>
									<Image
										preview={true}
										style={{
											height: "400px",
											width: "100%",
											objectFit: "scale-down",
										}}
										src={data.photoUrl}
									/>
								</ImageContainer>
							)}
						</LeftCarousel>
					</LeftContainer>
					<RightContainer>
						{data && (
							<Info>
								{data.offerPrice > 0 && data.offerPrice !== data.price ? (
									<InfoRow>
										<Section>
											<Typo type="primary" level={6}>
												OFERTA:{" "}
											</Typo>
											<PriceTextOffContainer>
												<PriceTextOff translate="no">OFF</PriceTextOff>
												<Typo
													level={4}
													style={{ paddingLeft: "10px", color: "#1FD655" }}
												>
													{`${numberWithDotAndCommas(
														(
															100 -
															(data.offerPrice * 100) / data.price
														).toFixed(2)
													)}%`}
												</Typo>
											</PriceTextOffContainer>
										</Section>
										<Section>
											<SubSection>
												<Typo type="primary" level={7}>
													{data.client.typeOfSale}
												</Typo>
												<Line>
													<Typo level={3} type="muted" underline>
														${numberWithDotAndCommas(data.price)}
													</Typo>
												</Line>
											</SubSection>
											<Typo level={2} type="secondary">
												{`$${numberWithDotAndCommas(
													data.offerPrice.toFixed(2)
												)}  `}
											</Typo>
										</Section>
										<InfoIva>IVA no incluido</InfoIva>
									</InfoRow>
								) : (
									<InfoRow>
										<SubSection>
											<Typo type="primary" level={7}>
												{data.client.typeOfSale}
											</Typo>
											<Typo level={1} type="secondary" underline>
												${numberWithDotAndCommas(data.price)}
											</Typo>
										</SubSection>
									</InfoRow>
								)}
								<Typo level={4} type="primary">
									{data.title}
								</Typo>
								<Typo type="muted" level={12}>
									{data.shortDescription}
								</Typo>
								<Section style={{ justifyContent: "space-between" }}>
									<SectionItems>
										<Typo type="primary">Categorias: </Typo>
										<Typo level={7} type="muted">
											{`${data.categories.map((c) => c.name).join(", ")}`}
										</Typo>
									</SectionItems>
									<SectionItems>
										<Typo type="primary">Principios Activos: </Typo>
										<Typo level={7} type="muted">
											{`${data.activeIngredients
												.map((a) => a.name)
												.join(", ")}`}
										</Typo>
									</SectionItems>
								</Section>
								<SectionItems>
									<Typo type="primary">Presentación: </Typo>
									<Typo level={7} type="muted">
										{`${data.presentation?.name}. ${data.presentationQty} ${data.presentation.unit?.shortName}`}
									</Typo>
								</SectionItems>
							</Info>
						)}

						{data.client.typeOfSale === "PVP" && (
							<Typo type="muted" level={8} translate="no">
								* PVP: Precio Venta Público
							</Typo>
						)}
						{data.client.typeOfSale === "PSD" && (
							<Typo type="muted" level={8} translate="no">
								* PSD: Precio Sugerido Droguería
							</Typo>
						)}
					</RightContainer>
				</Container>
				<BottomContainer>
					<InfoDescription>
						<Typo level={6} type="primary">
							Descripción:
						</Typo>
						<Info style={{ marginTop: 5 }}>
							<Typo level={6} type="muted">
								{`${data.longDescription}`}
							</Typo>
						</Info>
					</InfoDescription>
				</BottomContainer>
			</Card>
		</Inner>
	);
}
