import React, { useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import OfferSlideContainer from "../../../containers/product-slide/OfferSlideContainer";
import { Inner, Section, BannerContainer, SectionBanners } from "./styles";
import Slider from "../../../common/slider/Slider";
import SliderProduct from "../../../common/slider-products/SliderProducts";
import HotSaleSlideContainer from "../../../containers/product-slide/HotSaleSlideContainer";
import { get_campaigns_by_region } from "../../../../api/endpoints/campaigns";
import { get_banners } from "../../../../api/endpoints/banner";
import { get_products_by_max_discount } from "../../../../api/endpoints/products";

export default function HotSale() {
	const [loading, setLoading] = useState(false);
	const [bigBannerImages, setBigBannerImages] = useState(null);
	const [campaignRegion, setCampaignRegion] = useState(null);
	const [hotSaleProducts, setHotSaleProducts] = useState(null);

	const fetch_banner_data = async () => {
		const res = await get_banners("BIGBANNERHOTSALE", "ACTIVE");

		setBigBannerImages(
			res
				.map((b) => ({
					id: b.id,
					title: b.title,
					src: b.photoUrl,
					bannerUrl: b.bannerUrl,
					isActive: b.isActive,
				}))
				.filter((b) => b.isActive === true)
		);
	};

	useEffect(() => {
		setLoading(true);
		try {
			fetch_banner_data();
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		const fetch_campaign_region = async () => {
			const res = await get_campaigns_by_region();
			setCampaignRegion(res);
		};

		fetch_campaign_region();
	}, []);

	useEffect(() => {
		const fetch_max_discount_products = async () => {
			const res = await get_products_by_max_discount();
			setHotSaleProducts(res);
		};

		fetch_max_discount_products();
	}, []);

	if (!campaignRegion || !hotSaleProducts) return <div>Loading...</div>;

	return (
		<Inner>
			<Slider slidesPerView={1} navigation={true}>
				{bigBannerImages &&
					bigBannerImages.map((d, idx) => (
						<SwiperSlide key={`bigBanner_slide_${idx}`}>
							<HotSaleSlideContainer
								data={d}
								bannerHeight={"610px"}
								bannerFit="cover"
							/>
						</SwiperSlide>
					))}
			</Slider>

			<Section>
				<SliderProduct
					slidesPerView={4}
					spaceBetween={60}
					delay={false}
					navigation={true}
				>
					{hotSaleProducts &&
						hotSaleProducts.maxDiscountPVPProducts.map((d, idx) => (
							<SwiperSlide key={`offers_slide_${idx}`}>
								<OfferSlideContainer
									id={d.id}
									title={d.title}
									image={d.photoUrl}
									price={d.price}
									offerPrice={d.offerPrice}
									offerTo={d.offerTo}
									discount={d.discount}
									labImage={d.client.photoUrl}
									qtyInCart={d.productQtyInCart}
									clientName={d.client.name}
									minQuantity={d.minQuantity}
									maxQuantity={d.maxQuantity}
									productPresentation={d.presentation?.name}
									productQty={d.presentationQty}
									marketing={d.marketingId}
									clientTypeOfSale={d.client.typeOfSale}
									triggerQty={d.triggerQty}
									triggerDiscount={d.triggerDiscount}
								/>
							</SwiperSlide>
						))}
				</SliderProduct>
			</Section>

			<SectionBanners>
				<BannerContainer>
					<Slider
						loading={loading}
						slidesPerView={
							campaignRegion?.regionCampaignsSliderSmall1.length % 2 === 0
								? 2
								: 1
						}
						spaceBetween={5}
						navigation={true}
					>
						{campaignRegion?.regionCampaignsSliderSmall1 &&
							campaignRegion?.regionCampaignsSliderSmall1.map((d, idx) => (
								<SwiperSlide key={`campaign_slider_small1_${idx}`}>
									<HotSaleSlideContainer
										data={d}
										bannerHeight={"160px"}
										bannerBorder={"10px"}
									/>
								</SwiperSlide>
							))}
					</Slider>
				</BannerContainer>
			</SectionBanners>

			<SectionBanners>
				<BannerContainer>
					<Slider
						loading={loading}
						slidesPerView={1}
						spaceBetween={5}
						navigation={true}
					>
						{campaignRegion?.regionCampaignsSliderMedium1 &&
							campaignRegion?.regionCampaignsSliderMedium1.map((d, idx) => (
								<SwiperSlide key={`campaign_slider_medium1_${idx}`}>
									<HotSaleSlideContainer
										data={d}
										bannerHeight={"300px"}
										bannerBorder={"10px"}
									/>
								</SwiperSlide>
							))}
					</Slider>
				</BannerContainer>
			</SectionBanners>

			<Section>
				<SliderProduct
					slidesPerView={4}
					spaceBetween={60}
					delay={false}
					navigation={true}
				>
					{hotSaleProducts &&
						hotSaleProducts.maxDiscountPSDProducts.map((d, idx) => (
							<SwiperSlide key={`offers_slide_${idx}`}>
								<OfferSlideContainer
									id={d.id}
									title={d.title}
									image={d.photoUrl}
									price={d.price}
									offerPrice={d.offerPrice}
									offerTo={d.offerTo}
									discount={d.discount}
									labImage={d.client.photoUrl}
									qtyInCart={d.productQtyInCart}
									clientName={d.client.name}
									minQuantity={d.minQuantity}
									maxQuantity={d.maxQuantity}
									productPresentation={d.presentation?.name}
									productQty={d.presentationQty}
									marketing={d.marketingId}
									clientTypeOfSale={d.client.typeOfSale}
									triggerQty={d.triggerQty}
									triggerDiscount={d.triggerDiscount}
								/>
							</SwiperSlide>
						))}
				</SliderProduct>
			</Section>

			<SectionBanners>
				<BannerContainer>
					<Slider
						loading={loading}
						slidesPerView={
							campaignRegion?.regionCampaignsSliderSmall2.length % 2 === 0
								? 2
								: 1
						}
						spaceBetween={5}
						navigation={true}
					>
						{campaignRegion?.regionCampaignsSliderSmall2 &&
							campaignRegion?.regionCampaignsSliderSmall2.map((d, idx) => (
								<SwiperSlide key={`campaign_slider_small2_${idx}`}>
									<HotSaleSlideContainer
										data={d}
										bannerHeight={"160px"}
										bannerBorder={"10px"}
									/>
								</SwiperSlide>
							))}
					</Slider>
				</BannerContainer>
			</SectionBanners>
		</Inner>
	);
}
