import styled, { keyframes } from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../../../../../../../layout/theme";

const spin = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

export const Spinner = styled.div`
	margin-top: 10px;
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border-left-color: #09f;

	animation: ${spin} 1s ease infinite;
`;

export const ContainerDiv = styled.div`
	width: 100%;
	height: 650px;

	overflow: auto;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const InfoItem = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	text-align: center;
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const DiscountPer = styled.div`
	color: ${COLORS.Default};
	font-size: ${SIZES.level7};
	font-weight: ${WEIGHT.Bold};

	display: ${(props) => (props.quantity > 0 ? "auto" : "none")};
`;

export const TableTitle = styled.div`
	color: ${COLORS.White};
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
`;

export const TableBody = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
	align-content: center;
	justify-content: center;
`;
