import { Select } from "antd";
import styled from "styled-components";
import { COLORS, SIZES } from "../../../../../../../layout/theme";
import AntArrow from "../../../../../../../../assets/images/cart-arrow-select.svg";

export const AntdSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 2px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 70px;

  &..ant-select-arrow {
      src=${AntArrow};
    }
  

  &.ant-select-selector{
    .ant-select-selection-item {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      font-size: ${SIZES.level12};
      color: ${COLORS.Primary};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${COLORS.Primary};
      }
    }
  }
`;
