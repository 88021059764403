import { Space, Table, Button } from "antd";
import { useEffect, useState } from "react";
import Image from "../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../layout/theme";
import ProductQuantity from "../../../cart/components/step-1-products/components/product-quantity/ProductQuantity";
import { CartDelete, ContainerDiv, ContainerTitle } from "./styles";

export default function TemplatesTable({
	data,
	onAdd,
	onDecrement,
	onIncrement,
	onDelete,
	columns,
	scrollData,
	fetch,
	onChange,
}) {
	const defColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 90,
			render: (photoUrl) => <Image src={photoUrl} height={40} width={40} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			width: 380,
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 16 : 14}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.presentationName}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Cantidad en Plantilla</div>
			),
			dataIndex: "quantity",
			key: "quantity",
			width: 180,
			render: (quantity, record, index) => (
				<ProductQuantity
					product={{ quantity, record, index }}
					onChange={onChange}
				/>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Quitar</div>,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button type="link" onClick={() => onDelete(index)}>
						<CartDelete>X</CartDelete>
					</Button>
				</Space>
			),
		},
	];

	const [pagination, setPagination] = useState({
		current: 1,
	});

	const fetchData = async (params = {}) => {
		await fetch(params.pagination.current);

		setPagination({
			...params.pagination,
		});
	};

	useEffect(() => {
		if (!data) return;
		setPagination({
			...pagination,
			total: data.totalCount,
			pageSize: data.pageSize,
			current: data.pageNumber,
			showTotal: (total) => `Total ${total} items`,
		});
	}, [data]);

	const handleTableChange = (newPagination, filters, sorter) => {
		fetchData({
			sortField: sorter.field,
			sortOrder: sorter.order,
			pagination: newPagination,
			...filters,
		});
	};

	return (
		<ContainerDiv>
			<Table
				showHeader={true}
				columns={columns ? columns : defColumns}
				dataSource={data}
				pagination={fetch ? pagination : false}
				onChange={handleTableChange}
				rowKey={(record) => record.id}
				scroll={scrollData}
			/>
		</ContainerDiv>
	);
}
