import React from "react";
import { COLORS, Typo } from "../../../../../layout/theme";
import {
	AntdTable,
	BigChartContainer,
	Container,
	Dashboard1Container,
	FilterContainer,
} from "./styles";
import { Button, DatePicker, Select } from "antd";

export default function PharmacyDashboard({
	dashboard,
	distributors,
	selectDistributor,
	provinces,
	selectProvince,
	laboratories,
	selectLaboratory,
	pharmacies,
	selectPharmacy,
	handleDateChange,
	deleteFilters,
	isLoading,
	selectedLaboratory,
	selectedDistributor,
	selectedProvince,
	selectedPharmacy,
	pharmaciesTable,
	rangeDate,
	hasMobileDashboard,
}) {
	const columns = [
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Nombre</div>,
			width: 350,
			dataIndex: "pharmacyName",
			render: (value, record) => <Typo level={5}>{value}</Typo>,
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Provincia</div>,
			width: 300,
			dataIndex: "provinceName",
			render: (value, record) => (
				<Typo level={5} type="secondary">
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Localidad</div>,
			width: 250,
			dataIndex: "localityName",
			render: (value, record) => (
				<Typo level={5} type="secondary">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey }}>Ordenes totales</div>
			),
			dataIndex: "totalOrders",
			width: 140,
			render: (value, record) => (
				<Typo type="primary" level={3}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Mobile</div>,
			dataIndex: "totalMobileOrders",
			render: (value, record) => (
				<Typo type="muted" level={3}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Vendedor</div>,
			width: 250,
			dataIndex: "seller",
			render: (value, record) => (
				<Typo level={5}>
					{record.totalMobileOrders > 0
						? record.sellerFirstName + " " + record.sellerLastName
						: ""}
				</Typo>
			),
		},
	];

	const columnsWithoutMobile = [
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Nombre</div>,
			width: 350,
			dataIndex: "pharmacyName",
			render: (value, record) => <Typo level={5}>{value}</Typo>,
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Provincia</div>,
			width: 300,
			dataIndex: "provinceName",
			render: (value, record) => (
				<Typo level={5} type="secondary">
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Localidad</div>,
			width: 250,
			dataIndex: "localityName",
			render: (value, record) => (
				<Typo level={5} type="secondary">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey }}>Ordenes totales</div>
			),
			dataIndex: "totalOrders",
			render: (value, record) => (
				<Typo type="primary" level={3}>
					{value}
				</Typo>
			),
		},
	];

	return (
		<Dashboard1Container>
			<FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Fecha (desde/hasta)
					</Typo>
					{/* <DatePicker.RangePicker onChange={(e) => handleDateChange(e)} /> */}
					{rangeDate ? (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							value={rangeDate}
							onChange={(e) => handleDateChange(e)}
						/>
					) : (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							onChange={(e) => handleDateChange(e)}
						/>
					)}
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Droguería
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Droguería"
						optionFilterProp="children"
						value={selectedDistributor}
						// defaultValue={selectedDistributor ?? null}
						onChange={(e, o) => selectDistributor(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 180 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Provincia
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Provincia"
						optionFilterProp="children"
						value={selectedProvince}
						// defaultValue={selectedProvince ?? null}
						onChange={(e, o) => selectProvince(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={provinces}
						style={{ width: 220 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Farmacia
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Farmacia"
						optionFilterProp="children"
						value={selectedPharmacy}
						// defaultValue={selectedPharmacy ?? null}
						onChange={(e, o) => selectPharmacy(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={pharmacies}
						style={{ width: 180 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Laboratorio
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Laboratorio"
						optionFilterProp="children"
						value={selectedLaboratory}
						// defaultValue={selectedLaboratory ?? null}
						onChange={(e, o) => selectLaboratory(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={laboratories}
						style={{ width: 180 }}
					/>
				</Container>
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</FilterContainer>
			<BigChartContainer>
				<AntdTable
					dataSource={pharmaciesTable}
					loading={isLoading}
					columns={hasMobileDashboard ? columns : columnsWithoutMobile}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
			</BigChartContainer>
		</Dashboard1Container>
	);
}
