import axiosInstance from "../axiosInstance";

const CONTROLLER = "distributors";

const get_distributors = async (laboratoryId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?laboratoryId=${laboratoryId || ""}`
	);
	return response.data;
};

const get_distributors_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_distributors_header_info = async (distributorType) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetDistributorHeader?distributorType=${distributorType}`
	);
	return response.data;
};

const get_distributor_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const get_distributor_of_client = async (id) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetDistributorOfClient/${id}`
	);
	return response.data;
};

const create_distributor = async (distributor) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, distributor, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_distributor = async (id, product) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, product, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_distributor_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateDistributorState/${id}`,
		state
	);
	return response.data;
};

const get_distributors_list_dapper = async (
	term,
	skip,
	take,
	state,
	distributorType
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetDistributorsList`,
		{
			params: {
				searchTerm: term,
				skip: skip,
				take: take,
				state: state,
				distributorType: distributorType,
			},
		}
	);
	return response.data;
};

const get_market_distributors = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarketDistributors`
	);
	return response.data;
};

const get_expo_distributors = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetExpoDistributors`);
	return response.data;
};

const get_distributors_name_list = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetDistributorNameList`
	);
	return response.data;
};

const get_distributor_response_file = async (
	distributorId,
	laboratoryId,
	dateToSearch
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetResponseFile?distributorId=${distributorId}&laboratoryId=${laboratoryId}&dateToSearch=${dateToSearch}`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const import_distributor_response_file = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ImportDistributorResponseFile`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const get_response_history = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetResponseHistory`);
	return response.data;
};

const get_response_history_detail = async (id) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetResponseHistoryDetail/${id}`
	);
	return response.data;
};

const get_distributors_name_list_with_orders = async (
	distributorId,
	laboratoryId
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetDistributorNameListWithOrders?distributorId=${
			distributorId || ""
		}&laboratoryId=${laboratoryId || ""}`
	);
	return response.data;
};

const get_distributor_response_file_preview = async (
	distributorId,
	laboratoryId,
	dateToSearch
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetDistributorResponseFilePreview`,
		{
			params: {
				distributorId: distributorId,
				laboratoryId: laboratoryId,
				dateToSearch: dateToSearch,
			},
		}
	);
	return response.data;
};

export {
	get_distributors,
	get_distributors_page,
	get_distributors_header_info,
	get_distributor_by_id,
	get_distributor_of_client,
	create_distributor,
	update_distributor,
	update_distributor_state,
	get_distributors_list_dapper,
	get_market_distributors,
	get_distributors_name_list,
	get_distributor_response_file,
	import_distributor_response_file,
	get_response_history,
	get_response_history_detail,
	get_distributors_name_list_with_orders,
	get_distributor_response_file_preview,
	get_expo_distributors,
};
