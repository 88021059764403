import axiosInstance from "../axiosInstance";

const CONTROLLER = "historyApprovals";

const get_history_approval_by_client_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetByClientId/${id}`);
	return response.data;
};

const create_history_approval = async (historyData) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, historyData);
	return response.data;
};

export { get_history_approval_by_client_id, create_history_approval };
