import styled from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../theme";

export const Inner = styled.div`
	padding: 30px 100px;
	background-color: ${COLORS.LightGrey};
	min-height: 65vh;

	@media only screen and (min-width: 600px) and (max-width: 860px) {
	}
`;
export const Header = styled.div`
	margin-bottom: 30px;
`;
export const Breadcrumb = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 5px;
`;

export const ActiveBreadcrumbItem = styled.span`
	font-weight: ${WEIGHT.Bold};
	color: ${COLORS.Primary};
	font-size: ${SIZES.level6};
`;

export const BreadcrumbItem = styled.span`
	font-size: ${SIZES.level7};
`;

export const BreadcrumbSeparator = styled.div`
	font-size: ${SIZES.level8};
	font-weight: ${WEIGHT.Black};
`;
export const Title = styled.div`
	margin-bottom: 5px;
`;
export const SubTitle = styled.div`
	margin: 10px 0 15px 0;
`;
export const Body = styled.div`
	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}
`;
