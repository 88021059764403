import { RightCircleOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import React, { useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import Image from "../../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../../layout/theme";
import { ContainerDiv, Inner, RoundSearch, SearchContainer } from "./styles";

export default function ProductsModal({ products, loading, AddToList }) {
	const [data, setData] = useState(products);

	const productColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			key: "action",
			render: (_, record, index) => (
				<Button
					type="primary"
					ghost
					onClick={() => {
						AddToList(_.id);
					}}
				>
					<RightCircleOutlined />
				</Button>
			),
		},
	];

	return (
		<Inner>
			<SearchContainer>
				<RoundSearch
					allowClear
					placeholder="Buscar productos"
					onChange={(nameSearch) => {
						setData(
							products.filter((item) =>
								item.title
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.toUpperCase()
									.includes(
										nameSearch.target.value
											.normalize("NFD")
											.replace(/[\u0300-\u036f]/g, "")
											.toUpperCase()
									)
							)
						);
					}}
					suffix={
						<Typo type="primary" level={4}>
							<BiSearchAlt2 style={{ position: "relative", bottom: "-3px" }} />
						</Typo>
					}
				/>
			</SearchContainer>
			<ContainerDiv>
				<Table
					showHeader={true}
					columns={productColumns}
					dataSource={data}
					pagination={false}
					loading={loading}
				/>
			</ContainerDiv>
		</Inner>
	);
}
