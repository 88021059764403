import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";
import Image from "../../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../../layout/theme";
import ContractTable from "../contract-table/ContractTable";
import {
	BottomCard,
	Container,
	FileContainer,
	IconContainer,
	Inner,
	LeftCard,
	PreviewImage,
	RightCard,
	Section,
	SectionFiles,
	SubSection,
} from "./styles";
import { FaCalendarAlt, FaHeadset, FaMobileAlt } from "react-icons/fa";
import { update_client_contract_web_seller_route } from "../../../../../../../api/endpoints/clients";
import { MdOutlineLocalOffer } from "react-icons/md";

export default function ContractModal({
	image,
	files,
	contract,
	products,
	clientId,
	webSellerFilteredRouteIsActive,
	setWebSellerFilteredRouteIsActive,
	setFlag,
}) {
	// const [specialProducts, setSpecialProducts] = useState(null);

	// useEffect(() => {
	// 	setSpecialProducts(
	// 		products
	// 			.filter((p) => p.saleCommision !== contract.salesCommision)
	// 			.map((p) => ({ ...p, key: p.id }))
	// 	);
	// }, []);

	const handleDownloadImage = (file, index) => {
		const link = document.createElement("a");
		link.href = file.url;
		link.setAttribute("download", `contractImage_${index}.png`);
		document.body.append(link);
		link.click();
	};

	return (
		<>
			<Inner>
				<LeftCard>
					<Section>
						{image && <PreviewImage src={image} alt="avatar" />}
					</Section>
					<Section>
						<Typo type="primary" level={6}>
							Datos de la cuenta
						</Typo>
						<Container>
							<SubSection>
								<Typo type="primary" level={7}>
									Representante:
								</Typo>
								<Typo type="defaul" level={7}>
									{contract.firstName} {contract.lastName}
								</Typo>
							</SubSection>
							<SubSection>
								<Typo type="primary" level={7}>
									Correo electrónico:
								</Typo>
								<Typo type="defaul" level={7}>
									{contract.email}
								</Typo>
							</SubSection>
						</Container>
						<Typo type="primary" level={6}>
							Acuerdo Comercial
						</Typo>
						<Container>
							<SubSection>
								<Typo type="primary" level={7}>
									Comisión por venta:
								</Typo>
								<Typo type="defaul" level={7}>
									{contract.salesCommision} %
								</Typo>
							</SubSection>
							<SubSection>
								<Typo type="primary" level={7}>
									Comisión por venta App Mobile:
								</Typo>
								<Typo type="defaul" level={7}>
									{contract.mobileAppCommision} %
								</Typo>
							</SubSection>
							{/* <SubSection>
								<Typo type="primary" level={7}>
									Estado App Mobile
								</Typo>
								<Checkbox checked={contract.activeMobileApp}></Checkbox>
							</SubSection> */}
						</Container>
					</Section>
				</LeftCard>
				<RightCard>
					<Typo type="primary" level={6}>
						Imágenes del contrato
					</Typo>
					<SectionFiles>
						{files &&
							files.map((f, idx) => (
								<FileContainer>
									<Image
										preview={true}
										src={f.url}
										alt={f.name}
										width="120px"
										height="120px"
									/>
									<Typo
										onClick={() => handleDownloadImage(f, idx)}
										type="muted"
										style={{ cursor: "pointer" }}
									>
										<FiDownload /> Descargar
									</Typo>
								</FileContainer>
							))}
					</SectionFiles>
				</RightCard>
			</Inner>
			{contract &&
				(contract.activeMobileApp ||
					contract.activeWebSeller ||
					contract.activeExpoSection ||
					contract.activeCouponSection) && (
					<BottomCard>
						{/* <ContractTable data={specialProducts} /> */}
						<Typo type="primary" level={6}>
							Aplicaciones
						</Typo>
						<IconContainer>
							{contract.activeMobileApp && (
								<Container
									style={{
										alignItems: "center",
									}}
								>
									<FaMobileAlt size={50} style={{ color: COLORS.Primary }} />
									<Typo type="primary" level={7}>
										Mobile
									</Typo>
								</Container>
							)}
							{contract.activeWebSeller && (
								<Container
									style={{
										alignItems: "center",
									}}
								>
									<FaHeadset size={50} style={{ color: COLORS.Primary }} />
									<Typo type="primary" level={7}>
										Vendedores Web
									</Typo>
									<Checkbox
										onChange={(e) => {
											setWebSellerFilteredRouteIsActive(e.target.checked);
											update_client_contract_web_seller_route(contract.id, {
												id: contract.id,
												clientId: clientId,
												webSellerFilteredRoute: e.target.checked,
											});
											setFlag((prev) => !prev);
										}}
										checked={webSellerFilteredRouteIsActive}
									>
										<Typo type="primary" level={7}>
											Base clientes propios
										</Typo>
									</Checkbox>
								</Container>
							)}
							{contract.activeExpoSection && (
								<Container
									style={{
										alignItems: "center",
									}}
								>
									<FaCalendarAlt size={50} style={{ color: COLORS.Primary }} />
									<Typo type="primary" level={7}>
										Eventos
									</Typo>
								</Container>
							)}
							{contract.activeCouponSection && (
								<Container
									style={{
										alignItems: "center",
									}}
								>
									<MdOutlineLocalOffer
										size={50}
										style={{ color: COLORS.Primary }}
									/>
									<Typo type="primary" level={7}>
										Cupones
									</Typo>
								</Container>
							)}
						</IconContainer>
					</BottomCard>
				)}
		</>
	);
}
