import React from "react";
import { Inner } from "./styles";
import DistributorsSelector from "../../../../../../components/common/distributos-selector/DistributorsSelector";

export default function DistributorModal({
	setDistributors,
	distributors,
	isSeller,
	isOneMarket,
}) {
	return (
		<Inner>
			<DistributorsSelector
				onChange={setDistributors}
				initialValues={distributors}
				noBuyer={isSeller || isOneMarket ? true : false}
			/>
		</Inner>
	);
}
