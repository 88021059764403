import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";
import { Button } from "antd";

export const Inner = styled.div`
	display: flex;
	border-radius: 15px;
	background-color: ${COLORS.White};
	height: 90px;
	width: 100%;
	padding: 10px 10px;
	gap: 20px;
`;
export const ImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: contain;
`;
export const CenterData = styled.div`
	flex: 1 1 60%;
	display: flex;
	border-left: 1px solid ${COLORS.Grey};
	border-right: 1px solid ${COLORS.Grey};
	padding: 10px;
	gap: 20px;
`;

export const RightData = styled.div`
	flex: 1 1 10%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	gap: 40px;
	pointer-events: ${(props) => (props.isOneMarket ? "none" : "auto")};
`;

export const Section = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	gap: 10px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	gap: 10px;
`;

export const OffContainer = styled.div`
	writing-mode: vertical-lr;
	font-size: 11px;
	transform: rotateZ(180deg);
	color: ${COLORS.DarkGrey};
	margin-right: -8px;
`;

export const CartButtomNumber = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	& input {
		background: transparent !important;
		color: ${COLORS.Grey} !important;
		border: none !important;
		height: 100% !important;
		outline: none !important;
		box-shadow: none !important;
		text-align: center !important;
		font-size: ${SIZES.level4} !important;
		padding: 0 10px;
		width: 60px;
		position: absolute;
		top: -45px;
	}
`;

export const Loader = styled.div`
	position: absolute;
	top: 0px;
	transform: translate(10px, -20px) scale(2.5);
	background-color: transparent;
	z-index: 999;
`;

export const ItemsQty = styled.div`
	position: absolute;
	color: ${COLORS.Grey};
	user-select: none;
	margin-bottom: -115px;
`;

export const Line = styled.div`
	text-decoration: line-through rgba(0, 0, 0, 0.2);
	align-items: flex-start;
	align-content: center;
	justify-content: center;
	display: flex;
`;
export const PriceInfoContainer = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: space-around;
`;

export const PriceAndDiscountContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const InputContainer = styled.div`
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	padding: 0 10px 0 10px;
`;

export const CartButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${COLORS.Primary};
	color: ${COLORS.White} !important;
	border: none !important;
	height: 100% !important;
	border-radius: 16px;
	font-size: ${SIZES.level8} !important;
`;

export const CartButtonsContainer = styled.div`
	height: 33px;
	background: ${COLORS.Primary} !important;
	color: ${COLORS.White};
	display: flex;
	align-items: center;
	transition: width 0.2s ease;
	z-index: 3;
	border-radius: 10px;

	& button {
		background: ${COLORS.Primary} !important;
		color: ${COLORS.White} !important;
		border: none !important;
		height: 100% !important;
		font-size: ${SIZES.level6} !important;
		font-weight: ${WEIGHT.Bold} !important;
		width: 100%;
	}

	& input {
		background: ${COLORS.White} !important;
		color: ${COLORS.Primary} !important;
		border: none !important;
		height: 100% !important;
		outline: none !important;
		box-shadow: none !important;
		text-align: center !important;
		font-size: ${SIZES.level6} !important;
		font-weight: ${WEIGHT.Bold} !important;
		padding: 10px;
		width: 100%;
	}
`;

export const SectionOffer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	gap: 5px;
	width: 100%;
	margin-left: 10;
`;
