import { Table, Button } from "antd";
import moment from "moment";
import { calculatePrice } from "../../../../../../../../utils/calculatePrice";
import { numberWithDotAndCommas } from "../../../../../../../../utils/numberWithDotAndComas";
import Image from "../../../../../../../common/image/Image";
import { COLORS } from "../../../../../../../layout/theme";
import {
	ContainerDiv,
	DiscountPer,
	InfoItem,
	ProductDelete,
	TableBody,
	TableTitle,
} from "./styles";

export default function CampaignTable({
	data,
	onDelete,
	onChange,
	campaignOffer,
	loading,
}) {
	const today = moment();

	const columns = [
		{
			title: () => <TableTitle>Producto</TableTitle>,
			dataIndex: "photoUrl",
			key: "photoUrl",
			render: (value, record) => (
				<TableBody>
					<InfoItem>{record.title}</InfoItem>
					<Image src={record.photoUrl} height={80} width={80} />
				</TableBody>
			),
		},
		{
			title: () => <TableTitle>Precio</TableTitle>,
			dataIndex: "price",
			key: "price",
			render: (value, record) => (
				<TableBody>
					<InfoItem>$ {record.price}</InfoItem>
				</TableBody>
			),
		},
		{
			title: () => <TableTitle>Oferta</TableTitle>,
			dataIndex: "offerPrice",
			key: "offerPrice",
			render: (value, record) => (
				<TableBody>
					<InfoItem>$ {record.offerPrice.toFixed(2)}</InfoItem>
				</TableBody>
			),
		},
		{
			title: () => <TableTitle>% Adicional</TableTitle>,
			dataIndex: "campaignOffer",
			key: "campaignOffer",
			render: (quantity, record, index) => (
				<TableBody style={{ flexDirection: "row" }}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignContent: "center",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{campaignOffer > 0 && (
							<>
								<InfoItem style={{ color: COLORS.StrongGreen }}>
									{campaignOffer}%
								</InfoItem>
								<InfoItem style={{ marginLeft: "15px" }}>
									{`$${(
										record.offerPrice -
										(record.offerPrice * campaignOffer) / 100
									).toFixed(2)}`}
								</InfoItem>
							</>
						)}
					</div>
					<DiscountPer quantity={quantity} style={{ marginLeft: "15px" }}>
						{record?.offerPrice !== record?.price
							? `$${(
									record.offerPrice -
									(record.offerPrice * quantity) / 100
							  ).toFixed(0)}`
							: `$${numberWithDotAndCommas(
									calculatePrice(
										record.price,
										record.client.generalDiscount,
										moment(record.offerFrom) <= today &&
											moment(record.offerTo) >= today
											? record.discount
											: 0,
										0
									)
							  )}`}
					</DiscountPer>
				</TableBody>
			),
		},
		{
			title: () => <TableTitle>Acción</TableTitle>,
			key: "action",
			render: (_, record, index) => (
				<TableBody>
					<Button type="link" onClick={() => onDelete(_.id)} loading={loading}>
						<ProductDelete>X</ProductDelete>
					</Button>
				</TableBody>
			),
		},
	];

	return (
		<ContainerDiv>
			<Table
				showHeader={true}
				columns={columns}
				dataSource={data}
				pagination={false}
				rowKey={(record) => record.id}
			/>
		</ContainerDiv>
	);
}
