import styled from "styled-components";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div``;

export const InfoTableContainer = styled.div`
	flex: 1 1 80%;
	height: 600px;
	width: 100%;
	overflow: auto;

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.Grey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}
`;
