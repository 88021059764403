import { Skeleton } from "antd";
import { Block, Container } from "./styles";

export default function SkeletonPublicity() {
	return (
		<Container>
			<Skeleton.Input active size="large" style={{ marginBottom: "10px" }} />
			<Block>
				<Skeleton.Input active size="large" block />
				<Skeleton.Input active size="large" block />
				<Skeleton.Input active size="large" block />
			</Block>
			<Block>
				<Skeleton.Input active size="large" block />
				<Skeleton.Input active size="large" block />
				<Skeleton.Input active size="large" block />
			</Block>
			<Block>
				<Skeleton.Input active size="large" block />
				<Skeleton.Input active size="large" block />
				<Skeleton.Input active size="large" block />
			</Block>
		</Container>
	);
}
