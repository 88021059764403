import { Button, Input, InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { FaCartPlus, FaTrashAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { get_smart_buy } from "../../../../../../api/endpoints/bookofmissings";
import Main from "../../../../../layout/main/Main";
import { COLORS, Typo } from "../../../../../layout/theme";
import RecommendedProduct from "./components/recommended-product/RecommendedProduct";
import {
	CloseCircleOutlined,
	MinusOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import {
	AntdTable,
	BorderPercentage,
	CellContainer,
	Container,
	FloatingItem,
	FloatingTextContainer,
	FlowtingButton,
	FlowtingContainer,
	GridSeparator,
	HeadersContainer,
	Inner,
	NoDataContainer,
	NonPercentage,
	QuantityContainer,
	QuantityContainerBottom,
	QuantityContainerTop,
	QuantityItem,
	RadioContainer,
	RelatedItem,
	SelectedItem,
	SmartBody,
	SmartMain,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import { useBook } from "../../../../../../contexts/bookContext";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { MdProductionQuantityLimits } from "react-icons/md";
import { TbArrowBack } from "react-icons/tb";

const { confirm } = Modal;

export default function SmartBuy() {
	const [prods, setProds] = useState(null);
	const { sendProdsToCart, delete_from_smart_buy } = useBook();
	const navigate = useNavigate();
	const fetch_smart_buy = async () => {
		const res = await get_smart_buy();
		setProds(
			res.map((e) => ({
				bestOffers: e.bestOffers.map((j, i) => ({
					...j,
					selected: i === 0 ? true : false,
					quantity: 1,
					id: e.bestOffers[0].id,
				})),
			}))
		);
	};

	useEffect(() => {
		fetch_smart_buy();
	}, []);

	const onFinish = () => {
		confirm({
			title: "¿Seguro que quiere agregar estos productos al carrito?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La acción se aplicará de inmediato",
			okText: "Sí",
			okType: "primary",
			cancelText: "No",
			onOk: () => {
				sendToCart();
			},
		});
	};

	const sendToCart = async () => {
		const postData = prods
			.map((e) => e.bestOffers.filter((o) => o.selected))
			.map((o) => o[0])
			.map((o) => ({
				id: o.prodId,
				quantity: o.quantity,
				paused: o.paused,
				draft: o.draft,
				deleted: o.deleted,
			}));

		const res = await sendProdsToCart(postData);
		openNotificationWithIcon(
			TYPE.SUCCESS,
			"Productos agregados al carrito con exito",
			"Los productos se agregaron al carrito con exito"
		);
		if (res) navigate("/cart");
	};

	const onRadioChange = (e, f, c) => {
		let _prods = prods.map((o, idx) => ({
			bestOffers: o.bestOffers.map((k, i) => ({
				...k,
				selected:
					idx === f && i === c
						? true
						: idx === f && i !== c
						? false
						: idx !== f && k.selected,
			})),
		}));

		setProds(_prods);
	};

	const handleDecrement = (f) => {
		let _prods = [...prods];
		_prods[f] = {
			bestOffers: _prods[f].bestOffers.map((q, i) => ({
				...q,
				quantity: q.quantity === 1 ? 1 : q.quantity - 1,
			})),
		};
		setProds(_prods);
	};

	const handleIncrement = (f) => {
		let _prods = [...prods];
		_prods[f] = {
			bestOffers: _prods[f].bestOffers.map((q, i) => ({
				...q,
				quantity: q.quantity + 1,
			})),
		};
		setProds(_prods);
	};

	const handleChange = (e, f) => {
		let _prods = [...prods];
		_prods[f] = {
			bestOffers: _prods[f].bestOffers.map((q, i) => ({ ...q, quantity: e })),
		};
		setProds(_prods);
	};

	const handleDelete = (item, idx) => {
		confirm({
			title: "¿Seguro que quiere eliminar el producto del libro?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La eliminación del producto se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(item, idx);
			},
		});
	};

	const handleAction = async (item, idx) => {
		try {
			await delete_from_smart_buy(item.bestOffers.find((e) => e.selected).id);
			let _prods = [...prods];
			_prods.splice(idx, 1);
			setProds(_prods);
		} catch (err) {
			console.log(err);
		} finally {
		}
	};

	const columns = [
		{
			render: (values, a, i) => (
				<QuantityContainer>
					<QuantityContainerTop
						style={{ display: "flex", gap: "5px", width: "100%" }}
					>
						<Button
							onClick={() => handleDecrement(i)}
							shape="circle"
							type="muted"
						>
							<MinusOutlined style={{ color: `${COLORS.Secondary}` }} />
						</Button>
						<InputNumber
							type="number"
							value={prods[i].bestOffers[0].quantity}
							onChange={(e) => handleChange(e, i)}
							controls={false}
							style={{ width: "46px", color: COLORS.Secondary }}
							min={1}
						/>
						<Button
							onClick={() => handleIncrement(i)}
							shape="circle"
							type="muted"
						>
							<PlusOutlined style={{ color: `${COLORS.Secondary}` }} />
						</Button>
					</QuantityContainerTop>
					<QuantityContainerBottom onClick={(e) => handleDelete(values, i)}>
						<Typo type="muted" level={5} hover="danger">
							<FaTrashAlt />
						</Typo>
					</QuantityContainerBottom>
				</QuantityContainer>
			),
			width: "20px",
		},
		{
			dataIndex: "bestOffers",
			render(fila, a, i) {
				return {
					props: {
						style: { background: "hsl(0, 0%, 97%)" },
					},
					children: (
						<>
							<CellContainer>
								<RadioContainer>
									<Input
										type="radio"
										onChange={(e) => onRadioChange(e, i, 0)}
										name={`k__${i}`}
										defaultChecked={true}
										checked={fila[0]?.selected}
									/>
								</RadioContainer>
								<RecommendedProduct
									nombre={fila[0]?.title}
									price={fila[0]?.price}
									fila={i}
									index={0}
									prodPhoto={fila[0]?.photoUrl}
									percentageWithout={fila[0]?.totalDiscountWhitoutTrigger}
									percentageWith={fila[0]?.totalDiscount}
									laboratory={fila[0]?.clientName}
									labType={fila[0]?.clientType}
									labGeneralDiscount={fila[0]?.clientGeneralDiscount}
									labTier={fila[0]?.clientTier}
									labIsApproved={fila[0]?.clientIsApproved}
									labPhoto={fila[0]?.clientPhoto}
									presentation={fila[0]?.presentation}
									unit={fila[0]?.unit}
									presentationQty={fila[0]?.presentationQty}
									prods={prods}
									setProds={setProds}
									triggerQty={fila[0]?.triggerDiscountQty}
									prodDiscount={fila[0]?.discount}
									triggerDiscount={fila[0]?.triggerDiscount}
								/>
							</CellContainer>
						</>
					),
				};
			},
			width: "20%",
		},
		{
			children: [
				{
					dataIndex: "bestOffers",
					render: (fila, record, i) => (
						<>
							<CellContainer>
								<RadioContainer>
									<Input
										type="radio"
										onChange={(e) => onRadioChange(e, i, 1)}
										disabled={!fila[1] ? true : false}
										name={`k__${i}`}
										width="100px"
										style={{ display: !fila[1] && "none" }}
										checked={fila[1]?.selected}
									/>
								</RadioContainer>
								<RecommendedProduct
									nombre={fila[1]?.title}
									price={fila[1]?.price}
									index={1}
									fila={i}
									prodPhoto={fila[1]?.photoUrl}
									percentageWithout={fila[1]?.totalDiscountWhitoutTrigger}
									percentageWith={fila[1]?.totalDiscount}
									laboratory={fila[1]?.clientName}
									labType={fila[1]?.clientType}
									labGeneralDiscount={fila[1]?.clientGeneralDiscount}
									labTier={fila[1]?.clientTier}
									labIsApproved={fila[1]?.clientIsApproved}
									labPhoto={fila[1]?.clientPhoto}
									presentation={fila[1]?.presentation}
									unit={fila[1]?.unit}
									presentationQty={fila[1]?.presentationQty}
									prods={prods}
									setProds={setProds}
									triggerQty={fila[1]?.triggerDiscountQty}
									prodDiscount={fila[1]?.discount}
									triggerDiscount={fila[1]?.triggerDiscount}
								/>
							</CellContainer>
						</>
					),
					width: "20%",
				},
				{
					dataIndex: "bestOffers",
					render: (fila, record, i) => (
						<>
							<CellContainer>
								<RadioContainer>
									<Input
										type="radio"
										onChange={(e) => onRadioChange(e, i, 2)}
										disabled={!fila[2] ? true : false}
										name={`k__${i}`}
										style={{ display: !fila[2] && "none" }}
										checked={fila[2]?.selected}
									/>
								</RadioContainer>
								<RecommendedProduct
									nombre={fila[2]?.title}
									price={fila[2]?.price}
									index={2}
									fila={i}
									prodPhoto={fila[2]?.photoUrl}
									percentageWithout={fila[2]?.totalDiscountWhitoutTrigger}
									percentageWith={fila[2]?.totalDiscount}
									laboratory={fila[2]?.clientName}
									labType={fila[2]?.clientType}
									labGeneralDiscount={fila[2]?.clientGeneralDiscount}
									labTier={fila[2]?.clientTier}
									labIsApproved={fila[2]?.clientIsApproved}
									labPhoto={fila[2]?.clientPhoto}
									presentation={fila[2]?.presentation}
									unit={fila[2]?.unit}
									presentationQty={fila[2]?.presentationQty}
									prods={prods}
									setProds={setProds}
									triggerQty={fila[2]?.triggerDiscountQty}
									prodDiscount={fila[2]?.discount}
									triggerDiscount={fila[2]?.triggerDiscount}
								/>
							</CellContainer>
						</>
					),
					width: "20%",
				},
				{
					dataIndex: "bestOffers",
					render: (fila, record, i) => (
						<CellContainer>
							<RadioContainer>
								<Input
									type="radio"
									onChange={(e) => onRadioChange(e, i, 3)}
									disabled={!fila[3] ? true : false}
									name={`k__${i}`}
									style={{ display: !fila[3] && "none" }}
									checked={fila[3]?.selected}
								/>
							</RadioContainer>
							<RecommendedProduct
								nombre={fila[3]?.title}
								index={3}
								price={fila[3]?.price}
								fila={i}
								prodPhoto={fila[3]?.photoUrl}
								percentageWithout={fila[3]?.totalDiscountWhitoutTrigger}
								percentageWith={fila[3]?.totalDiscount}
								laboratory={fila[3]?.clientName}
								labType={fila[3]?.clientType}
								labGeneralDiscount={fila[3]?.clientGeneralDiscount}
								labTier={fila[3]?.clientTier}
								labIsApproved={fila[3]?.clientIsApproved}
								labPhoto={fila[3]?.clientPhoto}
								presentation={fila[3]?.presentation}
								unit={fila[3]?.unit}
								presentationQty={fila[3]?.presentationQty}
								prods={prods}
								setProds={setProds}
								triggerQty={fila[3]?.triggerDiscountQty}
								prodDiscount={fila[3]?.discount}
								triggerDiscount={fila[3]?.triggerDiscount}
							/>
						</CellContainer>
					),
					width: "20%",
				},
				{
					dataIndex: "bestOffers",
					render: (fila, record, i) => (
						<CellContainer>
							<RadioContainer>
								<Input
									type="radio"
									onChange={(e) => onRadioChange(e, i, 4)}
									disabled={!fila[4] ? true : false}
									name={`k__${i}`}
									style={{ display: !fila[4] && "none" }}
									checked={fila[4]?.selected}
								/>
							</RadioContainer>
							<RecommendedProduct
								nombre={fila[4]?.title}
								price={fila[4]?.price}
								index={4}
								fila={i}
								prodPhoto={fila[4]?.photoUrl}
								percentageWithout={fila[4]?.totalDiscountWhitoutTrigger}
								percentageWith={fila[4]?.totalDiscount}
								laboratory={fila[4]?.clientName}
								labType={fila[4]?.clientType}
								labGeneralDiscount={fila[4]?.clientGeneralDiscount}
								labTier={fila[4]?.clientTier}
								labIsApproved={fila[4]?.clientIsApproved}
								labPhoto={fila[4]?.clientPhoto}
								presentation={fila[4]?.presentation}
								unit={fila[4]?.unit}
								presentationQty={fila[4]?.presentationQty}
								triggerQty={fila[4]?.triggerDiscountQty}
								prods={prods}
								setProds={setProds}
								prodDiscount={fila[4]?.discount}
								triggerDiscount={fila[4]?.triggerDiscount}
							/>
						</CellContainer>
					),
					width: "20%",
				},
			],
		},
	];

	if (!prods) return <LoadingSkeleton />;
	return (
		<SmartMain>
			<Main.Breadcrumb style={{ marginLeft: "20px" }}>
				<Main.BreadcrumbItem>
					<Link to="/">Home</Link>
				</Main.BreadcrumbItem>
				<Main.BreadcrumbSeparator />
				<Main.BreadcrumbItem>
					<Link to="/book">Libro de Faltantes</Link>
				</Main.BreadcrumbItem>
				<Main.BreadcrumbSeparator />
				<Main.ActiveBreadcrumbItem>
					Compra Inteligente
				</Main.ActiveBreadcrumbItem>
			</Main.Breadcrumb>
			<SmartBody>
				<Inner>
					<Container>
						<HeadersContainer>
							<QuantityItem>
								<Typo type="secondary" level={4}>
									Cantidad
								</Typo>
								<GridSeparator />
							</QuantityItem>
							<SelectedItem>
								<Typo type="primary" level={4}>
									Productos Seleccionados
								</Typo>
								<GridSeparator
									style={{
										transform: `translateX(${
											prods
												.map((e) => e.bestOffers.filter((o) => o.selected))
												.map((o) => o[0])
												.some((o) => o.clientName === "Drogueria")
												? "8px"
												: "20px"
										})`,
									}}
								/>
							</SelectedItem>
							<RelatedItem>
								<Typo type="primary" level={4} style={{ minWidth: "246px" }}>
									Productos Relacionados
								</Typo>
							</RelatedItem>
							<FloatingItem>
								{prods
									.map((e) => e.bestOffers.filter((o) => o.selected))
									.map((o) => o[0])
									.filter((o) => o.clientType === "PVP").length > 0 && (
									<FloatingTextContainer>
										<Typo type="darkMuted" level={7}>
											%
										</Typo>
										<NonPercentage>
											<Typo type="darkMuted" level={7}>
												PROD FARMA.
											</Typo>
											<BorderPercentage>
												<Typo type="success" level={3}>
													{(
														prods
															.map((e) =>
																e.bestOffers.filter((o) => o.selected)
															)
															.map((o) => o[0])
															.filter((o) => o.clientType === "PVP")
															.map((o) =>
																o.quantity >= o.triggerDiscountQty
																	? o.quantity * o.totalDiscount
																	: o.quantity * o.totalDiscountWhitoutTrigger
															)
															.reduce((a, b) => a + b, 0) /
														prods
															.map((e) =>
																e.bestOffers.filter((o) => o.selected)
															)
															.map((o) => o[0])
															.filter((o) => o.clientType === "PVP")
															.map((o) => o.quantity)
															.reduce((a, b) => a + b, 0)
													).toFixed(2)}
												</Typo>
											</BorderPercentage>
										</NonPercentage>
									</FloatingTextContainer>
								)}
								{prods
									.map((e) => e.bestOffers.filter((o) => o.selected))
									.map((o) => o[0])
									.filter((o) => o.clientType === "PSD").length > 0 && (
									<FloatingTextContainer>
										<Typo type="darkMuted" level={7}>
											%
										</Typo>
										<NonPercentage>
											<Typo type="darkMuted" level={7}>
												PROD CONS.
											</Typo>

											<BorderPercentage
												style={{
													borderRadius: "8px",
													border: `2px solid ${COLORS.Default}`,
													padding: "0px 10px",
													width: "83px",
													display: "flex",
													justifyContent: "center",
												}}
											>
												<Typo type="secondary" level={3}>
													{(
														prods
															.map((e) =>
																e.bestOffers.filter((o) => o.selected)
															)
															.map((o) => o[0])
															.filter((o) => o.clientType === "PSD")
															.map((o) =>
																o.quantity >= o.triggerDiscountQty
																	? o.quantity * o.totalDiscount
																	: o.quantity * o.totalDiscountWhitoutTrigger
															)
															.reduce((a, b) => a + b, 0) /
														prods
															.map((e) =>
																e.bestOffers.filter((o) => o.selected)
															)
															.map((o) => o[0])
															.filter((o) => o.clientType === "PSD")
															.map((o) => o.quantity)
															.reduce((a, b) => a + b, 0)
													).toFixed(2)}
												</Typo>
											</BorderPercentage>
										</NonPercentage>
									</FloatingTextContainer>
								)}
							</FloatingItem>
						</HeadersContainer>
						<AntdTable
							dataSource={prods}
							columns={columns}
							pagination={false}
							bordered
							showHeader={false}
							locale={{
								emptyText: (
									<NoDataContainer>
										<Typo level={1} type="primary">
											<MdProductionQuantityLimits />
										</Typo>
										<Typo level={3} type="muted">
											No posee productos en el libro de faltantes
										</Typo>
										<Link to="/book">
											<Typo
												level={5}
												type="secondary"
												hover="primary"
												style={{ position: "relative" }}
											>
												<TbArrowBack
													style={{ position: "relative", top: "3px" }}
												/>
												Volver al libro de faltantes
											</Typo>
										</Link>
									</NoDataContainer>
								),
							}}
						/>
					</Container>
					<FlowtingContainer onClick={onFinish}>
						<Typo type="primary" level={5}>
							Seleccionar Productos
						</Typo>
						<FlowtingButton>
							<Typo
								type="white"
								level={2}
								style={{ transform: "translate(-1px, 4px)" }}
							>
								<FaCartPlus />
							</Typo>
						</FlowtingButton>
					</FlowtingContainer>
				</Inner>
			</SmartBody>
		</SmartMain>
	);
}
