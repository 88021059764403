import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../../../../layout/theme";

export const ModalContainer = styled.div`
	display: flex;
	width: 100%;
	height: 180px;
	overflow: hidden;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	align-items: flex-start;
	gap: 40px;
`;

export const InfoSection = styled.div``;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: center;
	gap: 10px;
`;

export const InfoTitle = styled.div`
	color: ${COLORS.Secondary};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level6};
`;

export const SelectContainer = styled.div`
	width: 200px;
`;

export const InfoNumber = styled.div`
	color: ${COLORS.Default};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level6};
`;
