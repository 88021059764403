import { Skeleton, Row, Col } from "antd";
import { Inner } from "./styles";

export default function LoadingHeader() {
	return (
		<Inner>
			<Row>
				<Col span={6}>
					<Skeleton.Input active size="default" style={{ width: 250 }} />
				</Col>
				<Col span={4}>
					<Skeleton.Input
						active
						size="default"
						style={{ width: 100, marginLeft: -50 }}
					/>
				</Col>
				<Col span={4}>
					<Skeleton.Input
						active
						size="default"
						style={{ width: 100, marginLeft: -110 }}
					/>
				</Col>
			</Row>
		</Inner>
	);
}
