import React from "react";
import {
  Main,
  Inner,
  Header,
  HeaderLogo,
  HeaderIcon,
  HeaderIconGroup,
  HeaderMenuIcon,
  HeaderMenuIconContainer,
  HeaderLinks,
  HeaderSearch,
  HeaderRight,
  HeaderMenuParagraph,
  HeaderAction,
  HeaderActionParagraph,
  HeaderUserIcon,
  HeaderUserIconContainer,
  HeaderUserParagraph,
  HeaderSpan,
  LayoutHeader,
  LayoutHeaderOptions,
  WhatsAppButton,
} from "./styles";

const Layout = ({ children, ...restProps }) => (
  <Inner {...restProps}>{children}</Inner>
);

export default Layout;

Layout.LayoutHeader = ({ children, ...restProps }) => {
  return <LayoutHeader {...restProps}>{children}</LayoutHeader>;
};

Layout.LayoutHeaderOptions = ({ children, ...restProps }) => {
  return <LayoutHeaderOptions {...restProps}>{children}</LayoutHeaderOptions>;
};

Layout.Header = ({ children, ...restProps }) => {
  return <Header {...restProps}>{children}</Header>;
};

Layout.HeaderLogo = ({ children, ...restProps }) => {
  return <HeaderLogo {...restProps}>{children}</HeaderLogo>;
};

Layout.HeaderIconGroup = ({ children, ...restProps }) => {
  return <HeaderIconGroup {...restProps}>{children}</HeaderIconGroup>;
};

Layout.HeaderIcon = ({ children, ...restProps }) => {
  return <HeaderIcon {...restProps}>{children}</HeaderIcon>;
};

Layout.HeaderMenuIcon = ({ children, ...restProps }) => {
  return <HeaderMenuIcon {...restProps}>{children}</HeaderMenuIcon>;
};

Layout.HeaderMenuIconContainer = ({ children, ...restProps }) => {
  return (
    <HeaderMenuIconContainer {...restProps}>{children}</HeaderMenuIconContainer>
  );
};

Layout.HeaderLinks = ({ children, ...restProps }) => {
  return <HeaderLinks {...restProps}>{children}</HeaderLinks>;
};

Layout.HeaderSearch = ({ children, ...restProps }) => {
  return <HeaderSearch {...restProps}>{children}</HeaderSearch>;
};

Layout.HeaderRight = ({ children, ...restProps }) => {
  return <HeaderRight {...restProps}>{children}</HeaderRight>;
};

Layout.HeaderAction = ({ children, ...restProps }) => {
  return <HeaderAction {...restProps}>{children}</HeaderAction>;
};

Layout.HeaderActionParagraph = ({ children, ...restProps }) => {
  return (
    <HeaderActionParagraph {...restProps}>{children}</HeaderActionParagraph>
  );
};

Layout.HeaderMenuParagraph = ({ children, ...restProps }) => {
  return <HeaderMenuParagraph {...restProps}>{children}</HeaderMenuParagraph>;
};

Layout.HeaderSpan = ({ children, ...restProps }) => {
  return <HeaderSpan {...restProps}>{children}</HeaderSpan>;
};

Layout.Main = ({ children, ...restProps }) => {
  return <Main {...restProps}>{children}</Main>;
};

Layout.HeaderUserIcon = ({ children, ...restProps }) => {
  return <HeaderUserIcon {...restProps}>{children}</HeaderUserIcon>;
};

Layout.HeaderUserIconContainer = ({ children, ...restProps }) => {
  return (
    <HeaderUserIconContainer {...restProps}>{children}</HeaderUserIconContainer>
  );
};

Layout.HeaderUserParagraph = ({ children, ...restProps }) => {
  return <HeaderUserParagraph {...restProps}>{children}</HeaderUserParagraph>;
};

Layout.WhatsAppButton = ({ children, ...restProps }) => {
  return <WhatsAppButton {...restProps}>{children}</WhatsAppButton>;
};
