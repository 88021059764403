import styled from "styled-components/macro";
import { Checkbox } from "antd";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";
import TextArea from "antd/lib/input/TextArea";

export const Inner = styled.div`
	display: flex;
	gap: 40px;
	flex-direction: column;

	@media only screen and (min-width: 760px) and (max-width: 1080px) {
		flex-direction: column;
	}
`;

export const InnerForm = styled.div`
	display: flex;
	flex-direction: column;
`;

export const AntdCheckbox = styled(Checkbox)`
	pointer-events: none;
`;

export const OrderData = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const LineBreak = styled.hr`
	width: 100%;
	border: none;
	margin-top: 0px;
	box-shadow: 0 1px 1px #bfbfbf;
	height: 1px;
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
`;

export const SubTitleContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;

export const InfoContainer = styled.div`
	display: flex;
	gap: 5px;

	.ant-form-item {
		margin-bottom: 0px;
	}
`;

export const InfoText = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	color: ${COLORS.Grey};
	font-size: ${SIZES.level6};
	font-weight: ${WEIGHT.Bold};
`;

export const Container = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 10px;
	height: 30px;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const PriceContainer = styled.div`
	display: flex;
	background-color: ${COLORS.White};
	border-radius: 10px;
	padding: 1rem;
	justify-content: space-evenly;
`;

export const OptionsButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	gap: 100px;
	margin-top: 50px;
`;

export const InfoIva = styled.div`
	font-size: 10px;
	align-items: center;
	align-content: center;
	justify-content: center;
	width: 100%;
	text-align: center;
	color: ${COLORS.Default};
`;

export const LeftSection = styled.div`
	flex: 1 1 70%;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const RightSection = styled.div`
	display: flex;
	gap: 50px;
	padding: 0rem 4rem 0rem 4rem;
`;

export const DistributorInfo = styled.div`
	flex: 1 1 50%;
	display: flex;
	background-color: ${COLORS.White};
	border-radius: 10px;
	padding: 1rem 2rem 1rem 2rem;
	justify-content: space-evenly;
	align-items: center;
	min-height: 50px;
`;

export const StatusInfo = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${COLORS.White};
	border-radius: 10px;
	padding: 1rem;
`;

export const QtyContainer = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 40px;
	width: 100%;
`;

export const SubSection = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
