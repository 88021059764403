import React, { useEffect, useState } from "react";
import Main from "../../../../layout/main/Main";
import { Link } from "react-router-dom";
import { ButtonContainer, ChildrenContainer, AntdButton } from "./styles";
import { get_clients_name_list } from "../../../../../api/endpoints/clients";
import AsignMenu from "./asign-menu/AsignMenu";
import { CONSTANTS } from "../../../../../utils/constants";

export default function AsignPage() {
	const [filter, setFilter] = useState(
		CONSTANTS.DISTRIBUTORS_FILTER_WITHOUTCONTRACT
	);
	const [laboratoriesWithContract, setLaboratoriesWithContract] =
		useState(null);
	const [laboratoriesWithoutContract, setLaboratoriesWithoutContract] =
		useState(null);
	const [selectedClient, setSelectedClient] = useState(null);

	const get_filter = () => {
		setFilter(
			localStorage.getItem("asignDistributorsIndexFilter")
				? localStorage.getItem("asignDistributorsIndexFilter")
				: CONSTANTS.DISTRIBUTORS_FILTER_WITHOUTCONTRACT
		);
	};

	useEffect(() => {
		get_filter();
	}, []);

	useEffect(() => {
		const fetch_market_client_list = async () => {
			const res = await get_clients_name_list();
			setLaboratoriesWithoutContract(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
					.filter((l) => !l.isApproved)
			);
			setLaboratoriesWithContract(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
					.filter((l) => l.isApproved)
			);
		};

		fetch_market_client_list();
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/admin/distributors">Droguerías</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Asignar droguerías
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<ButtonContainer>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600" }}
						width={220}
						height={48}
						fontSize={12}
						onClick={() =>
							setFilter(CONSTANTS.DISTRIBUTORS_FILTER_WITHOUTCONTRACT)
						}
						$active={filter === CONSTANTS.DISTRIBUTORS_FILTER_WITHOUTCONTRACT}
					>
						Clientes sin contrato
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={220}
						height={48}
						fontSize={12}
						onClick={() =>
							setFilter(CONSTANTS.DISTRIBUTORS_FILTER_WITHCONTRACT)
						}
						$active={filter === CONSTANTS.DISTRIBUTORS_FILTER_WITHCONTRACT}
					>
						Clientes con contrato
					</AntdButton>
				</ButtonContainer>
				<ChildrenContainer>
					{filter === CONSTANTS.DISTRIBUTORS_FILTER_WITHOUTCONTRACT &&
						laboratoriesWithContract && (
							<AsignMenu
								clients={laboratoriesWithoutContract}
								selectedClient={selectedClient}
								setSelectedClient={setSelectedClient}
								clientType={filter}
							/>
						)}
					{filter === CONSTANTS.DISTRIBUTORS_FILTER_WITHCONTRACT &&
						laboratoriesWithoutContract && (
							<AsignMenu
								clients={laboratoriesWithContract}
								selectedClient={selectedClient}
								setSelectedClient={setSelectedClient}
								clientType={filter}
							/>
						)}
				</ChildrenContainer>
			</Main.Body>
		</Main>
	);
}
