import { Skeleton, Row, Col, Space } from "antd";
import { Inner } from "./styles";

const GUTTER = 10;

export default function LoadingHeader() {
	return (
		<Inner>
			<Row gutter={GUTTER}>
				<Col span={6}>
					<Skeleton.Input active size="default" block />
				</Col>
				<Col span={4}>
					<Skeleton.Input active size="default" block />
				</Col>
				<Col span={4}>
					<Skeleton.Input active size="default" block />
				</Col>
				<Col span={10}></Col>
			</Row>
		</Inner>
	);
}
