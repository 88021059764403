import React, { useState } from "react";
import Image from "../../common/image/Image";
import PublicitySlide from "../../common/slides/publicity-slide/PublicitySlide";
import HotSaleModalFooter from "../../pages/common/hot-sale/components/hot-sale-modal-footer/HotSaleModalFooter";
import HotSaleModal from "../../pages/common/hot-sale/components/hot-sale-modal/HotSaleModal";
import { AntdModal } from "./styles/style";

export default function HotSaleSlideContainer({
	data,
	bannerHeight,
	bannerBorder = 0,
	bannerFit = "cover",
}) {
	const [showHotSaleModal, setShowHotSaleModal] = useState(false);

	const handleClick = () => {
		if (data.products) setShowHotSaleModal(true);
	};

	const handleOk = () => {
		setShowHotSaleModal(false);
	};

	const handleCancel = () => {
		setShowHotSaleModal(false);
	};

	return (
		<>
			<PublicitySlide onClick={handleClick} bannerHeight={bannerHeight}>
				<PublicitySlide.TopContainer bannerHeight={bannerHeight}>
					<Image
						src={data?.src}
						height="100%"
						width="100%"
						style={{
							objectFit: bannerFit,
							borderRadius: bannerBorder,
						}}
					/>
				</PublicitySlide.TopContainer>
			</PublicitySlide>
			<AntdModal
				title={`Campaña ${data.title}`}
				visible={showHotSaleModal}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1200}
				centered={true}
				footer={
					<HotSaleModalFooter
						campaignTitle={data.title}
						campaignRegion={data.campaignRegion}
						campaignDistributors={data.distributors}
					/>
				}
				destroyOnClose={true}
			>
				<HotSaleModal data={data} />
			</AntdModal>
		</>
	);
}
