import React from "react";
import {
	Body,
	Inner,
	NavLink,
	Carousel,
	AntdCarousel,
	Top,
	Main,
	AntdButton,
	Button,
	Title,
	Desc,
	Logo,
	Formulario,
	AntdFormItem,
	ButtonsOnLeft,
	ButtonsOnRight,
	ButtonsContainer,
	Space,
	Break,
	RightArrow,
	SubTitle,
	TermsSection,
} from "./styles";

const SignInTemplate = ({ children, ...restProps }) => (
	<Inner {...restProps}>{children}</Inner>
);

export default SignInTemplate;

SignInTemplate.Body = ({ children, ...restProps }) => {
	return <Body {...restProps}>{children}</Body>;
};

SignInTemplate.Carousel = ({ children, ...restProps }) => {
	return <Carousel {...restProps}>{children}</Carousel>;
};

SignInTemplate.AntdCarousel = ({ children, ...restProps }) => {
	return <AntdCarousel {...restProps}>{children}</AntdCarousel>;
};

SignInTemplate.Main = ({ children, ...restProps }) => {
	return <Main {...restProps}>{children}</Main>;
};

SignInTemplate.Top = ({ children, ...restProps }) => {
	return <Top {...restProps}>{children}</Top>;
};

SignInTemplate.Logo = ({ children, ...restProps }) => {
	return <Logo {...restProps}>{children}</Logo>;
};

SignInTemplate.Title = ({ children, ...restProps }) => {
	return <Title {...restProps}>{children}</Title>;
};

SignInTemplate.AntdFormItem = ({ children, ...restProps }) => {
	return <AntdFormItem {...restProps}>{children}</AntdFormItem>;
};

SignInTemplate.Desc = ({ children, ...restProps }) => {
	return <Desc {...restProps}>{children}</Desc>;
};

SignInTemplate.NavLink = ({ children, to, ...restProps }) => {
	return (
		<NavLink to={to} {...restProps}>
			{children}
		</NavLink>
	);
};

SignInTemplate.AntdButton = ({ children, ...restProps }) => {
	return <AntdButton {...restProps}>{children}</AntdButton>;
};

SignInTemplate.Button = ({ children, ...restProps }) => {
	return <Button {...restProps}>{children}</Button>;
};

SignInTemplate.Formulario = ({ children, ...restProps }) => {
	return <Formulario {...restProps}>{children}</Formulario>;
};

SignInTemplate.ButtonsOnRight = ({ children, ...restProps }) => {
	return <ButtonsOnRight {...restProps}>{children}</ButtonsOnRight>;
};

SignInTemplate.ButtonsOnLeft = ({ children, ...restProps }) => {
	return <ButtonsOnLeft {...restProps}>{children}</ButtonsOnLeft>;
};

SignInTemplate.ButtonsContainer = ({ children, ...restProps }) => {
	return <ButtonsContainer {...restProps}>{children}</ButtonsContainer>;
};

SignInTemplate.Space = ({ children, ...restProps }) => {
	return <Space {...restProps}>{children}</Space>;
};

SignInTemplate.Break = ({ children, ...restProps }) => {
	return <Break {...restProps}>{children}</Break>;
};

SignInTemplate.RightArrow = ({ children, ...restProps }) => {
	return <RightArrow {...restProps}>{children}</RightArrow>;
};

SignInTemplate.SubTitle = ({ children, ...restProps }) => {
	return <SubTitle {...restProps}>{children}</SubTitle>;
};

SignInTemplate.TermsSection = ({ children, ...restProps }) => {
	return <TermsSection {...restProps}>{children}</TermsSection>;
};
