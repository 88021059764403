import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import {
	get_client_contract,
	update_client_contract,
	update_client_tier,
} from "../../../../../api/endpoints/clients";
import Main from "../../../../layout/main/Main";
import {
	AddSpecialCommisionContainer,
	AntdModal,
	BottomCard,
	ButtonContainer,
	Container,
	Inner,
	LeftCard,
	PreviewImage,
	RightCard,
	Section,
	SectionTitle,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import ImageGallery from "../../../../common/image-gallery/ImageGallery";
import { COLORS, Typo } from "../../../../layout/theme";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { CloseCircleOutlined } from "@ant-design/icons";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import ContractTable from "./components/contract-table/ContractTable";
import Image from "../../../../common/image/Image";
import AddIcon from "../../../../../assets/images/circle-plus-solid.svg";
import ProductsModal from "./components/products-modal/ProductsModal";

let productIndex = null;

export default function ClientContract() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [initialValues, setInitialValues] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [appIsActive, setAppIsActive] = useState(false);
	const [photoGallery, setPhotoGallery] = useState(null);
	const [products, setProducts] = useState(null);
	const [specialProducts, setSpecialProducts] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [discountForUsersIsActive, setDiscountsForUsersIsActive] =
		useState(false);
	const [webIsActive, setWebIsActive] = useState(false);
	const [expoIsActive, setExpoIsActive] = useState(false);
	const [webSellerIsActive, setWebSellerIsActive] = useState(false);
	const [mobileConfigIsActive, setMobileConfigIsActive] = useState(false);
	const [webSellerFilteredRouteIsActive, setWebSellerFilteredRouteIsActive] =
		useState(false);
	const [couponIsActive, setCouponIsActive] = useState(false);

	useEffect(() => {
		const fetch_client_contract = async () => {
			const data = await get_client_contract(id);

			const client = {
				name: data.client.name,
				firstName: data.client.contract ? data.client.contract.firstName : "",
				lastName: data.client.contract ? data.client.contract.lastName : "",
				contactEmail: data.client.contract ? data.client.contract.email : "",
				salesCommision: data.client.contract
					? data.client.contract.salesCommision
					: "",
				mobileAppCommision: data.client.contract
					? data.client.contract.mobileAppCommision
					: "",
				mobileUsersZoneType: data.client.contract
					? data.client.contract.mobileUsersZoneType
					: "REGION",
			};

			setAppIsActive(
				data.client.contract ? data.client.contract.activeMobileApp : false
			);

			setWebIsActive(
				data.client.contract ? data.client.contract.activeClientWeb : false
			);

			setExpoIsActive(
				data.client.contract ? data.client.contract.activeExpoSection : false
			);

			setWebSellerIsActive(
				data.client.contract ? data.client.contract.activeWebSeller : false
			);

			setCouponIsActive(
				data.client.contract ? data.client.contract.activeCouponSection : false
			);

			setMobileConfigIsActive(
				data.client.contract ? data.client.contract.activeMobileConfig : false
			);

			setWebSellerFilteredRouteIsActive(
				data.client.contract
					? data.client.contract.webSellerFilteredRoute
					: false
			);

			setDiscountsForUsersIsActive(
				data.client.contract
					? data.client.contract.activeDiscountsForUsers
					: false
			);

			setImageUrl(data.client.photoUrl);

			setPhotoGallery(
				data.client.contract?.files?.map((f) => ({
					uid: f.id,
					name: f.fileName,
					status: "done",
					url: f.url,
				})) || []
			);

			setProducts(
				data.client.products?.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);

			setSpecialProducts(
				data.client.products
					?.filter(
						(p) => p.saleCommision !== data.client.contract?.salesCommision
					)
					.map((p) => ({ ...p, key: p.id }))
			);

			setInitialValues(client);
		};
		if (id) {
			setLoading(true);
			fetch_client_contract();
			setLoading(false);
		}
	}, []);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleTableChange = (values) => {
		const _temp = specialProducts.map((p, idx) =>
			idx === values.index
				? {
						...p,
						saleCommision: parseInt(values.value),
				  }
				: { ...p }
		);
		setSpecialProducts(_temp);
	};

	const handleAddToSpecialTable = async (e, q) => {
		const _temp = products?.filter((p, idx) => p.id === e);

		productIndex = _temp;
		const auxSpecialProducts = _temp.concat(specialProducts);

		let uniqueArray = auxSpecialProducts.filter((value, index) => {
			const _value = JSON.stringify(value);
			return (
				index ===
				auxSpecialProducts.findIndex((obj) => {
					return JSON.stringify(obj) === _value;
				})
			);
		});

		uniqueArray = uniqueArray.filter(
			(value, index, self) => index === self.findIndex((t) => t.id === value.id)
		);

		setSpecialProducts(uniqueArray);
	};

	const handleDelete = async (id) => {
		const _temp = specialProducts.filter((p) => p.id !== id);

		setSpecialProducts(_temp);
	};

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const contractData = {
				clientId: id,
				firstName: values.firstName,
				lastName: values.lastName,
				contactEmail: values.contactEmail,
				salesCommision: values.salesCommision,
				mobileAppCommision: values.mobileAppCommision,
				activeMobileApp: appIsActive,
				activeClientWeb: appIsActive ? webIsActive : true,
				activeExpoSection: expoIsActive,
				activeWebSeller: webSellerIsActive,
				activeCouponSection: couponIsActive,
				activeMobileConfig: mobileConfigIsActive,
				webSellerFilteredRoute: webSellerFilteredRouteIsActive,
				mobileUsersZoneType: values.mobileUsersZoneType,
				activeDiscountsForUsers: discountForUsersIsActive,
				existingPhotoGallery: photoGallery?.map((i) => i.uid),
				specialProducts:
					specialProducts?.map((p) => ({
						id: p.id,
						saleCommision: p.saleCommision,
					})) || [],
			};

			const formData = new FormData();

			photoGallery.forEach((f) => {
				formData.append("files", f.originFileObj);
			});

			formData.append("data", JSON.stringify(contractData));

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"El contrato del cliente se editó con éxito"
			);

			await update_client_contract(id, formData);

			if (values.salesCommision > 0) {
				const tierData = { id: id, tier: 1 };
				await update_client_tier(tierData);
			}

			navigate(`/admin/clients`);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al editar el contrato del cliente",
				"Un error ocurrio al intentar editar el contrato, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handlePhotoGalleryChange = (values) => {
		setPhotoGallery(values);
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/clients/");
			},
		});
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/clients">Clientes</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Contrato de Cliente
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<Inner>
							<LeftCard>
								<Container>
									<Section style={{ flex: "1 1 15%" }}>
										{imageUrl && <PreviewImage src={imageUrl} alt="avatar" />}
									</Section>
									<Section style={{ flex: "1 1 15%" }}>
										<SectionTitle>Contacto Comercial</SectionTitle>
										<Form.Item
											label={
												<label
													style={{
														color: COLORS.Primary,
														fontWeight: "600",
													}}
												>
													Nombre
												</label>
											}
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras",
												},
											]}
											name="firstName"
										>
											<Input />
										</Form.Item>
										<Form.Item
											label={
												<label
													style={{
														color: COLORS.Primary,
														fontWeight: "600",
													}}
												>
													Apellido
												</label>
											}
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras",
												},
											]}
											name="lastName"
										>
											<Input />
										</Form.Item>
										<Form.Item
											label={
												<label
													style={{
														color: COLORS.Primary,
														fontWeight: "600",
													}}
												>
													Correo electrónico
												</label>
											}
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
											name="contactEmail"
										>
											<Input />
										</Form.Item>
									</Section>
									<Section style={{ flex: "1 1 30%" }}>
										<SectionTitle>Acuerdo Comercial</SectionTitle>
										<Container>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Comisión por ventas
													</label>
												}
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
												name="salesCommision"
												style={{ flex: "1 1 50%" }}
											>
												<Input
													suffix={<Typo type="primary">%</Typo>}
													style={{ width: 100 }}
												/>
											</Form.Item>

											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Comisión por AppMobile
													</label>
												}
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
												name="mobileAppCommision"
												style={{ flex: "1 1 50%" }}
											>
												<Input
													suffix={<Typo type="primary">%</Typo>}
													style={{ width: 100 }}
												/>
											</Form.Item>
										</Container>
										<Container>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Estado App Mobile
													</label>
												}
												name="activeMobileApp"
												style={{ flex: "1 1 50%" }}
											>
												<Checkbox
													onChange={(e) => setAppIsActive(e.target.checked)}
													checked={appIsActive}
												></Checkbox>
											</Form.Item>
											{appIsActive && (
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Visible en Web
														</label>
													}
													name="activeClientWeb"
													style={{ flex: "1 1 50%" }}
												>
													<Checkbox
														onChange={(e) => setWebIsActive(e.target.checked)}
														checked={webIsActive}
													></Checkbox>
												</Form.Item>
											)}
										</Container>
										<Container>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Config Mobile
													</label>
												}
												name="activeMobileConfig"
												style={{ flex: "1 1 50%" }}
											>
												<Checkbox
													onChange={(e) =>
														setMobileConfigIsActive(e.target.checked)
													}
													checked={mobileConfigIsActive}
												></Checkbox>
											</Form.Item>
										</Container>
										<Container>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Zona vendedores Mobile
													</label>
												}
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
												name="mobileUsersZoneType"
												style={{ flex: "1 1 50%" }}
											>
												<Select style={{ width: 100 }}>
													<Select.Option key={1} value="REGION">
														Region
													</Select.Option>
													<Select.Option key={2} value="ROUTE">
														Ruta
													</Select.Option>
												</Select>
											</Form.Item>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Descuentos por usuarios
													</label>
												}
												name="activeDiscountsForUsers"
												style={{ flex: "1 1 50%" }}
											>
												<Checkbox
													onChange={(e) =>
														setDiscountsForUsersIsActive(e.target.checked)
													}
													checked={discountForUsersIsActive}
												></Checkbox>
											</Form.Item>
										</Container>
										<Container>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Vendedor Web
													</label>
												}
												name="activeWebSeller"
												style={{ flex: "1 1 50%" }}
											>
												<Checkbox
													onChange={(e) =>
														setWebSellerIsActive(e.target.checked)
													}
													checked={webSellerIsActive}
												></Checkbox>
											</Form.Item>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Base clientes propios
													</label>
												}
												name="webSellerFilteredRoute"
												style={{ flex: "1 1 50%" }}
											>
												<Checkbox
													onChange={(e) =>
														setWebSellerFilteredRouteIsActive(e.target.checked)
													}
													checked={webSellerFilteredRouteIsActive}
												></Checkbox>
											</Form.Item>
										</Container>
										<Container>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Seccion Expo
													</label>
												}
												name="activeExpoSection"
												style={{ flex: "1 1 50%" }}
											>
												<Checkbox
													onChange={(e) => setExpoIsActive(e.target.checked)}
													checked={expoIsActive}
												></Checkbox>
											</Form.Item>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Seccion Cupones
													</label>
												}
												name="activeCouponSection"
												style={{ flex: "1 1 50%" }}
											>
												<Checkbox
													onChange={(e) => setCouponIsActive(e.target.checked)}
													checked={couponIsActive}
												></Checkbox>
											</Form.Item>
										</Container>
									</Section>
									<AntdModal
										title={
											<Typo type="primary" level={6}>
												Productos de {initialValues?.name}
											</Typo>
										}
										visible={isModalVisible}
										width={600}
										onOk={handleOk}
										onCancel={handleCancel}
										destroyOnClose={true}
									>
										<ProductsModal
											AddToList={handleAddToSpecialTable}
											products={products}
											loading={loading}
										/>
									</AntdModal>
								</Container>
								<AddSpecialCommisionContainer onClick={showModal}>
									<Image
										src={AddIcon}
										alt="Menu"
										style={{
											width: "30px",
										}}
									/>
									<Typo type="primary">
										Agregar producto con comisión especial
									</Typo>
								</AddSpecialCommisionContainer>
							</LeftCard>
							<RightCard>
								<SectionTitle type="primary" level={5}>
									Imágenes del contrato
								</SectionTitle>
								<ImageGallery
									data={photoGallery}
									onChange={handlePhotoGalleryChange}
								/>
							</RightCard>
						</Inner>
						{specialProducts && specialProducts?.length > 0 && (
							<BottomCard>
								<Typo type="primary" level={6}>
									Productos con comisión especial
								</Typo>
								<ContractTable
									data={specialProducts}
									onDelete={handleDelete}
									onChange={handleTableChange}
								/>
							</BottomCard>
						)}
						<ButtonContainer>
							<Form.Item>
								<Button type="primary" htmlType="submit" loading={submitting}>
									Guardar Contrato
								</Button>
							</Form.Item>
							<SignInTemplate.AntdButton
								type="Primary"
								loading={submitting}
								onClick={handleBack}
							>
								<Typo type="danger" level={6}>
									Volver sin guardar
								</Typo>
							</SignInTemplate.AntdButton>
						</ButtonContainer>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
