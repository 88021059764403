import styled from "styled-components/macro";
import { COLORS } from "../../../../layout/theme";
import { Upload } from "antd";

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;
export const ProductData = styled.div`
	flex: 1 1 65%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	min-height: 450px;
`;
export const PublishSettings = styled.div`
	flex: 1 1 35%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	max-height: 220px;
`;

export const UploadFile = styled(Upload)`
	width: 100%;
	min-height: 250px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 2px solid ${COLORS.LightGrey};
	border-radius: 10px;

	& span {
		color: ${COLORS.Primary};
	}
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: row;
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
`;
