import styled from "styled-components/macro";
import { COLORS } from "../../layout/theme";
import { FaList } from "react-icons/fa";
import { HiViewGrid } from "react-icons/hi";

export const List = styled(FaList)`
	cursor: pointer;
`;
export const ViewGrid = styled(HiViewGrid)`
	cursor: pointer;
`;
export const Inner = styled.div`
	margin-left: 0px;
	background-color: white;
`;
export const Applied = styled.div`
	margin: 20px 0;
	padding-right: 10px;
`;
export const AppliedItemList = styled.div`
	padding: 5px 0;
	& span {
		padding: 5px;
	}
`;
export const AppliedItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	margin: 3px 0;
`;
export const AppliedItemRight = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
export const NotApplied = styled.div``;
export const NotAppliedInner = styled.div`
	margin-bottom: 25px;
`;
export const NotAppliedTitle = styled.div`
	display: flex;
	justify-content: start;
	align-items: end;
	position: relative;
`;
export const IconContainer = styled.div`
	cursor: pointer;
	margin-left: 10px;
	border: 1px solid ${COLORS.Grey};
	border-radius: 3.5px;
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	user-select: none;
	-webkit-user-select: none;
	top: -2px;
`;
export const NotAppliedItemList = styled.div``;
export const NotAppliedItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	margin: 5px 0;
	& span:hover {
		color: ${COLORS.Primary};
	}
`;
export const NotAppliedItemRight = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;

	.ant-image {
		border: solid;
		border-radius: 5px;
		border-color: #d7e6f7;
		border-width: 1.4px;
		border-radius: 5px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
