import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";

export const Inner = styled.div``;

export const InfoRegion = styled.div`
	color: ${COLORS.Primary};
	font-size: ${SIZES.level6};
	font-weight: ${WEIGHT.Bold};
`;

export const Container = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
`;

export const Section = styled.div``;
