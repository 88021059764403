import { Modal, Select } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";
import AntArrow from "../../../../../../assets/images/cart-arrow-select.svg";

export const Inner = styled.div`
	display: flex;
	border-radius: 15px;
	background-color: ${COLORS.White};
	height: 140px;
	width: 100%;
	padding: 10px 20px;
	gap: 20px;
`;
export const ImageContainer = styled.div`
	flex: 1 1 20%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	object-fit: contain;
`;
export const CenterData = styled.div`
	flex: 1 1 60%;
	display: flex;
	border-left: 1px solid ${COLORS.Grey};
	border-right: 1px solid ${COLORS.Grey};
	padding: 1rem;
	gap: 80px;
`;

export const RightData = styled.div`
	flex: 1 1 20%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;
	padding: 1rem;
`;

export const Section = styled.div``;

export const AntdSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 2px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  min-width: 160px;

  &..ant-select-arrow {
      src=${AntArrow};
    }
  

  &.ant-select-selector{
    .ant-select-selection-item {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      font-size: ${SIZES.level12};
      color: ${COLORS.Primary};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${COLORS.Primary};
      }
    }
  }
`;

export const AntdModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
	}

	.ant-modal-header {
		border-radius: 10px 10px 0 0;
	}
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
		border-radius: 10px;
	}
	.ant-modal-footer {
		text-align: left;
		height: 80px;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	height: 500px;
	overflow: auto;

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.SkeletonGrey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}
`;

export const CenterLeftData = styled.div`
	display: flex;
	flex-direction: column;
	height: 90px;
	justify-content: space-around;
	flex: 1 1 25%;
`;
export const CenterRightData = styled.div`
	display: flex;
	flex-direction: column;
	height: 90px;
	justify-content: space-around;
	flex: 1 1 65%;
`;

export const MoreInfo = styled.span`
  color: ${COLORS.Secondary};
  font-size: ${SIZES.level6}:
  font-weight: ${WEIGHT.Bold};
  cursor: Pointer;
`;
