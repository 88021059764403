import React, { useEffect, useState } from "react";
import { TableContainer, AntdTable } from "./styles";
import { BsDownload } from "react-icons/bs";
import moment from "moment";
import { COLORS, Typo } from "../../../../../layout/theme";
import Image from "../../../../../common/image/Image";
import UsersInfoModal from "../users-info-modal/UsersInfoModal";
import { Modal } from "antd";
import { CONSTANTS } from "../../../../../../utils/constants";

export default function ReportBannersTable({
	isLoading,
	data,
	expandableData,
}) {
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [key, setKey] = useState(null);

	const handleDetail = async (infoDetails) => {
		setKey(infoDetails);
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
		setKey(null);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setKey(null);
	};

	const checkBannerTitle = (title) => {
		switch (title) {
			case CONSTANTS.BIG_BANNER_HOME:
				return "Banner Principal Home";

			case CONSTANTS.DOUBLE_BANNER_HOME:
				return "Banner Dobles Home";

			case CONSTANTS.TRIPLE_BANNER_HOME:
				return "Banner Triples Home";

			case CONSTANTS.BOOK_DOWN_LEFT_BANNER:
				return "Banner Izquierda Inferior Libro";

			case CONSTANTS.BOOK_UP_LEFT_BANNER:
				return "Banner Izquierda Superior Libro";

			case CONSTANTS.BOOK_DOWN_RIGHT_BANNER:
				return "Banner Derecha Inferior Libro";

			case CONSTANTS.BOOK_UP_RIGHT_BANNER:
				return "Banner Derecha Superior Libro";

			default:
				break;
		}
	};

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Banner</div>,
			dataIndex: "title",
			width: 500,
			render: (value, record) => (
				<Typo type="primary" fontWeight={600} fontSize={14}>
					{checkBannerTitle(value)}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Cantidad interacciones</div>
			),
			dataIndex: "totalCount",
			render: (value, record) => (
				<Typo type="secondary" fontWeight={600} fontSize={14}>
					Banners clickeados: {value}
				</Typo>
			),
		},
	];

	const expandedRowRender = () => {
		const expandableColumns = [
			{
				title: () => <div style={{ color: COLORS.White }}>Banner</div>,
				dataIndex: "photoUrl",
				render: (value) => <Image src={value} width={150} height={100} />,
			},
			{
				title: () => (
					<div style={{ color: COLORS.White }}>Usuarios que hicieron click</div>
				),
				dataIndex: "bannerVisits",
				render: (value) => (
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				),
			},
			{
				title: () => <div style={{ color: COLORS.White }}>Clicks totales</div>,
				dataIndex: "bannerTotalVisits",
				render: (value, record) => (
					<Typo
						type="muted"
						fontWeight={600}
						fontSize={14}
						onClick={() => handleDetail(record.interactionInfo)}
						style={{ cursor: "pointer" }}
					>
						{value}
					</Typo>
				),
			},
			{
				title: () => <div style={{ color: COLORS.White }}>Creado</div>,
				dataIndex: "created",
				render: (value) => (
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{moment(value).format("DD-MM-YYYY")}
					</Typo>
				),
			},
		];
		return (
			<>
				<AntdTable
					dataSource={expandableData}
					columns={expandableColumns}
					pagination={
						expandableColumns.length < 25
							? false
							: {
									pageSize: 25,
									showSizeChanger: false,
									onChange: (page) => setPage(page),
							  }
					}
				/>
				{key && isModalVisible && (
					<Modal
						title={
							<div style={{ height: 30 }}>
								<Typo level={8} type="muted">
									Detalle interacciones
								</Typo>
							</div>
						}
						visible={isModalVisible}
						onOk={handleOk}
						onCancel={handleCancel}
						width={900}
						centered={true}
						footer={null}
					>
						<UsersInfoModal modalData={key} />
					</Modal>
				)}
			</>
		);
	};

	return (
		<TableContainer>
			<AntdTable
				dataSource={data}
				columns={columns}
				showHeader={false}
				pagination={false}
				expandable={{
					expandedRowRender,
					defaultExpandedRowKeys: ["0"],
				}}
			/>
		</TableContainer>
	);
}
