import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { COLORS, Typo } from "../../../../../layout/theme";
import {
	AntdTable,
	ButtonContainer,
	CartDelete,
	CompaginationDiv,
	Container,
	ContainerTitle,
	Inner,
	InnerForm,
	ModalContainer,
	ProductData,
	PublishSettings,
	Section,
} from "./styles";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";
import {
	Button,
	Form,
	Input,
	InputNumber,
	Modal,
	Select,
	Space,
	message,
} from "antd";
import {
	CloseCircleOutlined,
	LeftOutlined,
	RightCircleOutlined,
	RightOutlined,
} from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import Image from "../../../../../common/image/Image";
import { get_simplified_products_list } from "../../../../../../api/endpoints/products";
import debounce from "lodash/debounce";
import ProductsTable from "../../components/products-table/ProductsTable";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import {
	get_featured_product_by_id,
	update_featured_group_product,
} from "../../../../../../api/endpoints/advertisings";
import ImageGallery from "../../../../../common/image-gallery/ImageGallery";
let productIndex = null;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function CreateFeaturedProduct() {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { id } = useParams();
	const [loadingPage, setLoadingPage] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [searchByStore, setSearchByStore] = useState(null);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(50);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchModalShow, setSearchModalShow] = useState(false);
	const [homeFeaturedProducts, setHomeFeaturedProducts] = useState([]);
	const [imageUrl, setImageUrl] = useState();
	const [state, setState] = useState("ACTIVE");
	const [featuredTitle, setFeaturedTitle] = useState(null);
	const [featuredOrder, setFeaturedOrder] = useState(null);
	const [photoGallery, setPhotoGallery] = useState(null);
	const [initialValues, setInitialValues] = useState(null);

	const fetch_featured_product = async () => {
		const res = await get_featured_product_by_id(id);

		setState(res?.isActive ? "ACTIVE" : "INACTIVE");
		setFeaturedTitle(res?.title);
		setFeaturedOrder(res?.order);
		setHomeFeaturedProducts(res?.featuredProducts);

		setPhotoGallery(
			res?.photoGallery?.map((f) => ({
				uid: f.id,
				name: f.fileName,
				status: "done",
				url: f.url,
			})) || []
		);

		setInitialValues(res);
	};

	useEffect(() => {
		fetch_featured_product();
	}, []);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const productColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			width: 100,
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image width={40} height={40} src={value} alt={record.title} />
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 18 : 16}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.presentationName}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "discount",
			key: "discount",
			render: (t, item) => (
				<Typo type="success" lineHeight={1} level={5}>
					{`${numberWithDotAndCommas(
						(100 - (item.offerPrice * 100) / item.price).toFixed(2)
					)}%`}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			width: 100,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(index);
						}}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	const featuredsColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 90,
			render: (photoUrl) => <Image src={photoUrl} height={40} width={40} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			width: 600,
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t?.length < 15 ? 16 : 14}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.presentationName}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "discount",
			key: "discount",
			render: (t, item) => (
				<Typo type="success" lineHeight={1} level={5}>
					{`${numberWithDotAndCommas(
						(100 - (item.offerPrice * 100) / item.price).toFixed(2)
					)}%`}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Quitar</div>,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button type="link" onClick={() => handleDelete(index)}>
						<CartDelete>X</CartDelete>
					</Button>
				</Space>
			),
		},
	];

	const fetch_products = async (search, skipValue) => {
		setLoading(true);
		try {
			const res = await get_simplified_products_list(
				search,
				"",
				skipValue !== null ? skipValue : skip,
				take
			);
			setData(
				res.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setSearchModalShow(true);
		}
	};

	//////******************************************************** */

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => idx === e);
		productIndex = _template;
		const auxTemplate = homeFeaturedProducts.concat(_template);

		const templateSinDuplicados = auxTemplate.reduce(
			(acumulador, valorActual) => {
				const elementoYaExiste = acumulador.find(
					(elemento) => elemento.id === valorActual.id
				);
				if (elementoYaExiste) {
					return acumulador.map((elemento) => {
						if (elemento.id === valorActual.id) {
							return {
								...elemento,
								quantity: elemento.quantity + valorActual.quantity,
							};
						}
						return elemento;
					});
				}

				return [...acumulador, valorActual];
			},
			[]
		);
		setHomeFeaturedProducts(
			templateSinDuplicados.sort((a, b) => {
				const titleA = a.title.toLowerCase();
				const titleB = b.title.toLowerCase();
				if (titleA < titleB) {
					return -1;
				}
				if (titleA > titleB) {
					return 1;
				}

				return 0;
			})
		);
	};

	//////******************************************************** */

	const handleDelete = (index) => {
		const _template = homeFeaturedProducts.filter((p, idx) => idx !== index);
		setHomeFeaturedProducts(_template);
	};

	//////******************************************************** */

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido completados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/featuredSettings");
			},
		});
	};

	const eventHandler = (e) => {
		setCurrentPage(1);
		setSearchByStore(e.target.value);
		fetch_products(e.target.value, 0);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 600), []);

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - 50;

			fetch_products(searchByStore, skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - 50);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + 50;

		fetch_products(searchByStore, skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + 50);
	};

	const handleModalOk = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	const handleModalCancel = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	const onFinish = async (values) => {
		if (!photoGallery || photoGallery.length === 0) {
			return openNotificationWithIcon(
				TYPE.ERROR,
				"Imagen obligatoria",
				"Un error ocurrio al intentar crear la campaña, no ingreso una imagen"
			);
		}
		try {
			if (homeFeaturedProducts.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe seleccionar al menos un Producto para destacar en el home"
				);
				return;
			} else if (homeFeaturedProducts.length > 24) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"La cantidad de Productos para destacar no puede ser mayor a 24"
				);
				return;
			}

			setSubmitting(true);

			const postData = {
				title: featuredTitle,
				cardPosition: featuredOrder,
				isActive: state === "ACTIVE" ? true : false,
				products:
					homeFeaturedProducts?.map((element, idx) => {
						return element?.id;
					}) || [],
			};

			const formData = new FormData();
			photoGallery.forEach((f) => {
				formData.append("files", f.originFileObj);
			});
			formData.append("data", JSON.stringify(postData));

			await update_featured_group_product(id, formData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Destacados creados con exito",
				"Los destacados se crearon con exito"
			);

			navigate("/admin/featuredSettings");
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear destacados",
				"Un error ocurrio al intentar crear los destacados, contacte con soporte a soporte@onetransfer.com."
			);
			console.log(error);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handlePhotoGalleryChange = (values) => {
		setPhotoGallery(values);
	};

	if (!initialValues) return <p>Cargando...</p>;

	return (
		<Inner>
			<Form
				form={form}
				name="basic"
				//onFinish={onFinish}
				autoComplete="off"
				requiredMark={false}
				layout="vertical"
				initialValues={initialValues}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
						padding: "1rem",
					}}
				>
					<InnerForm>
						<ProductData>
							<Container>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Nombre
										</label>
									}
									name="title"
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
										{
											pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
											message:
												"No se aceptan caracteres especiales. Solo letras y numeros",
										},
									]}
								>
									<Input
										onChange={(e) => setFeaturedTitle(e.target.value)}
										value={featuredTitle}
										placeholder="Nombre"
										style={{ width: 450 }}
									/>
								</Form.Item>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Posición
										</label>
									}
									name="cardPosition"
									rules={[]}
								>
									<InputNumber
										onChange={(e) => setFeaturedOrder(e)}
										value={featuredOrder}
										placeholder="Posición"
									/>
								</Form.Item>
							</Container>
							<Container>
								<Typo type="primary" level={6}>
									Galeria
								</Typo>
								<ImageGallery
									data={photoGallery}
									onChange={handlePhotoGalleryChange}
								/>
							</Container>
							{(!photoGallery || photoGallery.length === 0) && (
								<Typo type="danger" level={8}>
									*La foto es obligatoria
								</Typo>
							)}
						</ProductData>
						<PublishSettings>
							<ButtonContainer>
								<SignInTemplate.AntdButton
									type="Primary"
									color="white"
									bg="Primary"
									onClick={onFinish}
									//htmlType="submit"
									loading={submitting}
								>
									Guardar cambios
								</SignInTemplate.AntdButton>
								<SignInTemplate.AntdButton
									type="Primary"
									loading={submitting}
									onClick={handleBack}
								>
									<Typo type="danger" level={6}>
										Salir sin guardar
									</Typo>
								</SignInTemplate.AntdButton>
							</ButtonContainer>
							<Form.Item
								label={
									<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
										Estado
									</label>
								}
								name="state"
							>
								<Select defaultValue={state} onChange={(e) => setState(e)}>
									<Select.Option value="ACTIVE">Activa</Select.Option>
									<Select.Option value="INACTIVE">Inactiva</Select.Option>
								</Select>
							</Form.Item>
						</PublishSettings>
					</InnerForm>
					<Section>
						<Typo type="primary" level={6}>
							Productos Destacados
						</Typo>
						<AntdSearch
							allowClear
							placeholder="Buscar producto"
							width={459}
							onChange={(e) => setSearchByStore(e.target.value)}
							value={searchByStore}
							onPressEnter={(e) =>
								e.target.value.toUpperCase() === "RX" ||
								e.target.value.toUpperCase() === "OTC"
									? debouncedEventHandler(e)
									: e.target.value.length > 3
									? debouncedEventHandler(e)
									: console.log(e.target.value)
							}
							disabled={loading}
						/>
						<Modal
							open={searchModalShow}
							onOk={handleModalOk}
							onCancel={handleModalCancel}
							width={900}
							centered={true}
							footer={null}
							destroyOnClose={true}
						>
							<ModalContainer>
								{data && data.length > 0 && (
									<CompaginationDiv>
										{currentPage !== 1 && (
											<Button
												size="small"
												shape="circle"
												onClick={handleDecrement}
											>
												<LeftOutlined />
											</Button>
										)}
										{currentPage}
										{data.length === 50 && (
											<Button
												size="small"
												shape="circle"
												onClick={handleIncrement}
												disabled={data.length < 50 ? true : false}
											>
												<RightOutlined />
											</Button>
										)}
									</CompaginationDiv>
								)}
								<ProductsTable
									data={data}
									columns={productColumns}
									loading={loading}
									scrollData={{ y: 350 }}
								/>
							</ModalContainer>
						</Modal>

						<Form.Item
							name="products"
							label={
								<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
									Productos destacados
								</label>
							}
						>
							<AntdTable
								showHeader={true}
								columns={featuredsColumns}
								dataSource={homeFeaturedProducts}
								pagination={false}
								rowKey={(record) => record.id}
								scroll={{ y: 700 }}
							/>
						</Form.Item>
					</Section>
				</div>
			</Form>
		</Inner>
	);
}
