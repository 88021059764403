function numberToMillions(num) {
	if (num >= 1000000) {
		const roundedNum = Math.round(num / 100000) / 10;
		return roundedNum.toFixed(1) + "M";
	} else {
		return null;
	}
}

export { numberToMillions };
