import { COLORS, SIZES, Typo, WEIGHT } from "../../../layout/theme";
import styled from "styled-components/macro";
import { Modal } from "antd";

export const Line = styled(Typo)`
	text-decoration: line-through rgba(0, 0, 0, 0.2);
`;

export const ItemsQty = styled.div`
	color: ${COLORS.Warning};
	text-align: center;
	font-size: ${SIZES.level7};
`;

export const InfoUnit = styled.div`
	color: ${COLORS.Secondary};
	text-align: center;
	font-size: 12px;
`;

export const TopContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	height: 100%;
	width: 100%;
	padding: 9px;

	@media only screen and (min-width: 760px) and (max-width: 1160px) {
		height: 60px;
		width: 60px;
		padding: 0px;
	}
`;

export const Inner = styled.div`
	cursor: pointer;
	background: ${COLORS.White};
	height: 100px;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
`;

export const StarContainer = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	right: 10px;
	top: 10px;
	color: ${COLORS.Default};
	font-size: 20px;
	cursor: pointer;
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: start;
	gap: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const HeaderLeft = styled.div`
	flex: 0 0 100%;
	overflow: hidden;
	white-space: nowrap;
	display: flex;
	flex-direction: column;
`;

export const HeaderRight = styled.div`
	flex: 1 1 20%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const SectionOffer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
`;

export const TriggerQtyContainer = styled.div`
	color: ${COLORS.StrongGreen};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level7};
	text-align: center;

	@media only screen and (min-width: 760px) and (max-width: 1180px) {
		font-size: 10px;
	}
`;

export const MinQtyContainer = styled.div`
	color: ${COLORS.Primary};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level7};
	text-align: center;
	margin-bottom: 2px;

	@media only screen and (min-width: 760px) and (max-width: 1180px) {
		font-size: 10px;
	}
`;

export const OffContainer = styled.div`
	writing-mode: vertical-lr;
	font-size: 8px;
	transform: rotateZ(180deg);
`;

export const SpecialOfferContainer = styled.div`
	display: flex;
	align-items: center;

	@media only screen and (min-width: 760px) and (max-width: 1180px) {
		span {
			font-size: 11px;
		}

		div {
			font-size: 6px;
		}
	}
`;

export const AntdModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
	}

	.ant-modal-header {
		border-radius: 10px 10px 0 0;
	}
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
		padding: 1rem 5rem 1rem 5rem;
	}
	.ant-modal-footer {
		text-align: left;
		height: 80px;
	}
`;

export const MarketingStar = styled.div`
	color: ${COLORS.Default};
`;

export const PslContainer = styled.div`
	font-size: ${SIZES.level10};
	font-weight: ${WEIGHT.SemiBold};
	color: ${COLORS.Success};
`;

export const LeftHeaderContainer = styled.div`
	flex: 1 1 25%;
	display: flex;
	justify-content: center;
`;
export const CenterHeaderContainer = styled.div`
	flex: 1 1 50%;
	display: flex;
	justify-content: center;
`;
export const RightHeaderContainer = styled.div`
	flex: 1 1 25%;
	display: flex;
	justify-content: center;
	padding-right: 5px;
	min-height: 30px;
`;

export const TopHeaderContainer = styled.div`
	display: flex;
	justify-content: space-beetween;
	align-items: center;
	width: 100%;
`;
