import styled from "styled-components/macro";
import { COLORS } from "../../../../layout/theme";
import { Table } from "antd";

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;
export const LeftSection = styled.div`
	flex: 1 1 65%;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const RightSection = styled.div`
	flex: 1 1 35%;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const PublishSettings = styled.div`
	flex: 0 0 30%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
`;

export const StoreData = styled.div`
	flex: 1 1 40%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const SectionData = styled.div`
	flex: 1;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const SalesData = styled.div`
	flex: 0;
	background-color: ${COLORS.White};
	display: flex;
	flex-direction: column;
	padding: 2rem;
	border-radius: 10px;
	gap: 30px;
`;

export const Container = styled.div`
	display: flex;
	gap: 10px;
	padding: 20px 0px;

	.ant-form-item-explain-error {
		white-space: nowrap;
	}
`;

export const BlockedData = styled.div`
	pointer-events: none;
`;

export const BillerContainer = styled.div`
	display: flex;
	width: 100%;
	padding: 1rem;
	gap: 10px;
	background-color: ${COLORS.White};
	border-radius: 20px;
`;

export const BillerAndCodeContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	align-items: flex-end;
	padding: 10px;
`;

export const BillerSelection = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
`;

export const BillerCode = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	justify-content: center;
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const ContainerMails = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 10px;
`;

export const AddMailContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;
