import { Button, Modal, Select } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES } from "../../../layout/theme";

export const ClientSelect = styled(Select)`

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 2px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &..ant-select-arrow {
      
    }
  

  &.ant-select-selector{
    .ant-select-selection-item {
      font-size: ${SIZES.level7};
      color: ${COLORS.Primary};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${COLORS.Primary};
      }
    }
  }
`;

export const Inner = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;
	padding: 30px 100px;
	background-color: ${COLORS.LightGrey};
`;

export const Section = styled.div`
	background-color: white;
	border-radius: 10px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	min-height: 120px;
	padding: 1rem;
	flex-direction: column;
	gap: 10px;
`;

export const AntdButton = styled(Button)`
	cursor: pointer;

	&:hover {
		color: ${COLORS.Primary};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}

	background-color: ${COLORS.Primary};
	color: ${COLORS.White};
	border-radius: 5px;
	box-shadow: 0 3px 3px 0 #c7c7c7;
`;

export const CreateOrderContainer = styled.div`
	display: flex;
	gap: 10px;
	height: 30px;
	align-items: center;
	cursor: pointer;
	width: 20%;
`;

export const Container = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;
