import styled from "styled-components/macro";

export const Inner = styled.section`
	display: flex;
	flex-direction: column;
	gap: 0px;
`;

export const Title = styled.h3``;

export const DateFilters = styled.article``;

export const Section = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 1rem;
`;
