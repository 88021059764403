import React, { useState } from "react";
import {
	CartButtomNumber,
	CenterData,
	Container,
	ImageContainer,
	Inner,
	ItemsQty,
	Line,
	Loader,
	OffContainer,
	RightData,
	Section,
} from "./styles";
import Image from "../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import {
	LoadingOutlined,
	MinusOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import { Input, Spin } from "antd";
import { FaShoppingCart } from "react-icons/fa";
import { useCart } from "../../../../../../contexts/cartContext";
import { useAuth } from "../../../../../../contexts/authContext";

export default function BannerCardContainer({ item, campaignOffer }) {
	const { addToCart } = useCart();
	const { userdata } = useAuth();
	const [quantity, setQuantity] = useState(item.minQuantity);
	const [submitting, setSubmitting] = useState(false);
	const [qtyInCart, setQtyInCart] = useState(
		item.productQtyInCart ? item.productQtyInCart : 0
	);

	const handleDecrement = () => {
		if (quantity > item.minQuantity) setQuantity((prev) => prev - 1);
	};

	const handleChange = (e) => {
		if (e.target.value >= item.maxQuantity) e.target.value = item.maxQuantity;
		const onlyPositiveInteger = /^[0-9\b]+$/;
		if (onlyPositiveInteger.test(e.target.value))
			setQuantity(parseInt(e.target.value));
	};

	const handleIncrement = (e) => {
		if (quantity >= item.maxQuantity) setQuantity(item.maxQuantity - 1);
		setQuantity((prev) => prev + 1);
	};

	const handleAddToCart = async () => {
		setSubmitting(true);
		const res = await addToCart(item.id, quantity);
		setQtyInCart(res);
		setQuantity(item.minQuantity);

		setSubmitting(false);
	};

	if (!item) return <div>Loading...</div>;

	return (
		<Inner>
			<ImageContainer>
				<Image
					src={item.photoUrl}
					height={120}
					borderRadius="10px"
					preview={true}
				/>
			</ImageContainer>
			<CenterData>
				<Container style={{ flex: "1 1 70%" }}>
					<Typo type="muted" level={6}>
						{item.client?.name.toUpperCase()}
					</Typo>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={item.title.length < 15 ? 18 : 16}
					>
						{item.title}{" "}
						<Typo type="muted" lineHeight={1} level={5}>
							{item.presentation.name}
						</Typo>
					</Typo>
					<Typo type="muted" level={6}>
						{item.activeIngredients?.length > 1
							? item.activeIngredients?.map((i, idx) =>
									idx === item.activeIngredients?.length - 1
										? `${i.name}`
										: `${i.name}, `
							  )
							: item?.activeIngredients[0]?.name === "No aplica"
							? ""
							: item?.activeIngredients[0]?.name}
					</Typo>
				</Container>
				<Container>
					<Section>
						<Typo type="primary" level={5}>
							Oferta:{" "}
						</Typo>
					</Section>
					<Line>
						<Typo type="muted" level={4} underline>
							{`${numberWithDotAndCommas(
								(100 - (item.offerPrice * 100) / item.price).toFixed(2)
							)}%`}
						</Typo>
					</Line>
					<Section>
						<OffContainer translate="no">OFF</OffContainer>
						<Typo type="green" level={3}>
							{`${numberWithDotAndCommas(
								(
									100 -
									((item.offerPrice - (item.offerPrice * campaignOffer) / 100) *
										100) /
										item.price
								).toFixed(2)
							)}%`}
						</Typo>
					</Section>
				</Container>
			</CenterData>
			<RightData isOneMarket={userdata?.isOneMarket || !userdata?.p_shop}>
				<Typo
					level={3}
					type="primary"
					onClick={handleDecrement}
					style={{ paddind: "5px", cursor: "Pointer" }}
				>
					<MinusOutlined />
				</Typo>
				<CartButtomNumber>
					{quantity > 1 ? (
						<Input value={quantity} onChange={handleChange} />
					) : null}
					<Typo
						level={2}
						type="primary"
						onClick={handleAddToCart}
						style={{ position: "relative", cursor: "Pointer" }}
					>
						<FaShoppingCart />
						{submitting && (
							<Loader>
								<Spin
									indicator={
										<LoadingOutlined
											style={{
												fontSize: 24,
												color: COLORS.Grey,
												backgroundColor: "transparent",
											}}
											spin
										/>
									}
								/>
							</Loader>
						)}
					</Typo>
				</CartButtomNumber>
				<Typo
					level={3}
					type="primary"
					onClick={handleIncrement}
					style={{ paddind: "5px", cursor: "Pointer" }}
				>
					<PlusOutlined />
				</Typo>
				{qtyInCart > 0 && (
					<ItemsQty>
						{qtyInCart === 1
							? `${qtyInCart} agregado`
							: `${qtyInCart} agregados`}
					</ItemsQty>
				)}
			</RightData>
		</Inner>
	);
}
