import React from "react";
import Image from "../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../layout/theme";
import { ImageContainer, Inner, RightData, Section } from "./styles";
import { Button, Checkbox, Modal } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { CloseCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export default function BannerCardContainer({
	item,
	handleChange,
	handleDelete,
}) {
	const warningDelete = (key) => {
		confirm({
			title: "¿Seguro que eliminar este banner?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "Esta modificación es irreversible",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleDelete(key);
			},
		});
	};

	if (!item) return <div>Loading...</div>;

	return (
		<Inner>
			<ImageContainer>
				<Image
					src={item.photoUrl}
					height={120}
					borderRadius="10px"
					preview={true}
				/>
			</ImageContainer>
			<RightData>
				<Section>
					<Typo>Activar</Typo>
					<Checkbox
						onChange={(e) =>
							handleChange(item.id, e.target.checked ? "ACTIVE" : "INACTIVE")
						}
						checked={item.isActive}
					></Checkbox>
				</Section>
				<Section>
					<Typo>Eliminar</Typo>
					<Button
						type="link"
						style={{ marginTop: -4 }}
						onClick={() => warningDelete(item.id)}
					>
						<AiFillDelete
							style={{
								color: COLORS.Secondary,
								width: " 100%",
								height: "100%",
							}}
						/>
					</Button>
				</Section>
			</RightData>
		</Inner>
	);
}
