import styled from "styled-components";
import { COLORS } from "../../../../../../../layout/theme";

export const Inner = styled.div`
  color: ${COLORS.Grey};
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 4px;
`;

export const Container = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;
export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  white-space: nowrap;
`;

export const OffContainer = styled.div`
  writing-mode: vertical-lr;
  font-size: 8px;
  transform: rotateZ(180deg);
`;

export const Line = styled.div`
  text-decoration: line-through rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  display: flex;
`;

export const SubContainer = styled.div`
  display: flex;
  align-items: center;
`;
