import React from "react";
import { RoundSearch } from "./styles";
import { Typo } from "../../layout/theme";
import { BiSearchAlt2 } from "react-icons/bi";

export default function AntdSearch({ ...props }) {
	return (
		<RoundSearch
			{...props}
			suffix={
				<Typo type="primary" level={4} onClick={props.callbackSearch ?? null}>
					<BiSearchAlt2
						style={{
							position: "relative",
							bottom: "-3px",
						}}
					/>
				</Typo>
			}
		/>
	);
}
