import { Col, Row } from "antd";
import styled from "styled-components";
import { SIZES, COLORS, WEIGHT } from "../theme/index";

export const Inner = styled.div`
  background-color: ${COLORS.White};
`;

export const Main = styled(Row)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 50px;
`;

export const Divider = styled.hr`
  width: 100%;
  border: none;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  background-color: ${COLORS.LightGrey};
  opacity: 1;
  box-shadow: 0 3px 3px #bfbfbf;
`;

export const Column = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.div``;

export const Copy = styled.div`
  text-align: center;
  color: ${COLORS.Grey};
  font-size: ${SIZES.level7};
  //   padding: 20px 0 0 0;
`;

export const Titles = styled.div`
  color: ${COLORS.Grey};
  font-size: ${SIZES.level7};
  font-weight: ${WEIGHT.Black};
`;

export const Info = styled.div`
  color: ${COLORS.Primary};
  font-size: ${SIZES.level5};
  text-align: left;
  justify-content: center;
  //   padding: 50px 0 0 0;
`;

export const Bottom = styled.div`
  //   padding: 100px 0 0 0;
  background-color: ${COLORS.White};
`;
