import { Skeleton, Row, Col, Space, Form } from "antd";
import { Inner, ClientData } from "./styles";
import Main from "../../../../../../layout/main/Main";

const GUTTER = 10;

export default function LoadingSkeleton() {
	return (
		<Inner>
			<ClientData>
				<Main.Body>
					<Space direction="vertical" size="middle" style={{ display: "flex" }}>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Form.Item>
									<Skeleton.Input active />
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col>
								<Skeleton.Image width={150} height={150} alt="avatar" />
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={6}>
								<Skeleton.Input active={true} block />
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={6}>
								<Skeleton.Input active={true} block />
							</Col>
							<Col span={6}>
								<Skeleton.Input active={true} block />
							</Col>
							<Col span={6}>
								<Skeleton.Input active={true} block />
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Skeleton.Input active={true} block />
							</Col>
							<Col span={6}>
								<Skeleton.Input active={true} block />
							</Col>
						</Row>
						<Row></Row>
						<Row></Row>
						<Row>
							<Col span={2}>
								<Skeleton.Input active={true} block />
							</Col>
						</Row>
					</Space>
				</Main.Body>
			</ClientData>
		</Inner>
	);
}
