import React from "react";
import { AntdTable } from "./styles";
import { COLORS, Typo } from "../../../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { FaImages } from "react-icons/fa";
import Image from "../../../../../common/image/Image";

export default function SaleDetail({ modalInfo }) {
	const columns = [
		{
			title: () => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "productPhotoUrl",
			width: 80,
			render: (value, record, index) => (
				<Image width={40} height={40} src={value} alt={record.productTitle} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "productTitle",
			render: (value) => (
				<Typo type="terciary" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Empresa</div>,
			dataIndex: "productEan",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio</div>,
			dataIndex: "productPrice",
			width: 120,
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					${numberWithDotAndCommas(value)}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "productOfferPrice",
			width: 80,
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{`${numberWithDotAndCommas(
						(
							100 -
							(record.productOfferPrice * 100) / record.productPrice
						).toFixed(2)
					)}%`}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cantidad</div>,
			dataIndex: "productQty",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Final</div>,
			dataIndex: "productOfferPrice",
			width: 120,
			render: (value, r) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					${numberWithDotAndCommas((value * r.productQty).toFixed(2))}
				</Typo>
			),
		},
	];

	return (
		<AntdTable columns={columns} dataSource={modalInfo} pagination={false} />
	);
}
