import { Button } from "antd";
import styled from "styled-components";
import { COLORS } from "../../layout/theme";

export const ValidatorAntdButton = styled(Button)`
	cursor: pointer;

	&:hover {
		color: ${COLORS.White};
		border: 1px solid ${COLORS.Secondary};
		background-color: ${COLORS.Secondary};
		box-shadow: 0 1px 1px #bfbfbf;
	}

	background-color: ${COLORS.Secondary};
	color: ${COLORS.White};
	border-radius: 5px;
	box-shadow: 0 3px 3px 0 ${COLORS.MediumGrey};
`;
