import axiosInstance from "../axiosInstance";

const CONTROLLER = "dashboards";

const get_sales_dashboard = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetSales`);
	return response.data;
};

const get_buys_dashboard = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetBuys`);
	return response.data;
};

const get_one_market_main = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetOneMarketMain`);
	return response.data;
};

const get_general_dashboard = async (
	distributorId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetGeneralDashboard`,
		{
			params: {
				distributorId: distributorId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		}
	);
	return response.data;
};

const get_dinamic_sales_dashboard = async (
	distributorId,
	clientId,
	provinceName,
	dateFrom,
	dateTo,
	typeOfSale
) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetSalesDashboard`, {
		params: {
			distributorId: distributorId,
			clientId: clientId,
			provinceName: provinceName,
			dateFrom: dateFrom,
			dateTo: dateTo,
			typeOfSale: typeOfSale,
		},
	});
	return response.data;
};

const get_dinamic_mobile_dashboard = async (
	distributorId,
	clientId,
	userId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetMobileDashboard`, {
		params: {
			distributorId: distributorId,
			clientId: clientId,
			userId: userId,
			provinceName: provinceName,
			dateFrom: dateFrom,
			dateTo: dateTo,
		},
	});
	return response.data;
};

const get_pharmacy_general_dashboard = async (
	distributorId,
	clientId,
	pharmacyId,
	pointOfSaleId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPharmacyGeneralDashboard`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				pharmacyId: pharmacyId,
				pointOfSaleId: pointOfSaleId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		}
	);
	return response.data;
};

const get_region_dashboard = async (
	distributorId,
	clientId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetRegionDashboard`, {
		params: {
			distributorId: distributorId,
			clientId: clientId,
			provinceName: provinceName,
			dateFrom: dateFrom,
			dateTo: dateTo,
		},
	});
	return response.data;
};

const get_distributor_dashboard = async (
	distributorId,
	clientId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetDistributorDashboard`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		}
	);
	return response.data;
};

const get_pharmacy_laboratory_dashboard = async (
	distributorId,
	clientId,
	pharmacyId,
	pointOfSaleId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPharmacyLaboratoryDashboard`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				pharmacyId: pharmacyId,
				pointOfSaleId: pointOfSaleId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		}
	);
	return response.data;
};

const get_laboratory_dashboard = async (
	distributorId,
	clientId,
	provinceName,
	dateFrom,
	dateTo
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetLaboratoryDashboard`,
		{
			params: {
				distributorId: distributorId,
				clientId: clientId,
				provinceName: provinceName,
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		}
	);
	return response.data;
};

const export_dashboard_pharmacies = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ExportDashboardPharmacy`,
		data,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

export {
	get_sales_dashboard,
	get_buys_dashboard,
	get_one_market_main,
	get_general_dashboard,
	get_dinamic_sales_dashboard,
	get_dinamic_mobile_dashboard,
	get_pharmacy_general_dashboard,
	get_region_dashboard,
	get_distributor_dashboard,
	get_pharmacy_laboratory_dashboard,
	get_laboratory_dashboard,
	export_dashboard_pharmacies,
};
