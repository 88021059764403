import styled from "styled-components/macro";
import "swiper/css";
import { Swiper } from "swiper/react";
import { COLORS } from "../../layout/theme";

export const WidthImage = styled.div`
	width: 300px;
`;

export const PlaceholderImage = styled.div`
	height: 400px;
	width: 100vw;
	background: #eee;
`;

export const SwiperNavPrev = styled.div`
	&:hover {
		color: white;
		background-color: ${COLORS.Primary};
		border: 2px solid ${COLORS.Primary};
		box-shadow: 0 0px 1px ${COLORS.Grey};
		opacity: 1;
	}

	top: 50%;
	position: absolute;
	transform: translateY(-50%);
	width: 28px;
	height: 28px;
	z-index: 1;
	cursor: pointer;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	left: 9px;
	background-color: white;
	border: 2px solid ${COLORS.LightGrey};
	border-radius: 40px;
	box-shadow: 0 3px 3px ${COLORS.Grey};
	color: ${COLORS.Grey};
	justify-content: center;
	display: flex;
	flex-direction: column;
	opacity: ${(props) => props.opacityValue};
`;

export const SwiperNavNext = styled.div`
	&:hover {
		color: white;
		background-color: ${COLORS.Primary};
		border: 2px solid ${COLORS.Primary};
		box-shadow: 0 0px 1px ${COLORS.Grey};
		opacity: 1;
	}

	top: 50%;
	position: absolute;
	transform: translateY(-50%);
	width: 28px;
	height: 28px;
	z-index: 1;
	cursor: pointer;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	right: 9px;
	background-color: white;
	border: 2px solid ${COLORS.LightGrey};
	border-radius: 40px;
	box-shadow: 0 3px 3px ${COLORS.Grey};
	color: ${COLORS.Grey};
	justify-content: center;
	display: flex;
	flex-direction: column;
	opacity: ${(props) => props.opacityValue};
`;

export const SwiperSliders = styled(Swiper)``;
