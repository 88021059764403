import axiosInstance from "../axiosInstance";

const CONTROLLER = "CartTemplates";

const get_templates = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}`
	);
	return response.data;
};

const get_templates_page = async (page, state, search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?pageNumber=${page}&state=${state}&search=${search}`
	);
	return response.data;
};

const get_template_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const get_template_header_info = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetOrderTemplatesHeader`
	);
	return response.data;
};

const create_template = async (template) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, template);
	return response.data;
};

const update_template = async (id, template) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, template);
	return response.data;
};

const add_template_to_cart = async (id) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/AddTemplateToCart/${id}`
	);
	return response.data;
};

const delete_template = async (id) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/DeleteTemplate/${id}`
	);
	return response.data;
};

const update_template_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateTemplateState/${id}`,
		state
	);
	return response.data;
};

export {
	create_template,
	update_template,
	get_templates,
	get_template_by_id,
	get_templates_page,
	add_template_to_cart,
	delete_template,
	get_template_header_info,
	update_template_state,
};
