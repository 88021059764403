import { Skeleton, Row, Col } from "antd";
import { CheckBoxDiv, Inner, PublishContainer } from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
	return (
		<Inner>
			<PublishContainer style={{ flex: "1 1 65%" }}>
				<Row></Row>
				<Row gutter={GUTTER} style={{ width: "100%" }}>
					<Col span={8}>
						<Skeleton.Image style={{ marginLeft: 30 }} />
					</Col>
					<Col span={8}>
						<Skeleton paragraph={{ rows: 0 }} active />
						<Skeleton.Input active={true} />
						<Skeleton paragraph={{ rows: 0 }} active />
						<Skeleton.Input active={true} />
						<Skeleton paragraph={{ rows: 0 }} active />
						<Skeleton.Input active={true} />
					</Col>
					<Col span={6}>
						<Skeleton paragraph={{ rows: 0 }} active />
						<Skeleton.Input active={true} />
						<Skeleton paragraph={{ rows: 0 }} active />
						<Skeleton.Input active={true} />
						<Skeleton paragraph={{ rows: 0 }} active />
						<CheckBoxDiv />
					</Col>
				</Row>
				<Row></Row>
			</PublishContainer>

			<PublishContainer
				style={{ justifyContent: "flex-start", paddingLeft: "2rem" }}
			>
				<Skeleton paragraph={{ rows: 0 }} active />
				<Skeleton.Image />
			</PublishContainer>
		</Inner>
	);
}
