import styled from "styled-components/macro";
import { COLORS } from "../../../../../../../layout/theme";

export const Inner = styled.div`
  display: flex;
`;
export const ProductData = styled.div`
  flex: 1 1 60%;
  width: 950px;
`;
export const PublishSettings = styled.div`
  flex: 1 1 40%;
`;

export const Container = styled.div`
  padding: 2rem;
`;

export const PublishContainer = styled.div`
  height: 450px;
  width: 350px;
  background-color: ${COLORS.SkeletonGrey};
  padding: 2rem;
  margin-top: 70px;
  margin-right: 0px;
`;

export const TextLine = styled.div`
  height: ${(props) => (props.ySize ? props.ySize : "10px")};
  width: ${(props) => (props.xSize ? props.xSize : "200px")};
  background-color: ${COLORS.SkeletonGrey};
`;
