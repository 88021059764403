import styled from "styled-components/macro";
import { Drawer } from "antd";
import { COLORS } from "../../../../layout/theme";

export const Inner = styled.div`
	min-height: 100vh;
	width: 100%;
	background-color: rgb(243 244 246 / 1);
	padding: 20px;
	gap: 20px;
`;
export const OptionsSection = styled.div`
	flex: 0 0 20%;
	padding: 40px 0 40px 40px;
	background-color: white;
`;
export const ResultsSection = styled.div`
	flex: 1 1 80%;
	padding: 30px 20px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
`;
export const BigChartContainer = styled.div`
	grid-row: 2 / 4;
	grid-column: 1 / 4;
`;
export const BigBox = styled.div`
	grid-row: 4 / 6;
	grid-column: 1 / 3;
`;

export const VerticalDobleBox = styled.div`
	grid-row: 4 / 6;
	grid-column: 3 / 4;
	${({ second }) => second && "grid-column: 4/5"}
`;

export const DivVacio = styled.div`
	width: 100%;
	height: 100%;
	background-color: white;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	justify-items: space-between;
	padding: 25px 20px;
`;

export const ContainerText = styled.div``;

export const Wrapper = styled.div`
	background-color: ${COLORS.LightGrey};
`;

export const FilterContainerButton = styled.div`
	cursor: ${(props) => (props.conditionFullfilled ? "pointer" : "auto")};
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 5px;
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	&:hover {
		background-color: ${(props) =>
			props.conditionFullfilled ? COLORS.LightDefault : COLORS.White};
	}
`;

export const DrawerContainer = styled(Drawer)`
	position: absolute;
	height: 300px;
	left: 80px;
	top: 20px;
	display: flex;
	justify-content: space-between;

	.ant-drawer-content-wrapper {
		width: 200px !important;
	}
`;
