import { COLORS, SIZES, Typo, WEIGHT } from "../../layout/theme";
import styled from "styled-components/macro";
import { Button } from "antd";

export const Line = styled(Typo)`
	display: flex;
	justify-content: end;
	&::before {
		content: "";
		background-color: ${COLORS.Grey};
		height: 1px;
		width: 60%;
		position: absolute;
		z-index: 4;
		top: 12px;
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	gap: 5px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const ItemsQty = styled.div`
	color: ${COLORS.Warning};
	user-select: none;
	margin-bottom: -20px;
`;

export const Loader = styled.div`
	transform: translate(0px, 0px) scale(2.5);
	z-index: 999;
`;

export const CardContainer = styled.div`
	height: 100%;
`;
export const TextSeparatorForPrice = styled.div`
	height: 100%;
`;

export const MarketingStar = styled.div`
	position: absolute;
	top: 0px;
	right: 10%;
	color: ${COLORS.Grey};
	font-size: 20px;
	color: ${COLORS.Default};
`;

export const TextOfferContainer = styled.div`
	font-weight: 700;
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level5};
`;

export const TextPriceOff = styled.p`
	font-weight: 700;
	writing-mode: vertical-lr;
	font-size: 9px;
	height: 9px;
	margin-right: -7px;
	color: #646464;
	transform-origin: bottom;
	transform: rotateZ(180deg) translateY(3px);
`;

export const SectionOffer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	gap: 5px;
	margin-top: 5px;
	width: 100%;
`;

export const TriggerQtyContainer = styled.div`
	color: ${COLORS.Success};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level7};
	text-align: center;
`;
export const OffContainer = styled.div`
	writing-mode: vertical-lr;
	font-size: 8px;
	transform: rotateZ(180deg);
`;

export const SpecialOfferContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const InputContainer = styled.div`
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	padding: 0 10px 0 10px;
`;

export const CartButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${COLORS.Primary};
	color: ${COLORS.White} !important;
	border: none !important;
	height: 100% !important;
	border-radius: 16px;
	font-size: ${SIZES.level8} !important;
`;

export const CartButtonsContainer = styled.div`
	height: 33px;
	background: ${COLORS.Primary} !important;
	color: ${COLORS.White};
	display: flex;
	align-items: center;
	transition: width 0.2s ease;
	z-index: 3;
	border-radius: 10px;

	& button {
		background: ${COLORS.Primary} !important;
		color: ${COLORS.White} !important;
		border: none !important;
		height: 100% !important;
		font-size: ${SIZES.level6} !important;
		font-weight: ${WEIGHT.Bold} !important;
		width: 100%;
	}

	& input {
		background: ${COLORS.White} !important;
		color: ${COLORS.Primary} !important;
		border: none !important;
		height: 100% !important;
		outline: none !important;
		box-shadow: none !important;
		text-align: center !important;
		font-size: ${SIZES.level6} !important;
		font-weight: ${WEIGHT.Bold} !important;
		padding: 10px;
		width: 100%;
	}
`;
export const MinQtyContainer = styled.div`
	color: ${COLORS.Primary};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level7};
	text-align: center;

	@media only screen and (min-width: 760px) and (max-width: 1180px) {
		font-size: 10px;
	}
`;

export const PslContainer = styled.div`
	position: absolute;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
`;

export const PslText = styled.div`
	font-size: ${SIZES.level10};
	font-weight: ${WEIGHT.SemiBold};
	color: ${COLORS.Success};
`;

export const PslDiscount = styled.div`
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.ExtraBold};
	color: ${COLORS.Success};
	margin-top: -5px;
`;
