import { Skeleton, Row, Col, Space } from "antd";
import { Inner, ProductData, TableHeader } from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
	return (
		<Inner>
			<ProductData>
				<Space direction="vertical" size="middle" style={{ display: "flex" }}>
					<Row gutter={GUTTER}>
						<Col span={8}>
							<Skeleton
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 600, marginBottom: -10, marginTop: 30 }}
							/>
							<Skeleton.Input
								active={true}
								block
								style={{ marginBottom: -10 }}
							/>
						</Col>
						<Col span={4}></Col>
					</Row>
					<Row></Row>
					<Row gutter={GUTTER}>
						<Col span={12}>
							<TableHeader></TableHeader>
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 500, marginTop: 5, marginLeft: 10 }}
							/>
						</Col>
						<Col span={4}></Col>
						<Col span={4}>
							<Skeleton.Button
								active={true}
								style={{ width: 35, height: 38, marginTop: 7, marginLeft: -15 }}
							/>
						</Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 500, marginTop: 5, marginLeft: 10 }}
							/>
						</Col>
						<Col span={4}></Col>
						<Col span={4}>
							<Skeleton.Button
								active={true}
								style={{ width: 35, height: 38, marginTop: 7, marginLeft: -15 }}
							/>
						</Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 500, marginTop: 5, marginLeft: 10 }}
							/>
						</Col>
						<Col span={4}></Col>
						<Col span={4}>
							<Skeleton.Button
								active={true}
								style={{ width: 35, height: 38, marginTop: 7, marginLeft: -15 }}
							/>
						</Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 500, marginTop: 5, marginLeft: 10 }}
							/>
						</Col>
						<Col span={4}></Col>
						<Col span={4}>
							<Skeleton.Button
								active={true}
								style={{ width: 35, height: 38, marginTop: 7, marginLeft: -15 }}
							/>
						</Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={4}>
							<Skeleton
								avatar
								active={true}
								paragraph={{ rows: 0 }}
								style={{ width: 500, marginTop: 5, marginLeft: 10 }}
							/>
						</Col>
						<Col span={4}></Col>
						<Col span={4}>
							<Skeleton.Button
								active={true}
								style={{ width: 35, height: 38, marginTop: 7, marginLeft: -15 }}
							/>
						</Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
						<Col span={3}></Col>
					</Row>

					<Row></Row>
					<Row></Row>
				</Space>
				<Row gutter={GUTTER}>
					<Col span={6}>
						<Skeleton.Input active={true} style={{ width: 200 }} size="large" />
					</Col>
					<Col span={6}>
						<Skeleton.Input active={true} style={{ width: 200 }} size="large" />
					</Col>
					<Col span={6}>
						<Skeleton.Input active={true} style={{ width: 200 }} size="large" />
					</Col>
					<Col span={6}>
						<Skeleton.Input active={true} style={{ width: 200 }} size="large" />
					</Col>
				</Row>
			</ProductData>
		</Inner>
	);
}
