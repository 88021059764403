import React, { useEffect, useState } from "react";
import { Checkbox, Table } from "antd";
import { get_points_of_sale } from "../../../api/endpoints/pointsofsale";
import { COLORS } from "../../layout/theme";
import { TableContainer } from "./styles";

export default function PointOfSaleSelector({
	initialValues,
	onChange,
	userId,
}) {
	const [pointsOfSale, setPointsOfSale] = useState(null);

	const columns = [
		{
			title: "",
			dataIndex: "selected",
			render: (value, record, index) => (
				<Checkbox
					checked={value}
					onChange={(e) => handleChange(record.id, e.target.checked)}
				/>
			),
			width: 35,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Punto de Venta</div>,
			dataIndex: "name",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Dirección</div>,
			dataIndex: "address",
		},
	];

	useEffect(() => {
		const fetch_points_of_sale = async () => {
			const res = await get_points_of_sale("", userId);
			setPointsOfSale(
				res.map((d) => ({
					...d,
					key: d.id,
					selected: initialValues?.some((a) => a.id === d.id),
				})) || []
			);
		};

		fetch_points_of_sale();
	}, []);

	useEffect(() => {
		onChange(pointsOfSale);
	}, [pointsOfSale]);

	const handleChange = (id, check) => {
		const _pos = pointsOfSale.map((p) =>
			p.id === id ? { ...p, selected: check } : { ...p }
		);
		setPointsOfSale(_pos);
	};

	if (!pointsOfSale || !initialValues) return <div>Loading...</div>;

	return (
		<>
			{!pointsOfSale ? (
				<div>No hay Puntos de Venta</div>
			) : (
				<TableContainer>
					<Table
						scroll={{ y: "200px" }}
						size="small"
						columns={columns}
						dataSource={pointsOfSale}
						pagination={true}
					/>
				</TableContainer>
			)}
		</>
	);
}
