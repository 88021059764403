import axiosInstance from "../axiosInstance";

const CONTROLLER = "accounts";

const sign_up = async (user) => {
	const response = await axiosInstance.post(`${CONTROLLER}/SignUp`, user);
	return response.data;
};

const send_verification_email = async () => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/SendVerificationEmail`
	);
	return response.data;
};

const send_verification_email_external_user = async (id) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/SendVerificationEmailToNewUser/${id}`
	);
	return response.data;
};

const send_password_reset_email = async (email) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/SendPasswordResetEmail/${email}`
	);
	return response.data;
};

const complete_client_registration = async (data) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/CompleteRegistration`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response;
};

const send_in_progress_validation_email = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/SendInProgressValidationEmail/${data}`
	);
	return response.data;
};

const send_approved_validation_email = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/SendApprovedValidationEmail/${data}`
	);
	return response.data;
};

const send_approved_mobile_request_email = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/SendApprovedMobileRequestEmail/${data}`
	);
	return response.data;
};

const get_cuit_info = async (cuit) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetInfoByCuit/${cuit}`
	);
	return response.data;
};

export {
	sign_up,
	send_verification_email,
	send_verification_email_external_user,
	complete_client_registration,
	send_password_reset_email,
	send_in_progress_validation_email,
	send_approved_validation_email,
	send_approved_mobile_request_email,
	get_cuit_info,
};
