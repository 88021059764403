import React from "react";
import Box from "../box/Box";
import { COLORS, Typo } from "../../../../../layout/theme";
import {
	AntdTable,
	BigChartContainer,
	Container,
	Dashboard1Container,
	FilterContainer,
	IconContainer,
	IconGroupContainer,
} from "./styles";
import { Button, DatePicker, Select } from "antd";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { BiBox, BiDollar } from "react-icons/bi";

export default function Dashboard5({
	dashboard,
	distributors,
	selectDistributor,
	provinces,
	selectProvince,
	laboratories,
	selectLaboratory,
	handleDateChange,
	deleteFilters,
	isLoading,
	selectedLaboratory,
	selectedDistributor,
	selectedProvince,
	rangeDate,
	regionTable,
	selectRegionInfo,
	regionInfoSelector,
}) {
	const columns = [
		{
			title: () => (
				<div
					style={{
						color: COLORS.DarkGrey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Región
				</div>
			),
			width: 350,
			dataIndex: "province",
			render: (value, record) => (
				<div
					style={{ paddingLeft: 20, cursor: "pointer" }}
					onClick={() => {
						selectProvince(value, { label: value });
						window.scrollTo(0, 0);
					}}
				>
					<Typo level={5}>{value}</Typo>
				</div>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.DarkGrey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Ventas
				</div>
			),
			width: 400,
			dataIndex: "totals",
			// defaultSortOrder: "descend",
			// sorter: (a, b) => a.totals.totalSales - b.totals.totalSales,
			render: (value, record) => (
				<div style={{ display: "flex", justifyContent: "center" }}>
					{record.totals.totalSales ? (
						<Typo type="primary" level={3}>
							${" "}
							{numberWithDotAndCommas(
								parseFloat(record.totals.totalSales).toFixed(0)
							)}
						</Typo>
					) : (
						<Typo type="primary" level={3}>
							Sin Datos
						</Typo>
					)}
				</div>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.DarkGrey,
						display: "flex",
						justifyContent: "center",
						gap: 20,
					}}
				>
					Evolución {regionInfoSelector === "SALES" ? "Ventas" : "Pedidos"}
					<IconGroupContainer>
						<IconContainer
							onClick={() => selectRegionInfo("SALES")}
							height={25}
							width={25}
							$active={regionInfoSelector === "SALES"}
						>
							<BiDollar size={18} />
						</IconContainer>
						<IconContainer
							onClick={() => selectRegionInfo("ORDERS")}
							height={25}
							width={25}
							$active={regionInfoSelector === "ORDERS"}
						>
							<BiBox size={18} />
						</IconContainer>
					</IconGroupContainer>
				</div>
			),
			dataIndex: "salesGraph",
			width: 300,
			render: (value, record) => (
				<>
					{regionInfoSelector === "SALES" && (
						<Box
							graphData={record.salesGraph}
							isLoading={isLoading}
							regionInfo={regionInfoSelector}
							type="regionTableBox"
						/>
					)}
					{regionInfoSelector === "ORDERS" && (
						<Box
							graphData={record.ordersGraph}
							isLoading={isLoading}
							regionInfo={regionInfoSelector}
							type="regionTableBox"
						/>
					)}
				</>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.DarkGrey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Total PDV
				</div>
			),
			width: 250,
			dataIndex: "totalPos",
			render: (value, record) => (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<Typo type="secondary" level={4}>
						{value}
					</Typo>
				</div>
			),
		},
	];

	return (
		<Dashboard1Container>
			<FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Fecha (desde/hasta)
					</Typo>
					{rangeDate ? (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							value={rangeDate}
							onChange={(e) => handleDateChange(e)}
						/>
					) : (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							onChange={(e) => handleDateChange(e)}
						/>
					)}
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Droguería
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Droguería"
						optionFilterProp="children"
						value={selectedDistributor}
						// defaultValue={selectedDistributor ?? null}
						onChange={(e, o) => selectDistributor(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 180 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Provincia
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Provincia"
						optionFilterProp="children"
						value={selectedProvince}
						// defaultValue={selectedProvince ?? null}
						onChange={(e, o) => selectProvince(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={provinces}
						style={{ width: 220 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Laboratorio
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Laboratorio"
						optionFilterProp="children"
						value={selectedLaboratory}
						// defaultValue={selectedLaboratory ?? null}
						onChange={(e, o) => selectLaboratory(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={laboratories}
						style={{ width: 180 }}
					/>
				</Container>
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</FilterContainer>
			<BigChartContainer>
				<AntdTable
					dataSource={regionTable}
					loading={isLoading}
					columns={columns}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
			</BigChartContainer>
		</Dashboard1Container>
	);
}
