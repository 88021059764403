import styled from "styled-components/macro";
import { COLORS } from "../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
	height: 300px;
`;
export const ProductData = styled.div`
	flex: 1 1 65%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
`;
export const PublishSettings = styled.div`
	flex: 1 1 35%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	max-height: 160px;
`;
