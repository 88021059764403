import styled from "styled-components";
import { COLORS } from "../../../../../layout/theme";

export const Inner = styled.div`
  display: flex;
  height: 100vh;
  background-color: rgb(243 244 246 / 1);
`;
export const FilterSection = styled.div`
  flex: 0 0 20%;
  padding: 40px 0 40px 40px;
`;
export const ResultsSection = styled.div`
  flex: 1 1 80%;
  padding: 20px 60px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;
export const PlaceholderProduct = styled.div`
  height: 350px;
  background: ${COLORS.SkeletonGrey};
`;
