import { Button } from "antd";
import styled from "styled-components";

export const Section = styled.div`
	background-color: white;
	border-radius: 10px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	min-height: 120px;
	padding: 1rem;
	flex-direction: column;
	gap: 10px;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;
export const LinkButton = styled(Button)`
	height: 100%;
`;
