import { Skeleton } from "antd";
import { Card, Section, Container } from "./styles";

export default function SkeletonCard() {
	return (
		<Card>
			<Skeleton.Image style={{ width: 150, height: 150 }} active />

			<Section>
				<Skeleton.Input active />
				<Container>
					<Section>
						<Skeleton.Input active />
						<Skeleton.Input active />
					</Section>
					<Section>
						<Skeleton.Input active />
						<Skeleton.Input active />
					</Section>
				</Container>
			</Section>
		</Card>
	);
}
