import styled from "styled-components";

export const InnerForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
export const DataSector = styled.div`
  flex: 1 1 70%;
`;
export const LogoSector = styled.div`
  flex: 1 1 30%;
`;
