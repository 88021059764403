import styled from "styled-components";
import { COLORS } from "../../../../layout/theme";

export const DistributorImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 10px;
  background-color: ${COLORS.White};
  border-radius: 20px;
`;

export const DistributorBiller = styled.div`
  width: 98.5%;
  display: flex;
  gap: 20px;
`;

export const DistributorContainer = styled.div`
  flex: 0 0 60%;
  padding: 2rem;
  gap: 10px;
  background-color: ${COLORS.White};
  border-radius: 20px;
`;

export const BillerContainer = styled.div`
  flex: 0 0 40%;
  padding: 2rem;
  gap: 10px;
  background-color: ${COLORS.White};
  border-radius: 20px;
`;

export const BillerAndCodeContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const BillerSelection = styled.div``;

export const BillerCode = styled.div``;
