import React, { useEffect, useState } from "react";
import { TableContainer, AntdTable } from "./styles";
import { COLORS } from "../../../../../layout/theme";
import { get_general_pos_history } from "../../../../../../api/endpoints/pointsofsale";
import moment from "moment";
import { CONSTANTS } from "../../../../../../utils/constants";

export default function HistoryExports() {
	const [data, setData] = useState(false);
	const [loading, setLoading] = useState(false);

	const fetch_history_general_pos = async () => {
		setLoading(true);
		const res = await get_general_pos_history(
			CONSTANTS.GENERAL_POS_HISTORY_EXPORTED
		);
		setData(res.items);
		setLoading(false);
	};

	useEffect(() => {
		fetch_history_general_pos();
	}, []);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Fecha</div>,
			dataIndex: "created",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					{moment(value).format("DD-MM-YYYY HH:ss")}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Usuario</div>,
			dataIndex: "user",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{record.userFirstName + " " + record.userLastName}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cliente</div>,
			dataIndex: "clientName",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
	];

	return (
		<TableContainer>
			<AntdTable
				dataSource={data}
				columns={columns}
				loading={loading}
				pagination={{ pageSize: 25, showSizeChanger: false }}
				rowClassName={(record) => (record.hasChanges ? "hasChanges" : null)}
			/>
		</TableContainer>
	);
}
