import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Image from "../image/Image";
import { get_distributors } from "../../../api/endpoints/distributors";
import { FaImages } from "react-icons/fa";
import { COLORS } from "../../layout/theme";
import { TableContainer } from "./styles";
import AntdCheckbox from "../antd-checkbox/AntdCheckbox";

export default function DistributorsSelectorCoupon({
	initialValues,
	onChange,
	selectedClient,
}) {
	const [distributors, setDistributors] = useState(null);
	const [selectAll, setSelectAll] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetch_distributors = async () => {
			setLoading(true);
			try {
				const data = await get_distributors(selectedClient);
				const initialDistributor =
					data.map((d) => ({
						key: d.id,
						...d,
						checked: initialValues?.find((i) => i.id === d.id)?.name
							? true
							: false,
					})) || [];

				setDistributors(initialDistributor);
				setSelectAll(initialDistributor.every((d) => d.checked));
			} catch (error) {
			} finally {
				setLoading(false);
			}
		};
		fetch_distributors();
	}, []);

	useEffect(() => {
		onChange(distributors?.filter((d) => d.checked));
	}, [distributors]);

	const handleChange = (id, check) => {
		const _dis = distributors.map((d) =>
			d.id === id ? { ...d, checked: check } : { ...d }
		);
		setDistributors(_dis);
		setSelectAll(_dis.every((d) => d.checked));
	};

	const handleSelectAll = (check) => {
		const _dis = distributors.map((d) => ({
			...d,
			checked: check,
		}));
		setDistributors(_dis);
		setSelectAll(check);
	};

	const columns = [
		{
			title: () => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			render: (data) => <Image width={40} height={40} src={data} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Droguería</div>,
			dataIndex: "name",
			key: "name",
		},
		{
			title: () => (
				<AntdCheckbox
					checked={selectAll}
					indeterminate={!selectAll && distributors?.some((d) => d.checked)}
					onChange={(e) => handleSelectAll(e.target.checked)}
				/>
			),
			dataIndex: "",
			render: (value, record, index) => (
				<AntdCheckbox
					onChange={(e) => handleChange(record.id, e.target.checked)}
					checked={record.checked}
				></AntdCheckbox>
			),
		},
	];

	//if (!distributors) return <div>Loading...</div>;

	return (
		<TableContainer>
			<Table
				size="small"
				loading={loading}
				columns={columns}
				dataSource={distributors}
				pagination={false}
				rowKey="key"
			/>
		</TableContainer>
	);
}
