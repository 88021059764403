import { Table } from "antd";
import styled from "styled-components/macro";
import { COLORS } from "../../../../../../../layout/theme";

export const ContainerDiv = styled.div`
	width: 100%;
	height: 650px;
	overflow: auto;
	direction: rtl;

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.SkeletonGrey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}
`;

export const AntdTable = styled(Table)`
	direction: ltr;

	.ant-empty-description {
		visibility: hidden;
	}

	.ant-empty-description::after {
		content: "No hay productos";
		visibility: visible;
		display: block;
		margin-top: -10px;
	}

	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;
