import React, { useState } from "react";
import { Dropdown, Menu, Modal } from "antd";
import { Link } from "react-router-dom";
import LoadingSkeleton from "../loading-sekeleton/LoadingSkeleton";
import { ActionButton, AntdTable, Container } from "./styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdComputer } from "react-icons/md";
import { BiMobile } from "react-icons/bi";
import moment from "moment";
import { FiPhoneCall } from "react-icons/fi";
import { COLORS, Typo } from "../../../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import SaleDetailModal from "../sale-detail-modal/SaleDetailModal";

export default function SalesReportTable({ data, details, ...props }) {
	const [key, setKey] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalInfo, setModalInfo] = useState(null);

	const columns = [
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						N° TRF
					</div>
				);
			},
			dataIndex: "orderNumId",
			render: (f, r) => (
				<Container
					onMouseOver={() => setKey(r)}
					onClick={() => handleDetail(r)}
					style={{ cursor: "pointer" }}
				>
					<Typo type="terciary" fontWeight={600} fontSize={14}>
						{f}
					</Typo>
				</Container>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Origen
					</div>
				);
			},
			dataIndex: "typeOfSale",
			render: (f, r) => (
				<>
					<Typo
						type="muted"
						fontWeight={600}
						fontSize={14}
						//onClick={() => showModal(r)}
					>
						{f === "WEB" ? (
							<MdComputer />
						) : f === "MOBILE" ? (
							<>
								<BiMobile /> App
							</>
						) : (
							<FiPhoneCall />
						)}
					</Typo>
				</>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Usuario
					</div>
				);
			},
			dataIndex: "sellerFirstName",
			render: (f, r) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f} {r.sellerLastName}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Fecha
					</div>
				);
			},
			dataIndex: "created",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{moment(f).format("DD/MM/YYYY")}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Farmacia
					</div>
				);
			},
			dataIndex: "pointOfSaleName",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Dirección
					</div>
				);
			},
			dataIndex: "pointOfSaleAddress",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Provincia
					</div>
				);
			},
			dataIndex: "provinceName",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Items
					</div>
				);
			},
			dataIndex: "qtyItems",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Un.
					</div>
				);
			},
			dataIndex: "qtyUnits",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Droguería
					</div>
				);
			},
			dataIndex: "distributorName",
			render: (f, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Total
					</div>
				);
			},
			dataIndex: "total",
			width: 130,
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					$ {numberWithDotAndCommas(f)}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Estado
					</div>
				);
			},
			dataIndex: "orderState",
			render: (fila, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila === "Pending" && "Pendiente"}
					{fila === "Active" && "Activa"}
					{fila === "Sended" && "Enviada a Droguería"}
					{fila === "Canceled" && "Cancelada"}
				</Typo>
			),
		},
	];

	const handleDetail = async (r) => {
		setModalInfo(details?.filter((o) => o.orderNumId === r.orderNumId));
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	if (!data) return <LoadingSkeleton />;

	return (
		<>
			<AntdTable columns={columns} dataSource={data} {...props} />
			<Modal
				title={
					<div style={{ height: 30 }}>
						<Typo level={6} type="muted">
							Detalle orden: {key?.orderNumId}
						</Typo>
					</div>
				}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				width={800}
				centered={true}
				footer={null}
				destroyOnClose
			>
				<SaleDetailModal modalInfo={modalInfo} />
			</Modal>
		</>
	);
}
