import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
	Form,
	Input,
	Select,
	Row,
	Col,
	Space,
	message,
	Modal,
	Button,
} from "antd";
import {
	AddMailContainer,
	AntdTable,
	ColumnSection,
	Container,
	ContainerColumns,
	ContainerMails,
	FileStructureBackground,
	Header,
	InfoContainer,
	InnerForm,
	MailBackground,
	ProductData,
	PublishSettings,
	ResetButton,
	RightContainer,
	SectionTitle,
	TopContainer,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import Main from "../../../../../layout/main/Main";
import COLORS from "../../../../../layout/theme/colors/Colors";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";

import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import {
	get_megaDistributor_by_id,
	update_megaDistributor,
} from "../../../../../../api/endpoints/megaDistributors";
import UploadFileImage from "../../../../../common/upload-file-with-image/UploadFileImage";
import { Typo } from "../../../../../layout/theme";
import {
	CheckOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import { CONSTANTS } from "../../../../../../utils/constants";
import DistributorsCode from "../../../../../common/distributors-code/DistributorsCode";
import MegaDistributorsCode from "../../../../../common/megaDistributors-code/MegaDistributorsCode";
import { FaPlus } from "react-icons/fa";
import AddCopyMegaDistributorEmail from "../../../../../common/add-copy-mega-distributor-email/AddCopyMegaDistributorEmail";
import {
	delete_mega_distributor_email,
	get_emails_by_megaDistributorId,
	update_mega_distributor_email,
} from "../../../../../../api/endpoints/megaDistributorEmails";

const { Option } = Select;
const GUTTER = 10;

const states = [
	{
		id: "ACTIVE",
		name: "Activo",
	},
	{
		id: "DELETED",
		name: "Inactivo",
	},
];

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function UpdateMegaDistributor() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [initialValues, setInitialValues] = useState(null);
	const [selectedColumns, setSelectedColumns] = useState(null);
	const [availableColumns, setAvailableColumns] = useState(null);
	const [availableTypesOfFiles, setAvailableTypesOfFiles] = useState(null);
	const [availableSendTypes, setAvailableSendTypes] = useState(null);
	const [distributors, setDistributors] = useState(null);
	const [copyModalOpen, setCopyModalOpen] = useState(false);
	const [flag, setFlag] = useState(false);
	const [key, setKey] = useState(null);
	const [mailID, setMailID] = useState(null);
	const [show, setShow] = useState(false);
	const [newEmail, setNewEmail] = useState(null);
	const [copyEmails, setCopyEmails] = useState(null);

	useEffect(() => {
		const fetch_megaDistributor_by_id = async () => {
			const data = await get_megaDistributor_by_id(id);
			const formData = {
				title: data.name,
				state: data.isActive ? "ACTIVE" : "DELETED",
				email: data.email,
				firstName: data.firstName,
				lastName: data.lastName,
				send: JSON.parse(data.typeOfArchive)?.send ?? "",
				format: JSON.parse(data.typeOfArchive)?.format ?? "",
				server: JSON.parse(data.typeOfArchive)?.server ?? "",
				port: JSON.parse(data.typeOfArchive)?.port ?? "",
				user: JSON.parse(data.typeOfArchive)?.user ?? "",
				password: JSON.parse(data.typeOfArchive)?.password ?? "",
				folder: JSON.parse(data.typeOfArchive)?.folder ?? "",
				columnsToSend: data.columnsToSend
					? data.columnsToSend.split(";").map((colum) => ({
							value: colum,
							label: CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS.find(
								(col) => col.value === colum
							)?.label,
					  }))
					: [],
				distributors: data.distributorCodes?.map((i) => ({
					key: i.distributorId,
					id: i.distributorId,
					code: i.code,
					officeCode: i.distributorOfficeCode,
				})),
			};

			setImageUrl(data.photoUrl);

			setSelectedColumns(
				data.columnsToSend
					? data.columnsToSend.split(";").map((colum) => ({
							value: colum,
							label: CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS.find(
								(col) => col.value === colum
							)?.label,
					  }))
					: new Array(CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS.length).fill("")
			);

			setAvailableColumns(
				data.columnsToSend ? [] : CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS
			);

			setAvailableTypesOfFiles(CONSTANTS.TYPE_OF_FILE);

			setAvailableSendTypes(CONSTANTS.TYPE_OF_SEND);

			setInitialValues(formData);
		};

		if (id) {
			fetch_megaDistributor_by_id();
		}
	}, []);

	const fetch_copy_emails = async () => {
		const res = await get_emails_by_megaDistributorId(id);
		setCopyEmails(res?.copyEmails);
	};

	useEffect(() => {
		if (id) {
			fetch_copy_emails();
		}
	}, [flag]);

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Nombre de Contacto</div>
			),
			dataIndex: "name",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>@Mail</div>,
			dataIndex: "mail",
			render: (value, r) => (
				<div
					onClick={() => handleModifyMail(r.id)}
					onMouseOver={() => setKey(r)}
					style={{ cursor: "pointer" }}
				>
					{mailID === r.id && show ? (
						<Container onMouseLeave={() => setShow(false)}>
							<Input
								type="mail"
								style={{}}
								min={0}
								max={4}
								step={1}
								defaultValue={value}
								onChange={(e) => setNewEmail(e.target.value)}
							/>
							<Button
								onClick={() => handleEmailSave(r.id, newEmail, r.name)}
								icon={<CheckOutlined />}
							/>
							<Button
								onClick={() => handleEmailDelete(r.id)}
								icon={<DeleteOutlined style={{ color: COLORS.Danger }} />}
							/>
						</Container>
					) : (
						<Typo type="muted" fontWeight={600} fontSize={14}>
							{value}
						</Typo>
					)}
				</div>
			),
		},
	];

	const handleModifyMail = (id) => {
		setMailID(id);
		setShow(true);
	};

	const handleEmailSave = async (id, mail, name) => {
		const updateData = { name: name, mail: mail, id: id };
		await update_mega_distributor_email(key.id, updateData);
		setFlag((prev) => !prev);
		setShow(false);
	};

	const handleEmailDelete = async (id) => {
		await delete_mega_distributor_email(id);
		setFlag((prev) => !prev);
		setShow(false);
	};

	const onFinish = async (values) => {
		try {
			if (!selectedColumns?.some((column) => column.value !== "")) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe existir una estructura de archivo para poder activar el Distribuidor"
				);
				setSubmitting(false);
				return;
			}

			setSubmitting(true);

			const newDistributor = distributors.map((d) =>
				d.checked === true
					? d.code === undefined || d.code.trim() === ""
						? { ...d, code: null, officeCode: null }
						: { ...d, code: d.code.trim(), officeCode: d.officeCode.trim() }
					: { ...d, code: null, officeCode: null }
			);

			const nonEmptyColumns = selectedColumns.filter(
				(column) => column.value !== ""
			);
			const columnsToSend = nonEmptyColumns
				.map((colum) => colum.value)
				.join(";");

			const sendInfo = {
				send: values.send,
				format: values.format,
				server: values.server ?? "",
				port: values.port ?? "",
				user: values.user ?? "",
				password: values.password ?? "",
				folder: values.folder ?? "",
			};

			const postData = {
				id: id,
				state: values.state,
				title: values.title,
				firstName: values.firstName,
				lastName: values.lastName,
				email: values.email,
				columnsToSend: columnsToSend,
				typeOfArchive: JSON.stringify(sendInfo),
				distributors: newDistributor.map((d, i) => {
					return {
						id: d.id,
						code: d.code,
						officeCode: d.officeCode,
						key: `k_${i}`,
					};
				}),
			};

			const distributorFormData = new FormData();

			distributorFormData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);

			distributorFormData.append("data", JSON.stringify(postData));

			await update_megaDistributor(id, distributorFormData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Distribuidor editado con éxito",
				"El distribuidor se editó con éxito"
			);
			navigate(`/admin/distributorsIndex`);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al editar el distribuidor",
				"Un error ocurrió al intentar editar el distribuidor, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	if (!id) navigate("/admin/distributorsIndex");

	if (!initialValues) return <LoadingSkeleton />;

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/distributorsIndex");
			},
		});
	};

	const handleColumnChange = (index, value, obj) => {
		const updatedSelectedColumns = [...selectedColumns];
		updatedSelectedColumns[index] = { value: value, label: obj.children };

		setSelectedColumns(updatedSelectedColumns);
		const updatedAvailableColumns = availableColumns.filter(
			(column) => column.value !== value
		);

		setAvailableColumns(updatedAvailableColumns);
	};

	const handleReset = () => {
		const initialValues = new Array(
			CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS.length
		).fill({ value: "", label: "" });
		setSelectedColumns(initialValues);

		setAvailableColumns(CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS);

		form.resetFields();
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/distributorsIndex">
							Distribuidores y Laboratorios
						</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Editar distribuidor
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Form
					form={form}
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
					initialValues={initialValues}
				>
					<InnerForm>
						<TopContainer>
							<ProductData>
								<InfoContainer>
									<Row gutter={GUTTER}>
										<Col span={6}>
											<Form.Item
												name="file"
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Logo del distribuidor
													</label>
												}
											>
												<UploadFileImage
													onChange={handleImageChange}
													urlImage={imageUrl}
													imageHeight="100%"
													imageWidth="100%"
												/>
											</Form.Item>
											{/* {imageUrl && <PreviewImage src={imageUrl} alt="avatar" />} */}
										</Col>
										<Col span={18}>
											<Row gutter={GUTTER}>
												<Col span={6}>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Distribuidor
															</label>
														}
														name="title"
														rules={[
															{
																required: true,
																message: "Este campo es obligatorio",
															},
															{
																pattern: new RegExp(
																	/^(?![-.\(\)\s]*$)[-.\(\)a-zA-Z0-9\s]*$/
																),
																message:
																	"No se aceptan caracteres especiales. Solo letras y numeros",
															},
														]}
													>
														<Input placeholder="Nombre" />
													</Form.Item>
												</Col>
												<Col span={6}>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Nombre contacto
															</label>
														}
														name="firstName"
														rules={[
															{
																required: true,
																message: "Este campo es obligatorio",
															},
															{
																pattern: new RegExp(
																	/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
																),
																message:
																	"No se aceptan caracteres especiales. Solo letras y numeros",
															},
														]}
													>
														<Input placeholder="Nombre" />
													</Form.Item>
												</Col>
												<Col span={6}>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Apellido contacto
															</label>
														}
														name="lastName"
														rules={[
															{
																required: true,
																message: "Este campo es obligatorio",
															},
															{
																pattern: new RegExp(
																	/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/
																),
																message:
																	"No se aceptan caracteres especiales. Solo letras y numeros",
															},
														]}
													>
														<Input placeholder="Nombre" />
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col span={18}>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Correo electrónico
															</label>
														}
														name="email"
														rules={[
															{
																required: true,
																message: "Este campo es obligatorio",
															},
														]}
													>
														<Input placeholder="Nombre" />
													</Form.Item>
												</Col>
											</Row>
										</Col>
									</Row>
								</InfoContainer>
								<FileStructureBackground>
									<Typo level={6} type="primary">
										Información para envío de archivo
									</Typo>
									<Row gutter={GUTTER}>
										<Col span={12}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Tipo de envio
													</label>
												}
												name="send"
												rules={[
													{
														required: true,
														message: "Por favor, seleccione el tipo de envío",
													},
												]}
											>
												<Select allowClear={true} placeholder="Tipo de envio">
													{availableSendTypes.map((item) => (
														<Option key={item} value={item}>
															{item}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Formato
													</label>
												}
												name="format"
												rules={[
													{
														required: true,
														message:
															"Por favor, seleccione el formato de archivo",
													},
												]}
											>
												<Select
													allowClear={true}
													placeholder="Formato de archivo"
												>
													{availableTypesOfFiles.map((item) => (
														<Option key={item} value={item}>
															{item}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={GUTTER}>
										<Col span={8}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Servidor
													</label>
												}
												name="server"
												rules={[
													({ getFieldValue }) => ({
														validator(_, value) {
															const sendValue = getFieldValue("send");

															if (sendValue === "sftp") {
																if (!value) {
																	return Promise.reject(
																		"Por favor ingrese un servidor para envio sftp"
																	);
																}
															}

															return Promise.resolve();
														},
													}),
												]}
											>
												<Input placeholder="Servidor" />
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Puerto
													</label>
												}
												name="port"
												rules={[
													({ getFieldValue }) => ({
														validator(_, value) {
															const sendValue = getFieldValue("send");

															if (sendValue === "sftp") {
																if (!value) {
																	return Promise.reject(
																		"Por favor ingrese un puerto para envio sftp"
																	);
																}
															}

															return Promise.resolve();
														},
													}),
												]}
											>
												<Input placeholder="Puerto" />
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Carpeta
													</label>
												}
												name="folder"
												rules={[
													({ getFieldValue }) => ({
														validator(_, value) {
															const sendValue = getFieldValue("send");

															if (sendValue === "sftp") {
																if (!value) {
																	return Promise.reject(
																		"Por favor ingrese una carpeta para envio sftp"
																	);
																}
															}

															return Promise.resolve();
														},
													}),
												]}
											>
												<Input placeholder="Carpeta" />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={GUTTER}>
										<Col span={12}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Usuario
													</label>
												}
												name="user"
												rules={[
													({ getFieldValue }) => ({
														validator(_, value) {
															const sendValue = getFieldValue("send");

															if (sendValue === "sftp") {
																if (!value) {
																	return Promise.reject(
																		"Por favor ingrese un usuario para envio sftp"
																	);
																}
															}

															return Promise.resolve();
														},
													}),
												]}
											>
												<Input placeholder="Usuario" />
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Contraseña
													</label>
												}
												name="password"
												rules={[
													({ getFieldValue }) => ({
														validator(_, value) {
															const sendValue = getFieldValue("send");

															if (sendValue === "sftp") {
																if (!value) {
																	return Promise.reject(
																		"Por favor ingrese una contraseña para envio sftp"
																	);
																}
															}

															return Promise.resolve();
														},
													}),
												]}
											>
												<Input placeholder="Contraseña" />
											</Form.Item>
										</Col>
									</Row>
								</FileStructureBackground>
							</ProductData>
							<RightContainer>
								<PublishSettings>
									<Space
										direction="vertical"
										size="middle"
										style={{ display: "flex" }}
									>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Estado de la droguería
												</label>
											}
											name="state"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Select
												allowClear={true}
												placeholder="Estado de la droguería"
											>
												{states.map((item) => (
													<Option key={item.id} value={item.id}>
														{item.name}
													</Option>
												))}
											</Select>
										</Form.Item>

										<Form.Item>
											<SignInTemplate.AntdButton
												type="Primary"
												color="white"
												bg="Primary"
												htmlType="submit"
												loading={submitting}
												block
											>
												Guardar Cambios
											</SignInTemplate.AntdButton>
										</Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											loading={submitting}
											onClick={handleBack}
											block
										>
											<Typo type="danger" level={6}>
												Volver sin guardar
											</Typo>
										</SignInTemplate.AntdButton>
									</Space>
								</PublishSettings>
								<MailBackground>
									<Row gutter={GUTTER}>
										<Col span={24}>
											<Form.Item
												name="mailList"
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
															display: "flex",
															alignItems: "center",
															gap: "30px",
														}}
													>
														Copia de Pedidos{" "}
														<AddMailContainer>
															<Button
																onClick={() => setCopyModalOpen(true)}
																style={{
																	borderRadius: "10px",
																}}
															>
																<Typo type="primary">
																	<FaPlus />
																</Typo>
															</Button>
															<Typo level={7} type="primary">
																Agregar mail
															</Typo>
														</AddMailContainer>
													</label>
												}
											>
												{copyEmails && (
													<ContainerMails>
														<AntdTable
															columns={columns}
															dataSource={copyEmails}
															pagination={false}
														></AntdTable>
													</ContainerMails>
												)}
											</Form.Item>

											<Modal
												title={
													<div style={{ height: 30 }}>
														<Typo level={6} type="secondary">
															Agregar Destinos de Pedidos
														</Typo>
													</div>
												}
												open={copyModalOpen}
												onCancel={() => setCopyModalOpen(false)}
												width={900}
												centered={true}
												footer={null}
											>
												<AddCopyMegaDistributorEmail
													distributorId={id}
													setState={setCopyModalOpen}
													updateData={() => setFlag((prev) => !prev)}
												/>
											</Modal>
										</Col>
									</Row>
								</MailBackground>
							</RightContainer>
						</TopContainer>
						<FileStructureBackground>
							<SectionTitle>
								<Container>
									<Typo level={6} type="primary">
										Estructura de archivo
									</Typo>
									<ResetButton type="button" onClick={handleReset}>
										Reiniciar selecciones
									</ResetButton>
								</Container>
							</SectionTitle>
							<ContainerColumns>
								{CONSTANTS.MEGA_DISTRIBUTORS_COLUMNS?.map((column, index) => (
									<Form.Item
										key={index}
										label={
											<Typo type="primary" level={6}>
												Columna {index + 1}
											</Typo>
										}
										initialValue={selectedColumns[index] || ""}
									>
										<Select
											onChange={(value, obj) =>
												handleColumnChange(index, value, obj)
											}
											value={selectedColumns[index]?.label}
										>
											{availableColumns?.map((availColumn) => (
												<Option
													key={availColumn.value}
													value={availColumn.value}
												>
													{availColumn.label}
												</Option>
											))}
										</Select>
									</Form.Item>
								))}
							</ContainerColumns>
						</FileStructureBackground>
						<FileStructureBackground>
							<Typo level={5} type="darkmuted">
								Ingrese el código interno del distribuidor para cada droguería
							</Typo>
							<MegaDistributorsCode
								initialValues={initialValues?.distributors}
								onChange={setDistributors}
							/>
						</FileStructureBackground>
					</InnerForm>
				</Form>
			</Main.Body>
		</Main>
	);
}
