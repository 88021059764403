import styled from "styled-components";

export const Inner = styled.div`
	display: flex;
	flex: 1;
`;

export const PreviewImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: contain;
`;
