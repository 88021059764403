function checkSellerProgressPercentage(userdata) {
	let userProgress = 0;
	switch (userdata?.guideProgress) {
		case 0:
			userProgress = 0;
			break;
		case 1:
			userProgress = 12;
			break;
		case 2:
			userProgress = 24;
			break;
		case 3:
			userProgress = 36;
			break;
		case 4:
			userProgress = 48;
			break;
		case 5:
			userProgress = 64;
			break;
		case 6:
			userProgress = 76;
			break;
		case 7:
			userProgress = 88;
			break;
		case 8:
			userProgress = 100;
			break;
		default:
			break;
	}

	if (userProgress > 99) {
		return 100;
	}
	return userProgress;
}

export { checkSellerProgressPercentage };
