import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Form, Input, Row, Col, Space, Modal } from "antd";
import { InnerForm, ProductData, PublishSettings } from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import COLORS from "../../../../layout/theme/colors/Colors";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Typo } from "../../../../layout/theme";
import {
	get_access_type_by_id,
	update_access_type,
} from "../../../../../api/endpoints/accessTypes";

const GUTTER = 10;

export default function UpdateAccessType() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const fetch_access_type_by_id = async () => {
			const data = await get_access_type_by_id(id);

			const accessType = {
				title: data.name,
				description: data.description,
			};

			setInitialValues(accessType);
		};

		if (id) {
			fetch_access_type_by_id();
		}
	}, []);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const putData = {
				id,
				title: values.title,
				description: values.description,
			};

			await update_access_type(id, putData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Tipo de Acceso actualizado con éxito",
				"El Tipo de Acceso se actualizó con éxito"
			);
			navigate(`/admin/administrationAccess`);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al actualizar el Tipo de Acceso",
				"Un error ocurrio al intentar actualizar el Tipo de Acceso, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/administrationAccess");
			},
		});
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/administrationAccess">Tipos de Acceso</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Editar Tipo de Acceso
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<ProductData>
								<Row gutter={GUTTER}>
									<Col span={22}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Nombre
												</label>
											}
											name="title"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(
														/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
													),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input placeholder="Nombre" />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={GUTTER}>
									<Col span={22}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Descripcion
												</label>
											}
											name="description"
											rules={[]}
										>
											<Input placeholder="Descripcion" />
										</Form.Item>
									</Col>
								</Row>
							</ProductData>
							<PublishSettings>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
											block
										>
											Guardar Cambios
										</SignInTemplate.AntdButton>
									</Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										loading={submitting}
										onClick={handleBack}
										block
									>
										<Typo type="danger" level={6}>
											Volver sin guardar
										</Typo>
									</SignInTemplate.AntdButton>
								</Space>
							</PublishSettings>
						</InnerForm>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
