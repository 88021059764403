import React, { useEffect, useState } from "react";
import { Input } from "antd";
import Image from "../image/Image";
import { get_client_by_id } from "../../../api/endpoints/clients";
import { FaImages } from "react-icons/fa";
import { COLORS } from "../../layout/theme";
import { AntdTable } from "./styles";

export default function DistributorCodeLabSellers({
	initialValues,
	onChange,
	clientId,
	flag,
}) {
	const [distributors, setDistributors] = useState(null);

	useEffect(() => {
		const fetch_distributors = async () => {
			const res = await get_client_by_id(clientId);
			setDistributors(
				initialValues
					? res.distributors
							.map((d) => ({
								key: d.id,
								...d,
							}))
							.filter((p) => p.code !== null)
							.map((d) => ({
								...d,
								code: initialValues?.find((i) => i.id === d.id)?.code,
								disabled: !!initialValues?.find((i) => i.id === d.id)?.code
									? initialValues?.find((i) => i.id === d.id)?.code === "si" ||
									  initialValues?.find((i) => i.id === d.id)?.code === ""
										? false
										: true
									: false,
							}))
					: res.distributors
							.map((d) => ({
								key: d.id,
								...d,
							}))
							.filter((p) => p.code !== null)
			);
		};
		if (clientId) fetch_distributors();
	}, [clientId, flag]);

	useEffect(() => {
		onChange(distributors);
	}, [distributors]);

	const handleChange = (text, record, index, value) => {
		let items = [...distributors];
		items[index] = {
			...distributors[index],
			code: value,
		};
		setDistributors(items);
	};

	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			render: (data) => <Image width={40} height={40} src={data} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Droguería</div>,
			dataIndex: "name",
			key: "name",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Codigo</div>,
			dataIndex: "code",
			key: "code",
			render: (text, record, index) => (
				<Input
					value={text === "si" ? "" : text}
					onChange={(e) => handleChange(text, record, index, e.target.value)}
					defaultValue={text === "si" ? "" : text}
					disabled={record.disabled}
				/>
			),
		},
	];

	if (!distributors) return <div>Loading...</div>;

	return (
		<AntdTable
			size="small"
			columns={columns}
			dataSource={distributors}
			pagination={false}
		/>
	);
}
