import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { get_history_prices_detail } from "../../../../../../api/endpoints/products";
import Main from "../../../../../layout/main/Main";
import { COLORS } from "../../../../../layout/theme";
import { AntdTable } from "./styles";

export default function PriceHistoryDetail() {
  const { id } = useParams();
  const [data, setData] = useState(null);

  const fetch_history_detail = async () => {
    const res = await get_history_prices_detail({ id: id });
    setData(res);
  };
  useEffect(() => {
    fetch_history_detail();
  }, []);

  const columns = [
    {
      title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
      dataIndex: "title",
      render: (value, record) => (
        <div
          style={{
            color: record.hasChanges ? COLORS.White : COLORS.Primary,

            fontWeight: "600",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: () => <div style={{ color: COLORS.White }}>Ean13</div>,
      dataIndex: "ean13",
      render: (value, record) => (
        <div
          style={{
            color: record.hasChanges ? COLORS.White : COLORS.Grey,
            fontWeight: "600",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: () => <div style={{ color: COLORS.White }}>Precio</div>,
      dataIndex: "price",
      render: (value, record) => (
        <div
          style={{
            color: record.hasChanges ? COLORS.White : COLORS.Grey,
            fontWeight: "600",
          }}
        >
          ${value}
        </div>
      ),
    },
    {
      title: () => <div style={{ color: COLORS.White }}>Precio Oferta</div>,
      dataIndex: "offerPrice",
      render: (value, record) => (
        <div
          style={{
            color: record.hasChanges ? COLORS.White : COLORS.Grey,
            fontWeight: "600",
          }}
        >
          ${value}
        </div>
      ),
    },
    {
      title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
      dataIndex: "discount",
      render: (value, record) => (
        <div
          style={{
            color: record.hasChanges ? COLORS.White : COLORS.Grey,
            fontWeight: "600",
          }}
        >
          %{value}
        </div>
      ),
    },
  ];

  return (
    <Main>
      <Main.Header>
        <Main.Breadcrumb>
          <Main.BreadcrumbItem>
            <Link to="/">Home</Link>
          </Main.BreadcrumbItem>
          <Main.BreadcrumbSeparator />
          <Main.BreadcrumbItem>
            <Link to="/admin/prices">Lista de Precios</Link>
          </Main.BreadcrumbItem>
          <Main.BreadcrumbSeparator />
          <Main.ActiveBreadcrumbItem>Detalle</Main.ActiveBreadcrumbItem>
        </Main.Breadcrumb>
      </Main.Header>
      <Main.Body>
        <AntdTable
          dataSource={data?.items}
          columns={columns}
          pagination={{ pageSize: 25, showSizeChanger: false }}
        />
      </Main.Body>
    </Main>
  );
}
