import styled from "styled-components/macro";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
  display: flex;
  gap: 50px;
  align-content: center;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 50px;
`;
export const ProductData = styled.div`
  flex: 1 1 65%;
`;
export const PublishSettings = styled.div`
  flex: 1 1 35%;
`;

export const Container = styled.div`
  padding: 2rem;
`;

export const PublishContainer = styled.div`
  height: 200px;
  width: 400px;
  background-color: ${COLORS.SkeletonGrey};
  padding: 2rem;
  margin-top: 10px;
  margin-right: 60px;
`;
