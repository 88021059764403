import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import React, { useState } from "react";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import { Typo } from "../../../../../layout/theme";
import { Inner, PreviewImage } from "./styles";

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function GeneralInformation({ form }) {
	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [file, setFile] = useState(null);

	const handleChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			setLoading(true);

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
					setFile(info.fileList[info.fileList.length - 1]);
				}
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const uploadButton = (
		<Button loading={loading} icon={<UploadOutlined />}>
			Click to Upload
		</Button>
	);
	return (
		<Inner>
			<Typo level={4} type="primary">
				Logo de la cuenta (opcional)
			</Typo>
			<SignInTemplate.Break height="15px" />
			<SignInTemplate.AntdFormItem name="file">
				<Upload
					multiple={false}
					className="avatar-uploader"
					showUploadList={false}
					beforeUpload={() => false}
					action={null}
					onChange={handleChange}
				>
					{uploadButton}
				</Upload>
			</SignInTemplate.AntdFormItem>

			{imageUrl && <PreviewImage src={imageUrl} alt="avatar" />}
		</Inner>
	);
}
