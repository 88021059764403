import styled from "styled-components/macro";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
  display: flex;
  gap: 50px;
  height: 300px;
`;
export const ProductData = styled.div`
  flex: 1 1 65%;
`;
export const PublishSettings = styled.div`
  flex: 1 1 35%;
`;

export const Container = styled.div`
  padding: 2rem;
`;

export const PublishContainer = styled.div`
  height: 100px;
  width: 400px;
  background-color: #bebebe33;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;
