import { Skeleton, Row, Col } from "antd";
import { Inner } from "./styles";

const GUTTER = 20;

export default function LoadingHeader() {
	return (
		<Inner>
			<Row gutter={GUTTER}>
				<Col span={7}>
					<Skeleton.Input active size="default" block />
				</Col>
				<Col span={4}></Col>
				<Col span={9}></Col>
			</Row>
		</Inner>
	);
}
