import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { get_market_orders_dapper } from "../../../../api/endpoints/orders";
import { Button, DatePicker, Select, Spin } from "antd";
import {
	AntdInput,
	CompaginationDiv,
	FilterContainer,
	FilterSection,
} from "./styles";
import OrdersTable from "./components/orders-table/OrdersTable";
import { useFilter } from "../../../../contexts/filterContext";
import { CONSTANTS } from "../../../../utils/constants";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useAuth } from "../../../../contexts/authContext";

export default function MarketOrders() {
	const {
		marketOrdersLaboratoryId,
		setMarketOrdersLaboratoryId,
		marketOrdersDistributorId,
		setMarketOrdersDistributorId,
		marketOrdersPharmacyId,
		setMarketOrdersPharmacyId,
		marketOrdersSellerId,
		setMarketOrdersSellerId,
		marketOrdersProvinceName,
		setMarketOrdersProvinceName,
		marketOrdersTypeOfSale,
		setMarketOrdersTypeOfSale,
		marketOrdersOrderState,
		setMarketOrdersOrderState,
		marketOrdersOrdersFrom,
		setMarketOrdersOrdersFrom,
		marketOrdersOrdersTo,
		setMarketOrdersOrdersTo,
		marketOrdersOrdersRange,
		setMarketOrdersOrdersRange,
	} = useFilter();
	const { ordersQty, setOrdersQty } = useAuth();
	const [data, setData] = useState(null);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(CONSTANTS.PAGE_LARGE_SIZE);
	const [actualPage, setActualPage] = useState(1);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [searchInfo, setSearchInfo] = useState(null);
	const [laboratories, setLaboratories] = useState(null);
	const [pharmacies, setPharmacies] = useState(null);
	const [distributors, setDistributors] = useState(null);
	const [users, setUsers] = useState(null);
	const [provinces, setProvinces] = useState(null);
	const [orderTypes, setOrderTypes] = useState(null);
	const [orderStates, setOrderStates] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const fetch_orders = async (skipValue) => {
		setIsLoading(true);
		try {
			const res = await get_market_orders_dapper(
				marketOrdersLaboratoryId,
				marketOrdersDistributorId,
				marketOrdersPharmacyId,
				marketOrdersSellerId,
				marketOrdersProvinceName,
				marketOrdersTypeOfSale,
				marketOrdersOrderState,
				marketOrdersOrdersFrom,
				marketOrdersOrdersTo,
				skipValue,
				page
			);
			const resTypesOfOrders = JSON.parse(res?.paginationInfo?.typesOfOrders);
			const resStatesOfOrders = JSON.parse(res?.paginationInfo?.statesOfOrders);
			const resProvinceOfOrders = JSON.parse(
				res?.paginationInfo?.provinceOfOrders
			);
			const resPharmacyOfOrders = JSON.parse(
				res?.paginationInfo?.pharmacyOfOrders
			);

			setData(res?.orders);

			setSearchInfo(res?.paginationInfo);
			setPaginationInputValue(res?.paginationInfo?.actualPage?.toString());
			setUsers(
				res?.users?.map((r) => ({
					value: r.sellerId,
					label: r.sellerFirstName + " " + r.sellerLastName,
				}))
			);
			setOrderTypes(
				resTypesOfOrders?.map((r) => ({
					value: r.TypeOfSale,
					label: r.TypeOfSale,
				}))
			);
			setOrderStates(
				resStatesOfOrders?.map((r) => ({
					value: r.OrderState,
					label: selectStateToShow(r.OrderState),
				}))
			);
			setProvinces(
				resProvinceOfOrders?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);
			setPharmacies(
				resPharmacyOfOrders?.map((r) => ({
					value: r.PharmacyId,
					label: r.PharmacyName,
				}))
			);
			setDistributors(
				res?.distributors?.map((r) => ({
					value: r.distributorId,
					label: r.distributorName,
				}))
			);

			setHeaderInfo({
				totalActive: res?.paginationInfo.totalActive,
				totalPending: res?.paginationInfo.totalPending,
				totalSended: res?.paginationInfo.totalSended,
				totalCanceled: res?.paginationInfo.totalCanceled,
			});

			if (res && res?.paginationInfo.totalActive !== ordersQty) {
				setOrdersQty(res?.paginationInfo.totalActive);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setActualPage(1);
		setPage(CONSTANTS.PAGE_LARGE_SIZE);
		fetch_orders(1);
	}, [
		marketOrdersLaboratoryId,
		marketOrdersDistributorId,
		marketOrdersPharmacyId,
		marketOrdersSellerId,
		marketOrdersProvinceName,
		marketOrdersTypeOfSale,
		marketOrdersOrderState,
		marketOrdersOrdersFrom,
		marketOrdersOrdersTo,
	]);

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendientes";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activas";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviadas";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Canceladas";

			default:
				return;
		}
	};

	const handleDeleteFilters = () => {
		setMarketOrdersLaboratoryId(null);
		setMarketOrdersDistributorId(null);
		setMarketOrdersPharmacyId(null);
		setMarketOrdersSellerId(null);
		setMarketOrdersProvinceName(null);
		setMarketOrdersTypeOfSale(null);
		setMarketOrdersOrderState(null);
		setMarketOrdersOrdersFrom(null);
		setMarketOrdersOrdersTo(null);
		setMarketOrdersOrdersRange(null);
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_orders(cp);
			setActualPage(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_orders(cp);
			setActualPage(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_orders(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	const handleSelectedDate = (dateRange) => {
		const firstDate = moment(dateRange[0]).format("MM-DD-YYYY").toString();
		const lastDate = moment(dateRange[1]).format("MM-DD-YYYY").toString();
		setMarketOrdersOrdersFrom(firstDate);
		setMarketOrdersOrdersTo(lastDate);
		setMarketOrdersOrdersRange([dateRange[0], dateRange[1]]);
	};

	const selectLaboratory = (e) => {
		setMarketOrdersLaboratoryId(e);
	};
	const selectDistributor = (e) => {
		setMarketOrdersDistributorId(e);
	};
	const selectPharmacy = (e) => {
		setMarketOrdersPharmacyId(e);
	};
	const selectProvince = (e) => {
		setMarketOrdersProvinceName(e);
	};

	const selectUser = (e) => {
		setMarketOrdersSellerId(e);
	};

	const selectTypeOfSale = (e) => {
		setMarketOrdersTypeOfSale(e);
	};

	const selectState = (e) => {
		setMarketOrdersOrderState(e);
	};

	return (
		<Main>
			{/* <Header>
				{searchInfo ? (
					<Container
						style={{ flex: "1 1 70%", justifyContent: "space-evenly" }}
					>
						<TotalsCard>
							<Typo level={6} type="primary">
								Ordenes:
							</Typo>
							<Typo level={3} type="primary">
								{searchInfo.totalOrders}
							</Typo>
						</TotalsCard>
						<TotalsCard>
							<Typo level={6} type="primary">
								Total:
							</Typo>
							<Typo level={3} type="primary">
								$ {numberWithDotAndCommas(parseFloat(searchInfo.totalAmmount))}
							</Typo>
						</TotalsCard>
					</Container>
				) : (
					<Container>
						<Spin />
					</Container>
				)}
			</Header> */}
			<Main.Body>
				{data ? (
					<FilterContainer>
						<FilterSection>
							{marketOrdersOrdersRange ? (
								<DatePicker.RangePicker
									format="DD/MM/YYYY"
									value={marketOrdersOrdersRange}
									onChange={(e) => handleSelectedDate(e)}
								/>
							) : (
								<DatePicker.RangePicker
									format="DD/MM/YYYY"
									onChange={(e) => handleSelectedDate(e)}
								/>
							)}
						</FilterSection>
						<FilterSection>
							<Select
								showSearch
								allowClear
								placeholder="droguería"
								value={marketOrdersDistributorId}
								optionFilterProp="children"
								onChange={(e) => selectDistributor(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={distributors}
								style={{ width: 180 }}
							/>
						</FilterSection>
						<FilterSection>
							<Select
								showSearch
								allowClear
								placeholder="farmacia"
								value={marketOrdersPharmacyId}
								optionFilterProp="children"
								onChange={(e) => selectPharmacy(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={pharmacies}
								style={{ width: 220 }}
							/>
						</FilterSection>
						<FilterSection>
							<Select
								showSearch
								allowClear
								placeholder="provincia"
								optionFilterProp="children"
								value={marketOrdersProvinceName}
								onChange={(e) => selectProvince(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={provinces}
								style={{ width: 150 }}
							/>
						</FilterSection>
						<FilterSection>
							<Select
								showSearch
								allowClear
								placeholder="origen"
								optionFilterProp="children"
								value={marketOrdersTypeOfSale}
								onChange={(e) => selectTypeOfSale(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={orderTypes}
								style={{ width: 120 }}
							/>
						</FilterSection>
						{/* <Select
								showSearch
								allowClear
								placeholder="usuario"
								optionFilterProp="children"
								onChange={(e) => selectUser(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={users}
								style={{ width: 200 }}
							/> */}
						<FilterSection>
							<Select
								showSearch
								allowClear
								placeholder="estado"
								optionFilterProp="children"
								value={marketOrdersOrderState}
								onChange={(e) => selectState(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={orderStates}
								style={{ width: 120 }}
							/>
						</FilterSection>
						<FilterSection>
							<Button
								type="primary"
								onClick={handleDeleteFilters}
								loading={isLoading}
							>
								Borrar filtros
							</Button>
						</FilterSection>
						{/* <SignInTemplate.AntdButton
								type="Default"
								bg="Default"
								color="White"
								icon={<CloudDownloadOutlined />}
								onClick={fetch_export_report}
								loading={isLoading}
								style={{ width: "200px", fontWeight: "400" }}
							>
								Exportar
							</SignInTemplate.AntdButton> */}
					</FilterContainer>
				) : (
					<FilterContainer>
						<Spin />
					</FilterContainer>
				)}
				<OrdersTable data={data} loading={isLoading} />
				{searchInfo && searchInfo.totalOrders > 0 && (
					<CompaginationDiv>
						{searchInfo.actualPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						<AntdInput
							step={1}
							min={1}
							max={searchInfo.totalPages}
							onPressEnter={(e) => handlePageChange(e)}
							onChange={(e) =>
								setPaginationInputValue(e.target.value.toString())
							}
							defaultValue={paginationInputValue.toString()}
							value={paginationInputValue.toString()}
							addonAfter={`/ ${searchInfo.totalPages}`}
							style={{
								width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
							}}
						/>
						{searchInfo.actualPage < searchInfo.totalPages && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={
									searchInfo.actualPage === searchInfo.totalPages ? true : false
								}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
			</Main.Body>
		</Main>
	);
}
