import styled from "styled-components";

export const Inner = styled.div``;
export const Title = styled.div``;
export const Subtitle = styled.div``;

export const Main = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
`;
