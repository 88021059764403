const SIZES = {
  level1: "38px",
  level2: "30px",
  level3: "24px",
  level4: "20px",
  level5: "16px",
  level6: "14px",
  level7: "12px",
  level8: "10px",
  level9: "13px",
  level10: "9px",
  level11: "18px",
  level12: "15px",
  level13: "7px",
  default: "12px",
};

export default SIZES;
