import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Space, Radio, Dropdown, Menu } from "antd";
import {
	ActionButton,
	AntdRadioButton,
	Filters,
	Inner,
	RadioOptions,
} from "./styles";
import LoadingHeader from "./components/LoadingHeader";
import Image from "../../../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import { COLORS, Typo } from "../../../../../layout/theme";
import DataTable from "../../../../../common/data-table/DataTable";
import { get_clients_header_info } from "../../../../../../api/endpoints/clients";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import { get_clients_laboratories_page } from "../../../../../../api/endpoints/clients";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";

export default function LaboratoriesList() {
	const [headerInfo, setHeaderInfo] = useState(null);
	const [data, setData] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [key, setKey] = useState("null");

	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cliente</div>,
			dataIndex: "name",
			render: (value, record) => (
				<Link
					to={`/admin/distributorsIndex/laboratory/${key.id}`}
					onMouseOver={() => setKey(record)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Link>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Distribuidor asociado</div>
			),
			dataIndex: "megaDistributor",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value ? value.name : "No posee Distribuidor"}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Contacto</div>,
			dataIndex: "contactName",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={!r.deleted ? menu : menuInactive}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/distributorsIndex/laboratory/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/distributorsIndex/laboratory/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
			]}
		/>
	);

	const fetch_clients = async (page, state, search) => {
		const res = await get_clients_laboratories_page(search, state, page);
		setData(res);
	};

	const fetch_clients_header = async () => {
		const res = await get_clients_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_clients_header();
	}, [state]);

	useEffect(() => {
		fetch_clients(1, state, "");
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_clients(1, state, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	return (
		<Inner>
			<Filters>
				{!headerInfo ? (
					<LoadingHeader />
				) : (
					<Space size="large">
						<AntdSearch
							width={400}
							allowClear
							placeholder="Buscar laboratorio"
							onChange={debouncedEventHandler}
						/>
					</Space>
				)}
			</Filters>
			<RadioOptions>
				<Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
					<AntdRadioButton value="ACTIVE">
						Activos ({headerInfo?.totalLaboratoriesActive})
					</AntdRadioButton>
					<AntdRadioButton value="DELETED">
						Inactivos ({headerInfo?.totalLaboratoriesDisabled})
					</AntdRadioButton>
				</Radio.Group>
			</RadioOptions>
			<DataTable data={data} fetch={fetch_clients} columns={columns} />
		</Inner>
	);
}
