import axiosInstance from "../axiosInstance";

const CONTROLLER = "marketing";

const get_marketings = async (search, state) => {
  const response = await axiosInstance.get(
    `${CONTROLLER}?search=${search || ""}&state=${state}`
  );
  return response.data;
};

const get_marketing_by_id = async (id) => {
  const response = await axiosInstance.get(
    `${CONTROLLER}/GetMarketingById/${id}`
  );
  return response.data;
};

const get_all_marketings = async () => {
  const response = await axiosInstance.get(`${CONTROLLER}/GetAll`);
  return response.data;
};

const get_marketings_header_info = async () => {
  const response = await axiosInstance.get(`${CONTROLLER}/GetMarketingsHeader`);
  return response.data;
};

const update_marketing = async (id, data) => {
  const response = await axiosInstance.put(`${CONTROLLER}/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const update_marketing_state = async (state) => {
  const response = await axiosInstance.put(
    `${CONTROLLER}/UpdateMarketingStatus`,
    state
  );
  return response.data;
};

const create_marketing = async (data) => {
  const response = await axiosInstance.post(`${CONTROLLER}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export {
  create_marketing,
  get_marketings,
  get_all_marketings,
  get_marketings_header_info,
  update_marketing_state,
  get_marketing_by_id,
  update_marketing,
};
