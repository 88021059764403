import styled from "styled-components/macro";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin-top: 10px;
`;
export const ProductData = styled.div`
	display: flex;
	flex: 1;
	margin-top: 60px;
	align-items: center;
	justify-content: center;
`;

export const TableHeader = styled.div`
	width: 630px;
	height: 70px;
	background-color: ${COLORS.SkeletonGrey};
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justift-content: center;
	align-items: center;
`;

export const BannerSection = styled.div`
	position: absolute;
	left: 130px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	gap: 20px;
	min-height: 50px;
	background-color: ${COLORS.SkeletonGrey};
	border-radius: 20px;
	padding: 10px;
`;

export const BannerContainer = styled.div`
	min-width: 260px;
	min-height: 260px;
	user-select: none;
	background-color: ${COLORS.SkeletonGrey};
	border-radius: 20px;
`;

export const ProductsSection = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 30px;
	overflow: hidden;
	padding: 0 30px 0 30px;
`;

export const SearchContainer = styled.div`
	flex: 1 1 40%;
	align-items: center;
	align-content: center;
	display: flex;
	flex-direction: column;
	align-content: space-evenly;
	border-raidus: 50px;

	.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}
`;

export const BookContainer = styled.div`
	min-heigth: 650px;
	height: 650px;
	max-height: 650px;
	width: 100%;
	padding: 30px 1.2% 0px 13.5px;
	position: sticky;
	border-radius: 10px;
	background-color: ${COLORS.SkeletonGrey};
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 50%;
	}
	text-align: center;
`;
