import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";
import { Button as ButtonAntd } from "antd";

export const Inner = styled.div`
	display: flex;
	gap: 40px;

	@media only screen and (min-width: 700px) and (max-width: 1190px) {
		flex-direction: column;
	}

	@media only screen and (min-width: 1191px) and (max-width: 1231px) {
		flex-direction: row;
		gap: 10px;
	}
`;
export const DistributorSelection = styled.div`
	height: 100%;
	width: 100%;
	overflow: hidden;
`;

export const InfoClient = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level7};
	font-weight: ${WEIGHT.Bold};
`;

export const InfoQuantity = styled.div`
	display: flex;
	color: ${COLORS.Primary};
	font-size: ${SIZES.level11};
	font-weight: ${WEIGHT.Bold};
	justify-content: center;
	align-items: flex-end;
`;

export const InfoUnity = styled.div`
	color: ${COLORS.Grey};
	font-size: 17px;
	font-weight: ${WEIGHT.Bold};
	margin-left: 5px;
`;

export const OptionsSection = styled.div`
	display: flex;
	gap: 10px;
	margin-top: 20px;
	margin-left: 15px;
	margin-bottom: 10px;

	.ant-form-item {
		margin-bottom: 0px;
	}
`;

export const ProductsSection = styled.div`
	flex: 1 1 80%;
`;

export const CartAntdButton = styled(ButtonAntd)`
	cursor: pointer;
	height: 40px;

	&:hover {
		color: ${COLORS.Primary};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}

	background-color: ${COLORS.Secondary};
	color: ${COLORS.White};
	border-radius: 5px;
	box-shadow: 0 3px 3px 0 #c7c7c7;
`;

export const InfoSection = styled.div`
	flex: 1 1 30%;
	background-color: white;
	position: sticky;
	border-radius: 10px;
	max-height: 750px;
`;

export const Container = styled.div`
	padding-top: 1rem;
`;

export const InfoSubSection = styled.div`
	display: flex;
	justify-content: space-evenly;
	margin: 15px 0px 15px 0px;
	align-content: center;
	align-items: center;
`;

export const InfoTitle = styled.div`
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitleTotal = styled.div`
	color: ${COLORS.Primary};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitleProductQuantity = styled.div`
	color: ${COLORS.Default};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitleBuy = styled.div`
	color: ${COLORS.Primary};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.Black};
`;

export const InfoSubTitle = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;
export const InfoNumber = styled.div`
	outline: auto;
	outline-width: thin;
	outline-color: ${COLORS.Default};
	border-radius: 5px;
	width: 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.Default};
	font-size: ${SIZES.level9};
`;

export const InfoTitleProductQuantityItems = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoNumberItems = styled.div`
	outline: auto;
	outline-width: thin;
	outline-color: ${COLORS.Grey};
	border-radius: 5px;
	width: 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
`;

export const InfoIva = styled.div`
	font-size: 10px;
	align-items: center;
	align-content: center;
	justify-content: center;
	width: 100%;
	text-align: center;
	color: ${COLORS.Default};
`;

export const PresentationTitle = styled.div`
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.SemiBold};
`;
export const TableContainer = styled.div`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}

	.ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
		border-radius: 10px;
		border: 1px solid ${COLORS.Default};
		scale: 1.1;
	}
`;

export const CartCouponButton = styled(ButtonAntd)`
	cursor: pointer;
	height: 30px;

	&:hover {
		color: ${COLORS.Primary};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}

	background-color: ${COLORS.Secondary};
	color: ${COLORS.White};
	border-radius: 5px;
	box-shadow: 0 3px 3px 0 #c7c7c7;
`;

export const CouponToggleContainer = styled.div`
	display: flex;
	gap: 10px;
	width: 100%;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	min-height: 30px;
	padding-right: 10px;
`;
