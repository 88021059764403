import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	CalendarButton,
	RadioOptions,
	CompaginationDiv,
	AntdInput,
	Container,
	Header,
	TotalsCard,
} from "./styles";
import { Button, Checkbox, DatePicker, Select, Spin } from "antd";
import {
	CloudDownloadOutlined,
	LeftOutlined,
	RightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import SalesReportTable from "./components/sales-report-table/SalesReportTable";
import { CONSTANTS } from "../../../../utils/constants";
import {
	export_laboratory_sales,
	get_laboratory_sales,
} from "../../../../api/endpoints/sales";
import Main from "../../../layout/main/Main";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import { get_clients_name_list } from "../../../../api/endpoints/clients";
import { Typo } from "../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../utils/numberWithDotAndComas";

export default function SalesReport() {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [laboratoryId, setLaboratoryId] = useState(null);
	const [distributorId, setDistributorId] = useState(null);
	const [sellerId, setSellerId] = useState(null);
	const [provinceId, setProvinceId] = useState(null);
	const [typeOfSale, setTypeOfSale] = useState(null);
	const [orderState, setOrderState] = useState(null);
	const [dateToSearch, setDateToSearch] = useState(null);
	const [page, setPage] = useState(CONSTANTS.PAGE_LARGE_SIZE);
	const [actualPage, setActualPage] = useState(1);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [searchInfo, setSearchInfo] = useState(null);
	const [users, setUsers] = useState(null);
	const [orderTypes, setOrderTypes] = useState(null);
	const [distributors, setDistributors] = useState(null);
	const [provinces, setProvinces] = useState(null);
	const [orderStates, setOrderStates] = useState(null);
	const [clients, setClients] = useState(null);
	const [originalClients, setOriginalClients] = useState(null);
	const [isApprovedFilter, setIsApprovedFilter] = useState(false);
	const [fileName, setFileName] = useState(null);
	const [orderDetails, setOrderDetails] = useState(null);

	useEffect(() => {
		const fetch_market_client_list = async () => {
			const res = await get_clients_name_list();
			setOriginalClients(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
			);
			setClients(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
			);
		};

		fetch_market_client_list();
	}, []);

	const fetch_sales = async (skipValue) => {
		setIsLoading(true);
		try {
			const res = await get_laboratory_sales(
				laboratoryId,
				distributorId,
				sellerId,
				provinceId,
				dateToSearch,
				typeOfSale,
				orderState,
				skipValue,
				page
			);
			const resTypesOfOrders = JSON.parse(res?.paginationInfo?.typesOfOrders);
			const resStatesOfOrders = JSON.parse(res?.paginationInfo?.statesOfOrders);
			const resProvinceOfOrders = JSON.parse(
				res?.paginationInfo?.provinceOfOrders
			);

			setData(res?.orders);

			setOrderDetails(res?.detailOrders);

			setSearchInfo(res?.paginationInfo);
			setPaginationInputValue(res?.paginationInfo?.actualPage);
			setUsers(
				res?.users?.map((r) => ({
					value: r.sellerId,
					label: r.sellerFirstName + " " + r.sellerLastName,
				}))
			);
			setOrderTypes(
				resTypesOfOrders?.map((r) => ({
					value: r.TypeOfSale,
					label: r.TypeOfSale,
				}))
			);
			setOrderStates(
				resStatesOfOrders?.map((r) => ({
					value: r.OrderState,
					label: selectStateToShow(r.OrderState),
				}))
			);
			setProvinces(
				resProvinceOfOrders?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);
			setDistributors(
				res?.distributors?.map((r) => ({
					value: r.distributorId,
					label: r.distributorName,
				}))
			);

			setHeaderInfo({
				totalActive: res?.paginationInfo.totalActive,
				totalPending: res?.paginationInfo.totalPending,
				totalSended: res?.paginationInfo.totalSended,
				totalCanceled: res?.paginationInfo.totalCanceled,
			});
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (laboratoryId) {
			setActualPage(1);
			setPage(CONSTANTS.PAGE_LARGE_SIZE);
			fetch_sales(1);
		}
	}, [
		laboratoryId,
		orderState,
		typeOfSale,
		dateToSearch,
		sellerId,
		provinceId,
		distributorId,
	]);

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendientes";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activas";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviadas";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Canceladas";

			default:
				return;
		}
	};

	const handleDeleteDates = () => {
		setDateToSearch(null);
		setDistributorId(null);
		setSellerId(null);
		setProvinceId(null);
		setTypeOfSale(null);
		setOrderState(null);
		fetch_sales(1);
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_sales(cp);
			setActualPage(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_sales(cp);
			setActualPage(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_sales(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	const handleDateChange = (date) => {
		setDateToSearch(date.format("DD/MM/YYYY").toString());
	};

	const selectProvince = (e) => {
		setProvinceId(e);
	};

	const selectUser = (e) => {
		setSellerId(e);
	};

	const selectTypeOfSale = (e) => {
		setTypeOfSale(e);
	};

	const selectDistributor = (e) => {
		setDistributorId(e);
	};

	const selectState = (e) => {
		setOrderState(e);
	};

	const fetch_export_report = async () => {
		setIsLoading(true);
		try {
			const res = await export_laboratory_sales({
				laboratoryId: laboratoryId,
				distributorId: distributorId,
				sellerId: sellerId,
				provinceId: provinceId,
				typeOfSale: typeOfSale,
				orderState: orderState,
				dateToSearch: dateToSearch,
			});
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const _fileName =
				"SalesReport_" +
				fileName +
				"_" +
				moment().format("DDMMYYYYhhmmss").toString() +
				".xlsx";
			link.setAttribute("download", _fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	const handleChange = (value, obj) => {
		setIsLoading(true);
		if (value === "...") {
			setLaboratoryId(null);
			setFileName(null);
		} else {
			setLaboratoryId(value);
			setFileName(obj?.label);
		}
		setIsLoading(false);
	};

	const handleIsApprovedFilter = (value) => {
		setIsApprovedFilter(value);
		if (value) {
			const filteredClients = clients.filter((client) => client.isApproved);
			setClients(filteredClients);
		} else {
			setClients(originalClients);
		}
	};

	return (
		<Main>
			<Main.Breadcrumb>
				<Main.BreadcrumbItem>
					<Link to="/">Home</Link>
				</Main.BreadcrumbItem>
				<Main.BreadcrumbSeparator />
				<Main.ActiveBreadcrumbItem>Reporte de ventas</Main.ActiveBreadcrumbItem>
			</Main.Breadcrumb>
			{/* {laboratoryId && data && (
				<FloatingContainer>
					<TotalsCard>
						<Typo level={6} type="primary">
							Ordenes: {searchInfo.totalOrders}
						</Typo>
						<Typo level={6} type="primary">
							Total: ${searchInfo.totalAmmount}
						</Typo>
					</TotalsCard>
				</FloatingContainer>
			)} */}
			<Header>
				<Container style={{ flex: "1 1 30%" }}>
					<Select
						showSearch
						placeholder="Elegi un cliente"
						optionFilterProp="children"
						onChange={handleChange}
						//value={selectedClient !== "null" ? selectedClient : "..."}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={clients}
						style={{ width: 180 }}
					/>
					<Checkbox
						checked={isApprovedFilter}
						onChange={(e) => handleIsApprovedFilter(e.target.checked)}
					>
						Clientes con Contrato
					</Checkbox>
				</Container>
				{laboratoryId && data && (
					<Container
						style={{ flex: "1 1 70%", justifyContent: "space-evenly" }}
					>
						<TotalsCard>
							<Typo level={6} type="primary">
								Ordenes:
							</Typo>
							<Typo level={3} type="primary">
								{searchInfo.totalOrders}
							</Typo>
						</TotalsCard>
						<TotalsCard>
							<Typo level={6} type="primary">
								Total:
							</Typo>
							<Typo level={3} type="primary">
								$ {numberWithDotAndCommas(searchInfo.totalAmmount)}
							</Typo>
						</TotalsCard>
					</Container>
				)}
			</Header>
			<Main.Body>
				{laboratoryId ? (
					data ? (
						<RadioOptions>
							<DatePicker
								picker="month"
								onChange={handleDateChange}
								placeholder="fecha"
							/>
							<Select
								showSearch
								allowClear
								placeholder="provincia"
								optionFilterProp="children"
								onChange={(e) => selectProvince(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={provinces}
								style={{ width: 220 }}
							/>
							<Select
								showSearch
								allowClear
								placeholder="origen"
								optionFilterProp="children"
								onChange={(e) => selectTypeOfSale(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={orderTypes}
								style={{ width: 120 }}
							/>
							<Select
								showSearch
								allowClear
								placeholder="usuario"
								optionFilterProp="children"
								onChange={(e) => selectUser(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={users}
								style={{ width: 200 }}
							/>
							<Select
								showSearch
								allowClear
								placeholder="droguería"
								optionFilterProp="children"
								onChange={(e) => selectDistributor(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={distributors}
								style={{ width: 180 }}
							/>
							<Select
								showSearch
								allowClear
								placeholder="estado"
								optionFilterProp="children"
								onChange={(e) => selectState(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={orderStates}
								style={{ width: 180 }}
							/>
							<CalendarButton
								type="primary"
								color="white"
								onClick={handleDeleteDates}
								loading={isLoading}
							>
								Eliminar filtros
							</CalendarButton>
							<SignInTemplate.AntdButton
								type="Default"
								bg="Default"
								color="White"
								icon={<CloudDownloadOutlined />}
								onClick={fetch_export_report}
								loading={isLoading}
								style={{ width: "200px", fontWeight: "400" }}
							>
								Exportar
							</SignInTemplate.AntdButton>
						</RadioOptions>
					) : (
						<RadioOptions>
							<Spin />
						</RadioOptions>
					)
				) : (
					<></>
				)}
				{laboratoryId ? (
					<SalesReportTable
						data={data}
						details={orderDetails}
						loading={isLoading}
						pagination={false}
					/>
				) : (
					<></>
				)}
				{searchInfo && searchInfo.totalOrders > 0 && (
					<CompaginationDiv>
						{searchInfo.actualPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						<AntdInput
							step={1}
							min={1}
							max={searchInfo.totalPages}
							onPressEnter={(e) => handlePageChange(e)}
							onChange={(e) =>
								setPaginationInputValue(e.target.value.toString())
							}
							defaultValue={paginationInputValue.toString()}
							value={paginationInputValue.toString()}
							addonAfter={`/ ${searchInfo.totalPages}`}
							style={{
								width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
							}}
						/>
						{searchInfo.actualPage < searchInfo.totalPages && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={
									searchInfo.actualPage === searchInfo.totalPages ? true : false
								}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
			</Main.Body>
		</Main>
	);
}
