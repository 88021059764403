import axiosInstance from "../axiosInstance";

const CONTROLLER = "Home";

const get_home_data = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}`);
	return response.data;
};

export { get_home_data };
