import styled from "styled-components";
import { Checkbox } from "antd";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 40px;
	flex-direction: column;
`;

export const InnerForm = styled.div`
	flex: 1 1 65%;
	gap: 50px;
`;
export const ProductData = styled.div`
	flex: 1 1 65%;
`;
export const PublishSettings = styled.div`
	flex: 1 1 35%;
	background-color: white;
`;
export const DistributorImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`;

export const AntdCheckbox = styled(Checkbox)``;

export const OrderData = styled.div`
	flex: 1 1 65%;
	pointer-events: none;
	margin-bottom: 20px;
`;

export const BlockedData = styled.div`
	pointer-events: none;
`;

export const LineBreak = styled.hr`
	width: 100%;
	border: none;
	margin-top: 0px;
	box-shadow: 0 1px 1px #bfbfbf;
	height: 1px;
`;

export const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	flex-direction: column;
	gap: 20px;
	margin-top: 10px;
`;

export const TitleBanner = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	background-color: ${COLORS.Primary};
	height: 100px;
	width: 100%;
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
`;

export const SubTitleContainer = styled.div``;

export const InfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;
`;

export const InfoText = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	color: ${COLORS.Grey};
	font-size: ${SIZES.level6};
	font-weight: ${WEIGHT.Bold};
`;

export const InfoTableContainer = styled.div`
	flex: 1 1 80%;
	height: 100%;
	width: 100%;
	overflow: hidden;
`;

export const InfoPriceContainer = styled.div`
	flex: 1 1 30%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	padding: 1rem;
	max-height: 100px;
`;

export const OptionsButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	gap: 100px;
	margin-top: 50px;
`;

export const InfoSubSection = styled.div`
	display: flex;
	justify-content: space-evenly;
	margin: 15px 0px 15px 0px;
	align-content: center;
	align-items: center;
`;

export const InfoTitleTotal = styled.div`
	color: ${COLORS.Primary};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitleProductQuantity = styled.div`
	color: ${COLORS.Default};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoNumber = styled.div`
	outline: auto;
	outline-width: thin;
	outline-color: ${COLORS.Grey};
	border-radius: 5px;
	width: 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.Default};
	font-size: ${SIZES.level9};
`;

export const PriceContainer = styled.div`
	display: flex;
	background-color: ${COLORS.White};
	border-radius: 10px;
	padding: 1rem;
	justify-content: space-evenly;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;
