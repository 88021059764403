import styled from "styled-components";
import { Button as ButtonAntd } from "antd";
import { COLORS } from "../../../layout/theme";

export const ButtonContainer = styled.div`
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	min-height: 130px;
	flex: 1;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
`;

export const AntdButton = styled(ButtonAntd)`
	background-color: ${(props) =>
		props.$active ? COLORS.Primary : COLORS.White};
	color: ${(props) => (props.$active ? COLORS.White : COLORS.Primary)};
	border-color: ${COLORS.Primary};
	border-radius: 5px;
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
`;
export const ChildrenContainer = styled.div`
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	min-height: 130px;
`;
export const SelectContainer = styled.div`
	display: flex;
	gap: 20px;
	margin-bottom: 20px;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const Container = styled.div`
	display: flex;
	gap: 80px;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	min-height: 130px;
	flex: 0 0 50%;
	justify-content: center;
	align-items: center;
`;
