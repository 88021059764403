import React, { useEffect, useState } from "react";
import { Select, Input, Form } from "antd";
import { Inner } from "./styles";
import { get_countries } from "../../../api/endpoints/general";
import Image from "../image/Image";

const { Option } = Select;

const PhoneInput = ({ form, size, label, labelStyle, ...rest }) => {
  const [countries, setCountries] = useState([]);

  const formValues = Form.useWatch([], form);

  useEffect(() => {
    const fetch_countries = async () => {
      const data = await get_countries();
      setCountries(data || []);
      form.setFieldsValue({
        phoneAreaCode: data.find((c) => c.code === "arg").phoneAreaCode,
      });
    };

    fetch_countries();
  }, []);

  const phoneAreaCodes = (
    <Form.Item name="phoneAreaCode" noStyle>
      <Select
        size={size}
        style={{
          width: 95,
        }}
      >
        {countries.map((country) => (
          <Option key={country.id} value={country.phoneAreaCode}>
            <Image
              src={require(`../../../assets/images${country.flagImageUrl}`)}
              width={20}
              height={20}
            />{" "}
            {country.phoneAreaCode}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    <Inner>
      <Form.Item
        // label={label ? label : null}
        label={labelStyle ? <label style={labelStyle}>{label}</label> : label}
        name="phone"
        rules={[
          {
            required: true,
            message: "Este campo es obligatorio",
          },
          {
            pattern: new RegExp(/^(?!\s*$)[0-9\s]*$/),
            message:
              "No se aceptan caracteres especiales. Solo letras y numeros",
          },
        ]}
      >
        <Input
          size={size}
          placeholder="Teléfono"
          type="number"
          addonBefore={phoneAreaCodes}
        />
      </Form.Item>
    </Inner>
  );
};

export default PhoneInput;
