import { Skeleton, Row, Col, Space } from "antd";
import { Box, Inner } from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
	return (
		<Inner>
			<Space
				direction="vertical"
				size="small"
				style={{ display: "flex", width: "100%" }}
			>
				<Row gutter={GUTTER} style={{ width: "100%" }}>
					<Col span={4}>
						<Skeleton.Input
							active={true}
							size="large"
							shape="square"
							block={true}
						/>
					</Col>
				</Row>
				<Row gutter={GUTTER}></Row>
				<Row gutter={GUTTER} style={{ width: "100%" }}>
					<Col span={4}>
						<Skeleton.Input
							active={true}
							size="large"
							shape="square"
							block={true}
						/>
					</Col>
					<Col span={4}>
						<Skeleton.Input
							active={true}
							size="large"
							shape="square"
							block={true}
						/>
					</Col>
					<Col span={8} offset={4}>
						<Skeleton.Input
							active={true}
							size="large"
							shape="square"
							block={true}
						/>
					</Col>
				</Row>
				<Row gutter={GUTTER}></Row>
				<Row gutter={GUTTER} style={{ width: "100%", padding: "10px" }}>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
				</Row>
				<Row gutter={GUTTER} style={{ width: "100%", padding: "10px" }}>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
				</Row>
				<Row gutter={GUTTER} style={{ width: "100%", padding: "10px" }}>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
				</Row>
				<Row gutter={GUTTER} style={{ width: "100%", padding: "10px" }}>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
					<Col span={4}>
						<Box />
					</Col>
				</Row>
			</Space>
		</Inner>
	);
}
