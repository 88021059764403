import styled from "styled-components";
import { Radio, Select, Option } from "antd";
import { COLORS, WEIGHT } from "../../../../../layout/theme";

export const Filters = styled.div`
  margin: 20px 0;

  & button {
    padding: 0 !important;
  }
`;

export const RadioOptions = styled.div`
  margin-bottom: 20px;
`;

export const AntdRadioButton = styled(Radio.Button)`
  border: none;
  font-weight: ${WEIGHT.Bold};
`;

export const AntdSelect = styled(Select)`
  border: none;
  font-weight: ${WEIGHT.SemiBold};
`;

export const AntdOption = styled.div`
  border: none;
  font-weight: ${WEIGHT.SemiBold};
  color: ${COLORS.Grey};
`;

export const PreviewImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
`;

export const ActionButton = styled.button`
  border: none;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  color: ${COLORS.Primary};

  &:hover {
    background-color: ${COLORS.Grey};
  }
`;
