import styled, { keyframes } from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";

const spin = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

export const Spinner = styled.div`
	margin-top: 10px;
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border-left-color: #09f;

	animation: ${spin} 1s ease infinite;
`;
export const ContainerDiv = styled.div`
	width: 100%;
	height: 500px;
	overflow: auto;

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.Grey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.Grey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;
export const CartDelete = styled.div`
	color: ${COLORS.Grey};
	font-family: sans-serif;
	font-stretch: ultra-expanded;
	font-size: ${SIZES.level12};
	font-weight: ${WEIGHT.Bold};
`;

export const PriceInfoContainer = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: space-around;
`;

export const PriceAndDiscountContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Container = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
