import React from "react";
import { MenuOutlined } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { COLORS } from "../../layout/theme";

const Dragger = ({ id }) => {
	const { listeners, isDragging, setNodeRef } = useSortable({
		id,
	});

	return (
		<div
			ref={setNodeRef}
			{...listeners}
			style={{
				cursor: "grab",
				touchAction: "none",
				color: isDragging ? "blue" : "initial",
			}}
		>
			<MenuOutlined style={{ color: COLORS.Grey }} />
		</div>
	);
};

export default Dragger;
