import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link } from "react-router-dom";
import {
	ChildrenContainer,
	SelectContainer,
	Section,
	Container,
} from "./styles";
import { Select } from "antd";
import { Typo } from "../../../layout/theme";
import { get_clients_users } from "../../../../api/endpoints/clients";
import AntdSelect from "../../../common/antd-select/AntdSelect";
import ProductDiscountList from "./list/ProductDiscountList";

export default function MarketProductsDiscountsPage() {
	const [clientType, setClientType] = useState("LABORATORIO");
	const [data, setData] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [loading, setLoading] = useState(false);
	const [clientOptions, setClientOptions] = useState(null);

	const clientTypes = [
		{
			id: 1,
			name: "Laboratorio",
			value: "LABORATORIO",
		},
		{
			id: 2,
			name: "One Transfer",
			value: "ONETRANSFER",
		},
	];

	useEffect(() => {
		const fetch_clients = async () => {
			const res = await get_clients_users();
			setData(res);
		};
		setLoading(true);
		fetch_clients();
		setLoading(false);
	}, []);

	const handleClientTypeSelect = (value) => {
		setSelectedClient(null);
		switch (value) {
			case "LABORATORIO":
				setClientType(
					data
						?.filter(
							(c) =>
								!c.isOneMarket &&
								c.isSeller &&
								c.isApproved &&
								c.contract?.activeMobileApp
						)
						.sort((a, b) => a.title - b.title)
				);
				setClientOptions(
					data
						?.filter(
							(c) =>
								!c.isOneMarket &&
								c.isSeller &&
								c.isApproved &&
								c.contract?.activeMobileApp
						)
						.sort((a, b) => a.title - b.title)
						.map((r) => ({
							value: r.id,
							label: r.name,
						}))
				);
				break;
			case "ONETRANSFER":
				setClientType(data?.filter((c) => c.isOneMarket));
				setClientOptions(
					data
						?.filter((c) => c.isOneMarket)
						.sort((a, b) => a.title - b.title)
						.map((r) => ({
							value: r.id,
							label: r.name,
						}))
				);
				break;

			default:
				break;
		}
	};

	const handleClientSelect = (value) => {
		const _data = clientType.filter((c) => c.id === value);
		setSelectedClient(_data);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Descuentos por Producto
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<SelectContainer style={{ flexBasis: 0 }}>
					<Container>
						<Section>
							<Typo type="primary" level={6}>
								Tipo de Cuenta
							</Typo>
							<AntdSelect
								onChange={handleClientTypeSelect}
								style={{ width: 160 }}
								loading={loading || !data}
								disabled={!data}
							>
								{clientTypes?.map((item) => (
									<Select.Option key={item.id} value={item.value}>
										{item.name}
									</Select.Option>
								))}
							</AntdSelect>
						</Section>
						<Section>
							{clientType && (
								<Typo type="primary" level={6}>
									Cuentas
								</Typo>
							)}
							{clientType && (
								<Select
									showSearch
									placeholder="Elegi un cliente"
									optionFilterProp="children"
									onChange={handleClientSelect}
									value={selectedClient ? selectedClient[0].name : "..."}
									filterOption={(input, option) =>
										(option?.label ?? "")
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									options={clientOptions}
									style={{ width: 180 }}
								/>
							)}
						</Section>
					</Container>
				</SelectContainer>
				{selectedClient && (
					<ChildrenContainer>
						<ProductDiscountList
							clientId={selectedClient[0].id}
							client={selectedClient[0]}
						/>
					</ChildrenContainer>
				)}
			</Main.Body>
		</Main>
	);
}
