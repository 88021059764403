import styled from "styled-components/macro";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
  display: flex;
  gap: 10px;
  align-content: center;
  align-items: flex-start;
  margin-top: 40px;
  margin-left: 10px;
  margin-bottom: 50px;
`;
export const ProductData = styled.div`
  flex: 1 1 65%;
`;
export const PublishSettings = styled.div`
  flex: 1 1 35%;
`;

export const PublishContainer = styled.div`
  height: 550px;
  width: 450px;
  background-color: ${COLORS.SkeletonGrey};
  padding: 2rem;
  margin-right: 20px;
`;
