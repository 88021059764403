import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Menu, Space, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import Main from "../../../../layout/main/Main";
import {
	Filters,
	Span,
	SpanCointainer,
	CampaignsContainer,
	ActionButton,
	CardContainer,
	Arrow,
} from "./styles";
import {
	get_marketings,
	get_marketings_header_info,
	update_marketing_state,
} from "../../../../../api/endpoints/marketing";
import CampaingCardContainer from "../../../../containers/campaign-cards/CampaingCardContainer";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Typo } from "../../../../layout/theme";
import debounce from "lodash/debounce";
import {
	CloseCircleOutlined,
	InfoCircleOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import SkeletonPublicity from "./components/skeleton-publicity/SkeletonPublicity";
import { useAuth } from "../../../../../contexts/authContext";
import { FaArrowLeft } from "react-icons/fa";
import AntdSearch from "../../../../common/antd-search/AntdSearch";

const { confirm } = Modal;

export default function MarketingList() {
	const navigate = useNavigate();
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const [campaigns, setCampaigns] = useState(null);
	const [key, setKey] = useState(null);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [campaignsState, setCampaignsState] = useState("ACTIVE");

	const fetch_campaigns = async () => {
		const res = await get_marketings("", campaignsState);
		setCampaigns(res);
	};

	const fetch_marketings_header = async () => {
		const res = await get_marketings_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_campaigns();
		fetch_marketings_header();
	}, [campaignsState]);

	const handleInactivate = (key) => {
		confirm({
			title: "¿Seguro que quiere desactivar la publicidad?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La publicidad se desactivara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "INACTIVE");
			},
		});
	};

	const handleActivate = (key) => {
		confirm({
			title: "¿Seguro que quiere activar la publicidad?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "La publicidad se activara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "ACTIVE");
			},
		});
	};

	const handleDeleted = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar la publicidad?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La publicidad se eliminara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "DELETED");
			},
		});
	};

	const handleAction = async (key, state) => {
		try {
			const _state = { id: key, state: state };
			await update_marketing_state(_state);
			fetch_campaigns();
			fetch_marketings_header();
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Publicidad se actualizo con exito",
				"La publicidad se actualizo con exito"
			);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"No se puedo actualizar",
				"No se puede actualizar una publicidad que la fecha de finalizacion es anterior a la de hoy"
			);
		}
	};

	const menuActive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/marketing/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleInactivate(key)}>Desactivar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/marketing/${key}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleActivate(key)}>Activar</Typo>,
				},
				{
					key: "3",
					label: <Typo onClick={() => handleDeleted(key)}>Borrar</Typo>,
				},
			]}
		/>
	);

	const menuDeleted = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleDeleted(key)}>Borrar</Typo>,
				},
			]}
		/>
	);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_campaigns(e.target.value, campaignsState);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleBackToTheGuide = () => {
		updateStartGuideProgress(user.uid, 7);
		navigate("/startGuide");
	};

	if (!campaigns) return <SkeletonPublicity />;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Marketing</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					<Space size="large">
						<AntdSearch
							allowClear
							enterButton={true}
							placeholder="Buscar publicidad"
							onChange={debouncedEventHandler}
							width={400}
						/>
						<DropShadow type="drop">
							<SignInTemplate.AntdButton
								type="primary"
								bg="Primary"
								color="White"
								icon={
									<AiOutlinePlusCircle
										style={{
											top: 2,
											position: "relative",
											marginRight: "5px",
										}}
									/>
								}
								onClick={() => navigate("/admin/marketing/create")}
								style={{ fontWeight: "600" }}
								width={220}
								height={48}
								fontSize={12}
							>
								CREAR PUBLICIDAD
							</SignInTemplate.AntdButton>
							{userdata?.guideProgress === 6 && campaigns?.length === 0 && (
								<Arrow>
									<FaArrowLeft />
								</Arrow>
							)}
						</DropShadow>
					</Space>
				</Filters>
				<SpanCointainer>
					<Span onClick={() => setCampaignsState("ACTIVE")}>
						Activas ({headerInfo?.totalActive})
					</Span>
					<Span onClick={() => setCampaignsState("FUTURE")}>
						Proximas ({headerInfo?.totalFuture})
					</Span>
					<Span onClick={() => setCampaignsState("INACTIVE")}>
						Inactivas ({headerInfo?.totalInactive})
					</Span>
					<Span onClick={() => setCampaignsState("DELETED")}>
						Borradas ({headerInfo?.totalDeleted})
					</Span>
					{userdata?.guideProgress === 6 && (
						<SignInTemplate.AntdButton
							type="primary"
							bg="Primary"
							color="White"
							icon={<QuestionCircleOutlined />}
							onClick={handleBackToTheGuide}
							style={{
								width: "250px",
								fontWeight: "400",
							}}
						>
							Volver a la Guia de Inicio
						</SignInTemplate.AntdButton>
					)}
				</SpanCointainer>
				<CampaignsContainer>
					{campaigns?.map((i) => (
						<CardContainer>
							<CampaingCardContainer item={i} />
							{campaignsState !== "DELETED" && (
								<Dropdown
									overlay={
										campaignsState === "ACTIVE"
											? menuActive
											: campaignsState === "FUTURE"
											? menuActive
											: campaignsState === "DELETED"
											? menuDeleted
											: menuInactive
									}
									placement="bottomRight"
								>
									<ActionButton onMouseOver={() => setKey(i.id)}>
										<BsThreeDotsVertical />
									</ActionButton>
								</Dropdown>
							)}
						</CardContainer>
					))}
				</CampaignsContainer>
			</Main.Body>
		</Main>
	);
}
