import styled from "styled-components/macro";
import { COLORS, SIZES } from "../../../layout/theme";

export const Inner = styled.div`
	cursor: pointer;
	background: transparent;
	height: ${(props) => (props.bannerHeight ? props.bannerHeight : "100%")};
`;

export const InfoTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 0 25px 0;
	font-size: ${SIZES.level7};
	color: ${COLORS.Primary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	@media only screen and (min-width: 760px) and (max-width: 1160px) {
		height: 45px;
		font-size: ${SIZES.level10};
	}
`;

export const TopContainer = styled.div`
	height: ${(props) => (props.bannerHeight ? props.bannerHeight : "100%")};

	.ant-image {
		height: 100%;
		width: 100%;
	}
`;

export const Divider = styled.hr`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	border: none;
	margin-top: 0px;
	margin-left: auto;
	margin-right: auto;
	height: 1px;
	background-color: ${COLORS.LightGrey};
	opacity: 1;
`;

export const InfoTitleTemp = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 0 25px 0;
	font-size: ${SIZES.level7};
	color: ${COLORS.Primary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	height: 20px;

	@media only screen and (min-width: 760px) and (max-width: 1360px) {
		height: 45px;
		font-size: ${SIZES.level10};
	}
`;
export const TopContainerTemp = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	height: 70px;
	width: 90px;
	padding: 20px;

	@media only screen and (min-width: 760px) and (max-width: 1360px) {
		height: 40px;
		width: 40px;
		padding: 0px;
	}
`;

export const BottomContainer = styled.div``;
export const Info = styled.div``;
export const Add = styled.div``;
export const PriceSection = styled.div``;
export const Price = styled.div``;
export const OfferPrice = styled.div``;
export const InfoTop = styled.div``;
export const InfoLab = styled.div``;
export const AddedToCartMessage = styled.div``;
