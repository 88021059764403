import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link } from "react-router-dom";
import {
	ChildrenContainer,
	SelectContainer,
	Section,
	Container,
} from "./styles";
import { Select } from "antd";
import { Typo } from "../../../layout/theme";
import {
	get_client_by_id,
	get_clients_users,
} from "../../../../api/endpoints/clients";
import AntdSelect from "../../../common/antd-select/AntdSelect";
import RouteList from "./list/RouteList";

export default function LaboratoryRoutesPage({ clientId }) {
	const [clientType, setClientType] = useState("LABORATORIO");
	const [data, setData] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [loading, setLoading] = useState(false);
	const [clientOptions, setClientOptions] = useState(null);

	useEffect(() => {
		const fetch_clients = async () => {
			const res = await get_client_by_id(clientId);
			setSelectedClient(res.client);
		};
		setLoading(true);
		fetch_clients();
		setLoading(false);
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Puntos de venta asociados
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<SelectContainer style={{ flexBasis: 0 }}></SelectContainer>
				{selectedClient && (
					<ChildrenContainer>
						<RouteList
							usersData={selectedClient.users}
							clientId={selectedClient.id}
							client={selectedClient}
							userType="MOBILE"
						/>
					</ChildrenContainer>
				)}
			</Main.Body>
		</Main>
	);
}
