import styled from "styled-components";
import { COLORS } from "../../layout/theme";

export const Inner = styled.div`
	display: flex;
	border-radius: 15px;
	background-color: ${COLORS.White};
	height: 140px;
	width: 100%;
	padding: 10px 20px;
	gap: 20px;
	${(props) =>
		props.conditional &&
		`
      border: 1px solid ${COLORS.MediumGrey};
    `}
`;
export const ImageContainer = styled.div`
	flex: 1 1 15%;
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: contain;
`;
export const CenterData = styled.div`
	flex: 1 1 55%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-left: 1px solid ${COLORS.Grey};
	border-right: 1px solid ${COLORS.Grey};
	padding: 0px 15px;
`;
export const ProductsContainer = styled.div`
	display: flex;
	gap: 10px;
`;
export const TopCenter = styled.div`
	display: flex;
	flex-direction: column;
`;

export const RightData = styled.div`
	flex: 1 1 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const LinksContainer = styled.div`
	display: flex;
	gap: 50px;
	align-items: center;
`;

export const ExternalLink = styled.a`
	text-decoration: none;
	color: ${COLORS.Default};
	display: flex;
	align-items: center;
`;

export const ContainerModal = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const ProductContainer = styled.div`
	display: flex;
	border: 1px solid ${COLORS.LightGrey};
	border-radius: 20px;
	padding: 20px;
	justify-content: flex-start;
	gap: 30px;
`;

export const ProductSection = styled.div`
	display: flex;
	flex: 1 1 20%;
	flex-direction: column;
	gap: 5px;
`;
export const SelectData = styled.div`
	flex: 1 1 10%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-left: 1px solid ${COLORS.Grey};
	padding: 0px 15px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
`;
