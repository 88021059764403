import React, { useEffect, useMemo, useState } from "react";
import {
	BookContainer,
	BookHeaderContainer,
	ButtonContainer,
	CartDelete,
	CompaginationDiv,
	ConnectionContainer,
	Container,
	Inner,
	ModalContainer,
	PriceAndDiscountContainer,
	PriceInfoContainer,
	ProductsSection,
	SearchContainer,
	Section,
	TableInfoContainer,
	TitleContainer,
	UploadFile,
} from "./styles";
import { useNavigate } from "react-router-dom";
import LoadingSkeleton from "../components/loading-skeleton/LoadingSkeleton";
import { Button, Modal, Space, Spin } from "antd";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import { get_market_products_dapper } from "../../../../../../api/endpoints/products";
import Image from "../../../../../common/image/Image";
import {
	CloseCircleOutlined,
	InfoCircleFilled,
	LeftOutlined,
	RetweetOutlined,
	RightCircleOutlined,
	RightOutlined,
	ShoppingCartOutlined,
} from "@ant-design/icons";
import Main from "../../../../../layout/main/Main";
import TemplatesTable from "../../components/templates-table/TemplatesTable";
import debounce from "lodash/debounce";
import ProductsTable from "../components/products-table/ProductsTable";
import { COLORS, Typo } from "../../../../../layout/theme";
import {
	create_book,
	get_book,
	has_book,
	update_item_quantity,
} from "../../../../../../api/endpoints/bookofmissings";
import CreateBook from "../create-book/CreateBook";
import { useBook } from "../../../../../../contexts/bookContext";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { calculatePrice } from "../../../../../../utils/calculatePrice";
import ProductQuantity from "../../components/product-quantity/ProductQuantity";
import { import_distributor_file } from "../../../../../../api/endpoints/import";
import { CONSTANTS } from "../../../../../../utils/constants";
const { confirm } = Modal;

let productIndex = null;

export default function BookList({
	userdata,
	hasBook,
	setHasBook,
	logoImportFile,
	setLogoImportFile,
	logoImportSoftware,
	setLogoImportSoftware,
	connectionType,
	setConnectionType,
	connectionName,
	setConnectionName,
	reload,
	setReload,
}) {
	const [templateProducts, setTemplateProducts] = useState([]);
	const navigate = useNavigate();
	const [submitting, setSubmitting] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [book, setBook] = useState(null);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(100);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [totalCount, setTotalCount] = useState(null);
	const { update_book, quantity_book, sendProdsToCartFromBook } = useBook();
	const [dataSource, setDataSource] = useState(null);
	const [searchModalShow, setSearchModalShow] = useState(false);
	const [showUploadFileModal, setShowUploadFileModal] = useState(false);
	const [file, setFile] = useState(null);
	const [logoSheet, setLogoSheet] = useState(null);
	const [hover, setHover] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [listHasConflicts, setListHasConflicts] = useState(false);

	const fetch_book = async () => {
		setLoading(true);
		const hasBook = await has_book();
		setHasBook(hasBook);

		if (hasBook) {
			const res = await get_book();
			setBook(res);
			setLogoImportFile(res?.urlIconBook1);
			setLogoImportSoftware(res?.urlIconBook2);
			setLogoSheet(res?.urlIconBook3);
			setConnectionType(res?.clientImportType);
			setConnectionName(res?.clientConnectionName);
			setListHasConflicts(res?.listHasConflicts);

			var uniqueArray = res.items.filter(
				(value, index, self) =>
					index === self.findIndex((t) => t.product.id === value.product.id)
			);

			setTemplateProducts(
				uniqueArray.map((i) => ({ ...i.product, productId: i.product.id }))
			);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetch_book();
		//setShowUploadFileModal(true);
	}, [reload]);

	const productColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			width: 100,
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image width={40} height={40} src={value} alt={record.title} />
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			render: (v, r, i) => (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<Typo level={6}>
						{v}{" "}
						<Typo type="muted" level={7}>
							{r.presentationName}
						</Typo>
					</Typo>
					<Typo type="muted">{r.clientName}</Typo>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio</div>,
			dataIndex: "clientTypeOfSale",
			key: "clientTypeOfSale",
			render: (v, r, i) => (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<div
						style={{
							display: "flex",
							gap: "25px",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Typo level={8} type="muted">
							{v}
						</Typo>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<span>${numberWithDotAndCommas(r.price)}</span>
							<Typo type="success" level={6}>
								{`${numberWithDotAndCommas(
									(100 - (r.offerPrice * 100) / r.price).toFixed(2)
								)}%`}
							</Typo>
						</div>
					</div>
					{r.triggerQty !== "0" && (
						<Typo
							level={8}
							type="primary"
							style={{ textAlign: "center" }}
							translate="no"
						>
							{r.triggerQty} o más{" "}
							{`${numberWithDotAndCommas(
								(
									100 -
									((r.offerPrice - (r.offerPrice * r.triggerDiscount) / 100) *
										100) /
										r.price
								).toFixed(2)
							)}`}
							% OFF
						</Typo>
					)}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			key: "action",
			render: (_, r, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(_.productId);
						}}
						style={{ marginRight: 20 }}
					>
						<RightCircleOutlined />
					</Button>
					{r.discount > 0 && (
						<Typo type="success" level={6}>
							psl - {numberWithDotAndCommas(r.discount)} %
						</Typo>
					)}
				</Space>
			),
		},
	];

	const columns = [
		{
			title: () => (
				<div
					style={{
						color: COLORS.Grey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Empresa
				</div>
			),
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 120,
			render: (photoUrl) => (
				<TableInfoContainer>
					<Image src={photoUrl} height={40} width={40} />
				</TableInfoContainer>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Grey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Producto
				</div>
			),
			dataIndex: "title",
			key: "title",
			render: (v, record) => (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<Typo level={6}>
						{v}{" "}
						<Typo type="primary" level={7}>
							{record.presentationName}
						</Typo>
					</Typo>
					<Typo type="muted">{record.clientName}</Typo>
				</div>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Grey,
						display: "flex",
						justifyContent: "center",
					}}
					translate="no"
				>
					Oferta PVP
				</div>
			),
			dataIndex: "clientTypeOfSale",
			key: "clientTypeOfSale",
			width: "150px",
			render: (v, r, i) => (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<PriceInfoContainer>
						<Typo level={8} type="muted">
							{v}
						</Typo>
						<PriceAndDiscountContainer>
							<span>${numberWithDotAndCommas(r.price)}</span>
							<Typo type="success" level={6}>
								{`${numberWithDotAndCommas(
									(100 - (r.offerPrice * 100) / r.price).toFixed(2)
								)}%`}
							</Typo>
						</PriceAndDiscountContainer>
					</PriceInfoContainer>
					{r.triggerQty !== 0 && (
						<Typo
							level={8}
							type="primary"
							style={{ textAlign: "center" }}
							translate="no"
						>
							{r.triggerQty} o más{" "}
							{`${numberWithDotAndCommas(
								(
									100 -
									((r.offerPrice - (r.offerPrice * r.triggerDiscount) / 100) *
										100) /
										r.price
								).toFixed(2)
							)}`}
							% OFF
						</Typo>
					)}
				</div>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Grey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Cantidad
				</div>
			),
			dataIndex: "quantity",
			key: "quantity",
			width: 140,
			render: (quantity, record, index) => (
				<ProductQuantity
					product={{ quantity, record, index }}
					onChange={handleTableChange}
				/>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Grey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Oferta PSL
				</div>
			),
			key: "offer",
			render: (_, r, index) => (
				<TableInfoContainer>
					{r.clientDeleted ? (
						<Typo type="danger" level={6}>
							Laboratorio fuera de circulación
						</Typo>
					) : r.paused || r.draft || r.deleted ? (
						<Typo type="danger" level={6}>
							Producto sin stock informado
						</Typo>
					) : r.monthsOfDifference > 3 ? (
						<Typo type="danger" level={6}>
							Producto sin stock informado
						</Typo>
					) : r.discount > 0 ? (
						<Typo type="success" level={4}>
							- {numberWithDotAndCommas(r.discount)} %
						</Typo>
					) : (
						<Typo type="muted" level={6}>
							condición habitual droguería
						</Typo>
					)}
				</TableInfoContainer>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Grey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Eliminar
				</div>
			),
			key: "action",
			render: (_, record, index) => (
				<TableInfoContainer>
					<Button type="link" onClick={() => handleDelete(_.id)}>
						<CartDelete>X</CartDelete>
					</Button>
				</TableInfoContainer>
			),
		},
	];

	const fetch_products = async (search, skipValue) => {
		setLoading(true);
		try {
			const res = await get_market_products_dapper(
				search,
				skipValue !== null ? skipValue : skip,
				take
			);

			setData(
				res.products.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);
			setDataSource(
				res.products.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);

			setTotalCount(res.totalCount);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
			setSearchModalShow(true);
		}
	};

	const handleModalOk = () => {
		setSearchTerm(null);
		setSearchModalShow(false);
	};

	const handleModalCancel = () => {
		setSearchTerm(null);
		setSearchModalShow(false);
	};

	useEffect(() => {
		if (productIndex) {
			setDataSource((prev) =>
				prev?.filter((p, idx) => p.productId !== productIndex[0]?.productId)
			);
		}
	}, [productIndex]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = async (e) => {
		setCurrentPage(1);
		setSearchTerm(e.target.value);
		fetch_products(e.target.value, 0);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	//////**********************************************************//////

	const handleTableChange = debounce(async (values) => {
		const _template = templateProducts.map((p, idx) =>
			idx === values.index
				? {
						...p,
						quantity: parseInt(values.value),
				  }
				: { ...p }
		);

		setTemplateProducts(_template);

		const _selectedProduct = _template.find((p, idx) => idx === values.index);
		await update_item_quantity({
			id: _selectedProduct.boMIID,
			quantity: parseInt(values.value),
		});
	}, 300);

	//////******************************************************** */

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => p.productId === e);

		productIndex = _template;
		const auxTemplate = _template.concat(templateProducts);

		let uniqueArray = auxTemplate.filter((value, index) => {
			const _value = JSON.stringify(value);
			return (
				index ===
				auxTemplate.findIndex((obj) => {
					return JSON.stringify(obj) === _value;
				})
			);
		});

		uniqueArray = uniqueArray.filter(
			(value, index, self) =>
				index === self.findIndex((t) => t.productId === value.productId)
		);

		setTemplateProducts(uniqueArray);
		const postData = {
			products: uniqueArray?.map((element) => element.productId),
		};

		const res = await update_book(postData);
		if (res) {
			const resBook = await fetch_book();
			// setBook(resBook);

			// var uniqueArray2 = resBook.items.filter(
			// 	(value, index, self) =>
			// 		index === self.findIndex((t) => t.product.id === value.product.id)
			// );

			// setTemplateProducts(
			// 	uniqueArray2.map((i) => ({ ...i.product, productId: i.product.id }))
			// );
		}
	};

	//////******************************************************** */

	const handleDelete = async (id) => {
		const _template = templateProducts.filter((p) => p.id !== id);

		setTemplateProducts(_template);
		const postData = {
			products: _template?.map((element) => element.id),
		};

		const res = await update_book(postData);
	};

	//////******************************************************** */

	const onFinish = async (values) => {
		if (templateProducts.length === 0) {
			setSubmitting(false);
			openNotificationWithIcon(
				TYPE.ERROR,
				"El libro debe contener al menos un producto para hacer una compra inteligente"
			);
			return;
		}

		quantity_book();
		navigate(`/book/smartbuy/`);
	};

	const createBook = async () => {
		const res = await create_book();
		if (res) fetch_book();
	};

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - 100;

			fetch_products(searchTerm, skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - 100);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + 100;

		fetch_products(searchTerm, skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + 100);
	};

	const refreshPage = () => {
		setReload();
	};

	const sendToCart = async () => {
		if (listHasConflicts) {
			confirm({
				title: "Para avanzar debe quitar los productos con problemas",
				icon: <CloseCircleOutlined style={{ color: "red" }} />,
				content: "¿Desea eliminar los productos sin stock informado?",
				okText: "Sí",
				okType: "danger",
				cancelText: "No",
				onOk: () => {
					handleConflictiveProducts();
				},
			});
		} else {
			const res = await sendProdsToCartFromBook();
			if (res) {
				navigate(`/cart`);
			}
		}
	};

	const handleUploadFile = async (e) => {
		let res;
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("file", e.fileList[e.fileList.length - 1].originFileObj);
			formData.append(
				"data",
				JSON.stringify({
					clientId: "",
				})
			);
			res = await import_distributor_file(formData);
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error de archivo",
				"Verifique el formato enviado"
			);
		} finally {
			setLoading(false);
			setShowUploadFileModal(false);
			res && refreshPage();
		}
	};

	const handleUploadModalOk = () => {
		setShowUploadFileModal(false);
	};

	const handleUploadModalCancel = () => {
		setShowUploadFileModal(false);
	};

	const handleConflictiveProducts = async () => {
		setIsLoading(true);

		const _template = templateProducts.filter(
			(p) =>
				!p.paused &&
				!p.draft &&
				!p.deleted &&
				!p.clientDeleted &&
				p.monthsOfDifference < 3
		);

		console.log(templateProducts);
		console.log(_template);

		setTemplateProducts(_template);

		const postData = {
			products: _template?.map((element) => element.id),
		};

		const res = await update_book(postData);
		setIsLoading(false);
		setReload();
	};

	// if (loading)
	// 	return (
	// 		<Inner>
	// 			<LoadingSkeleton />
	// 		</Inner>
	// 	);

	if (hasBook === false) return <CreateBook cb={createBook} />;

	if (hasBook === true && !book)
		return (
			<Inner>
				<LoadingSkeleton />
			</Inner>
		);

	return (
		<Inner>
			<ConnectionContainer>
				<ButtonContainer condition={connectionType}>
					{(connectionType === CONSTANTS.BILLER_CONNECTIONS_TYPE[1].value ||
						connectionType === "DEFAULT") && (
						<Container
							onClick={() => setShowUploadFileModal(true)}
							style={{
								marginLeft: 7,
								gap: 5,
								flexDirection: "column",
							}}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginLeft: 7,
									gap: 5,
								}}
							>
								<Image src={logoImportFile} width="20px" />
								Importar archivo
							</div>
							{connectionType !== "DEFAULT" && (
								<Typo type="muted">Conectado a {connectionName}</Typo>
							)}
						</Container>
					)}
					<Modal
						title={
							<TitleContainer>
								<Typo
									type="primary"
									level={6}
									style={{ cursor: "pointer", userSelect: "none" }}
								>
									Importar datos <InfoCircleFilled />
								</Typo>
								<Typo level={7}>Información de archivo de subida</Typo>
								<Typo level={7}>Extensiones admitidas: .csv, .txt, .res</Typo>
								<Typo level={7}>
									Estructura de archivo: La primer columna debe ser el{" "}
									<b>Ean del producto</b> (codigo de barra), la segunda columna
									la <b>cantidad</b> y la tercera columna la <b>fecha</b>
								</Typo>
							</TitleContainer>
						}
						open={showUploadFileModal}
						onOk={handleUploadModalOk}
						onCancel={handleUploadModalCancel}
						centered={false}
						footer={null}
						destroyOnClose={true}
					>
						<UploadFile
							multiple={false}
							showUploadList={false}
							beforeUpload={() => false}
							action={null}
							onChange={(e) => handleUploadFile(e)}
							disabled={loading}
						>
							{loading ? (
								<Spin size="default" />
							) : (
								<Section>
									<Image src={logoSheet} width="80px" />
									<Typo level={7}>
										Haga click para elegir el archivo desde el explorador
									</Typo>
									<Typo level={7}>
										También puede arrastrarlo y soltarlo en este espacio
									</Typo>
								</Section>
							)}
						</UploadFile>
					</Modal>
					{connectionType === CONSTANTS.BILLER_CONNECTIONS_TYPE[0].value && (
						<Container
							onClick={refreshPage}
							onMouseEnter={() => setHover(true)}
							onMouseLeave={() => setHover(false)}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginLeft: 7,
									gap: 5,
								}}
							>
								<Image src={logoImportSoftware} width="20px" />
								{hover ? (
									<Typo type="muted">Refrescar conexión</Typo>
								) : (
									<Typo type="muted">Conectado a {connectionName}</Typo>
								)}
							</div>
						</Container>
					)}
				</ButtonContainer>
			</ConnectionContainer>
			<SearchContainer>
				<AntdSearch
					allowClear
					placeholder="Buscar productos"
					width={459}
					onChange={(e) => setSearchTerm(e.target.value)}
					value={searchTerm}
					onPressEnter={(e) =>
						e.target.value.toUpperCase() === "RX" ||
						e.target.value.toUpperCase() === "OTC"
							? debouncedEventHandler(e)
							: e.target.value.length > 3
							? debouncedEventHandler(e)
							: console.log(e.target.value)
					}
					disabled={loading}
				/>
				{loading && (
					<div
						style={{
							position: "absolute",
							paddingTop: 8,
						}}
					>
						<Typo type="secondary" level={7}>
							Buscando{" "}
						</Typo>{" "}
						<Spin size="small" />
					</div>
				)}
			</SearchContainer>
			<ProductsSection>
				<BookContainer>
					<BookHeaderContainer>
						<Typo type="primary" level={3}>
							Reposición
						</Typo>
						<ButtonContainer
							condition={templateProducts.length !== 0 && userdata.p_shop}
							style={{ width: 150, minHeight: 0 }}
						>
							{templateProducts.length !== 0 && listHasConflicts && (
								<Button
									icon={<RetweetOutlined />}
									type="danger"
									loading={isLoading}
									onClick={handleConflictiveProducts}
								>
									Eliminar productos sin stock informado
								</Button>
							)}
						</ButtonContainer>
						<ButtonContainer
							condition={templateProducts.length !== 0 && userdata.p_shop}
							style={{ width: 150, minHeight: 0 }}
						>
							{templateProducts.length !== 0 && userdata.p_shop && (
								<Button
									icon={<ShoppingCartOutlined />}
									type="primary"
									loading={submitting}
									onClick={sendToCart}
								>
									Enviar al carrito
								</Button>
							)}
						</ButtonContainer>
					</BookHeaderContainer>
					{templateProducts.length !== 0 && (
						<TemplatesTable
							loading={loading}
							data={templateProducts}
							columns={columns}
						></TemplatesTable>
					)}
					{templateProducts.length === 0 && (
						<div style={{ paddingTop: 40 }}>
							<Typo type="muted" level={4}>
								{connectionType !== "DEFAULT"
									? connectionType ===
									  CONSTANTS.BILLER_CONNECTIONS_TYPE[1].value
										? "No se han cargado productos"
										: "No se han importado productos"
									: "No se han cargado productos"}
							</Typo>
						</div>
					)}
				</BookContainer>
			</ProductsSection>

			<Modal
				open={searchModalShow}
				onOk={handleModalOk}
				onCancel={handleModalCancel}
				width={900}
				centered={true}
				footer={null}
				destroyOnClose={true}
			>
				<ModalContainer>
					{data && data.length > 0 && (
						<CompaginationDiv>
							{currentPage !== 1 && (
								<Button size="small" shape="circle" onClick={handleDecrement}>
									<LeftOutlined />
								</Button>
							)}
							{
								<div style={{ display: "flex", alignItems: "center" }}>
									<span>
										{currentPage * 100 > totalCount
											? totalCount
											: currentPage * 100}
										/ {totalCount}
									</span>
								</div>
							}
							{data.length === 100 && (
								<Button
									size="small"
									shape="circle"
									onClick={handleIncrement}
									disabled={data.length < 100 ? true : false}
								>
									<RightOutlined />
								</Button>
							)}
						</CompaginationDiv>
					)}
					<ProductsTable
						data={dataSource}
						columns={productColumns}
						loading={loading && !dataSource}
					/>
				</ModalContainer>
			</Modal>
		</Inner>
	);
}
