import { Space, Table, Button } from "antd";
import { useEffect, useState } from "react";
import Image from "../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../layout/theme";
import ProductQuantity from "../product-quantity/ProductQuantity";
import {
	CartDelete,
	ContainerDiv,
	PriceAndDiscountContainer,
	PriceInfoContainer,
} from "./styles";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { calculatePrice } from "../../../../../../utils/calculatePrice";

export default function ProductTable({
	data,
	onAdd,
	onDecrement,
	onIncrement,
	onDelete,
	columns,
	scrollData = null,
	fetch,
	onChange,
}) {
	const defColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 90,
			render: (photoUrl) => <Image src={photoUrl} height={40} width={40} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Laboratorio</div>,
			dataIndex: "clientName",
			key: "clientName",
			render: (value, record, index) => (
				<div key={`${value}`} style={{ display: "flex", gap: 10 }}>
					<Image
						width={20}
						height={20}
						src={record.clientPhotoUrl}
						alt={record.clientName}
					/>{" "}
					<Typo type="primary" level={6}>
						{value}
					</Typo>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			render: (value, record, index) => (
				<Typo type="primary" level={6}>
					{value} {record.presentationName}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Tipo</div>,
			dataIndex: "clientTypeOfSale",
			key: "clientTypeOfSale",
			render: (v, r, idx) => (
				<Typo level={7} type="muted">
					{v}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio</div>,
			dataIndex: "price",
			key: "price",
			render: (v, r, idx) => (
				<Typo level={6} type="darkGrey">
					$ {v}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "clientGeneralDiscount",
			key: "clientGeneralDiscount",
			render: (v, r, idx) => (
				<div style={{ display: "flex", flexDirection: "column" }}>
					<PriceAndDiscountContainer>
						<Typo type="success" level={6}>
							{numberWithDotAndCommas(
								(
									100 -
									(calculatePrice(r.price, v, r.discount, 0, 0) * 100) / r.price
								).toFixed(2)
							)}
							%
						</Typo>
					</PriceAndDiscountContainer>
					{parseInt(r.triggerQty) !== 0 && (
						<Typo
							level={8}
							type="primary"
							style={{ textAlign: "start" }}
							translate="no"
						>
							{r.triggerQty} o más {r.triggerDiscount}% OFF
						</Typo>
					)}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cantidad pedido</div>,
			dataIndex: "quantity",
			key: "quantity",
			width: 180,
			render: (quantity, record, index) => (
				<ProductQuantity
					product={{ quantity, record, index }}
					onChange={onChange}
				/>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Quitar</div>,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button type="link" onClick={() => onDelete(index)}>
						<CartDelete>X</CartDelete>
					</Button>
				</Space>
			),
		},
	];

	const [pagination, setPagination] = useState({
		current: 1,
	});

	const fetchData = async (params = {}) => {
		await fetch(params.pagination.current);

		setPagination({
			...params.pagination,
		});
	};

	useEffect(() => {
		if (!data) return;
		setPagination({
			...pagination,
			total: data.totalCount,
			pageSize: data.pageSize,
			current: data.pageNumber,
			showTotal: (total) => `Total ${total} items`,
		});
	}, [data]);

	const handleTableChange = (newPagination, filters, sorter) => {
		fetchData({
			sortField: sorter.field,
			sortOrder: sorter.order,
			pagination: newPagination,
			...filters,
		});
	};

	return (
		<ContainerDiv>
			<Table
				showHeader={true}
				columns={columns ? columns : defColumns}
				dataSource={data}
				pagination={fetch ? pagination : false}
				onChange={handleTableChange}
				rowKey={(record) => record.id}
				scroll={scrollData}
			/>
		</ContainerDiv>
	);
}
