import React from "react";
import { Button } from "antd";
import StoreSearchCard from "../../common/store-search-card/card/StoreSearchCard";
import Image from "../../common/image/Image";
import { RightOutlined } from "@ant-design/icons";
import { Typo } from "../../layout/theme";
import { Link } from "react-router-dom";

export default function StoresSearchCardContainer({ item }) {
	return (
		<Link to={`/stores/${item.slug}`}>
			<StoreSearchCard>
				<StoreSearchCard.Section>
					<StoreSearchCard.Top>
						<Image height={80} width={80} src={item.photoUrl} />
					</StoreSearchCard.Top>
					<StoreSearchCard.Info.Header>
						<Typo level={2}>{item.name}</Typo>
					</StoreSearchCard.Info.Header>
					{/* <StoreSearchCard.Info.Body>
						{item.products &&
							item.products.map((i) => (
								<Image width={60} height={60} src={i.photoUrl} />
							))}
					</StoreSearchCard.Info.Body> */}
				</StoreSearchCard.Section>
				<StoreSearchCard.LinkContainer>
					<Button>
						Ir a la tienda <RightOutlined />
					</Button>
				</StoreSearchCard.LinkContainer>
			</StoreSearchCard>
		</Link>
	);
}
