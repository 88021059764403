import styled from "styled-components";
import { COLORS } from "../../../layout/theme";
import { Input } from "antd";

export const Inner = styled.div`
	display: flex;
	width: 100%;
	background-color: transparent;
	padding-top: 20px;
`;
export const FilterSection = styled.div`
	flex: 0 0 20%;
	padding: 20px 0 40px 40px;
	height: 100%;
	overflow-x: hidden;
	overflow-y: none;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px #e3e3e3;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background: ${COLORS.LightGrey};
		border-radius: 10px;
	}

	@media only screen and (min-width: 680px) and (max-width: 692px) {
		flex: 0 0 41%;
	}

	@media only screen and (min-width: 693px) and (max-width: 847px) {
		flex: 0 0 31%;
	}

	@media only screen and (min-width: 848px) and (max-width: 1028px) {
		flex: 0 0 21%;
	}

	@media only screen and (min-width: 1029px) and (max-width: 1200px) {
		flex: 0 0 26%;
	}

	@media only screen and (min-width: 1200px) and (max-width: 1414px) {
		flex: 0 0 18%;
	}
`;

export const ResultsSection = styled.div`
	width: 100%;

	.ReactVirtualized__Grid {
	}

	${(props) =>
		props.display === "CARD"
			? `
          flex: 1 1 80%;
          padding: 20px 20px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 20px;
          gap: 20px;
          width: 100%;
          min-height: 100vh;
          background-color: rgb(243 244 246 / 1);

          @media only screen and (min-width: 760px) and (max-width: 1037px) {
		        grid-template-columns: repeat(2, 1fr);
            padding: 40px 40px
	        }

          @media only screen and (min-width: 1038px) and (max-width: 1338px) {
            grid-template-columns: repeat(3, 1fr);
          }
        

          @media only screen and (min-width: 1339px) and (max-width: 1414px) {
            grid-template-columns: repeat(4, 1fr);
            flex: 1 1 70%;
            padding: 20px 0px -10px 10px;
            gap: 10px;
          }
        `
			: `
          flex: 1 1 70%;
          padding: 2rem 5rem 1rem 5rem;
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;
          min-height: 100vh;
          background-color: rgb(243 244 246 / 1);
      @media only screen and (min-width: 760px) and (max-width: 1037px) {
          grid-template-columns: repeat(2, 1fr);
      }

	    @media only screen and (min-width: 1038px) and (max-width: 1414px) {
        grid-template-columns: repeat(3, 1fr);
      }
  `}
`;

export const ContainerDiv = styled.div`
	display: flex;
	flex-grow: 1;
	height: 100%;
	width: 100%;
	overflow: none;
	background-color: rgb(243 244 246 / 1);
	&::-webkit-scrollbar {
		display: none;
	}
`;

export const ScrollSection = styled.div`
	${(props) =>
		props.display === "CARD"
			? `
          

          @media only screen and (min-width: 760px) and (max-width: 1037px) {
	        }

          @media only screen and (min-width: 1038px) and (max-width: 1338px) {
          }
        

          @media only screen and (min-width: 1339px) and (max-width: 1414px) {
          }
        `
			: `
          
      @media only screen and (min-width: 760px) and (max-width: 1201px) {
      }

	    @media only screen and (min-width: 1202px) and (max-width: 1448px) {
        padding: 2rem 2rem 0rem 2rem;
      }

      @media only screen and (min-width: 1449px) and (max-width: 1600px) {
        padding: 2rem 3rem 0rem 3rem;
      }

      @media only screen and (min-width: 1601px) and (max-width: 1800px) {
        padding: 2rem 4rem 0rem 4rem;
      }

      @media only screen and (min-width: 1801px) and (max-width: 2100px) {
        padding: 2rem 5rem 0rem 5rem;
      }

      @media only screen and (min-width: 2101px) and (max-width: 2300px) {
        padding: 2rem 6rem 0rem 6rem;
      }

      @media only screen and (min-width: 2301px) and (max-width: 2600px) {
        padding: 2rem 8rem 0rem 8rem;
      }
  `}
`;

export const Section = styled.div`
	width: 100%;
	height: 100%;

	.ReactVirtualized__Grid {
		margin: 1rem 1rem 1rem 1rem;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	${(props) =>
		props.display === "CARD"
			? `
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 20px;

  `
			: ``}
`;

export const CompaginationDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
`;

export const Container = styled.div`
	height: 110px;
	width: 97%;
	display: flex;
	gap: 10px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-radius: 20px;
	user-select: none;
	padding: 20px;
`;

export const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-height: 500px;
	background-color: transparent;
	border-radius: 20px;
	width: 100%;
	gap: 10px;
`;

export const LoadingSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 80px 30px 80px;
`;

export const AntdInput = styled(Input)`
	.ant-input-group .ant-input {
		border: 1px solid ${COLORS.Grey};
		border-radius: 10px;
		background-color: transparent;
		text-align: end;
		padding: 5px;
	}

	.ant-input-group .ant-input:hover {
		border: 1px solid ${COLORS.Secondary};
		border-radius: 10px;
	}

	.ant-input-group .ant-input:focus {
		border-radius: 10px;
	}

	.ant-input-group-addon {
		border: 0;
		background-color: transparent;
		padding: 5px;
	}
`;
