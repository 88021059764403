import { Button } from "antd";
import styled from "styled-components";
import { COLORS } from "../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const BannerListContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const Section = styled.div``;

export const AntdButton = styled(Button)`
	background-color: ${(props) =>
		props.$active ? COLORS.Primary : COLORS.White};
	color: ${(props) => (props.$active ? COLORS.White : COLORS.Primary)};
	border-color: ${COLORS.Primary};
	border-radius: 5px;
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
`;
