import { Button, Modal, Table } from "antd";
import styled from "styled-components";
import { COLORS } from "../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	width: 100%;
	gap: 40px;
	margin-top: 30px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: column;
	}
`;

export const ImageSection = styled.div`
	min-height: 350px;
	width: 100%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 10px 20px 20px 20px;
	align-items: center;
`;

export const FilterContainer = styled.div`
	display: flex;
`;

export const AntdButton = styled(Button)`
	display: flex;
	height: 40px;
	align-items: center;
	background-color: ${(props) =>
		props.success ? COLORS.Success : COLORS.Secondary};
	color: ${COLORS.White};
	border: 1px solid
		${(props) => (props.success ? COLORS.Success : COLORS.Secondary)};
	border-radius: 7px;
	padding: 1rem;
	&:hover {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
		color: ${COLORS.White};
	}
	& span {
		color: ${COLORS.White};
	}
	position: relative;

	&:focus {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
	}
	&:active {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
	}
`;

export const Container = styled.div`
	display: flex;
	gap: 20px;
	width: 85%;
	align-items: center;
	justify-content: flex-start;
	padding-top: 20px;
`;

export const AntdTable = styled(Table)`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const TableContainer = styled.div`
	width: 85%;
`;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const AntdModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
	}

	.ant-modal-header {
		border-radius: 10px 10px 0 0;
	}
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
		padding: 1rem 5rem 1rem 5rem;
		border-radius: 0 0 10px 10px;
	}
	.ant-modal-footer {
		text-align: left;
		height: 80px;
	}
`;

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;

export const ClientData = styled.div`
	flex: 1 1 65%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
`;
export const PublishSettings = styled.div`
	display: flex;
	flex: 1 1 35%;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
`;
