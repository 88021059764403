import { Button } from "antd";
import styled from "styled-components/macro";
import { COLORS } from "../../../layout/theme";
import { SIZES } from "../../../layout/theme";
import { WEIGHT } from "../../../layout/theme";

export const Inner = styled.div`
	margin-top: -10px;
	width: 100%;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
	.ant-card-bordered {
		border: none;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-wrap: nowrap;
	gap: 20px;
`;

export const BottomContainer = styled.div`
	display: flex;
`;

export const LeftContainer = styled.div`
	flex: 1 1 40%;
`;

export const LeftCarousel = styled.div`
	width: 400px;
	align-content: center;
`;

export const MainImageContainer = styled.div`
	text-align: center;
`;

export const ImageContainer = styled.div``;

export const ImageFooter = styled.div`
	text-align: center;
`;

export const BtnCarousel = styled.div`
  & .ant-carousel .slick-dots li button {
    background-color: ${COLORS.Grey} !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 100px !important;
    opacity: 0.7 !important;
  }
  & .ant-carousel .slick-dots li button:active {
    background-color: ${COLORS.StrongGreen} !important;
    color: ${COLORS.StrongGreen} !important;
  }

  & .ant-carousel .slick-prev {
    height: 30px !important;
    width: 30px !important;
    border-radius: 50% !important;
    background-color: ${COLORS.White} !important;
    color: ${COLORS.Grey} !important;
    box-shadow: 2px 2px 9px 1px rgba(0, 0, 0, 0.1) !important;
    font-size: inherit !important;
    left: 10px !important;
    padding: 8px !important;
    font-size: ${SIZES.level7}; !important;
    z-index: 1 !important;
  }
  & .ant-carousel .slick-next {
    height: 30px !important;
    width: 30px !important;
    border-radius: 50% !important;
    background-color: ${COLORS.White} !important;
    color: ${COLORS.Grey} !important;
    box-shadow: 2px 2px 9px 1px rgba(0, 0, 0, 0.1) !important;
    font-size: inherit !important;
    right: 10px !important;
    padding: 8px !important;
    font-size: ${SIZES.level7}; !important;
  }
`;

export const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 500px;
	box-shadow: rgb(50 50 93 / 5%) 20px 0px 14px 1px,
		rgb(0 0 0 / 5%) -19px 20px 20px -10px;
	flex: 1 1 50%;
	padding: 30px 40px 10px 40px;

	.slick-list {
		padding: 0 30px;
	}
`;

export const Info = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const InfoRow = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: flex-end;
`;

export const Title = styled.div`
	max-width: 350px;
	margin-top: 5px;
`;

export const PriceTextOff = styled.p`
	font-weight: 700;
	writing-mode: vertical-lr;
	font-size: 11px;
	margin-right: -10px;
	margin-top: 12px;
	color: ${COLORS.Primary};
	transform: rotateZ(180deg);
	width: 10px;
`;

export const InputContainer = styled.div`
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
`;

export const CartButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${COLORS.Primary};
	color: ${COLORS.White} !important;
	border: none !important;
	height: 100% !important;
	border-radius: 40px;
	font-size: ${SIZES.level8} !important;
`;

export const CartButtonsContainer = styled.div`
	height: 40px;
	background: ${COLORS.Primary};
	color: ${COLORS.White};
	display: flex;
	align-items: center;
	transition: width 0.2s ease;
	z-index: 3;
	border-radius: 10px;

	& :hover {
		background: ${COLORS.Secondary};
	}

	& :hover:last-child {
		background: ${COLORS.Secondary};
		border-radius: 0 10px 10px 0;
	}

	& :hover:first-child {
		background: ${COLORS.Secondary};
		border-radius: 10px 0 0 10px;
	}

	& button {
		background: none;
		color: ${COLORS.White} !important;
		border: none !important;
		height: 100% !important;
		font-size: ${SIZES.level6} !important;
		font-weight: ${WEIGHT.Bold} !important;
		width: 100%;
	}

	& input {
		background: ${COLORS.White} !important;
		color: ${COLORS.Primary} !important;
		border: none !important;
		height: 100% !important;
		outline: none !important;
		box-shadow: none !important;
		text-align: center !important;
		font-size: ${SIZES.level6} !important;
		font-weight: ${WEIGHT.Bold} !important;
		width: 100%;
	}

	& input:hover {
		background: ${COLORS.Secondary} !important;
	}
`;

export const Loader = styled.div`
	transform: translate(0px, 0px) scale(1.5);
	z-index: 999;
`;

export const PriceTextOffContainer = styled.div`
	display: flex;
	align-items: center;
	scale: 1.3;
`;

export const SectionItems = styled.div`
	display: flex;
	flex-direction: column;
`;

export const InfoSection = styled.div`
	margin-top: 7px;
`;

export const Section = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 40px;
`;

export const SubSection = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;

export const InfoSellerSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0 20px 0 20px;
`;

export const Quantity = styled.div`
	margin-top: 10px;

	& input {
		width: 250px;
		padding: 0.2em 1em;
		color: ${COLORS.Primary};
		text-align: center;
		font-weight: ${WEIGHT.Black};
	}
	& input:hover {
		border: 1px solid ${COLORS.Primary};
	}
`;

export const Buttons = styled.div`
	height: 60px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	justify-content: space-evenly;
`;

export const ButtonsContainer = styled.div``;

export const AddToCart = styled.div`
	& button:hover {
		color: ${COLORS.Primary};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
	}

	& button {
		color: ${COLORS.White};
		font-size: ${SIZES.level7};
		font-weight: ${WEIGHT.Black};
		background-color: ${COLORS.Primary};
		padding: 0.2em 1em;
		border: 2px solid ${COLORS.Primary};
		border-radius: 20px;
		box-shadow: 0 5px 5px #bfbfbf;
	}
`;
export const MinPlusContainer = styled.div`
	display: flex;
	font-size: ${SIZES.level7};

	& button {
		color: ${COLORS.White};
		border: 2px solid ${COLORS.Primary};
		font-weight: ${WEIGHT.Black};
		background-color: ${COLORS.Primary};
	}
	& button:hover {
		color: ${COLORS.Primary};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
	}
`;

export const MinusButton = styled.div`
	& button {
		right: -2px;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		border-right: none;
		box-shadow: 0 5px 5px #bfbfbf;
	}
`;
export const PlusButton = styled.div`
	& button {
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		border-left: none;
		box-shadow: 4px 5px 5px #bfbfbf;
	}
`;

export const Line = styled.div`
	text-decoration: line-through rgba(0, 0, 0, 0.2);
	align-items: flex-start;
	align-content: center;
	justify-content: center;
	display: flex;
`;

export const ItemsQty = styled.div`
	color: ${COLORS.Grey};
	font-weight: 600;
	display: flex;
	align-content: center;
	color: #bbc4c2;
	justify-content: center;
	align-items: center;
	pointer-events: none;
`;

export const InfoDescription = styled.div`
	height: 50px;
	margin-top: 10px;
	position: relative;
`;

export const StarContainer = styled.div`
	display: flex;
	justify-content: end;
	transform: translateY(20px);
	color: ${COLORS.Default};
	font-size: 20px;
	cursor: pointer;
`;
export const MarketingContainer = styled.div`
	background-color: ${COLORS.Grey};
`;

export const ContainerShadow = styled.div``;

export const InfoIva = styled.div`
	padding-top: 5px;
	gap: 10px;
	max-width: 300px;
	font-size: 10px;
	color: ${COLORS.Default};
`;

export const SectionOffer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin-bottom: 10px;
`;

export const TriggerQtyContainer = styled.div`
	color: ${COLORS.StrongGreen};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level7};
	text-align: center;
`;

export const OffContainer = styled.div`
	writing-mode: vertical-lr;
	font-size: 8px;
	transform: rotateZ(180deg);
`;

export const SpecialOfferContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const MinQtyContainer = styled.div`
	color: ${COLORS.Primary};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level7};
	text-align: center;
	margin-bottom: 2px;

	@media only screen and (min-width: 760px) and (max-width: 1180px) {
		font-size: 10px;
	}
`;
