import React from "react";
import { Typo } from "../../../../../../../layout/theme";
import {
	Bottom,
	Container,
	InfoSection,
	Inner,
	Line,
	OffContainer,
	PriceSection,
	SubContainer,
	Top,
} from "./styles";
import { numberWithDotAndCommas } from "../../../../../../../../utils/numberWithDotAndComas";
import { calculatePrice } from "../../../../../../../../utils/calculatePrice";

export default function RecommendedProduct({
	nombre,
	price,
	percentageWith,
	percentageWithout,
	laboratory,
	prodPhoto,
	labPhoto,
	labType,
	labGeneralDiscount,
	labTier,
	labIsApproved,
	presentation,
	presentationQty,
	unit,
	prodId,
	prods,
	setProds,
	index,
	triggerQty,
	fila,
	prodDiscount,
	triggerDiscount,
}) {
	if (!nombre) return null;
	return (
		<Inner>
			<Top>
				<InfoSection>
					<Container>
						<Typo type="primary" level={4}>
							{nombre}
						</Typo>
					</Container>
					<Container>
						<SubContainer>
							<Typo type="muted" level={7}>
								{presentation} {presentationQty} {unit}
							</Typo>
						</SubContainer>
					</Container>
					<Container>
						<Typo>{laboratory}</Typo>
					</Container>
				</InfoSection>
				<PriceSection>
					<Line>
						<Typo level={6} type="muted" underline>
							$ {price}
						</Typo>
					</Line>
					<SubContainer
						style={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<OffContainer translate="no">OFF</OffContainer>
						<Typo type={labType === "PVP" ? "success" : "secondary"} level={4}>
							{numberWithDotAndCommas(
								(
									100 -
									(calculatePrice(
										price,
										labGeneralDiscount,
										prodDiscount,
										0,
										triggerDiscount
									) *
										100) /
										price
								).toFixed(2)
							)}
							%
						</Typo>
					</SubContainer>
				</PriceSection>
			</Top>
			<Bottom>
				{triggerQty > 0 && (
					<>
						<Typo level={8} type="muted">
							Comprando {triggerQty} un.
						</Typo>
						<Typo level={7} type="success">
							%{triggerDiscount}
						</Typo>
					</>
				)}
			</Bottom>
		</Inner>
	);
}
