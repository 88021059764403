import React, { useEffect, useState } from "react";
import Box from "../box/Box";
import { TbActivityHeartbeat } from "react-icons/tb";
import { Typo } from "../../../../../layout/theme";
import {
	BiBox,
	BiBuildings,
	BiDollar,
	BiLineChart,
	BiPieChart,
} from "react-icons/bi";
import { MdSafetyDivider } from "react-icons/md";
import {
	BigChartContainer,
	Container,
	Dashboard1Container,
	DoubleChartContainer,
	FilterContainer,
	HeaderContainer,
} from "./styles";
import { Button, DatePicker, Select } from "antd";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../../../../utils/constants";
import moment from "moment";

export default function Dashboard1({
	dashboard,
	optionsPie,
	timePharmacyGraph,
	timePosGraph,
	timeOrderGraph,
	posByRegionGraph,
	timeSalesGraph,
	distributorSalesGraph,
	distributors,
	selectDistributor,
	provinces,
	selectProvince,
	handleDateChange,
	deleteFilters,
	isLoading,
	selectedProvince,
	selectedDistributor,
	rangeDate,
	selectPharmacyInfo,
	pharmacyInfoSelector,
	timeSalesAgainstOrders,
}) {
	return (
		<Dashboard1Container>
			<FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Fecha (desde/hasta)
					</Typo>
					{/* <DatePicker.RangePicker onChange={(e) => handleDateChange(e)} /> */}
					{rangeDate ? (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							value={rangeDate}
							onChange={(e) => handleDateChange(e)}
						/>
					) : (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							onChange={(e) => handleDateChange(e)}
						/>
					)}
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Droguería
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Droguería"
						optionFilterProp="children"
						value={selectedDistributor}
						//defaultValue={selectedDistributor ?? null}
						onChange={(e, o) => selectDistributor(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 180 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Provincia
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Provincia"
						optionFilterProp="children"
						// defaultValue={selectedProvince ?? null}
						value={selectedProvince}
						onChange={(e, o) => selectProvince(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={provinces}
						style={{ width: 220 }}
					/>
				</Container>
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</FilterContainer>
			<HeaderContainer>
				<Box
					data={dashboard?.totalPharmacies}
					subData={
						dashboard?.actualMonthPharmacies > 0
							? dashboard?.actualMonthPharmacies
							: null
					}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					graphData={timePharmacyGraph}
					graphData2={optionsPie}
					isLoading={isLoading}
					type="headerBoxGraphAndPie"
					prefix={<BiBuildings />}
					selectPharmacyInfo={selectPharmacyInfo}
					pharmacyInfo={pharmacyInfoSelector}
					iconOption={<BiPieChart size={20} />}
					iconOption2={<BiLineChart size={20} />}
					title={
						<Typo texto="light" type="primary">
							{pharmacyInfoSelector === "TOTAL"
								? "Total farmacias"
								: pharmacyInfoSelector === "TYPES"
								? "Tipos de farmacia"
								: ""}
						</Typo>
					}
				/>
				<Box
					data={dashboard?.totalPointsOfSale}
					subData={
						dashboard?.actualMonthPointsOfSale > 0
							? dashboard?.actualMonthPointsOfSale
							: null
					}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					graphData={timePosGraph}
					isLoading={isLoading}
					type="headerBox"
					prefix={<TbActivityHeartbeat />}
					title={
						<Typo texto="light" type="primary">
							Total puntos de venta
						</Typo>
					}
				/>
				<Box
					data={dashboard?.totalOrders}
					subData={
						dashboard?.actualMonthOrders > 0
							? dashboard?.actualMonthOrders
							: null
					}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					graphData={timeOrderGraph}
					isLoading={isLoading}
					type="headerBox"
					prefix={<BiBox />}
					title={
						<Typo texto="light" type="primary">
							Total órdenes
						</Typo>
					}
				/>
				<Box
					graphData={timeSalesAgainstOrders}
					isLoading={isLoading}
					type="headerBoxGraph"
					prefix={<TbActivityHeartbeat />}
					title={
						<Typo texto="light" type="primary">
							Promedio ordenes
						</Typo>
					}
				/>
			</HeaderContainer>
			{!selectedProvince && (
				<BigChartContainer>
					<Box
						type="timeChart"
						options={posByRegionGraph}
						isLoading={isLoading}
						title={
							<Typo texto="light" type="primary">
								Puntos de venta por región
							</Typo>
						}
					/>
				</BigChartContainer>
			)}
			<DoubleChartContainer>
				<Box
					type="timeChart"
					options={timeSalesGraph}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							Ventas históricas
						</Typo>
					}
				/>
				{/* <Box
					type="timeChart"
					options={timeUnitsGraph}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							Ventas unidades históricas
						</Typo>
					}
				/> */}
				{/* </DoubleChartContainer>
			<DoubleChartContainer> */}
				<Box
					prefix={"$"}
					suffix={"un."}
					data={numberWithDotAndCommas(dashboard?.totalYearSales?.toFixed(0))}
					subData={dashboard?.totalYearUnits}
					isLoading={isLoading}
					type="anualRevenue"
					title={"Total ventas $"}
					subTitle={"Total unidades"}
				/>
				<Box
					type="timeChart"
					options={distributorSalesGraph}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							Ventas por droguería
						</Typo>
					}
				/>
			</DoubleChartContainer>
		</Dashboard1Container>
	);
}
