export const CONSTANTS = {
	PAGE_SIZE_ALL: 2500,
	PAGE_DOBLE_BIG_SIZE: 100,
	PAGE_BIG_SIZE: 50,
	PAGE_LARGE_SIZE: 30,
	PAGE_MEDIUM_SIZE: 20,
	PAGE_SMALL_SIZE: 12,
	DISTRIBUTORS_COLUMNS: [
		{ value: "OrderNumId", label: "N Transfer" },
		{ value: "Name", label: "Nombre Pdv" },
		{ value: "BusinessName", label: "Razon Social" },
		{ value: "Cuit", label: "Cuit" },
		{ value: "PostalCode", label: "Cod. Postal" },
		{ value: "Address", label: "Direccion Pdv" },
		{ value: "DistributorCode", label: "Cod. Drogueria" },
		{ value: "Created", label: "Fecha" },
		{ value: "ProductEan13", label: "Ean" },
		{ value: "ProductTitle", label: "Descripcion" },
		{ value: "ProductPresentation", label: "Presentacion" },
		{ value: "Quantity", label: "Cantidad" },
		{ value: "OfferPrice", label: "Desc. Total" },
		{ value: "GeneralDiscount", label: "Desc. Base" },
		{ value: "Discount", label: "Desc. Oferta" },
		{ value: "TriggerDiscount", label: "Desc. Cantidad" },
		{ value: "CampaignDiscount", label: "Desc. Campaña" },
		{ value: "ProvinceName", label: "Provincia" },
		{ value: "LocalityName", label: "Localidad" },
		{ value: "Empty1", label: "Vacia 1" },
		{ value: "Empty2", label: "Vacia 2" },
		{ value: "Empty3", label: "Vacia 3" },
		{ value: "Empty4", label: "Vacia 4" },
		{ value: "Empty5", label: "Vacia 5" },
		{ value: "Empty6", label: "Vacia 6" },
		{ value: "Empty7", label: "Vacia 7" },
		{ value: "Empty8", label: "Vacia 8" },
		{ value: "Empty9", label: "Vacia 9" },
		{ value: "Empty10", label: "Vacia 10" },
	],
	MEGA_DISTRIBUTORS_COLUMNS: [
		{ value: "OrderNumId", label: "N Transfer" },
		{ value: "Name", label: "Nombre Pdv" },
		{ value: "BusinessName", label: "Razon Social" },
		{ value: "Cuit", label: "Cuit" },
		{ value: "PostalCode", label: "Cod. Postal" },
		{ value: "Address", label: "Direccion Pdv" },
		{ value: "DistributorCode", label: "Cod. Cliente Drogueria" },
		{ value: "Created", label: "Fecha" },
		{ value: "ProductEan13", label: "Ean" },
		{ value: "ProductTitle", label: "Descripcion" },
		{ value: "ProductPresentation", label: "Presentacion" },
		{ value: "Quantity", label: "Cantidad" },
		{ value: "OfferPrice", label: "Desc. Total" },
		{ value: "GeneralDiscount", label: "Desc. Base" },
		{ value: "Discount", label: "Desc. Oferta" },
		{ value: "TriggerDiscount", label: "Desc. Cantidad" },
		{ value: "CampaignDiscount", label: "Desc. Campaña" },
		{ value: "DistributorName", label: "Drogueria" },
		{ value: "MegaDistributorCode", label: "Cod. Laboratorio" },
		{ value: "DistributorCuit", label: "Cuit Droguería" },
		{ value: "DistributorGln", label: "Gln Droguería" },
		{ value: "GlnAnmat", label: "Gln Pdv" },
		{ value: "PositionNumber", label: "N Posicion" },
		{ value: "ProvinceName", label: "Provincia" },
		{ value: "LocalityName", label: "Localidad" },
		{ value: "CommercialActionCode", label: "Cod. Accion Comercial" },
		{
			value: "MegaDistributorDistributorCode",
			label: "Cod. Distribuidor Drogueria",
		},
		{
			value: "MegaDistributorDistributorOfficeCode",
			label: "Cod. Sucursal Drogueria",
		},
		{ value: "ProductLaboratoryCode", label: "Cod. Producto Laboratorio" },
		{ value: "Empty1", label: "Vacia 1" },
		{ value: "Empty2", label: "Vacia 2" },
		{ value: "Empty3", label: "Vacia 3" },
		{ value: "Empty4", label: "Vacia 4" },
		{ value: "Empty5", label: "Vacia 5" },
		{ value: "Empty6", label: "Vacia 6" },
		{ value: "Empty7", label: "Vacia 7" },
		{ value: "Empty8", label: "Vacia 8" },
		{ value: "Empty9", label: "Vacia 9" },
		{ value: "Empty10", label: "Vacia 10" },
	],
	TYPE_OF_FILE: ["csv", "xls", "txt"],
	TYPE_OF_SEND: ["email", "sftp", "ftp", "api"],
	MOBILE_BREAKPOINT_WIDTH_CHECK: 768,
	DISTRIBUTORS_FILTER_WITHOUTCONTRACT: "WITHOUTCONTRACT",
	DISTRIBUTORS_FILTER_WITHCONTRACT: "WITHCONTRACT",
	DISTRIBUTORS_FILTER_SUBMENU_ALL: "ALL",
	DISTRIBUTORS_FILTER_SUBMENU_INDIVIDUAL: "INDIVIDUAL",
	ONE_TRANSFER_CLIENT_ID: "2161aac9-7c52-42e3-a6a7-552bc2e410bf",
	GENERAL_POS_HISTORY_EXPORTED: "EXPORT",
	GENERAL_POS_HISTORY_CREATED: "CREATE",
	GENERAL_POS_HISTORY_UPDATED: "UPDATE",
	GENERAL_POS_HISTORY_DELETED: "DELETE",
	AFIP_STATUS_OK: "RESPONSABLE INSCRIPTO",
	CUIT_STRUCTURE_OK: "ESTRUCTURA VALIDA",
	CUIT_STRUCTURE_ERROR: "ESTRUCTURA INVALIDA",
	BIG_BANNER_HOME: "BIGBANNERHOME",
	TRIPLE_CARD_BANNER_HOME: "TRIPLECARDBANNERHOME",
	DOUBLE_BANNER_HOME: "DOUBLEBANNERHOME",
	TRIPLE_BANNER_HOME: "TRIPLEBANNERHOME",
	OFFER_BANNER: "OFFERBANNER",
	BOOK_UP_LEFT_BANNER: "UPLEFTBANNERBOOK",
	BOOK_DOWN_LEFT_BANNER: "DOWNLEFTBANNERBOOK",
	BOOK_UP_RIGHT_BANNER: "UPRIGHTBANNERBOOK",
	BOOK_DOWN_RIGHT_BANNER: "DOWNRIGHTBANNERBOOK",

	USER_ZONE_TYPE_ROUTE: "ROUTE",
	USER_ZONE_TYPE_REGION: "REGION",

	ORDER_STATE_PENDING: "Pending",
	ORDER_STATE_ACTIVE: "Active",
	ORDER_STATE_SENDED: "Sended",
	ORDER_STATE_CANCELED: "Canceled",

	FILE_FOLDER_DISTRIBUTORS: "distributors",
	FILE_FOLDER_MEGADISTRIBUTORS: "megaDistributors",

	CLIENT_TYPE_ONETRANSFER: "ONETRANSFER",
	CLIENT_TYPE_PHARMACY: "PHARMACY",
	CLIENT_TYPE_LABORATORY: "LABORATORY",
	CLIENT_TYPE_LABORATORY_MOBILE: "LABORATORYMOBILE",
	CLIENT_TYPE_LABORATORY_EXPO: "LABORATORYEXPO",
	CLIENT_TYPE_LABORATORY_EXPO_AND_MOBILE: "LABORATORYEXPOANDMOBILE",

	ALL_VALUES: "ALL",

	BILLER_CONNECTIONS_TYPE: [
		{ value: "API", label: "API" },
		{ value: "FILE", label: "Importación de Archivo" },
	],

	CALENDAR_MONTHS: [
		"Empty",
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	],

	PERIOD_CURRENT: "CURRENT",
	PERIOD_HISTORICAL: "HISTORICAL",
	MOBILE_DASHBOARD_PERIODS: [
		{ value: "CURRENT", label: "Período actual" },
		{ value: "HISTORICAL", label: "Histórico" },
	],

	DASHBOARD_FILTERS: "DASHBOARDFILTERS",
	DASHBOARD_GENERAL: "GENERALDASHBOARD",
	DASHBOARD_SALES: "SALESDASHBOARD",
	DASHBOARD_MOBILE: "MOBILEDASHBOARD",
	DASHBOARD_PHARMACY: "PHARMACYDASHBOARD",
	DASHBOARD_REGION: "REGIONDASHBOARD",
	DASHBOARD_DISTRIBUTOR: "DISTRIBUTORDASHBOARD",
	DASHBOARD_LABORATORY: "DISTRIBUTORLABORATORY",

	CONTAINER_FILES: "files",
	CONTAINER_IMAGES: "images",
	CONTAINER_GUIDES: "guides",

	BOOK_LIST: "BOOKLIST",

	FEATURED_CATALOG: "FEATUREDCATALOG",
	FEATURED_HOME: "FEATUREDHOME",
	FEATURED_PRODUCTS: "FEATUREDPRODUCTS",
	FEATURED_STORES: "FEATUREDSTORES",
	FEATURED_LABORATORIES: "FEATUREDLABORATORIES",

	DISTRIBUTOR_GLOBAL_FARM: "8b0a2eee-2720-40d7-a781-014592664485",

	PRODUCTS_ORIGIN_EXPO_EVENT: "EXPOEVENT",
	PRODUCTS_ORIGIN_WEB_SELLER: "WEBSELLER",
	PRODUCTS_ORIGIN_MOBILE: "MOBILE",

	COUPON_TYPES: [
		{ value: "GENERAL", label: "General" },
		{ value: "TRIGGER", label: "Por disparador" },
		{ value: "MULTILAB", label: "Multi laboratorio" },
	],

	MOBILE_CONFIG_APP_LOGO: "APPLOGOFILE",
	MOBILE_CONFIG_POS_IMAGE: "POSFILE",
	MOBILE_CONFIG_OFFER_IMAGE: "OFFERFILE",
	MOBILE_CONFIG_CART_IMAGE: "CARTFILE",
	MOBILE_CONFIG_ORDER_IMAGE: "ORDERFILE",

	DISTRIBUTOR_TYPE_DISTRIBUTOR: "DISTRIBUTOR",
	DISTRIBUTOR_TYPE_LABORATORY: "LABORATORY",
};
