import React from "react";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import Image from "../../../common/image/Image";
import MarketLogo from "../../../../assets/images/Onetransfer-logo.png";
import { Typo } from "../../../layout/theme";
import { Main } from "./styles";

export default function MobileSignIn() {
	return (
		<SignInTemplate>
			<Main>
				<SignInTemplate.Logo>
					<Image
						src={MarketLogo}
						alt="Logo FarmaTransfer"
						height="45px"
						width="220px"
					/>
				</SignInTemplate.Logo>
				<Typo level={4} type="danger">
					Aviso
				</Typo>
				<Typo level={5} type="danger">
					Dispositivo no admitido
				</Typo>
				<Typo level={6} type="darkGrey">
					Le recomendamos el uso de Tablet, Laptop o PC
				</Typo>
				<Typo level={6} type="darkGrey">
					Por favor, ingrese nuevamente desde otro dispositivo
				</Typo>
			</Main>
		</SignInTemplate>
	);
}
