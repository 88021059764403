import React, { useEffect, useState } from "react";
import Main from "../../../../../layout/main/Main";
import { ButtonContainer, ChildrenContainer, AntdButton } from "./styles";
import AsignDistributors from "../asign-distributor/AsignDistributors";
import { CONSTANTS } from "../../../../../../utils/constants";

export default function AsignMenu({
	selectedClient,
	setSelectedClient,
	clients,
	clientType,
}) {
	const [subMenufilter, setsubMenuFilter] = useState(
		CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_ALL
	);

	const get_filter = () => {
		setsubMenuFilter(
			localStorage.getItem("asignDistributorsMenuFilter")
				? localStorage.getItem("asignDistributorsMenuFilter")
				: clientType === CONSTANTS.DISTRIBUTORS_FILTER_WITHCONTRACT
				? CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_INDIVIDUAL
				: selectedClient
				? CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_INDIVIDUAL
				: CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_ALL
		);
	};

	useEffect(() => {
		get_filter();
	}, []);

	return (
		<div>
			<Main.Body>
				<ButtonContainer>
					{clientType === CONSTANTS.DISTRIBUTORS_FILTER_WITHOUTCONTRACT && (
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() =>
								setsubMenuFilter(CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_ALL)
							}
							$active={
								subMenufilter === CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_ALL
							}
						>
							Todos
						</AntdButton>
					)}
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={220}
						height={48}
						fontSize={12}
						onClick={() =>
							setsubMenuFilter(CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_INDIVIDUAL)
						}
						$active={
							subMenufilter === CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_INDIVIDUAL
						}
					>
						Individual
					</AntdButton>
				</ButtonContainer>
				<ChildrenContainer>
					{subMenufilter === CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_ALL &&
						clientType === CONSTANTS.DISTRIBUTORS_FILTER_WITHOUTCONTRACT && (
							<AsignDistributors
								clients={clients}
								type={subMenufilter}
								clientType={clientType}
								selectedClient={selectedClient}
								setSelectedClient={setSelectedClient}
							/>
						)}
					{subMenufilter ===
						CONSTANTS.DISTRIBUTORS_FILTER_SUBMENU_INDIVIDUAL && (
						<AsignDistributors
							clients={clients}
							type={subMenufilter}
							clientType={clientType}
							selectedClient={selectedClient}
							setSelectedClient={setSelectedClient}
						/>
					)}
				</ChildrenContainer>
			</Main.Body>
		</div>
	);
}
