import styled from "styled-components";
import { COLORS, WEIGHT } from "../../../../../layout/theme";
import { Button } from "antd";

export const Section = styled.div`
	background-color: white;
	border-radius: 10px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	min-height: 120px;
	padding: 2rem;
	flex-direction: column;
	gap: 10px;
`;

export const InnerForm = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const AntdButton = styled(Button)`
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}

  border-radius: 20px;

	background-color: ${(props) =>
		props.backGroundColor ? props.backGroundColor : COLORS.Secondary};
	color: ${COLORS.White};
	font-weigth: ${WEIGHT.Bold};

	span {
		${(props) =>
			props.height &&
			`
        height: ${props.height}px;
      `}
		${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
	}
`;

export const Container = styled.div`
	display: flex;
	gap: 30px;
`;

export const AfipCuitInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	max-height: 300px;
`;
