import React from "react";
import {
	Body,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbSeparator,
	ActiveBreadcrumbItem,
	Header,
	Inner,
	SubTitle,
	Title,
} from "./styles";

const Main = ({ children, ...restProps }) => (
	<Inner {...restProps}>{children}</Inner>
);

export default Main;

Main.Header = ({ children, ...restProps }) => {
	return <Header {...restProps}>{children}</Header>;
};

Main.Breadcrumb = ({ children, ...restProps }) => {
	return <Breadcrumb {...restProps}>{children}</Breadcrumb>;
};

Main.BreadcrumbSeparator = ({ children, ...restProps }) => {
	return <BreadcrumbSeparator {...restProps}>/</BreadcrumbSeparator>;
};

Main.BreadcrumbItem = ({ children, ...restProps }) => {
	return <BreadcrumbItem {...restProps}>{children}</BreadcrumbItem>;
};

Main.ActiveBreadcrumbItem = ({ children, ...restProps }) => {
	return <ActiveBreadcrumbItem {...restProps}>{children}</ActiveBreadcrumbItem>;
};

Main.Title = ({ children, ...restProps }) => {
	return <Title {...restProps}>{children}</Title>;
};

Main.SubTitle = ({ children, ...restProps }) => {
	return <SubTitle {...restProps}>{children}</SubTitle>;
};

Main.Body = ({ children, ...restProps }) => {
	return <Body {...restProps}>{children}</Body>;
};
