import styled from "styled-components/macro";
import { Button as ButtonAntd, Select } from "antd";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 40px;

	@media only screen and (min-width: 700px) and (max-width: 1190px) {
		flex-direction: column;
	}

	@media only screen and (min-width: 1191px) and (max-width: 1231px) {
		flex-direction: row;
		gap: 10px;
	}
`;

export const OptionsSection = styled.div`
	display: flex;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	margin-bottom: 30px;
	gap: 20px;
`;

export const ProductsSection = styled.div`
	flex: 1 1 80%;
	height: 100%;
	width: 100%;
	overflow: hidden;
`;

export const InfoSection = styled.div`
	flex: 1 1 25%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	max-height: 560px;
`;

export const CartTempButton = styled(ButtonAntd)`
	height: 100%;
	margin-right: 18%;
`;

export const AddTemplateIconContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 30px;
	height: 30px;
	align-items: center;
	margin-left: 0.01em;
`;

export const AddTemplateIconParagraph = styled.div`
	display: flex;
	width: 30px;
	position: absolute;
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level6};
	margin-left: 73px;
	text-decoration: none;
`;

export const PointOfSaleSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &..ant-select-arrow {
      
    }
  

  &.ant-select-selector{
    .ant-select-selection-item {
      font-size: ${SIZES.level7};
      color: ${COLORS.Primary};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${COLORS.Primary};
      }
    }
  }
`;

export const CartAntdButton = styled(ButtonAntd)`
	cursor: pointer;
	height: 40px;

	&:hover {
		color: ${COLORS.Primary};
		border: 2px solid ${COLORS.White};
		background-color: ${COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}

	background-color: ${COLORS.Secondary};
	color: ${COLORS.White};
	border-radius: 5px;
	box-shadow: 0 3px 3px 0 #c7c7c7;
`;

export const Container = styled.div`
	padding-top: 1rem;
`;

export const InfoSubSection = styled.div`
	display: flex;
	justify-content: space-evenly;
	margin: 15px 0px 0px 0px;
	align-content: center;
	align-items: center;

	@media only screen and (min-width: 760px) and (max-width: 979px) {
		justify-content: center;
		gap: 20px;

		div {
			font-size: ${SIZES.level12};
		}
	}
`;

export const InfoTitle = styled.div`
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitleTotal = styled.div`
	color: ${COLORS.Primary};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitleProductQuantity = styled.div`
	color: ${COLORS.Default};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoTitleBuy = styled.div`
	color: ${COLORS.Primary};
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.Black};
`;

export const InfoSubTitle = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;
export const InfoNumber = styled.div`
	outline: auto;
	outline-width: thin;
	outline-color: ${COLORS.Default};
	border-radius: 5px;
	width: 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.Default};
	font-size: ${SIZES.level9};
`;

export const InfoTitleProductQuantityItems = styled.div`
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.ExtraBold};
`;

export const InfoNumberItems = styled.div`
	outline: auto;
	outline-width: thin;
	outline-color: ${COLORS.Grey};
	border-radius: 5px;
	width: 60px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.Grey};
	font-size: ${SIZES.level9};
`;

export const InfoIva = styled.div`
	font-size: 10px;
	align-items: center;
	align-content: center;
	justify-content: center;
	width: 100%;
	text-align: center;
	color: ${COLORS.Default};
`;

export const DiscountsAlertContainer = styled.div`
	color: ${COLORS.Success};
	display: flex;
	position: absolute;
	top: 15px;
	left: 40px;
`;

export const TriggerQtyContainer = styled.div`
	color: ${COLORS.StrongGreen};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level8};
	text-align: center;
	position: absolute;
	bottom: 5px;
	width: 90px;
`;

export const CartDelete = styled.div`
	color: ${COLORS.Grey};
	font-family: sans-serif;
	font-stretch: ultra-expanded;
	font-size: ${SIZES.level12};
	font-weight: ${WEIGHT.Bold};
`;

export const SelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const DistributorErrorContainer = styled.div`
	display: vertical;
	justify-content: space-between;
	align-items: center;
`;

export const DistributorTableSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border: 2px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  

  &.ant-select-selector{
    .ant-select-selection-item {
      font-size: ${SIZES.level7};
      color: ${(props) => (props.condition ? COLORS.MediumGrey : COLORS.Grey)};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${(props) =>
					props.condition ? COLORS.MediumGrey : COLORS.Grey};
      }
    }
  }
`;

export const DistributorSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border: 1px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &..ant-select-arrow {
      
    }
  

  &.ant-select-selector{
    .ant-select-selection-item {
      font-size: ${SIZES.level7};
      color: ${COLORS.Primary};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${COLORS.Primary};
      }
    }
  }
`;

export const DistributorError = styled.div`
	color: ${COLORS.Warning};
	font-size: ${SIZES.level8};
	position: absolute;
	bottom: 0;
`;

export const WhiteSpace = styled.div`
	display: flex;
	width: 260px;
`;

export const Section = styled.div`
	padding: 20px 0;
	padding: 0 30px 10px 30px;
	user-select: none;
`;

export const SectionTitle = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-weight: ${WEIGHT.Black};
	padding: 40px 0 30px 0;
`;

export const PresentationTitle = styled.div`
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level9};
	font-weight: ${WEIGHT.SemiBold};
`;

export const DiscountsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export const PriceContainer = styled.div`
	display: flex;
	gap: 5px;
	align-items: baseline;
	justify-content: center;
`;
