import styled from "styled-components/macro";
import { COLORS } from "../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;
export const LeftSection = styled.div`
	flex: 1 1 65%;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const RightSection = styled.div`
	flex: 1 1 35%;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const PublishSettings = styled.div`
	flex: 0 0 30%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
`;

export const StoreData = styled.div`
	flex: 1 1 40%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const SectionData = styled.div`
	flex: 1;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const SalesData = styled.div`
	flex: 0;
	background-color: ${COLORS.White};
	display: flex;
	flex-direction: column;
	padding: 2rem;
	border-radius: 10px;
	gap: 30px;
`;

export const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 30px;
	align-items: center;

	.ant-form-item-explain-error {
		white-space: nowrap;
	}
`;
