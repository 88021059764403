import { DatePicker } from "antd";
import styled from "styled-components";
import { COLORS, SIZES } from "../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 1600px;
	height: 100vh;
	width: 100%;
	background-color: transparent;
	padding-top: 20px;
`;
export const FilterSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	padding: 1rem;

	@media only screen and (min-width: 680px) and (max-width: 692px) {
	}

	@media only screen and (min-width: 693px) and (max-width: 847px) {
	}

	@media only screen and (min-width: 848px) and (max-width: 1028px) {
	}

	@media only screen and (min-width: 1029px) and (max-width: 1200px) {
	}

	@media only screen and (min-width: 1200px) and (max-width: 1414px) {
	}
`;

export const ResultsSection = styled.div`
	width: 100%;
	height: 100vh;

	.ReactVirtualized__Grid {
	}
	flex: 1 1 70%;
	padding: 2rem 5rem 1rem 5rem;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	min-height: 100%;
	height: auto;
	background-color: rgb(243 244 246 / 1);
	@media only screen and (min-width: 760px) and (max-width: 1037px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media only screen and (min-width: 1038px) and (max-width: 1414px) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const ContainerDiv = styled.div`
	display: flex;
	flex-grow: 1;
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-color: rgb(243 244 246 / 1);
	padding: 0 6rem 0 6rem;
`;

export const ScrollSection = styled.div`
	@media only screen and (min-width: 760px) and (max-width: 1201px) {
	}

	@media only screen and (min-width: 1202px) and (max-width: 1448px) {
		padding: 0rem 2rem 0rem 2rem;
	}

	@media only screen and (min-width: 1449px) and (max-width: 1600px) {
		padding: 0rem 3rem 0rem 3rem;
	}

	@media only screen and (min-width: 1601px) and (max-width: 1800px) {
		padding: 0rem 4rem 0rem 4rem;
	}

	@media only screen and (min-width: 1801px) and (max-width: 2100px) {
		padding: 0rem 5rem 0rem 5rem;
	}

	@media only screen and (min-width: 2101px) and (max-width: 2300px) {
		padding: 0rem 6rem 0rem 6rem;
	}

	@media only screen and (min-width: 2301px) and (max-width: 2600px) {
		padding: 0rem 8rem 0rem 8rem;
	}
`;

export const Section = styled.div`
	width: 100%;
	height: 100%;

	.ReactVirtualized__Grid {
		margin: 1rem 1rem 1rem 1rem;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
`;

export const Container = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	gap: 20px;
`;

export const AntdDatePicker = styled(DatePicker)`
	border-radius: 5px;
	border: 1px solid ${COLORS.Grey};
`;
