import styled from "styled-components";
import { COLORS } from "../../../../../../layout/theme";
import { Button, Modal, Table } from "antd";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const AntdModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
	}

	.ant-modal-header {
		border-radius: 10px 10px 0 0;
	}
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
		padding: 1rem 5rem 1rem 5rem;
		border-radius: 0 0 10px 10px;
	}
	.ant-modal-footer {
		text-align: left;
		height: 80px;
	}
`;

export const TableContainer = styled.div`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: transparent !important;
		border: none;
	}

	& .hasChanges,
	& .hasChanges:hover {
		color: white;
		background-color: ${COLORS.Default};
	}
`;

export const ConfirmButton = styled.div`
	${(props) =>
		!props.clientId
			? `
	display: flex;
	justify-content: end;
  gap: 20px;`
			: `display: flex;
	justify-content: start;
  gap: 20px;`}
`;
export const AntdButton = styled(Button)`
	width: 200px;
	height: 40px;
	background-color: ${(props) =>
		props.success ? COLORS.Success : COLORS.Default};
	color: ${COLORS.White};
	border: 1px solid
		${(props) => (props.success ? COLORS.Success : COLORS.Default)};
	border-radius: 7px;
	padding: 0.5rem 0;
	&:hover {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Default};
	}
	& span {
		color: ${COLORS.White};
	}
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
`;

export const CompaginationDiv = styled.div`
	display: flex;
	gap: 4px;
`;
export const SpinnerContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	gap: 20px;
`;
