import { QuestionCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { get_all_marketings } from "../../../../api/endpoints/marketing";
import { useAuth } from "../../../../contexts/authContext";
import CampaingCardContainer from "../../../containers/campaign-cards/CampaingCardContainer";
import Main from "../../../layout/main/Main";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import { Typo } from "../../../layout/theme";
import SkeletonPublicity from "./componentes/skeleton-publicity/SkeletonPublicity";
import { Inner } from "./styles";

export default function PublicityCampaigns() {
	const navigate = useNavigate();
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const [campaigns, setCampaigns] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const fetch_all_campaigns = async () => {
		setIsLoading(true);
		try {
			const res = await get_all_marketings();
			setCampaigns(res);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetch_all_campaigns();
	}, []);

	const handleBackToTheGuide = () => {
		updateStartGuideProgress(user.uid, 7);
		navigate("/startGuide");
	};

	if (isLoading) return <SkeletonPublicity />;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Campañas Publicitarias
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Inner>
					{userdata?.guideProgress === 6 && (
						<SignInTemplate.AntdButton
							type="primary"
							bg="Primary"
							color="White"
							icon={<QuestionCircleOutlined />}
							onClick={handleBackToTheGuide}
							style={{
								width: "250px",
								fontWeight: "400",
							}}
						>
							Volver a la Guia de Inicio
						</SignInTemplate.AntdButton>
					)}
					{campaigns?.length !== 0 ? (
						campaigns?.map((i) => <CampaingCardContainer item={i} />)
					) : (
						<Typo type="secondary" level={3}>
							No hay campañas publicitarias actualmente
						</Typo>
					)}
				</Inner>
			</Main.Body>
		</Main>
	);
}
