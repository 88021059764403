import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Main from "../../../layout/main/Main";
import LoadingHeader from "./components/loading-header/LoadingHeader";
import AntdSearch from "../../../common/antd-search/AntdSearch";
import { Button, Checkbox, DatePicker, Select, Space } from "antd";
import { CompaginationDiv, Filters, TableContainer } from "./styles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ReportProductTable from "./components/report-product-table/ReportProductTable";
import { COLORS, Typo } from "../../../layout/theme";
import { get_market_products_report } from "../../../../api/endpoints/products";
import moment from "moment";

export default function InteractionReport({ clientId }) {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [interactionDetails, setInteractionDetails] = useState(null);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [loading, setLoading] = useState(false);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(300);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [productToSearch, setProductToSearch] = useState("");
	const [brandToSearch, setBrandToSearch] = useState("");
	const [dateToSearch, setDateToSearch] = useState(
		moment().format("DD/MM/YYYY")
	);
	const [brands, setBrands] = useState(null);

	const fetch_products = async (search = "", skipValue = null) => {
		setLoading(true);
		try {
			const res = await get_market_products_report(
				search,
				skipValue !== null ? skipValue : skip,
				take,
				state,
				productToSearch,
				brandToSearch,
				clientId,
				dateToSearch,
				"ACTIVE"
			);

			setData(res?.productsReport);
			setInteractionDetails(res?.interactionInfo);
			setBrands(
				res?.brandsFilter?.map((r) => ({
					value: r.brandId,
					label: r.brandName,
				}))
			);
			setHeaderInfo(true);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch_products();
	}, [state, brandToSearch, dateToSearch]);

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - 100;

			fetch_products(searchTerm, skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - 100);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + 100;

		fetch_products(searchTerm, skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + 100);
	};

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setSearchTerm(e.target.value);
		fetch_products(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Laboratorio</div>,
			dataIndex: "clientName",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Marca</div>,
			dataIndex: "brandName",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "productTitle",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Presentación</div>,
			dataIndex: "productPresentation",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Vistas</div>,
			dataIndex: "productVisits",
			sorter: (a, b) => a.productVisits - b.productVisits,
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Ventas</div>,
			dataIndex: "productSales",
			sorter: (a, b) => a.productSales - b.productSales,
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Conversión</div>,
			dataIndex: "productRelation",
			sorter: (a, b) => a.productRelation - b.productRelation,
			render: (value, record) => (
				<Typo
					type={`${value > 50 ? "success" : "warning"}`}
					fontWeight={600}
					fontSize={14}
				>
					{value} %
				</Typo>
			),
		},
	];

	const selectBrand = async (e) => {
		setBrandToSearch(e);
	};

	const handleDateChange = (date) => {
		setDateToSearch(date.format("DD/MM/YYYY").toString());
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Reporte Productos
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<AntdSearch
								width={400}
								allowClear
								placeholder="Buscar producto"
								onChange={debouncedEventHandler}
							/>
							<Select
								showSearch
								allowClear
								placeholder="Buscar marca"
								optionFilterProp="children"
								onChange={(e) => selectBrand(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={brands}
								style={{ width: 180 }}
							/>
							<DatePicker picker="month" onChange={handleDateChange} />
						</Space>
					)}
				</Filters>
				<TableContainer>
					<ReportProductTable
						data={data}
						columns={columns}
						loading={loading}
						isHeaderShow={true}
						scroll={{ y: 900 }}
					/>
				</TableContainer>
				{data && data.length > 0 && (
					<CompaginationDiv>
						{currentPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						{data.length === 100 && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={data.length < 100 ? true : false}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
			</Main.Body>
		</Main>
	);
}
