import React, { useState } from "react";
import {
	CartButtomNumber,
	CartButton,
	CartButtonsContainer,
	CenterData,
	Container,
	ImageContainer,
	Inner,
	InputContainer,
	ItemsQty,
	Line,
	Loader,
	OffContainer,
	PriceAndDiscountContainer,
	PriceInfoContainer,
	RightData,
	Section,
	SectionOffer,
} from "./styles";
import Image from "../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import {
	LoadingOutlined,
	MinusOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Spin } from "antd";
import { FaShoppingCart } from "react-icons/fa";
import { useCart } from "../../../../../../contexts/cartContext";
import { useAuth } from "../../../../../../contexts/authContext";
import { compareWithToday } from "../../../../../../utils/compareDates";

export default function FeaturedGroupCardContainer({ item }) {
	const { addToCart, cartItems } = useCart();
	const { userdata } = useAuth();
	const [quantity, setQuantity] = useState(item.minQuantity);
	const [submitting, setSubmitting] = useState(false);
	const [qtyInCart, setQtyInCart] = useState(
		cartItems && cartItems[item.id]
			? cartItems[item.id].quantity
			: item.productQtyInCart
			? item.productQtyInCart
			: 0
	);
	const [showAddToCart, setShowAddToCart] = useState(
		item.productQtyInCart ? true : false
	);

	// const handleDecrement = () => {
	// 	if (quantity > item.minQuantity) setQuantity((prev) => prev - 1);
	// };

	// const handleChange = (e) => {
	// 	if (e.target.value >= item.maxQuantity) e.target.value = item.maxQuantity;
	// 	const onlyPositiveInteger = /^[0-9\b]+$/;
	// 	if (onlyPositiveInteger.test(e.target.value))
	// 		setQuantity(parseInt(e.target.value));
	// };

	// const handleIncrement = (e) => {
	// 	if (quantity >= item.maxQuantity) setQuantity(item.maxQuantity - 1);
	// 	setQuantity((prev) => prev + 1);
	// };

	// const handleAddToCart = async () => {
	// 	setSubmitting(true);
	// 	const res = await addToCart(item.id, quantity);
	// 	setQtyInCart(res);
	// 	setQuantity(item.minQuantity);

	// 	setSubmitting(false);
	// };

	const handleDecrement = async () => {
		setSubmitting(true);
		let res;
		let newQuantity = quantity;
		if (quantity > item.minQuantity && quantity > 1) {
			newQuantity -= 1;
			setQuantity((prev) => prev - 1);
		}
		if (newQuantity <= item.minQuantity) {
			res = await addToCart(item.id, item.minQuantity, false);
		} else {
			res = await addToCart(item.id, 1, false);
		}
		if (res === 0) {
			setQtyInCart(res);
			setQuantity(item.minQuantity > 0 ? item.minQuantity : 1);
			item.productQtyInCart = 0;
			setShowAddToCart(false);
		} else {
			setQtyInCart(res);
			setQuantity(res);
			item.productQtyInCart = res;
			setShowAddToCart(true);
		}
		setSubmitting(false);
	};

	const handleChange = (e) => {
		if (e.target.value <= item.minQuantity) {
			e.target.value = item.minQuantity;
			e.target.setSelectionRange(0, 0);
			e.target.focus();
			e.target.select();
		}
		if (e.target.value >= item.maxQuantity) e.target.value = item.maxQuantity;
		const onlyPositiveInteger = /^[0-9\b]+$/;
		if (onlyPositiveInteger.test(e.target.value))
			setQuantity(parseInt(e.target.value));
	};

	const handleIncrement = async (e) => {
		setSubmitting(true);
		setQuantity((prev) => prev + 1);
		if (quantity >= item.maxQuantity) {
			setQuantity((prev) => prev - 1);
		} else {
			const res = await addToCart(item.id, 1);
			setQtyInCart(res);
			setQuantity(res);
			item.productQtyInCart = res;
			setShowAddToCart(true);
			setSubmitting(false);
		}
	};

	const handleAddToCart = async () => {
		setSubmitting(true);
		const res = await addToCart(item.id, quantity);
		setQtyInCart(res);
		setQuantity(item.minQuantity);
		item.productQtyInCart = res;
		setShowAddToCart(true);
		setSubmitting(false);
	};

	if (!item) return <div>Loading...</div>;

	return (
		<Inner>
			<Col span={3}>
				<ImageContainer>
					<Image
						src={item.photoUrl}
						height={60}
						borderRadius="10px"
						preview={true}
					/>
				</ImageContainer>
			</Col>
			<Col span={7}>
				<Container>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={item.title.length < 15 ? 18 : 16}
					>
						{item.title}{" "}
						<Typo type="muted" lineHeight={1} level={6}>
							{item.presentation?.name}
						</Typo>
					</Typo>
					<Typo type="muted" level={6}>
						{item.client?.name.toUpperCase()}
					</Typo>
				</Container>
			</Col>
			<Col span={4}>
				<Container>
					<PriceInfoContainer>
						<Typo level={7} type="muted">
							{item.client?.typeOfSale}
						</Typo>
						<PriceAndDiscountContainer>
							<span>${numberWithDotAndCommas(item.price)}</span>
							<Typo type="secondary" level={5}>
								{`${numberWithDotAndCommas(
									(100 - (item.offerPrice * 100) / item.price).toFixed(2)
								)}%`}
							</Typo>
						</PriceAndDiscountContainer>
					</PriceInfoContainer>
					<SectionOffer
						style={{
							backgroundColor:
								item.triggerQty > 0
									? COLORS.Success
									: item.minQuantity > 1
									? COLORS.White
									: COLORS.White,
						}}
					>
						{item.triggerQty > 0 ? (
							<Typo
								level={7}
								type="white"
								style={{ textAlign: "center" }}
								translate="no"
							>
								{item.triggerQty} o más{" "}
								{`${numberWithDotAndCommas(
									(
										100 -
										((item.offerPrice -
											(item.offerPrice * item.triggerDiscount) / 100) *
											100) /
											item.price
									).toFixed(2)
								)}%`}
								OFF
							</Typo>
						) : item.minQuantity > 1 ? (
							<Typo level={7} type="primary" style={{ textAlign: "center" }}>
								Compra mínima {item.minQuantity} un.
							</Typo>
						) : (
							` `
						)}
					</SectionOffer>
				</Container>
			</Col>
			<Col span={5}>
				<Container>
					<div style={{ display: "flex", justifyContent: "center" }}>
						{qtyInCart > 0 ? (
							qtyInCart > 1 ? (
								<Typo type="warning" level={7}>
									{qtyInCart} agregados
								</Typo>
							) : (
								<Typo type="warning" level={7}>
									{qtyInCart} agregado
								</Typo>
							)
						) : null}
					</div>
					<RightData isOneMarket={userdata?.isOneMarket || !userdata?.p_shop}>
						{/* <Typo
							level={3}
							type="primary"
							onClick={handleDecrement}
							style={{ paddind: "5px", cursor: "Pointer" }}
						>
							<MinusOutlined />
						</Typo>
						<CartButtomNumber>
							<Typo
								level={2}
								type="primary"
								onClick={handleAddToCart}
								style={{ position: "relative", cursor: "Pointer" }}
							>
								<FaShoppingCart />
								{submitting && (
									<Loader>
										<Spin
											indicator={
												<LoadingOutlined
													style={{
														fontSize: 24,
														color: COLORS.Grey,
														backgroundColor: "transparent",
													}}
													spin
												/>
											}
										/>
									</Loader>
								)}
							</Typo>
						</CartButtomNumber>
						<Typo
							level={3}
							type="primary"
							onClick={handleIncrement}
							style={{ paddind: "5px", cursor: "Pointer" }}
						>
							<PlusOutlined />
						</Typo>
						{qtyInCart > 0 && (
							<ItemsQty>
								{qtyInCart === 1
									? `${qtyInCart} agregado`
									: `${qtyInCart} agregados`}
							</ItemsQty>
						)} */}
						{submitting === true ? (
							<InputContainer
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Spin
									indicator={
										<LoadingOutlined
											style={{
												fontSize: 30,
												color: COLORS.Primary,
												backgroundColor: "transparent",
											}}
											spin
										/>
									}
								/>
							</InputContainer>
						) : (
							<InputContainer>
								{!showAddToCart ? (
									<CartButton
										type="primary"
										onClick={handleAddToCart}
										loading={submitting}
									>
										<FaShoppingCart style={{ fontSize: "21px" }} />
									</CartButton>
								) : (
									<CartButtonsContainer>
										<Button
											type="primary"
											onClick={handleDecrement}
											style={{ borderRadius: "10px 0 0 10px" }}
										>
											<MinusOutlined />
										</Button>
										<Input
											value={quantity}
											onChange={handleChange}
											style={{
												pointerEvents: "none",
											}}
										/>
										<Button
											type="primary"
											onClick={handleIncrement}
											style={{ borderRadius: "0 10px 10px 0" }}
										>
											<PlusOutlined />
										</Button>
									</CartButtonsContainer>
								)}
							</InputContainer>
						)}
					</RightData>
				</Container>
			</Col>
			<Col span={3}>
				<Container style={{ flexDirection: "row", alignItems: "center" }}>
					{item?.discount > 0 &&
						item?.offerFrom &&
						item?.offerTo &&
						compareWithToday(item?.offerTo) && (
							<>
								<Typo type="success" level={4}>
									psl
								</Typo>
								<Typo type="success" level={4}>
									- {numberWithDotAndCommas(item.discount)} %
								</Typo>
							</>
						)}
				</Container>
			</Col>
		</Inner>
	);
}
