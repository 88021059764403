import React, {
	useState,
	useEffect,
	useMemo,
	useRef,
	useCallback,
} from "react";
import { Spin, AutoComplete, Form } from "antd";
import debounce from "lodash/debounce";
import { get_autocomplete_search } from "../../../api/endpoints/search";
import { OptionInner, OptionLabel, SearchBar } from "./styles";
import Image from "../image/Image";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "../../../assets/images/magnifying-glass-solid.svg";
import { Typo } from "../../layout/theme";

const debounceTimeout = 600;

const OptionView = ({
	photoUrl,
	label,
	actionUrl,
	presentationName,
	presentationQty,
}) => {
	return (
		<Link to={`/${actionUrl}`}>
			<OptionInner>
				<OptionLabel>
					{label} <Typo type="mediumGrey">{presentationName}</Typo>
				</OptionLabel>
				{photoUrl && (
					<Image width={20} height={20} src={photoUrl} alt={label} />
				)}
			</OptionInner>
		</Link>
	);
};

export default function SearchProduct({ location }) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const [searchType, setSearchType] = useState("ALL");
	const fetchRef = useRef(0);
	const [searchTerm, setSearchTerm] = useState("");

	const fetch_search = useCallback(
		async (term) => {
			const res = await get_autocomplete_search(term, searchType);
			if (res.results.length === 0) {
				return [
					{
						label: (
							<Typo texto="italic" type="muted" level={6}>
								Sin resultados en la búsqueda
							</Typo>
						),
					},
				];
			} else {
				return res.results.map((r) => ({
					label: r.label,
					options: r.options.map((o) => ({
						value: o.id,
						label: (
							<OptionView
								photoUrl={o.photoUrl}
								label={o.label}
								actionUrl={o.actionUrl}
								presentationName={o.presentationName}
								presentationQty={o.presentationQty}
							/>
						),
					})),
				}));
			}
		},
		[searchType]
	);

	useEffect(() => {
		setLoading(true);
		fetch_search("").then((newOptions) => {
			setOptions(newOptions);
			setLoading(false);
		});
	}, []);

	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setLoading(true);
			try {
				fetch_search(value).then((newOptions) => {
					if (fetchId !== fetchRef.current) {
						return;
					}

					setOptions(newOptions);
					setLoading(false);
				});
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		};

		return debounce(loadOptions, debounceTimeout);
	}, [fetch_search]);

	const onFinish = (values) => {
		if (location === "/book") return;
		if (searchType === "ALL") {
			navigate(`/search/${searchTerm}`);
		} else {
			navigate(`/offers/${searchTerm}`);
		}
	};

	return (
		<Form onFinish={onFinish}>
			<AutoComplete
				options={options}
				filterOption={false}
				notFoundContent={loading ? <Spin size="small" /> : null}
				style={{
					width: "auto",
				}}
				onSearch={!loading ? debounceFetcher : () => {}}
				loading={loading}
				disabled={location === "/book"}
			>
				<SearchBar
					placeholder="Buscar en el catálogo"
					onChange={(e) => (!loading ? setSearchTerm(e.target.value) : null)}
					value={searchTerm}
					bordered={true}
					size="medium"
					onPressEnter={(e) => onFinish(e.target.value)}
					suffix={
						<Image
							onClick={(e) => onFinish(e.target.value)}
							src={SearchIcon}
							style={{
								height: "18px",
								width: "18px",
							}}
						/>
					}
				/>
			</AutoComplete>
		</Form>
	);
}
