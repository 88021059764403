import React from "react";
import { Container, Inner } from "./styles";
import { Typo } from "../../../../../../layout/theme";
import Image from "../../../../../../common/image/Image";

export default function DistributorCard({ distributor }) {
	return (
		<Inner>
			<Container>
				<Image
					width={35}
					height={35}
					src={distributor.photoUrl}
					alt={distributor.name}
				/>
				<Typo type="primary" level={7}>
					{distributor.name}
				</Typo>
			</Container>
		</Inner>
	);
}
