import React, { useState } from "react";
import { Menu, Dropdown, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import LoadingSkeleton from "../loading-sekeleton/LoadingSkeleton";
import { COLORS, Typo } from "../../../../../../layout/theme";
import { ActionButton, AntdTable } from "./styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdComputer } from "react-icons/md";
import { BiMobile } from "react-icons/bi";
import { numberWithDotAndCommas } from "../../../../../../../utils/numberWithDotAndComas";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../../utils/notificationToast";
import { useCart } from "../../../../../../../contexts/cartContext";
import { FiPhoneCall } from "react-icons/fi";
import { FaHeadset } from "react-icons/fa";
import { AiOutlineAudit } from "react-icons/ai";

const { confirm } = Modal;

export default function MyBuysDateTable({ data, loading }) {
	const [key, setKey] = useState(null);
	const [modalInfo, setModalInfo] = useState(null);
	const navigate = useNavigate();

	const { addProdsToCart } = useCart();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const showModal = (r) => {
		setIsModalOpen(true);
		setModalInfo(r);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleReBuy = () => {
		confirm({
			title: `¿Seguro que quiere rehacer esta orden?`,
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "Los productos habilitados se agregaran al carrito.",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction();
			},
		});
	};

	const handleAction = async () => {
		const postData = key.items.map((o) => ({
			id: o.productId,
			quantity: o.quantity,
			paused: o.product.paused,
			draft: o.product.draft,
			deleted: o.product.deleted,
			fromOrder: true,
		}));
		const res = await addProdsToCart(postData);
		openNotificationWithIcon(
			TYPE.SUCCESS,
			"Productos agregados al carrito con exito",
			"Los productos se agregaron al carrito con exito"
		);
		if (res) navigate("/cart");
	};

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/compras/${key?.id}`}>
							<Typo type="secondary">Ver detalle</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						// <Link to={`/admin/compras/${key}`}>
						<Typo onClick={() => handleReBuy()}>Volver a comprar</Typo>
						// </Link>
					),
				},
			]}
		/>
	);

	const columns = [
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Fecha
					</div>
				);
			},
			dataIndex: "created",
			render: (f) => (
				<Typo type="terciary" fontWeight={600} fontSize={14}>
					{moment(f).format("dddd, DD MMMM YYYY")}
				</Typo>
			),
		},
		// {
		// 	title: () => {
		// 		return (
		// 			<div
		// 				style={{
		// 					color: COLORS.White,
		// 				}}
		// 			>
		// 				Punto de Venta
		// 			</div>
		// 		);
		// 	},
		// 	dataIndex: "pointOfSale",
		// 	render: (f) => (
		// 		<Typo type="muted" fontWeight={600} fontSize={14}>
		// 			{f.name}
		// 		</Typo>
		// 	),
		// },
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Usuario
					</div>
				);
			},
			dataIndex: "userFrom",
			render: (f, r) => (
				<>
					<Typo
						type="muted"
						fontWeight={600}
						fontSize={14}
						onClick={() => showModal(r)}
						style={{ cursor: "pointer" }}
					>
						{f === "web" ? (
							<MdComputer />
						) : f === "expoSeller" ? (
							<AiOutlineAudit />
						) : f === "webSeller" ? (
							<FaHeadset />
						) : f === "mobile" ? (
							<>
								<BiMobile />{" "}
								<Typo type="muted" fontWeight={600} fontSize={12}>
									App
								</Typo>
							</>
						) : (
							<FiPhoneCall />
						)}
					</Typo>
				</>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						# Orden
					</div>
				);
			},
			dataIndex: "orderNumId",
			render: (f, r) => (
				<Link to={`/admin/compras/${r.id}`}>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{f}
					</Typo>
				</Link>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Laboratorio
					</div>
				);
			},
			dataIndex: "laboratoryName",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						# Items
					</div>
				);
			},
			dataIndex: "quantityItems",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						SubTotal
					</div>
				);
			},
			dataIndex: "total",
			render: (fila, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					$ {numberWithDotAndCommas(record.subTotal)}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Descuento
					</div>
				);
			},
			dataIndex: "total",
			render: (fila, record) => (
				<Typo type="success" fontWeight={600} fontSize={14}>
					{record.total - record.subTotal === 0
						? ``
						: `$ ${numberWithDotAndCommas(
								((record.total - record.subTotal) * -1).toFixed(2)
						  )} `}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Total
					</div>
				);
			},
			dataIndex: "total",
			render: (fila, record) => (
				<Typo type="primary" fontWeight={600} fontSize={14}>
					$ {numberWithDotAndCommas(record.total)}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Estado
					</div>
				);
			},
			dataIndex: "state",
			render: (fila, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila === "Pending" && "Pendiente"}
					{fila === "Active" && "Activa"}
					{fila === "Sended" && "Enviada a Droguería"}
					{fila === "Canceled" && "Cancelada"}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown overlay={menu} placement="bottomRight">
						<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	if (!data) return <LoadingSkeleton />;

	return (
		<>
			<AntdTable columns={columns} dataSource={data} loading={loading} />
			{modalInfo && (
				<Modal
					title={
						<Typo type="primary" level={4}>
							Usuario de la compra
						</Typo>
					}
					visible={isModalOpen}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<p>
						Nombre: {modalInfo?.userFirstName + " " + modalInfo?.userLastName}
					</p>
					<p>Mail: {modalInfo?.userEmail}</p>
					<p>
						Usuario de:{" "}
						{modalInfo.userFrom === "web"
							? modalInfo?.userClientName + " - Usuario Web"
							: modalInfo.userFrom === "mobile"
							? modalInfo?.userClientName + " - Usuario Mobile"
							: "Usuario One Market"}
					</p>
				</Modal>
			)}
		</>
	);
}
