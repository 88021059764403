import axiosInstance from "../axiosInstance";

const CONTROLLER = "PointsOfSale";

const get_points_of_sale = async (search, userId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search}&userId=${userId}`
	);
	return response.data;
};

const get_orders_points_of_sale = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetOrdersPos`);
	return response.data;
};

const get_points_of_sale_cart = async (search, userId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPosCart?search=${search}&userId=${userId}`
	);
	return response.data;
};

const get_point_of_sale_by_id = async (id, userId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetById?id=${id}&userId=${userId}`
	);
	return response.data;
};

const get_points_of_sale_page = async (page, filter, search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?pageNumber=${page}&state=${filter}&search=${search}`
	);
	return response.data;
};

const get_points_of_sale_header_info = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPointsOfSaleHeader`
	);
	return response.data;
};

const get_points_of_sale_with_billers = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetWithBillers`);
	return response.data;
};

const update_point_of_sale = async (id, pointOfSale) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, pointOfSale);
	return response.data;
};

const create_point_of_sale = async (pointOfSale) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, pointOfSale);
	return response.data;
};

const delete_point_of_sale = async (id) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/DeletePointOfSale/${id}`
	);
	return response.data;
};

const update_point_of_sale_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdatePointOfSaleState/${id}`,
		state
	);
	return response.data;
};

const get_client_pointOfSale_dapper = async (userId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetClientPointOfSale`,
		{
			params: {
				userId: userId,
			},
		}
	);
	return response.data;
};

const get_pointOfSale_by_clientId_dapper = async (
	searchTerm,
	clientId,
	skip,
	page,
	state
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPointOfSaleByClientId`,
		{
			params: {
				searchTerm: searchTerm,
				clientId: clientId,
				skip: skip,
				page: page,
				state: state,
			},
		}
	);
	return response.data;
};

const get_general_pointOfSale_dapper = async (
	searchTerm,
	skip,
	page,
	provinceName,
	localityName
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetGeneralPointsOfSale`,
		{
			params: {
				searchTerm: searchTerm,
				skip: skip,
				page: page,
				provinceName: provinceName,
				localityName: localityName,
			},
		}
	);
	return response.data;
};

const export_general_pos = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/ExportGeneralPointsOfSale`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const get_general_pos = async (search, provinceName, localityName, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetGeneralPos?search=${search || ""}&provinceName=${
			provinceName || ""
		}&localityName=${localityName || ""}&state=${state || ""}`
	);
	return response.data;
};

const update_general_pos_data = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/UpdateGeneralPosData`,
		data
	);
	return response.data;
};

const check_general_pos_data = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/CheckGeneralPosData`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const import_general_pos = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ImportGeneralPos`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const confirm_import_general_pos = async (pointsOfSale) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ConfirmImportGeneralPdv`,
		pointsOfSale
	);
	return response.data;
};

const get_general_pos_header_info = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetGeneralPointsOfSaleHeader`
	);
	return response.data;
};

const update_general_pos_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdatePreDefinedPointOfSaleState/${id}`,
		state
	);
	return response.data;
};

const add_general_pos = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/AddGeneralPos`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const download_pos_report = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ExportPdvFileReport`,
		data,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const get_general_pos_history = async (typeOfAction) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GeneralPosHistory?typeOfAction=${typeOfAction || ""}`
	);
	return response.data;
};

const get_general_pos_history_detail = async (data) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetGeneralPosHistoryDetail?id=${data.id || ""}`,
		data
	);
	return response.data;
};

const update_point_of_sale_distributors = async (id, pointOfSale) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdatePointOfSaleDistributors/${id}`,
		pointOfSale
	);
	return response.data;
};

const get_pos_code = async (pointOfSaleId, distributorId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPosCode?pointOfSaleId=${pointOfSaleId}&distributorId=${distributorId}`
	);
	return response.data;
};

export {
	get_point_of_sale_by_id,
	update_point_of_sale,
	create_point_of_sale,
	get_points_of_sale_page,
	get_points_of_sale,
	get_points_of_sale_header_info,
	get_points_of_sale_with_billers,
	delete_point_of_sale,
	update_point_of_sale_state,
	get_points_of_sale_cart,
	get_orders_points_of_sale,
	get_client_pointOfSale_dapper,
	get_pointOfSale_by_clientId_dapper,
	get_general_pointOfSale_dapper,
	export_general_pos,
	get_general_pos,
	update_general_pos_data,
	check_general_pos_data,
	import_general_pos,
	confirm_import_general_pos,
	get_general_pos_header_info,
	update_general_pos_state,
	add_general_pos,
	download_pos_report,
	get_general_pos_history,
	get_general_pos_history_detail,
	update_point_of_sale_distributors,
	get_pos_code,
};
