import axiosInstance from "../axiosInstance";

const CONTROLLER = "events";

const get_expo_events = async (search, clientId, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search}&clientId=${clientId}&state=${state}`
	);
	return response.data;
};

const get_expo_event_by_id = async (id) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetExpoEventById/${id}`
	);
	return response.data;
};

const get_expo_events_header_info = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetExpoEventsHeader?clientId=${clientId}`
	);
	return response.data;
};

const update_expo_event = async (id, data) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_expo_event_state = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateExpoEventState`,
		state
	);
	return response.data;
};

const create_expo_event = async (data) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

export {
	get_expo_events,
	get_expo_events_header_info,
	create_expo_event,
	get_expo_event_by_id,
	update_expo_event,
	update_expo_event_state,
};
