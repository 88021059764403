import React from "react";
import {
	Inner,
	SectionContainer,
	RigthContainer,
	LeftContainer,
	ImageContainer,
	DataContainer,
	DataSeparator,
	PriceContainer,
	CartContainer,
	PriceSeparator,
	Price,
	CartButtomNumber,
	FlowtingNumber,
	Laboratory,
	PriceCenter,
	PriceAlong,
	PriceAndOffer,
	PslContainer,
} from "./styles";

const ListCard = ({ children, ...restProps }) => (
	<Inner {...restProps}>{children}</Inner>
);

export default ListCard;

ListCard.SectionContainer = ({ children, ...restProps }) => {
	return <SectionContainer {...restProps}>{children}</SectionContainer>;
};

ListCard.LeftContainer = ({ children, ...restProps }) => {
	return <LeftContainer {...restProps}>{children}</LeftContainer>;
};

ListCard.RigthContainer = ({ children, ...restProps }) => {
	return <RigthContainer {...restProps}>{children}</RigthContainer>;
};

ListCard.ImageContainer = ({ children, ...restProps }) => {
	return <ImageContainer {...restProps}>{children}</ImageContainer>;
};

ListCard.DataContainer = ({ children, ...restProps }) => {
	return <DataContainer {...restProps}>{children}</DataContainer>;
};

ListCard.DataSeparator = ({ children, ...restProps }) => {
	return <DataSeparator {...restProps}>{children}</DataSeparator>;
};

ListCard.Laboratory = ({ children, ...restProps }) => {
	return <Laboratory {...restProps}>{children}</Laboratory>;
};

ListCard.PriceContainer = ({ children, ...restProps }) => {
	return <PriceContainer {...restProps}>{children}</PriceContainer>;
};

ListCard.CartContainer = ({ children, ...restProps }) => {
	return <CartContainer {...restProps}>{children}</CartContainer>;
};

ListCard.Price = ({ children, ...restProps }) => {
	return <Price {...restProps}>{children}</Price>;
};

ListCard.PriceSeparator = ({ children, ...restProps }) => {
	return <PriceSeparator {...restProps}>{children}</PriceSeparator>;
};

ListCard.CartButtomNumber = ({ children, ...restProps }) => {
	return <CartButtomNumber {...restProps}>{children}</CartButtomNumber>;
};

ListCard.FlowtingNumber = ({ children, ...restProps }) => {
	return <FlowtingNumber {...restProps}>{children}</FlowtingNumber>;
};

ListCard.PriceCenter = ({ children, ...restProps }) => {
	return <PriceCenter {...restProps}>{children}</PriceCenter>;
};

ListCard.PriceAlong = ({ children, ...restProps }) => {
	return <PriceAlong {...restProps}>{children}</PriceAlong>;
};

ListCard.PriceAndOffer = ({ children, ...restProps }) => {
	return <PriceAndOffer {...restProps}>{children}</PriceAndOffer>;
};

ListCard.PslContainer = ({ children, ...restProps }) => {
	return <PslContainer {...restProps}>{children}</PslContainer>;
};
