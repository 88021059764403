import axiosInstance from "../axiosInstance";

const CONTROLLER = "clientEmails";

const create_client_email = async (data) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, data);
	return response.data;
};

const update_client_email = async (id, emailData) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, emailData);
	return response.data;
};

const delete_client_email = async (id) => {
	const response = await axiosInstance.delete(
		`${CONTROLLER}/DeleteClientEmail/${id}`
	);
	return response.data;
};

const get_client_emails_by_clientId = async (clientId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetByClientId?clientId=${clientId}`
	);
	return response.data;
};

export {
	create_client_email,
	update_client_email,
	delete_client_email,
	get_client_emails_by_clientId,
};
