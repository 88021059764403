import React from "react";
import { Card, Skeleton } from "antd";
import { Container, Inner } from "./styles";

export default function LoadingSkeleton() {
	return (
		<Inner>
			<Card>
				<Container>
					<Skeleton />
				</Container>
			</Card>
		</Inner>
	);
}
