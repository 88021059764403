import React from "react";
import { AntdTable, ContainerDiv } from "./styles";

export default function ProductsTable({ data, columns }) {
	return (
		<ContainerDiv>
			<AntdTable
				showHeader={true}
				columns={columns}
				dataSource={data}
				pagination={false}
				scroll={{
					y: 480,
				}}
			/>
		</ContainerDiv>
	);
}
