import React, { useState } from "react";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import Image from "../../../common/image/Image";
import MarketLogo from "../../../../assets/images/Onetransfer-logo.png";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import { Text, AntdModal, Section, AntdButton } from "./styles";
import { useAuth } from "../../../../contexts/authContext";
import { send_in_progress_validation_email } from "../../../../api/endpoints/auth";
import TermsModal from "./components/terms-modal/TermsModal";
import TermsPolicy from "./components/policy-modal/TermsPolicy";
import { Typo } from "../../../layout/theme";

export default function TermsOfService({ user }) {
	const [submitting, setSubmitting] = useState(false);
	const { updateTermsOfService } = useAuth();
	const [showTermsModal, setShowTermsModal] = useState(false);
	const [showPolicyModal, setShowPolicyModal] = useState(false);

	const termOfService = async () => {
		try {
			function setsubm() {
				setSubmitting(true);
			}
			setTimeout(setsubm, 500);
			function tos() {
				updateTermsOfService();
				openNotificationWithIcon(
					TYPE.SUCCESS,
					"Terminos de servicios aceptados con éxito"
				);
			}
			setTimeout(tos, 4000);
			if (!user.validated) await send_in_progress_validation_email(user.email);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"No se pudo completar la operación",
				"Debe aceptar los términos de servicio para unirse a One Transfer"
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleTermsClick = () => {
		setShowTermsModal(true);
	};

	const handleTermsOk = () => {
		setShowTermsModal(false);
	};

	const handleTermsCancel = () => {
		setShowTermsModal(false);
	};

	const handlePolicyClick = () => {
		setShowPolicyModal(true);
	};

	const handlePolicyOk = () => {
		setShowPolicyModal(false);
	};

	const handlePolicyCancel = () => {
		setShowPolicyModal(false);
	};

	return (
		<SignInTemplate>
			<SignInTemplate.Main>
				<SignInTemplate.Top>
					<SignInTemplate.Title>Términos de servicio</SignInTemplate.Title>
					<SignInTemplate.Desc>
						Debe aceptar los términos de servicio para unirse a One Transfer
					</SignInTemplate.Desc>
				</SignInTemplate.Top>
				<SignInTemplate.Body>
					<Section>
						<SignInTemplate.Logo>
							<Image
								src={MarketLogo}
								alt="Logo FarmaTransfer"
								height="45px"
								width="220px"
							/>
						</SignInTemplate.Logo>
						<SignInTemplate.TermsSection>
							<Text onClick={handleTermsClick}>Términos y condiciones</Text>
							<Text onClick={handlePolicyClick}>
								Políticas de privacidad y Cookies
							</Text>
							<AntdModal
								title={`Términos y condiciones`}
								visible={showTermsModal}
								onOk={handleTermsOk}
								onCancel={handleTermsCancel}
								width={1200}
								centered={true}
								footer={null}
								destroyOnClose={true}
							>
								<TermsModal user={user} />
							</AntdModal>
							<AntdModal
								title={`Políticas de privacidad y Cookies`}
								visible={showPolicyModal}
								onOk={handlePolicyOk}
								onCancel={handlePolicyCancel}
								width={1200}
								centered={true}
								footer={null}
								destroyOnClose={true}
							>
								<TermsPolicy user={user} />
							</AntdModal>
							<AntdButton
								type="primary"
								onClick={termOfService}
								loading={submitting}
							>
								<Typo type="white" level={6}>
									Aceptar todo y acceder a OneTransfer
								</Typo>
							</AntdButton>
						</SignInTemplate.TermsSection>
					</Section>
				</SignInTemplate.Body>
			</SignInTemplate.Main>
		</SignInTemplate>
	);
}
