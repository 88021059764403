import styled from "styled-components";
import { COLORS } from "../../../../../layout/theme";
import { Input } from "antd";

export const Section = styled.div`
	background-color: white;
	border-radius: 10px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	min-height: 120px;
	padding: 1rem;
	flex-direction: column;
	gap: 10px;
`;

export const Container = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const TableContainer = styled.div`
	width: 100%;
	height: 500px;
	overflow: auto;

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 6px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.SkeletonGrey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}
`;

export const CompaginationDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
`;

export const AntdInput = styled(Input)`
	.ant-input-group .ant-input {
		border: 1px solid ${COLORS.Grey};
		border-radius: 10px;
		background-color: transparent;
		text-align: end;
		padding: 5px;
	}

	.ant-input-group .ant-input:hover {
		border: 1px solid ${COLORS.Secondary};
		border-radius: 10px;
	}

	.ant-input-group .ant-input:focus {
		border-radius: 10px;
	}

	.ant-input-group-addon {
		border: 0;
		background-color: transparent;
		padding: 5px;
	}
`;

export const CardContainer = styled.div`
	display: flex;
	width: 70%;
	align-items: center;
	gap: 10px;
`;
