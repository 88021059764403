import styled from "styled-components/macro";
import { Modal } from "antd";
import { COLORS } from "../../../../../layout/theme";

export const AntdModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;

		.ant-modal-close-x {
			width: 40px;
			height: 40px;
			line-height: 40px;
		}
	}

	.ant-modal-header {
		border-radius: 10px 10px 0 0;
		height: 40px;
		display: flex;
		align-items: center;
	}
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
		padding: 0.6rem 4rem 0.6rem 4rem;
		border-radius: 0 0 10px 10px;
	}
	.ant-modal-footer {
		text-align: left;
		height: 40px;
	}
`;
