import { numberWithDotAndCommas } from "./numberWithDotAndComas";

function calculatePrice(
	price,
	generalDiscount,
	discount,
	campaignDiscount,
	qtyDiscount
) {
	return (
		price -
		generalDiscount * (price / 100) -
		(discount / 100) * (price - generalDiscount * (price / 100)) -
		(campaignDiscount / 100) *
			(price -
				(price -
					generalDiscount * (price / 100) -
					(discount / 100) * (price - generalDiscount * (price / 100))))
	);
}

function applyOffersInCampaign(cartProducts, campaignsProducts) {
	return cartProducts.forEach((prodInCart) => {
		let prodFromCampaign = campaignsProducts?.find(
			(prodInCampaign) => prodInCampaign.productId === prodInCart.id
		);
		if (prodFromCampaign) {
			prodInCart.campaignDiscount = prodFromCampaign.campaignDiscount;
			prodInCart.offerPrice =
				prodInCart.offerPrice -
				prodInCart.offerPrice * (prodFromCampaign.campaignDiscount / 100);
		}
	});
}

function calculateEstimatedPrice(
	offerPrice,
	campaignDiscount,
	quantityDiscount
) {
	return (
		offerPrice -
		quantityDiscount * (offerPrice / 100) -
		(campaignDiscount / 100) *
			(offerPrice - quantityDiscount * (offerPrice / 100))
	);
}

const calculatePercentage = (
	price,
	generalDiscount,
	discount = 0,
	triggerDiscount = 0,
	mobileDiscount = 0
) => {
	const offerPrice =
		price -
		generalDiscount * (price / 100) -
		(discount / 100) * (price - generalDiscount * (price / 100));

	const offerPriceMobile = (offerPrice / 100) * (100 - mobileDiscount);

	const percentage = numberWithDotAndCommas(
		(
			100 -
			((offerPrice - (offerPrice * triggerDiscount) / 100) * 100) / price
		).toFixed(2)
	);

	const percentageMobile = numberWithDotAndCommas(
		(
			100 -
			((offerPriceMobile - (offerPriceMobile * triggerDiscount) / 100) * 100) /
				price
		).toFixed(2)
	);

	return mobileDiscount > 0 ? percentageMobile : percentage;
};

const calculatePSLFinalDiscount = (offerPrice, price, generalDiscount) => {
	const discount =
		((100 * offerPrice - 100 * price + price * generalDiscount) /
			(price * generalDiscount - 100 * price)) *
		100;
	return discount;
};

export {
	calculatePrice,
	applyOffersInCampaign,
	calculateEstimatedPrice,
	calculatePercentage,
	calculatePSLFinalDiscount,
};
