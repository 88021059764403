import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Filters, TableSection } from "./styles";
import { Space, Input, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import Main from "../../../layout/main/Main";
import { COLORS, Typo } from "../../../layout/theme";
import { get_categories } from "../../../../api/endpoints/categories";
import CategoryTable from "./components/CategoryTable";
import debounce from "lodash/debounce";
import moment from "moment";

const { Search } = Input;

export default function Categories() {
	const navigate = useNavigate();
	const [data, setData] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			render: (f) => (
				<Typo type="terciary" fontWeight={600} fontSize={14}>
					{f.toUpperCase()}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Cantidad de Productos</div>
			),
			dataIndex: "productQuantity",
			render: (value, record) => (
				<div style={{ color: COLORS.Grey }}>{value}</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Acción</div>,
			key: "action",
			render: (_, record) => (
				<Space size="middle">
					<Button
						icon={<EditOutlined />}
						type="link"
						onClick={() => navigate(`/search/?c=${record.name}`)}
					>
						Ver productos
					</Button>
				</Space>
			),
		},
	];

	const fetch_data_categories = async (search) => {
		const res = await get_categories(search);
		const today = moment();

		setData(
			res.map((c) => ({
				id: c.id,
				name: c.name,
				productQuantity: c.products.filter(
					(p) => moment(p.publishFrom) <= today && moment(p.publishTo) >= today
				).length,
			}))
		);
	};

	useEffect(() => {
		fetch_data_categories();
	}, []);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_data_categories(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	return (
		<Main>
			<Main.Breadcrumb>
				<Main.BreadcrumbItem>
					<Link to="/">Home</Link>
				</Main.BreadcrumbItem>
				<Main.BreadcrumbSeparator />
				<Main.ActiveBreadcrumbItem>Categorias</Main.ActiveBreadcrumbItem>
			</Main.Breadcrumb>

			<Main.Body>
				<Filters>
					<Space size="large">
						<Search
							style={{ width: "400px" }}
							allowClear
							placeholder="Buscar categoria"
							onChange={debouncedEventHandler}
						/>
					</Space>
				</Filters>
				<TableSection>
					<CategoryTable
						data={data}
						columns={columns}
						fetch={fetch_data_categories}
					/>
				</TableSection>
			</Main.Body>
		</Main>
	);
}
