function generateRandomKey() {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$^*()-";
	let randomKey = "";
	for (let i = 0; i < 14; i++) {
		randomKey += characters.charAt(
			Math.floor(Math.random() * characters.length)
		);
	}
	return randomKey;
}

export { generateRandomKey };
