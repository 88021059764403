const CHECKS = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];

function getDigits(cuit) {
	const digits = [];
	for (let i = 0; i < cuit.length; i++) {
		digits.push(Number.parseInt(cuit[i]));
	}
	return digits;
}

function getSumProd(digits) {
	let sumProd = 0;
	for (let i = 0; i < CHECKS.length; i++) {
		sumProd += CHECKS[i] * digits[i];
	}
	return sumProd;
}

export function validateCUIT(cuit) {
	if (/^\d{2}\-\d{8}\-\d{1}$/.test(cuit) || /^\d{11}$/.test(cuit)) {
		const formatCuit = cuit.replace(/-/g, "");
		const DIGITS = getDigits(formatCuit);
		const lastDigit = DIGITS[10];

		const sumProd = getSumProd(DIGITS);
		const verificationResult = sumProd % 11;

		let verifier = verificationResult === 0 ? 0 : 11 - verificationResult;

		return verifier === lastDigit;
	} else {
		throw new Error("Formato de CUIT invalido.");
	}
}
