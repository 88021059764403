import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { COLORS, Typo } from "../../../../../layout/theme";
import { AntdSelect, SelectContainer } from "./styles";
import { Inner } from "./styles";

export default function TripleSelector({
	products,
	brands,
	laboratories,
	saleDepartment,
	handleBannerUrl,
	id,
	initialLaboratory,
	initialBrand,
	initialProduct,
	initialSaleDepartment,
	showSaleUrl = false,
}) {
	const [selectedLaboratory, setSelectedLaboratory] = useState(null);
	const [selectedBrand, setSelectedBrand] = useState(null);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedSaleDepartment, setSelectedSaleDepartment] = useState(null);
	const [brandsValues, setBrandsValues] = useState(null);
	const [productValues, setProductsValues] = useState(null);
	const [saleDeparmentValues, setSaleDepartmentValues] = useState(null);

	useEffect(() => {
		if (selectedLaboratory)
			setBrandsValues(
				brands?.filter((b) => b.clientId === selectedLaboratory?.value) || []
			);
		if (selectedBrand)
			setProductsValues(
				products?.filter((b) => b.brandId === selectedBrand?.value) || []
			);
		if (selectedSaleDepartment)
			setSaleDepartmentValues(
				saleDepartment?.filter((b) => b.Id === selectedSaleDepartment?.value) ||
					[]
			);
	}, [selectedLaboratory, selectedBrand, selectedSaleDepartment]);

	const handleLaboratorySelect = (value, object) => {
		setSelectedLaboratory(object);
		setSelectedBrand(null);
		setSelectedProduct(null);
		setSelectedSaleDepartment(null);
		handleBannerUrl(id, {
			laboratory: object.children,
			brand: "",
			product: "",
		});
	};

	const handleBrandSelect = (value, object) => {
		setSelectedBrand(object);
		setSelectedProduct(null);
		setSelectedSaleDepartment(null);
		handleBannerUrl(id, {
			laboratory: selectedLaboratory.children,
			brand: object.children,
			product: "",
			saleDepartment: null,
		});
	};

	const handleProductSelect = (value, object) => {
		setSelectedProduct(object);
		handleBannerUrl(id, {
			laboratory: selectedLaboratory.children,
			brand: selectedBrand.children,
			product: object.children,
			saleDepartment: null,
		});
	};

	const handleSaleDepartmentSelect = (value, object) => {
		setSelectedLaboratory(null);
		setSelectedBrand(null);
		setSelectedProduct(null);
		setSelectedSaleDepartment(object);
		handleBannerUrl(id, {
			laboratory: "",
			brand: "",
			product: "",
			saleDepartment: object.children,
		});
	};

	if (!id) return <div>Loading...</div>;

	return (
		<Inner>
			{showSaleUrl ? (
				<>
					<SelectContainer
						key={`saleDepartment_${id}`}
						style={{ flexBasis: 0 }}
					>
						<Typo type="primary" level={6}>
							Rubro
						</Typo>
						<AntdSelect
							onChange={handleSaleDepartmentSelect}
							value={
								initialSaleDepartment
									? initialSaleDepartment
									: selectedSaleDepartment?.children
							}
							style={{ minWidth: "40%" }}
						>
							{saleDepartment?.map((item) => (
								<Select.Option key={item.id} value={item.id}>
									{item.name}
								</Select.Option>
							))}
						</AntdSelect>
					</SelectContainer>
				</>
			) : (
				<>
					<SelectContainer key={`laboratory_${id}`} style={{ flexBasis: 0 }}>
						<Typo type="primary" level={6}>
							Laboratorio
						</Typo>
						<AntdSelect
							onChange={handleLaboratorySelect}
							value={
								initialLaboratory
									? initialLaboratory
									: selectedLaboratory?.children
							}
						>
							{laboratories?.map((item) => (
								<Select.Option key={item.clientId} value={item.clientId}>
									{item.clientSlug}
								</Select.Option>
							))}
						</AntdSelect>
					</SelectContainer>
					<SelectContainer
						key={`brand_${id}`}
						style={{
							borderLeft: `1px solid ${COLORS.Grey}`,
							borderRight: `1px solid ${COLORS.Grey}`,
							flexBasis: 0,
						}}
					>
						<Typo type="primary" level={6}>
							Marca
						</Typo>
						<AntdSelect
							onChange={handleBrandSelect}
							value={
								initialBrand && initialBrand !== "&l"
									? initialBrand
									: selectedBrand?.children
							}
							style={{ minWidth: "50%" }}
						>
							{brandsValues &&
								brandsValues.map((item) => (
									<Select.Option key={item.id} value={item.id}>
										{item.name}
									</Select.Option>
								))}
						</AntdSelect>
					</SelectContainer>
					<SelectContainer key={`product_${id}`}>
						<Typo type="primary" level={6}>
							Producto
						</Typo>
						<AntdSelect
							onChange={handleProductSelect}
							value={
								!initialLaboratory && !initialBrand && initialProduct
									? initialProduct
									: selectedProduct?.children
							}
							style={{ minWidth: "100%" }}
						>
							{productValues &&
								productValues.map((item) => (
									<Select.Option key={item.id} value={item.id}>
										{item.title}
									</Select.Option>
								))}
						</AntdSelect>
					</SelectContainer>
				</>
			)}
		</Inner>
	);
}
