import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Form, Input, Row, Col, Space, Modal } from "antd";
import { InnerForm, ProductData, PublishSettings } from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import COLORS from "../../../../layout/theme/colors/Colors";
import { get_categories } from "../../../../../api/endpoints/categories";
import DebounceSelect from "../../../../common/debounce-select/DebounceSelect";
import {
	get_sale_departments_by_id,
	update_sale_department,
} from "../../../../../api/endpoints/saledepartments";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Typo } from "../../../../layout/theme";

const GUTTER = 10;

export default function UpdateSaleDepartment() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const [categories, setCategories] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const fetch_sale_department_by_id = async () => {
			const data = await get_sale_departments_by_id(id);

			const saleDepartment = {
				title: data.name,
			};

			setCategories(
				data.categories?.map((i) => ({
					value: i.category.id,
					label: i.category.name,
				}))
			);

			setInitialValues(saleDepartment);
		};

		if (id) {
			fetch_sale_department_by_id();
		}
	}, []);

	const fetch_categories = async (term) => {
		const res = await get_categories(term);
		return res.map((r) => ({
			label: r.name,
			value: r.id,
		}));
	};

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const putData = {
				id,
				title: values.title,
				categories: categories?.map((i) => ({ id: i.value })),
			};

			await update_sale_department(id, putData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Rubro actualizado con éxito",
				"El rubro se actualizó con éxito"
			);
			navigate(`/admin/saledepartments`);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al actualizar el rubro",
				"Un error ocurrio al intentar actualizar el rubro, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/saledepartments");
			},
		});
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/saledepartments">Rubros</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Editar Rubro</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<ProductData>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Row gutter={GUTTER}>
										<Col span={22}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Nombre
													</label>
												}
												name="title"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														pattern: new RegExp(
															/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
														),
														message:
															"No se aceptan caracteres especiales. Solo letras y numeros",
													},
												]}
											>
												<Input placeholder="Nombre" />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={GUTTER}>
										<Col span={22}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Categorías asociadas
													</label>
												}
												name="categories"
											>
												<DebounceSelect
													fetchCallback={fetch_categories}
													placeholder="Buscar categorias"
													onValuesChange={setCategories}
													initialValues={categories}
												/>
											</Form.Item>
										</Col>
									</Row>
								</Space>
							</ProductData>
							<PublishSettings>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
											block
										>
											Guardar Cambios
										</SignInTemplate.AntdButton>
									</Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										loading={submitting}
										onClick={handleBack}
										block
									>
										<Typo type="danger" level={6}>
											Volver sin guardar
										</Typo>
									</SignInTemplate.AntdButton>
								</Space>
							</PublishSettings>
						</InnerForm>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
