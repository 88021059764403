import { useEffect, useState } from "react";
import LoadingSkeleton from "./LoadingSkeleton";
import { AntdTable } from "./styles";

export default function DataTable({
	data,
	fetch,
	columns,
	loading = false,
	state,
	...props
}) {
	const [pagination, setPagination] = useState({
		current: 1,
		showSizeChanger: false,
	});

	const fetchData = async (params = {}) => {
		await fetch(params.pagination.current, state && state);

		setPagination({
			...params.pagination,
		});
	};

	useEffect(() => {
		if (!data) return;
		setPagination({
			...pagination,
			total: data.totalCount,
			pageSize: data.pageSize,
			current: data.pageNumber,
			showTotal: (total) => `Total ${total} items`,
		});
	}, [data]);

	const handleTableChange = (newPagination, filters, sorter) => {
		fetchData({
			sortField: sorter.field,
			sortOrder: sorter.order,
			pagination: newPagination,
			...filters,
		});
	};

	if (!data) return <LoadingSkeleton />;

	return (
		<AntdTable
			loading={loading}
			columns={columns}
			rowKey={(record) => record.id}
			dataSource={data.items}
			pagination={pagination}
			onChange={handleTableChange}
			{...props}
		/>
	);
}
