import { Select } from "antd";
import React, { useEffect } from "react";
import { get_template_by_id } from "../../../../../../../../api/endpoints/templates";
import {
	Container,
	InfoContainer,
	InfoNumber,
	InfoSection,
	InfoTitle,
	ModalContainer,
	SelectContainer,
} from "./styles";

const { Option } = Select;

export default function ModalTemplates({
	initialModalValues,
	setInitialModalValues,
	selectTemplate,
	setSelectTemplate,
	templateList,
	templateName,
	setTemplateName,
}) {
	const fetch_data_template_id = async () => {
		const dataPOS = await get_template_by_id(selectTemplate);
		setInitialModalValues({
			...dataPOS,
			totalProductQuantity: dataPOS.items?.reduce(function (prev, current) {
				return prev + +current.quantity;
			}, 0),
		});
	};

	useEffect(() => {
		fetch_data_template_id();
	}, [selectTemplate]);

	const handleChange = (value, object) => {
		setTemplateName(object);
		setSelectTemplate(value);
	};

	return (
		<ModalContainer>
			{templateList ? (
				<Container>
					<SelectContainer>
						<Select
							placeholder="Listado de plantillas"
							onChange={handleChange}
							style={{ width: "100%" }}
							value={templateName?.children}
						>
							{templateList.map((temp, i = 0) => {
								return (
									<Option key={temp.id} value={temp.id}>
										{temp.name}
									</Option>
								);
							})}
						</Select>
					</SelectContainer>
					<InfoSection>
						{initialModalValues && templateName ? (
							<InfoContainer>
								<InfoTitle>
									Productos en plantilla:
									<InfoNumber>{initialModalValues.items.length}</InfoNumber>
								</InfoTitle>

								<InfoTitle>
									Unidades totales en plantilla:
									<InfoNumber>
										{initialModalValues.totalProductQuantity}
									</InfoNumber>
								</InfoTitle>
							</InfoContainer>
						) : (
							<></>
						)}
					</InfoSection>
				</Container>
			) : (
				<Container>
					<InfoTitle>No hay plantillas para mostrar</InfoTitle>
				</Container>
			)}
		</ModalContainer>
	);
}
