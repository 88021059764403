import { Button, Col, Form, Row } from "antd";
import React, { useState, useEffect } from "react";
import { complete_client_registration } from "../../../../api/endpoints/auth";
import { get_distributors } from "../../../../api/endpoints/distributors";
import { get_sale_departments } from "../../../../api/endpoints/saledepartments";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import DistributorsSelector from "../../../common/distributos-selector/DistributorsSelector";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import GeneralInformation from "./components/general-information/GeneralInformation";
import SalesDepartments from "./components/sales-department/SalesDepartments";
import Image from "../../../common/image/Image";
import MarketLogo from "../../../../assets/images/Onetransfer-logo.png";
import DropShadow from "../../../common/drop-shadow/DropShadow";
import { InnerForm } from "./styles";
import { Typo } from "../../../layout/theme";
import { useAuth } from "../../../../contexts/authContext";
import DistributorsCode from "../../../common/distributors-code/DistributorsCode";

const GUTTER = 7;

export default function CompleteUserData() {
	const [form] = Form.useForm();
	const [distributors, setDistributors] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const { user, userdata, updateVerifiedEmail } = useAuth();

	useEffect(() => {
		if (user && !userdata?.emailVerified) {
			updateVerifiedEmail(user.uid, true);
		}
	}, []);

	useEffect(() => {
		const fetch_data = async () => {
			const distributors = await get_distributors();
			const saledepartments = await get_sale_departments();

			const init = {
				distributors: distributors,
				saledepartments: saledepartments,
			};
			setInitialValues(init);
		};

		fetch_data();
	}, []);

	const onFinish = async (values) => {
		try {
			const newDistributor = distributors.map((d) =>
				d.checked === true
					? d.code === undefined || d.code.trim() === ""
						? { ...d, code: null }
						: { ...d, code: d.code.trim() }
					: { ...d, code: null }
			);

			if (newDistributor.filter((d) => d.code !== null).length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe seleccionar y cargar el código de al menos una droguería"
				);
				return;
			}

			setSubmitting(true);

			const postData = {
				saleDepartments: values.saledepartments.map((s, i) => {
					return {
						id: s.id,
						selected: s.selected,
						key: `k_${i}`,
					};
				}),
				distributors: newDistributor.map((d, i) => {
					return {
						id: d.id,
						code: d.code,
						key: `k_${i}`,
					};
				}),
			};
			const formData = new FormData();
			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);
			formData.append("data", JSON.stringify(postData));

			await complete_client_registration(formData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Registro completado",
				`Sus datos se guardaron con exito!`
			);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"No se pudo completar la operacion",
				"Error al intentar guardar datos de registro"
			);
		} finally {
			setSubmitting(false);
		}
	};

	if (!initialValues) return <div>Loading...</div>;

	return (
		<SignInTemplate>
			<SignInTemplate.Main>
				<SignInTemplate.Top>
					<SignInTemplate.Title>
						Completar datos de Usuario
					</SignInTemplate.Title>
					<SignInTemplate.Desc>
						Complete sus datos para unirse a One Transfer
					</SignInTemplate.Desc>
				</SignInTemplate.Top>

				<SignInTemplate.Body>
					<DropShadow>
						<SignInTemplate.Logo>
							<Image
								src={MarketLogo}
								alt="Logo FarmaTransfer"
								height="45px"
								width="220px"
							/>
						</SignInTemplate.Logo>
						<SignInTemplate.Formulario>
							<Form
								form={form}
								name="complete-user-registration"
								onFinish={onFinish}
								requiredMark={false}
								layout="vertical"
								wrapperCol={{ span: 24 }}
								initialValues={initialValues}
							>
								<InnerForm>
									<Row gutter={GUTTER}>
										<Col span={24} align="start">
											<GeneralInformation form={form} />
										</Col>
									</Row>
									<Row gutter={GUTTER}>
										<Col span={24} align="start">
											<Typo level={4} type="primary">
												Droguerías
											</Typo>
										</Col>
										<Typo level={5} type="darkmuted">
											Ingrese el código de las droguerías con las cuales trabaja
										</Typo>
										<SignInTemplate.Break height="15px" />
										<Col span={20} align="start">
											<DistributorsCode onChange={setDistributors} />
											{/* <DistributorsSelector
												onChange={setDistributors}
												initialValues={distributors}
												noBuyer={
													userdata.isSeller || userdata.isOneMarket
														? true
														: false
												}
											/> */}
										</Col>
									</Row>
									<SignInTemplate.Break height="20px" />
									<Row gutter={GUTTER}>
										<Col span={24} align="start">
											<SalesDepartments
												form={form}
												initialValues={initialValues.saledepartments}
											/>
										</Col>
									</Row>
									<Row gutter={GUTTER}>
										<Col>
											<Form.Item>
												<Button
													type="primary"
													htmlType="submit"
													loading={submitting}
												>
													Guardar y acceder a Onetransfer
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</InnerForm>
							</Form>
						</SignInTemplate.Formulario>
					</DropShadow>
				</SignInTemplate.Body>
			</SignInTemplate.Main>
		</SignInTemplate>
	);
}
