import {
	SortableContext,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";

function DraggableWrapper(props) {
	const { children, ...restProps } = props;
	return (
		<SortableContext
			items={
				children[1] instanceof Array
					? children[1].map((child) => child.key)
					: []
			}
			strategy={verticalListSortingStrategy}
			{...restProps}
		>
			<tbody {...restProps}>{children}</tbody>
		</SortableContext>
	);
}

export default DraggableWrapper;
