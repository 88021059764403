import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";
import { Button as ButtonAntd } from "antd";

export const Inner = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 30px;

  @media only screen and (min-width: 760px) and (max-width: 1060px) {
    flex-direction: column;
  }
`;

export const InfoSection = styled.div`
  flex: 1 1 70%;
  width: 100%;
  background-color: ${COLORS.White};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 20px;
  align-items: start;
  gap: 10px;
`;

export const SubmitSection = styled.div`
  flex: 1 1 30%;
  width: 100%;
  height: 250px;
  position: sticky;
  top: 15%;
  background-color: ${COLORS.White};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 20px;
  align-items: center;
  gap: 10px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
`;

export const FirstTable = styled.div`
  flex: 1 1 40%;
`;

export const SecondTable = styled.div`
  flex: 1 1 60%;
`;

export const InfoRegion = styled.div`
  color: ${COLORS.Primary};
  font-size: ${SIZES.level6};
  font-weight: ${WEIGHT.Bold};
`;

export const CartAntdButton = styled(ButtonAntd)`
  cursor: pointer;

  &:hover {
    color: ${COLORS.Primary};
    border: 2px solid ${COLORS.White};
    background-color: ${COLORS.White};
    box-shadow: 0 1px 1px #bfbfbf;
  }

  background-color: ${COLORS.Primary};
  color: ${COLORS.White};
  border-radius: 5px;
  box-shadow: 0 3px 3px 0 #c7c7c7;
`;
