import axiosInstance from "../axiosInstance";

const CONTROLLER = "MegaDistributorEmails";

const create_mega_distributor_email = async (data) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, data);
	return response.data;
};

const update_mega_distributor_email = async (id, emailData) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, emailData);
	return response.data;
};

const delete_mega_distributor_email = async (id) => {
	const response = await axiosInstance.delete(
		`${CONTROLLER}/DeleteMegaDistributorEmail/${id}`
	);
	return response.data;
};

const get_emails_by_megaDistributorId = async (megaDistributorId) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetByMegaDistributorId?megaDistributorId=${megaDistributorId}`
	);
	return response.data;
};

export {
	create_mega_distributor_email,
	update_mega_distributor_email,
	delete_mega_distributor_email,
	get_emails_by_megaDistributorId,
};
