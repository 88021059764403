import styled from "styled-components";

export const InnerForm = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const Inner = styled.div`
	display: flex;
	gap: 20px;
`;

export const LeftData = styled.div`
	flex: 1 1 65%;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const Container = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	padding-top: 5px;
`;

export const NewMarketing = styled.div``;

export const InfoContainer = styled.div`
	background-color: white;
	border-radius: 10px;
	padding: 20px;
`;
