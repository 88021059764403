import React, { useEffect, useState } from "react";
import { Input, Form, Row, Col, Popover, Tooltip } from "antd";
import { DataContainer, Inner } from "./styles";
import { COLORS } from "../../../../../layout/theme";
import UploadFileImage from "../../../../../common/upload-file-with-image/UploadFileImage";
import { CONSTANTS } from "../../../../../../utils/constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import CustomAntdTooltip from "../../../../../common/custom-antd-tooltip/CustomAntdTooltip";

const GUTTER = 10;

const ColorPicker = ({ value = "", onChange }) => {
	const [visible, setVisible] = useState(false);
	const [color, setColor] = useState(value);

	const handleVisibleChange = (newVisible) => {
		setVisible(newVisible);
	};

	const handleChangeComplete = (e) => {
		const newColor = e.target.value;
		setColor(newColor);
		onChange(newColor);
		setVisible(false);
	};

	const handleInputChange = (e) => {
		const newColor = e.target.value;
		setColor(newColor);
		onChange(newColor);
	};

	useEffect(() => {
		setColor(value);
	}, [value]);

	return (
		<Popover
			content={
				<input
					type="color"
					value={color}
					onChange={handleChangeComplete}
					style={{ border: "none", cursor: "pointer" }}
				/>
			}
			trigger="click"
			visible={visible}
			onVisibleChange={handleVisibleChange}
		>
			<div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
				<div
					style={{
						width: 36,
						height: 36,
						backgroundColor: color,
						border: "1px solid #ddd",
					}}
				/>
				<Input
					value={color}
					onChange={handleInputChange}
					style={{ width: "120px", marginLeft: "10px" }}
				/>
			</div>
		</Popover>
	);
};

const ConfigurationUpdate = ({
	form,
	handleImageChange,
	appLogo,
	posImageUrl,
	offerImageUrl,
	cartImageUrl,
	orderImageUrl,
}) => {
	const formValues = Form.useWatch([], form);

	const handleColorChange = (field, color) => {
		form.setFieldsValue({
			[field]: color,
		});
	};

	return (
		<Inner>
			<DataContainer>
				<Row gutter={GUTTER} style={{ width: "100%" }}>
					<Col span={2}></Col>
					<Col span={6}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
										whiteSpace: "nowrap",
										overflow: "visible",
									}}
								>
									Logo
								</label>
							}
							name="appLogoFile"
						>
							<UploadFileImage
								onChange={(e) =>
									handleImageChange(e, CONSTANTS.MOBILE_CONFIG_APP_LOGO)
								}
								urlImage={appLogo}
								imageHeight="100%"
								imageWidth="100%"
							/>
						</Form.Item>
					</Col>
					<Col span={2}></Col>
					<Col span={6}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
										whiteSpace: "nowrap",
										overflow: "visible",
									}}
								>
									Creación orden
								</label>
							}
							name="posFile"
						>
							<UploadFileImage
								onChange={(e) =>
									handleImageChange(e, CONSTANTS.MOBILE_CONFIG_POS_IMAGE)
								}
								urlImage={posImageUrl}
								imageHeight="100%"
								imageWidth="100%"
							/>
						</Form.Item>
					</Col>
					<Col span={2}></Col>
					<Col span={6}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Ofertas
								</label>
							}
							name="offerFile"
						>
							<UploadFileImage
								onChange={(e) =>
									handleImageChange(e, CONSTANTS.MOBILE_CONFIG_OFFER_IMAGE)
								}
								urlImage={offerImageUrl}
								imageHeight="100%"
								imageWidth="100%"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={GUTTER}>
					<Col span={2}></Col>
					<Col span={6}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Carro
								</label>
							}
							name="cartFile"
						>
							<UploadFileImage
								onChange={(e) =>
									handleImageChange(e, CONSTANTS.MOBILE_CONFIG_CART_IMAGE)
								}
								urlImage={cartImageUrl}
								imageHeight="100%"
								imageWidth="100%"
							/>
						</Form.Item>
					</Col>
					<Col span={2}></Col>
					<Col span={6}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Checkout
								</label>
							}
							name="orderFile"
						>
							<UploadFileImage
								onChange={(e) =>
									handleImageChange(e, CONSTANTS.MOBILE_CONFIG_ORDER_IMAGE)
								}
								urlImage={orderImageUrl}
								imageHeight="100%"
								imageWidth="100%"
							/>
						</Form.Item>
					</Col>
					<Col span={2}></Col>
					<Col span={6}></Col>
				</Row>
			</DataContainer>
			<DataContainer>
				<Row gutter={GUTTER} style={{ width: "100%" }}>
					<Col span={8}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Color principal
								</label>
							}
							name="mainColor1"
							rules={[]}
						>
							<ColorPicker
								value={formValues?.mainColor1}
								onChange={(color) => handleColorChange("mainColor1", color)}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Color secundario
								</label>
							}
							name="mainColor2"
							rules={[]}
						>
							<ColorPicker
								value={formValues?.mainColor2}
								onChange={(color) => handleColorChange("mainColor2", color)}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Color terciario
								</label>
							}
							name="mainColor3"
							rules={[]}
						>
							<ColorPicker
								value={formValues?.mainColor3}
								onChange={(color) => handleColorChange("mainColor3", color)}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={GUTTER}>
					{/* <Col span={8}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Color éxito
								</label>
							}
							name="successColor"
							rules={[]}
						>
							<ColorPicker
								value={formValues?.successColor}
								onChange={(color) => handleColorChange("successColor", color)}
							/>
						</Form.Item>
					</Col> */}
					<Col span={8}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Color iconos home{" "}
									<CustomAntdTooltip
										width="200px"
										tooltipContent={[
											{ title: "Se aplica en", body: "Iconos tarjeta home" },
											{ body: "Texto ingreso cupones" },
										]}
									>
										<InfoCircleOutlined />
									</CustomAntdTooltip>
								</label>
							}
							name="successColorLight"
							rules={[]}
						>
							<ColorPicker
								value={formValues?.successColorLight}
								onChange={(color) =>
									handleColorChange("successColorLight", color)
								}
							/>
						</Form.Item>
					</Col>
				</Row>
				{/* <Row gutter={GUTTER}>
					<Col span={8}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Color error
								</label>
							}
							name="errorColor"
							rules={[]}
						>
							<ColorPicker
								value={formValues?.errorColor}
								onChange={(color) => handleColorChange("errorColor", color)}
							/>{" "}
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={
								<label
									style={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								>
									Color advertencia
								</label>
							}
							name="warningColor"
							rules={[]}
						>
							<ColorPicker
								value={formValues?.warningColor}
								onChange={(color) => handleColorChange("warningColor", color)}
							/>
						</Form.Item>
					</Col>
				</Row> */}
			</DataContainer>
		</Inner>
	);
};

export default ConfigurationUpdate;
