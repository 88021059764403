import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Main from "../../../../layout/main/Main";
import { Space, Dropdown, Menu, Modal, Radio } from "antd";
import { ActionButton, AntdRadioButton, Filters, RadioOptions } from "./styles";
import { COLORS, Typo } from "../../../../layout/theme";
import DataTable from "../../../../common/data-table/DataTable";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import LoadingHeader from "./components/LoadingHeader";
import HistoryModal from "./components/HistoryModal/HistoryModal";
import { useAuth } from "../../../../../contexts/authContext";
import {
	get_orders_requests,
	get_orders_requests_header_info,
} from "../../../../../api/endpoints/orders";
import AntdSearch from "../../../../common/antd-search/AntdSearch";

export default function MobileRequestList() {
	const { updateRequestValidation, setMobileRequestsQty } = useAuth();
	const [data, setData] = useState(null);
	const [state, setState] = useState("PENDING");
	const [key, setKey] = useState("null");
	const [headerInfo, setHeaderInfo] = useState(null);
	const [show, setShow] = useState(false);
	const [history, setHistory] = useState(null);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Laboratorio</div>,
			dataIndex: "laboratoryName",
			render: (value, r) => (
				<Link
					to={`/admin/mobileRequests/${key.id}`}
					onMouseOver={() => setKey(r)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Link>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Vendedor mobile</div>,
			dataIndex: "sellerMobile",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Nombre Punto de Venta</div>
			),
			dataIndex: "pointOfSaleName",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Provincia</div>,
			dataIndex: "provinceName",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Localidad</div>,
			dataIndex: "localityName",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Telefono</div>,
			dataIndex: "contactPhone",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Mail</div>,
			dataIndex: "contactEmail",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Pedido</div>,
			dataIndex: "orderNumId",
			render: (fila, r) => (
				<Link
					to={`/admin/mobileRequests/${key.id}`}
					onMouseOver={() => setKey(r)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{fila}
					</Typo>
				</Link>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={
							r.users[0].approved
								? r.deleted
									? menuRejected
									: menuApproved
								: r.deleted
								? menuRejected
								: menu
						}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/mobileRequests/${key.id}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
			]}
		/>
	);

	const menuApproved = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/mobileRequests/${key.id}`}>
							<Typo type="secondary">Ver</Typo>
						</Link>
					),
				},
			]}
		/>
	);

	const menuRejected = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/mobileRequests/${key.id}`}>
							<Typo type="secondary">Ver</Typo>
						</Link>
					),
				},
			]}
		/>
	);

	const fetch_requests = async (page, state, search) => {
		const res = await get_orders_requests(search, state, page);
		setData({
			...res,
			items: res.items?.map((r) => ({
				...r,
				orderNumId: r.orders[0]?.orderNumId,
				laboratoryName: r.orders[0]?.seller.laboratory.name,
				sellerMobile:
					r.orders[0]?.seller.firstName + " " + r.orders[0]?.seller.lastName,
				pointOfSaleName: r.orders[0]?.pointOfSale?.name,
				localityName:
					r.orders[0]?.pointOfSale?.locality_Codes[0]?.locality?.title,
				provinceName:
					r.orders[0]?.pointOfSale?.locality_Codes[0]?.locality?.province[0]
						?.province?.title,
			})),
		});
	};

	const fetch_requests_header = async () => {
		const res = await get_orders_requests_header_info();
		setHeaderInfo(res);
		setMobileRequestsQty(res.totalPending);
	};

	useEffect(() => {
		fetch_requests_header();
	}, [state]);

	useEffect(() => {
		fetch_requests(1, state, "");
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_requests(1, state, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleOk = () => {
		setShow(false);
	};

	const handleCancel = () => {
		setShow(false);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Solicitudes Mobile
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<AntdSearch
								width={400}
								allowClear
								placeholder="Buscar farmacia"
								onChange={debouncedEventHandler}
							/>
						</Space>
					)}
				</Filters>
				<RadioOptions>
					<Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
						<AntdRadioButton value="PENDING">
							Pendientes ({headerInfo?.totalPending})
						</AntdRadioButton>
						<AntdRadioButton value="APPROVED">
							Aprobadas ({headerInfo?.totalApproved})
						</AntdRadioButton>
						<AntdRadioButton value="REJECTED">
							Rechazadas ({headerInfo?.totalRejected})
						</AntdRadioButton>
					</Radio.Group>
				</RadioOptions>
				<DataTable data={data} fetch={fetch_requests} columns={columns} />
				<Modal
					open={show}
					onOk={handleOk}
					onCancel={handleCancel}
					width={900}
					centered={true}
					footer={null}
					destroyOnClose={true}
				>
					<HistoryModal client={key} history={history} />
				</Modal>
			</Main.Body>
		</Main>
	);
}
