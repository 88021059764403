import { Row, Skeleton, Col } from "antd";
import React from "react";
import { Inner, BigHeader, Main, Container, BigPublicity } from "./styles";

export default function LoadingSkeleton() {
	return (
		<Inner>
			<BigHeader />
			<Main>
				<Row>
					<Col style={{ display: "flex", alignItems: "center" }} span={24}>
						<Skeleton.Input size="large" style={{ marginLeft: "30px" }} />
						<Skeleton.Button
							style={{ marginLeft: "15px" }}
							size="small"
							shape="round"
						/>
					</Col>

					<Container>
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
					</Container>
				</Row>

				<Row style={{ marginTop: "40px" }}>
					<Col style={{ display: "flex", alignItems: "center" }} span={24}>
						<Skeleton.Input size="large" style={{ marginLeft: "30px" }} />
						<Skeleton.Button
							style={{ marginLeft: "15px" }}
							size="small"
							shape="round"
						/>
					</Col>
					<Container>
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
					</Container>
				</Row>

				<Row style={{ marginTop: "40px" }}>
					<Col style={{ display: "flex", alignItems: "center" }} span={24}>
						<Skeleton.Input size="large" style={{ marginLeft: "30px" }} />
						<Skeleton.Button
							style={{ marginLeft: "15px" }}
							size="small"
							shape="round"
						/>
					</Col>
					<Container>
						<BigPublicity />
						<BigPublicity />
					</Container>
				</Row>

				<Row style={{ marginTop: "40px" }}>
					<Col style={{ display: "flex", alignItems: "center" }} span={24}>
						<Skeleton.Input size="large" style={{ marginLeft: "30px" }} />
						<Skeleton.Button
							style={{ marginLeft: "15px" }}
							size="small"
							shape="round"
						/>
					</Col>
					<Container>
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
					</Container>
				</Row>

				<Row style={{ marginTop: "40px" }}>
					<Col style={{ display: "flex", alignItems: "center" }} span={24}>
						<Skeleton.Input size="large" style={{ marginLeft: "30px" }} />
						<Skeleton.Button
							style={{ marginLeft: "15px" }}
							size="small"
							shape="round"
						/>
					</Col>
					<Container>
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
						<Skeleton.Image style={{ width: "220px", height: "200px" }} />
					</Container>
				</Row>

				<Row style={{ marginTop: "40px" }}>
					<Col style={{ display: "flex", alignItems: "center" }} span={24}>
						<Skeleton.Input size="large" style={{ marginLeft: "30px" }} />
						<Skeleton.Button
							style={{ marginLeft: "15px" }}
							size="small"
							shape="round"
						/>
					</Col>
					<Container>
						<Skeleton.Image style={{ width: "150px", height: "100px" }} />
						<Skeleton.Image style={{ width: "150px", height: "100px" }} />
						<Skeleton.Image style={{ width: "150px", height: "100px" }} />
						<Skeleton.Image style={{ width: "150px", height: "100px" }} />
						<Skeleton.Image style={{ width: "150px", height: "100px" }} />
						<Skeleton.Image style={{ width: "150px", height: "100px" }} />
						<Skeleton.Image style={{ width: "150px", height: "100px" }} />
						<Skeleton.Image style={{ width: "150px", height: "100px" }} />
						<Skeleton.Image style={{ width: "150px", height: "100px" }} />
					</Container>
				</Row>
			</Main>
		</Inner>
	);
}
