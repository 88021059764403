import styled from "styled-components";
import { SIZES, COLORS, WEIGHT } from "../../../../../layout/theme";
import { Input } from "antd";

const { Search } = Input;

export const Filters = styled.div`
	margin: 20px 0;

	& button {
		padding: 0 !important;
	}
`;
export const AntdSearch = styled(Search)`
	& .ant-input-group-addon:last-child {
		display: none;
	}
	& input.ant-input {
		font-size: ${SIZES.level11};
	}
	& input.ant-input {
		height: 35px;
		width: 400px;
	}
	& span.ant-input-affix-wrapper {
		border-radius: 5px !important;
	}
`;
export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const Span = styled.span`
	cursor: pointer;
	color: ${COLORS.Secondary};
	margin-right: 60px;
	font-weight: ${WEIGHT.ExtraBold};
`;

export const SpanCointainer = styled.div`
	margin: 60px 0;
`;
