import { Row } from "antd";
import styled, { css, keyframes } from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../layout/theme";

const spin = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

export const Inner = styled.div`
	height: 300px;
	cursor: pointer;
	background-color: ${COLORS.White};
	border-radius: 6px;
`;
export const Header = styled.div`
	border-bottom: 1px solid ${COLORS.Grey};
	display: flex;
	gap: 5px;
`;
export const HeaderLeft = styled.div`
	padding-left: 3px;
`;
export const InfoLab = styled.div`
	height: 25px;
	width: 25px;
`;
export const InfoLabContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
`;

export const LabName = styled.div`
	white-space: nowrap;
	margin-top: -6px;

	@media only screen and (min-width: 760px) and (max-width: 1290px) {
		overflow: hidden;
	}
`;

export const BottomContainer = styled.div``;
export const FooterContainer = styled.div`
	padding-right: 5px;
	padding-left: 5px;
	height: 40px;
`;

export const SectionContainer = styled.div`
	padding-right: 5px;
	padding-left: 5px;
	height: 50px;
`;
export const Main = styled.div`
	justify-content: space-between;
	align-items: center;
	align-content: center;
	display: flex;
	flex-direction: column;
	height: 210px;
`;

export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: end;
	background-color: white;
	height: 40px;
	border-radius: 0 0 6px 6px;
`;

export const FooterPricesLeft = styled.div`
	padding-top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	scale: 1;
	flex-direction: row;

	@media only screen and (min-width: 760px) and (max-width: 1180px) {
		align-items: center;
		justify-content: center;

		span {
			font-size: 11px;
		}

		#symbol {
			font-size: 6px;
		}

		#clientType {
			font-size: 6px;
		}
	}

	@media only screen and (min-width: 1181px) and (max-width: 1300px) {
		align-items: center;
		justify-content: center;

		span {
			font-size: 13px;
		}

		#symbol {
			font-size: 8px;
		}

		#clientType {
			font-size: 6px;
		}
	}
`;

export const FooterPricesCenter = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	align-content: center;
	margin-top: 4px;
	gap: 10px;
	scale: 1;

	@media only screen and (min-width: 760px) and (max-width: 1300px) {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		align-content: center;
		margin-top: 4px;

		span {
			font-size: 7px;
		}
	}
`;
export const FooterPricesLeftTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: content;
`;
export const FooterPricesLeftTextBox = styled.div`
	line-height: 0;
	margin-right: 10px;
`;

export const FooterPricesRight = styled.div`
	padding-top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	scale: 1;

	@media only screen and (min-width: 760px) and (max-width: 1123px) {
		align-items: center;
		justify-content: center;

		span {
			font-size: 14px;
		}
	}

	@media only screen and (min-width: 1123px) and (max-width: 1300px) {
		align-items: center;
		justify-content: center;

		span {
			font-size: 16px;
		}
	}
`;
export const FooterPriceTextOff = styled.p`
	font-weight: 700;
	writing-mode: vertical-lr;
	font-size: 9px;
	height: 9px;
	margin-right: -7px;
	color: #646464;
	transform-origin: bottom;
	transform: rotateZ(180deg);

	@media only screen and (min-width: 760px) and (max-width: 1180px) {
		font-size: 6px;
		transform: rotateZ(180deg) translateY(5px);
	}
`;
export const FooterPrices = styled.div`
	border-top: 1px solid ${COLORS.Grey};
`;

export const FooterButtons = styled.div`
	height: 40px;
	background: ${COLORS.Primary};
	color: ${COLORS.White};
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;

	& button {
		background: ${COLORS.Primary} !important;
		color: ${COLORS.White} !important;
		border: none !important;
		height: 100% !important;
		font-size: ${SIZES.level10} !important;
		width: 100%;
	}

	& input {
		background: ${COLORS.Primary} !important;
		color: ${COLORS.White} !important;
		border: none !important;
		height: 100% !important;
		outline: none !important;
		box-shadow: none !important;
		text-align: center !important;
		font-size: ${SIZES.level5} !important;
		width: 100%;
	}
`;

export const FooterInputs = styled.div`
	height: 40px;
	background: ${COLORS.Primary};
	color: ${COLORS.White};
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	border-radius: 0 0 6px 6px;
	transition: all 1s ease-out;

	& button {
		background: ${COLORS.Primary} !important;
		color: ${COLORS.White} !important;
		border: none !important;
		height: 100% !important;
		font-size: ${SIZES.level10} !important;
		width: 100%;
	}

	& input {
		background: ${COLORS.White} !important;
		color: ${COLORS.Primary} !important;
		transition: color 0.2s ease-out;
		border: none !important;
		height: 100% !important;
		outline: none !important;
		box-shadow: none !important;
		text-align: center !important;
		font-size: ${SIZES.level5} !important;
		font-weight: ${WEIGHT.Bold} !important;
		width: 100%;
	}
`;

export const Spinner = styled.div`
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border-left-color: ${COLORS.White};
	animation: ${spin} 1s ease infinite;
`;

export const SpinnerSection = styled.div`
	display: flex;
	height: 40px;
	background: ${COLORS.Primary};
	justify-content: center;
	align-items: center;
	color: ${COLORS.White};
	border-radius: 0 0 6px 6px;
`;
