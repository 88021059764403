import React, { useState } from "react";
import Image from "../../common/image/Image";
import { COLORS, Typo } from "../../layout/theme";
import {
	AntdTable,
	ButtonContainer,
	CenterData,
	ContainerModal,
	ImageContainer,
	Inner,
	ProductContainer,
	ProductSection,
	RightData,
	Section,
	SelectData,
	TopCenter,
} from "./styles";
import moment from "moment";
import { Modal } from "antd";
import { InfoCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import CouponImage from "../../../assets/images/coupon.svg";
import { CONSTANTS } from "../../../utils/constants";
import CustomAntdTooltip from "../../common/custom-antd-tooltip/CustomAntdTooltip";

export default function CouponCardContainer({ item, onSelect }) {
	const [isModalVisible, setIsModalVisible] = useState();
	const [isBuyersModalVisible, setIsBuyersModalVisible] = useState();

	const columns = [
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Orden</div>,
			width: 120,
			dataIndex: "orderNumId",
			render: (value, record) => <Typo level={7}>{value}</Typo>,
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Punto de venta</div>,
			dataIndex: "pointOfSaleName",
			render: (value, record) => (
				<Typo type="primary" level={7}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Dirección</div>,
			dataIndex: "pointOfSaleAddress",
			render: (value, record) => (
				<Typo type="muted" level={7}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Cuit</div>,
			dataIndex: "pointOfSaleCuit",
			render: (value, record) => (
				<Typo type="muted" level={7}>
					{value}
				</Typo>
			),
		},
	];

	return (
		<Inner conditional={!!onSelect}>
			<ImageContainer>
				<Image
					src={item?.photoUrl ?? CouponImage}
					width={90}
					borderRadius="10px"
					preview={true}
				/>
			</ImageContainer>
			<CenterData>
				<TopCenter>
					<Section>
						<Typo level={4} type="primary">
							{item?.title}
						</Typo>
						{item?.strictProductValidation && (
							<CustomAntdTooltip
								width="150px"
								tooltipContent={[{ title: "Cupón combo" }]}
							>
								<InfoCircleOutlined
									style={{ color: COLORS.Warning, cursor: "pointer" }}
								/>
							</CustomAntdTooltip>
						)}
					</Section>

					<Typo level={5} type="secondary">
						{item?.code}
					</Typo>
					<Typo
						level={6}
						type="terciary"
						onClick={() => setIsModalVisible(true)}
						style={{ cursor: "pointer" }}
					>
						{item?.client?.name} - Productos en cupón: {item?.totalProducts}
					</Typo>
					<Typo
						level={6}
						type="terciary"
						onClick={() => setIsModalVisible(true)}
						style={{ cursor: "pointer" }}
					>
						Droguerías válidas: {item?.totalDistributors}{" "}
						<CustomAntdTooltip
							width="250px"
							tooltipContent={[
								{ title: "Droguerías" },
								{ body: `${item?.distributors.map((p) => " " + p.name)}` },
							]}
						>
							<InfoCircleOutlined />
						</CustomAntdTooltip>
					</Typo>
					<Typo
						level={6}
						type="muted"
						onClick={() => setIsModalVisible(true)}
						style={{ cursor: "pointer" }}
					>
						Tipo de cupón: {item?.type}{" "}
						{item?.type === CONSTANTS.COUPON_TYPES[1].value
							? ` - Producto: ${item.triggerProductTitle} ${item.triggerPresentationName} (${item.triggerEan13})`
							: ""}
					</Typo>
					<Typo
						level={6}
						type="muted"
						onClick={() => setIsModalVisible(true)}
						style={{ cursor: "pointer" }}
					>
						Descuento: {item?.discount}%{" "}
						{item?.minQuantity > 1
							? `con un mínimo de ${item?.minQuantity} un.`
							: ""}
					</Typo>
				</TopCenter>
			</CenterData>
			<RightData>
				<Typo
					type="primary"
					onClick={() => setIsBuyersModalVisible(true)}
					style={{ cursor: "pointer" }}
				>
					Fecha de inicio: {moment(item.publishFrom).format("DD MMMM YYYY")}
				</Typo>
				<Typo
					type="primary"
					onClick={() => setIsBuyersModalVisible(true)}
					style={{ cursor: "pointer" }}
				>
					Fecha de cierre: {moment(item.publishTo).format("DD MMMM YYYY")}
				</Typo>
				{item?.activeLimits && (
					<Typo
						type="primary"
						onClick={() => setIsBuyersModalVisible(true)}
						style={{ cursor: "pointer" }}
					>
						Usos: {item?.currentUses} / {item?.limitUses}
					</Typo>
				)}
				{item?.activeLimits && (
					<Typo
						type="primary"
						onClick={() => setIsBuyersModalVisible(true)}
						style={{ cursor: "pointer" }}
					>
						Unidades: {item?.currentUnits} / {item?.limitUnits}
					</Typo>
				)}
			</RightData>
			{onSelect && (
				<SelectData>
					<ButtonContainer>
						<RightCircleOutlined
							style={{ color: COLORS.Primary, fontSize: 30 }}
							onClick={() => onSelect(item)}
						/>
					</ButtonContainer>
				</SelectData>
			)}
			<Modal
				title={
					<Typo level={4} type="primary">
						Productos
					</Typo>
				}
				open={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}
				width={900}
				bodyStyle={{ height: "400px", overflowY: "scroll" }}
				centered={true}
				footer={null}
			>
				<ContainerModal>
					{item?.products?.map((p, idx) => (
						<ProductContainer key={idx} style={{ width: "70%" }}>
							<ProductSection style={{ flex: "1 1 10%" }}>
								<Image src={p.photoUrl} height={40} />
							</ProductSection>
							<ProductSection style={{ flex: "1 1 40%" }}>
								<Typo level={7} type="primary">
									{p.title} {p.presentationName}
								</Typo>
								<Typo level={7} type="muted">
									{p.ean13}
								</Typo>
							</ProductSection>
						</ProductContainer>
					))}
				</ContainerModal>
			</Modal>
			<Modal
				title={
					<Typo level={4} type="primary">
						Compradores
					</Typo>
				}
				open={isBuyersModalVisible}
				onOk={() => setIsBuyersModalVisible(false)}
				onCancel={() => setIsBuyersModalVisible(false)}
				width={900}
				bodyStyle={{ height: "400px" }}
				centered={true}
				footer={null}
			>
				<ContainerModal>
					<AntdTable
						dataSource={item?.buyers}
						columns={columns}
						pagination={false}
						scroll={{ y: "200px" }}
					/>
				</ContainerModal>
			</Modal>
		</Inner>
	);
}
