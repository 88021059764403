import { Form, Input, Select, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	get_campaign_by_id,
	update_campaign,
} from "../../../../../../api/endpoints/campaigns";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import ImageGallery from "../../../../../common/image-gallery/ImageGallery";
import Main from "../../../../../layout/main/Main";
import { COLORS, Typo } from "../../../../../layout/theme";
import CampaignTable from "./components/campaign-table/CampaignTable";
import {
	CartAntdButton,
	Container,
	Inner,
	SecondTable,
	InfoSection,
	SubmitSection,
} from "./styles";

const { Option } = Select;

export default function CreateCampaign() {
	const [form] = Form.useForm();
	const [selectedClient, setSelectedClient] = useState(null);
	const [distributors, setDistributors] = useState(null);
	const [realProducts, setRealProducts] = useState([]);
	const [campaignOffer, setCampaignOffer] = useState(0);
	const [photoGallery, setPhotoGallery] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [checkboxState, setCheckboxState] = useState(false);
	const [distributorsForCampaign, setDistributorsForCampaign] = useState(null);
	const [initialValues, setInitialValues] = useState(null);
	const [initialRegion, setInitialRegion] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const { id } = useParams();
	const navigate = useNavigate();

	const fetch_campaign = async () => {
		const res = await get_campaign_by_id(id);
		const campaign = {
			title: res.title,
			client: { value: res.clientId, key: res.clientId, label: res.clientName },
			campaignDate: [
				moment(res.publishFrom, "YYYY-MM-DD"),
				moment(res.publishTo, "YYYY-MM-DD"),
			],
			state: res.isActive
				? { value: "ACTIVE", key: "ACTIVE", label: "Activa" }
				: { value: "INACTIVE", key: "INACTIVE", label: "Inactiva" },
			distributor: res.campaignDistributors.map((i) => ({
				value: i.id,
				key: i.id,
				label: i.name,
			})),
			campaignOffer: res.campaignOffer,
		};

		setIsActive(
			(res.isActive &&
				moment(res.publishFrom, "YYYY-MM-DD") <= moment() &&
				moment(res.publishTo, "YYYY-MM-DD") >= moment()) ||
				(res.isActive &&
					moment(res.publishFrom, "YYYY-MM-DD") < moment() &&
					moment(res.publishTo, "YYYY-MM-DD") <= moment())
		);

		setInitialValues(campaign);
		setPhotoGallery(res.photoGallery);
		setSelectedClient(res.clientId);
		setRealProducts(res.campaignProducts);
		setInitialRegion(res.campaignRegion);
		setState(res.isActive === true ? "ACTIVE" : false);
		setDistributorsForCampaign(res.campaignDistributors.map((i) => i.id));
		setCampaignOffer(res.campaignOffer);
	};

	const handlePhotoGalleryChange = (values) => {
		setPhotoGallery(values);
	};

	useEffect(() => {
		fetch_campaign();
	}, []);

	const onFinish = async (values) => {
		if (!photoGallery || photoGallery.length === 0) {
			return openNotificationWithIcon(
				TYPE.ERROR,
				"Imagen obligatoria",
				"Un error ocurrio al intentar crear la campaña, no ingreso una imagen"
			);
		}
		try {
			const campaign = {
				title: values.title,
				isActive: state === "ACTIVE" ? true : false,
				publishFrom: moment(values.campaignDate[0]).format("YYYY-MM-DD"),
				publishTo: moment(values.campaignDate[1]).format("YYYY-MM-DD"),
				clientId: values.client.value || values.client,
			};
			const formData = new FormData();
			formData.append("data", JSON.stringify(campaign));
			photoGallery.forEach((f) => {
				formData.append("files", f.originFileObj);
			});
			const res = await update_campaign(id, formData);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Campaña se actualizo con exito",
				"La campaña se actualizo con exito"
			);

			navigate("/admin/advertising/campaigns");
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al actualizar la campaña",
				"Un error ocurrio al intentar actualizar la campaña, contacte con soporte a soporte@onetransfer.com."
			);
		}
	};

	if (!initialValues) return <p>Cargando...</p>;

	const handleBack = () => {
		navigate("/admin/advertising/campaigns");
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/advertising/campaigns">Campañas</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Actualizar Campaña
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Form
					form={form}
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
					initialValues={initialValues}
				>
					<Inner>
						<InfoSection>
							<Typo type="primary" level={4}>
								Actualizar Campaña
							</Typo>
							<Typo type="secondary" level={5} style={{ marginTop: "20px" }}>
								Información Campaña
							</Typo>
							<Container>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Nombre de la campaña
										</label>
									}
									name="title"
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
										{
											pattern: new RegExp(
												/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
											),
											message:
												"No se aceptan caracteres especiales. Solo letras y numeros",
										},
									]}
								>
									<Input placeholder="Titulo" disabled={isActive} />
								</Form.Item>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Laboratorio
										</label>
									}
									name="client"
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									<Select
										style={{ width: "200px" }}
										placeholder="Elegir Cliente"
										disabled={true}
									></Select>
								</Form.Item>
							</Container>
							<Container>
								<Form.Item
									name="campaignDate"
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Fecha
										</label>
									}
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									<Space direction="vertical">
										<div>
											<Typo>Fecha de Inicio: </Typo>
											<Typo type="primary">
												{moment(initialValues.campaignDate[0]._i).format(
													"DD-MM-YYYY HH:mm:ss"
												)}
											</Typo>
										</div>
										<div>
											<Typo>Fecha de Finalización: </Typo>
											<Typo type="primary">
												{moment(initialValues.campaignDate[1]._i).format(
													"DD-MM-YYYY HH:mm:ss"
												)}
											</Typo>
										</div>
									</Space>
								</Form.Item>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Estado
										</label>
									}
									name="state"
								>
									<Select
										defaultValue="ACTIVE"
										onChange={(e) => setState(e)}
										disabled={isActive}
									>
										<Select.Option value="ACTIVE">Activa</Select.Option>
										<Select.Option value="INACTIVE">Inactiva</Select.Option>
									</Select>
								</Form.Item>
							</Container>
							<Container>
								<Typo type="primary" level={6}>
									Galeria
								</Typo>
								<ImageGallery
									data={photoGallery}
									onChange={handlePhotoGalleryChange}
									disabled={isActive ? true : false}
								/>
							</Container>
							{photoGallery === null ||
								(photoGallery.length === 0 && (
									<Typo type="danger" level={8}>
										*La foto es obligatoria
									</Typo>
								))}
							<Typo type="secondary" level={5} style={{ marginTop: "20px" }}>
								Región
							</Typo>
							<Container>
								<div style={{ display: "flex", flexDirection: "column" }}>
									{initialRegion.map((i) => (
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												gap: "20px",
											}}
										>
											<p>Pais: {i.country}</p>
											{i.provinceName && <p>Provincia: {i.provinceName}</p>}
											{i.localitieName && <p>Localidad: {i.localitieName}</p>}
										</div>
									))}
								</div>
							</Container>
							<Typo type="secondary" level={5} style={{ marginTop: "20px" }}>
								Distribuidor/es
							</Typo>
							<Container>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Distribuidores habilitados para la campaña
										</label>
									}
									name="distributor"
									rules={[
										{
											required: distributorsForCampaign ? false : true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									{checkboxState ? (
										<Select
											mode="multiple"
											allowClear
											style={{
												width: "100%",
											}}
											placeholder="Distribuidores"
											disabled={true}
											value={distributors.map((e) => ({
												key: e.id,
												value: e.id,
											}))}
										>
											{distributors?.map((i) => (
												<Option value={i.id}>{i.name}</Option>
											))}
										</Select>
									) : (
										<Select
											mode="multiple"
											allowClear
											style={{
												width: "100%",
											}}
											placeholder="Distribuidores"
											disabled={true}
											onChange={(e) => setDistributorsForCampaign(e)}
										>
											{distributors?.map((i) => (
												<Option value={i.id}>{i.name}</Option>
											))}
										</Select>
									)}
								</Form.Item>
							</Container>
							<Typo type="secondary" level={5} style={{ marginTop: "20px" }}>
								Productos
							</Typo>
							<Container>
								<SecondTable style={{ marginTop: "16px" }}>
									<CampaignTable
										data={realProducts}
										campaignOffer={campaignOffer}
									/>
								</SecondTable>
							</Container>
						</InfoSection>

						<SubmitSection>
							<Typo type="secondary" level={5} style={{ marginTop: "20px" }}>
								Publicar
							</Typo>
							<Form.Item
								label={
									<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
										% Adicional
									</label>
								}
								name="campaignOffer"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
							>
								<Input
									disabled={true}
									placeholder="% Adicional"
									type="number"
									onChange={(e) => setCampaignOffer(e.target.value)}
								/>
							</Form.Item>
							{!isActive && (
								<CartAntdButton
									type="Primary"
									color="white"
									bg="Primary"
									htmlType="submit"
									style={{ width: "150px" }}
								>
									Guardar cambios
								</CartAntdButton>
							)}
							{isActive && (
								<CartAntdButton
									type="Primary"
									color="white"
									bg="Primary"
									onClick={handleBack}
									style={{ width: "150px" }}
								>
									Volver
								</CartAntdButton>
							)}
						</SubmitSection>
					</Inner>
				</Form>
			</Main.Body>
		</Main>
	);
}
