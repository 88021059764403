import React, { useEffect, useState } from "react";
import { TableContainer, AntdTable, ActionButton, AntdModal } from "./styles";
import { Dropdown, Menu } from "antd";
import { COLORS, Typo } from "../../../../../layout/theme";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
	get_response_history,
	get_response_history_detail,
} from "../../../../../../api/endpoints/distributors";
import moment from "moment";

export default function ResponseHistory({ isLoading }) {
	const [data, setData] = useState(false);
	const [key, setKey] = useState(null);
	const [dataDetails, setDataDetails] = useState(null);
	const [showHistoryDetailModal, setShowHistoryDetailModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const fetch_import_history = async () => {
		setLoading(true);
		const res = await get_response_history();
		setData(res.items);
		setLoading(false);
	};

	useEffect(() => {
		fetch_import_history();
	}, [isLoading]);

	const handleHistoryDetail = async (historyId) => {
		setLoading(true);
		const res = await get_response_history_detail(historyId);
		setDataDetails(res);
		setShowHistoryDetailModal(true);
		setLoading(false);
	};

	const handleOk = () => {
		setShowHistoryDetailModal(false);
	};

	const handleCancel = () => {
		setShowHistoryDetailModal(false);
	};

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Typo type="secondary" onClick={() => handleHistoryDetail(key?.id)}>
							Ver Detalle
						</Typo>
					),
				},
			]}
		/>
	);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Fecha</div>,
			dataIndex: "created",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Usuario</div>,
			dataIndex: "user",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Droguería</div>,
			dataIndex: "distributor",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Archivo</div>,
			dataIndex: "fileName",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			width: 50,
			align: "center",
			className: "noBackground",
			render: (f, r, i) => (
				<>
					<Dropdown overlay={menu} placement="bottomRight">
						<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const detailColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>TRF</div>,
			dataIndex: "orderNumId",
			fixed: "left",
			width: 150,
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Laboratorio</div>,
			dataIndex: "productClient",
			fixed: "left",
			width: 150,
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Ean13</div>,
			dataIndex: "productEan13",
			fixed: "left",
			width: 150,
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "productTitle",
			fixed: "left",
			width: 150,
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Presentación</div>,
			dataIndex: "productPresentation",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cantidad</div>,
			dataIndex: "unitsTRF",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Facturadas</div>,
			dataIndex: "unitsBilled",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Pendientes</div>,
			dataIndex: "unitsPending",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Estado</div>,
			dataIndex: "state",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Fecha</div>,
			dataIndex: "created",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{moment(value).format("DD/MM/YYYY")}
				</Typo>
			),
		},
	];

	return (
		<TableContainer>
			<AntdTable
				dataSource={data}
				columns={columns}
				loading={loading}
				pagination={{ pageSize: 25, showSizeChanger: false }}
				rowClassName={(record) => (record.hasChanges ? "hasChanges" : null)}
			/>
			<AntdModal
				title={`Historial importación ${key?.distributor} día: ${key?.created}`}
				open={showHistoryDetailModal}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1200}
				centered={true}
				footer={false}
				destroyOnClose={true}
			>
				<AntdTable
					dataSource={dataDetails?.items}
					columns={detailColumns}
					loading={loading}
					pagination={{ pageSize: 25, showSizeChanger: false }}
					scroll={{
						x: 1500,
						y: 450,
					}}
				/>
			</AntdModal>
		</TableContainer>
	);
}
