import styled from "styled-components/macro";
import { COLORS } from "../../../../../../../layout/theme";

export const Inner = styled.div`
  display: flex;
  margin-top: 30px;
  wdith: 100%;
`;

export const Box = styled.div`
  width: 100%;
  height: 125px;
  background-color: ${COLORS.SkeletonGrey};
`;
