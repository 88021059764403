import { useSortable } from "@dnd-kit/sortable";

function DraggableRow(props) {
	const { attributes, listeners, setNodeRef, isDragging, overIndex, index } =
		useSortable({
			id: props["data-row-key"],
		});
	const isOver = overIndex === index;
	const { children, ...restProps } = props;
	const isData = children instanceof Array;
	const style = {
		...restProps?.style,
		...(isData && isDragging ? { background: "#80808038" } : {}),
		...(isData && isOver ? { borderTop: "5px solid #ec161638" } : {}),
	};
	return (
		<tr ref={setNodeRef} {...attributes} {...restProps} style={style}>
			{children instanceof Array
				? children.map((child) => {
						const { children, key, ...restProps } = child;
						return key === "dragHandle" ? (
							<td {...listeners} {...restProps}>
								{child}
							</td>
						) : (
							<td {...restProps}>{child}</td>
						);
				  })
				: children}
		</tr>
	);
}

export default DraggableRow;
