import styled from "styled-components";
import { COLORS } from "../../../../layout/theme";

export const Inner = styled.div`
  display: flex;
  gap: 50px;
`;
export const CategoryData = styled.div`
  flex: 1 1 80%;
`;
export const PublishSettings = styled.div`
  flex: 1 1 20%;
`;

export const TableHeader = styled.div`
  width: 1300px;
  height: 55px;
  background-color: ${COLORS.SkeletonGrey};
`;
