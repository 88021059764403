import { Badge } from "antd";
import React, { useState } from "react";
import { COLORS } from "../../../layout/theme";
import { DropdownLink, SidebarLabel, SidebarLink } from "./styles";

const AsideBarSubMenuAdminOneMarket = ({
	item,
	setShowDrawer,
	requestsQty,
	mobileRequestsQty,
	ordersQty,
}) => {
	const [subnav, setSubnav] = useState(false);

	const showSubnav = () => setSubnav(!subnav);

	return (
		<>
			<SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
				{item.title === "Solicitudes" ? (
					<div>
						<Badge
							count={requestsQty}
							offset={[-18, -8]}
							size="small"
							color={COLORS.Danger}
						>
							{item.icon}
						</Badge>
						<SidebarLabel>{item.title}</SidebarLabel>
					</div>
				) : item.title === "Administración" ? (
					<div>
						<Badge
							count={ordersQty}
							offset={[-18, -8]}
							size="small"
							color={COLORS.Danger}
						>
							{item.icon}
						</Badge>
						<SidebarLabel>{item.title}</SidebarLabel>
					</div>
				) : item.title === "Mobile" ? (
					<div>
						<Badge
							count={mobileRequestsQty}
							offset={[-18, -8]}
							size="small"
							color={COLORS.Danger}
						>
							{item.icon}
						</Badge>
						<SidebarLabel>{item.title}</SidebarLabel>
					</div>
				) : (
					<div>
						{item.icon}
						<SidebarLabel>{item.title}</SidebarLabel>
					</div>
				)}
				<div>
					{item.subNav && subnav
						? item.iconOpened
						: item.subNav
						? item.iconClosed
						: null}
				</div>
			</SidebarLink>
			{subnav &&
				item.subNav.map((item, index) => {
					return (
						<DropdownLink
							to={item.path}
							key={index}
							onClick={() => setShowDrawer(false)}
						>
							{item.title === "Solicitudes de Farmacias" ? (
								<Badge
									count={requestsQty}
									offset={[-18, -8]}
									size="small"
									color={COLORS.Danger}
								>
									{item.icon}
								</Badge>
							) : item.title === "Solicitudes Mobile" ? (
								<Badge
									count={mobileRequestsQty}
									offset={[-18, -8]}
									size="small"
									color={COLORS.Danger}
								>
									{item.icon}
								</Badge>
							) : item.title === "Ordenes" ? (
								<Badge
									count={ordersQty}
									offset={[-18, -8]}
									size="small"
									color={COLORS.Danger}
								>
									{item.icon}
								</Badge>
							) : (
								item.icon
							)}
							<SidebarLabel onClick={() => setShowDrawer(false)}>
								{item.title}
							</SidebarLabel>
						</DropdownLink>
					);
				})}
		</>
	);
};

export default AsideBarSubMenuAdminOneMarket;
