import { Button, Checkbox } from "antd";
import styled from "styled-components";
import { COLORS, WEIGHT } from "../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;
export const ClientData = styled.div`
	flex: 1 1 65%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
`;
export const PublishSettings = styled.div`
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	max-height: 300px;
`;

export const AfipCuitInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	max-height: 300px;
`;

export const DistributorImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`;
export const PreviewImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: contain;
`;
export const AntdCheckbox = styled(Checkbox)``;

export const BlockedData = styled.div`
	pointer-events: none;
`;

export const RightContainer = styled.div`
	display: flex;
	flex: 1 1 35%;
	flex-direction: column;
	gap: 20px;
`;

export const AntdButton = styled(Button)`
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
  
  border-radius: 20px;

	background-color: ${COLORS.Secondary};
	color: ${COLORS.White};
	font-weigth: ${WEIGHT.Bold};

	span {
		${(props) =>
			props.height &&
			`
        height: ${props.height}px;
      `}
		${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 0 20px 0 20px;
`;

export const Section = styled.div`
	display: flex;
	gap: 10px;
	justify-content: space-between;
`;
