import React, { useState, useEffect, useMemo } from "react";
import { Space, Input, Radio, Dropdown, Menu, Modal } from "antd";
import {
	CloseCircleOutlined,
	PlusOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import {
	ActionButton,
	AntdModal,
	AntdRadioButton,
	Filters,
	Inner,
	RadioOptions,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import LoadingHeader from "./components/LoadingHeader";
import Image from "../../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import { COLORS, Typo } from "../../../../layout/theme";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import DataTable from "../../../../common/data-table/DataTable";
import {
	get_users_by_clientId,
	get_users_by_client_header_info,
	update_user_state,
} from "../../../../../api/endpoints/users";
import NoImageUser from "../../../.././../assets/images/SinImagenDeUsuario.jpeg";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import UpdateUser from "../update/UpdateUser";
import CreateUser from "../create/CreateUser";
import {
	checkRolPermission,
	setRolName,
} from "../../../../../utils/checkRolPermission";
import { Link, useNavigate } from "react-router-dom";
import AntdSearch from "../../../../common/antd-search/AntdSearch";

const { Search } = Input;
const { confirm } = Modal;

export default function UsersList({
	clientId,
	client,
	userType,
	user,
	userdata,
	updateStartGuideProgress,
	guideProgress,
}) {
	const [headerInfo, setHeaderInfo] = useState(null);
	const [data, setData] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [key, setKey] = useState("null");
	const [userUpdated, setUserUpdated] = useState(false);
	const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
	const [showCreateUserModal, setShowCreateUserModal] = useState(false);
	const navigate = useNavigate();

	const handleUpdateUserClick = () => {
		if (data) setShowUpdateUserModal(true);
	};

	const handleCreateUserClick = () => {
		if (data) setShowCreateUserModal(true);
	};

	const handleOk = () => {
		setShowUpdateUserModal(false);
		setShowCreateUserModal(false);
	};

	const handleCancel = () => {
		setShowUpdateUserModal(false);
		setShowCreateUserModal(false);
	};

	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<Image
					width={40}
					height={40}
					borderRadius={40}
					src={value ? value : NoImageUser}
					alt={record.firstName ? record.firstName : "No Image"}
				/>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "firstName",
			render: (value, record) => (
				<Typo
					type="muted"
					fontWeight={600}
					fontSize={14}
					onMouseOver={() => setKey(record)}
					onClick={handleUpdateUserClick}
					style={{ cursor: "pointer" }}
				>
					{value} {record.lastName}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Rol</div>,
			dataIndex: "rol",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={state === "ACTIVE" ? menuActive : menuDeleted}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menuActive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Typo type="secondary" onClick={handleUpdateUserClick}>
							Editar
						</Typo>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => deleteUser(key?.id)}>Eliminar</Typo>,
				},
			]}
		/>
	);

	const menuDeleted = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Typo type="secondary" onClick={handleUpdateUserClick}>
							Editar
						</Typo>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => activateUser(key?.id)}>Activar</Typo>,
				},
			]}
		/>
	);

	const fetch_users = async (search) => {
		const res = await get_users_by_clientId(search, clientId, userType);
		setData({
			items: res
				?.map((r) => ({
					...r,
					rol: setRolName(checkRolPermission(r, client)),
				}))
				.filter((c) => c.deleted === (state === "DELETED")),
		});
	};

	const fetch_users_header = async () => {
		const res = await get_users_by_client_header_info(clientId, userType);
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_users_header();
	}, [state]);

	useEffect(() => {
		fetch_users("");
	}, [state, clientId, userUpdated]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_users(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const deleteUser = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar el usuario?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "El usuario se eliminara de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "DELETED");
			},
		});
	};

	const activateUser = (key) => {
		confirm({
			title: "¿Seguro que quiere activar el usuario?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "El usuario se activara de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "ACTIVE");
			},
		});
	};

	const handleAction = async (key, state) => {
		try {
			const _state = { id: key, state: state };
			await update_user_state(_state);
			fetch_users("");
			fetch_users_header();
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"El usuario no se puede borrar",
				"El usuario no se puede borrar"
			);
		}
	};

	const handleRadioCheck = (e) => {
		setState(e.target.value);
	};

	const handleBackToTheGuide = () => {
		updateStartGuideProgress(user.uid, guideProgress);
		navigate("/startGuide");
	};

	return (
		<Inner>
			<Filters>
				{!headerInfo ? (
					<LoadingHeader />
				) : (
					<Space size="large">
						<AntdSearch
							width={400}
							allowClear
							placeholder="Buscar usuario"
							onChange={debouncedEventHandler}
						/>
						<DropShadow type="drop">
							<SignInTemplate.AntdButton
								type="primary"
								bg="Primary"
								color="White"
								icon={<PlusOutlined />}
								onClick={handleCreateUserClick}
								style={{ width: "200px", fontWeight: "400" }}
							>
								Crear usuario
							</SignInTemplate.AntdButton>
							<AntdModal
								title={`Crear Usuario Web de cuenta ${client.name}`}
								visible={showCreateUserModal}
								onOk={handleOk}
								onCancel={handleCancel}
								width={1200}
								centered={true}
								footer={false}
								destroyOnClose={true}
							>
								<CreateUser
									data={client.users[0]}
									client={client}
									checkCreated={setUserUpdated}
									closeCreateModal={setShowCreateUserModal}
									user={user}
									userdata={userdata}
									updateStartGuideProgress={updateStartGuideProgress}
									guideProgress={guideProgress}
								/>
							</AntdModal>
						</DropShadow>
					</Space>
				)}
			</Filters>
			<RadioOptions>
				<Radio.Group value={state} onChange={handleRadioCheck}>
					<AntdRadioButton value="ACTIVE">
						Activo ({headerInfo?.totalActive})
					</AntdRadioButton>
					<AntdRadioButton value="DELETED">
						Inactivo ({headerInfo?.totalDisabled})
					</AntdRadioButton>
				</Radio.Group>
			</RadioOptions>
			<DataTable data={data} fetch={fetch_users} columns={columns} />
			{userdata?.guideProgress === 3 && (
				<SignInTemplate.AntdButton
					type="primary"
					bg="Primary"
					color="White"
					icon={<QuestionCircleOutlined />}
					onClick={handleBackToTheGuide}
					style={{ width: "250px", fontWeight: "400" }}
				>
					Volver a la Guia de Inicio
				</SignInTemplate.AntdButton>
			)}
			<AntdModal
				title={`Usuario: ${key?.firstName} ${key?.lastName}`}
				visible={showUpdateUserModal}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1200}
				centered={true}
				footer={false}
				destroyOnClose={true}
			>
				<UpdateUser
					data={key}
					client={client}
					checkUpdated={setUserUpdated}
					closeUpdateModal={setShowUpdateUserModal}
				/>
			</AntdModal>
		</Inner>
	);
}
