import { Dropdown, Form, Input, Menu, Modal, Select, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Main from "../../../../layout/main/Main";
import { COLORS, Typo } from "../../../../layout/theme";
import {
	ActionButton,
	AntdModal,
	AntdTable,
	ClientData,
	Container,
	ImageSection,
	Inner,
	InnerForm,
	PublishSettings,
	TableContainer,
} from "./styles";
import {
	TYPE,
	openNotificationWithIcon,
} from "../../../../../utils/notificationToast";
import moment from "moment";
import {
	delete_firebase_user,
	get_firestore_users,
} from "../../../../../api/endpoints/users";
import { CONSTANTS } from "../../../../../utils/constants";
import debounce from "lodash/debounce";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import { CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import { BsThreeDotsVertical } from "react-icons/bs";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";

const { Option } = Select;
const { Paragraph } = Typography;

export default function MarketUsersFirebase() {
	const [isLoading, setIsLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [clientType, setClientType] = useState("");
	const [userType, setUserType] = useState("");
	const [key, setKey] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [firebaseUsersList, setFirebaseUsersList] = useState(null);
	const [modalInputValue, setModalInputValue] = useState("");
	const [emailModified, setEmailModified] = useState(false);

	const fetch_users = async (search) => {
		setIsLoading(true);
		try {
			const res = await get_firestore_users(search, clientType, userType);
			setFirebaseUsersList(res.firebaseUsersWithoutFirestore);
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetch_users("");
	}, [clientType, userType, emailModified]);

	const handleDeleteUserClick = () => {
		if (key) {
			setModalInputValue(key?.email);
			setShowEditModal(true);
		}
	};

	const handleOk = () => {
		setModalInputValue("");
		setShowEditModal(false);
		setKey(null);
	};

	const handleCancel = () => {
		setModalInputValue("");
		setShowEditModal(false);
		setKey(null);
	};

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Correo</div>,
			dataIndex: "email",
			key: "email",
			width: 260,
			render: (value, record, index) => {
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 5,
							cursor: "pointer",
						}}
						onClick={handleDeleteUserClick}
						onMouseOver={() => setKey(record)}
					>
						<Typo level={6} type="primary">
							{value}
						</Typo>
					</div>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Creacion</div>,
			dataIndex: "createdAt",
			key: "createdAt",
			render: (value, record, index) => {
				return (
					<Typo level={6} type="primary">
						{moment(value).format("DD/MM/YYYY")}
					</Typo>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Ultimo Login</div>,
			dataIndex: "accessDate",
			key: "accessDate",
			render: (value, record, index) => {
				return (
					<Typo level={6} type="primary">
						{moment(value).format("DD/MM/YYYY")}
					</Typo>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}></div>,
			dataIndex: "action",
			key: "action",
			width: 50,
			render: (value, record, index) => {
				return (
					<>
						<Dropdown menu={{ items }} placement="bottomRight">
							<ActionButton key={`${value}`} onMouseOver={() => setKey(record)}>
								<BsThreeDotsVertical />
							</ActionButton>
						</Dropdown>
					</>
				);
			},
		},
	];

	const items = [
		{
			key: "1",
			label: <Typo onClick={handleDeleteUserClick}>Editar</Typo>,
		},
	];

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_users(e.target.value);
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 350),
		[clientType, userType]
	);

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				setShowEditModal(false);
			},
		});
	};

	const onFinish = async () => {
		Modal.confirm({
			title: "Aviso de confirmación",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: (
				<Paragraph>
					<p>Se va a eliminar el siguiente correo de firebase:</p> <br />
					<strong>Correo:</strong> {key?.email} <br />
				</Paragraph>
			),
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleUserEdit();
			},
		});
	};

	const handleUserEdit = async () => {
		setSubmitting(true);
		try {
			const res = await delete_firebase_user({
				id: key.uid,
			});
			setShowEditModal(false);
		} catch (error) {
		} finally {
			setSubmitting(false);
			setShowEditModal(false);
			setEmailModified((prev) => !prev);
		}
	};

	return (
		// <Main>
		// 	<Main.Header>
		// 		<Main.Breadcrumb>
		// 			<Main.BreadcrumbItem>
		// 				<Link to="/">Home</Link>
		// 			</Main.BreadcrumbItem>
		// 			<Main.BreadcrumbSeparator />
		// 			<Main.ActiveBreadcrumbItem>
		// 				Usuarios Firebase
		// 			</Main.ActiveBreadcrumbItem>
		// 		</Main.Breadcrumb>
		// 	</Main.Header>
		// 	<Main.Body>
		<Inner>
			<ImageSection>
				<Container>
					<AntdSearch
						width={400}
						allowClear
						placeholder="Buscar correo"
						onChange={debouncedEventHandler}
					/>
				</Container>
				<TableContainer>
					<AntdTable
						columns={columns}
						loading={isLoading}
						dataSource={firebaseUsersList}
						pagination={false}
						scroll={{
							y: 500,
						}}
					/>
				</TableContainer>
			</ImageSection>

			<AntdModal
				title={`Usuario: ${key?.firstName} ${key?.lastName}`}
				visible={showEditModal}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1200}
				centered={true}
				footer={false}
				destroyOnClose={true}
			>
				<InnerForm>
					<ClientData>
						<Input
							type="email"
							disabled
							value={modalInputValue}
							// onChange={(e) => setModalInputValue(e.target.value)}
						/>
					</ClientData>
					<PublishSettings>
						<SignInTemplate.AntdButton
							type="Primary"
							color="white"
							bg="Primary"
							loading={submitting}
							onClick={onFinish}
							block
						>
							Eliminar
						</SignInTemplate.AntdButton>
						<SignInTemplate.AntdButton
							type="Danger"
							loading={submitting}
							onClick={handleBack}
							block
						>
							<Typo type="danger" level={6}>
								Volver sin guardar
							</Typo>
						</SignInTemplate.AntdButton>
					</PublishSettings>
				</InnerForm>
			</AntdModal>
		</Inner>

		// 	</Main.Body>
		// </Main>
	);
}
