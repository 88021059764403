import { Select } from "antd";
import styled from "styled-components";
import { COLORS, WEIGHT } from "../../../../layout/theme";

export const Inner = styled.div``;

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;
export const ClientData = styled.div`
	display: flex;
	flex: 1 1 65%;
	flex-direction: column;
	gap: 20px;
`;
export const PublishSettings = styled.div`
	display: flex;
	flex: 1 1 35%;
	flex-direction: column;
	gap: 20px;
`;

export const AntdSelect = styled(Select)`
	border: none;
	font-weight: ${WEIGHT.SemiBold};
	min-width: 150px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	min-height: 440px;

	.ant-checkbox-group {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	.ant-checkbox-disabled + span {
		color: ${COLORS.Grey};
	}

	.ant-checkbox-disabled .ant-checkbox-inner {
		background-color: ${COLORS.White};
	}

	.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
		border-color: ${COLORS.Secondary};
	}

	.ant-select {
		width: 210px;
	}
`;

export const Section = styled.div``;
