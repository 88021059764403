import { Button, Input } from "antd";
import styled from "styled-components/macro";
import { COLORS } from "../../../layout/theme";

export const CalendarButton = styled(Button)`
	margin-top: 20px;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const Header = styled.div`
	margin-bottom: 20px;
	display: flex;
	gap: 10px;
`;

export const Inner = styled.div`
	padding: 30px 100px;
	background-color: ${COLORS.LightGrey};
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	z-index: 3;
`;

export const TotalsCard = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	gap: 30px;
	width: 40%;
	height: 50px;
	background-color: ${COLORS.White};
	border-radius: 10px;
`;

export const RadioOptions = styled.div`
	margin-bottom: 20px;
	display: flex;
	gap: 20px;
`;

export const CompaginationDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
`;

export const AntdInput = styled(Input)`
	.ant-input-group .ant-input {
		border: 1px solid ${COLORS.Grey};
		border-radius: 10px;
		background-color: transparent;
		text-align: end;
		padding: 5px;
	}

	.ant-input-group .ant-input:hover {
		border: 1px solid ${COLORS.Secondary};
		border-radius: 10px;
	}

	.ant-input-group .ant-input:focus {
		border-radius: 10px;
	}

	.ant-input-group-addon {
		border: 0;
		background-color: transparent;
		padding: 5px;
	}
`;

export const FilterContainer = styled.div`
	display: flex;
	gap: 20px;
	padding: 20px;
	background-color: ${COLORS.White};
	border-radius: 10px;
`;

export const FilterSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
