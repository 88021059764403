import styled from "styled-components/macro";
import { COLORS } from "../../../../../../../layout/theme";

export const ContainerDiv = styled.div`
  width: 100%;
  height: 650px;
  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .ant-table-thead > tr > th {
    background: ${COLORS.Primary};
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: ${COLORS.White};

    div {
      color: ${COLORS.White};
    }
  }

  thead {
    border-color: ${COLORS.White};
    border: 1px solid ${COLORS.White};
  }
`;

export const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
