import React from "react";
import { ModalContainer } from "./styles";
import SaleDetail from "../sale-detail/SaleDetail";

export default function SaleDetailModal({ modalInfo }) {
	return (
		<ModalContainer>
			<SaleDetail modalInfo={modalInfo} />
		</ModalContainer>
	);
}
