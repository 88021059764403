import { Skeleton, Row, Col, Space, Form } from "antd";
import { Inner, ClientData, Table } from "./styles";
import Main from "../../../../../layout/main/Main";

const GUTTER = 10;

export default function LoadingSkeleton() {
  return (
    <Inner>
      <ClientData>
        <Main.Body>
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Form.Item>
                  <Skeleton
                    active={true}
                    paragraph={{ rows: 0 }}
                    style={{ width: 250, marginBottom: -10, marginTop: -20 }}
                  />
                  <Skeleton.Input active />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Skeleton.Image width={150} height={150} alt="avatar" />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 250, marginBottom: -10, marginTop: -20 }}
                />
                <Skeleton.Input active={true} block />
              </Col>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 250, marginBottom: -10, marginTop: -20 }}
                />
                <Skeleton.Input active={true} block />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={6}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 250, marginBottom: -10, marginTop: -20 }}
                />
                <Skeleton.Input active={true} block />
              </Col>
            </Row>
            <Row></Row>
            <Row></Row>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Skeleton
                  active={true}
                  paragraph={{ rows: 0 }}
                  style={{ width: 400, marginBottom: -10, marginTop: -20 }}
                />
              </Col>
            </Row>
            <Table>
              <Row gutter={GUTTER}>
                <Col span={6}>
                  <Skeleton
                    avatar
                    active={true}
                    paragraph={{ rows: 0 }}
                    style={{ width: 200, marginLeft: 10, marginTop: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active={true}
                    style={{ width: 100, marginBottom: 0, marginTop: 9 }}
                  />
                </Col>
              </Row>
              <Row gutter={GUTTER}>
                <Col span={6}>
                  <Skeleton
                    avatar
                    active={true}
                    paragraph={{ rows: 0 }}
                    style={{ width: 200, marginLeft: 10, marginTop: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton.Button
                    active={true}
                    style={{ width: 100, marginBottom: 0, marginTop: 9 }}
                  />
                </Col>
              </Row>
            </Table>

            <Row></Row>
            <Row></Row>
            <Row style={{ marginTop: -20 }}>
              <Col span={2}>
                <Skeleton.Button active={true} block />
              </Col>
              <Col span={2}>
                <Skeleton.Button
                  active={true}
                  block
                  style={{ marginLeft: 10 }}
                />
              </Col>
            </Row>
          </Space>
        </Main.Body>
      </ClientData>
    </Inner>
  );
}
