import React from "react";
import { Form, Input, Button, Row, Col, Space } from "antd";
import { COLORS } from "../../layout/theme/colors/Colors";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../utils/notificationToast";
import { create_email } from "../../../api/endpoints/emails";

const GUTTER = 10;

export default function AddSendMail({ distributorId, setState, updateData }) {
	const [form] = Form.useForm();

	const onFinish = async (values) => {
		try {
			const postData = {
				name: values.name,
				mail: values.mail,
				distributorId: distributorId,
			};
			const res = await create_email(postData);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Mail agregado con exito",
				"El Mail se agrego con exito"
			);
			setState(false);
			updateData();
		} catch (err) {
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Mail no se pudo agregar",
				"Complete todos los datos o contacte con soporte a soporte@onetransfer.com"
			);
			console.log(err);
		}
	};
	return (
		<Form
			form={form}
			name="basic"
			onFinish={onFinish}
			autoComplete="off"
			requiredMark={false}
			layout="vertical"
		>
			<Space direction="vertical" size="middle" style={{ display: "flex" }}>
				<Row gutter={GUTTER}>
					<Col span={12}>
						<Form.Item
							name="name"
							label={
								<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
									Nombre de Contacto
								</label>
							}
							rules={[
								{
									required: true,
									message: "Este campo es obligatorio",
								},
								{
									pattern: new RegExp(
										/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
									),
									message:
										"No se aceptan caracteres especiales. Solo letras y numeros",
								},
							]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={GUTTER}>
					<Col span={12}>
						<Form.Item
							label={
								<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
									Mail
								</label>
							}
							name="mail"
							rules={[
								{
									required: true,
									message: "Este campo es obligatorio",
								},
							]}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>

				<Form.Item>
					<Button type="primary" htmlType="submit">
						Guardar
					</Button>
				</Form.Item>
			</Space>
		</Form>
	);
}
