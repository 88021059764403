import React, { useEffect, useState } from "react";
import { TableContainer, AntdTable } from "./styles";
import { COLORS, Typo } from "../../../../layout/theme";
import { BsDownload } from "react-icons/bs";
import { download_pos_report } from "../../../../../api/endpoints/pointsofsale";
import moment from "moment";

export default function ResultsTable({
	isLoading,
	data,
	expandableData,
	fileName,
}) {
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);

	const handleDownloadFile = async () => {
		setLoading(true);
		try {
			const res = await download_pos_report({
				pointsOfSaleToExport: expandableData,
			});
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const _fileName = fileName + moment().format("DD-MM-YYYY") + ".xlsx";
			link.setAttribute("download", _fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Tipo</div>,
			dataIndex: "title",
			width: 400,
			render: (value, record) => (
				<Typo type="primary" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cantidad</div>,
			dataIndex: "quantity",
			width: 150,
			render: (value, record) => (
				<Typo type="primary" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descargar</div>,
			dataIndex: "download",
			render: (value, record) => (
				<div style={{ cursor: "pointer" }} onClick={handleDownloadFile}>
					<BsDownload style={{ color: COLORS.Warning }} />
				</div>
			),
		},
	];

	const expandedRowRender = () => {
		const expandableColumns = [
			{
				title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
				dataIndex: "name",
				render: (value, record) => (
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				),
			},
			{
				title: () => <div style={{ color: COLORS.White }}>Direccion</div>,
				dataIndex: "address",
				render: (value, record) => (
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				),
			},
			{
				title: () => <div style={{ color: COLORS.White }}>CP</div>,
				dataIndex: "postalCode",
				render: (value, record) => (
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				),
			},
			{
				title: () => <div style={{ color: COLORS.White }}>Cuit</div>,
				dataIndex: "cuit",
				render: (value, record) => (
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				),
			},
			{
				title: () => <div style={{ color: COLORS.White }}>Razon Social</div>,
				dataIndex: "businessName",
				render: (value, record) => (
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				),
			},
			{
				title: () => <div style={{ color: COLORS.White }}>Provincia</div>,
				dataIndex: "province",
				render: (value, record) => (
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				),
			},
			{
				title: () => <div style={{ color: COLORS.White }}>Localidad</div>,
				dataIndex: "location",
				render: (value, record) => (
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				),
			},
		];
		return (
			<AntdTable
				columns={expandableColumns}
				dataSource={expandableData}
				pagination={
					expandableColumns.length < 25
						? false
						: {
								pageSize: 25,
								showSizeChanger: false,
								onChange: (page) => setPage(page),
						  }
				}
			/>
		);
	};

	return (
		<TableContainer>
			<AntdTable
				dataSource={data}
				columns={columns}
				showHeader={false}
				pagination={false}
				expandable={{
					expandedRowRender,
					defaultExpandedRowKeys: ["0"],
				}}
			/>
		</TableContainer>
	);
}
