import React, { useEffect, useState } from "react";
import { get_search } from "../../../../api/endpoints/search";
import {
	AntdInput,
	CompaginationDiv,
	Container,
	ContainerDiv,
	FilterSection,
	Inner,
	ResultsSection,
} from "./styles";
import { useParams, useSearchParams } from "react-router-dom";
import FilterContainer from "../../../containers/filter/FilterContainer";
import ProductSearchCardContainer from "../../../containers/product-search-cards/ProductSearchCardContainer";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import ProductSearchCardListContainer from "../../../containers/product-search-cards-list/ProductSearchCardListContainer";
import { CONSTANTS } from "../../../../utils/constants";
import { Button, Input } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import BuyerStartGuide from "./SearchFirstPage";
import { Typo } from "../../../layout/theme";

export default function Search() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [skip, setSkip] = useState(1);
	const [page, setPage] = useState(CONSTANTS.PAGE_BIG_SIZE);
	const [searchInfo, setSearchInfo] = useState(null);
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [display, setDisplay] = useState("LIST");
	const [width, setWidth] = useState(window.innerWidth);
	const [paginationInputValue, setPaginationInputValue] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const fetch_search = async (skipValue) => {
		setLoading(true);

		const queryParams = {
			i: searchParams.get("i"),
		};

		for (const key in queryParams) {
			if (queryParams[key]) {
				queryParams[key] = queryParams[key].replace(/ /g, "%2B");
			}
		}

		const res = await get_search(
			params.s ? params.s : searchParams.get("s") ? searchParams.get("s") : "",
			searchParams.get("c") ? searchParams.get("c") : "",
			queryParams.i ? queryParams.i : "",
			searchParams.get("l") ? searchParams.get("l") : "",
			searchParams.get("r") ? searchParams.get("r") : "",
			searchParams.get("t") ? searchParams.get("t") : "",
			searchParams.get("p") ? searchParams.get("p") : "",
			searchParams.get("b") ? searchParams.get("b") : "",
			searchParams.get("d") ? searchParams.get("d") : "",
			searchParams.get("h") ? searchParams.get("h") : "",
			"ALL",
			skipValue,
			page
		);
		if (res) {
			setLoading(false);
			setData(res);
			setSearchInfo(res.searchInfo);
			setPaginationInputValue(res.searchInfo?.actualPage);
		}
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_search(cp);
			setSkip(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_search(cp);
			setSkip(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_search(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	useEffect(() => {
		setSkip(1);
		setPage(CONSTANTS.PAGE_BIG_SIZE);
		fetch_search(1);
	}, [params.s, searchParams]);

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	if (loading) return <BuyerStartGuide />;
	if (!data) return <LoadingSkeleton />;

	return (
		<>
			<Inner>
				<FilterSection>
					{data && (
						<FilterContainer
							searchTerm={data?.term}
							appliedFilters={data?.selectedFilters}
							notAppliedFilters={data?.filters}
							resultsQty={data?.quantityResults}
							data={data}
							setDisplay={setDisplay}
							visibleDisplayButton={false}
						/>
					)}
				</FilterSection>
				<ContainerDiv>
					<ResultsSection display={display}>
						{data &&
							display === "CARD" &&
							data?.productSearch?.map((i, idx) => (
								<ProductSearchCardContainer
									key={`card_${i.productId}_${idx}`}
									item={i}
									display={display}
								/>
							))}
						{data &&
							display === "LIST" &&
							data?.productSearch?.map((i, idx) => (
								<ProductSearchCardListContainer
									key={`cardList_${i.productId}_${idx}`}
									item={i}
									display={display}
								/>
							))}
						{searchInfo && searchInfo.totalProducts > 0 && (
							<CompaginationDiv>
								{searchInfo.actualPage !== 1 && (
									<Button size="small" shape="circle" onClick={handleDecrement}>
										<LeftOutlined />
									</Button>
								)}
								<AntdInput
									step={1}
									min={1}
									max={searchInfo.totalPages}
									onPressEnter={(e) => handlePageChange(e)}
									defaultValue={paginationInputValue.toString()}
									addonAfter={`/ ${searchInfo.totalPages}`}
									style={{
										width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
									}}
								/>
								{searchInfo.actualPage < searchInfo.totalPages && (
									<Button
										size="small"
										shape="circle"
										onClick={handleIncrement}
										disabled={
											searchInfo.actualPage === searchInfo.totalPages
												? true
												: false
										}
									>
										<RightOutlined />
									</Button>
								)}
							</CompaginationDiv>
						)}
						{data && data.productSearch.length === 0 && (
							<Container>
								<Typo type="primary" level={6}>
									No hay resultados para esta búsqueda.
								</Typo>
								<Typo type="primary" level={6}>
									El término {data.term} no contiene coincidencias en nuestra
									base de datos.
								</Typo>
							</Container>
						)}
					</ResultsSection>
				</ContainerDiv>
			</Inner>
		</>
	);
}
