import styled from "styled-components";
import { Radio, Select, Modal, Table, Button, Upload } from "antd";
import { COLORS, WEIGHT } from "../../../../layout/theme";

export const Inner = styled.div`
	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.White} !important;
		border: none;
	}
`;

export const Filters = styled.div`
	margin: 20px 0;

	& button {
		padding: 0 !important;
	}
`;

export const RadioOptions = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
`;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const TableContainer = styled.div`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: transparent !important;
		border: none;
	}

	& .hasChanges,
	& .hasChanges:hover {
		color: white;
		background-color: ${COLORS.Default};
	}
`;

export const ConfirmButton = styled.div`
	${(props) =>
		!props.clientId
			? `
	display: flex;
	justify-content: end;
	margin: 20px 0;
  gap: 20px;`
			: `display: flex;
	justify-content: start;
	margin: 20px 0;
  gap: 20px;`}
`;
export const AntdButton = styled(Button)`
	width: 200px;
	height: 40px;
	background-color: ${(props) =>
		props.success ? COLORS.Success : COLORS.Default};
	color: ${COLORS.White};
	border: 1px solid
		${(props) => (props.success ? COLORS.Success : COLORS.Default)};
	border-radius: 7px;
	padding: 0.5rem 0;
	&:hover {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Default};
	}
	& span {
		color: ${COLORS.White};
	}
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
`;

export const ContainerTitle = styled.div`
	display: flex;
	align-items: start;
	gap: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
`;
