import React, { useEffect, useMemo, useState } from "react";
import { Container, Inner, OptionSection, SearchContainer } from "./styles";
import ProductTable from "../product-table/ProductTable";
import debounce from "lodash/debounce";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";

export default function AddProducts({ templateProducts, setTemplateProducts }) {
	const [loading, setLoading] = useState(false);
	const [searchByStore, setSearchByStore] = useState(null);
	const [listedProducts, setListedProducts] = useState(templateProducts);

	function updateQuantities(baseArray, updatesArray) {
		const updatesMap = new Map(
			updatesArray.map((item) => [item.id, item.quantity])
		);

		const updatedProducts = baseArray.map((item) => {
			if (updatesMap.has(item.id)) {
				return { ...item, quantity: updatesMap.get(item.id) };
			}
			return item;
		});

		setTemplateProducts(updatedProducts);
	}

	const handleTableChange = (values) => {
		const updatedProducts = listedProducts.map((product, index) =>
			product.id === values.id
				? { ...product, quantity: parseInt(values.value), hasChanges: true }
				: product
		);

		updateQuantities(templateProducts, updatedProducts);
		setListedProducts(updatedProducts);
	};

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		const searchValue = e.target.value.toLowerCase();
		setSearchByStore(searchValue);
		if (!searchValue) {
			updateQuantities(templateProducts, listedProducts);
			setListedProducts(templateProducts);
		} else {
			const filteredProducts = templateProducts.filter(
				(product) =>
					product.title.toLowerCase().includes(searchValue) ||
					product.ean13.includes(searchValue)
			);
			setListedProducts(filteredProducts);
		}
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 600),
		[templateProducts, listedProducts]
	);

	return (
		<Inner>
			<OptionSection>
				<SearchContainer>
					<AntdSearch
						allowClear
						placeholder="Buscar productos"
						width={459}
						onChange={(e) => {
							setSearchByStore(e.target.value);
							debouncedEventHandler(e);
						}}
						value={searchByStore}
						// onPressEnter={(e) => debouncedEventHandler(e)}
						disabled={loading}
					/>
				</SearchContainer>
			</OptionSection>
			<Container>
				<ProductTable data={listedProducts} onChange={handleTableChange} />
			</Container>
		</Inner>
	);
}
