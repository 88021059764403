import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import COLORS from "../theme/colors/Colors";
import SIZES from "../theme/sizes/Sizes";
import { WEIGHT } from "../theme";
import { IoIosArrowForward } from "react-icons/io";
import { Button as ButtonAntd, Form, Carousel as CarouselAntd } from "antd";

const colorSelector = (color) => {
	const separeteColors = Object.entries(COLORS).join(",").split(",");
	const colorNameIndex = separeteColors.findIndex(
		(element) => element === color
	);
	return colorNameIndex === -1 ? "#fff" : separeteColors[colorNameIndex + 1];
};

const handleOutline = (color) => {
	const separeteColors = Object.entries(COLORS).join(",").split(",");
	const colorNameIndex = separeteColors.findIndex(
		(element) => element === color
	);
	return `border: 1px solid ${separeteColors[colorNameIndex + 1]}`;
};

export const Inner = styled.div`
	min-height: 100vh;
	display: grid;
	grid-template-columns: ${({ columns }) =>
		columns === "2" || columns === 2 ? "2fr 5fr" : "1fr"};
	grid-template-rows: 1fr;
	align-items: center;
`;

export const Carousel = styled.div`
	background-color: ${COLORS.Grey};
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

export const AntdCarousel = styled(CarouselAntd)`
	height: 100%;
	img {
		min-height: 99vh;
		height: 100%;
	}
`;

export const Main = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Logo = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const Top = styled.div`
width 80%;
display flex;
flex-direction: column;
justify-content: center;
align-items: center`;

export const Title = styled.h1`
	text-align: center;
	color: ${COLORS.Primary};
	font-size: ${SIZES.level1};
`;

export const Desc = styled.span`
	color: ${({ color }) => (color ? colorSelector(color) : COLORS.Grey)};
	text-align: center;
	margin-bottom: 0px;
`;

export const Body = styled.div`
	background-color: ${COLORS.White};
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80%;
	padding: 40px 0;
`;

export const Formulario = styled.div`
	padding: ${SIZES.level1} ${SIZES.level1};
`;

export const AntdFormItem = styled(Form.Item)`
	margin: 0;
	padding: 0;
	min-height: ${({ password }) => (password ? "60px" : "0px")};

	div[role="alert"] {
		font-weight: ${WEIGHT.ExtraBold};
		font-size: ${SIZES.level9};
		padding: 0;
		margin: 0;
	}
	input {
		margin: 0;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		height: 40px;
		margin: 0;
		align-items: center;
		font-size: 16px;
	}
`;

export const NavLink = styled(Link)`
	text-decoration: none;
	color: ${({ color }) => (color ? colorSelector(color) : COLORS.Grey)};
	${({ bold }) => bold && "font-weight: 600"}
`;

export const AntdButton = styled(ButtonAntd)`
	background-color: ${({ bg }) => (bg ? colorSelector(bg) : "white")};
	color: ${({ color }) => (color ? colorSelector(color) : COLORS.Black)};
	border-radius: 5px;
	${(props) => props.outline && handleOutline(props.color)}
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
  ${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}

  overflow: hidden;

	span {
		white-space: pre-line;
	}
`;

export const Button = styled.div`
	position: relative;
	width: 100%;
	padding: 5px 0;
	border-radius: 3px;
	cursor: pointer;
	color: ${({ color }) => (color ? colorSelector(color) : "white")};
	font-size: ${SIZES.level7};
	font-weight: ${WEIGHT.ExtraBold};
	filter: drop-shadow(0px 50px 100px black, 0px 30px 60px black);
	background-color: ${({ bg }) => (bg ? colorSelector(bg) : "white")};
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
	transition: 250ms ease-in-out;
	${(props) => props.outline && handleOutline(props.color)}
`;
export const ButtonsContainer = styled.div`
	display: flex;
	gap: 20px;
`;

export const ButtonsOnLeft = styled.div`
	width: 100%;
`;

export const ButtonsOnRight = styled.div`
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const Space = styled.div``;

export const Break = styled.div`
	width: 100%;
	height: ${({ height }) => height};
`;

export const RightArrow = styled(IoIosArrowForward)`
	width: 18px;
	height: 18px;
	background-color: ${COLORS.Primary};
	border-radius: 50%;
	color: ${COLORS.White};
	position: relative;
	top: 5px;
	margin-left: 5px;
	${NavLink}:hover & {
		background-color: ${COLORS.Secondary};
	}
`;

export const SubTitle = styled.h3`
	color: ${({ color }) => (color ? colorSelector(color) : "black")};
`;

export const TermsSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;
	padding: 2rem;

	@media only screen and (min-width: 701px) and (max-width: 900px) {
		text-align: center;
	}

	@media only screen and (min-width: 414px) and (max-width: 700px) {
		text-align: center;
	}

	@media only screen and (min-width: 250px) and (max-width: 413px) {
		text-align: center;
	}
`;
