import axiosInstance from "../axiosInstance";

const CONTROLLER = "countries";

const get_countries = async () => {
  const response = await axiosInstance.get(CONTROLLER);
  return response.data;
};

export { get_countries };
