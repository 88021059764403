//import { useAuth } from "../../../contexts/authContext";

const PermissionCheckVisibility = ({ permission, children }) => {
	// const { userdata } = useAuth;
	// const isAllowed = userdata.some((item) => item === permission);

	if (permission) {
		return children;
	} else {
		return null;
	}
};

export default PermissionCheckVisibility;
