import { Skeleton, Row, Col, Space } from "antd";
import {
	BannerContainer,
	BannerSection,
	BookContainer,
	ButtonContainer,
	Container,
	Inner,
	ProductData,
	ProductsSection,
	SearchContainer,
	TableHeader,
} from "./styles";

const GUTTER = 30;

export default function LoadingSkeleton() {
	return (
		<Inner>
			<BannerSection>
				<ButtonContainer condition={true}>
					{" "}
					<Skeleton.Input active={true} block />
				</ButtonContainer>
			</BannerSection>
			<SearchContainer>
				<Skeleton.Input active={true} style={{ width: 600 }} />
			</SearchContainer>
			<ProductsSection>
				<BookContainer></BookContainer>
			</ProductsSection>
		</Inner>
	);
}
