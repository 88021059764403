import styled from "styled-components/macro";
import { Radio } from "antd";
import { COLORS, WEIGHT } from "../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Filters = styled.div`
	margin: 20px 0;

	& button {
		padding: 0 !important;
	}
`;

export const RadioOptions = styled.div`
	margin-bottom: -20px;
`;

export const AntdRadioButton = styled(Radio.Button)`
	border: none;
	font-weight: ${WEIGHT.Bold};
`;

export const AntdRadioButton2 = styled(Radio.Button)`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	font-weight: ${WEIGHT.Bold};
`;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const TableContainer = styled.div`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const CompaginationDiv = styled.div`
	display: flex;
	gap: 4px;
`;
