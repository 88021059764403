import React, { useEffect, useState } from "react";
import { COLORS, Typo } from "../../../layout/theme";
import { DrawerContainer, FilterContainerButton, Inner } from "./styles";
import { get_pharmacy_general_dashboard } from "../../../../api/endpoints/dashboard";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useAuth } from "../../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../../../utils/constants";
import PharmacyGeneralDashboard from "./components/dashboards/PharmacyGeneralDashboard";
import moment from "moment";
import { PieChartOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Layout, Menu, Tooltip } from "antd";
import { numberWithDotAndCommas } from "../../../../utils/numberWithDotAndComas";
import { useFilter } from "../../../../contexts/filterContext";
import { FaInfo } from "react-icons/fa";
import { numberToMillions } from "../../../../utils/numberToMillions";
const { Sider } = Layout;

export default function DashboardBuyer({ clientId }) {
	const navigate = useNavigate();
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const {
		selectedDistributor,
		setSelectedDistributor,
		selectedLaboratory,
		setSelectedLaboratory,
		selectedPharmacy,
		setSelectedPharmacy,
		selectedPointOfSale,
		setSelectedPointOfSale,
		selectedProvince,
		setSelectedProvince,
		rangeDate,
		setRangeDate,
		dateFrom,
		setDateFrom,
		dateTo,
		setDateTo,
		selectedFilters,
		setSelectedFilters,
	} = useFilter();
	const [collapsed, setCollapsed] = useState(true);
	const [openPanel, setOpenPanel] = useState([]);
	const [display, setDisplay] = useState(CONSTANTS.DASHBOARD_GENERAL);
	const [distributorsD1, setDistributorsD1] = useState(null);
	const [laboratoriesD1, setLaboratoriesD1] = useState(null);
	const [pharmaciesD1, setPharmaciesD1] = useState(null);
	const [provincesD1, setProvincesD1] = useState(null);
	// const [selectedDistributorD1, setSelectedDistributorD1] = useState(null);
	// const [selectedLaboratoryD1, setSelectedLaboratoryD1] = useState(null);
	// const [selectedPharmacyD1, setSelectedPharmacyD1] = useState(clientId);
	// const [selectedPointOfSaleD1, setSelectedPointOfSaleD1] = useState(null);
	// const [selectedProvinceD1, setSelectedProvinceD1] = useState(null);
	// const [dateFromD1, setDateFromD1] = useState(null);
	// const [dateToD1, setDateToD1] = useState(null);
	const [generalInfoD1, setGeneralInfoD1] = useState(null);
	const [pieDataD1, setPieDataD1] = useState(null);
	const [timeOrdersGraphD1, setTimeOrdersGraphD1] = useState(null);
	const [timeTotalSalesD1, setTimeTotalSalesD1] = useState(null);
	const [laboratoryUnitsGraphD1, setLaboratoryUnitsGraphD1] = useState(null);
	const [laboratorySalesGraphD1, setLaboratorySalesGraphD1] = useState(null);
	const [productUnitsGraphD1, setProductUnitsGraphD1] = useState(null);
	const [productSalesGraphD1, setProductSalesGraphD1] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [laboratoryGraphSelector, setLaboratoryGraphSelector] =
		useState("SALES");
	const [productGraphSelector, setProductGraphSelector] = useState("SALES");
	const [dealsInfoSelector, setDealsInfoSelector] = useState("SALES");
	const [openFilterList, setOpenFilterList] = useState(false);

	const fetch_general_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_pharmacy_general_dashboard(
				selectedDistributor,
				selectedLaboratory,
				clientId,
				selectedPointOfSale,
				selectedProvince,
				dateFrom,
				dateTo
			);
			const resDistributorsD1 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD1 = JSON.parse(res?.generalInfo?.provinceList);
			const resLaboratoriesD1 = JSON.parse(res?.generalInfo?.laboratoryList);
			const resPharmaciesD1 = JSON.parse(res?.generalInfo?.pharmacyList);
			const slicedLaboratoryUnits = res?.laboratoryOrders.slice(0, 25);
			const slicedLaboratorySales = res?.laboratorySales.slice(0, 25);
			const slicedProductUnits = res?.productOrders.slice(0, 25);
			const slicedProductSales = res?.productSales.slice(0, 25);

			setDistributorsD1(
				resDistributorsD1?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD1(
				resProvincesD1?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLaboratoriesD1(
				resLaboratoriesD1?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setPharmaciesD1(
				resPharmaciesD1?.map((r) => ({
					value: r.PharmacyId,
					label: r.PharmacyName,
				}))
			);

			setGeneralInfoD1(res?.generalInfo);

			setPieDataD1({
				chart: {
					type: "variablepie",
					height: "100%",
				},
				title: {
					style: { display: "none" },
				},
				credits: { enabled: false },
				tooltip: {
					pointFormat: "<b>{point.y}</b>",
				},
				legend: {
					layout: "vertical",
					align: "right",
					verticalAlign: "top",
					x: 0,
					y: 10,
					floating: false,
				},
				plotOptions: {
					variablepie: {
						size: "100",
						cursor: "pointer",
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
						center: ["40%", "15%"],
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						type: "variablepie",
						zMin: 0,
						name: "Droguerias",
						data: res?.distributorSales?.map((dist, idx) => ({
							name: dist.distributorName,
							y: dist.totalInOrders,
							z: 100,
						})),
					},
				],
			});

			setTimeTotalSalesD1({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Primary, COLORS.Secondary],
				credits: { enabled: false },
				xAxis: {
					categories: res?.totalOrdersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: true,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						stackLabels: {
							enabled: true,
							style: {
								color: COLORS.MediumGrey,
								textOutline: 0,
							},
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return numberToMillions(this.total);
								} else {
									return null;
								}
							},
						},
					},
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: false,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
				],
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "Ventas $",
						valuePrefix: "$",
						data: res?.totalOrdersByYearAndMonth.map((p) => p.totalSales),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.totalOrdersByYearAndMonth[
										res.totalOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.totalOrdersByYearAndMonth.length - 2
										: res?.totalOrdersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
					{
						name: "Unidades",
						valueSuffix: "un.",
						data: res?.totalOrdersByYearAndMonth.map((p) => p.totalUnits),
						yAxis: 1,
						lineWidth: 2,
						lineColor: COLORS.Default,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Default,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.totalOrdersByYearAndMonth[
										res.totalOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.totalOrdersByYearAndMonth.length - 2
										: res?.totalOrdersByYearAndMonth.length,
								color: COLORS.Default,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setLaboratorySalesGraphD1({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedLaboratorySales.map((p) => p.laboratoryName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedLaboratorySales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setLaboratoryUnitsGraphD1({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedLaboratoryUnits.map((p) => p.laboratoryName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedLaboratoryUnits.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductSalesGraphD1({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductSales.map((p) => p.productTitle),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						name: "",
						data: slicedProductSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductUnitsGraphD1({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductUnits.map((p) => p.productTitle),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						name: "",
						data: slicedProductUnits.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetch_general_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedPointOfSale,
		selectedProvince,
		dateFrom,
		dateTo,
	]);

	const handleSelectDistributor = (e, o) => {
		setSelectedDistributor(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Distributor" },
		]);
	};

	const handleSelectProvince = (e, o) => {
		setSelectedProvince(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Province" },
		]);
	};

	const handleSelectLaboratory = (e, o) => {
		setSelectedLaboratory(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Laboratory" },
		]);
	};

	const handleSelectPharmacy = (e, o) => {
		setSelectedPharmacy(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Pharmacy" },
		]);
	};

	const handleSelectPointOfSale = (e) => {
		setSelectedPointOfSale(e);
	};

	const handleLaboratoryGraphSelect = (value) => {
		setLaboratoryGraphSelector(value);
	};

	const handleProductGraphSelect = (value) => {
		setProductGraphSelector(value);
	};

	const handleDealsInfoSelect = (value) => {
		setDealsInfoSelector(value);
	};

	const handleSelectedDate = (dateRange) => {
		const firstDate = moment(dateRange[0]).format("MM-DD-YYYY").toString();
		const lastDate = moment(dateRange[1]).format("MM-DD-YYYY").toString();
		setDateFrom(firstDate);
		setDateTo(lastDate);
		setRangeDate([dateRange[0], dateRange[1]]);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: "Fecha", key: "DateRange" },
		]);
	};

	const handleDeleteFilters = () => {
		setSelectedDistributor(null);
		setSelectedProvince(null);
		setSelectedLaboratory(null);
		setSelectedPharmacy(null);
		setDateFrom(null);
		setDateTo(null);
		setRangeDate(null);
		setSelectedFilters([]);
		onCloseFilterList();
	};

	const handleBackToTheGuide = () => {
		updateStartGuideProgress(user.uid, 6);
		navigate("/startGuide");
	};

	const handleMenuClick = ({ key }) => {
		setDisplay(key);
	};

	const handleFilterRemove = (filterToRemove) => {
		setSelectedFilters((prevFilters) =>
			prevFilters.filter((filter) => filter !== filterToRemove)
		);
		//onCloseFilterList();

		switch (filterToRemove.key) {
			case "Distributor":
				setSelectedDistributor(null);
				break;
			case "Province":
				setSelectedProvince(null);
				break;
			case "Laboratory":
				setSelectedLaboratory(null);
				break;
			case "DateRange":
				setDateFrom(null);
				setDateTo(null);
				setRangeDate(null);
				break;
			case "Pharmacy":
				setSelectedPharmacy(null);
				break;
			default:
				break;
		}
	};

	const showFilterList = (value) => {
		setOpenFilterList(value);
	};

	const onCloseFilterList = () => {
		setOpenFilterList(false);
	};

	return (
		<Layout style={{ minHeight: "100vh" }}>
			<Sider
				theme="light"
				trigger={null}
				collapsible
				collapsed={collapsed}
				// onMouseEnter={() => setCollapsed(false)}
				// onMouseLeave={() => setCollapsed(true)}
			>
				<div></div>
				<Menu
					theme="light"
					mode="inline"
					defaultSelectedKeys={[CONSTANTS.DASHBOARD_GENERAL]}
					style={{ paddingTop: 25 }}
					onClick={handleMenuClick}
				>
					<Tooltip
						placement="right"
						title={"Filtros aplicados"}
						trigger={selectedFilters.length > 0 ? "hover" : ""}
					>
						<FilterContainerButton
							key={CONSTANTS.DASHBOARD_FILTERS}
							onClick={
								selectedFilters.length > 0
									? (prev) => showFilterList((prev) => !prev)
									: null
							}
							conditionFullfilled={selectedFilters.length > 0}
							height={45}
						>
							{selectedFilters.length > 0 ? (
								<FaInfo color={COLORS.Default} size={16} />
							) : null}
						</FilterContainerButton>
					</Tooltip>
					<Menu.Item
						key={CONSTANTS.DASHBOARD_GENERAL}
						icon={<PieChartOutlined />}
						onClick={() => setDisplay(CONSTANTS.DASHBOARD_GENERAL)}
					>
						General
					</Menu.Item>
				</Menu>
				{selectedFilters.length > 0 && (
					<DrawerContainer
						title={
							<Typo type="primary" level={6}>
								Filtros aplicados
							</Typo>
						}
						placement="left"
						closable={false}
						onClose={onCloseFilterList}
						open={openFilterList}
						getContainer={false}
						style={{
							position: "absolute",
							height: "300px",
							left: "80px",
							top: "20px",
						}}
					>
						<>
							{selectedFilters.map((filter, index) => (
								<div
									key={index}
									onClick={() => handleFilterRemove(filter)}
									style={{
										cursor: "pointer",
										height: 30,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typo level={7} type="muted">
										{filter.label}
									</Typo>
									<CloseCircleOutlined style={{ color: COLORS.Danger }} />
								</div>
							))}
						</>
						<div
							onClick={() => onCloseFilterList()}
							style={{
								position: "absolute",
								bottom: 5,
								display: "flex",
								justifyContent: "center",
								alignItems: "flex-end",
								width: "75%",
								cursor: "pointer",
							}}
						>
							<Typo type="muted" level={7}>
								Cerrar
							</Typo>
						</div>
					</DrawerContainer>
				)}
			</Sider>
			<Layout className="site-layout">
				<Inner>
					{userdata?.guideProgress === 5 && (
						<SignInTemplate.AntdButton
							type="primary"
							bg="Primary"
							color="White"
							icon={<QuestionCircleOutlined />}
							onClick={handleBackToTheGuide}
							style={{
								width: "250px",
								fontWeight: "400",
								marginTop: "40px",
								marginLeft: "20px",
							}}
						>
							Volver a la Guia de Inicio
						</SignInTemplate.AntdButton>
					)}
					{display === CONSTANTS.DASHBOARD_GENERAL &&
						(generalInfoD1 ? (
							<PharmacyGeneralDashboard
								dashboard={generalInfoD1}
								provinceList={provincesD1}
								distributors={distributorsD1}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD1}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								laboratories={laboratoriesD1}
								selectLaboratory={handleSelectLaboratory}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								selectedLaboratory={selectedLaboratory}
								isLoading={isLoading}
								optionsPie={pieDataD1}
								timeTotalSales={timeTotalSalesD1}
								laboratorySalesGraph={laboratorySalesGraphD1}
								laboratoryUnitsGraph={laboratoryUnitsGraphD1}
								laboratoryGraphSelector={laboratoryGraphSelector}
								selectLaboratoryGraph={handleLaboratoryGraphSelect}
								productSalesGraph={productSalesGraphD1}
								productUnitsGraph={productUnitsGraphD1}
								productGraphSelector={productGraphSelector}
								selectProductGraph={handleProductGraphSelect}
								dealsInfoSelector={dealsInfoSelector}
								selectDealsInfo={handleDealsInfoSelect}
								rangeDate={rangeDate}
							/>
						) : (
							<LoadingSkeleton />
						))}
				</Inner>
			</Layout>
		</Layout>
	);
}
