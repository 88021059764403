import React from "react";
import { Card, Skeleton, Row, Col } from "antd";
import {
	Container,
	Inner,
	LeftCarousel,
	LeftContainer,
	RightContainer,
} from "./styles";

export default function LoadingSkeleton() {
	return (
		<Inner>
			<Card>
				<Container>
					<LeftContainer>
						<LeftCarousel>
							<Skeleton.Image
								active
								style={{ width: "400px", height: "400px" }}
							/>
						</LeftCarousel>
						<Row>
							<Col span={1}></Col>
							<Col span={4}>
								<Skeleton.Image
									active
									style={{ width: 100, borderRadius: 10, marginTop: 10 }}
								/>
							</Col>
							<Col span={4}></Col>
							<Col span={4}>
								<Skeleton.Image
									active
									style={{ width: 100, borderRadius: 10, marginTop: 10 }}
								/>
							</Col>
							<Col span={4}></Col>
							<Col span={4}>
								<Skeleton.Image
									active
									style={{ width: 100, borderRadius: 10, marginTop: 10 }}
								/>
							</Col>
						</Row>
					</LeftContainer>
					<RightContainer>
						<Row>
							<Col span={12}>
								<Skeleton.Input
									active
									style={{ width: 250, borderRadius: 10, marginTop: 10 }}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Skeleton.Input
									active
									style={{
										width: 180,
										height: 20,
										borderRadius: 10,
										marginTop: 10,
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Skeleton.Input
									active
									style={{
										width: 100,
										height: 10,
										borderRadius: 10,
										marginTop: 30,
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Skeleton.Input
									active
									style={{
										width: 50,
										height: 10,
										borderRadius: 10,
										marginTop: 30,
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Skeleton.Input
									active
									style={{
										width: 250,
										height: 10,
										borderRadius: 10,
										marginTop: 10,
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Skeleton.Input
									active
									style={{
										width: 50,
										height: 10,
										borderRadius: 10,
										marginTop: 30,
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Skeleton.Input
									active
									style={{
										width: 250,
										height: 10,
										borderRadius: 10,
										marginTop: 10,
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Skeleton.Input
									active
									style={{
										width: 150,
										height: 10,
										borderRadius: 10,
										marginTop: 30,
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Skeleton.Input
									active
									style={{
										width: 170,
										height: 10,
										borderRadius: 10,
										marginTop: 10,
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Skeleton.Input
									active
									style={{
										width: 250,
										height: 30,
										borderRadius: 0,
										marginTop: 30,
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={8}>
								<Skeleton.Button
									active
									style={{
										width: 120,
										height: 30,
										borderRadius: 20,
										marginTop: 10,
									}}
								/>
							</Col>
							<Col span={6}></Col>
							<Col span={6}>
								<Skeleton.Button
									active
									style={{
										width: 100,
										height: 30,
										borderRadius: 20,
										marginTop: 10,
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Skeleton.Input
									active
									style={{
										width: 150,
										height: 15,
										borderRadius: 10,
										marginTop: 20,
										marginLeft: 50,
									}}
								/>
							</Col>
						</Row>
					</RightContainer>
				</Container>
			</Card>
		</Inner>
	);
}
