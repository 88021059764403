import React, { useState } from "react";
import {
	TableContainer,
	AntdTable,
	InfoTitle,
	PresentationTitle,
	InfoClient,
	InfoQuantity,
	InfoUnity,
	ExpandableTableContainer,
	Container,
	CouponInfoContainer,
	PriceContainer,
} from "./styles";
import { numberWithDotAndCommas } from "../../../../../../../../utils/numberWithDotAndComas";
import Image from "../../../../../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import { COLORS, Typo } from "../../../../../../../layout/theme";
import { TbArrowsDownUp } from "react-icons/tb";
import { calculatePSLFinalDiscount } from "../../../../../../../../utils/calculatePrice";

export default function Step2Table({ data }) {
	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			width: 400,
			render: (value, record) => (
				<Typo type="primary" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.White,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Productos
				</div>
			),
			dataIndex: "totalItems",
			width: 150,
			render: (value, record) => (
				<Container>
					<Typo type="terciary" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Container>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.White,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Unidades
				</div>
			),
			dataIndex: "totalProducts",
			width: 150,
			render: (value, record) => (
				<Container>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Container>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.White,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Subtotal
				</div>
			),
			dataIndex: "subtotal",
			width: 150,
			render: (value, record) => (
				<Container>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						$ {numberWithDotAndCommas(parseFloat(value).toFixed(2))}
					</Typo>
				</Container>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.White,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Ahorro
				</div>
			),
			dataIndex: "saved",
			width: 150,
			render: (value, record) => (
				<Container>
					<Typo type="secondary" fontWeight={600} fontSize={14}>
						$ {numberWithDotAndCommas(parseFloat(value).toFixed(2))}
					</Typo>
				</Container>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.White,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Total
				</div>
			),
			dataIndex: "total",
			width: 150,
			render: (value, record) => (
				<Container>
					<Typo type="primary" fontWeight={600} fontSize={14}>
						$ {numberWithDotAndCommas(parseFloat(value).toFixed(2))}
					</Typo>
				</Container>
			),
		},
	];

	const expandedRowRender = (record) => {
		const expandableColumns = [
			{
				title: () => (
					<div
						style={{
							fontSize: "18px",
						}}
					>
						<FaImages style={{ color: COLORS.DarkGrey }} />
					</div>
				),
				dataIndex: "photoUrl",
				key: "photoUrl",
				width: 50,
				heigth: 100,
				align: "center",
				render: (photoUrl) => <Image src={photoUrl} width={80} height={80} />,
			},
			{
				title: () => <div style={{ color: COLORS.DarkGrey }}>Nombre</div>,
				dataIndex: "title",
				key: "title",
				width: 100,
				align: "center",
				render: (value, record) => (
					<>
						<InfoTitle>{value}</InfoTitle>
						<PresentationTitle>{record.presentationName}</PresentationTitle>
						<InfoClient>{record.clientName}</InfoClient>
					</>
				),
			},
			{
				title: () => <div style={{ color: COLORS.DarkGrey }}>Precio</div>,
				dataIndex: "price",
				key: "price",
				width: 100,
				align: "center",
				render: (value, record) => (
					<Typo type="muted" level={6}>
						$ {numberWithDotAndCommas(value.toFixed(2))}
					</Typo>
				),
			},
			{
				title: () => <div style={{ color: COLORS.DarkGrey }}>Descuento</div>,
				dataIndex: "price",
				key: "discount",
				width: 50,
				align: "center",
				render: (value, record) => (
					<CouponInfoContainer>
						<PriceContainer>
							{record.generalDiscount !== 0 ? (
								<Typo type="secondary" level={10} translate="no">
									PVP
								</Typo>
							) : (
								<Typo type="secondary" level={10} translate="no">
									PSD
								</Typo>
							)}
							<Typo type="secondary" level={6}>
								{numberWithDotAndCommas(
									(100 - (record.estimatedPrice * 100) / value).toFixed(2)
								)}{" "}
								%
							</Typo>
						</PriceContainer>
						{record.generalDiscount !== 0 && (
							<PriceContainer>
								<Typo type="success" level={11}>
									PSL
								</Typo>
								<Typo type="success" level={9}>
									{numberWithDotAndCommas(
										calculatePSLFinalDiscount(
											record.estimatedPrice,
											value,
											record.generalDiscount
										).toFixed(2)
									)}{" "}
									%
								</Typo>
							</PriceContainer>
						)}
						{record.couponDiscount > 0 && (
							<Typo type="success" level={8}>
								-{record.couponDiscount}% aplicado por cupón
							</Typo>
						)}
					</CouponInfoContainer>
				),
			},
			{
				title: () => <div style={{ color: COLORS.DarkGrey }}>Final</div>,
				dataIndex: "offerPrice",
				key: "offerPrice",
				width: 100,
				align: "center",
				render: (value, record) => (
					<Typo type="primary" level={6}>
						$ {numberWithDotAndCommas(record.estimatedPrice.toFixed(2))}
					</Typo>
				),
			},
			{
				title: () => <div style={{ color: COLORS.DarkGrey }}>Cantidad</div>,
				dataIndex: "quantity",
				key: "quantity",
				width: 60,
				align: "center",
				render: (value, record) => (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 10,
							justifyContent: "space-between",
							whiteSpace: "nowrap",
						}}
					>
						<InfoQuantity>
							{value}
							<InfoUnity>un.</InfoUnity>
						</InfoQuantity>
					</div>
				),
			},
		];
		return (
			<ExpandableTableContainer>
				<AntdTable
					columns={expandableColumns}
					dataSource={record.expandableData}
					pagination={false}
				/>
			</ExpandableTableContainer>
		);
	};

	return (
		<TableContainer>
			<AntdTable
				dataSource={data}
				columns={columns}
				expandable={{
					expandedRowRender,
					defaultExpandAllRows: data && data.length === 1,
					defaultExpandedRowKeys:
						data && data.length === 1 ? [data[0].key] : [],
					columnTitle: <TbArrowsDownUp color={COLORS.White} />,
				}}
				pagination={false}
			/>
		</TableContainer>
	);
}
