import React from "react";
import { Action, Actions, Inner, Top, Separator, Divider } from "./styles";

const Aside = ({ children, ...restProps }) => (
	<Inner {...restProps}>{children}</Inner>
);

export default Aside;

Aside.Top = ({ children, ...restProps }) => {
	return <Top {...restProps}>{children}</Top>;
};

Aside.Actions = ({ children, ...restProps }) => {
	return <Actions {...restProps}>{children}</Actions>;
};

Aside.Action = ({ children, ...restProps }) => {
	return <Action {...restProps}>{children}</Action>;
};
Aside.Separator = ({ children, ...restProps }) => {
	return <Separator {...restProps}>{children}</Separator>;
};
Aside.Divider = ({ children, ...restProps }) => {
	return <Divider {...restProps}>{children}</Divider>;
};
