import styled from "styled-components/macro";
import { COLORS, SIZES } from "../../../layout/theme";

export const Inner = styled.div`
	height: 150px;
	width: 100%;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: ${COLORS.White};
	border-radius: 20px 20px 0px 0px;

	transition-property: transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;

	&:hover {
		transform: scale(1.02);

		button {
			transition: all 1s ease;
			border: solid 1px ${COLORS.Grey};
			background-color: ${COLORS.LightGrey};
			color: ${COLORS.DarkGrey} !important;
		}
	}

	@media only screen and (min-width: 760px) and (max-width: 860px) {
		height: 280px;
		gap: 10px;
	}

	@media only screen and (min-width: 861px) and (max-width: 1181px) {
		height: 280px;
		gap: 10px;
	}
`;
export const TopContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: contain;
	position: relative;
	flex: 0 0 20%;

	@media only screen and (min-width: 760px) and (max-width: 860px) {
		height: 90px;
	}

	@media only screen and (min-width: 861px) and (max-width: 1181px) {
		height: 70px;
	}
`;

export const Info = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 140px;

	@media only screen and (min-width: 760px) and (max-width: 860px) {
		height: 100px;
		gap: 10px;
	}

	@media only screen and (min-width: 861px) and (max-width: 1181px) {
	}
`;

export const InfoHeader = styled.div`
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;

	@media only screen and (min-width: 861px) and (max-width: 1181px) {
	}
`;

export const InfoBody = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 0 20%;

	@media only screen and (min-width: 861px) and (max-width: 1181px) {
		.ant-image-img {
			height: 50px !important;
			width: 50px !important;
		}
	}
`;

export const LinkContainer = styled.div`
	color: ${COLORS.White};
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	& button {
		color: ${COLORS.Grey} !important;
		height: 100% !important;
		width: 100% !important;
		border: solid 1px ${COLORS.LightGrey};
		border-radius: 0 0 20px 20px;
		font-size: ${SIZES.level5} !important;
	}

	@media only screen and (min-width: 760px) and (max-width: 860px) {
		height: 70px;
	}

	@media only screen and (min-width: 861px) and (max-width: 1181px) {
	}
`;

export const SectionContainer = styled.div`
	padding: 1rem;
	display: flex;
	flex-direction: column;
	height: 100%;

	@media only screen and (min-width: 760px) and (max-width: 860px) {
		flex-direction: column;
		gap: 10px;
	}

	@media only screen and (min-width: 861px) and (max-width: 1180px) {
		flex-direction: column;
		gap: 10px;
	}
`;
