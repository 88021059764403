import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button } from "antd";

export default function UploadFile({ onChange }) {
	return (
		<Upload
			multiple={false}
			className="avatar-uploader"
			showUploadList={false}
			beforeUpload={() => false}
			action={null}
			onChange={onChange}
		>
			<Button icon={<UploadOutlined />}>Seleccionar Imagen</Button>
		</Upload>
	);
}
