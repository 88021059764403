import React, { useState } from "react";
import { Form, Input, Row, Col, Space, Button } from "antd";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";

const GUTTER = 10;

export default function VerifySmsCode({
	confirmationResult,
	setConnectedSmsProvider,
	setShowModal,
}) {
	const [form] = Form.useForm();
	const [smsCode, setSmsCode] = useState();
	const [error, setError] = useState("");

	const verifySms = async () => {
		setError("");
		if (smsCode === "" || smsCode === null) return;
		try {
			await confirmationResult.confirm(smsCode);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Se vinculo correctamente su celular"
			);
			setConnectedSmsProvider(true);
			setShowModal(false);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Codigo equivocado, intentelo de nuevo"
			);
		}
	};

	return (
		<Form form={form} name="basic" autoComplete="off" onFinish={verifySms}>
			<Space>
				<Row gutter={GUTTER}>
					<Form.Item
						name="smsCode"
						label="Ingresá tu código"
						rules={[
							{
								required: true,
								message: "Por favor ingresa un código valido",
							},
						]}
					>
						<Input
							type="text"
							value={smsCode}
							onChange={(e) => setSmsCode(e.target.value)}
						/>
					</Form.Item>

					<Col>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Enviar
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Space>
			<div id="recaptcha"></div>
		</Form>
	);
}
