import { Skeleton, Row, Col, Space } from "antd";
import { Inner } from "./styles";

const GUTTER = 10;

export default function LoadingSkeleton() {
	return (
		<Inner>
			<Space direction="vertical" size="middle" style={{ display: "flex" }}>
				<Row gutter={GUTTER}>
					<Col span={24}>
						<Skeleton.Input active={false} block />
					</Col>
				</Row>
				<Row gutter={GUTTER}>
					<Col span={24}>
						<Skeleton.Input active={false} block />
					</Col>
				</Row>
			</Space>
		</Inner>
	);
}
