import React from "react";
import {
	Inner,
	BottomContainer,
	InfoLab,
	Spinner,
	SpinnerSection,
	Header,
	HeaderLeft,
	Main,
	Footer,
	FooterPrices,
	FooterButtons,
	FooterInputs,
	FooterContainer,
	FooterPricesLeft,
	FooterPricesLeftTextContainer,
	FooterPricesLeftTextBox,
	FooterPricesRight,
	FooterPricesCenter,
	SectionContainer,
	InfoLabContainer,
	FooterPriceTextOff,
	LabName,
} from "./styles";

const ProductSlide = ({ children, ...restProps }) => (
	<Inner {...restProps}>{children}</Inner>
);

export default ProductSlide;
ProductSlide.LabName = ({ children, ...restProps }) => {
	return <LabName {...restProps}>{children}</LabName>;
};

ProductSlide.Header = ({ children, ...restProps }) => {
	return <Header {...restProps}>{children}</Header>;
};
ProductSlide.HeaderLeft = ({ children, ...restProps }) => {
	return <HeaderLeft {...restProps}>{children}</HeaderLeft>;
};
ProductSlide.Main = ({ children, ...restProps }) => {
	return <Main {...restProps}>{children}</Main>;
};
ProductSlide.BottomContainer = ({ children, ...restProps }) => {
	return <BottomContainer {...restProps}>{children}</BottomContainer>;
};
ProductSlide.Footer = ({ children, ...restProps }) => {
	return <Footer {...restProps}>{children}</Footer>;
};
ProductSlide.FooterPricesLeft = ({ children, ...restProps }) => {
	return <FooterPricesLeft {...restProps}>{children}</FooterPricesLeft>;
};
ProductSlide.FooterPricesLeftTextContainer = ({ children, ...restProps }) => {
	return (
		<FooterPricesLeftTextContainer {...restProps}>
			{children}
		</FooterPricesLeftTextContainer>
	);
};
ProductSlide.FooterPricesLeftTextBox = ({ children, ...restProps }) => {
	return (
		<FooterPricesLeftTextBox {...restProps}>{children}</FooterPricesLeftTextBox>
	);
};
ProductSlide.FooterPricesRight = ({ children, ...restProps }) => {
	return <FooterPricesRight {...restProps}>{children}</FooterPricesRight>;
};
ProductSlide.FooterPricesCenter = ({ children, ...restProps }) => {
	return <FooterPricesCenter {...restProps}>{children}</FooterPricesCenter>;
};
ProductSlide.FooterPrices = ({ children, ...restProps }) => {
	return <FooterPrices {...restProps}>{children}</FooterPrices>;
};
ProductSlide.FooterPriceTextOff = ({ children, ...restProps }) => {
	return <FooterPriceTextOff {...restProps}>{children}</FooterPriceTextOff>;
};
ProductSlide.SectionContainer = ({ children, ...restProps }) => {
	return <SectionContainer {...restProps}>{children}</SectionContainer>;
};
ProductSlide.FooterContainer = ({ children, ...restProps }) => {
	return <FooterContainer {...restProps}>{children}</FooterContainer>;
};
ProductSlide.FooterButtons = ({ children, ...restProps }) => {
	return <FooterButtons {...restProps}>{children}</FooterButtons>;
};
ProductSlide.FooterInputs = ({ children, ...restProps }) => {
	return <FooterInputs {...restProps}>{children}</FooterInputs>;
};
ProductSlide.Spinner = ({ children, ...restProps }) => {
	return <Spinner {...restProps}>{children}</Spinner>;
};

ProductSlide.SpinnerSection = ({ children, ...restProps }) => {
	return <SpinnerSection {...restProps}>{children}</SpinnerSection>;
};
ProductSlide.InfoLab = ({ children, ...restProps }) => {
	return <InfoLab {...restProps}>{children}</InfoLab>;
};
ProductSlide.InfoLabContainer = ({ children, ...restProps }) => {
	return <InfoLabContainer {...restProps}>{children}</InfoLabContainer>;
};
