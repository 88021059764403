import { Modal, Table } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 40px;
	margin-top: 10px;

	@media only screen and (min-width: 760px) and (max-width: 1060px) {
		flex-direction: column;
	}
`;

export const Header = styled.div`
	display: flex;
	gap: 30px;
`;

export const LeftSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex: 1 1 40%;
	height: 100%;
`;

export const LeftCard = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${COLORS.White};
	padding: 20px;
	border-radius: 10px;
	align-items: center;
	justify-content: center;

	.ant-form-item {
		margin-bottom: 0;
	}
`;

export const ImageCard = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${COLORS.White};
	border-radius: 10px;
	display: flex;
	gap: 20px;
	flex-direction: column;
	padding: 10px 20px 20px 20px;
	align-items: center;
	justify-content: space-evenly;
	min-height: 283px;
`;

export const DataSection = styled.div`
	flex: 1 1 60%;
	background-color: ${COLORS.White};
	padding: 10px 20px 20px 20px;
	border-radius: 10px;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 60%;
	}
`;

export const Container = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: flex-start;
	width: 100%;

	.ant-form-item {
		margin-bottom: 0;
	}
`;

export const ImageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px ${COLORS.Grey};
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	margin-top: 30px;
`;
export const DistributorSection = styled.div`
	flex: 1 1 60%;
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;

	@media only screen and (min-width: 1061px) and (max-width: 1380px) {
		flex: 1 1 60%;
	}
`;

export const Title = styled.span`
	${(props) =>
		props.type === "primary" &&
		`
      color: ${COLORS.Primary};
      font-weight: ${WEIGHT.Bold};
      font-size: ${SIZES.level5};
      display: flex;
      margin-top: 20px;
    `}
`;

export const AntdModal = styled(Modal)`
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
	}
`;

export const AntdTable = styled(Table)`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const DistributorText = styled.div`
	cursor: pointer;
	display: flex;
	gap: 5px;
	justify-content: center;
	align-items: center;
	color: ${(props) =>
		props.textColor === 1 ? COLORS.Primary : COLORS.Secondary};
	font-size: ${SIZES.level6};
	${(props) =>
		props.marginBottom &&
		`
      margin-bottom: ${props.marginBottom}px;
    `}
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const GuideText = styled.div`
	display: flex;
	white-space: nowrap;
	margin-bottom: 10px;
	color: ${COLORS.Primary};
	font-weight: ${WEIGHT.SemiBold};
	font-size: ${SIZES.level5};
	justify-content: center;
	margin-top: 20px;
`;

export const BillerContainer = styled.div`
	display: flex;
	width: 100%;
	padding: 1rem;
	gap: 10px;
	background-color: ${COLORS.White};
	border-radius: 20px;
`;

export const BillerAndCodeContainer = styled.div`
	display: flex;
	gap: 20px;
	width: 100%;
`;

export const BillerSelection = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
`;

export const BillerCode = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	justify-content: center;
`;

export const IconContainer = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	cursor: pointer;
`;
