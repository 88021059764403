import React, { useState } from "react";
import { Select, Input, Form, Row, Col, DatePicker } from "antd";
import { Inner } from "./styles";
import { COLORS } from "../../../../../../layout/theme";
import { CONSTANTS } from "../../../../../../../utils/constants";
import { BsLockFill, BsUnlockFill } from "react-icons/bs";
import UploadFileImage from "../../../../../../common/upload-file-with-image/UploadFileImage";

const { Option } = Select;
const GUTTER = 10;
const { RangePicker } = DatePicker;

const CouponBasicInfo = ({
	form,
	handleChange,
	selectedClient,
	clients,
	disabledDate,
	selectedTriggerProduct,
	triggerProducts,
	imageUrl,
	handleImageChange,
}) => {
	const formValues = Form.useWatch([], form);
	const [editUnlockCouponCode, setEditUnlockCouponCode] = useState(true);

	const couponType = Form.useWatch("type", form);

	return (
		<Inner>
			<Row gutter={GUTTER} style={{ width: "100%" }}>
				<Col span={4}>
					<Form.Item
						label={
							<label
								style={{
									color: COLORS.Primary,
									fontWeight: "600",
								}}
							>
								Imagen
							</label>
						}
						name="file"
					>
						<UploadFileImage
							onChange={handleImageChange}
							urlImage={imageUrl}
							imageHeight="100%"
							imageWidth="100%"
						/>
					</Form.Item>
				</Col>
				<Col span={20}>
					<Row gutter={GUTTER}>
						{couponType !== CONSTANTS.COUPON_TYPES[2].value && (
							<Col span={8}>
								<Form.Item
									label={
										<label
											style={{
												color: COLORS.Primary,
												fontWeight: "600",
											}}
										>
											Laboratorio
										</label>
									}
									name="clientId"
								>
									<Select
										disabled
										optionFilterProp="children"
										options={clients}
									/>
								</Form.Item>
							</Col>
						)}
						<Col span={8}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Nombre cupón
									</label>
								}
								name="title"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
									{
										pattern: new RegExp(
											/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
										),
										message:
											"No se aceptan caracteres especiales. Solo letras y numeros",
									},
								]}
							>
								<Input placeholder="Nombre" />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Tipo de cupón
									</label>
								}
								name="type"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
									{
										pattern: new RegExp(
											/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
										),
										message:
											"No se aceptan caracteres especiales. Solo letras y numeros",
									},
								]}
							>
								<Select
									showSearch
									placeholder="Elegi un tipo"
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label ?? "")
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									options={
										couponType === CONSTANTS.COUPON_TYPES[2].value
											? CONSTANTS.COUPON_TYPES[3]
											: CONSTANTS.COUPON_TYPES.slice(0, 2)
									}
									disabled={couponType === CONSTANTS.COUPON_TYPES[2].value}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={GUTTER}>
						{couponType === CONSTANTS.COUPON_TYPES[1].value && (
							<Col span={8}>
								<Form.Item
									label={
										<label
											style={{
												color: COLORS.Primary,
												fontWeight: "600",
											}}
										>
											Producto disparador
										</label>
									}
									name="triggerId"
								>
									<Select
										showSearch
										placeholder="Elegi un producto"
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? "")
												.toLowerCase()
												.includes(input.toLowerCase())
										}
										options={triggerProducts}
									/>
								</Form.Item>
							</Col>
						)}
						<Col span={8}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Código de cupón
									</label>
								}
								name="code"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
									{
										pattern: new RegExp(
											/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
										),
										message:
											"No se aceptan caracteres especiales. Solo letras y numeros",
									},
								]}
							>
								<Input
									placeholder="Codigo"
									suffix={
										editUnlockCouponCode ? (
											<BsLockFill
												onClick={() => setEditUnlockCouponCode((prev) => !prev)}
												color={COLORS.Secondary}
												style={{ cursor: "pointer" }}
											/>
										) : (
											<BsUnlockFill
												onClick={() => setEditUnlockCouponCode((prev) => !prev)}
												color={COLORS.Secondary}
												style={{ cursor: "pointer" }}
											/>
										)
									}
									disabled={editUnlockCouponCode}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Vigencia
									</label>
								}
								name="publish"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
							>
								<RangePicker
									disabledDate={disabledDate}
									format="DD/MM/YYYY"
									style={{
										width: "100%",
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			</Row>
		</Inner>
	);
};

export default CouponBasicInfo;
