import styled from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";
import { Steps } from "antd";

export const InnerForm = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const Inner = styled.div`
	display: flex;
	gap: 20px;
`;

export const DataContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: ${COLORS.White};
	width: 100%;
	padding: 20px;
	border-radius: 20px;
`;

export const RightData = styled.div`
	flex: 1 1 35%;
	background-color: white;
	border-radius: 10px;
	padding: 20px 0;
`;

export const Container = styled.div`
	padding: 0 2rem;
`;

export const NewMarketing = styled.div``;

export const AllProductsContainer = styled.div`
	text-align: end;
`;

export const PreviewImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: contain;
`;

export const Span = styled.span`
	cursor: pointer;
	color: ${COLORS.Secondary};
	margin-right: 60px;
	font-weight: ${WEIGHT.ExtraBold};
`;

export const CampaignsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
export const InfoContainer = styled.div`
	background-color: white;
	border-radius: 10px;
	padding: 20px;
`;

export const StepsContainer = styled.div`
	margin: 1rem;
	background-color: white;
	border-radius: 10px;
`;

export const AntdSteps = styled(Steps)`
	padding: 20px 150px 20px 150px;

	.ant-steps-item-content {
		background-color: ${COLORS.White};
	}

	.ant-steps-item-title::after {
		position: absolute;
		top: 16px;
		left: 200px;
		display: block;
		width: 1450px;
		height: 2px;
		background: #f0f0f0;
		content: "";
	}

	&.ant-steps-item-active {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					color: ${COLORS.Primary};
				}
				.ant-steps-item-icon {
					color: ${COLORS.Primary};
				}
			}
		}
	}

	.ant-steps-item-process
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title {
		color: ${COLORS.Primary};
		width: 170px;
		border: 1px solid ${COLORS.Primary};
		border-radius: 5px;
	}

	.ant-steps-item-finish {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					color: ${COLORS.Grey};
				}
			}
		}
	}

	&.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role="button"]
		.ant-steps-item-icon
		.ant-steps-icon {
		display: flex;
		margin-right: 10px;
		pointer-events: none;
	}

	.ant-steps-item-custom.ant-steps-item-process
		.ant-steps-item-icon
		> .ant-steps-icon {
		display: flex;
		margin-right: 10px;
	}

	.ant-steps-item-finish
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title::after {
		background-color: ${COLORS.Secondary};
	}

	.ant-steps-item-wait
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title {
		width: 170px;
		border: 1px solid ${COLORS.Grey};
		border-radius: 5px;
	}

	.ant-steps-item-finish
		.ant-steps-item-container
		.ant-steps-item-content
		.ant-steps-item-title {
		width: 170px;
		border: 1px solid ${COLORS.Grey};
		border-radius: 10px;
	}

	@media only screen and (min-width: 760px) and (max-width: 881px) {
		padding: 20px 30px 20px 30px;
	}

	@media only screen and (min-width: 882px) and (max-width: 979px) {
		padding: 20px 80px 20px 80px;
	}

	@media only screen and (min-width: 980px) and (max-width: 1076px) {
		padding: 20px 100px 20px 100px;
	}

	@media only screen and (min-width: 1075px) and (max-width: 1189px) {
		padding: 20px 120px 20px 120px;
	}
`;

export const StepContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 170px;
`;

export const IconContainer = styled.img`
	align-items: center;
	display: flex;
	justify-content: center;
	object-fit: contain;
	width: 25px;
	height: auto;
`;

export const TitleContainer = styled.div`
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.Bold};
`;
