import styled from "styled-components/macro";
import { COLORS } from "../../../layout/theme";
import { Button as ButtonAntd, Drawer } from "antd";

export const Inner = styled.div`
	min-height: 100vh;
	width: 100%;
	background-color: rgb(243 244 246 / 1);
	padding: 20px;
	gap: 20px;
`;

export const Wrapper = styled.div`
	background-color: ${COLORS.LightGrey};
	padding: 10px;
`;

export const AntdButton = styled(ButtonAntd)`
	background-color: ${(props) =>
		props.$active ? COLORS.Primary : COLORS.White};
	color: ${(props) => (props.$active ? COLORS.White : COLORS.Primary)};
	border-color: ${COLORS.Primary};
	border-radius: 5px;
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 10px;
`;

export const SideBar = styled.aside`
	background-color: ${COLORS.LightGrey};
`;

export const FilterContainerButton = styled.div`
	cursor: ${(props) => (props.conditionFullfilled ? "pointer" : "auto")};
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 0px;
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	&:hover {
		background-color: ${(props) =>
			props.conditionFullfilled ? COLORS.LightDefault : COLORS.White};
	}
`;

export const DrawerContainer = styled(Drawer)`
	position: absolute;
	height: 300px;
	left: 80px;
	top: 20px;
	display: flex;
	justify-content: space-between;

	.ant-drawer-content-wrapper {
		width: 200px !important;
	}
`;
