import { Input } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 20px;
	margin-bottom: 30px;
`;

export const PreviewImage = styled.img`
	width: 120px;
	height: 120px;
	object-fit: contain;
`;

export const LeftCard = styled.div`
	pointer-events: none;
	display: flex;
	gap: 50px;
	flex: 1 1 50%;
	background-color: ${COLORS.White};
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
`;

export const RightCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex: 1 1 40%;
	background-color: ${COLORS.White};
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const SubSection = styled.div`
	display: flex;
	gap: 10px;
`;

export const BottomCard = styled.div`
	background-color: ${COLORS.White};
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const FileContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const SectionFiles = styled.div`
	width: 100%;
	overflow: auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: flex-start;
	align-content: flex-start;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const IconContainer = styled.div`
	display: flex;
	padding: 20px 120px;
	align-items: start;
	justify-content: space-between;
`;
