import React from "react";
import Box from "../box/Box";
import { COLORS, Typo } from "../../../../../layout/theme";
import {
	AntdTable,
	BigChartContainer,
	Container,
	Dashboard1Container,
	FilterContainer,
	IconContainer,
	IconGroupContainer,
} from "./styles";
import { Button, DatePicker, Select } from "antd";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import {
	BiBox,
	BiChart,
	BiCoinStack,
	BiDollar,
	BiTrendingUp,
} from "react-icons/bi";
import Image from "../../../../../common/image/Image";
import NoImage from "../../../../../../assets/images/Fallback.png";

export default function Dashboard6({
	dashboard,
	distributors,
	selectDistributor,
	provinces,
	selectProvince,
	laboratories,
	selectLaboratory,
	handleDateChange,
	deleteFilters,
	isLoading,
	selectedLaboratory,
	selectedDistributor,
	selectedProvince,
	rangeDate,
	distributorTable,
	selectDistributorInfo,
	distributorInfoSelector,
	timeDistributorClientGraph,
	distributorsInfoGraph,
	evolutionDistributorClientGraph,
	distributorGraphSelector,
	selectDistributorGraph,
}) {
	const columns = [
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}></div>,
			dataIndex: "photoUrl",
			align: "center",
			width: 140,
			render: (value, record, index) => (
				<Image
					width={50}
					height={50}
					src={value ? value : NoImage}
					alt={record.distributorName ? record.distributorName : "No image"}
					borderRadius={40}
				/>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.DarkGrey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Droguería
				</div>
			),
			width: 250,
			dataIndex: "distributor",
			render: (value, record) => (
				<div style={{ paddingLeft: 20, cursor: "pointer" }}>
					<Typo level={5}>{value}</Typo>
				</div>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.DarkGrey,
						display: "flex",
						justifyContent: "center",
					}}
				>
					Ventas
				</div>
			),
			width: 400,
			dataIndex: "totals",
			render: (value, record) => (
				<div style={{ display: "flex", justifyContent: "center" }}>
					{record.totals.totalSales ? (
						<Typo type="primary" level={3}>
							${" "}
							{numberWithDotAndCommas(
								parseFloat(record.totals.totalSales).toFixed(0)
							)}
						</Typo>
					) : (
						<Typo type="primary" level={3}>
							Sin Datos
						</Typo>
					)}
				</div>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.DarkGrey,
						display: "flex",
						justifyContent: "center",
						gap: 20,
					}}
				>
					Evolución{" "}
					{distributorInfoSelector === "SALES"
						? "Ventas"
						: distributorInfoSelector === "UNITS"
						? "Unidades"
						: "Ordenes"}
					<IconGroupContainer>
						<IconContainer
							onClick={() => selectDistributorInfo("SALES")}
							height={25}
							width={25}
							$active={distributorInfoSelector === "SALES"}
						>
							<BiDollar size={18} />
						</IconContainer>
						<IconContainer
							onClick={() => selectDistributorInfo("UNITS")}
							height={25}
							width={25}
							$active={distributorInfoSelector === "UNITS"}
						>
							<BiCoinStack size={18} />
						</IconContainer>
						<IconContainer
							onClick={() => selectDistributorInfo("ORDERS")}
							height={25}
							width={25}
							$active={distributorInfoSelector === "ORDERS"}
						>
							<BiBox size={18} />
						</IconContainer>
					</IconGroupContainer>
				</div>
			),
			dataIndex: "salesGraph",
			width: 400,
			render: (value, record) => (
				<>
					{distributorInfoSelector === "SALES" && (
						<Box
							graphData={record.salesGraph}
							isLoading={isLoading}
							regionInfo={distributorInfoSelector}
							type="regionTableBox"
						/>
					)}
					{distributorInfoSelector === "UNITS" && (
						<Box
							graphData={record.unitsGraph}
							isLoading={isLoading}
							regionInfo={distributorInfoSelector}
							type="regionTableBox"
						/>
					)}
					{distributorInfoSelector === "ORDERS" && (
						<Box
							graphData={record.ordersGraph}
							isLoading={isLoading}
							regionInfo={distributorInfoSelector}
							type="regionTableBox"
						/>
					)}
				</>
			),
		},
	];

	return (
		<Dashboard1Container>
			<FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Fecha (desde/hasta)
					</Typo>
					{rangeDate ? (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							value={rangeDate}
							onChange={(e) => handleDateChange(e)}
						/>
					) : (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							onChange={(e) => handleDateChange(e)}
						/>
					)}
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Droguería
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Droguería"
						optionFilterProp="children"
						value={selectedDistributor}
						// defaultValue={selectedDistributor ?? null}
						onChange={(e, o) => selectDistributor(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 180 }}
					/>
				</Container>
				{/* <Container>
					<Typo type="primary" level={6}>
						Provincia
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Provincia"
						optionFilterProp="children"
						value={selectedProvince}
						// defaultValue={selectedProvince ?? null}
						onChange={(e, o) => selectProvince(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={provinces}
						style={{ width: 220 }}
					/>
				</Container> */}
				{/* <Container>
					<Typo type="primary" level={6}>
						Laboratorio
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Laboratorio"
						optionFilterProp="children"
						value={selectedLaboratory}
						// defaultValue={selectedLaboratory ?? null}
						onChange={(e, o) => selectLaboratory(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={laboratories}
						style={{ width: 180 }}
					/>
				</Container> */}
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</FilterContainer>
			<BigChartContainer>
				<Box
					type="timeChartMultipleDistributors"
					options={evolutionDistributorClientGraph}
					iconOption={<BiTrendingUp size={20} />}
					option2={timeDistributorClientGraph}
					iconOption2={<BiChart size={20} />}
					selectDistributorGraph={selectDistributorGraph}
					distributorGraph={distributorGraphSelector}
					rangeDate={rangeDate}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							{distributorGraphSelector === "EVOLUTION"
								? "Evolución farmacias asociadas"
								: distributorGraphSelector === "HISTORY"
								? "Mensual farmacias asociadas"
								: ""}
						</Typo>
					}
				/>
			</BigChartContainer>
			<BigChartContainer>
				<Box
					type="timeChart"
					options={distributorsInfoGraph}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							Ventas vs farmacias asociadas
						</Typo>
					}
				/>
			</BigChartContainer>
			<BigChartContainer>
				<AntdTable
					dataSource={distributorTable}
					loading={isLoading}
					columns={columns}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
			</BigChartContainer>
		</Dashboard1Container>
	);
}
