import { Button, Input, Modal, Select, Steps } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../layout/theme";

export const ClientSelect = styled(Select)`

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 2px solid ${COLORS.Grey};
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &..ant-select-arrow {
      
    }
  

  &.ant-select-selector{
    .ant-select-selection-item {
      font-size: ${SIZES.level7};
      color: ${COLORS.Primary};
    }
  }

  &.ant-select-selector{
    .ant-select-selection-search {
      .ant-select-selection-search-input {
        font-size: ${SIZES.level7};
        color: ${COLORS.Primary};
      }
    }
  }
`;

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 100px;
	background-color: ${COLORS.LightGrey};
`;

export const Section = styled.div`
	background-color: white;
	border-radius: 10px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	min-height: 120px;
	padding: 1rem;
	flex-direction: column;
	gap: 10px;
`;

export const AntdButton = styled(Button)`
	cursor: pointer;

	&:hover {
		color: ${(props) => (props.hoverColor ? props.hoverColor : COLORS.Primary)};
		border: 2px solid
			${(props) =>
				props.hoverBorderColor ? props.hoverBorderColor : COLORS.White};
		background-color: ${(props) =>
			props.hoverBackgroundColor ? props.hoverBackgroundColor : COLORS.White};
		box-shadow: 0 1px 1px #bfbfbf;
	}

	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : COLORS.Primary};
	color: ${(props) => (props.color ? props.color : COLORS.White)};
	border-radius: 5px;
	box-shadow: ${(props) =>
		props.buttonShadow ? props.buttonShadow : "0 3px 3px 0 #c7c7c7"};
`;

export const CreateOrderContainer = styled.div`
	display: flex;
	gap: 10px;
	height: 30px;
	align-items: center;
	cursor: pointer;
	width: 20%;
`;

export const Container = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
`;

export const CompaginationDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
`;

export const AntdInput = styled(Input)`
	.ant-input-group .ant-input {
		border: 1px solid ${COLORS.Grey};
		border-radius: 10px;
		background-color: transparent;
		text-align: end;
		padding: 5px;
	}

	.ant-input-group .ant-input:hover {
		border: 1px solid ${COLORS.Secondary};
		border-radius: 10px;
	}

	.ant-input-group .ant-input:focus {
		border-radius: 10px;
	}

	.ant-input-group-addon {
		border: 0;
		background-color: transparent;
		padding: 5px;
	}
`;

export const TableContainer = styled.div`
	width: 100%;
	height: 500px;
	overflow: auto;

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 6px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.SkeletonGrey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}
`;

export const StepsContainer = styled.div`
	margin: 1rem;
	background-color: white;
	border-radius: 10px;
`;

export const AntdSteps = styled(Steps)`
	padding: 10px 150px 10px 150px;

	.ant-steps-item-content {
		background-color: ${COLORS.White};
	}

	.ant-steps-item-title::after {
		position: absolute;
		top: 16px;
		left: 200px;
		display: block;
		width: 1450px;
		height: 2px;
		background: #f0f0f0;
		content: "";
	}

	&.ant-steps-item-active {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					color: ${COLORS.Primary};
				}
				.ant-steps-item-icon {
					color: ${COLORS.Primary};
				}
			}
		}
	}

	.ant-steps-item-process
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title {
		color: ${COLORS.Primary};
		width: 170px;
		border: 1px solid ${COLORS.Primary};
		border-radius: 5px;
	}

	.ant-steps-item-finish {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					color: ${COLORS.Grey};
				}
			}
		}
	}

	&.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role="button"]
		.ant-steps-item-icon
		.ant-steps-icon {
		display: flex;
		margin-right: 10px;
		pointer-events: none;
	}

	.ant-steps-item-custom.ant-steps-item-process
		.ant-steps-item-icon
		> .ant-steps-icon {
		display: flex;
		margin-right: 10px;
	}

	.ant-steps-item-finish
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title::after {
		background-color: ${COLORS.Secondary};
	}

	.ant-steps-item-wait
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-title {
		width: 170px;
		border: 1px solid ${COLORS.Grey};
		border-radius: 5px;
	}

	.ant-steps-item-finish
		.ant-steps-item-container
		.ant-steps-item-content
		.ant-steps-item-title {
		width: 170px;
		border: 1px solid ${COLORS.Grey};
		border-radius: 10px;
	}

	.ant-steps-item:last-child {
		pointer-events: none;
	}

	@media only screen and (min-width: 760px) and (max-width: 881px) {
		padding: 10px 30px 10px 30px;
	}

	@media only screen and (min-width: 882px) and (max-width: 979px) {
		padding: 10px 80px 10px 80px;
	}

	@media only screen and (min-width: 980px) and (max-width: 1076px) {
		padding: 10px 100px 10px 100px;
	}

	@media only screen and (min-width: 1075px) and (max-width: 1189px) {
		padding: 10px 120px 10px 120px;
	}
`;

export const StepContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 170px;
	height: 30px;
`;

export const IconContainer = styled.img`
	align-items: center;
	display: flex;
	justify-content: center;
	object-fit: contain;
	width: 25px;
	height: auto;
`;

export const TitleContainer = styled.div`
	font-size: ${SIZES.level5};
	font-weight: ${WEIGHT.Bold};
`;
