import styled from "styled-components";
import { COLORS, SIZES, WEIGHT } from "../../../../../../layout/theme";

export const Container = styled.div`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	.ant-table .ant-table-container::after {
	}

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.Grey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}
`;

export const CrossDelete = styled.div`
	color: ${COLORS.Grey};
	font-family: sans-serif;
	font-stretch: ultra-expanded;
	font-size: ${SIZES.level12};
	font-weight: ${WEIGHT.Bold};
`;
