import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Main from "../../../layout/main/Main";
import LoadingHeader from "./components/loading-header/LoadingHeader";
import AntdSearch from "../../../common/antd-search/AntdSearch";
import { Button, Checkbox, DatePicker, Modal, Select, Space } from "antd";
import { CompaginationDiv, Filters, TableContainer } from "./styles";
import {
	CloudDownloadOutlined,
	LeftOutlined,
	RightOutlined,
} from "@ant-design/icons";
import ReportProductTable from "./components/report-product-table/ReportProductTable";
import { COLORS, Typo } from "../../../layout/theme";
import {
	export_products_report,
	get_market_products_report,
} from "../../../../api/endpoints/products";
import moment from "moment";
import UsersInfoModal from "./components/users-info-modal/UsersInfoModal";
import DropShadow from "../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";

export default function ReportProducts() {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [interactionDetails, setInteractionDetails] = useState(null);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [loading, setLoading] = useState(false);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(300);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [productToSearch, setProductToSearch] = useState("");
	const [brandToSearch, setBrandToSearch] = useState("");
	const [clientToSearch, setClientToSearch] = useState("");
	const [dateToSearch, setDateToSearch] = useState(
		moment().format("DD/MM/YYYY")
	);
	const [clients, setClients] = useState(null);
	const [brands, setBrands] = useState(null);
	const [clientType, setClientType] = useState("ACTIVE");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [key, setKey] = useState(null);
	const [fileDate, setFileDate] = useState(moment().format("MMMM-YYYY"));

	const fetch_products = async (search = "", skipValue = null) => {
		setLoading(true);
		try {
			const res = await get_market_products_report(
				search,
				skipValue !== null ? skipValue : skip,
				take,
				state,
				productToSearch,
				brandToSearch,
				clientToSearch,
				dateToSearch,
				clientType
			);

			setData(res?.productsReport);
			setInteractionDetails(res?.interactionInfo);
			setClients(
				res?.clientsFilter?.map((r) => ({
					value: r.clientId,
					label: r.clientName,
				}))
			);
			setBrands(
				res?.brandsFilter?.map((r) => ({
					value: r.brandId,
					label: r.brandName,
				}))
			);
			setHeaderInfo(true);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch_products();
	}, [state, brandToSearch, clientToSearch, dateToSearch, clientType]);

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - 100;

			fetch_products(searchTerm, skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - 100);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + 100;

		fetch_products(searchTerm, skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + 100);
	};

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setSearchTerm(e.target.value);
		fetch_products(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Laboratorio</div>,
			dataIndex: "clientName",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Marca</div>,
			dataIndex: "brandName",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "productTitle",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Presentación</div>,
			dataIndex: "productPresentation",
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Vistas por usuario</div>
			),
			dataIndex: "productVisits",
			sorter: (a, b) => a.productVisits - b.productVisits,
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Total Vistas</div>,
			dataIndex: "productTotalVisits",
			sorter: (a, b) => a.productVisits - b.productVisits,
			render: (value, record) => (
				<Typo
					type="muted"
					fontWeight={600}
					fontSize={14}
					onClick={() => handleDetail(record.productId)}
					style={{ cursor: "pointer" }}
				>
					{value}
				</Typo>
			),
		},
		//Cambio al 25/01/2024
		//Este campo ahora muestra cantidad de ordenes en las cuales estuvo presente el producto
		//Dentro del valor puede haber más de una orden con ese producto de la misma farmacia
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Cantidad de Ordenes</div>
			),
			dataIndex: "productSales",
			sorter: (a, b) => a.productSales - b.productSales,
			render: (value, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Conversión</div>,
			dataIndex: "productRelation",
			sorter: (a, b) => a.productRelation - b.productRelation,
			render: (value, record) => (
				<Typo
					type={`${value > 50 ? "success" : "warning"}`}
					fontWeight={600}
					fontSize={14}
				>
					{value} %
				</Typo>
			),
		},
	];

	const selectLaboratory = async (e) => {
		setClientToSearch(e);
	};

	const selectBrand = async (e) => {
		setBrandToSearch(e);
	};

	const handleDateChange = (date) => {
		setDateToSearch(date.format("DD/MM/YYYY").toString());
		setFileDate(date.format("MMMM-YYYY").toString());
	};

	const handleIsApprovedFilter = (value) => {
		if (value) {
			setClientType("ACTIVE");
		} else {
			setClientType("INACTIVE");
		}
	};

	const handleDetail = async (productId) => {
		setKey(interactionDetails.filter((i) => i.productId === productId));
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
		setKey(null);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setKey(null);
	};

	const fetch_export_report = async () => {
		setLoading(true);
		try {
			const res = await export_products_report({
				productReportToExport: data,
			});
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const _fileName = "ProductsReport_" + fileDate + ".xlsx";
			link.setAttribute("download", _fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Reporte Productos
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<AntdSearch
								width={300}
								allowClear
								placeholder="Buscar producto"
								onChange={debouncedEventHandler}
							/>
							<Checkbox
								checked={clientType === "ACTIVE"}
								onChange={(e) => handleIsApprovedFilter(e.target.checked)}
							>
								Clientes con Contrato
							</Checkbox>
							<Select
								showSearch
								allowClear
								placeholder="Buscar cliente"
								optionFilterProp="children"
								onChange={(e) => selectLaboratory(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={clients}
								style={{ width: 180 }}
							/>
							<Select
								showSearch
								allowClear
								placeholder="Buscar marca"
								optionFilterProp="children"
								onChange={(e) => selectBrand(e)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={brands}
								style={{ width: 180 }}
							/>
							<DatePicker picker="month" onChange={handleDateChange} />
							<DropShadow type="drop">
								<SignInTemplate.AntdButton
									type="Default"
									bg="Default"
									color="White"
									icon={<CloudDownloadOutlined />}
									onClick={fetch_export_report}
									loading={loading}
									style={{ width: "200px", fontWeight: "400" }}
								>
									Exportar reporte
								</SignInTemplate.AntdButton>
							</DropShadow>
						</Space>
					)}
				</Filters>
				<TableContainer>
					<ReportProductTable
						data={data}
						columns={columns}
						loading={loading}
						isHeaderShow={true}
						scroll={{ y: 900 }}
					/>
				</TableContainer>
				{data && data.length > 0 && (
					<CompaginationDiv>
						{currentPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						{data.length === 100 && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={data.length < 100 ? true : false}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
			</Main.Body>
			{key && isModalVisible && (
				<Modal
					title={
						<div style={{ height: 30 }}>
							<Typo level={8} type="muted">
								Detalle interacciones
							</Typo>
						</div>
					}
					visible={isModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
					width={900}
					centered={true}
					footer={null}
				>
					<UsersInfoModal modalData={key} />
				</Modal>
			)}
		</Main>
	);
}
