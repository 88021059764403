import styled from "styled-components";
import { Button, Select, Table, Upload } from "antd";
import { COLORS, WEIGHT } from "../../../../layout/theme";

export const ButtonContainer = styled.div`
	display: flex;
	margin-top: 20px;
	gap: 20px;
`;
export const UploadFile = styled(Upload)`
	width: 170px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	& span {
		color: ${COLORS.White};
	}

	position: relative;
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;
export const AntdSelect = styled(Select)`
	border: none;
	font-weight: ${WEIGHT.SemiBold};
`;

export const AntdOption = styled.div`
	border: none;
	font-weight: ${WEIGHT.SemiBold};
	color: ${COLORS.Grey};
`;

export const AntdButton = styled(Button)`
	width: 170px;
	height: 40px;
	background-color: ${(props) =>
		props.success ? COLORS.Success : COLORS.Secondary};
	color: ${COLORS.White};
	border: 1px solid
		${(props) => (props.success ? COLORS.Success : COLORS.Secondary)};
	border-radius: 7px;
	padding: 0.5rem 0;
	&:hover {
		background-color: ${(props) =>
			props.success ? COLORS.Success : COLORS.Secondary};
		color: ${COLORS.White};
	}
	& span {
		color: ${COLORS.White};
	}
	position: relative;
`;

export const ImportHistoryContainer = styled.div`
	margin-top: 20px;
`;
