import styled from "styled-components";
import { COLORS } from "../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const TableContainer = styled.div`
	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	.ant-table .ant-table-container::after {
	}

	scrollbar-width: auto;
	scrollbar-color: ${COLORS.SkeletonGrey} ${COLORS.White};

	::-webkit-scrollbar {
		width: 7px;
	}

	::-webkit-scrollbar-track {
		background: ${COLORS.White};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${COLORS.Grey};
		border-radius: 10px;
		border: 2px solid ${COLORS.White};
	}
`;

export const Card = styled.div`
	background-color: ${COLORS.White};
	padding: 1rem 2rem 1rem 2rem;
	border-radius: 10px;
	display: flex;
	gap: 20px;
`;

export const Section = styled.div`
	display: flex;
	flex: 1;
	justify-content: start;
	align-items: center;
	flex-direction: column;
	gap: 20px;
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 100%;
`;
